<template>
  <div class="Stands white" v-loading="loading">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      v-bind:insert="true"
      v-bind:del="true"
      v-on:select="select"
      v-on:insert="insert"
      v-on:modify="modify"
      v-on:delete="del"
    >
    </actionbar>
     <b-button size="sm" @click="light">点亮发树</b-button>
    <el-table stripe=""
      :data="items"
      style="width: 100%"
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column
        :label="$t('名称')"
        prop="Name"
      ></el-table-column>
      <el-table-column label="启动">
        <template slot-scope="scope">
            <b-button size="sm" @click="start(scope.row.Sid,true)">启动</b-button>
        </template>
      </el-table-column>
      <el-table-column label="停止">
        <template slot-scope="scope">
            <b-button  size="sm" @click="start(scope.row.Sid,false)">停止</b-button>
        </template>
      </el-table-column>
    </el-table>
    
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/device/DelFake"
      :title="$t('删除')"
    ></deletedata>

    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="'演示数据编辑'"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('名称')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.Name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('摄像头')" :label-cols="2">
            <b-form-select :options="cams" v-model="modalData.CamSid" required text-field="DeviceName" value-field="Sid"> </b-form-select> 
        </b-form-group>
        <b-form-group v-bind:label="$t('显示屏')" :label-cols="2">
            <b-form-select :options="leds" v-model="modalData.LedSid" required text-field="DeviceName" value-field="Sid"> </b-form-select> 
        </b-form-group>
        <b-form-group v-bind:label="$t('节目')" :label-cols="2">
            <b-form-select :options="progs" v-model="modalData.ProgSid" required text-field="Name" value-field="Sid"> </b-form-select> 
        </b-form-group>
        <b-form-group v-bind:label="$t('音柱')" :label-cols="2">
            <b-form-select :options="videos" v-model="modalData.VideoSid" required text-field="DeviceName" value-field="Sid"> </b-form-select> 
        </b-form-group>
        <b-form-group v-bind:label="$t('播放文本')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.Text"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('电话通知')" :label-cols="2">
            <b-form-select :options="phoneOpt" v-model="modalData.CallPhone" required text-field="text" value-field="value"> </b-form-select> 
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width: 100px" v-on:click="hiden">Cancel</b-button>
            <b-button type="submit" style="width: 100px" variant="primary"
              >OK</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import deletedata from "@/components/deletedata";
export default {
  data() {
    return {
      current:null,
      loading: false,
      items: [],
      cams: [],
      videos: [],
      progs: [],
      leds: [],
      sid:"",
      modalData: this.getResetData(),
      phoneOpt:[
          {
              text:"是",
              value:true
          },
          {
              text:"否",
              value:false
          }
      ]
    };
  },
  components: { actionbar,deletedata },
  mounted() {
    this.select();
    this.getCams();
    this.getLeds();
    this.getProgs();
    this.getVideos();
  },
  methods: {
      start(sid,start){
          var obj={
              Sid:sid,
              Start:start
          }
          this.loading = true;
           this.$ksajax
        .ksPostPromise("/api/device/ActionFake", obj, true).then(()=>{
            this.loading=false;
        }).catch(err=>{
            this.$throw(err);
            this.loading=false;
        })
      },
      currentRow(val){
          this.current=val;
      },
      onSubmit(){
      this.loading = true;
           this.$ksajax
        .ksPostPromise("/api/device/setFake", this.modalData, true).then(()=>{
            this.loading=false;
            this.hiden();
            this.select();
        }).catch(err=>{
            this.$throw(err);
            this.loading=false;
            this.hiden();
        })
      },
      hiden(){
          
      this.$refs.modallg.hide();
      },
      light(){
      this.loading = true;
          this.$ksajax
        .ksPostPromise("/api/Extend/LightTree", "", true).then(()=>{
            this.loading=false;
        }).catch(err=>{
            this.loading=false;
            this.$throw(err);
        })
      },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/device/getFake", "", true)
        .then((data) => {
          console.log(data);
          this.items = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    deleted() {
      this.select();
    },
    getResetData() {
      return {
        Sid: "",
        Name: "",
        LedSid: "",
        ProgSid: "",
        CamSid: "",
        VideoSid: "",
        Text: "",
        CallPhone: "",
      };
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();},
    modify() {
      if(!this.current){
          throw "请选择一条数据"
      }
      this.modalData=this.getResetData();
      this.modalData=this.current;
      this.$refs.modallg.show();
    },
    insert() {
      this.modalData=this.getResetData();
      this.$refs.modallg.show();
    },
    getCams() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise(
          "/api/device/GetDeviceVideos",
          {
            Index: 0,
            Count: 1000,
          },
          true
        )
        .then((data) => {
          this.cams = data.Items;
          this.loading = false;
            console.log("cams",this.cams);
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    getLeds() {
      var obj = {
        Index: 0,
        Count: 1000,
      };
      this.loading=true;
      this.$ksajax
        .ksPostPromise("/api/led/GetNovaLeds", obj, true)
        .then((data) => {
          this.leds = data.Items;
          this.loading = false;
            console.log("leds",this.leds);
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
    getVideos() {
      var obj = {
        PageIndex: 1,
        PageCount: 1000,
      };
      this.loading=true;
        this.$ksajax
          .ksPostPromise("/api/CityBroadcast/Terminal/Local/GetList", obj, true)
          .then((data) => {
            this.videos = data.Items;
            console.log("videos",this.videos);
          this.loading = false;
          })
          .catch((err) => {
          this.$throw(err);
          this.loading = false;
          });
    },
    getProgs() {
      this.loading=true;
      this.$ksajax
        .ksPostPromise("/api/led/getledstage", "", true)
        .then((data) => {
            this.progs=data;
            console.log("progs",this.progs);
            this.loading = false;
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
  },
};
</script>