<template>
  <div v-loading="loading">
    <div class="log_action clearfix">
      <div class="log_action_filter">
        <span>年龄段</span>
        <el-select v-model="filter.Age">
          <el-option
            v-for="(item, index) in ages"
            :key="`${item.code}${index}`"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
      <div class="log_action_filter">
        <span>性别</span>
        <el-select v-model="filter.Sex">
          <el-option :value="1" label="男"></el-option>
          <el-option :value="2" label="女"></el-option>
          <el-option :value="null" label="全部"></el-option>
        </el-select>
      </div>
      <div class="log_action_filter">
        <span>口罩</span>
        <el-select v-model="filter.Mask">
          <el-option :value="2" label="戴口罩"></el-option>
          <el-option :value="1" label="不戴口罩"></el-option>
          <el-option :value="null" label="全部"></el-option>
        </el-select>
      </div>
      <div class="log_action_filter log_action_filter_botton">
        <el-button type="primary" style="width: 120px" @click="dselect"
          >查询</el-button
        >
      </div>
    </div>
    <tablepage
      ref="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>
    <b-modal
      ref="modal"
      :title="$t('详情')"
      centered
      content-class="ksmod"
      :ok-title="$t('确定')"
      hide-footer
      size="xl"
    >
      <div style="height: 350px">
        <lawDetail :value="currentLaw"></lawDetail>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
.log_action {
  color: black;
  background-color: white;
  padding: 10px;
}
.log_action_filter {
  width: 200px;
  padding: 5px;
  float: left;
}
.log_action_filter_botton {
  height: 74px;
  align-items: center;
  display: flex;
  padding-left: 20px;
}
</style>
<script>
import tablepage from "@/components/tablepage";
import lawDetail from "./components/lawDetail.vue";
import { getAgeByCode, getExpByCode,ages ,experssion} from "@/assets/utils/hikUtil";
export default {
  components: { tablepage, lawDetail },
  data() {
    return {
      currentLaw: {},
      items: [],
      loading: false,
      filter: {
        StartTime:null,
        EndTime: null,
        Mask: null,
        Age: null,
        Sex: null,
        Count:15,
        Skip:0
      },
      experssion: [],
      ages:[],
      cols: [
        {
          name: "抓拍头像",
          value: "HeaderPic",
          subvalue: "Law",
          pic:true
        },
        {
          name: "抓拍日期",
          value: "CreateTime",
          subvalue: "Law",
        },
        {
          name: "性别",
          value: "Sex",
          subvalue: "FaceInfo",
        },
        {
          name: "年龄段",
          value: "AgeGroup",
          subvalue: "FaceInfo",
        },
        {
          name: "年龄",
          value: "Age",
          subvalue: "FaceInfo",
        },
        {
          name: "是否戴帽子",
          value: "Hat",
          subvalue: "FaceInfo",
        },
        {
          name: "是否戴口罩",
          value: "Mask",
          subvalue: "FaceInfo",
        },
        {
          name: "是否戴眼镜",
          value: "EyeGlass",
          subvalue: "FaceInfo",
        },
        {
          name: "表情",
          value: "FaceExpression",
          subvalue: "FaceInfo",
        },
      ],
      total: 0,
      perpage: 0,
      count: 8,
      cpage: 1,
    };
  },
  mounted() {
    this.select();
    this.experssion = experssion;
    this.ages=ages;
    this.experssion.push({ code: null, name: "全部" });
    this.ages.push({ code: null, name: "全部" });
  },
  methods: {
    tableCreated() {},
    currentRow(val) {
      this.currentLaw = val;
      // if (this.currentLaw) {
      //   this.$refs.modal.show();
      // }
    },
    currentPage(val) {
      this.cpage = val;
      this.filter.Skip = (val - 1) * this.filter.Count;
      this.select();
    },
    dselect() {
      if (this.cpage == 1) {
        this.select();
      } else {
        this.$refs.tableref.reset();
      }
    },
    
    pariseData(data) {
      if (!data.FaceInfo) {
        data.FaceInfo = {
          Age: -1,
          AgeDeviation: 0,
          AgeGroup: -1,
          EyeGlass: -1,
          FaceExpression: -1,
          Hat: -1,
          Mask: -1,
          Sex: -1,
          Sid: -1,
          Smile: -1,
        };
      }
      data.FaceInfo.Age =
        data.FaceInfo.Age == -1 ? "未知" : data.FaceInfo.Age + "岁";
      data.FaceInfo.AgeGroup = getAgeByCode(data.FaceInfo.AgeGroup);
      data.FaceInfo.EyeGlass =
        data.FaceInfo.EyeGlass == 1
          ? "未戴眼镜"
          : data.FaceInfo.EyeGlass == 2
          ? "戴眼镜"
          : "未知";
      data.FaceInfo.FaceExpression = getExpByCode(data.FaceInfo.FaceExpression);
      data.FaceInfo.Hat =
        data.FaceInfo.Hat == 1
          ? "未戴帽子"
          : data.FaceInfo.Hat == 2
          ? "戴帽子"
          : "未知";
      data.FaceInfo.Mask =
        data.FaceInfo.Mask == 1
          ? "未戴口罩"
          : data.FaceInfo.Mask == 2
          ? "戴口罩"
          : "未知";
      data.FaceInfo.Sex =
        data.FaceInfo.Sex == 1 ? "男" : data.FaceInfo.Sex == 2 ? "女" : "未知";
      data.FaceInfo.Smile =
        data.FaceInfo.Smile == 1
          ? "是"
          : data.FaceInfo.Smile == 2
          ? "否"
          : "未知";
      return data;
    },
    select() {
      this.$refs.modal.hide();
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetFaceRecords", this.filter, true)
        .then((data) => {
          this.loading = false;
          console.log(data);
          data.Items.forEach((x) => {
              this.pariseData(x);
          });
          this.items = data.Items;
          this.total = data.TotalCount;
          this.$refs.modal.hide();
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
  },
};
</script>