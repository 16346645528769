<template>
  <div id="ksmap" :style="mapSize">
    <div id="container"></div>
    <div v-if="showCenter" id="map-center">
      <b-img :src="centerImg" height="46px" width="22px"></b-img>
    </div>
  </div>
</template>

<style>
#container {
  height: 100%;
  width: 100%;
}

#ksmap {
  position: relative;
}

#map-center {
  position: absolute;
  left: 50%;
  top: 50%;
  left: -moz-calc(50% - 11px);
  left: -webkit-calc(50% -11px);
  left: calc(50% - 11px);
  top: -moz-calc(50% - (46px));
  top: -webkit-calc(50% - (46px));
  top: calc(50% - (46px));
}
</style>

<script>
import config from "../assets/utils/config";
export default {
  baseUrl: config.BaseUrl,
  data: function () {
    return {
      baseUrl: config.BaseUrl,
      plugin: ["MapType"],
      map: {},
      geocoder: {},
      centerImg: require("@/assets/img/center.png"),
      markers: [],
    };
  },
  watch: {
    lng: function () {},
  },
  props: {
    lat: Number,
    lng: Number,
    zoom: Number,
    height: String,
    showCenter: Boolean,
    viewMode: String,
    custom: String,
  },
  computed: {
    mapSize: function () {
      return {
        width: "100%",
        height: this.height,
      };
    },
  },
  mounted: function () {
    this.map = new window.AMap.Map("container", {
      zoom: this.zoom, //级别
      center: [this.lng, this.lat], //中心点坐标
      // viewMode: this.viewMode||"3D" //使用3D视图
    });
    if (this.custom) {
      this.map.setMapStyle("amap://styles/" + this.custom);
    }
    this.map.on("moveend", this.mapMoveEnd);
    this.map.on("click", (e) => {
      this.$emit("mapclick", e);
    });
    var self = this;
    window.AMap.plugin("AMap.Geocoder", function () {
      let geo = new window.AMap.Geocoder({
        city: "全国",
      });
      self.geocoder = geo;
    });
  },
  methods: {
    setAlarm(serial) {
      var targ = null;
      this.markers.forEach((x) => {
        x.markers.forEach((y) => {
          if (y.Serial && y.Serial == serial) {
            targ = y;
          }
        });
      });
      if (targ) {
        var bicon = targ.getIcon();
        var icon = new AMap.Icon({
          size: new AMap.Size(50, 50), // 图标尺寸
          image: `${this.baseUrl}/img/map/alarm.gif`, // Icon的图像
          imageSize: new AMap.Size(50, 50), // 根据所设置的大小拉伸或压缩图片
        });
        targ.setIcon(icon);
        window.setTimeout(() => {
          targ.setIcon(bicon);
        }, 10000);
      }
    },
    mapMoveEnd() {
      let lat = this.map.getCenter().lat;
      let lng = this.map.getCenter().lng;
      let obj = {
        lat: lat,
        lng: lng,
      };
      this.$emit("mapMoveEnd", obj);
    },
    getAddressByPostion(lat, lng, success) {
      var lnglat = [lng, lat];
      this.geocoder.getAddress(lnglat, function (status, result) {
        if (status === "complete" && result.info === "OK") {
          success(result);
        }
      });
    },
    setCenter(lat, lng) {
      var center = new window.AMap.LngLat(lng, lat);
      this.map.setCenter(center);
      this.map.setZoom(this.zoom);
    },
    getPostionByAddress(adderss, success) {
      this.geocoder.getLocation(adderss, function (status, result) {
        if (status === "complete" && result.info === "OK") {
          success(
            result.geocodes[0].location.lat,
            result.geocodes[0].location.lng
          );
        }
      });
    },
    setAddress(address) {
      this.getPostionByAddress(address, (lat, lng) => {
        this.setCenter(lat, lng);
      });
    },
    deleteDeviceByType(type) {
      this.markers.forEach((x) => {
        if (x.type == type) {
          x.markers.forEach((y) => {
            y.setMap(null);
          });
        }
      });
    },
    getDeviceByType(type) {
      return this.markers.find((x) => x.type == type);
    },
    addDevice(datas, type, iconSize) {
      var ms = [];
      var size = new AMap.Size(40, 50);
      if (iconSize) {
        size = new AMap.Size(iconSize.w, iconSize.h);
      }
      datas.forEach((x) => {
        var icon = new AMap.Icon({
          size: size, // 图标尺寸
          image: x.icon, // Icon的图像
          imageSize: size, // 根据所设置的大小拉伸或压缩图片
        });
        var drag = x.draggable ? false : true;
        var marker = new AMap.Marker({
          position: new AMap.LngLat(x.lng, x.lat),
          // offset: new AMap.Pixel(-10, -10),
          icon: icon, // 添加 Icon 图标 URL
          title: x.title,
          draggable: drag,
        });
        marker.Sid = x.sid;
        marker.Serial = x.serial;
        marker.Obj = x.obj;
        this.map.add(marker);
        marker.on("click", () => {
          if (x.url) {
            this.$router.push(x.url);
          }
          this.$emit("iconClick",x.sid);
        });
        if (x.sid) {
          marker.on("dragend", (data) => {
            var obj = {
              Lat: data.lnglat.lat,
              Lng: data.lnglat.lng,
              // Address: add.regeocode.formattedAddress,
              Sid: data.target.Sid,
            };
            this.$ksajax.ksPostPromise("/api/info/UpdatePosition", obj, true);
            // this.getAddressByPostion(data.lnglat.lat, data.lnglat.lng, add => {
            // });
          });
        }
        ms.push(marker);
      });
      var obj = {
        type: type,
        markers: ms,
      };
      var s = this.markers.find((x) => x.type == type);
      if (!s) {
        this.markers.push(obj);
      } else {
        s = obj;
      }
      this.map.setFitView(obj.markers);
    },
    clear() {
      this.markers.forEach((x) => {
        x.markers.forEach(y=>{
        y.setMap(null);});
      });
      this.markers = [];
    },
    fresh(arr) {
      this.markers.forEach((x) => {
        var ex = arr.find((z) => z == x.type);
        if (ex) {
          x.markers.forEach((y) => {
            y.setMap(this.map);
          });
        } else {
          x.markers.forEach((y) => {
            y.setMap(null);
          });
        }
      });
    },
    displayByPar(type, state) {
      var obj = this.markers.find((x) => x.type == type);
      if (obj) {
        if (state == -1) {
          obj.markers.forEach((y) => {
            y.setMap(this.map);
          });
        } else {
          obj.markers.forEach((x) => {
            if (x.Obj.state == state) {
              x.setMap(this.map);
            } else {
              x.setMap(null);
            }
          });
        }
      }
    },
    setFilter(type) {
      if (type) {
        var s = this.markers.find((x) => x.type == type);
        if (s) {
          this.map.setFitView(s.markers);
        }
      } else {
        var datas = [];
        this.markers.forEach((x) => {
          x.markers.forEach((y) => {
            if (y.getMap()) {
              datas.push(y);
            }
          });
        });
        this.map.setFitView(datas);
      }
    },
    // 如果要使用简单路径就不能用3D地图
    drawLine(pathArr) {
      var mapSelf = this.map;
      AMapUI.load(
        ["ui/misc/PathSimplifier", "lib/$"],
        function (PathSimplifier, $) {
          $;
          if (!PathSimplifier.supportCanvas) {
            alert("当前环境不支持 Canvas！");
            return;
          }

          var pathSimplifierIns = new PathSimplifier({
            zIndex: 100,
            //autoSetFitView:false,
            map: mapSelf, //所属的地图实例

            getPath: function (pathData) {
              return pathData.path;
            },
            getHoverTitle: function (pathData, pathIndex, pointIndex) {
              if (pointIndex >= 0) {
                //point
                return (
                  pathData.name +
                  "，点：" +
                  pointIndex +
                  "/" +
                  pathData.path.length
                );
              }

              return pathData.name + "，点数量" + pathData.path.length;
            },
            renderOptions: {
              renderAllPointsIfNumberBelow: 100, //绘制路线节点，如不需要可设置为-1
            },
          });

          window.pathSimplifierIns = pathSimplifierIns;

          //设置数据
          pathSimplifierIns.setData([
            {
              name: "路线0",
              //path: [
              //    [116.405289, 39.904987],
              //    [113.964458, 40.54664],
              //    [111.47836, 41.135964],
              //    [108.949297, 41.670904],
              //    [106.380111, 42.149509],
              //    [103.774185, 42.56996],
              //    [101.135432, 42.930601],
              //    [98.46826, 43.229964],
              //    [95.777529, 43.466798],
              //    [93.068486, 43.64009],
              //    [90.34669, 43.749086],
              //    [87.61792, 43.793308]
              //]
              path: pathArr,
            },
          ]);

          //对第一条线路（即索引 0）创建一个巡航器
          var navg1 = pathSimplifierIns.createPathNavigator(0, {
            loop: true, //循环播放
            speed: 150, //巡航速度，单位千米/小时
          });

          navg1.start();
        }
      );
    },
  },
};
</script>