<template>
    <div style="width:100%;height:100%">
        <ysvedio v-if="!ie" ref="netplay"  :showright="showRight" :playdata="playdata" :unshowaction="unshowaction"></ysvedio>
        <netpay v-else ref="netieplay" :data="playdata"  :showright="showRight" :unshowaction="unshowaction"></netpay>
    </div>
</template>
<script>
import netpay from "@/components/netpay";
import ysvedio from '@/components/newvideo'
export default {
    components:{netpay,ysvedio},
    props: ["playdata","showRight","unshowaction"],
    data:function(){return{
        ie:false
    }},
    mounted:function(){
        this.ie=this.isIe();
    },
    methods:{ 
        isIe:function(){
            if(!!window.ActiveXObject || "ActiveXObject" in window){
                return true;
            }else{
            return false;
            }
        },
        start(){
            if(this.ie){
                this.$refs.netieplay.start();
            }else{
                this.$refs.netplay.start()
            }
        },
        stop(){
            if(this.ie){
                this.$refs.netieplay.stop();
            }else{
                this.$refs.netplay.stop()
            }
        }
    }
}
</script>
