<template>
  <div ref="barchart" style="width: 100%; height: 100%"></div>
</template>
<script>
export default {
  props: ["radius", "centerlable", "size"],
  data: function () {
    return {
      chart: null,
      data: null,
      legend: null,
      color: null,
      emphasis: null,
      title: null,
    };
  },
  methods: {
    getOption() {
      var sub = 0;
      this.data.forEach((element) => {
        sub += element.value;
      });
      var opt = {
        title: this.title,
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
          position: ["50%", "50%"],
        },
        textStyle: {
          color: "#fff",
        },
        legend: this.legend,
        grid: {
          left: "3%",
          right: "4%",
          bottom: "0%",
          top: "3%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            radius: this.radius
              ? this.size == "small"
                ? ["45%", "65%"]
                : ["65%", "85%"]
              : "65%",
            center: ["50%", "50%"],
            data: this.data,
            color: this.color,
            avoidLabelOverlap: false,
            label: this.centerlable
              ? {
                  normal: {
                    formatter: [
                      `{title|${this.centerlable}}`,
                      `{count|${sub}}`,
                    ].join("\n"),
                    show: true,
                    position: "center",
                    rich: {
                      title: {
                        color: "white",
                        fontSize: 20,
                      },
                      count: {
                        color: "#8495c2",
                        fontSize: 18,
                        padding: [0, 0, 10, 0],
                      },
                    },
                  },
                }
              : {
                  position: "outer",
                  alignTo: "none",
                  bleedMargin: 0,
                  formatter: "{b}:{d}%",
                },
            labelLine: {
              length: 10,
              length2: 10,
            },
          },
        ],
      };
      //   opt.title = this.pietitle;
      return opt;
    },
    setAndInit(data, legend, color, emphasis, title) {
      this.data = data;
      this.legend = legend;
      if (this.legend) {
        this.legend.textStyle = { color: "#fff" };
      }
      this.color = color;
      this.emphasis = emphasis;
      if (!title) {
        this.title = {
          show: false,
        };
      } else {
        this.title = title;
      }

      if (this.title) {
        if (this.title.textStyle) {
          this.title.textStyle.color = "#fff";
        } else {
          this.title.textStyle = { color: "#fff" };
        }
      }
      this.init();
    },
    init() {
      if (!this.chart) {
        this.chart = window.echarts.init(this.$refs.barchart);
      }else{
        this.chart.clear();
      }
      var obj = this.getOption();
      this.chart.setOption(obj);
      window.charts = window.charts || [];
      window.charts.push(this.chart);
      window.addEventListener("resize", () => {
        this.chart.resize();
      });
      return this.chart;
    },
  },
};
</script>