<template>
  <div class="ksflex smart_device white">
    <div class="smart_device_left">
      <el-menu
        default-active="0"
        class="el-menu-vertical-demo"
        @select="commandAction"
      >
        <el-menu-item v-for="(item,index1) in gates" :index="index1+''" :key="item.sid">
          <i class="el-icon-document"></i>
          <span slot="title">{{item.name}}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="smart_device_right" v-loading="loading">
      <div>
        <el-select v-model="choose" placeholder="请选择" style="padding-right:15px;width:200px">
          <el-option value="0" label="网关群控"></el-option>
          <el-option value="1" label="单灯控制"></el-option>
        </el-select>
        <el-button type="primary" size="mini" @click="action(100)">开灯</el-button>
        <el-button type="success" size="mini" @click="action(0)">关灯</el-button>调光
        <el-slider v-model="value1" style="width:200px;display:inline-block" @change="change"></el-slider>
      </div>
      <div>
        <el-table
          ref="multipleTable"
          :data="lights"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="name" label="灯具名称"></el-table-column>
          <el-table-column prop="addr" label="灯控器地址"></el-table-column>
          <el-table-column prop="online" label="在线状态">
            <template slot-scope="scope">
              <i :class="scope.row.online==0?'fa fa-signal stateOn':'fa fa-signal stateOff'"></i>
            </template>
          </el-table-column>
          <el-table-column prop="state" label="设备状态">
            <template slot-scope="scope">
              <i
                :class="scope.row.state==1?'fa fa-lightbulb-o stateOn':'fa fa-lightbulb-o stateOff'"
              ></i>
            </template>
          </el-table-column>
          <el-table-column prop="light" label="亮度"></el-table-column>
          <el-table-column prop="color" label="色温"></el-table-column>
          <el-table-column prop="v" label="电压(V)"></el-table-column>
          <el-table-column prop="a" label="电流(A)"></el-table-column>
          <el-table-column prop="y" label="有功功率"></el-table-column>
          <el-table-column prop="g" label="功率因素"></el-table-column>
          <el-table-column prop="hz" label="运行频率"></el-table-column>
          <el-table-column prop="time" label="更新时间"></el-table-column>
          <!-- <el-table-column prop="history" label="历史数据">
            <template>
              <i class="fa fa-table stateOn ipo"></i>
            </template>
          </el-table-column> -->
          <el-table-column prop="c" label="策略">
            <template>
              <i class="fa fa-hand-o-down stateOn ipo" @click="$refs.lightmodel.show()"></i>
            </template>
          </el-table-column>
        </el-table>
        <pageable v-on:currentChange="currentChange" :perPage="count" :totalPage="totalPage"></pageable>
      </div>
    </div>

    <b-modal
      id="modallg"
      ref="lightmodel"
      size="lg"
      title="单灯策略"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <el-form :inline="true" class="demo-form-inline">
        <div>
          <el-form-item label="时间策略开关">
            <el-radio   v-model="form.a1" label="1" value="0">开</el-radio>
            <el-radio  v-model="form.a1" label="2" value="1">关</el-radio>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="策略1">
            <el-radio label="1"  v-model="form.a2">开</el-radio>
            <el-radio label="2"  v-model="form.a2">关</el-radio>
          </el-form-item>
          <el-form-item label="时间">
            <el-date-picker type="date" v-model="form.a8" placeholder="选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="动作">
            <el-select  placeholder="请选择"  v-model="form.a3">
              <el-option label="开"   value="开"></el-option>
              <el-option label="关"   value="关"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="环路">
            <el-radio label="1"  v-model="form.a4" value="0">1路</el-radio>
            <el-radio label="2"  v-model="form.a4" value="1">2路</el-radio>
          </el-form-item>
          <el-form-item label="经维度策略开关">
            <el-radio label="1"  v-model="form.a7" value="0">开</el-radio>
            <el-radio label="2"  v-model="form.a7" value="1">关</el-radio>
          </el-form-item>
          <el-form-item label="经度">
            <el-select  placeholder="请选择" v-model="form.a9">
              <el-option label="东经"   value="0"></el-option>
              <el-option label="西经"   value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-input class="iinput" v-model="form.a10"></el-input>°<el-input class="iinput" v-model="form.a11"></el-input>'<el-input class="iinput" v-model="form.a12"></el-input>'
          <el-form-item label="纬度" >
            <el-select  placeholder="请选择" v-model="form.a13">
              <el-option label="北纬" value="0"></el-option>
              <el-option label="南纬" value="0"></el-option>
            </el-select>
          </el-form-item> <el-input class="iinput" v-model="form.a14"></el-input>°<el-input class="iinput" v-model="form.a15"></el-input>'<el-input class="iinput" v-model="form.a16"></el-input>'
         
          <el-form-item label="时区">
            <el-select  placeholder="请选择" v-model="form.a17">
              <el-option label="UTC" value="0"></el-option>
              <el-option label="UTC+8" value="1"></el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item label="经维度偏移量">
            <el-radio label="1"  v-model="form.a18">开</el-radio>
            <el-radio label="2" v-model="form.a19">关</el-radio>
          </el-form-item>
          <el-form-item label="日出偏移量">
            <el-input class="iinput"  v-model="form.a20"></el-input>分钟
          </el-form-item>
          <el-form-item label="日落偏移量">
            <el-input class="iinput"  v-model="form.a21"></el-input>分钟
          </el-form-item>
          <el-form-item label="光感开关">
            <el-radio label="1"  v-model="form.a22">开</el-radio>
            <el-radio label="2"  v-model="form.a22">关</el-radio>
          </el-form-item>
          <el-form-item label="微波开关">
            <el-radio label="1"  v-model="form.a23">开</el-radio>
            <el-radio label="2"  v-model="form.a23">关</el-radio>
          </el-form-item>
          <el-form-item label="GPS开关">
            <el-radio label="1"  v-model="form.a24">开</el-radio>
            <el-radio label="2"  v-model="form.a24">关</el-radio>
          </el-form-item>
          <el-form-item label="GPS校时开关">
            <el-radio label="1"  v-model="form.a25">开</el-radio>
            <el-radio label="2"  v-model="form.a25">关</el-radio>
          </el-form-item>
        </div>
        
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width:100px" v-on:click="hidden">退出</b-button>
            <b-button type="button" style="width:100px" variant="primary" v-on:click="onSubmit">下发策略</b-button>
          </div>
        </b-form-group>
      </el-form>
    </b-modal>
  </div>
</template>
<style scoped>
ul{
    width: 100% !important;
}
.smart_device {
  height: 100%;
}
.smart_device_left {
  width: 300px;
  height: 100%;
}
.smart_device_right {
  flex: 1;
  height: 100%;
  padding-left: 15px;
}
.ipo {
  cursor: pointer;
}
.iinput{
    display:inline-block;
    width: 40px;
}
</style>
<script>
import fake from "@/assets/utils/fake";
import pageable from "@/components/pageable";
export default {
  components: { pageable },
  data() {
    return {
        selectRow:[],
      choose: "",
      value1: 10,
      isLight: false,
      gates: [],
      loading: false,
      lights: [],
      count: 7,
      totalPage: 0,
      start: 0,
      current: "",
      form:{
        a1:0,
        a2:0,a3:"开",
        a4:0,a5:0,a6:0,a7:0,a8:"",a9:"0",a10:"34",a11:"12",a12:"1",a13:"0",a14:"34",a15:"12",a16:"1",a17:"0"
        ,a18:"0",a19:"0",a20:"0",a21:"0",a22:"0",a23:"0",a24:"0"
      }
    };
  },
  mounted() {
    this.gates = fake.getFakeGateways();
    this.commandAction(0);
  },
  methods: {
    select() {
      this.loading = true;
      window.setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    handleSelectionChange(val) {
        this.selectRow=val;
    },
    commandAction(command) {
      this.current = command;
      var ls = fake.getFakeLightDeath();
      var lss = ls.splice(command * 45, 45);
      var i = 0;
      lss.forEach(x => {
        i += 1;
        (x.addr = "57784181DS" + i),
          (x.online = x.state),
          (x.state = 0),
          (x.light = 0),
          (x.color = 0),
          (x.v = this.getRandomIntInclusive2(214, 220)),
          (x.a = this.getRandomIntInclusive2(0.6, 1)),
          (x.y = this.getRandomIntInclusive2(70, 90)),
          (x.g = this.getRandomIntInclusive2(0.8, 1)),
          (x.hz = 50),
          (x.time = "2022-07-07");
      });
      this.lights = lss.splice(this.start, this.count);
      this.totalPage = lss.length;
    },
    getRandomIntInclusive(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值
    },
    getRandomIntInclusive2(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return (Math.random() * (max - min + 1) + min).toFixed(2); //含最大值，含最小值
    },
    currentChange(val) {
      this.start = val * this.count;
      this.commandAction(this.command);
    },
    hidden(){
        this.$refs.lightmodel.hide();
    },
    onSubmit(){
        this.select()
    },
    change(val){
        this.action(val);
    },
    action(light){
        this.select();
        var cs=[];
        if(this.choose=='0'){
            cs=this.lights;
        }else{
            cs=this.selectRow;
        }
        cs.forEach(x=>{
            x.light=light;
            if(light==0){
                x.state=0;
                x.a = this.getRandomIntInclusive2(0.6, 1);
                x.y = this.getRandomIntInclusive2(70, 90)
            }else{
                x.state=1;
                x.a = this.getRandomIntInclusive2(5, 8);
                x.y = this.getRandomIntInclusive2(104, 524);
            }
        })
        
    }
  }
};
</script>