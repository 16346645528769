import Axios from "axios";
const baseUrl = "Https://kingsunsmart.com";
import cookies from "@/assets/utils/kscookie";
import config from "@/assets/utils/config";
export default {
  success: null,
  error: null,
  before: null,
  initCall: function(success, error, before) {
    this.success = success;
    this.error = error;
    this.before = before;
  },
  getInstance: function(method, type, url, baseurl, data, async) {
    url = config.Theme != 0 ? `${baseUrl}${url}` : url;
    var lan = cookies.get("lan");
    var http = Axios.create({  
      method: method,
      headers: {
        "Content-Type": type,
        "Access-Control-Allow-Origin": "*",
        "KS-LAN": lan,
      },
      url: url,
      data: data,
      baseURL: baseurl,
      async: async,
    });
    return http();
  },
  ksGetPromise: function(url) {
    var m = "get";
    var t = "application/json;charset=utf-8";
    return new Promise((res, rej) => {
      this.getInstance(m, t, url, "", "")
        .then((data) => {
          res(data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  ksGetXmlPromise: function(url) {
    var m = "get";
    var t = "application/xml;charset=utf-8";
    return new Promise((res, rej) => {
      this.getInstance(m, t, url, "", "")
        .then((data) => {
          res(data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  ksPostPromise: function(url, data, token, nasync) {
    var custom = cookies.get("ksCustom");
    var m = "post";
    var t = "application/json;charset=utf-8";
    return new Promise((resolve, rejects) => {
      var obj = data;
      if (token) {
        let t = cookies.get("token");
        if (!t || t.lenth == 0 || t == "null") {
          window.location.href =
            custom == "true" ? "/#/info/home" : "/#/info/login";
        }
        obj = {
          Token: t,
          Data: data,
        };
      }
      this.getInstance(m, t, url, "", obj, !nasync)
        .then((success) => {
          if (success.data.RetCode !== 0) {
            if (success.data.RetCode === 1008) {
              window.location.href =
                custom == "true" ? "/#/info/home" : "/#/info/login";
            } else {
              rejects(success.data.RetMsg + ":" + success.data.Data);
            }
          } else {
            resolve(success.data.Data);
          }
        })
        .catch((err) => {
          rejects(err.message);
        });
    });
  },
  ksPostData: function(url, data, token, success, error, isInit, nasync) {
    var custom = cookies.get("ksCustom");
    var m = "post";
    var t = "application/json;charset=utf-8";
    return new Promise(() => {
      var self = this;
      var baseUrl = "";
      if (self.before && !isInit) {
        self.before();
      }
      var obj = data;
      if (token) {
        let t = cookies.get("token");
        if (!t || t.lenth == 0 || t == "null") {
          window.location.href =
            custom == "true" ? "/#/info/home" : "/#/info/login";
        }
        obj = {
          Token: t,
          Data: data,
        };
      }
      self
        .getInstance(m, t, url, baseUrl, obj, !nasync)
        .then(function(data) {
          if (data.data.RetCode === 0) {
            if (success) {
              success(data.data.Data);
            }
            if (self.success && !isInit) {
              self.success(data.data.Data);
            }
          } else if (data.data.RetCode === 1008) {
            window.location.href =
              custom == "true" ? "/#/info/home" : "/#/info/login";
          } else {
            if (error) {
              error(data.data);
            }
            if (self.error && !isInit) {
              self.error(data.data);
            }
          }
        })
        .catch(function(data) {
          if (error) {
            error(data);
          }
          if (self.error && !isInit) {
            self.error(data);
          }
        });
    });
  },

  getExportInstance: function(method, type, url, baseurl, data, async) {
    url = config.Theme != 0 ? `${baseUrl}${url}` : url;
    var lan = cookies.get("lan");
    var http = Axios.create({
      method: method,
      headers: {
        "Content-Type": type,
        "Access-Control-Allow-Origin": "*",
        "KS-LAN": lan,
      },
      url: url,
      data: data,
      responseType: "blob",
      baseURL: baseurl,
      async: async,
    });
    return http();
  },

  ksPostExport: function(url, data, token, nasync) {
    var custom = cookies.get("ksCustom");
    var m = "post";
    var t = "application/json;charset=utf-8";
    return new Promise((resolve, rejects) => {
      var obj = data;
      if (token) {
        let t = cookies.get("token");
        if (!t || t.lenth == 0 || t == "null") {
          window.location.href =
            custom == "true" ? "/#/info/home" : "/#/info/login";
        }
        obj = {
          Token: t,
          Data: data,
        };
      }
      this.getExportInstance(m, t, url, "", obj, !nasync)
        .then((success) => {
          console.log("success:", success.headers);
          if (success.headers["content-type"].includes("json")) {
            const { data } = success;
            const reader = new FileReader();
            reader.onload = () => {
              const { result } = reader;
              const errorInfos = JSON.parse(result);
              console.log("errorInfos:", errorInfos);

              if (success.data.RetCode === 1008) {
                window.location.href =
                  custom == "true" ? "/#/info/home" : "/#/info/login";
              } else {
                rejects(errorInfos.RetMsg + ":" + errorInfos.Data);
              }
            };
            reader.onerror = (err) => {
              rejects(err);
            };
            reader.readAsText(data);
          } else {
            console.log("二进制流");
            var blob = new Blob([success.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            console.log("blob:", blob);
            var file = decodeURI(success.headers["content-disposition"]);
            var fileName = file.split("''")[1];
            console.log("fileName:", decodeURI(fileName));
            if (navigator.msSaveBlob) {
              console.log("msSaveBlob");
              navigator.msSaveBlob(blob, fileName);
              resolve(fileName);
            } else {
              var eleLink = document.createElement("a");
              eleLink.download = fileName;
              eleLink.style.display = "none";
              eleLink.href = URL.createObjectURL(blob);
              document.body.appendChild(eleLink);
              const clickEvent = document.createEvent("MouseEvent");
              clickEvent.initEvent("click", false, false);
              eleLink.dispatchEvent(clickEvent);
              URL.revokeObjectURL(eleLink.href);
              document.body.removeChild(eleLink);
              resolve(fileName);
            }
          }
        })
        .catch((err) => {
          rejects(err.message);
        });
    });
  },
};
