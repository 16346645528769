<template>
  <div class="main">
    <b-navbar id="home_menu" toggleable="md" type="dark">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-navbar-brand to="/info/login">
        <b-img :src="$store.state.project.Logo" style="height: 35px"></b-img>
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse">
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item>
            <router-link to="/admin/setting">
              <i
                style="font-size: 20px; color: white"
                class="fa fa-cog fa-fw"
              ></i>
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/admin/alarm">
              <i
                style="font-size: 20px; color: white"
                class="fa fa-bell-o fa-fw"
              ></i>
            </router-link>
          </b-nav-item>

          <b-nav-item-dropdown right>
            <template slot="button-content">
              <em>{{ userName }}</em>
            </template>
            <b-dropdown-item to="/admin/setting/password">{{
              $t("修改密码")
            }}</b-dropdown-item>
            <b-dropdown-item @click="logout">{{
              $t("安全退出")
            }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <el-radio-group
            v-model="lang"
            size="mini"
            fill="black"
            style="margin-right: 20px"
            @change="lanChange"
          >
            <el-radio-button value="cn" label="zh"></el-radio-button>
            <el-radio-button value="en" label="en"></el-radio-button>
          </el-radio-group>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <!-- <div  style="margin-top:3%;margin-right:10%;margin-left:10%">
                <div v-for="item in menu" :key="item.Sid"  :class="['main_block',item.class]" @click="to(item.Path)" >
                    <div class="main_block_back main_block_back_flex">
                        <div style="width:100%;text-align:center"> 
                            <b-img :src="item.Pic" fluid></b-img>
                            </div>
                        <div class="main_block_text" style="">{{item.Name}}</div>
                    </div>
                </div>
    </div>-->
    <b-row class="ksrow" style="color: white; margin: 2% 5% 0 5%">
      <b-col
        :cols="item.Sid != '048' ? 6 : 12"
        :md="item.Sid != '048' ? 3 : 12"
        :xl="item.Sid == '048' ? 4 : 2"
        class="m_block"
        v-for="item in menu"
        :key="item.Sid"
        @click="to(item.Path)"
      >
        <div class="main_block_back main_block_back_flex">
          <div style="width: 100%; text-align: center; margin-bottom: 10px">
            <b-img :src="item.Pic" fluid></b-img>
          </div>
          <div class style="font-size: 20px">{{ item.Name }}</div>
        </div>
      </b-col>
    </b-row>
  </div>
</template> 
<style>
.m_block {
  padding: 0px;
  margin: 0px;
}
.m_block.col-xl-2:after {
  content: "";
  display: block;
  margin-top: 100%;
}
@media (max-width: 1140px) {
  .m_block.col-xl-4:after {
    content: "";
    display: block;
    margin-top: 25%;
  }
}
@media (max-width: 960px) {
  .m_block.col-xl-4:after {
    content: "";
    display: block;
    margin-top: 50%;
  }
}
@media (min-width: 1140px) {
  .m_block.col-xl-4:after {
    content: "";
    display: block;
    margin-top: 50%;
  }
}

.w16 img {
  max-width: 50%;
}
.w20 img {
  max-width: 50%;
}
.w33 img {
  max-width: 33%;
}
.w40 img {
  max-width: 25%;
}
.w60 img {
  max-width: 16%;
}
.w100 img {
  max-width: 10%;
}
.main_block_back_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>

<script>
export default {
  data: function () {
    return {
      logopic: require("../assets/img/cute/logo.png"),
      lang: "zh",
      menu: [],
      userName: this.$cookies.get("username"),
    };
  },
  mounted: function () {
    this.init();
    this.lang = this.$cookies.get("lan");
  },
  methods: {
    init() {
      var m = this.$store.getters.getSonMenu("0");
      var data = [];
      var link = m.find((x) => x.Sid == "048");
      link.class = "w33";
      for (var i = 0; i < m.length; i++) {
        if (i == 8) {
          data.push(link);
        }
        if (m[i].Sid != "048") {
          m[i].class = "w16";
          data.push(m[i]);
        }
      }
      this.menu = data;
    },
    to(path) {
      if (path.indexOf("http") != -1) {
        if (path.indexOf("light") != -1) {
          this.$ksajax
            .ksPostPromise("/api/smart/SetKSLight", "", true)
            .then((data) => {
              window.open(`${path}?ddl=${data}`)
            })
            .catch((err) => {
              this.$throw(err);
            });
        } else 
        if (path.indexOf("tunnel") != -1) {
          this.$ksajax
            .ksPostPromise("/api/smart/SetKSTunnel", "", true)
            .then((data) => {
              window.open(`${path}?ddl=${data}`)
            })
            .catch((err) => {
              this.$throw(err);
            });
        } 
        else {
          window.location.href = path;
        }
      } else {
        this.$router.push({ path: path });
      }
    },
    logout() {
      //删除cookie
      this.$cookies.set("token", null, 0);
      this.$router.push({ path: "/info/login?reload=true" });
    },
    lanChange() {
      //先切换语言
      this.$i18n.locale = this.lang;
      this.$cookies.set("lan", this.$i18n.locale, 365);
      this.$store.dispatch(
        "menuAction",
        () => {
          this.init();
        },
        (err) => {
          this.$throw(err);
        }
      );
    },
  },
};
</script>
