<template>
    <div style="width:100%;height:100%">
        <div style="color:white;font-size:12px">{{desc}}</div>
        <img :src="pic" style="width:100%;height:auto;max-height:100%"/>
    </div>
</template>
<script>
import {getLawByCode} from "@/assets/utils/hikUtil.js"
export default {
    props:["objvalue"],
    data(){
        return{
            desc:"",
            pic:""
        }
    },
    watch:{
        objvalue:{
            immediate:true,
            deep:true,
            handler(){
                console.log(this.objvalue,"car");
                if(this.objvalue.Data.Law.illegalType==0){
                this.desc=`${this.objvalue.Data.Car.license}正常通行`;
                }else{
                    this.desc=`${this.objvalue.Data.Car.license}有${getLawByCode(this.objvalue.Data.Law.illegalType)}的违法行为`;
                }
                this.pic=JSON.parse(this.objvalue.Data.Law.pics)[0]
            }
        }
    }
}
</script>