<template>
  <div v-loading="loading" style="width:100%;height:100%">
    <div style="padding-top:10px">
      <el-button type="primary" @click="open">开屏</el-button>
      <el-button type="success" @click="close">关屏</el-button
      ><el-button type="danger" @click="restart">重启</el-button>
    </div>
    <div class="block" style="font-size:12px;color:white;padding-top:10px">
      <span class="demonstration">屏幕亮度调节</span>
      <el-slider v-model="light" style="width:80%;padding-top:10px"  @change="lightChange"></el-slider>
    </div>
  </div>
</template>
<script>
export default {
  props: ["sid"],
  data() {
    return {
      light: 0,
      loading: false,
      controlData: { option: 0, playerIds: [], value: "" },
      type:"",
      serial:""
    };
  },
  watch:{
    sid:{
      deep:true,
      handler(){
        console.log(this.sid,"sid");
        this.init();
      },
      immediate:true
    }
  },
  methods: {
    init(){console.log(this.sid,"sid test");
      this.loading=true;
      this.$ksajax
        .ksPostPromise("/api/oview/GetDeviceSerial", this.sid, true).then(data=>{
          this.type=data.DTypeSid;
          this.serial=data.Serial;
          this.loading=false;
          console.log(this.type,"type");
        }).catch(err=>{
          this.loading=false;
          this.$throw(err);
        })
      
    },
    novaOpen() {
      this.novaCtrl(1, 4);
    },
    novaClose() {
      this.novaCtrl(0, 4);
    },
    novaRestart() {
      this.novaCtrl(0, 0);
    },
    novaLight() {
      this.novaCtrl(this.light, 3);
    },

    xixunOpen() {
      this.xixunCtrl(0, true);
    },
    xixunClose() {
      this.xixunCtrl(0, false);
    },
    xixunRestart() {
      this.xixunCtrl(8, 1);
    },
    xixunLight() {
      this.xixunCtrl(2, this.light);
    },
    close() {
      this.type == "0601" ? this.xixunClose() : this.novaClose();
    },
    open() {
      this.type == "0601" ? this.xixunOpen() : this.novaOpen();
    },
    restart() {
      this.type == "0601" ? this.xixunRestart() : this.novaRestart();
    },
    lightChange() {
      this.type == "0601" ? this.xixunLight() : this.novaLight();
    },
    novaCtrl(value, option) {
      this.loading = true;
      this.controlData.playerIds = [this.serial];
      this.controlData.value = value;
      this.controlData.option = option;
      this.$ksajax
        .ksPostPromise("/api/led/NovaLedControl", this.controlData, true)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
    xixunCtrl(fun, value) {
      var obj = {
        Sid: this.sid,
        Fun: fun,
        Value: value,
      };
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/led/CallLed", obj, true)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
  },
};
</script>