<template>
  <div id="play" v-loading="loading">
    <div v-show="!this.ie" id="player" style="width: 100%; height: 100%"></div>

    <video
      v-show="this.ie"
      id="rtmpPlayer"
      controls
      playsInline
      webkit-playsinline
      autoplay
      style="width: 100%; height: 100%"
    >
      <source :src="source" type="rtmp/flv" />
    </video>
    <div
      id="playerOver"
      @click="overClick"
      :style="`visibility:${edit ? 'visible' : 'hidden'};`"
    ></div>
    <pointer
      v-for="item in oviewDevices"
      :key="item.Sid"
      :vdata="item"
      :parent="parent"
      v-on:delete="del"
      v-on:hclick="hclick"
    ></pointer>
    <div v-if="!edit">
      <div
        class="oview-alarm ksflexcol"
        :style="alarmStyle"
        v-show="vdata.show"
      >
        <div style="text-align: right; width: 100%" class="ksflex">
          <div
            style="
              padding-left: 5px;
              text-align: left;
              color: white;
              font-size: 12px;
              flex: 1;
            "
          >
            {{ title }}
          </div>
          <div style="text-align: right">
            <el-button
              type="success"
              @click="itemClick"
              style="height: 25px; font-size: 12px; line-height: 5px"
              >完成</el-button
            >
          </div>
        </div>
        <hr style="width: 100%; height: 1px" />
        <div style="flex: 1; width: 100%">
          <alarm :vdata="vdata"></alarm>
        </div>
      </div>
    </div>
    <div
      id="oview-video"
      v-loading="loadingVideo"
      @dragstart="videoDragStart"
      @dragend="videoDragEnd"
      @drag="videoDrag"
      draggable
      :style="postionStyle"
    >
      <netvideo
        ref="netplay"
        :showRight="false"
        :playdata="playdata"
      ></netvideo>
    </div>
    <div
      class="oview-alarm"
      @dragstart="alarmDragStart"
      @dragend="videoDragEnd"
      @drag="alarmDrag"
      draggable
      :style="alarmStyle"
      v-loading="loadingAlarm"
      v-show="alarmShow"
    >
      <div style="text-align: right">
        <i
          class="el-icon-close"
          style="cursor: pointer; color: white"
          @click="alarmShow = false"
        ></i>
      </div>
      <div v-if="alarmType == -1">报警消息窗口</div>
    </div>
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      title="新增关联设备"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('设备类型')" :label-cols="2">
          <b-form-select
            @change="change"
            :options="types"
            v-model="currentType"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group v-bind:label="$t('设备')" :label-cols="2">
          <b-form-select
            :options="devices"
            v-model="modalData.DeviceSid"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group v-bind:label="$t('预置点')" :label-cols="2">
          <b-form-select
            :options="persets"
            v-model="modalData.PresetSid"
            required
            value-field="Sid"
            text-field="Name"
          ></b-form-select>
        </b-form-group>
        <b-form-group v-bind:label="$t('标注方向')" :label-cols="2">
          <b-form-select v-model="modalData.Direct">
            <b-form-select-option :value="0">左向</b-form-select-option>
            <b-form-select-option :value="1">右向</b-form-select-option>
            <b-form-select-option :value="2">上向</b-form-select-option>
            <b-form-select-option :value="3">下向</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group v-bind:label="$t('仅局域网视频')" :label-cols="2">
          <b-form-select v-model="modalData.OnlyNat">
            <b-form-select-option :value="true">是</b-form-select-option>
            <b-form-select-option :value="false">否</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width: 100px" v-on:click="hiden">Cancel</b-button>
            <b-button type="submit" style="width: 100px" variant="primary"
              >OK</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<style scoped>
#play {
  position: relative;
  width: 100%;
  height: 100%;
}
#playerOver {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}
#oview-video {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 350px;
  height: 250px;
  z-index: 5;
}
.oview-alarm {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 250px;
  height: 200px;
  background-color: #133a67;
  border: 1px solid #3197fa;
  z-index: 6;
}
.loading-item {
  z-index: 9800;
}
</style>
<script>
import netvideo from "../../../components/netvideo.vue";
import pointer from "./components/pointer.vue";
import alarm from "./components/alarm.vue";
export default {
  components: { pointer, netvideo, alarm },
  data() {
    return {
      title: "",
      vdata: {
        deviceSid: "",
        objectValue: {},
        stringValue: "",
        CtgSid: "",
        show: false,
      },
      baseIndex: 6,
      loadingAlarm: false,
      alarmShow: false,
      alarmType: -1,
      source: "",
      ie: this.isIe(),
      playdata: {},
      loadingVideo: false,
      edit: false,
      sid: "",
      loading: false,
      player: null,
      persets: [],
      devices: [],
      detail: {},
      currentType: null,
      types: [],
      modalData: {
        DeviceSid: "",
        OViewSid: "",
        WidthPersent: 0,
        HeightPersent: 0,
        PresetSid: "",
        Direct: 0,
        OnlyNat: false,
      },
      oviewDevices: [],
      parent: {
        width: 0,
        height: 0,
      },
      dragData: {
        Type: 0,
        Style: "",
        Sid: "",
      },
      postionStyle: {},
      alarmStyle: {},
    };
  },
  watch: {
    "$store.state.alarmHubChange": function () {
      var data = this.$store.state.alarmHub;
      //找到设备
      var exsit = this.oviewDevices.find((x) => x.DeviceSid == data.DeviceSid);
      if (exsit) {
        var showDialog = true;
        var showTip = true;
        this.vdata.CtgSid = data.CtgSid;
        this.vdata.deviceSid = data.DeviceSid;
        this.vdata.Random = Math.random();
        this.vdata.objectValue = data;
        exsit.Alarm.Content = data.Desc;
        this.title = exsit.DeviceName;
        if (data.CtgSid == "016") {
          this.vdata.stringValue = data.Data.Content;
        }
        if (data.Type == 83) {
          //水位低处理
          showTip = true;
          if (exsit.newest && exsit.newest.Type == 83) {
            showTip = false;
          }
          if (this.vdata.CtgSid != 83) {
            showDialog = true;
          } else {
            showDialog = false;
          }
        }
        var ts = [44, 86, 24, 26];
        if (ts.find((x) => x == data.Type)) {
          //数据恢复处理
          showTip = false;
          if (ts.find((x) => x == this.vdata.CtgSid)) {
            showDialog = false;
          }
        }
        exsit.newest = data;
        exsit.Alarm.Show = showTip;
        this.vdata.show = showDialog;
      }
    },
  },
  mounted() {
    this.edit = this.$route.query.endit;
    if (this.edit) {
      this.alarmShow = true;
    }
    this.sid = this.$route.query.sid;
    this.$nextTick(() => {
      this.parent.width = document.getElementById("playerOver").clientWidth;
      this.parent.height = document.getElementById("playerOver").clientHeight;
    });
    this.select();
  },
  methods: {
    itemClick() {
      var exsit = this.oviewDevices.find(
        (x) => x.DeviceSid == this.vdata.deviceSid
      );
      if (exsit) {
        exsit.Alarm.Show = false;
        this.vdata.show = false;
      }
    },
    hclick(data) {
      var vs = ["001", "015"];
      var exsit = this.oviewDevices.find((x) => x.Sid == data.Sid);
      if (this.edit) {
        return;
      }
      if (!vs.find((x) => x == data.DCategorySid)) {
        this.playVideo(this.detail.VideoSid);
        var obj = {
          Index: data.PresetIndex,
          VideoSid: this.detail.VideoSid,
        };
        this.$ksajax.ksPostPromise("/api/device/CallVideoPreset", obj, true);
      } else {
        if (!exsit.OnlyNat) {
          this.playVideo(data.DeviceSid);
        } else {
          this.playVideo(this.detail.VideoSid);
          var obj1 = {
            Index: data.PresetIndex,
            VideoSid: this.detail.VideoSid,
          };
          this.$ksajax.ksPostPromise("/api/device/CallVideoPreset", obj1, true);
        }
      }

      this.vdata.CtgSid = data.DCategorySid;
      this.vdata.deviceSid = data.DeviceSid;
      var ds = [
        "011",
        "010",
        "009",
        "005",
        "099",
        "012",
        "004",
        "006",
        "015",
        "016",
      ];
      if (exsit) {
        this.title = exsit.DeviceName;
      }
      if (exsit) {
        exsit.Alarm.Show = true;
        exsit.Alarm.Content = null;
        if (this.vdata.newest) {
          this.vdata.objectValue = this.vdata.newest;
        } else {
          this.vdata.objectValue = null;
        }
        this.vdata.Random = Math.random();
        if (ds.find((x) => x == data.DCategorySid)) {
          this.vdata.show = true;
          if (data.DCategorySid == "015") {
            this.vdata.stringValue = exsit.Alarm.Content || "当前设备正常";
          }
        } else if (data.DCategorySid == "001" || data.DCategorySid == "200") {
          if (exsit.newest) {
            this.vdata.show = true;
            this.vdata.objectValue = exsit.newest;
          } else {
            this.vdata.show = false;
            exsit.Alarm.Show = false;
          }
        } else if (data.DCategorySid == "016") {
          this.vdata.show = true;
          if (exsit.newest) {
            this.vdata.show = true;
            this.vdata.stringValue = exsit.newest.Data;
          } else {
            this.vdata.show = false;
            exsit.Alarm.Show = false;
          }
        } else {
          exsit.Alarm.Show = false;
          exsit.Alarm.Content = null;
          this.vdata.show = false;
        }
      }
    },
    videoDragStart() {
      this.dragData.Type = 0;
    },
    alarmDragStart() {
      this.dragData.Type = 1;
    },
    videoDragEnd() {
      this.dragData.Sid = this.detail.Sid;
      this.dragData.Style =
        this.dragData.Type == 0
          ? JSON.stringify(this.postionStyle)
          : JSON.stringify(this.alarmStyle);
      this.$ksajax.ksPostPromise(
        "/api/oview/UpdatePostion",
        this.dragData,
        true
      );
    },
    videoDrag(e) {
      if (e.pageX != 0 && e.pageY != 0) {
        var offset =
          document.body.clientHeight -
          document.getElementById("play").clientHeight;
        this.postionStyle = {};
        this.postionStyle.top =
          ((e.pageY - offset) / document.getElementById("play").clientHeight) *
            100 +
          "%";
        this.postionStyle.left =
          (e.pageX / document.getElementById("play").clientWidth) * 100 + "%";
      }
    },
    alarmDrag(e) {
      if (e.pageX != 0 && e.pageY != 0) {
        var offset =
          document.body.clientHeight -
          document.getElementById("play").clientHeight;
        this.alarmStyle = {};
        var top =
          ((e.pageY - offset) / document.getElementById("play").clientHeight) *
          100;
        top = top < 0 ? 0 : top;
        var left =
          (e.pageX / document.getElementById("play").clientWidth) * 100;
        left = left < 0 ? 0 : left;
        this.alarmStyle.top = top + "%";
        this.alarmStyle.left = left + "%";
      }
    },
    isIe: function () {
      if (!!window.ActiveXObject || "ActiveXObject" in window) {
        return true;
      } else {
        return false;
      }
    },
    del(sid) {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/OView/DelOviewDevice", sid, true)
        .then(() => {
          this.loading = false;
          this.select(true);
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
    select(noPlay) {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/OView/GetOviewDetail", this.sid, true)
        .then((data) => {
          if (data.Postion) this.postionStyle = JSON.parse(data.Postion);
          if (data.VideoPostion)
            this.alarmStyle = JSON.parse(data.VideoPostion);
          this.oviewDevices = [];
          data.Items.forEach((x) => {
            x.Alarm = {
              Show: false,
              Content: "",
              Data: {},
              Style: {
                left: this.alarmStyle.left,
                top: this.alarmStyle.top,
                "z-index": this.baseIndex,
              },
            };
            x.vdata = {
              CtgSid: x.DCategorySid,
              DeviceSid: x.DeviceSid,
              Random: Math.random(),
            };
            x.rand = Math.random();
          });
          this.oviewDevices = data.Items;
          this.detail = data;
          this.loading = false;
          if (!noPlay) {
            if (this.ie) {
              this.source = data.RTSPUrl;
              this.$nextTick(() => {
                new window.EZUIKit.EZUIPlayer("rtmpPlayer");
              });
            } else {
              this.getPlayNet(
                data.Sid,
                data.Chanel,
                data.Definition == 0 ? "sd" : "hd"
              );
            }
            if (!this.edit) {
              this.playVideo(this.detail.VideoSid);
            }
          }
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
    getPlayReqData(sid, hd, chanel) {
      return {
        EzopenType: 0,
        Channel: chanel,
        Definition: hd,
        PlaybackSource: 0,
        Sid: sid,
      };
    },
    playVideo(sid) {
      if (this.playdata.device && this.playdata.device.Sid == sid) {
        return;
      }
      this.loadingVideo = true;
      var req = this.getPlayReqData(sid, "sd", 1);
      var url = "/api/device/GetPayNet";
      this.$ksajax
        .ksPostPromise(url, req, true)
        .then((success) => {
          this.loadingVideo = false;
          this.playdata.data = success;
          this.playdata.isPlay = true;
          this.playdata.device = {
            Sid: sid,
            DeviceName: success.DeviceName,
            serial: success.Serial,
          };
          this.$refs.netplay.start();
        })
        .catch((err) => {
          this.loadingVideo = false;
          this.$throw(err);
        });
    },
    getPlayNet(sid, chanel, def) {
      var req = this.getPlayReqData(sid, def, chanel);
      var url = "/api/device/GetPayNet";
      this.$ksajax
        .ksPostPromise(url, req, true)
        .then((data) => {
          this.play(data.AccessToken, data.Url);
        })
        .catch((err) => {
          this.$throw(err);
        });
    },
    async getOviewDevice() {
      this.oviewDevices = await this.$ksajax.ksPostPromise(
        "/api/OView/GetOviewDevice",
        this.sid,
        true
      );
    },
    play(accessToken, url) {
      this.player = new window.EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: "player",
        accessToken: accessToken,
        url: url,
        template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        // header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
        openSoundCallBack: (data) => console.log("开启声音回调", data),
        closeSoundCallBack: (data) => console.log("关闭声音回调", data),
        startSaveCallBack: (data) => console.log("开始录像回调", data),
        stopSaveCallBack: (data) => console.log("录像回调", data),
        capturePictureCallBack: (data) => console.log("截图成功回调", data),
        fullScreenCallBack: (data) => console.log("全屏回调", data),
        getOSDTimeCallBack: (data) => console.log("获取OSDTime回调", data),
        width: document.getElementById("player").clientWidth,
        height: document.getElementById("player").clientHeight,
      });
    },
    async onSubmit(evt) {
      this.loading = true;
      evt.preventDefault();
      this.$ksajax
        .ksPostPromise("/api/OView/SetOviewDevice", this.modalData, true)
        .catch((err) => {
          this.$throw(err);
        });
      this.loading = false;
      this.hiden();
      this.select(true);
    },
    hiden() {
      this.$refs.modallg.hide();
    },
    change() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/device/GetDeviceByCType", this.currentType, true)
        .then((data) => {
          this.devices = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    async overClick(e) {
      if (!this.edit) {
        return;
      }
      this.loading = true;
      if (this.persets.length == 0) {
        this.persets = await this.$ksajax.ksPostPromise(
          "/api/device/GetVideoPresets",
          this.detail.VideoSid,
          true
        );
      }
      if (this.types.length == 0) {
        this.types = await this.$ksajax.ksPostPromise(
          "/api/device/GetCType",
          "",
          true
        );
      }
      if (!this.currentType) {
        this.currentType = this.types[0].value;
      }
      this.devices = await this.$ksajax.ksPostPromise(
        "/api/device/GetDeviceByCType",
        this.currentType,
        true
      );
      this.modalData.OViewSid = this.sid;
      this.modalData.WidthPersent =
        document.getElementById("playerOver").clientWidth / e.offsetX;
      this.modalData.HeightPersent =
        document.getElementById("playerOver").clientHeight / e.offsetY;
      this.$refs.modallg.show();
      this.loading = false;
    },
  },
};
</script>