<template>
  <div id="broaddetail" class="clearfix">
     <div id="todetail">
           <router-link :to="`/admin/stand/commondevice?sid=${$route.query.sid}`">详情</router-link>
    </div>
    <div id="broaddetail_info" class="floatleft padding5">
      <boxtitle :data="infoTitle">
        <div
          style="width:100%;height:100%;text-align:center"
          class="ksflexcol"
          v-loading="loadingInfo"
        >
          <div class="row ksrow flex1">
            <div class="col">{{$t('名称')}}</div>
            <div class="col">{{current.DeviceName}}</div>
          </div>
          <div class="row ksrow flex1">
            <div class="col">{{$t('序号')}}</div>
            <div class="col">{{current.Serial}}</div>
          </div>
          <div class="row ksrow flex1">
            <div class="col">{{$t('型号')}}</div>
            <div class="col">{{current?current.DeviceType.ModelNumber:""}}</div>
          </div>
          <div class="row ksrow flex1">
            <div class="col">{{$t('IP地址')}}</div>
            <div class="col">{{current.IP}}</div>
          </div>
          <div class="row ksrow flex1">
            <div class="col">{{$t('状态')}}</div>
            <div
              class="col"
            >{{current.Status==-1?this.$t("离线"):current.Status==0?this.$t("在线"):this.$t("工作中")}}</div>
          </div>
          <div class="row ksrow flex1">
            <div class="col">{{$t('音量')}}</div>
            <div class="col">{{current.Volume}}</div>
          </div>
        </div>
      </boxtitle>
    </div>
    <div id="broaddetail_text" class="floatleft padding5" v-loading="loadingText">
      <boxtitle :data="textTitle" v-on:titleClick="playText">
        <div style="padding:5px" class="row ksrow">
          <div class="col">{{$t('播放次数')}}:
            <el-input type="number" v-model="count" style="width:150px"/>
          </div>
          <div class="col">{{$t('播放音量')}}:
            <el-select v-model="volModel" :placeholder="$t('请选择音量')">
              <ElOption
                v-for="item in volOpt"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></ElOption>
            </el-select>
          </div>
        </div>
        <b-textarea v-model="textContent" style="height:80%" :placeholder="$t('不支持暂停')"></b-textarea>
      </boxtitle>
    </div>
    <div id="broaddetail_timmer" class="floatleft padding5">
      <boxtitle :data="timmerTitle" v-loading="loadingBroad">
        <el-table stripe=""
          :data="broads"
          class="demo-table"
          highlight-current-row :empty-text="$t('暂无数据')"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item :label="$t('定时终端')">
                  <div
                    v-for="item in $store.getters.getBroadById(scope.row.Tids)"
                    :key="item.value"
                  >{{ item.text}}</div>
                </el-form-item>
                <el-form-item :label="$t('播放文件')">
                  <div
                    v-for="item in $store.getters.getMediaById(scope.row.ProgIds)"
                    :key="item.value"
                  >{{item.text}}.mp3</div>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column :label="$t('序号')" prop="ID"></el-table-column>
          <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
          <el-table-column :label="$t('类型')">
            <template slot-scope="scope">
              <!-- <span v-if='!it.subvalue'>{{scope.row[it.value]}}</span> -->
              <span v-if="scope.row.Type=='4'">{{$t("一次性任务")}}</span>
              <span v-if="scope.row.Type=='3'">{{$t("每月任务")}}</span>
              <span v-if="scope.row.Type=='2'">{{$t("每周任务")}}</span>
              <span v-if="scope.row.Type=='1'">{{$t("每天任务")}}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('开始时间')" prop="StartTime"></el-table-column>
          <el-table-column :label="$t('播放模式')">
            <template slot-scope="scope">
              <span v-if="scope.row.PlayMode=='0'">{{$t("顺序播放")}}</span>
              <span v-if="scope.row.PlayMode=='1'">{{$t("随机播放")}}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('播放音量')" prop="PlayVol"></el-table-column>
          <el-table-column :label="$t('启用状态')">
            <template slot-scope="scope">
              <span v-if="scope.row.Enable=='0'">{{$t("冻结")}}</span>
              <span v-if="scope.row.Enable=='1'">{{$t("启用")}}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('播放状态')">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.Status=='0'"
                type="primary"
                circle
                size="small"
                @click="play(scope.row.ID,true)"
              >
                <i class="fa fa-play"></i>
              </el-button>
              <el-button
                v-if="scope.row.Status=='1'"
                type="primary"
                circle
                size="small"
                @click="play(scope.row.ID,false)"
              >
                <i class="fa fa-stop"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </boxtitle>
    </div>
  </div>
</template>
<style>
#broaddetail {
  width: 100%;
  height: 100%;
  color: white;
}
#broaddetail_info,
#broaddetail_text {
  width: 50%;
  height: 50%;
}
#broaddetail_timmer {
  width: 100%;
  height: 50%;
}
@media (max-width: 1200px){
    #broaddetail_info,
    #broaddetail_text {
    width: 100%;
    height: 100%;
    }
    #broaddetail_timmer {
    width: 100%;
    height: 100%;
    }
}
</style>

<script>
import boxtitle from "@/components/boxtitle.vue";
import itc from "@/assets/utils/itchttp.js";
import { formatDate } from "@/assets/utils/datef";
export default {
  components: { boxtitle },
  data: function() {
    return {
      infoTitle: {
        title: this.$t("设备信息")
      },
      textTitle: {
        title: this.$t("播放实时文本"),
        action: this.$t("播放"),
        style: "fa fa-play"
      },
      timmerTitle: {
        title: this.$t("定时节目")
      },
      loadingInfo: false,
      current: {
        DeviceType: {},
        Status: 0
      },
      textContent: "",
      count: 1,
      volModel: 20,
      volOpt: [
        { value: 10, label: this.$t("安静") },
        { value: 20, label: this.$t("普通") },
        { value: 35, label: this.$t("大声") },
        { value: 56, label: this.$t("超大声") }
      ],
      loadingText: false,
      loadingBroad: false,
      broads: []
    };
  },
  mounted: function() {
    this.select();
  },
  methods: {
       play(id,isplay){
            let obj={
                TaskID:id
            };
            this.loadingBroad=true;
            let meth=isplay?"TaskManualStart":"TaskManualStop"
            itc.postData(this.$store.state.broadcast.url,meth,obj,this.$store.state.broadcast.session)
            .then(()=>{
                for(var i=0;i<this.broads.length;i++){
                    if(this.broads[i].ID===id){
                        this.broads[i].Status=isplay?1:0;
                    }
                }
            this.loadingBroad=false;
            }).catch((error)=>{
                this.$throw(error);
            this.loadingBroad=false;
            })
        },
    playText() {
      if (this.textContent.length == 0) {
        this.$throw("请输入播放文本");
      } else {
        var c = parseInt(this.count);
        if (!c || c <= 0) {
          throw "次数必需大于0";
        }
        this.loadingText = true;
        var obj = {
          Content: this.textContent,
          TargetIds: [parseInt(this.current.Serial)],
          TargetType: 1,
          Time: formatDate(new Date(), "yyyy/MM/dd hh:mm:ss"),
          Playtime: c,
          PlayPrior: 0,
          Volume: this.volModel,
          PlayInterval: 10
        };
        itc
          .postData(
            this.$store.state.broadcast.url,
            "TextPlay",
            obj,
            this.$store.state.broadcast.session
          )
          .then(() => {
            this.$throw("播放成功");
            this.loadingText = false;
          })
          .catch(err => {
            this.$throw(err);
            this.loadingText = false;
          });
      }
    },

    getTimmer() {
      this.loadingBroad = true;
      let obj = { TaskIDs: [] };
      itc
        .postData(
          this.$store.state.broadcast.url,
          "TaskList",
          obj,
          this.$store.state.broadcast.session
        )
        .then(data => {
          this.loadingBroad = false;
          this.broads = [];
          data.Items.forEach(x => {
            if (x.Tids) {
              var obj = x.Tids.find(y => y == this.current.Serial);
              if (obj && x.Enable == 1) {
                this.broads.push(x);
              }
            }
          });
        })
        .catch(err => {
          this.loadingBroad = false;
          this.$throw(err);
        });
    },
    select() {
      this.loadingInfo = true;
      this.$ksajax
        .ksPostPromise(
          "/api/device/GetBroadDetail",
          this.$route.query.sid,
          true
        )
        .then(data => {
          this.current = data;
          this.loadingInfo = false;
          this.getTimmer();
        })
        .catch(err => {
          this.$throw(err);
          this.loadingInfo = false;
        });
    }
  }
};
</script>
