export const color1= new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
    { offset: 0, color: "#2978c9" },
    { offset: 0.5, color: "#00b2e4" },
    { offset: 1, color: "#00e7fd" },
  ])
  export const  color2=new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
    { offset: 0, color: "#ffad42" },
    { offset: 0.5, color: "#ffc54b" },
    { offset: 1, color: "#ffd450" },
  ])
  export const  color3= new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
    { offset: 0, color: "#f7500f" },
    { offset: 0.5, color: "#eec54b" },
    { offset: 1, color: "#f7500f" },
  ])
  export const  color4= new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
    { offset: 0, color: "#f7510f" },
    { offset: 0.5, color: "#ee154b" },
    { offset: 1, color: "#f75020" },
  ])
  export const  color5= new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
    { offset: 0, color: "#6BC416" },
    { offset: 0.5, color: "#119857" },
    { offset: 1, color: "#066336" },
  ])
  export const  color6= new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
    { offset: 0, color: "#4A89CC" },
    { offset: 0.5, color: "#175698" },
    { offset: 1, color: "#073463" },
  ])
  export const  color7= new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
    { offset: 0, color: "#E766E5" },
    { offset: 0.5, color: "#EF34D7" },
    { offset: 1, color: "#9B118A" },
  ])
  export const  color8= new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
    { offset: 0, color: "#F4F4F4" },
    { offset: 0.5, color: "#E9E9E9" },
    { offset: 1, color: "#BBBBBB" },
  ])
  export const  color9= new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
    { offset: 0, color: "#BFBFCA" },
    { offset: 0.5, color: "#C0BBC6" },
    { offset: 1, color: "#B5BBC0" },
  ])
  export const  color10= new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
    { offset: 0, color: "#323232" },
    { offset: 0.5, color: "#282828" },
    { offset: 1, color: "#211010" },
  ])
  export const  colorArr= [
    color1,
    color2,
    color3,
    color4,
    color5,
    color6,
    color7,
    color8,
    color9,
    color10,
  ]