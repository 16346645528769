<template>
  <div id="standinfo" class="standinfo_content">
    <div class="standinfo_row floatleft" id="standinfo_row_l">
        <div id="standinfo_row_l_t" style="">
            <box style="text-align:center">
            <b-img :src="data.Pic" fluid class="ksimg" style="margin:0 auto"></b-img>
            </box>
        </div>
        <div  id="standinfo_row_l_b" style="">
            <box>
                <div v-if="data.DeviceLamp" style="width:100%;height:100%" class="ksflexcol">
            <div class="row" style="width:100%">
              <div class="col">
                {{$t("单灯控制器")}}
                <span
                  class="content_text"
                  style="padding-left:10px"
                >{{data.DeviceLamp.Status==0?$t("离线"):$t("在线")}}</span>
              </div>
              <div class="col" style="text-align:right">
                <router-link to="/Admin/Light/LControl">>>{{$t("详情")}}</router-link>
              </div>
              <hr style="width:100%">
            </div>
            <div class="flex1 row ksrow">
              <div class="col mdhide" style="height:100%;padding:10px">
                <b-img :src="data.DeviceLamp.Pic" fluid class="ksimg"></b-img>
              </div>
              <div class="col ksflexcol" style="height:100%"  v-loading="loadingLight">
                <div class="row ksrow flex1">
                  <div class="col">{{$t("开关状态")}}</div>
                  <div class="col">
                    <el-radio-group
                      v-model="switchValue"
                      size="mini"
                      fill="black"
                      style="margin-right:20px;width:100px;"
                      @change="switchLight"
                    >
                      <el-radio-button value="open" label="关"></el-radio-button>
                      <el-radio-button value="close" label="开"></el-radio-button>
                    </el-radio-group>
                  </div>
                </div>
                <div class="row ksrow flex1">
                  <div class="col">{{$t("调光")}}</div>
                  <div class="col" style="padding-left:0;margin-left:0">
                    <el-slider
                      v-model="lightValue"
                      style="width:95%;display:inline-block"
                      @change="changeLight"
                    ></el-slider>
                  </div>
                </div>
                <div class="row ksrow flex1">
                  <div class="col">{{$t("序号")}}</div>
                  <div class="col">{{data.DeviceLamp.Addr}}</div>
                </div>
                <div class="row ksrow flex1">
                  <div class="col">{{$t("变比")}}</div>
                  <div class="col">{{data.DeviceLamp.Formula}}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>{{$t("没有绑定单灯控制器")}}</div>
            </box>
        </div>
        <div class="clear"></div>
    </div>
    <div class="standinfo_row floatleft" id="standinfo_row_c">
      <div id="standinfo_row_c_t" class="padding5">
        <box v-if="data.DeviceVideo" v-loading="loadingVideo">
        <netvideo ref="netplay"  :showRight="false" :playdata="playdata"></netvideo>
          <!-- <netpay ref="netplay" :data="playdata"></netpay> -->
        </box>
        <box v-else>{{$t("没有绑定摄像头设备")}}</box>
      </div>
      <div id="standinfo_row_c_b" class="padding5">
        <box>
          <div v-if="data.DeviceLedAd" style="width:100%;height:100%" class="ksflexcol">
            <div class="row" style="width:100%">
              <div class="col">
                {{$t("Led屏")}}
                <span
                  class="content_text"
                  style="padding-left:10px"
                >{{data.DeviceLedAd.Status==0?$t("离线"):$t("在线")}}</span>
              </div>
              <div class="col" style="text-align:right">
                <router-link :to="`/admin/stand/Leddetail?sid=${data.DeviceLedAd.Sid}`">>>> {{$t("详情")}}</router-link>
              </div>
              <hr style="width:100%">
            </div>
            <div class="flex1 row ksrow">
              <div class="col" style="height:100%;padding:20px;position:relative"  v-loading="loadingLed">
                <b-img :src="ledPic" fluid class="ksimg"></b-img>
                <div style="position:absolute;top:10px;right:10px">
                  <el-button
                    type="primary"
                    circle
                    size="small"
                    @click="getScreen(data.DeviceLedAd.Sid)"
                  >
                    <i class="fa fa-refresh" style="padding:0"></i>
                  </el-button>
                </div>
              </div>
              <div class="col ksflexcol" style="height:100%">
                <div class="row ksrow flex1">
                  <div class="col">{{$t("屏宽")}}</div>
                  <div class="col">{{data.DeviceLedAd.Width}}</div>
                </div>
                <div class="row ksrow flex1">
                  <div class="col">{{$t("屏高")}}</div>
                  <div class="col">{{data.DeviceLedAd.Height}}</div>
                </div>
                <div class="row ksrow flex1">
                  <div class="col">{{$t("亮度")}}</div>
                  <div class="col">{{data.DeviceLedAd.Brightness}}</div>
                </div>
                <div class="row ksrow flex1">
                  <div class="col">{{$t("音量")}}</div>
                  <div class="col">{{data.DeviceLedAd.Volume}}</div>
                </div>
                <div class="row ksrow flex1">
                  <div class="col">{{$t("屏幕状态")}}</div>
                  <div class="col">{{data.DeviceLedAd.Switch==1?"开启":"关闭"}}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>{{$t("没有绑定Led屏")}}</div>
        </box>
      </div>
    </div>
    <div class="standinfo_row floatleft" id="standinfo_row_r">
      <div>
        <box>
          <div v-if="data.DeviceBroadcast" style="width:100%;height:100%" class="ksflexcol">
            <div class="row" style="width:100%">
              <div class="col">
                {{$t("公共广播")}}
                <span
                  class="content_text"
                  style="padding-left:10px"
                >{{data.DeviceBroadcast.Status==0?$t("离线"):$t("在线")}}</span>
              </div>
              <div class="col" style="text-align:right">
                <router-link :to="`/admin/stand/Broaddetail?sid=${data.DeviceBroadcast.Sid}`">>>{{$t("详情")}}</router-link>
              </div>
              <hr style="width:100%">
            </div>
            <div class="flex1 row ksrow">
              <div class="col" style="height:100%;padding:10px">
                <b-img :src="data.DeviceBroadcast.Pic" fluid class="ksimg"></b-img>
              </div>
              <div class="col" style="height:100%;overflow:auto;" v-loading="loadingBroad">
                <div class="row ksrow standinfo_timmer" v-for="item in broads" :key="item.ID">
                  <div class="col">{{item.Name}}</div>
                  <div class="col" style="text-align:right">
                    <el-button type="primary" circle size="small" @click="playTimmer(item.ID)">
                      <i v-if="item.Status==0" class="fa fa-play" style="padding:0"></i>
                      <i v-else class="fa fa-stop" style="padding:0"></i>
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>{{$t("没有绑定公共广播设备")}}</div>
        </box>
      </div>
      <div>
        <box>
          <div v-if="data.DeviceSensor" style="width:100%;height:100%" class="ksflexcol">
            <div class="row" style="width:100%">
              <div class="col">
                {{$t("气象传感器")}}
                <span
                  class="content_text"
                  style="padding-left:10px"
                >{{data.DeviceSensor.Status==0?$t("离线"):$t("在线")}}</span>
              </div>
              <div class="col" style="text-align:right">
                <router-link :to="`/admin/stand/Sensordetail?sid=${data.DeviceSensor.Sid}`">>> {{$t("详情")}}</router-link>
              </div>
              <hr style="width:100%">
            </div>
            <div class="flex1 row ksrow">
              <div class="col" style="height:100%;padding:10px">
                <b-img :src="data.DeviceSensor.Pic" fluid class="ksimg"></b-img>
              </div>
              <div class="col ksflexcol" style="height:100%">
                <div class="row ksrow flex1">
                  <div class="col">{{$t("温度")}}</div>
                  <div class="col">{{data.DeviceSensor.Data?data.DeviceSensor.Data.Ta:""}}</div>
                </div>
                <div class="row ksrow flex1">
                  <div class="col">{{$t("湿度")}}</div>
                  <div class="col">{{data.DeviceSensor.Data?data.DeviceSensor.Data.Ua:""}}</div>
                </div>
                <div class="row ksrow flex1">
                  <div class="col">{{$t("风速")}}</div>
                  <div class="col">{{data.DeviceSensor.Data?data.DeviceSensor.Data.Sm:""}}</div>
                </div>
                <div class="row ksrow flex1">
                  <div class="col">PM2.5</div>
                  <div class="col">{{data.DeviceSensor.Data?data.DeviceSensor.Data.PM2_5:""}}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>{{$t("没有气象传感器设备")}}</div>
        </box>
      </div>
      <div>
        <box>
          <div v-if="data.DeviceAP" style="width:100%;height:100%" class="ksflexcol">
            <div class="row" style="width:100%">
              <div class="col">
                {{$t("无线网络")}}
                <span
                  class="content_text"
                  style="padding-left:10px"
                >{{data.DeviceAP.Status==0?$t("离线"):$t("在线")}}</span>
              </div>
              <div class="col" style="text-align:right">
                <router-link to="/Admin/Wifi/AP">>>{{$t("详情")}}</router-link>
              </div>
              <hr style="width:100%">
            </div>
            <div class="flex1 row ksrow">
              <div class="col" style="height:100%;padding:10px">
                <b-img :src="data.DeviceAP.Pic" fluid class="ksimg"></b-img>
              </div>
              <div class="col ksflexcol" style="height:100%">
                <div class="row ksrow flex1">
                  <div class="col">{{$t("型号")}}</div>
                  <div class="col">{{data.DeviceAP.ModelNumber}}</div>
                </div>
                <div class="row ksrow flex1">
                  <div class="col">Ip</div>
                  <div class="col">192.168.1.2</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>{{$t("没有绑定无线AP设备")}}</div>
        </box>
      </div>
    </div>
  </div>
</template>
<style>
#standinfo_row_l_b{
    height: 40%;
    padding: 5px;
    width: 100%;
    float: left;
}
#standinfo_row_l_t{
    height: 60%;
    padding: 5px;
    width: 100%;
    float: left;
}
.standinfo_timmer {
  padding: 5px;
  /* border-bottom: 1px white solid; */
}
.standinfo_content {
  font-size: 15px;
  color: white;
}
#standinfo_row_r > div {
  padding: 5px;
  height: 33.33%;
}
#standinfo_row_c_t{
    height: 60%;
}
#standinfo_row_c_b {
  height: 40%;
}
.floatleft {
  float: left;
}
#standinfo {
  height: 100%;
  width: 100%;
}
/* .standinfo_row {
  height: 100%;
  width: 33.3%;
} */
#standinfo_row_l{
    height: 100%;
    width: 25%;
}
#standinfo_row_c{
    height: 100%;
    width: 50%;
}
#standinfo_row_r{
    height: 100%;
    width: 25%;
}
@media (max-width: 1200px) {
  .standinfo_row {
    width: 100%;
  }#standinfo_row_l{
    height: 100%;
    width: 100%;
}
#standinfo_row_c{
    height: 100%;
    width: 100%;
}
#standinfo_row_r{
    height: 100%;
     width: 100%;
}
  #standinfo_row_l {
    height: 50%;
  }
  #standinfo_row_r > div {
    height: 50%;
  }
  
#standinfo_row_l_b{
    height: 100%;
    width: 50%;
}
#standinfo_row_l_t{
    height: 100%;
    width: 50%;
}
.mdhide{
    display: none;
}
}
@media (max-width: 767px) {

    #standinfo_row_l_b{
        height: 50%;
        width: 100%;
    }
    #standinfo_row_l_t{
        height: 50%;
        width: 100%;
    }
}
</style>

<script>
import box from "@/components/box.vue";
import itc from '@/assets/utils/itchttp.js'
import netvideo from '@/components/netvideo'
import { BaseUrl } from '../../../assets/utils/config';
export default {
  components: { box ,netvideo},
  data: function() {
    return {
      playdata: {},
      data: {},
      loading: {},
      loadingVideo: true,
      ledPic: `${BaseUrl}/img/map/led.png`,
      loadingLed: true,
      switchValue: "",
      lightValue: 0,
      loadingLight: false,
      loadingBroad:false,
      broads:[]
    };
  },
  mounted: function() {
    this.select();
  },
  methods: {
    playTimmer(id){
        var timmer=this.broads.find(x=>x.ID==id);
        if(timmer){
            let obj={
                TaskID:id
            };
            this.loadingBroad=true;
            var isplay=timmer.Status==0;
            let meth=isplay?"TaskManualStart":"TaskManualStop"
            itc.postData(this.$store.state.broadcast.url,meth,obj,this.$store.state.broadcast.session)
            .then(()=>{
                timmer.Status=isplay?1:0;
                this.loadingBroad=false;
            }).catch((error)=>{
                this.$throw(error);
                this.loadingBroad=false;
            })
        }
    },
    getTimmer(id){
        this.loadingBroad=true;
        let obj={"TaskIDs":[]}
        itc.postData(this.$store.state.broadcast.url,"TaskList",obj,this.$store.state.broadcast.session).then((data)=>{
            this.loadingBroad=false;
            this.broads=[];
            data.Items.forEach(x => {
                if(x.Tids){
                    var obj=x.Tids.find(y=>y==id);
                    if(obj&&x.Enable==1){
                        this.broads.push(x);
                    }
                }
            });
         }).catch((err)=>{
            this.loadingBroad=false;
             this.$throw(err);
         })
    },
    switchLight() {
      new Promise((res, rej) => {
        var req = {
          Method: "send"
        };
        var data = {
          platform: 1,
          cmds: []
        };
        //单灯控制
        data.cmds.push({
          code: 1,
          headSerial: 0,
          uid: this.data.DeviceLamp.LampCtrlId,
          addr: this.data.DeviceLamp.Addr,
          gatewayAddr: this.data.DeviceLamp.GatewayAddr,
          control: {
            devtype: this.data.DeviceLamp.LampControllerType,
            on: this.switchValue == "开" ? 1 : 0
          }
        });
        req.SZ = data;
        this.loadingLight = true;
        this.$ksajax
          .ksPostPromise("/api/agent/RequestSzApi", req, true)
          .then(success => {
            this.loadingLight = false;
            res(success);
          })
          .catch(err => {
            this.loadingLight = false;
            rej(err);
          });
      })
        .then(() => {
          //this.$emit("refresh",{type:this.type,gateways:this.gateways,sid:null})
        })
        .catch(err => {
          this.$throw(err);
        });
    },
    changeLight() {
      new Promise((res, rej) => {
        var req = {
          Method: "send"
        };
        var data = {
          platform: 1,
          cmds: []
        };
        data.cmds.push({
          code: 1,
          headSerial: 0,
          uid: this.data.DeviceLamp.LampCtrlId,
          addr: this.data.DeviceLamp.Addr,
          gatewayAddr: this.data.DeviceLamp.GatewayAddr,
          control: {
            devtype: this.data.DeviceLamp.LampControllerType,
            bri: this.lightValue
          }
        });
        req.SZ = data;
        this.loadingLight=true;
        this.$ksajax
          .ksPostPromise("/api/agent/RequestSzApi", req, true)
          .then(success => {
            this.loadingLight = false;
            res(success);
          })
          .catch(err => {
            this.loadingLight = false;
            rej(err);
          });
      })
        .then(() => {
          //this.$emit("refresh",{type:this.type,gateways:this.gateways,sid:null})
        })
        .catch(err => {
          this.$throw(err);
        });
    },
    select() {
      this.loading = this.$loading();
      var sid = this.$route.query.sid;
      this.$ksajax
        .ksPostPromise("/api/info/GetStandinfo", sid, true)
        .then(s => {
          this.data = s;
          this.loading.close();
          if (this.data.DeviceVideo) {
             this.play(this.data.DeviceVideo.Sid,this.data.DeviceVideo.DeviceName)
          }
          if (this.data.DeviceLedAd) {
            this.getScreen(this.data.DeviceLedAd.Sid);
          }
          if (this.data.DeviceLamp) {
            this.switchValue = this.data.DeviceLamp.IsOn ? "开" : "关";
            this.lightValue = this.data.DeviceLamp.Brightness;
          }
          if(this.data.DeviceBroadcast){
              this.getTimmer(this.data.DeviceBroadcast.Serial);
          }
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    play(sid, name) {
      this.loadingVideo = true;
      var ret = {
        EzopenType: 0,
        Channel: 1,
        Definition: "sd",
        PlaybackSource: 0,
        Sid: sid
      };
      this.$ksajax
        .ksPostPromise("/api/device/GetPayNet", ret, true)
        .then(success => {
          this.playdata.data = success;
          this.playdata.isPlay = true;
          this.playdata.device = { text: name };
          //this.$refs.netplay.start();
          this.loadingVideo = false;
        })
        .catch(err => {
          this.$throw(err);
          this.loadingVideo = false;
        });
    },
    getScreen(sid) {
      this.loadingLed = true;
      this.$ksajax
        .ksPostPromise("/api/led/GetScreen", sid, true)
        .then(success => {
          this.ledPic = `data:image/gif;base64,${success.result}`;
          this.loadingLed = false;
        })
        .catch(err => {
          this.$throw(err);
          this.loadingLed = false;
        });
    }
  },
  destroyed:function(){
    if(this.$refs.netplay){
      this.$refs.netplay.stop()
    }
  }
};
</script>
