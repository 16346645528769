import { render, staticRenderFns } from "./LControl.vue?vue&type=template&id=38708d7a&scoped=true"
import script from "./LControl.vue?vue&type=script&lang=js"
export * from "./LControl.vue?vue&type=script&lang=js"
import style0 from "./LControl.vue?vue&type=style&index=0&id=38708d7a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38708d7a",
  null
  
)

export default component.exports