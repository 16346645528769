<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      :reboot="true"
      :ntp="true"
      :light="true"
      :screen="true"
      :volume="true"
      :init="true"
      :refresh="true"
      v-on:select="select"
      v-on:insert="insert"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:volume="vol"
      v-on:reboot="reboot"
      v-on:ntp="ntp"
      v-on:light="light"
      v-on:screen="screen"
      v-on:init="init"
      v-on:refresh="refresh"
    ></actionbar>
    <el-table
      stripe=""
      :data="items"
      style="width: 100%"
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column :label="$t('序号')" prop="Serial"></el-table-column>
      <el-table-column
        :label="$t('设备名')"
        prop="DeviceName"
      ></el-table-column>
      <el-table-column :label="$t('设备类型')">
        <template slot-scope="scope">
          <span>{{ scope.row.DeviceType.TypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('高度')">
        <template slot-scope="scope">
          <span>{{ scope.row.DeviceNovaLed.Height }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('宽度')">
        <template slot-scope="scope">
          <span>{{ scope.row.DeviceNovaLed.Width }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('版本')">
        <template slot-scope="scope">
          <span>{{ scope.row.DeviceNovaLed.Version }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('地址')" prop="Address"></el-table-column>
      <el-table-column :label="$t('在线状态')">
        <template slot-scope="scope">
          <i
            :class="[
              'fa',
              scope.row.Status == 1 ? 'stateOn' : 'stateOff',
              'fa-2x',
              'fa-signal',
            ]"
          ></i>
        </template>
      </el-table-column>
    </el-table>
    <modify
      ref="modify"
      :mapitem="mapitem"
      :modaldata="modalData"
      :title="title"
      :labels="labels"
      v-on:submited="submited"
      url="/api/led/AddNovaLed"
    ></modify>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/led/DeleteNovaLed"
      :title="$t('删除')"
    ></deletedata>
    <b-modal
      id="novalControlModal"
      ref="novalControlModal"
      size="ms"
      :title="$t('控制')"
      body-text-variant="dark"
      @ok="novaSubmit"
    >
      <div>{{ controlTitle }}{{ controlDesc }}</div>
      <b-form-input
        type="number"
        v-if="controlData.option == 2 || controlData.option == 3"
        v-model="controlData.value"
      ></b-form-input>
      <b-form-select
        v-if="
          controlData.option == 1 ||
          controlData.option == 4 ||
          controlData.option == 5
        "
        v-model="controlData.value"
      >
        <b-form-select-option value="1">开</b-form-select-option>
        <b-form-select-option value="0">关</b-form-select-option>
      </b-form-select>
    </b-modal>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
export default {
  components: { actionbar, modify, deletedata },
  data: function () {
    return {
      controlTitle: "",
      controlDesc: "",
      controlData: {
        option: 0,
        playerIds: [],
        value: "",
      },
      sid: "",
      items: [],
      val: null,
      mapitem: {
        value: "Address",
        require: true,
        subvalue: false,
        Position: {
          Lat: "Lat",
          Lng: "Lng",
        },
      },
      modalData: this.resetData(),
      title: "",
      labels: this.getResetLable(),
    };
  },
  methods: {
    init() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/NovaKSInit", this.current.Sid, true)
        .then(() => {
          this.$throw("Sccuess");
          this.$loading().close();
        })
        .catch((err) => {
          this.$throw(err);
          this.$loading().close();
        });
    },
    refresh() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/NovaKSNoInit", this.current.Sid, true)
        .then(() => {
          this.$throw("Sccuess");
          this.$loading().close();
        })
        .catch((err) => {
          this.$throw(err);
          this.$loading().close();
        });
    },
    screen() {
      this.controlDetail(this.$t("屏幕开关"), "", 4);
    },
    light: function () {
      this.controlDetail(this.$t("修改量度"), "(0-100)", 3);
    },
    ntp: function () {
      this.controlDetail(this.$t("Ntp校时"), "", 5);
    },
    reboot: function () {
      this.controlDetail(this.$t("重启设备"), "", 0);
    },
    vol: function () {
      this.controlDetail(this.$t("修改音量"), "(0-100)", 2);
    },
    controlDetail(title, desc, opt) {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.controlData.playerIds = [this.current.Serial];
      this.controlData.option = opt;
      this.controlTitle = title;
      this.controlDesc = desc;
      this.$refs.novalControlModal.show();
    },
    novaSubmit() {
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/NovaLedControl", this.controlData, true)
        .then(() => {
          this.$throw("Sccuess");
          this.$loading().close();
        })
        .catch((err) => {
          this.$throw(err);
          this.$loading().close();
        });
    },
    control: function (opt, value) {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      var obj = {
        playerIds: [this.current.Serial],
        option: opt,
        value: value.value,
      };
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/NovaLedControl", obj, true)
        .then(() => {
          this.$throw("Sccuess");
          this.$loading().close();
        })
        .catch((err) => {
          this.$throw(err);
          this.$loading().close();
        });
    },
    del: function () {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    getResetLable() {
      return [
        {
          label: this.$t("设备名"),
          type: "text",
          value: "DeviceName",
          require: true,
        },
        {
          label: this.$t("高度"),
          type: "number",
          value: "Height",
          require: true,
        },
        {
          label: this.$t("宽度"),
          type: "number",
          value: "Width",
          require: true,
        },
        {
          label: this.$t("播放器名称"),
          type: "text",
          value: "Name",
          require: true,
        },
        {
          label: this.$t("类型"),
          type: "select",
          value: "DTypeSid",
          require: true,
          bind: this.$store.getters.getDeviceType("006", "nova"),
        },
        {
          label: this.$t("灯杆"),
          type: "select",
          value: "LStandSid",
          require: false,
          bind: this.$store.state.lStands,
        },
        {
          label: this.$t("位置"),
          type: "map",
          value: "Address",
          require: true,
        },
      ];
    },
    modify: function () {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.title = this.$t("修改");
      this.modalData = {
        Sid: this.current.Sid,
        DeviceName: this.current.DeviceName,
        Lat: this.current.Lat,
        Lng: this.current.Lng,
        Address: this.current.Address,
        Serial: this.current.Serial,
        Width: this.current.DeviceNovaLed.Width,
        Height: this.current.DeviceNovaLed.Height,
        DTypeSid: this.current.DeviceType.Sid,
        LStandSid: this.current.LampStand ? this.current.LampStand.Sid : "",
        Name: this.current.DeviceNovaLed.Name,
      };
      this.$refs.modify.show();
    },
    select: function () {
      var obj = {
        Index: 0,
        Count: 1000,
      };
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/GetNovaLeds", obj, true)
        .then((data) => {
          this.items = data.Items;
          this.$loading().close();
        })
        .catch((err) => {
          this.$throw(err);
          this.$loading().close();
        });
    },
    currentRow: function (val) {
      this.current = val;
    },
    insert: function () {
      this.title = this.$t("新增");
      this.modalData = this.resetData();
      this.$refs.modify.show();
    },
    submited() {
      this.select();
    },
    deleted: function () {
      this.select();
    },
    resetData: function () {
      return {
        DeviceName: "",
        Lat: 0,
        Lng: 0,
        Address: "",
        Serial: "Serial",
        DTypeSid: "",
        LStandSid: "",
        Width: "",
        Height: "",
        Name: "",
      };
    },
  },
  mounted: function () {
    this.select();
  },
};
</script>