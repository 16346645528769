<template>
    <div class="white" v-loading="loading" style="position:relative;height:100%;">
        <div style="position:absolute;right:20px;top:10px;z-index:999;">
           <i class="fa fa-table fa-2x " style="cursor: pointer;color:8495c2" @click="tabled"></i>
        </div>
        <ksmap v-show="map"
        ref="map" width='100%' :showCenter="false" height="100%"
          v-bind:lat='0' v-bind:lng='0'
          v-bind:zoom=25>
          </ksmap>
          <div v-show="!map">
                 <el-table class="kstable" :data="items" stripe=""
                style="width: 100%"  highlight-current-row :empty-text="$t('暂无数据')">
                 <el-table-column prop="DeviceName" :label="this.$t('设备名称')"></el-table-column>
                 <el-table-column prop="Serial" :label="this.$t('序号')"></el-table-column>
                 <el-table-column  :label="this.$t('详情')">
                     <template slot-scope="scope">
                         <router-link :to="'/admin/box/detail?sid='+scope.row.Sid">详情</router-link>
                         </template>
                 </el-table-column>
              </el-table>
          </div>
    </div>
</template>
<script>
import ksmap from '@/components/map'
import { BaseUrl } from '../../../assets/utils/config';
export default {
    components:{ksmap},
    data:function(){
        return {
            loading:true,
            map:true,
            items:[]
        }
    },
    mounted:function(){
        this.select();
    },
    methods:{
        tabled(){
            this.map=!this.map;
            if(this.map){
                this.$refs.map.setFilter(0);
            }
        },
        select(){
            this.$ksajax.ksPostPromise("/api/box/getdevices","",true).then((data)=>{
                console.log(data);
                 this.loading=false;
                 var device=[];
                 this.items=data;
                 this.items.forEach(x => {
                     device.push(
                         {
                             lat:x.Lat,
                             lng:x.Lng,
                             icon:`${BaseUrl}/img/new/ic_swdt.png`,
                             url:"/admin/box/detail?sid="+x.Sid,
                             title:x.DeviceName
                         }
                     )
                 });
                 this.$refs.map.addDevice(device,0);
             }).catch((err)=>{
                 this.$throw(err,this);
                 this.loading=false;
             })
        }
    }
}
</script>
