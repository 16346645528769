<template>
  <div id="checkdetail" class="white">
    <div class="container" style="padding:0px" v-loading="loading">
      <div class="checkdetail_title">
        <h4>设备信息</h4>
        <hr>
        <b-alert show>
            <div class="info_alert">
            <span>名称:{{current.Device?current.Device.DeviceName:""}}</span>
            <span>型号:{{current.Device?current.Device.ModelNumber:""}}</span>
            </div>
        </b-alert>
      </div>
      <div class="checkdetail_title">
        <h4>报障人信息</h4>
        <hr>
        <b-alert show>
            <div class="info_alert">
            <span>联系人:{{current.Contacter}}</span>
            <span>联系电话:{{current.ContacterPhone}}</span>
            <span>时间:{{current.CheckTime}}</span>
            <span>状态:{{current.Device?current.Device.State==0?"正常":current.Device.State==1?"异常":"废弃":""}}</span>
            <span>描述:{{current.Reason}}</span>
            </div>
        </b-alert>
      </div>
      <div class="checkdetail_title">
        <h4>处理信息</h4>
        <hr>
        <b-alert show>
            <div class="info_alert">
            <span>处理人:{{current.Reciver?current.Reciver.Name:""}}</span>
            <span>处理人电话:{{current.Reciver?current.Reciver.PhoneNumber:""}}</span>
            <span>处理时间:{{current.DisposeTime}}</span>
            <span>处理状态:{{current.DisposeStatus==0?"未处理":"已处理"}}</span>
            </div>
        </b-alert>
      </div>
      
      <div class="checkdetail_title">
        <h4>故障图片</h4>
        <hr>
        <div v-if="current.Pics.length==0" style="font-size:16px">
            无故障图片
        </div>
        <div v-else>
            <div  v-for="item in current.Pics" :key="item" >
          <b-img :src="item" fluid class="w-100"></b-img>
          <hr>
          </div>
        </div>
      </div>
      <div class="checkdetail_title">
        <h4>处理图片</h4>
        <hr>
        <div v-if="current.ActionPics.length==0" style="font-size:16px">
            无处理图片
        </div>
        <div v-else>
            <div  v-for="item in current.ActionPics" :key="item" >
          <b-img :src="item" fluid class="w-100"></b-img>
          <hr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.checkdetail_title{
    color: black;
    font-size: 25px;
    width: 100%;
}
.info_alert>span{
    margin-right: 15px;
    font-size: 20px
}
</style>
<script>
export default {
    data:function(){return {
        current:{
            Pics:[],
            ActionPics:[]
        },
        loading:false
    }},
    mounted:function(){
        this.select();
    },
    methods:{
        select(){
            this.loading=true; 
            this.$ksajax
        .ksPostPromise("/api/info/GetDeviceErrorDetail",  this.$route.query.sid, true).then(data=>{
            if(!data.Pics){
                data.Pics=[];
            }
            if(!data.ActionPics){
                data.ActionPics=[];
            }
            this.current=data;
            this.loading=false;
        }).catch(err=>{
            this.loading=false;
            this.$throw(err);
        })
        }
    }
};
</script>
