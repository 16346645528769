<template>
<div class="ksalert">
    <b-alert :show="dismissCountDown"
             dismissible
             variant="warning"
             @dismissed="dismissCountDown=0"
             @dismiss-count-down="countDownChanged">
      <p>{{errorMsg}}</p>
      <b-progress variant="warning"
                  :max="dismissSecs"
                  :value="dismissCountDown"
                  height="4px">
      </b-progress>
    </b-alert></div>
</template>
<style>
.ksalert{
  position:absolute;
  width: 100%;
  z-index: 1000000;
}
</style>

<script>
export default {
    data:function(){
        return{
      errorMsg:"",
       dismissSecs: 3,
      dismissCountDown: 0,
        }
    },
    methods:{
        countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert (errorMsg) {
      this.dismissCountDown = this.dismissSecs;
      this.errorMsg=errorMsg;
    }
    }
}
</script>

