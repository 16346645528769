<template>
    <div style="height:100%;height:100%" v-loading="loading">
        <basic :data="datas"></basic>
    </div>
</template>
<script>
import basic from '@/components/basic'
export default {
    data:function(){return {
        datas:[],
        loading:false
    }},
    components:{basic},
    mounted:function(){
        this.select();
    },
    methods:{
        select(){
            if(this.$store.state.devices.length==0){
                this.loading=true;
                this.$ksajax.ksPostPromise("/api/info/GetDevice","",true).then((data)=>{
                 this.$store.state.devices=data;
                 this.datas=data;
                 this.loading=false;
                }).catch((err)=>{
                    this.$throw(err,this);
                    this.loading=false;
                })
            }else{
                this.datas= this.$store.state.devices;
            }
             
        }
    }
}
</script>
