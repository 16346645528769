<template>
  <div style="color: white; height: 100%">
    <img :src="lampData.pic" id="lampPic" @load="drawLamp(false)" hidden />
    <img :src="wallData.pic" id="wallPic" @load="drawWall(false)" hidden />
    <box id="canvas_box" style="position: relative">
      <div style="position: absolute; right: 0; height: 30%; width: 20%">
        <alarm ref="alarm"></alarm>
      </div>
      <div :style="canvasStyle">
        <canvas
          ref="canvas_lamp"
          style="position: absolute; left: 0; bottom: 0"
        ></canvas>
        <canvas
          ref="canvas_wall"
          style="position: absolute; left: 0; bottom: 0"
        ></canvas>
      </div>
    </box>
  </div>
</template>
<script>
import box from "@/components/box1.vue";
import alarm from "@/components/alarm";
export default {
  props:["sid","objectvalue"],
  components: { box, alarm },
  data() {
    return {
      alarmTrgger: false,
      loadingCanvas: false,
      leftBottomLoading: false,
      /**画布父元素的css */
      canvasStyle: {
        position: "absolute",
        left: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      },
      animate: {
        angle: 0,
        currentAngel: 0,
        thread: null,
        speed: 20,
      },
      /**画布初始化参数 */
      initData: {
        /**原图尺寸 */
        size: {
          width: 340,
          height: 424,
        },
        /**渲染尺寸 */
        rendSize: {
          width: 0,
          height: 0,
        },
      },
      sensorData: {
        TcpSensor: {},
      },
      /**底部墙渲染数据 */
      wallData: {
        //画片
        pic: require("@/assets/img/lamppost-base.png"),
        //canvas上下文
        ctx: null,
        //原图尺寸
        size: {
          width: 370,
          height: 34,
        },
        //渲染尺寸
        rendSize: {
          width: 0,
          height: 0,
        },
        //起点坐标
        startPos: {
          x: 0,
          y: 0,
        },
      },
      lampData: {
        //画片
        pic: require("@/assets/img/lamppost.png"),
        //canvas上下文
        ctx: null,
        //原图尺寸
        size: {
          width: 181,
          height: 387,
        },
        //渲染尺寸
        rendSize: {
          width: 0,
          height: 0,
        },
        //起点坐标
        startPos: {
          x: 0,
          y: 0,
        },
        offsetX: 80,
        RBX: 164,
        offsetRBX: 164,
      },
      leftTop: {},
      leftBottom: {},
      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" },
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" },
      ]),
    };
  },
  watch: {
    "animate.angle": function () {
      this.startBoltAnimate();
    },
    "$store.state.dataHubChange": function () {
      var data = this.$store.state.dataHub;
      if (data.Serial == this.sensorData.Serial) {
        if (data.Data.Ag || data.Data.Ag == 0) {
          this.animate.angle = this.sensorData.Angle = Math.round(data.Data.Ag);
        }
        if (this.sensorData.TcpSensor) {
          this.sensorData.TcpSensor.AC_Leakel = data.Data.Leakel;
          this.sensorData.TcpSensor.AngelX = data.Data.Tx;
          this.sensorData.TcpSensor.AngelY = data.Data.Ty;
          this.sensorData.TcpSensor.AngelZ = data.Data.Tz;
        }
      }
    },
    "$store.state.alarmHubChange": function () {
      var data = this.$store.state.alarmHub;
      if (data.Serial == this.sensorData.Serial) {
        if (data.Data.Leakel || data.Data.Leakel == 0) {
          this.sensorData.TcpSensor.AC_Leakel = data.Data.Leakel;
          this.alarmTrgger = data.Type == 6 ? true : false;
        }
      }
    },
    alarmTrgger: function () {
      if (this.alarmTrgger) {
        this.$refs.alarm.start(true);
      } else {
        this.$refs.alarm.start(false);
      }
    },
  },
  mounted() {
    //调整画布位置及大小
    //父元素大小
    this.getSensorData();
    var parentWidth = document.getElementById("canvas_box").clientWidth;
    var parentHeight = document.getElementById("canvas_box").clientHeight;
    //画布宽度最大值为父元素宽度
    this.initData.rendSize.width =
      this.initData.size.width > parentWidth
        ? parentWidth
        : this.initData.size.width;
    //根据比率设置画布高度
    var rate = this.initData.size.width / this.initData.size.height;
    this.initData.rendSize.height = this.initData.size.height / rate;
    //画面高度最大值为父元素高度
    if (this.initData.rendSize.height > parentHeight) {
      this.initData.rendSize.height = parentHeight;
      //再次调整宽度
      this.initData.rendSize.width = this.initData.rendSize.height * rate;
    }
    //设置画布大小
    this.canvasStyle.width = `${this.initData.rendSize.width}px`;
    this.canvasStyle.height = `${this.initData.rendSize.height}`;
    if (this.initData.rendSize.width < parentWidth) {
      //画布居中
      var diff = parentWidth - this.initData.rendSize.width;
      this.canvasStyle.left = `${diff / 2}px`;
    }
    //设置最底部墙的渲染数据
    var rateWall = this.wallData.size.width / this.wallData.size.height;
    this.wallData.rendSize.width = this.initData.rendSize.width;
    this.wallData.rendSize.height = this.initData.rendSize.width / rateWall;
    this.wallData.startPos.x = 0;
    this.wallData.startPos.y =
      this.initData.rendSize.height - this.wallData.rendSize.height;
    this.$refs.canvas_wall.width = this.initData.rendSize.width;
    this.$refs.canvas_wall.height = this.initData.rendSize.height;
    this.wallData.ctx = this.$refs.canvas_wall.getContext("2d");
    //设置灯杆的渲染数据
    var rateLamp = this.lampData.size.width / this.lampData.size.height;
    var rateLampWidth = this.lampData.size.width / this.initData.size.width;
    this.lampData.rendSize.width = this.initData.rendSize.width * rateLampWidth;
    this.lampData.rendSize.height = this.lampData.rendSize.width / rateLamp;
    var rateLampOffset =
      this.initData.size.width / this.initData.rendSize.width;
    this.lampData.startPos.x = this.lampData.offsetX / rateLampOffset;
    this.lampData.startPos.y =
      this.initData.rendSize.height -
      (this.wallData.rendSize.height + this.lampData.rendSize.height);
    this.lampData.offsetRBX = this.lampData.RBX / rateLampOffset;
    this.$refs.canvas_lamp.width = this.initData.rendSize.width;
    this.$refs.canvas_lamp.height = this.initData.rendSize.height;
    this.lampData.ctx = this.$refs.canvas_lamp.getContext("2d");
  },
  methods: {
    drawWall() {
      this.wallData.ctx.drawImage(
        document.getElementById("wallPic"),
        this.wallData.startPos.x,
        this.wallData.startPos.y,
        this.wallData.rendSize.width,
        this.wallData.rendSize.height
      );
    },
    
    getSensorData() {
      var sid = this.sid;
      this.$ksajax
        .ksPostPromise("/api/asset/GetLampStandInfo", sid, true)
        .then((data) => {
          if (data.TcpSensor) {
            data.SensorVoltage = data.TcpSensor.AC_V;
            data.Lsnr = 100;
            data.Angle =Math.round(Math.max(
              Math.abs(data.TcpSensor.AngelJX - data.TcpSensor.AngelX),
              Math.abs(data.TcpSensor.AngelJY - data.TcpSensor.AngelY),
              Math.abs(data.TcpSensor.AngelJZ - data.TcpSensor.AngelZ)
            ));
          }
          this.sensorData = data;
          if(this.objectvalue&&this.objectvalue.Ag){
                this.sensorData.Angle=this.objectvalue.Ag;
          }else{
            this.animate.angle =
                this.sensorData.Angle > 90 ? 90 : this.sensorData.Angle;
          }
          if(this.objectvalue&&this.objectvalue.Alarm){
              this.alarmTrgger=true;
          }
        });
    },
    drawLamp(angle) {
      angle = angle || this.animate.angle;
      this.lampData.ctx.clearRect(
        0,
        0,
        this.initData.rendSize.width,
        this.initData.rendSize.height
      );
      this.lampData.ctx.save();
      //以栓柱右下角为旋转基点
      this.lampData.ctx.translate(
        this.lampData.offsetRBX,
        this.initData.rendSize.height - this.wallData.rendSize.height
      );
      this.lampData.ctx.rotate(((360 - angle) * Math.PI) / 180);
      //以栓柱右下角为中心点，原来的起点坐标灰新坐标系中的位置
      var cx = -(this.lampData.offsetRBX - this.lampData.startPos.x);
      var cy = -this.lampData.rendSize.height;
      this.lampData.ctx.drawImage(
        document.getElementById("lampPic"),
        cx,
        cy,
        this.lampData.rendSize.width,
        this.lampData.rendSize.height
      );
      this.lampData.ctx.restore();
    },
    startBoltAnimate() {
      this.animate.thread = window.setInterval(() => {
        if (
          this.animate.angle == this.animate.currentAngel ||
          this.animate.angle == 0
        ) {
          if (this.animate.thread) {
            window.clearInterval(this.animate.thread);
            this.animate.thread = null;
          }
        }
        if (this.animate.angle > this.animate.currentAngel) {
          this.animate.currentAngel += 1;
        } else {
          this.animate.currentAngel -= 1;
        }
        this.drawLamp(this.animate.currentAngel);
      }, this.animate.speed);
    }
  },
};
</script>

