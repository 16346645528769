<template>
  <div style="width: 100%; height: 100%" class="ksflex">
      <div
        style="flex: 1; height: 100%; background-color: darkcyan;position:relative"
      >
      <div id="myPlayer"  style="width:100%;height:100%;"></div>
      <div class="divcenter">  
        <i :class="isPlay?' fa fa-stop fa-1x':' fa fa-play fa-1x'" @click="playAction"></i>
        <i
            class="fa fa-arrow-left fa-1x"
            @mouseup="startPlatControl(2,false)"
            @mousedown="startPlatControl(2,true)"
          ></i>
          <i class="fa fa-arrow-up fa-1x"
            @mouseup="startPlatControl(0,false)"
            @mousedown="startPlatControl(0,true)"
           ></i>
          <i
            class="fa fa-arrow-down fa-1x"
            @mouseup="startPlatControl(1,false)"
            @mousedown="startPlatControl(1,true)"
          ></i>
          <i
            class="fa fa-arrow-right fa-1x"
            @mouseup="startPlatControl(3,false)"
            @mousedown="startPlatControl(3,true)"
          ></i>
          <i
            class="fa fa-search-plus fa-1x"
            @mouseup="startPlatControl(8,false)"
            @mousedown="startPlatControl(8,true)"
          ></i>
          <i
            class="fa fa-search-minus fa-1x"
            @mouseup="startPlatControl(9,false)"
            @mousedown="startPlatControl(9,true)"
          ></i></div>
      </div>
      <div style="width:200px;height:100%;overflow:auto;" id="rightList"  ref="rightList" v-show="showright" v-loading="loadingPoint">
             <div style="width:100%;height:100%;color:grey;">
         <div style="text-align:center" class="leftTitle">
           <span>{{$t('预置点')}}</span>
           <el-button type="primary" round size="small" @click="addPoint()">{{insert?$t("保存"):$t("新增预置点")}}</el-button>
           </div>
           <div v-show="insert">
             <b-form-input v-model="inputName" placeholder="请输入名称"></b-form-input>
           </div>
         <hr>
         <div v-for="item in listPoint" :key="item.Sid" class="pointi">
           <b-row>
             <b-col>
           {{item.Name}}</b-col>
             <b-col>
           <i class="fa fa-forward fa-fw" @click="toPoint(item.Index)"></i>
           <i class="fa fa-cog fa-fw" @click="setPoint(item)"></i>
           <i class="fa fa-trash-o fa-fw" @click="delPoint(item.Sid)"></i></b-col>
           </b-row>
           </div>
       </div>
      </div>
  </div>
</template>
<script>
export default {
  props: ["playdata", "showright", "unshowaction"],
  data() {
    return {
      player: null,
      msg:"",
      loadingPoint:false,
      insert:false,
        listPoint:[],
      inputName:"",
      EZUIKit:null,
      isPlay:false
    };
  },
  methods: {
    init(){
      if(!this.EZUIKit){
        this.EZUIKit=window.EZUIKit;
      }
    },
    begin() {
      var width = window.document.getElementById("myPlayer").clientWidth;
      var height = window.document.getElementById("myPlayer").clientHeight;
      this.player = new this.EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: "myPlayer",
        accessToken: this.playdata.data.AccessToken,
        url: this.playdata.data.Url,
        template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        // header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        plugin: ["talk"], // 加载插件，talk-对讲
        // 视频下方底部控件
        footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
        openSoundCallBack: (data) => console.log("开启声音回调", data),
        closeSoundCallBack: (data) => console.log("关闭声音回调", data),
        startSaveCallBack: (data) => console.log("开始录像回调", data),
        stopSaveCallBack: (data) => console.log("录像回调", data),
        capturePictureCallBack: (data) => console.log("截图成功回调", data),
        fullScreenCallBack: (data) => console.log("全屏回调", data),
        getOSDTimeCallBack: (data) => console.log("获取OSDTime回调", data),
        width: width,
        height: height - 50,
      });
      this.isPlay=true;
        console.log("ezopen url", this.player.opt.url);
    },
    start() {
      if (!this.player) {
        this.begin();
      } else {
        this.player.stop();
        this.player.opt.accessToken = this.playdata.data.AccessToken;
        this.player.opt.url = this.playdata.data.Url.replace("?mute=true","");
        console.log("ezopen url", this.player.opt.url);
        this.player.play();
        this.isPlay=true;
      }
       if(this.showright){
          this.selectPoint();
        }
    },
    stop() {
      this.player.stop();
      this.isPlay=false;
    },
    
    startPlatControl(Direction,enable){
       if(!this.playdata||!this.playdata.data){
         throw("请先指定播放设备");
      }
       var obj={
        Serial:this.playdata.device.serial,
        Direction:Direction,
        Enable:enable
      }
      this.$ksajax.ksPostPromise("/api/device/ControlVideoPlat",obj,true).then(success=>{
        if(success){
          // this.$throw("操作成功");
        }else{
          this.$throw("操作失败");
        }
      }).catch(error=>{
        this.$throw(error);
      })
    }, delPoint(sid){
      if(!this.playdata||!this.playdata.device){
        this.$throw("请先播放视频");
      }else{
        this.loadingPoint=true;
         this.$ksajax.ksPostPromise("/api/device/DelVideoPreset",sid,true).then(()=>{
           this.loadingPoint=false;
           this.selectPoint();
         }).catch(err=>{
           this.loadingPoint=false;
           this.$throw(err);
         })
      }
    },
    toPoint(index){
      if(!this.playdata||!this.playdata.device||!this.playdata.isPlay){
        this.$throw("请先播放视频");
      }else{
         this.loadingPoint=true;
         var obj={
           Index:index,
           VideoSid:this.playdata.device.sid
         };
         this.$ksajax.ksPostPromise("/api/device/CallVideoPreset",obj,true).then(()=>{
           this.loadingPoint=false;
         }).catch(err=>{
           this.loadingPoint=false;
           this.$throw(err);
         })
      }
    },
    setPoint(item){
      if(!this.playdata||!this.playdata.device||!this.playdata.isPlay){
        this.$throw("请先播放视频");
      }else{
         this.loadingPoint=true;
       var obj={
            "Sid":item.Sid,
            "VideoSid": item.VideoSid,
            "Name": item.Name,
            "Describe": item.Describe
          };
          this.$ksajax.ksPostPromise("/api/device/AddVideoPreset",obj,true).then(()=>{
            this.loadingPoint=false;
          }).catch(err=>{
            this.loadingPoint=false;
            this.$throw(err);
          })
      }
     
    },
    addPoint(sid){
      if(!this.playdata||!this.playdata.device){
        this.$throw("请先播放视频");
      }else{
        if(this.insert){
          var obj={
            "Sid":sid,
            "VideoSid": this.playdata.device.sid,
            "Name": this.inputName,
            "Describe": this.inputName
          };
           this.$ksajax.ksPostPromise("/api/device/AddVideoPreset",obj,true).then(()=>{
             this.selectPoint();
           }).catch(err=>{
             this.$throw(err);
           })
        }
        this.insert=!this.insert;
      }
    },
    handleError() {
      //alert(e)
    },
    selectPoint(){
      if(this.playdata.device.sid){
          this.loadingPoint=true;
       this.$ksajax.ksPostPromise("/api/device/GetVideoPresets",this.playdata.device.sid,true).then((d)=>{
         this.listPoint=d;
          this.loadingPoint=false;
       }).catch(err=>{
         this.$throw(err);
          this.loadingPoint=false;
       })
      }
    },
    log(str){
      console.log(str);
    },
    playAction(){
      if(this.isPlay){
        this.stop();
      }else{
        this.start();
      }
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.pointi i {
  cursor: pointer;
}
#div-action-bar > i,
#div-action-arrow > i {
  margin-left: 10px;
  cursor: pointer;
}
.netplay-obj {
  /* position: absolute; */
}
.netplay {
  width: 100%;
  height: 100%;
  background-color: black;
  position: relative;
  color: white;
}
.netplay-play {
  position: absolute;
}
#frame-title {
  position: absolute;
  z-index: 1;
  width: 120px;
  height: 25px;

  top: 10px;
  right: 10px;
  filter: "progid:DXImageTransform.Microsoft.Alpha(style=0,opacity=0)";
}
#frame-action {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
  bottom: 0px;
  filter: "progid:DXImageTransform.Microsoft.Alpha(style=0,opacity=0)";
}
#div-title {
  position: absolute;
  z-index: 2;
  height: 25px;
  top: 10px;
  right: 10px;
}
#div-action {
  position: absolute;
  z-index: 2;
  width: 95%;
  height: 50px;
  bottom: 0px;
}
.divcenter{
  position: absolute;
    bottom: 50px;
    height: 50px;
    padding-top: 8px;
    padding-left: 8px;
    color: white;
    width: 100%;
    background-color: rgba(50, 50, 50, 0.3);
}
</style>