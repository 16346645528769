<template>
  <div class="main amdin" style="position:relative">
    <div :class="result">
      <router-view></router-view>
    </div>
    <div v-show="alarmShow" id="alarm_model">
      <div style="width:100%;height:100%;position:relative">
        <iframe id="alarm_frame" allowtransparency="true" frameborder="0" scrolling="no"></iframe>
        <div id="alarm_divcontent">
          <box style="padding:10px">
            <div style="position:relative;width:100%;height:100%;">
              <div style="text-align:center;font-size:30px">
                <i class="fa fa-warning" style="color:red"></i>
                紧急呼叫
              </div>
              <hr>
              <div>设备【{{alarmDevice}}】正在进行【{{alarmType}}】呼叫。请您立即处理。</div>
              <div id="alarm_divcontent_footer">
                <hr>
                <div style="text-align:right">
                  <b-button variant="success" style="margin-right:10px" @click="cancel">取消</b-button>
                  <b-button variant="danger" style="margin-right:10px" @click="action">处理</b-button>
                </div>
              </div>
            </div>
          </box>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.white{
  background-color:white !important;
  padding: 0 5px;
}
.text-white{
  color: black !important;
}
</style>
<style scoped>
#alarm_divcontent {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.top_main{
    height: calc(100% + 70px);
}

/* #main_top_center{
    width:440px;
    height: 100%;
    background-image:  url("../assets/img/cute/nav_01.png")!important;
    background-position:center;
    text-align: center;
    font-size: 2.5em;
    color: white;
    line-height: 70px
}
#main_top_left{
    flex: 1;
    background-image:  url("../assets/img/cute/nav_02.png")!important;
    background-position:center;
    height: 70px;
    line-height: 70px;
    padding-left: 20px;
}#main_top_right{
    flex: 1;
    background-image:  url("../assets/img/cute/nav_02.png")!important;
    background-position:center;
    height: 70px !important;
    text-align: right;
    padding-right: 50px;
    line-height: 70px;
} */
#alarm_divcontent_footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
#alarm_model {
  position: absolute;
  width: 500px;
  height: 250px;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: white;
}
#alarm_frame {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  filter: "progid:DXImageTransform.Microsoft.Alpha(style=0,opacity=0)";
}
html,
body {
  width: 100% !important;
  height: 100% !important;
}
.admin {
  width: 100%;
  height: 100%;
}
/* .router{
  position:absolute;
  width:100%;
  top: 56px;
  bottom: 0px;
}  */

.router {
  /* height: -moz-calc(100% - 70px);
  height: -webkit-calc(100% - 70px);
  height: calc(100% - 70px); */
  height: 100%;
}
.homeRouter {
  height: 100%;
}
</style>

<script>
import mainMenu from "../components/mainMenu";
import { AlarmTypes, sensorFormat } from "@/assets/utils/datef.js";
import box from "@/components/box.vue";
export default {
  data: function() {
    return {
      height: {},
      alarmDevice: "测试设备",
      alarmType: "紧急援助",
      serial: "",
      alarmShow: false,
      textTitle: {
        title: "紧急呼叫",
        action: "关闭",
        style: "fa fa-close"
      },
      result: "router",
      signalr:require("@aspnet/signalr"),
       sign: null,
    };
  },

  components: {
    mainMenu,
    box
  },
  mounted: function() {
    // this.height={
    //   height:`${this.$parent.$el.clientHeight-this.$refs.mainmenu.$el.clientHeight-6}px`
    // };
    this.result =
      this.$route.path.toLowerCase().indexOf("/home") != -1
        ? "homeRouter"
        : "router";
     this.restartSig();
  },
  methods: {
     restartSig(){
      window.setTimeout(()=>{
        if(this.sign){
          if(this.sign.state!="Connected"){
            this.sign=null;
            this.startSig().then(()=>{
              this.restartSig()}).catch(()=>{
              this.restartSig()});
          }else{
            this.restartSig();
          }
        }else{
          this.startSig().then(()=>this.restartSig()).catch(()=>{this.restartSig()});
        }
      },2000);
    },
    startSig(){
      return new Promise((res,rej)=>{
      //  var url = "http://192.168.1.4:5000/KSSmart";
        var url="https://kingsunsmart.com/KSSmart";
        if(!this.sign){
            this.sign= new this.signalr.HubConnectionBuilder()
          .withUrl(url)
          .build(); 
          this.sign.serverTimeoutInMilliseconds=60000;
          this.sign.on("AlarmHubModel", data => {
          if(this.judeage(data.projectSid)){
              this.toUpperCase(data);
              this.toUpperCase(data.Data); 
              var list = AlarmTypes();
              var obj = list.find(x => x.value == data.Type);
              if (obj) {
                var box = {};
                box.title = obj.text;
                if (obj.data == 0) {
                  box.content = `设备(${data.DeviceName})发生${obj.text}报警`;
                } else {
                  var d = "";
                  if (data.Data) {
                    d = data.Data[Object.keys(data.Data)[0]];
                  }
                  if ((d + "").length > 0) {
                    var pix = Object.keys(data.Data)[0];
                    var ss = sensorFormat(false);
                    var pixx = ss.find(x => x.value == pix);
                    var sd = pixx ? pixx.pix : "";
                    box.content = `设备(${data.DeviceName})发生${
                      obj.text
                    }报警。当前数据${d}${sd}`;
                  } else {
                    box.content = `设备(${data.DeviceName})发生${obj.text}报警`;
                  }
                }
                data.box = box;
              }
              this.$store.commit("setAlarmHub", data);
          }
        });
        this.sign.on("DataHubModel", data => {
          if(this.judeage(data.projectSid)){
            this.toUpperCase(data);
            this.toUpperCase(data.Data);
            this.$store.commit("setDataHub", data);
          }
        });
        this.sign.on("ActionHubModel", data => {
           if(this.judeage(data.projectSid)){
              this.toUpperCase(data);
              this.toUpperCase(data.Data);
              this.$store.commit("setActionHub", data);
           }
        });
        this.sign.on("FaceMessageHubModel", data => {
           if(this.judeage(data.projectSid)){
              this.toUpperCase(data);
              this.toUpperCase(data.Data);
              this.$store.commit("setFaceHub", data);
           }
        });
        this.sign.on("PlayLogHubModel", data => {
           if(this.judeage(data.projectSid)){
              this.toUpperCase(data);
              this.toUpperCase(data.Data);
              this.$store.commit("setPlayHub", data);
           }
        });
        this.sign.on("NovaPlayLogHubModel", data => {
           if(this.judeage(data.projectSid)){
              this.toUpperCase(data);
              this.toUpperCase(data.Data);
              this.$store.commit("setNovaPlayHub", data);
           }
        });
        this.sign.on("CarPoliceHubModel", data => {
           if(this.judeage(data.projectSid)){
              this.toUpperCase(data);
              this.toUpperCase(data.Data);
              this.$store.commit("setCarPoliceHub", data);
           }
        });
        this.sign
          .start().then(()=>{
            res();
          })
          .catch((err)=> {
            this.sign=null;
            rej(err);
          });
        }else{
          res(); 
        }
      })
    },
    judeage(sid){
      if(this.$store.state.project){
        if(this.$store.state.project.Sid==sid){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    },
    titleCase5(str) {
      return str.toLowerCase().replace(/( |^)[A-Z]/g, L => L.toUpperCase());
    },
    toUpperCase(jsonObj) {
      if (typeof jsonObj == "object") {
        for (var key in jsonObj) {
          jsonObj[key.substring(0, 1).toUpperCase() + key.substring(1)] =
            jsonObj[key];
          delete jsonObj[key];
        }
        return jsonObj;
      }
      return jsonObj;
    },
    cancel() {
      this.alarmShow = false;
    },
    action() {
      if (window.alarmDoAction) {
        window.alarmDoAction(this.serial);
      } else {
        this.$router.push({
          path: "/admin/alarms/alarmAction?serial=" + this.serial
        });
      }
      this.alarmShow = false;
    }
  },
  created: function() {
    this.$i18n.locale = this.$cookies.get("lan");
  },
  watch: {
    $route: function() {
      this.result =
        this.$route.path.toLowerCase().indexOf("/home") != -1
          ? "homeRouter"
          : "router";
    },
    "$store.state.alarmHubChange": function() {
      try {
        var data = this.$store.state.alarmHub;
            if (data.Type == 25 || data.Type == 27) {
              //报警开始
              this.serial = data.Serial;
              this.alarmDevice = data.DeviceName;
              this.alarmType = data.Type == 25 ? "紧急援助" : "业务咨询";
              this.alarmShow = true;
            }
            if (data.Type == 24 || data.Type == 26) {
              //报警结束
              this.alarmShow = false;
            }
      } catch (err) {
        err;
      }
    }
  }
};
</script>

