<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:playMedia="true"
      v-bind:playText="true"
      v-on:select="refresh"
      v-on:playMedia="playMedia"
      v-on:playText="playText"
    >
    </actionbar>
    <cityBroadCastPage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:stopRun="stopRun"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></cityBroadCastPage>
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('终端列表')" :label-cols="2">
          <div>
            <b-check-group
              :options="iterminalArray"
              v-model="iterminals"
              disabled-field="notEnabled"
            ></b-check-group>
          </div>
        </b-form-group>
        <b-form-group
          v-bind:label="$t('媒体列表')"
          :label-cols="2"
          v-if="title === '播放文件'"
        >
          <b-form-select :options="mediaArray" v-model="medias"></b-form-select>
        </b-form-group>

        <b-form-group
          v-bind:label="$t('播放文本')"
          :label-cols="2"
          v-if="title === '播放文本'"
        >
          <b-form-input type="text" v-model="text" required></b-form-input>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button
              style="width: 100px"
              variant="secondary"
              v-on:click="hiden"
              >{{ $t("取消") }}</b-button
            >
            <b-button
              type="button"
              style="width: 100px"
              variant="primary"
              v-on:click="onSubmit"
              >{{ $t("开始播放") }}</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import actionbar from "@/components/actionbar";
import cityBroadCastPage from "./components/cityBroadCastPage.vue";

export default {
  components: { actionbar, cityBroadCastPage },
  data: function () {
    return {
      title: "",
      iterminalArray: [],
      iterminals: [],
      mediaArray: [],
      medias: "",
      text: "",
      playItem: {},
      loading: {},
      cols: [
        { name: this.$t("终端名称"), value: "Name" },
        { name: this.$t("终端Id"), value: "Id" },
        { name: this.$t("广播音量"), value: "BCOutV" },
        { name: this.$t("任务状态"), value: "TaskName", runState: true },
        { name: this.$t("在线状态"), value: "StateName" },
      ],
      items: [],
      total: 0,
      count: 6,
      index: 0,
    };
  },
  mounted: function () {
    this.getIterminal();
    this.getMedia();
    this.select();
  },
  methods: {
    refresh() {
      this.getIterminal();
      this.select();
    },
    tableCreated() {},
    currentPage(val) {
      console.log("val：", val);
      this.index = val - 1;
      this.select();
    },

    select() {
      this.loading = this.$loading({ fullScreen: true });

      let obj = {
        PageIndex: this.index,
        PageCount: this.count,
      };
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/Terminal/GetList", obj, true)
        .then((data) => {
          console.log("data:", data);
          this.items = data.Rows;
          this.items.forEach((element) => {
            if (element.Task === "0") {
              element.TaskName = "空闲";
            } else {
              element.TaskName = "工作中";
            }
            if (element.State === "-1") {
              element.StateName = "离线";
            } else {
              element.StateName = "在线";
            }
          });
          this.total = parseInt(data.Total);
          if (this.total <= this.count) {
            this.index = 0;
          }
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    stopRun(item) {
      console.log("stopRun:", item);
      this.loading = this.$loading({ fullScreen: true });
      let obj = {
        TerminalId: item.Id,
        SrcId: item.Task.split("|")[1],
      };
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/Terminal/SessionControl", obj, true)
        .then((data) => {
          console.log("data:", data);
          this.select();
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    playMedia() {
      console.log("playMedia");
      this.title = this.$t("播放文件");

      this.$refs.modallg.show();
    },
    playText() {
      console.log("playText");
      this.title = this.$t("播放文本");

      this.$refs.modallg.show();
    },
    getIterminal() {
      this.loading = this.$loading({ fullScreen: true });

      this.iterminalArray = [];
      let obj = {
        PageIndex: 0,
        PageCount: 10000,
      };
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/Terminal/GetList", obj, true)
        .then((data) => {
          console.log("data:", data);

          data.Rows.forEach((element) => {
            let dict = { value: "", text: "" };
            dict.value = element.Id;
            dict.text = element.Name;
            dict.notEnabled = element.Task === "0" ? false : true;
            this.iterminalArray.push(dict);
          });

          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    getMedia() {
      this.loading = this.$loading({ fullScreen: true });

      this.mediaArray = [];
      let obj2 = {
        PageIndex: 0,
        PageCount: 10000,
      };
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/Media/GetList", obj2, true)
        .then((data) => {
          console.log("data:", data);
          data.Rows.forEach((element) => {
            let dict = { value: "", text: "" };
            dict.value = element.Name;
            dict.text = element.Name;
            this.mediaArray.push(dict);
          });
          if (data.Rows.length >= 1) {
            this.medias = this.mediaArray[0].value;
          }
        })
        .catch((error) => {
          this.loading.close();
          this.$throw(error);
        });
    },
    hiden() {
      this.$refs.modallg.hide();
    },
    onSubmit(evt) {
      evt.preventDefault();
      console.log("iterminals:", this.iterminals);
      console.log("medias:", this.medias);
      console.log("text:", this.text);
      if (this.title === "播放文件") {
        this.loading = this.$loading({ fullScreen: true });
        let obj = {
          Content: this.medias,
          Terminals: this.iterminals,
        };
        this.$ksajax
          .ksPostPromise("/api/CityBroadcast/RealTime/PlayMeidaFile", obj, true)
          .then((data) => {
            console.log("data:", data);
            this.playItem = data;
            this.hiden();
            this.select();
            if (this.loading.close) {
              this.loading.close();
            }
          })
          .catch((err) => {
            this.$throw(err, this);
            if (this.loading.close) {
              this.loading.close();
            }
          });
      } else if (this.title === "播放文本") {
        this.loading = this.$loading({ fullScreen: true });
        let obj = {
          Content: this.text,
          Terminals: this.iterminals,
        };
        this.$ksajax
          .ksPostPromise("/api/CityBroadcast/RealTime/PlayText", obj, true)
          .then((data) => {
            console.log("data:", data);
            this.playItem = data;
            this.playItem = data;
            this.hiden();
            if (this.loading.close) {
              this.loading.close();
            }
          })
          .catch((err) => {
            this.$throw(err, this);
            if (this.loading.close) {
              this.loading.close();
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>