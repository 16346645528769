<template>
  <div class="broadset">
    <div class="login">
      <b-container>
        <b-row>
          <b-col cols="12" md="8" lg="6" offset-lg="3" offset-md="2">
            <div class="login-form" style="margin: 5%; position: relative">
              <b-form @submit="onSubmit">
                <b-form-group
                  id="exampleInputGroup1"
                  v-bind:label="$t('用户名')"
                  label-for="exampleInput1"
                >
                  <b-form-input
                    id="exampleInput1"
                    type="text"
                    v-model="form.UserName"
                    required
                    v-bind:placeholder="$t('用户名')"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  id="exampleInputGroup2"
                  v-bind:label="$t('密码')"
                  label-for="exampleInput2"
                >
                  <b-form-input
                    id="exampleInput2"
                    type="password"
                    v-model="form.Password"
                    required
                    v-bind:placeholder="$t('密码')"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  id="exampleInputGroup3"
                  v-bind:label="$t('服务器地址')"
                  label-for="exampleInput3"
                >
                  <b-form-input
                    id="exampleInput3"
                    type="text"
                    v-model="form.BaseAddress"
                    required
                    v-bind:placeholder="$t('服务器地址')"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  id="exampleInputGroup4"
                  v-bind:label="$t('终端编号最小值')"
                  label-for="exampleInput4"
                >
                  <b-form-input
                    id="exampleInput4"
                    type="number"
                    v-model="form.MinId"
                    required
                    v-bind:placeholder="$t('终端编号最小值')"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  id="exampleInputGroup5"
                  v-bind:label="$t('终端编号最大值')"
                  label-for="exampleInput5"
                >
                  <b-form-input
                    id="exampleInput5"
                    type="number"
                    v-model="form.MaxId"
                    required
                    v-bind:placeholder="$t('终端编号最大值')"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  id="exampleInputGroup6"
                  v-bind:label="$t('区域Id')"
                  label-for="exampleInput6"
                >
                  <b-form-input
                    id="exampleInput6"
                    type="text"
                    v-model="form.ZoneId"
                    required
                    v-bind:placeholder="$t('区域Id')"
                  >
                  </b-form-input>
                </b-form-group>
                <hr />
                <div style="text-align: center">
                  <b-button
                    type="submit"
                    variant="primary"
                    style="width: 100%"
                    >{{ $t("初始化") }}</b-button
                  >
                </div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<style>
.broadset {
  height: 100%;
}
</style>

<script>
import actionbar from "@/components/actionbar";

export default {
  components: {
    actionbar,
  },

  data: function () {
    return {
      form: {
        UserName: "",
        PassWord: "",
        BaseAddress: "",
        MinId: 0,
        MaxId: 10,
        ZoneId: "",
      },
      loading: {},
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/Init", this.form, true)
        .then(() => {
          this.loading.close();
          this.$message("初始化成功");
        })
        .catch((err) => {
          this.loading.close();
          this.$throw(err);
        });
    },
  },
};
</script>

