import Vue from 'vue'
import Vuex from 'vuex'
import http from '@/assets/utils/kshttp.js'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
      login:null,
      menu:{},
      menuLoaded:false,
      errorMsg:"",
      isError:false,
      lStandType:[],
      lStands:[],
      deviceType:[],
      broadcast:{
        url:"http://120.25.203.34:25580",
        user:"admin",
        pass:"admin",
        session:""
      },
      broads:null,
      meidas:null,
      sz:null,
      lan:"zh",
      dataHub:{},
      dataHubChange:0,
      actionHub:{},
      actionHubChange:0,
      alarmHub:{},
      alarmHubChange:0,
      faceHub:{},
      faceHubChange:0,
      playHub:{},
      playHubChange:0,
      novaPlayHub:{},
      novaPlayHubChange:0,
      carPoliceHub:{},
      carPoliceHubChange:0,
      devices:[],
      project:null,
      alarms:null,
      projectData:null,
      custom:false,
      currentUrl:null
    },
    mutations: {
      setCurrentUrl(state,data){
        state.currentUrl=data;
      },
      setCustom(state,data){
        state.custom=data;
      },
      setProjectData(state,data){
        state.projectData=data;
      },
      setAlarms(state,data){
        state.alarms=data;
      },
      setProject(state,data){
        state.project=data;
      },
      setDevices(state,data){
        state.devices=data;
      },
      setPlayHub(state,data){
        state.playHub=data;
        state.playHubChange=Math.random();
      },
      setNovaPlayHub(state,data){
        state.novaPlayHub=data;
        state.novaPlayHubChange=Math.random();
      },
      setCarPoliceHub(state,data){
        state.carPoliceHub=data;
        state.carPoliceHubChange=Math.random();
      },
      setFaceHub(state,data){
        state.faceHub=data;
        state.faceHubChange=Math.random();
      },
      setAlarmHub(state,data){
        state.alarmHub=data;
        state.alarmHubChange=Math.random();
      },
      setActionHub(state,data){
        state.actionHub=data;
        state.actionHubChange=Math.random();
      },
      setDataHub(state,data){
        state.dataHub=data;
        state.dataHubChange=Math.random();
      },
      setLogin(state,login){
        state.login=login;
      },
      setLan(state,lan){
        state.lan=lan;
      },
      setMenu(state,menu){
        state.menu=menu;
      },setmenuLoaded(state){
        state.menuLoaded=true;
      },seterrorMsg(state,errorMsg){
        state.errorMsg=errorMsg;
        state.isError=!state.isError;
      }
      ,setlStandType(state,lStandType){
        state.lStandType=lStandType;
      }
      ,setlStands(state,lStands){
        state.lStands=lStands;
      }
      ,setdeviceType(state,lStands){
        state.deviceType=lStands;
      },setBroadcast(state,broadcast){
        state.broadcast=broadcast;
      },setBroadcastSession(state,session){
        state.broadcast.session=session;
      },
      setMedia(state,meidas){
        state.meidas=meidas;
      },
      setBroads(state,borads){
        state.borads=borads;
      },
      setSz(state,setSz){
        state.sz=setSz;
      }
    },
    actions:{
      menuAction(context,success,error){  
        var url="/api/smart/GetMenu";
        var data="";
        http.ksPostData(
          url,data,true,function(sucess){
            context.commit("setMenu",sucess);
            context.commit("setmenuLoaded",true);
            if(success){
              success();
            }
          },function(err){
            if(error){
              error(err);
            }
          },true
        );
      },
      lStandTypeAction(context,fun,error){
        var url="/api/Asset/GetLSTypes";
        var data="";
        http.ksPostData(url,data,true,
          (success)=>{
            var array =[];
            for(var i=0;i<success.length;i++){
              var obj ={
                text:`${success[i].TypeName}`,
                value:success[i].Sid,
                pic:success[i].Pic
              }; 
              array.push(obj);
            }
            context.commit("setlStandType",array);
            if(fun){
              fun();
            }
          }
          ,(err)=>{
            if(error){
              error(err);
            }
          },true
          );
      },
      lstandAction(context,fun,error){
        var url="/api/Asset/GetLampStands";
        var data={
          Index:0,
          Count:100000
        };
        http.ksPostData(url,data,true,
          (success)=>{
            var array =[];
            for(var i=0;i<success.Items.length;i++){
              var obj ={
                text:`${success.Items[i].Serial}(${success.Items[i].Description})`,
                value:success.Items[i].Sid,
                pic:success.Items[i].LampStandType.Pic
              }; 
              array.push(obj);
            }
            context.commit("setlStands",array);
            if(fun){
              fun();
            }
          }
          ,(err)=>{
            if(error)
            {
              error(err);
            }
          },true
          );
      },
      deviceTypeAction(context,fun,error){
        var url="/api/device/GetDTypes";
        var data="";
        http.ksPostData(url,data,true,
          (success)=>{
            var array =[];
            for(var i=0;i<success.length;i++){
              var obj ={
                text:`${success[i].ModelNumber}(${success[i].TypeName})`,
                value:success[i].Sid,
                pic:success[i].Pic,
                DCategory:success[i].DCategory,
                VendorType:success[i].VendorType,
                VendorId:success[i].VendorId
              }; 
              array.push(obj);
            }
            context.commit("setdeviceType",array);
            if(fun){
              fun();
            }
          }
          ,(err)=>{
            if(error){
              error(err);
            }
          },true
          );
      }
    },
    getters:{
      getSonMenu:(state) => (sid) => {
        console.log("test");
        if(state.menu){
          if(sid==="0"){
            return state.menu.Sons;
          }
          if(state.menu.Sons&&state.menu.Sons.length!==0){
            for(var i=0;i<state.menu.Sons.length;i++){
              if(state.menu.Sons[i].Sid===sid){
                return state.menu.Sons[i].Sons;
              }
            }
         }else{
          return [];

         }
        }
        else{
          return [];
        }
      },
      getSonMenuPath:(state) => (path) => {
        var obj ={};
        if(!path){
          return obj;
        }
        if(state.menu){
          if(state.menu.Path===path){
            obj=state.menu;
          }
          if(state.menu.Sons&&state.menu.Sons.length!==0){
            state.menu.Sons.forEach(e => {
               if(e.Path.toLowerCase()===path.toLowerCase()){
                 obj=e;
               }
            });
         }
        }
        return obj;
      },
      getDeviceType:(state)=>(type,vid)=>{
        var ret =[];
        for(var i=0;i<state.deviceType.length;i++){
          if((state.deviceType[i].DCategory===type||!type)&&(state.deviceType[i].VendorId==vid||!vid)){
            ret.push(state.deviceType[i]);
          }
        }
        return ret;
      },
      getDeviceCustom:(state)=>(type)=>{
        var ret =[];
        for(var i=0;i<state.deviceType.length;i++){
          if(state.deviceType[i].DCategory===type){
            var obj={
              text:state.deviceType[i].text,
              value:state.deviceType[i].VendorType,
              sid:state.deviceType[i].value
            }
            ret.push(obj);
          }
        }
        return ret;
      },
      getBroadById:(state)=>(ids)=>{
        var ret=[];
        if(state.borads){
          for(var i=0;i<state.borads.length;i++){
            if(!ids||ids.length==0||ids.indexOf(parseInt(state.borads[i].Serial))!=-1){
              ret.push({text:state.borads[i].DeviceName,value:parseInt(state.borads[i].Serial)});
            }
          }
        }
        return ret;
      },
      getMediaById:(state)=>(ids)=>{
        var ret=[];
        if(state.meidas){
          for(var i=0;i<state.meidas.length;i++){
            if(!ids||ids.length==0||ids.indexOf(parseInt(state.meidas[i].ID))!=-1){
              ret.push({text:state.meidas[i].Name,value:parseInt(state.meidas[i].ID)});
            }
          }
        }
        return ret;
      }
    }
  })