<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
    ></actionbar>
    <!--         
        <tablepage ref="tableref" id="tableref"
         v-on:created='tableCreated'
         v-on:currentPage='currentPage'
         v-on:currentRow='currentRow'
         :cols='cols'
         :items='items'
         :total='total'
         :perpage='count'
    ></tablepage>-->
    <el-table
    stripe
      :data="items"
      style="width: 100%"
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
      <el-table-column :label="$t('类型')" prop="Type"></el-table-column>
      <el-table-column :label="$t('大小')+'(kb)'" prop="Size"></el-table-column>
      <el-table-column :label="$t('内容')">
        <template slot-scope="scope">
          <span
            v-if="scope.row.SType==0||scope.row.SType==2||scope.row.SType==3||scope.row.SType==6"
          >{{scope.row.Content}}</span>
          <b-img v-if="scope.row.SType==1" :src="scope.row.Content" width="30px"></b-img>
          <div v-if="scope.row.SType==4">
            <b-img v-for="item in scope.row.Content" :key="item" :src="item" width="30px"></b-img>
          </div>
          <div v-if="scope.row.SType==5">
            <b-form-checkbox-group
              :checked="scope.row.Custom.sensor"
              :disabled="true"
              :options="scope.row.Custom.sensor"
            ></b-form-checkbox-group>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('类型')" :label-cols="2">
          <b-form-select :options="types" v-model="modalData.Type" required></b-form-select>
        </b-form-group>
        <b-form-group v-bind:label="$t('名称')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Name" required></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="modalData.Type==0||modalData.Type==3||modalData.Type==6||modalData.Type==9"
          v-bind:label="$t('内容')"
          :label-cols="2"
        >
          <b-form-input type="text" v-model="modalData.Content" required></b-form-input>
        </b-form-group>
        <b-form-group v-if="modalData.Type==5" v-bind:label="$t('传感器')" :label-cols="2">
          <b-form-select
            :options="sensors"
            v-model="modalData.Content"
            required
            v-on:change="sensorChange"
          ></b-form-select>
        </b-form-group>
        <b-form-group v-if="modalData.Type==5" v-bind:label="$t('传感器数据')" :label-cols="2">
          <!-- <b-form-select :options="sensorDatas" v-model="modalData.Custom.sensor" required> </b-form-select>  -->
          <b-form-checkbox-group v-model="modalData.Custom.sensor" :options="sensorDatas"></b-form-checkbox-group>
        </b-form-group>

        <b-form-group v-if="modalData.Type==1" v-bind:label="$t('图片')" :label-cols="2">
          <el-upload
            :on-success="Uploaded"
            class="upload-demo"
            :action="baseUrl+'/api/Upload/FileUploadSingle'"
            :file-list="fileList"
            :data="fileData"
            :limit="1"
            list-type="picture"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            accept="image/gif, image/jpeg, image/png"
          >
            <el-button size="small" type="primary">{{$t('上传到服务器')}}</el-button>
            <div slot="tip" class="el-upload__tip">{{$t('图像上传类型')}}</div>
          </el-upload>
        </b-form-group>

        <b-form-group v-if="modalData.Type==4" v-bind:label="$t('图片列表')" :label-cols="2">
          <el-upload
            :on-success="Uploaded"
            class="upload-demo"
            :action="baseUrl+'/api/Upload/FileUploadSingle'"
            :file-list="fileList"
            :data="fileData"
            :limit="100"
            list-type="picture"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            accept="image/gif, image/jpeg, image/png"
          >
            <el-button size="small" type="primary">{{$t('上传到服务器')}}</el-button>
            <div slot="tip" class="el-upload__tip">{{$t('图像上传类型数量')}}</div>
          </el-upload>
        </b-form-group>
        <b-form-group v-if="modalData.Type==2" v-bind:label="$t('视频')" :label-cols="2">
          <el-upload
            :on-success="Uploaded"
            class="upload-demo"
            :action="baseUrl+'/api/Upload/FileUploadSingle'"
            :file-list="fileList"
            :data="fileData"
            :limit="1"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            accept=".mp4"
          >
            <el-button size="small" type="primary">{{$t('上传到服务器')}}</el-button>
            <div slot="tip" class="el-upload__tip">
              {{$t('MP4上传类型')}}
              <a :href="baseUrl+'/doc/format.zip'">格式工厂下载</a>
            </div>
          </el-upload>
        </b-form-group>

        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width:100px" v-on:click="hiden">Cancel</b-button>
            <b-button type="submit" style="width:100px" variant="primary">OK</b-button>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>

    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/resource/DelLedResource"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<style>
.s {
  border: 1px solid black;
}
.fontcolor
  .el-color-picker__trigger
  .el-color-picker__color
  .el-color-picker__color-inner {
  background-color: red;
}
</style>

<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import deletedata from "@/components/deletedata";
import { sensorFormat, getJson } from "@/assets/utils/datef";
import {BaseUrl} from "@/assets/utils/config"
export default {
  components: { actionbar, tablepage, deletedata },
  data: function() {
    return {
      baseUrl:BaseUrl,
      fontFamily: [
        { text: "Helvetica Neue", value: "Helvetica Neue" },
        { text: "PingFang SC", value: "PingFang SC" },
        { text: "Hiragino Sans GB", value: "Hiragino Sans GB" },
        { text: "Helvetica", value: "Helvetica" },
        { text: "微软雅黑", value: "微软雅黑" },
        { text: "Arial", value: "Arial" },
        { text: "sans-serif", value: "sans-serif" }
      ],
      fontSize: [
        { text: this.$t("X1"), value: "1em" },
        { text: this.$t("X2"), value: "2em" },
        { text: this.$t("X3"), value: "3em" },
        { text: this.$t("X4"), value: "4em" },
        { text: this.$t("X5"), value: "5em" }
      ],
      sensors: [],
      sensorDatas: [],
      fileList: [],
      fileData: {},
      modalData: this.resetData(),
      loading: {},
      cols: [
        { name: this.$t("名称"), value: "Name" },
        { name: this.$t("类型"), value: "Type" },
        { name: this.$t("大小"), value: "Size" },
        { name: this.$t("内容"), value: "Content" }
      ],
      items: [],
      total: 0,
      count: 100000,
      index: 0,
      sid: "",
      title: "",
      current: null,
      types: [
        { text: this.$t("文本"), value: 0 },
        { text: this.$t("图片"), value: 1 },
        { text: this.$t("视频"), value: 2 },
        { text: this.$t("滚动文本"), value: 3 },
        { text: this.$t("滚动图片"), value: 4 },
        { text: this.$t("传感器数据"), value: 5 },
        { text: this.$t("摄像头"), value: 6 },
        { text: this.$t("通用报警图片"), value: 7 },
        { text: this.$t("灯具亮度数据"), value: 8 },
        { text: this.$t("内容可变文本"), value: 9 }
      ]
    };
  },
  methods: {
    select(noloading, index) {
      console.log(this.baseUrl);
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      let obj = {
        Index: this.index,
        Count: this.count
      };
      this.$ksajax
        .ksPostPromise("/api/resource/GetLedResources", obj, true)
        .then(data => {
          data.Items.forEach(element => {
            element.SType = element.Type;
            element.Type = this.types.find(x => {
              return x.value == element.Type;
            }).text;
            element.Content = getJson(element.Content);
            element.Custom = getJson(element.Custom);
          });
          this.items = data.Items;
          this.total = data.Items.length;
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch(err => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sensors = [];
      this.sensorDatas = [];
      this.modalData = {
        Sid: this.current.Sid,
        Name: this.current.Name,
        Type: this.current.SType,
        Content: this.current.Content,
        Size: this.current.Size,
        Duration: this.current.Duration,
        Custom: this.current.Custom
      };
      this.fileList = [];
      if (this.current.SType == 1 || this.current.SType == 2) {
        this.fileList.push({
          name: this.current.Name,
          url: this.current.Content
        });
      }
      if (this.current.SType == 4) {
        var array = this.current.Content;
        array.forEach(ele => {
          this.fileList.push({
            name: this.$t("图片"),
            url: ele,
            size: 0
          });
        });
      }
      var sid = null;
      if (this.current.SType == 5) {
        sid = this.current.Content;
      }
      this.title = this.$t("修改");
      this.show(sid);
    },
    insert() {
      this.modalData = this.resetData();
      this.sensors = [];
      this.title = this.$t("新增");
      this.fileList = [];
      this.show();
    },
    hiden() {
      this.$refs.modallg.hide();
    },
    show(sid) {
      let obj = {
        Index: 0,
        Count: 1000
      };
      this.loading = this.$loading({ fullScreen: true });
      this.$ksajax
        .ksPostPromise("/api/sensor/GetSensors", obj, true)
        .then(data => {
          for (var i = 0; i < data.Items.length; i++) {
            this.sensors.push({
              text: data.Items[i].DeviceName,
              value: data.Items[i].Sid
            });
          }
        })
        .then(() => {
          if (sid) {
            return this.$ksajax.ksPostPromise(
              "/api/sensor/GetSensorData",
              sid,
              true
            );
          }
        })
        .then(data => {
          if (data) {
            this.sensorDatas = sensorFormat(data.SensorData);
          }
          this.loading.close();
          this.$refs.modallg.show();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      return {
        Sid: "",
        Name: "",
        Type: 0,
        Content: "",
        Size: 0,
        Duration: 0,
        Custom: {}
      };
    },
    submited() {
      this.select();
    },
    deleted() {
      this.select();
    },
    onSubmit(evt) {
      evt.preventDefault();
      let data = Object.assign({}, this.modalData);
      this.loading = this.$loading({ fullScreen: true });
      if (data.Type != 5) {
        delete data.Custom.sensor;
        data.Custom = "";
      } else {
        data.Custom = JSON.stringify(data.Custom);
      }
      if (data.Type == 7) {
        data.Content = "alarm";
      }
      if (data.Type == 8) {
        data.Content = "bright";
      }
      data.size = data.Content.length;
      if (data.Type == 1) {
        if (this.fileList.length != 1) {
          throw this.$t("请上传一张图片");
        }
        data.Content = this.fileList[0].url;
        data.Size = this.fileList[0].size;
      }
      if (data.Type == 2) {
        if (this.fileList.length != 1) {
          throw this.$t("请上传一个MP4视频");
        }
        data.Content = this.fileList[0].url;
        data.Size = this.fileList[0].size;
      }
      if (data.Type == 4) {
        if (this.fileList.length <= 1) {
          throw this.$t("请上传一张以上的图片");
        }
        data.Content = [];
        let size = data.Size;
        for (var i = 0; i < this.fileList.length; i++) {
          data.Content.push(this.fileList[i].url);
          size += this.fileList[i].size;
        }
        data.Content = JSON.stringify(data.Content);
        data.size = size;
      }
      this.$ksajax
        .ksPostPromise("/api/resource/AddLedResource", data, true)
        .then(() => {
          this.loading.close();
          this.hiden();
          this.select();
        })
        .catch(err => {
          this.loading.close();
          this.$throw(err);
        });
    },
    Uploaded(response, file) {
      if (response.RetCode == 0) {
        file.url = response.Data;
        this.fileList.push(file);
      } else {
        throw response.RetMsg;
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePreview() {},
    sensorChange(ele) {
      if (ele) {
        this.sensorDatas = [];
        this.modalData.Custom.sensor = [];
        let obj = ele;
        this.loading = this.$loading({ fullScreen: true });
        this.$ksajax
          .ksPostPromise("/api/sensor/GetSensorData", obj, true)
          .then(data => {
            this.sensorDatas = sensorFormat(data.SensorData);
            this.loading.close();
          })
          .catch(err => {
            this.$throw(err);
            this.loading.close();
          });
      }
    }
  },
  mounted: function() {
    this.select();
  }
};
</script>
