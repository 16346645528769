<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      :send="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
      v-on:publish="publish"
    ></actionbar>
    <el-table
      :data="items"
      class="demo-table"
      stripe=""
      @current-change="handleCurrentChange"
      highlight-current-row
      :empty-text="$t('暂无数据')"
    >
      <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column
        :label="$t('创建时间')"
        prop="createDate"
      ></el-table-column>
      <el-table-column :label="$t('创建人')" prop="createBy"></el-table-column>
    </el-table>
    <b-modal
      id="timmerModal"
      ref="timmerModal"
      :title="title"
      size="lg"
      hide-footer
    >
      <b-form ref="form" @submit="onSubmit">
        <b-form-group v-bind:label="$t('名称')" :label-cols="2">
          <b-form-input type="text" v-model="form.Name" required></b-form-input>
        </b-form-group>
        <b-form-group
          v-bind:label="$t('时间安排')"
          :label-cols="2"
          v-for="time in form.Shedules"
          :key="time.Sid"
        >
          <div class="sceneTemp">
            <div style="text-align: right; margin-bottom: 5px">
              <i
                class="fa fa-trash-o"
                style="cursor: pointer"
                @click="delSchedule(time.Sid)"
              ></i>
              <i
                class="fa fa-plus"
                style="cursor: pointer"
                @click="addSchedule(time.Sid)"
              ></i>
            </div>
            <b-form-group v-bind:label="$t('日期')" :label-cols="2">
              <b-form-checkbox
                v-model="time.dateType"
                value="All"
                unchecked-value="Range"
                @change="dateChange($event, time)"
                >{{ $t("不限") }}</b-form-checkbox
              >
              <div v-if="time.dateType == 'Range'">
                <el-date-picker
                  v-model="time.startDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker
                >-
                <el-date-picker
                  v-model="time.endDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </div>
            </b-form-group>
            <b-form-group v-bind:label="$t('时间')" :label-cols="2">
              <b-form-checkbox
                v-model="time.timeType"
                value="All"
                unchecked-value="Range"
                @change="timeChange($event, time)"
                >{{ $t("不限") }}</b-form-checkbox
              >
              <div v-if="time.timeType == 'Range'">
                <el-time-picker
                  v-model="time.startTime"
                  placeholder="选择时间"
                  value-format="HH:mm"
                ></el-time-picker
                >-
                <el-time-picker
                  v-model="time.endTime"
                  placeholder="选择时间"
                  value-format="HH:mm"
                ></el-time-picker>
              </div>
            </b-form-group>
            <b-form-group v-bind:label="$t('星期')" :label-cols="2">
              <b-form-checkbox
                v-model="time.filterType"
                value="None"
                unchecked-value="Week"
                @change="weekChange($event, time)"
                >{{ $t("不限") }}</b-form-checkbox
              >
              <b-check-group
                v-if="time.filterType == 'Week'"
                :options="Weeks"
                v-model="time.weekFilter"
              ></b-check-group>
            </b-form-group>
          </div>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width: 100px" v-on:click="hidden">Cancel</b-button>
            <b-button type="submit" style="width: 100px" variant="primary"
              >OK</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="publishmodal"
      class="ksmodal"
      ref="publishmodal"
      size="lg"
      :title="$t('发布')"
      body-text-variant="dark"
      @ok="publishStage"
    >
      <hr />
      <el-table
        ref="multipleTable"
        :data="devices"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :empty-text="$t('暂无数据')"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          :label="$t('名称')"
          prop="DeviceName"
        ></el-table-column>
        <el-table-column :label="$t('宽度')" prop="Width"></el-table-column>
        <el-table-column :label="$t('高度')" prop="Height"></el-table-column>
        <el-table-column :label="$t('位置')" prop="Address"></el-table-column>
        <el-table-column :label="$t('查询')">
          <template slot-scope="scope">
            <el-button @click="showTime(scope.row.Serial)">{{
              $t("查询")
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </b-modal>
    <b-modal
      id="subModal"
      ref="subModal"
      size="md"
      :title="$t('设备定时任务')"
      ok-only
    >
      <div v-if="!subForm">{{ $t("无") }}</div>
      <div v-else>
        <el-table :data="subForm">
          <el-table-column :label="$t('日期')">
            <template slot-scope="scope">
              <div v-if="scope.row.dateType == 'All'">{{ $t("不限") }}</div>
              <div v-else>
                {{ scope.row.startDate }}-{{ scope.row.endDate }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('时间')">
            <template slot-scope="scope">
              <div v-if="scope.row.timeType == 'All'">{{ $t("不限") }}</div>
              <div v-else>
                {{ scope.row.startTime }}-{{ scope.row.endTime }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('星期')">
            <template slot-scope="scope">
              <div v-if="scope.row.filterType != 'Week'">{{ $t("不限") }}</div>
              <div v-else>
                {{ $t("每星期")
                }}<span v-for="item in scope.row.weekFilter" :key="item"
                  >{{ item }}
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/led/DeleteTimmer"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>

<script>
import actionbar from "@/components/actionbar";
import { GetGuid, formatDate } from "@/assets/utils/datef";
import deletedata from "@/components/deletedata";
export default {
  components: { actionbar, deletedata },
  mounted: function () {
    this.select();
  },
  data: function () {
    return {
      form: {},
      title: "",
      current: null,
      sid: "",
      Weeks: [
        { value: 1, text: this.$t("星期一") },
        { value: 2, text: this.$t("星期二") },
        { value: 3, text: this.$t("星期三") },
        { value: 4, text: this.$t("星期四") },
        { value: 5, text: this.$t("星期五") },
        { value: 6, text: this.$t("星期六") },
        { value: 7, text: this.$t("星期天") },
      ],
      items: [],
      devices: [],
      loading: null,
      deviceSelect: [],
      subForm: null,
    };
  },
  methods: {
    showTime(serial) {
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/GetDevTimmer", serial, true)
        .then((data) => {
          this.subForm = data;
          this.$refs.subModal.show();
          this.loading.close();
          this.loading.close();
        })
        .catch((err) => {
          this.$throw(err);
          this.loading.close();
        });
    },
    handleSelectionChange(val) {
      this.deviceSelect = val;
    },
    publishStage() {
      if (this.deviceSelect && this.deviceSelect.length > 0) {
        var obj = {
          Sid: this.current.Sid,
          Serials: [],
        };
        this.deviceSelect.forEach((x) => {
          obj.Serials.push(x.Serial);
        });
        this.loading = this.$loading({ fullScreen: true });
        this.$ksajax
          .ksPostPromise("/api/led/PublishTimmer", obj, true)
          .then(() => {
            this.loading.close();
            this.$refs.publishmodal.hide();
          })
          .catch((err) => {
            this.$throw(err);
            this.loading.close();
            this.$refs.publishmodal.hide();
          });
      }
    },
    publish() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.loading = this.$loading({ fullScreen: true });
      new Promise((res, rej) => {
        if (this.devices.length != 0) {
          res();
        } else {
          //获取设备列表
          let obj = {
            Index: 0,
            Count: 10000,
          };
          this.$ksajax
            .ksPostPromise("/api/device/GetDeviceLedAds", obj, true)
            .then((data) => {
              this.devices = data.Items;
              res();
            })
            .catch((err) => {
              rej(err);
            });
        }
      })
        .then(() => {
          this.loading.close();
          this.$refs.publishmodal.show();
        })
        .catch((err) => {
          this.loading.close();
          this.$throw(err);
        });
    },
    insert() {
      this.form = this.getResetForm();
      this.title = this.$t("新增");
      this.$refs.timmerModal.show();
    },
    del() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    deleted() {
      this.select();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.form = this.current;
      this.title = this.$t("修改");
      this.$refs.timmerModal.show();
    },
    select() {
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/GetTimmer", "", true)
        .then((data) => {
          this.items = data;
          this.loading.close();
          this.hidden();
        })
        .catch((err) => {
          this.loading.close();
          this.hidden();
          this.$throw(err);
        });
    },
    hidden() {
      this.$refs.timmerModal.hide();
    },
    onSubmit(event) {
      event.preventDefault();
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/CreateOrModifyTimmer", this.form, true)
        .then(() => {
          this.hidden();
          this.loading.close();
          this.select();
        })
        .catch((err) => {
          this.loading.close();
          this.hidden();
          this.$throw(err);
        });
    },
    addSchedule() {
      this.form.Shedules.push({
        Sid: GetGuid(),
        dateType: "All",
        startDate: null,
        endDate: null,
        timeType: "All",
        startTime: null,
        endTime: null,
        weekFilter: [],
        filterType: "None",
      });
    },
    delSchedule(sid) {
      if (this.form.Shedules.length <= 1) {
        return;
      } else {
        this.form.Shedules = this.form.Shedules.filter((x) => x.Sid != sid);
      }
    },
    weekChange(event, time) {
      if (event == "None") {
        time.weekFilter = [];
      }
    },
    timeChange(event, time) {
      if (event == "All") {
        time.startTime = null;
        time.endTime = null;
      } else {
        time.startTime = formatDate(new Date(), "hh:mm");
        time.endTime = formatDate(new Date(), "hh:mm");
      }
    },
    dateChange(event, time) {
      if (event == "All") {
        time.startDate = null;
        time.endDate = null;
      } else {
        time.startDate = formatDate(new Date(), "yyyy-MM-dd");
        time.endDate = formatDate(new Date(), "yyyy-MM-dd");
      }
    },
    handleCurrentChange(val) {
      this.current = val;
    },
    getResetForm() {
      return {
        Sid: "",
        Name: "",
        Shedules: [
          {
            startDate: "",
            endDate: "",
            startTime: "",
            endTime: "",
            weekFilter: [],
            Sid: GetGuid(),
            dateType: "All",
            timeType: "All",
            filterType: "None",
          },
        ],
      };
    },
  },
};
</script>

<style>
</style>