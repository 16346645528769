<template>
  <div
    style="color: white; height: 100%; position: relative"
    v-loading="loading"
    class="bigView"
  >
    <div style="width: 10%; height: 65%; padding: 10px 0 0 10px">
      <box style="width: 100%; height: 100%">
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="
            currentSid === item.Sid
              ? 'slider_router slider_router_check'
              : 'slider_router'
          "
          @click="change(item.Sid)"
        >
          {{ item.Name }}
        </div>
      </box>
    </div>
    <div class="mapView">
      <ksmap
        v-show="map"
        ref="map"
        width="100%"
        :showCenter="false"
        height="100%"
        v-bind:lat="0"
        v-bind:lng="0"
        v-bind:zoom="25"
      >
      </ksmap>
    </div>

    <div class="detailviewL">
      <div class="detailviewItem">
        <box style="position: relative">
          <pie
            :radius="true"
            :centerlable="'总数'"
            :size="'small'"
            ref="deviceOnline"
          ></pie>
        </box>
      </div>

      <div class="detailviewItem">
        <box id="canvas_box" style="position: relative">
          <pie
            :radius="true"
            :centerlable="'总数'"
            :size="'small'"
            ref="lawType"
          ></pie>
        </box>
      </div>
    </div>

    <div class="detailviewR">
      <div class="detailviewItem2">
        <box>
          <div style="width: 100%; height: 100%">
            <linebar ref="plateType"></linebar>
          </div>
        </box>
      </div>

      <div class="detailviewItem2">
        <box>
          <linebar ref="carCount" :smooth="true"></linebar>
        </box>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bigView {
  display: flex;
}
.slider_router {
  padding-left: 10px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}
.slider_router_check {
  color: cadetblue;
}
.mapView {
  width: 60%;
  height: 65%;
  padding: 10px 0 0 10px;
}
.detailviewL {
  width: 30%;
  height: 65%;
  padding: 10px;
}
.detailviewItem {
  width: 100%;
  height: 50%;
  margin-bottom: 10px;
  background: transparent;
}
.detailviewR {
  display: flex;
  width: 100%;
  height: 35%;
  position: absolute;
  bottom: 0;
  padding: 10px 0 10px 10px;
}
.detailviewItem2 {
  width: 50%;
  height: 100%;
  margin-right: 10px;
  background: transparent;
}
</style>
<script>
import box from "@/components/box1.vue";
import pie from "@/components/chart/pie.vue";
import bar from "@/components/chart/bar.vue";
import linebar from "@/components/chart/line.vue";
import ksmap from "@/components/map";
import { BaseUrl } from "@/assets/utils/config";

export default {
  components: { box, pie, bar, linebar, ksmap },
  data() {
    return {
      chartData: {},
      loading: false,
      deviceOnline: null,
      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" },
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" },
      ]),
      color3: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#f7500f" },
        { offset: 0.5, color: "#eec54b" },
        { offset: 1, color: "#f7500f" },
      ]),
      color4: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#f7510f" },
        { offset: 0.5, color: "#ee154b" },
        { offset: 1, color: "#f75020" },
      ]),
      color5: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#6BC416" },
        { offset: 0.5, color: "#119857" },
        { offset: 1, color: "#066336" },
      ]),
      color6: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#4A89CC" },
        { offset: 0.5, color: "#175698" },
        { offset: 1, color: "#073463" },
      ]),
      color7: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#E766E5" },
        { offset: 0.5, color: "#EF34D7" },
        { offset: 1, color: "#9B118A" },
      ]),
      color8: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#F4F4F4" },
        { offset: 0.5, color: "#E9E9E9" },
        { offset: 1, color: "#BBBBBB" },
      ]),
      color9: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#BFBFCA" },
        { offset: 0.5, color: "#C0BBC6" },
        { offset: 1, color: "#B5BBC0" },
      ]),
      color10: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#323232" },
        { offset: 0.5, color: "#282828" },
        { offset: 1, color: "#211010" },
      ]),
      map: true,
      items: [],
      currentSid: "",
      deviceTest1: {
        Name: "园区停车场T1",
        Sid: "t01",
        Alias: "公司停车场t1",
        VCount: 5,
        HCount: 8,
        Geo: null,
        Address: "广东省东莞市常平镇横江厦勤上光电股份有限公司",
        ParkLatitude: 22.9865,
        ParkLongitude: 114.018,
        DeviceCount: 6,
        ParkPortCount: 13,
      },
      deviceTest2: {
        Name: "园区停车场T2",
        Sid: "t02",
        Alias: "公司停车场t2",
        VCount: 5,
        HCount: 3,
        Geo: null,
        Address: "广东省东莞市常平镇横江厦勤上光电股份有限公司",
        ParkLatitude: 22.9863,
        ParkLongitude: 114.017,
        DeviceCount: 6,
        ParkPortCount: 8,
      },
      chartDataT1: {
        parkPortsInfo: { Busy: 6, Idle: 7 },
        deviceStatusInfo: { Online: 6, Offline: 0 },
        sevenDaysParkTimeData: [
          { Date: "2021/08/20 00:00", Hour: 63.49 },
          { Date: "2021/08/21 00:00", Hour: 155.49 },
          { Date: "2021/08/22 00:00", Hour: 198.19 },
          { Date: "2021/08/23 00:00", Hour: 228.96 },
          { Date: "2021/08/24 00:00", Hour: 100.2 },
          { Date: "2021/08/25 00:00", Hour: 50.42 },
          { Date: "2021/08/26 00:00", Hour: 80.29 },
          { Date: "2021/08/27 00:00", Hour: 24.22 },
        ],
        sevenDayChargeData: [
          { Date: "2021/08/20 00:00", Charge: 65.82 },
          { Date: "2021/08/21 00:00", Charge: 159.2 },
          { Date: "2021/08/22 00:00", Charge: 282.97 },
          { Date: "2021/08/23 00:00", Charge: 378.66 },
          { Date: "2021/08/24 00:00", Charge: 146.44 },
          { Date: "2021/08/25 00:00", Charge: 30.0 },
          { Date: "2021/08/26 00:00", Charge: 63.73 },
          { Date: "2021/08/27 00:00", Charge: 10.0 },
        ],
      },
      chartDataT2: {
        parkPortsInfo: { Busy: 5, Idle: 3 },
        deviceStatusInfo: { Online: 6, Offline: 0 },
        sevenDaysParkTimeData: [
          { Date: "2021/08/20 00:00", Hour: 53.49 },
          { Date: "2021/08/21 00:00", Hour: 105.49 },
          { Date: "2021/08/22 00:00", Hour: 128.19 },
          { Date: "2021/08/23 00:00", Hour: 128.96 },
          { Date: "2021/08/24 00:00", Hour: 300.2 },
          { Date: "2021/08/25 00:00", Hour: 150.42 },
          { Date: "2021/08/26 00:00", Hour: 80.29 },
          { Date: "2021/08/27 00:00", Hour: 204.22 },
        ],
        sevenDayChargeData: [
          { Date: "2021/08/20 00:00", Charge: 165.82 },
          { Date: "2021/08/21 00:00", Charge: 259.2 },
          { Date: "2021/08/22 00:00", Charge: 382.97 },
          { Date: "2021/08/23 00:00", Charge: 278.66 },
          { Date: "2021/08/24 00:00", Charge: 106.44 },
          { Date: "2021/08/25 00:00", Charge: 80.0 },
          { Date: "2021/08/26 00:00", Charge: 53.73 },
          { Date: "2021/08/27 00:00", Charge: 82.0 },
        ],
      },
    };
  },
  mounted() {
    this.selectRouter();
    this.select();
  },
  methods: {
    selectRouter() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkList", "", true)
        .then((data) => {
          console.log("parkData:", data);
          this.loading = false;
          var device = [];
          this.items = data;
          if (this.items.length !== 0) {
            this.currentSid = this.items[0].Sid;
            this.items.push(this.deviceTest1);
            this.items.push(this.deviceTest2);
            this.items.forEach((x) => {
              device.push({
                lat: x.ParkLatitude,
                lng: x.ParkLongitude,
                icon: `${BaseUrl}/img/new/ic_car.png`,
                url: "/admin/parking/detail?sid=" + x.Sid,
                title: x.Name,
              });
            });
            this.$refs.map.addDevice(device, 0);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    change(val) {
      this.currentSid = val;
      if (val === "t01") {
        this.chartData = this.chartDataT1;
        this.initDeviceOnline();
        this.initLawType();
        this.initPlateType();
        this.initcarCount();
      } else if (val === "t02") {
        this.chartData = this.chartDataT2;
        this.initDeviceOnline();
        this.initLawType();
        this.initPlateType();
        this.initcarCount();
      } else {
        this.select();
      }
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkReport", "", true)
        .then((data) => {
          this.chartData = data;
          this.loading = false;
          this.initDeviceOnline();
          this.initLawType();
          this.initPlateType();
          this.initcarCount();
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
    initDeviceOnline() {
      var data = [
        {
          name: "在线",
          value: this.chartData.deviceStatusInfo.Online,
        },
        {
          name: "离线",
          value: this.chartData.deviceStatusInfo.Offline,
        },
      ];
      var legend = {
        orient: "vertical",
        left: 10,
        data: ["在线", "离线"],
        bottom: 5,
        show: true,
      };
      var title = {
        show: true,
        text: "设备在线率",
        left: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      var color = [this.color1, this.color2];
      this.$refs.deviceOnline.setAndInit(data, legend, color, null, title);
    },

    initLawType() {
      var data = [
        {
          name: "占用",
          value: this.chartData.parkPortsInfo.Busy,
        },
        {
          name: "空闲",
          value: this.chartData.parkPortsInfo.Idle,
        },
      ];
      var legend = {
        orient: "vertical",
        left: 10,
        data: ["占用", "空闲"],
        bottom: 5,
        show: true,
      };
      var title = {
        show: true,
        text: "泊位占用率",
        left: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      var color = [this.color3, this.color5];
      this.$refs.lawType.setAndInit(data, legend, color, null, title);
    },
    initPlateType() {
      var title = {
        show: true,
        text: "7天停车时长",
        left: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      var data = [
        {
          name: "小时",
          data: [],
        },
      ];
      var xdata = [];
      this.chartData.sevenDaysParkTimeData.forEach((x) => {
        data[0].data.push(x.Hour);
        var name = x.Date.split(" ")[0];
        xdata.push(name);
      });
      var legend = {
        orient: "vertical",
        left: 10,
        data: xdata,
        bottom: 5,
        show: true,
      };
      this.$refs.plateType.setAndInit(
        data,
        legend,
        [this.color2],
        null,
        xdata,
        title
      );
    },
    initcarCount() {
      var title = {
        show: true,
        text: "7天费用趋势",
        left: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      var data = [
        {
          name: "费用",
          data: [],
          color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#ffb669" },
            { offset: 0.5, color: "#ea413f" },
            { offset: 1, color: "#132f57" },
          ]),
        },
      ];
      var xdata = [];
      this.chartData.sevenDayChargeData.forEach((x) => {
        data[0].data.push(x.Charge);
        var name = x.Date.split(" ")[0];
        xdata.push(name);
      });
      var legend = {
        orient: "vertical",
        left: 10,
        data: xdata,
        bottom: 5,
        show: true,
      };
      this.$refs.carCount.setAndInit(
        data,
        legend,
        [this.color1],
        null,
        xdata,
        title
      );
    },
  },
};
</script>


