<template>
  <div style="height: 100%; color: white" class="clearfix">
    <div class="portDetail" style="width: 25%" v-loading="loadingCharge">
      <div style="height: 30%; padding: 5px">
        <box>
          <div style="width: 100%%; height: 100%; overflow: auto">
            <div style="text-align: center; padding-bottom: 10px">计费规则</div>
            <div v-if="!charges || charges.legnth == 0">没有配置计费规则</div>
            <div v-else>
              <div
                v-for="(item, index) in charges"
                :key="item.Sid"
                class="chargeDetail"
              >
                <span>{{ index + 1 }}.</span>
                <span>{{ item.Start }}分钟</span>
                <span>--</span>
                <span>{{
                  index == charges.length - 1 ? "不限时长" : item.End + "分钟"
                }}</span>
                <span>{{ item.Charge }}元</span>
              </div>
            </div>
          </div>
        </box>
      </div>

      <div style="height: 70%; padding: 5px" v-loading="loadingReal">
        <box>
          <div style="width: 100%%; height: 100%; overflow: auto">
            <div style="text-align: center; padding-bottom: 10px">泊位详情</div>
            <el-row>
              <el-col :span="8">泊位ID</el-col>
              <el-col :span="16">{{ currentPort.Sid }}</el-col>
            </el-row>
            <div v-if="currentPort && currentPort.Status == 1">
              <el-row>
                <el-col :span="8">车牌号码</el-col>
                <el-col :span="16">{{ currentPort.Plate }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="8">入场时间</el-col>
                <el-col :span="16">{{ currentPort.InTime }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="8">实时费用</el-col>
                <el-col :span="16"
                  >{{ Math.round(currentPort.Charge) }}元</el-col
                >
              </el-row>
              <el-row>
                <el-col :span="8">入场图片</el-col>
                <el-col :span="16">
                  <img
                    :src="currentPort.InPic"
                    style="width: 100%; max-height: 100%"
                  />
                </el-col>
              </el-row>
            </div>
            <div v-else>该泊位处于空闲状态</div>
          </div>
        </box>
      </div>
    </div>
    <div
      class="portDetail"
      style="width: 75%"
      ref="portParent"
      v-loading="loading"
    >
      <vport
        :vheight="vHeight"
        :ports="vPorts"
        :width="width"
        :current="currentSid"
        v-on:portClick="portClick"
      ></vport>
      <routerV :rheight="rHeight" :width="width"></routerV>
      <hport
        :hheight="hHeight"
        :ports="hPorts"
        :width="width"
        :current="currentSid"
        v-on:portClick="portClick"
      ></hport>
    </div>
  </div>
</template>
<style scoped>
.portDetail {
  float: left;
  height: 100%;
  overflow: auto;
}
.portDetailHori {
  height: 100%;
  width: 100%;
}
.chargeDetail > span {
  padding-left: 15px;
}
</style>
<script>
import vport from "./compoents/vport";
import routerV from "./compoents/router";
import hport from "./compoents/hport";
import Box from "../../../components/box.vue";
export default {
  data() {
    return {
      //竖向车位高度比例
      vHeightRate: 1 / 3,
      vHeight: 0,
      rHeight: 0,
      hHeight: 0,
      width: 0,
      sid: "",
      loading: false,
      loadingCharge: false,
      loadingReal: false,
      currentPort: {},
      currentSid: "",
      charges: [],
      vPorts: [
        {
          IsVertical: true,
          PortId: "0",
        },
      ],
      hPorts: [
        {
          IsVertical: false,
          PortId: "0",
        },
      ],
      items: {
        VCount: 8,
        HCount: 3,
        Prots: [],
      },
    };
  },
  components: { vport, routerV, hport, Box },
  watch: {
    "$store.state.alarmHubChange": function () {
      var data = this.$store.state.alarmHub;
      if (
        data.Type == 700 ||
        data.Type == 701 ||
        data.Type == 702 ||
        data.Type == 703
      ) {
        var alarmData = this.items.Prots.find(
          (x) => x.Sid == data.Data.ParkPortSid
        );
        if (alarmData) {
          alarmData.Plate = data.Data.Plate;
          alarmData.InTime = data.Data.InTime;
          alarmData.Charge = data.Data.Charge;
          alarmData.Status = data.Data.Status;
          alarmData.ParkingId = data.Data.ParkingId;
          alarmData.InPic = data.Data.InPic;
          switch (data.Type) {
            case 700:
              //车辆入场
              alarmData.Status = 1;
              break;
            case 701:
            case 702:
              //车辆出场
              alarmData.Status = 0;
              break;
            case 703:
              //车辆车牌修正
              alarmData.Status = 1;
              break;
            default:
              break;
          }
          this.currentPort = alarmData;
          this.currentSid = alarmData.Sid;
        }
      }
    },
  },
  mounted() {
    this.init();
    this.select();
    this.selectCharge();
    window.addEventListener("resize", () => {
      this.init();
    });
  },
  methods: {
    portClick(sid) {
      //   this.currentSid = sid;
      //   this.currentPort = this.items.Prots.find((x) => x.Sid == sid);
      this.selectChargeReal(sid);
    },
    init() {
      this.sid = this.$route.query.sid;
      this.vHeight = this.$refs.portParent.clientHeight * this.vHeightRate;
      this.rHeight = this.vHeight * 1.5 - 25;
      this.hHeight = this.vHeight / 2;
      this.vPorts = this.items.Prots.filter((x) => x.IsVertical == true);
      this.hPorts = this.items.Prots.filter((x) => x.IsVertical == false);
      var vw = this.vPorts.length * (this.vHeight / 1.5);
      var hw = this.hPorts.length * (this.hHeight * 2.5);
      this.width = Math.max(vw, hw);
    },
    selectCharge() {
      this.loadingCharge = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkRuleList", this.sid, true)
        .then((data) => {
          this.loadingCharge = false;
          console.log(data);
          this.charges = data;
        })
        .catch((err) => {
          this.loadingCharge = false;
          this.$throw(err);
        });
    },
    selectChargeReal(sid) {
      this.loadingReal = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetRealTimeCharge", sid, true)
        .then((data) => {
          this.loadingReal = false;
          this.currentPort = this.items.Prots.find((x) => x.Sid == sid);
          this.currentPort.Charge = data.Charge;
          this.currentSid = sid;
        })
        .catch((err) => {
          this.loadingReal = false;
          this.currentSid = sid;
          this.currentPort = this.items.Prots.find((x) => x.Sid == sid);
          this.$throw(err);
        });
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkPortState", this.sid, true)
        .then((data) => {
          this.loading = false;
          console.log(data);
          var v = this.items.Prots.filter((x) => x.IsVertical == true);
          var h = this.items.Prots.filter((x) => x.IsVertical == false);
          this.items.VCount = v.length;
          this.items.HCount = h.length;
          this.items.Prots = data;
          var ex= this.items.Prots.filter((x) => x.Status == 1);
          if (ex.length != 0) {
            this.portClick(ex[0].Sid);
          }
          this.init();
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
  },
};
</script>