<template>
    <div class="part white">
        part
    </div>
</template>
<script>
export default {
}
</script>

