<template>
  <div v-loading="loading" style="width:100%;height:100%">
    <div style="padding-top:10px">
      <el-button type="primary" @click="open(true)">开灯</el-button>
      <el-button type="success" @click="open(false)">关灯</el-button>
    </div>
    <div class="block" style="font-size:12px;color:white;padding-top:10px">
      <span class="demonstration">亮度调节</span>
      <el-slider v-model="light" style="width:80%;padding-top:10px"  @change="lightChange"></el-slider>
    </div>
  </div>
</template>
<script>
export default {
  props: ["sid"],
  data() {
    return {
      loading: false,
      item: {},
      light: 0,
    };
  },
  watch:{
    sid(){
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    open(open) {
      var req = {
        Method: "send",
      };
      var data = {
        platform: 1,
        cmds: [],
      };
      data.cmds.push({
        code: 1,
        headSerial: 1,
        uid: this.item.SZ.lamp_ctrl_id,
        addr: this.item.SZ.addr,
        gatewayAddr: this.item.SZ.gateway_addr || this.item.SZ.addr,
        control: {
          devtype: this.item.SZ.lampControllerType,
          on: open ? 1 : 0,
        },
      });
      req.SZ = data;
      this.ctrLight(req);
    },
    ctrLight(req) {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/agent/RequestSzApi", req, true)
        .then(() => {
          this.loading = false;
          // res(success);
        })
        .catch(() => {
          this.loading = false;
          // rej(err);
        });
    },
    lightChange() {
      var req = {
        Method: "send",
      };
      var data = {
        platform: 1,
        cmds: [],
      };
      data.cmds.push({
        code: 1,
        headSerial: 1,
        uid: this.item.SZ.lamp_ctrl_id,
        addr: this.item.SZ.addr,
        gatewayAddr: this.item.SZ.gateway_addr || this.item.SZ.addr,
        control: {
          devtype: this.item.SZ.lampControllerType,
          bri: this.light,
        },
      });
      req.SZ = data;
      this.ctrLight(req);
    },
    init() {
      let obj = {
        Method: "read",
        SZ: {
          platform: 1,
          type: 4,
          skip: 0,
          limit: 1000000,
          name: "",
        },
        Custom: {
          Index: 0,
          Count: 10000,
        },
      };
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/agent/RequestSzApi", obj, true)
        .then((success) => {
          console.log(success,this.sid,"light");
          this.item = success.Items.find((x) => x.Custom.Sid == this.sid);
          if (!this.item) {
            throw "没有找到灯控设备";
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
  },
};
</script>