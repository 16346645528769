<template>
  <div style="width: 100%; height: 80%; position: relative; margin-top: 20px">
    <div
      style="position: absolute; text-align: center; width: 100%; height: 100%"
    >
      <img :src="borderImg" style="max-height: 90%; max-width: 100%" />
    </div>
    <div
      id="carAni"
      style="
        position: absolute;
        text-align: center;
        width: 100%;
        height: 100%;
        padding-top: 20px;
      "
      :class="carClass"
    >
      <img :src="coverImg" style="max-height: 90%; max-width: 100%" />
    </div>
  </div>
</template>
<style scoped>
.animateIn {
  animation: carin 3s;
  opacity: 1;
}
.animateOut {
  animation: carout 3s;
  opacity: 0;
}
@keyframes carin {
  from {
    top: 40%;
    opacity: 0;
  }
  to {
    top: 0%;
    opacity: 1;
  }
}
@keyframes carout {
  from {
    top: 0%;
    opacity: 1;
  }
  to {
    top: 40%;
    opacity: 0;
  }
}
</style>
<script>
export default {
  props: ["sid", "objvalue"],
  data() {
    return {
      loading: false,
      item: {},
      carClass: "animateIn",
      borderImg: require("@/assets/img/ic_ppppp.png"),
      coverImg: require("@/assets/img/ic_car.png"),
      startCoverValueThread: null,
      startCoverThread: null,
      alarm: false,
      leftBottomLoading: false,
    };
  },
  mounted: function () {
    this.$nextTick(() => {
      this.select();
    });
  },
  watch: {
    "$store.state.alarmHubChange": function () {
      try {
        var data = this.$store.state.alarmHub;
        if (data.DeviceSid == this.sid) {
        this.item.SpaceStatus = data.Data.Ss||0;
        this.alarm = this.item.SpaceStatus == 0 ? false : true;
        }
      } catch (err) {
        console.error(err);
      }
    },
    objvalue: function () {
      console.log(this.objvalue,"car object value");
      if (this.objvalue && this.objvalue.Data) {
        this.item.SpaceStatus = this.objvalue.Data.Ss||0;
        this.alarm = this.item.SpaceStatus == 0 ? false : true;
      if (this.alarm) {
        this.carClass = "animateIn";
      } else {
        this.carClass = "animateOut";
      }
        console.log("alarm",this.alarm);
      }
    },
    alarm: function () {
      if (this.alarm) {
        this.carClass = "animateIn";
      } else {
        this.carClass = "animateOut";
      }
    },
    sid: function () {
      this.select();
    },
  },
  methods: {
    select() {
      if(this.objvalue){
        return;
      }
      var sid = this.sid;
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/device/GetParkingSpaceInfo", sid, true)
        .then((data) => {
          this.loading = false;
          this.item = data;
          this.alarm = data.SpaceStatus == 0 ? false : true;
          this.carClass = data.SpaceStatus == 0 ? "animateOut" : "animateIn";
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    startCar() {
      this.startCarThread = window.setInterval(() => {
        this.alarm = !this.alarm;
      }, 1000 * 10);
    },
  },
  destroyed: function () {
    window.clearInterval(this.startCarThread);
  },
};
</script>