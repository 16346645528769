<template>
  <div class="netplay" ref="netplay" :style="isFullScreen?styleFull:styleNormal">
    <div  v-show="installed" class="ksflex" style="height:100%;width:100%">
     <div style="flex:1;height:100%">
       <div style="width:100%;height:100%;" class="ksflexcol">
         <div style="flex:1;width:100%;position:relative">
            <object
        class="netplay-obj"
        v-if="installed"
        ref="obj"
        classid="clsid:54FC7795-1014-4BF6-8BA3-500C61EC1A05"
        id="EZUIKit"
        width="100%"
        height="100%"
        name="EZUIKit"
      ></object>
       <!-- <iframe id="frame-title" allowtransparency="true" frameborder="0" scrolling="no"></iframe> -->
      <!-- <div  v-show="!unshowaction">
        <div id="div-title">{{data.device?data.device.text:''}}</div>
        <iframe id="frame-action" allowtransparency="true" frameborder="0" scrolling="no"></iframe>
      </div> -->
         </div>
          <div style="width:100%;height:50px;line-height:50px" v-show="!unshowaction">
              <div id="div-action-bar" style="height:100%;width:100%;color:grey">
           <i :class="['fa','fa-1x',!play ? 'fa-play':'fa-pause']" v-on:click="play?stop():start()"></i>
          <i
            :class="['fa', 'fa-1x',talk?'fa-microphone':'fa-microphone-slash']"
            v-on:click="talk?stopTalk():startTalk()"
          ></i>
          <i
            :class="['fa','fa-1x',sound?'fa-volume-up':'fa-volume-off']"
            v-on:click="sound?startMetu():stopMetu()"
          ></i>
           <i
            class="fa fa-arrow-left fa-1x"
            @mouseup="StopLeftPTZCtrl()"
            @mousedown="StartLeftPTZCtrl()"
          ></i>
          <i class="fa fa-arrow-up fa-1x" @mouseup="StopUpPTZCtrl()" @mousedown="StartUpPTZCtrl()"></i>
          <i
            class="fa fa-arrow-down fa-1x"
            @mouseup="StopDownPTZCtrl()"
            @mousedown="StartDownPTZCtrl()"
          ></i>
          <i
            class="fa fa-arrow-right fa-1x"
            @mouseup="StopRightPTZCtrl()"
            @mousedown="StartRightPTZCtrl()"
          ></i>
          <i
            class="fa fa-search-plus fa-1x"
            @mouseup="StopMaxPTZCtrl()"
            @mousedown="StartMaxPTZCtrl()"
          ></i>
          <i
            class="fa fa-search-minus fa-1x"
            @mouseup="StopMinPTZCtrl()"
            @mousedown="StartMinPTZCtrl()"
          ></i>
          <i
            :class="`fa ${!isFullScreen?'fa-window-maximize ':'fa-window-minimize'} fa-1x`"
            @click="Screen()"
          ></i>
       </div>
          </div>
       </div>
     </div>
     <div v-loading="loadingPoint" v-show="showRight" :style="!this.list?styleRight:styleRightList">
       <div style="width:100%;height:100%;color:grey;background-color:white">
         <div style="text-align:center" class="leftTitle">
           <span>{{$t('预置点')}}</span>
           <el-button type="primary" round size="small" @click="addPoint()">{{insert?$t("保存"):$t("新增预置点")}}</el-button>
           </div>
           <div v-show="insert">
             <b-form-input v-model="inputName" placeholder="请输入名称"></b-form-input>
           </div>
         <hr>
         <div v-for="item in listPoint" :key="item.Sid" class="pointi">
           <b-row>
             <b-col>
           {{item.Name}}</b-col>
             <b-col>
           <i class="fa fa-forward fa-fw" @click="toPoint(item.Index)"></i>
           <i class="fa fa-cog fa-fw" @click="setPoint(item)"></i>
           <i class="fa fa-trash-o fa-fw" @click="delPoint(item.Sid)"></i></b-col>
           </b-row>
           </div>
       </div>
     </div>
     <div v-show="showRight" :style="!this.list?styleTrig:styleTrigList">
       <i :class="!list?classToLeft:classToRight" style=" cursor: pointer" @click="listClick"></i>
     </div>
     </div>
     <div  v-show="!installed" style="color:red"> <a :href="BaseUrl+'/share/EZUIKit.zip'">{{$t("IE")}}</a></div>
  </div>
</template>
<style scoped>
.pointi i{
  cursor: pointer;
}
#div-action-bar > i,
#div-action-arrow > i {
  margin-left: 10px;
  cursor: pointer;
}
.netplay-obj {
  /* position: absolute; */
}
.netplay {
  width: 100%;
  height: 100%;
  background-color: black;
  position:relative;
  color: white;

}
.netplay-play {
  position: absolute;
}
#frame-title {
  position: absolute;
  z-index: 1;
  width: 120px;
  height: 25px;

  top: 10px;
  right: 10px;
  filter: "progid:DXImageTransform.Microsoft.Alpha(style=0,opacity=0)";
}
#frame-action {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
  bottom: 0px;
  filter: "progid:DXImageTransform.Microsoft.Alpha(style=0,opacity=0)";
}
#div-title {
  position: absolute;
  z-index: 2;
  height: 25px;
  top: 10px;
  right: 10px;
}
#div-action {
  position: absolute;
  z-index: 2;
  width: 95%;
  height: 50px;
  bottom: 0px;
}
</style>
<script>
import { BaseUrl } from '@/assets/utils/config'
export default {
  props: ["data","showRight","unshowaction"],
  data: function() {
    return {
      styleNormal:{},
      styleFull:{
        position:"fixed",
        width:"100%",
        height:"100%",
        top:0
      },
      baseUrl:BaseUrl,
      isFullScreen:false,
      installed: false,
      style: {
        height: "100%",
        width: "100%"
      },
      actionStyle: {
        left: "0px"
      },
      play: false,
      talk: false,
      sound: true,
      cmd_up: 0,
      cmd_down: 1,
      cmd_left: 2, 
      cmd_right: 3,
      cmd_max:8,
      cmd_min:9,
      action_start: 0,
      action_stop: 1,
      ptz_speed: 7,
      loading: {},
      styleTrig:{position:"absolute",bottom:0,right:"0",},
      styleRight:{width:"0",height:"100%"},
      styleTrigList:{position:"absolute",bottom:0,right:"250px",},
      styleRightList:{width:"250px",height:"100%"},
      list:true,
      listPoint:[],
      insert:false,
      inputName:"",
      loadingPoint:false,
      classToLeft:"fa fa-angle-double-left fa-1x",
      classToRight:"fa fa-angle-double-right fa-1x"
    };
  },
  mounted: function() {
     var pw=this.$refs.netplay.clientWidth;
     var ph=this.$refs.netplay.clientHeight;
     var ww=pw;
     var wh=ph;
    var h= (9*ww)/16*1.0;
    //var w=(16*wh)/9*1.0;
    if(h<=ph){
        var h1=h-100;
        var w1=(16*h1)/9*1.0;
        this.style.width=w1+'px';
        this.style.height=h1+'px';
        var rand=(ph-h1)/2;
        this.style.top=rand+'px';
        var rand1=(pw-w1)/2;
        this.style.left=rand1+"px";
    }
    else{
        var h2=wh-100;
        var w2=(16*h2)/9*1.0;
        this.style.width=w2+'px';
        this.style.height=h2+'px';
        var randx=(pw-w2)/2;
        this.style.left=randx+"px";
        var rand12=(ph-h2)/2;
        this.style.top=rand12+'px';
    }
    this.actionStyle.width=this.style.width;
    this.actionStyle.left=this.style.left;
    var result=this.$route.path.toLowerCase().indexOf("alarmaction")!=-1;
    if(result){
       window.stopYSPlay=()=>{
         if(this.play){
          this.stop();
         }
         var result=this.$route.path.toLowerCase().indexOf("alarmaction")!=-1;
         if(result){
           this.$router.push({path:"/admin/alarms/alarmAction"});
           window.location.reload();
          }
       };
       window.stopYSSound=()=>{
         if(this.talk){
          this.stopTalk();
         }
       };
    }
  },
  created: function() {
    try {
      new window.ActiveXObject("EZOPENUIACTIVEXK.EzOpenUIActiveXKCtrl.1");
      this.installed = true;
    } catch (e) {
      this.installed = false;
    }
    window.PluginEventHandler = this.callBack;
  },
  watch: {
    data: function() {
      if (this.data.isPlay) {
        this.start();
      } else {
        this.stop();
      }
    }
  },
  methods: {
    Screen(){
      this.isFullScreen=!this.isFullScreen
    },
    delPoint(sid){
      if(!this.data||!this.data.device){
        this.$throw("请先播放视频");
      }else{
        this.loadingPoint=true;
         this.$ksajax.ksPostPromise("/api/device/DelVideoPreset",sid,true).then(()=>{
           this.loadingPoint=false;
           this.selectPoint();
         }).catch(err=>{
           this.loadingPoint=false;
           this.$throw(err);
         })
      }
    },
    toPoint(index){
      if(!this.data||!this.data.device||!this.data.isPlay){
        this.$throw("请先播放视频");
      }else{
         this.loadingPoint=true;
         var obj={
           Index:index,
           VideoSid:this.data.device.sid
         };
         this.$ksajax.ksPostPromise("/api/device/CallVideoPreset",obj,true).then(()=>{
           this.loadingPoint=false;
         }).catch(err=>{
           this.loadingPoint=false;
           this.$throw(err);
         })
      }
    },
    setPoint(item){
      if(!this.data||!this.data.device||!this.data.isPlay){
        this.$throw("请先播放视频");
      }else{
         this.loadingPoint=true;
       var obj={
            "Sid":item.Sid,
            "VideoSid": item.VideoSid,
            "Name": item.Name,
            "Describe": item.Describe
          };
          this.$ksajax.ksPostPromise("/api/device/AddVideoPreset",obj,true).then(()=>{
            this.loadingPoint=false;
          }).catch(err=>{
            this.loadingPoint=false;
            this.$throw(err);
          })
      }
     
    },
    addPoint(sid){
      if(!this.data||!this.data.device){
        this.$throw("请先播放视频");
      }else{
        if(this.insert){
          var obj={
            "Sid":sid,
            "VideoSid": this.data.device.sid,
            "Name": this.inputName,
            "Describe": this.inputName
          };
           this.$ksajax.ksPostPromise("/api/device/AddVideoPreset",obj,true).then(()=>{
             this.selectPoint();
           }).catch(err=>{
             this.$throw(err);
           })
        }
        this.insert=!this.insert;
      }
    },
    selectPoint(){
      if(this.data.device.sid){
          this.loadingPoint=true;
       this.$ksajax.ksPostPromise("/api/device/GetVideoPresets",this.data.device.sid,true).then((d)=>{
         this.listPoint=d;
          this.loadingPoint=false;
       }).catch(err=>{
         this.$throw(err);
          this.loadingPoint=false;
       })
      }
    },
    listClick(){
      this.list=!this.list;
    },
    stop() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.StopPlay();
      if (res !== 0) {
        this.$throw("停止播放失败", this);
      } else {
        this.play = false;
      }
    },
    start(metu) {
      if(!this.data||!this.data.data||!this.data.data.AccessToken){
        this.$throw("请选择设备");
      }else{
        this.loading = this.$loading();
        var playOcx = document.getElementById("EZUIKit");
        playOcx.InitWithAppKey(this.data.data.AppKey);
        var res = playOcx.SetAccessToken(this.data.data.AccessToken);
        res = playOcx.StartPlay(this.data.data.Url);
        console.log(playOcx,"playOcx");
        if (res !== 0) {
          this.$throw("播放视频失败", this);
        } else {
          this.play = true;
          if (metu) {
            this.sound = false;
          } else {
            this.sound = true;
          }
        }
        if(this.showRight){
          this.selectPoint();
        }
      }
    },
    startTalk() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.StartTalk();
      if (res !== 0) {
        this.$throw("开启对讲失败", this);
      } else {
        this.talk = true;
      }
    },
    stopTalk() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.StopTalk();
      if (res !== 0) {
        this.$throw("关闭对讲失败", this);
      } else {
        this.talk = false;
      }
    },
    startMetu() {
      this.data.data.Url = this.data.data.Url.replace(
        "mute=false",
        "mute=true"
      );
      this.start(true);
    },
    stopMetu() {
      this.data.data.Url = this.data.data.Url.replace(
        "mute=true",
        "mute=false"
      );
      this.start(false);
    },
    StartUpPTZCtrl() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.PTZCtrl(this.cmd_up, this.action_start, this.ptz_speed);
      if (res !== 0) {
        this.$throw("球机控制失败", this);
      } else {
        this.play = false;
      }
    },
    StopUpPTZCtrl() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.PTZCtrl(this.cmd_up, this.action_stop, this.ptz_speed);
      if (res !== 0) {
        this.$throw("球机控制失败", this);
      } else {
        this.play = false;
      }
    },
    StartMaxPTZCtrl() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.PTZCtrl(this.cmd_max, this.action_start, this.ptz_speed);
      if (res !== 0) {
        this.$throw("球机控制失败", this);
      } else {
        this.play = false;
      }
    },
    StopMaxPTZCtrl() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.PTZCtrl(this.cmd_max, this.action_stop, this.ptz_speed);
      if (res !== 0) {
        this.$throw("球机控制失败", this);
      } else {
        this.play = false;
      }
    },
    StartMinPTZCtrl() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.PTZCtrl(this.cmd_min, this.action_start, this.ptz_speed);
      if (res !== 0) {
        this.$throw("球机控制失败", this);
      } else {
        this.play = false;
      }
    },
    StopMinPTZCtrl() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.PTZCtrl(this.cmd_min, this.action_stop, this.ptz_speed);
      if (res !== 0) {
        this.$throw("球机控制失败", this);
      } else {
        this.play = false;
      }
    },
    StartDownPTZCtrl() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.PTZCtrl(
        this.cmd_down,
        this.action_start,
        this.ptz_speed
      );
      if (res !== 0) {
        this.$throw("球机控制失败", this);
      } else {
        this.play = false;
      }
    },
    StopDownPTZCtrl() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.PTZCtrl(
        this.cmd_down,
        this.action_stop,
        this.ptz_speed
      );
      if (res !== 0) {
        this.$throw("球机控制失败", this);
      } else {
        this.play = false;
      }
    },
    StartLeftPTZCtrl() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.PTZCtrl(
        this.cmd_left,
        this.action_start,
        this.ptz_speed
      );
      if (res !== 0) {
        this.$throw("球机控制失败", this);
      } else {
        this.play = false;
      }
    },
    StopLeftPTZCtrl() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.PTZCtrl(
        this.cmd_left,
        this.action_stop,
        this.ptz_speed
      );
      if (res !== 0) {
        this.$throw("球机控制失败", this);
      } else {
        this.play = false;
      }
    },
    StartRightPTZCtrl() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.PTZCtrl(
        this.cmd_right,
        this.action_start,
        this.ptz_speed
      );
      if (res !== 0) {
        this.$throw("球机控制失败", this);
      } else {
        this.play = false;
      }
    },
    StopRightPTZCtrl() {
      var playOcx = document.getElementById("EZUIKit"); //得到控件引用
      var res = playOcx.PTZCtrl(
        this.cmd_right,
        this.action_stop,
        this.ptz_speed
      );
      if (res !== 0) {
        this.$throw("球机控制失败", this);
      } else {
        this.play = false;
      }
    },
    callBack(lEventType, strErrorCode, lInterErrorCode) {
      //handle message msgtype
      var EZUI_MSGID_PLAY_EXCEPTION = 0; //播放异常
      var EZUI_MSGID_PLAY_RECONNECT = 1; //播放重连
      var EZUI_MSGID_PLAY_RECONNECT_EXCEPTION = 2; //播放重连异常
      var EZUI_MSGID_PLAY_START = 3; //播放开始
      var EZUI_MSGID_PLAY_STOP = 4; //播放停止
      var EZUI_MSGID_PLAY_ARCHIVE_END = 5; //回放结束
      var EZUI_MSGID_VOICETALK_START = 16; //语音对讲开始
      var EZUI_MSGID_VOICETALK_STOP = 17; //语音对讲停止
      var EZUI_MSGID_VOICETALK_EXCEPTION = 18; //语音对讲异常
      var EZUI_MSGID_RECORD_FILE = 20; //查询的录像文件
      var EZUI_MSGID_PTZCTRL_SUCCESS = 46; //云台控制命令发送成功
      var EZUI_MSGID_PTZCTRL_FAILED = 47; //云台控制失败

      var EZUI_ERROR_ACCESSTOKEN_EXPIRE = "UE001"; ///< accesstoken异常或失效，需要重新获取accesstoken，并传入到sdk
      var EZUI_ERROR_APPKEY_TOKEN_NOT_MATCH = "UE002"; ///< appkey和AccessToken不匹配,建议更换appkey或者AccessToken
      var EZUI_ERROR_CHANNEL_NOT_EXIST = "UE004"; ///< 通道不存在，设备参数错误，建议重新获取播放地址
      var EZUI_ERROR_DEVICE_NOT_EXIST = "UE005"; ///< 设备不存在，设备参数错误，建议重新获取播放地址
      var EZUI_ERROR_PARAM_INVALID = "UE006"; ///< 参数错误，建议重新获取播放地址
      var EZUI_ERROR_EZOPEN_URL_INVALID = "UE007"; ///< 播放地址错误,建议重新获取播放地址
      var EZUI_ERROR_NO_RESOURCE = "UE101"; ///< 设备连接数过大，停止其他连接后再试试
      var EZUI_ERROR_DEVICE_OFFLINE = "UE102"; ///< 设备不在线，确认设备上线之后重试
      var EZUI_ERROR_CONNECT_DEVICE_TIMEOUT = "UE103"; ///< 播放失败，请求连接设备超时，检测设备网路连接是否正常.
      var EZUI_ERROR_INNER_VERIFYCODE = "UE104"; ///< 视频验证码错误，建议查看设备上标记的验证码
      var EZUI_ERROR_PLAY_FAIL = "UE105"; ///< 视频播放失败
      var EZUI_ERROR_TERMINAL_BINDING = "UE106"; ///< 当前账号开启了终端绑定，只允许指定设备登录操作
      var EZUI_ERROR_DEVICE_INFO_INVALID = "UE107"; ///< 设备信息异常为空，建议重新获取播放地址
      var EZUI_ERROR_VIDEO_RECORD_NOTEXIST = "UE108"; ///< 未查找到录像文件
      var EZUI_ERROR_VTDU_NO_RESOURCE = "UE109"; ///< 取流并发路数限制,请升级为企业版.
      var EZUI_ERROR_UNSUPPORTED = "UE110"; ///< 设备不支持的清晰度类型, 请根据设备预览能力级选择.
      var info = null;
      switch (lEventType) {
        case EZUI_MSGID_VOICETALK_EXCEPTION:
          {
            info = "语音对讲异常!";
          }
          break;
        case EZUI_MSGID_PLAY_ARCHIVE_END:
          {
            info = "回放结束!";
          }
          break;
        case EZUI_MSGID_PLAY_RECONNECT_EXCEPTION:
          {
            info = "播放重连失败!";
          }
          break;
        case EZUI_MSGID_PLAY_RECONNECT:
          {
            info = "播放重连!";
          }
          break;
        case EZUI_MSGID_PLAY_START: //播放开始
          {
            info = "播放成功!";
            this.$emit("stateChanged",true);
            var result=this.$route.path.toLowerCase().indexOf("alarmaction")!=-1;
            if(result){
              this.startTalk();
            }
          }
          break;
        case EZUI_MSGID_PLAY_EXCEPTION: //播放异常
          {
            var errinfo = "";
            if (strErrorCode == EZUI_ERROR_ACCESSTOKEN_EXPIRE) {
              errinfo =
                "accesstoken异常或失效，需要重新获取accesstoken，并传入到sdk";
            } else if (strErrorCode == EZUI_ERROR_APPKEY_TOKEN_NOT_MATCH) {
              errinfo =
                "ppkey和AccessToken不匹配,建议更换appkey或者AccessToken";
            } else if (strErrorCode == EZUI_ERROR_CHANNEL_NOT_EXIST) {
              errinfo = "通道不存在，设备参数错误，建议重新获取播放地址";
            } else if (strErrorCode == EZUI_ERROR_DEVICE_NOT_EXIST) {
              errinfo = "设备不存在，设备参数错误，建议重新获取播放地址";
            } else if (strErrorCode == EZUI_ERROR_PARAM_INVALID) {
              errinfo = "参数错误，建议重新获取播放地址";
            } else if (strErrorCode == EZUI_ERROR_EZOPEN_URL_INVALID) {
              errinfo = "播放地址错误,建议重新获取播放地址";
            } else if (strErrorCode == EZUI_ERROR_NO_RESOURCE) {
              errinfo = "设备连接数过大，停止其他连接后再试试";
            } else if (strErrorCode == EZUI_ERROR_DEVICE_OFFLINE) {
              errinfo = "设备不在线，确认设备上线之后重试";
            } else if (strErrorCode == EZUI_ERROR_CONNECT_DEVICE_TIMEOUT) {
              errinfo = "播放失败，请求连接设备超时，检测设备网路连接是否正常.";
            } else if (strErrorCode == EZUI_ERROR_INNER_VERIFYCODE) {
              errinfo = "视频验证码错误，建议查看设备上标记的验证码";
            } else if (strErrorCode == EZUI_ERROR_PLAY_FAIL) {
              errinfo = "视频播放失败";
            } else if (strErrorCode == EZUI_ERROR_TERMINAL_BINDING) {
              errinfo = "当前账号开启了终端绑定，只允许指定设备登录操作";
            } else if (strErrorCode == EZUI_ERROR_DEVICE_INFO_INVALID) {
              errinfo = "设备信息异常为空，建议重新获取播放地址";
            } else if (strErrorCode == EZUI_ERROR_VIDEO_RECORD_NOTEXIST) {
              errinfo = "未查找到录像文件";
            } else if (strErrorCode == EZUI_ERROR_VTDU_NO_RESOURCE) {
              errinfo = "取流并发路数限制,请升级为企业版.";
            } else if (strErrorCode == EZUI_ERROR_UNSUPPORTED) {
              errinfo = "设备不支持的清晰度类型, 请根据设备预览能力级选择";
            }

            info =
              "播放失败," +
              errinfo +
              ".错误码:" +
              strErrorCode +
              ", 内部错误码:" +
              lInterErrorCode;
          }
          break;
        case EZUI_MSGID_PLAY_STOP: //播放停止
          {
            info = "播放停止";
            this.$emit("stateChanged",false);
            if(window.stopYSSound){
              window.stopYSSound();
            }
          }
          break;
        case EZUI_MSGID_RECORD_FILE: //录像搜索成功
          {
            info = "录像搜索成功";
          }
          break;
        case EZUI_MSGID_VOICETALK_START: //对讲开启
          {
            info = "对讲开启成功";
          }
          break;
        case EZUI_MSGID_VOICETALK_STOP: //对讲开启
          {
            info = "对讲停止成功";
            this.stopTalk();
            if(window.stopYSPlay){
            window.stopYSPlay();
            }
          }
          break;
        case EZUI_MSGID_PTZCTRL_SUCCESS: //云台控制成功
          {
            info = "云台控制信令发送成功";
          }
          break;
        case EZUI_MSGID_PTZCTRL_FAILED: //云台控制失败
          {
            info = "云台控制失败";
          }
          break;
        default:
      }
      if (info) {
        // this.$throw(info);
      }
      if (this.loading.close) {
        this.loading.close();
      }
    }
  }
};
</script>
