<template>
  <div id="sensordetail" v-loading="loading">
     <div id="todetail">
           <router-link :to="`/admin/stand/commondevice?sid=${$route.query.sid}`">{{$t("详情")}}</router-link>
    </div>
    <div style="text-align:center">
      <span class="content_text" style="font-size:20px;color:white;margin-right:30px">
        {{$t("数据刷新时间")}}
        <span class="number">{{updateTime}}</span>
      </span>
    </div>
    <div style="height:80%">
      <div  class="ksflex sensor-data">
        <div v-if="current.Dm||current.Dm==0" id="sensor-dm">
          <div class="sensor-center ksflex text-align:center">
            <div style="text-align:center;flex:1">
              <img src="../../../assets/img/windDirection.png">
              <div id="Dm" class="animated">
                <i style="font-size:150%">{{getDm(current.Dm)}}deg</i>
              </div>
              <div class="sensor-desc">
                <span> {{$t("风向")}}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="sensor-sm" v-if="current.Sm||current.Sm==0">
          <div class="sensor-center ksflex text-align:center">
            <div style="text-align:center;flex:1">
              <img src="../../../assets/img/wind.png">
              <div id="Sm" class="animated">
                <i style="font-size:150%">{{current.Sm}}m/s</i>
              </div>
              <div class="sensor-desc">
                <span>{{$t("风速")}}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="sensor-ta" v-if="current.Ta||current.Ta==0">
          <div class="sensor-center ksflex text-align:center">
            <div style="text-align:center;flex:1">
              <img src="../../../assets/img/temperature.png">
              <div id="Ta" class="animated">
                <i style="font-size:150%">{{current.Ta}}℃</i>
              </div>
              <div class="sensor-desc">
                <span>{{$t("温度")}}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="sensor-ua" v-if="current.Ua||current.Ua==0">
          <div class="sensor-center ksflex text-align:center">
            <div style="text-align:center;flex:1">
              <img src="../../../assets/img/humidity.png">
              <div id="Ua" class="animated">
                <i style="font-size:150%">{{current.Ua}}%</i>
              </div>
              <div class="sensor-desc">
                <span>{{$t("湿度")}}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="sensor-pa" v-if="current.Pa||current.Pa==0">
          <div class="sensor-center ksflex text-align:center">
            <div style="text-align:center;flex:1">
              <img src="../../../assets/img/pressure.png">
              <div id="Pa" class="animated">
                <i style="font-size:150%">{{current.Pa}}hPa</i>
              </div>
              <div class="sensor-desc">
                <span>{{$t("气压")}}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="sensor-rc" v-if="current.Rc||current.Rc==0">
          <div class="sensor-center ksflex text-align:center">
            <div style="text-align:center;flex:1">
              <img src="../../../assets/img/rainfall.png">
              <div id="Rc" class="animated">
                <i style="font-size:150%">{{current.Rc}}mm</i>
              </div>
              <div class="sensor-desc">
                <span>{{$t("降雨量")}}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="sensor-sr" v-if="current.Sr||current.Sr==0">
          <div class="sensor-center ksflex text-align:center">
            <div style="text-align:center;flex:1">
              <img src="../../../assets/img/radiation.png">
              <div id="Sr" class="animated">
                <i style="font-size:150%">{{current.Sr}}W/m²</i>
              </div>
              <div class="sensor-desc">
                <span>{{$t("太阳辐射")}}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="sensor-uv" v-if="current.Uv||current.Uv==0">
          <div class="sensor-center ksflex text-align:center">
            <div style="text-align:center;flex:1">
              <img src="../../../assets/img/ultraviolet.png">
              <div id="Uv" class="animated">
                <i style="font-size:150%">{{current.Uv}}W/m²</i>
              </div>
              <div class="sensor-desc">
                <span>{{$t("紫外线指数等级")}}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="sensor-nx" v-if="current.Ns||current.Ns==0">
          <div class="sensor-center ksflex text-align:center">
            <div style="text-align:center;flex:1">
              <img src="../../../assets/img/noise.png">
              <div id="Ns" class="animated">
                <i style="font-size:150%">{{current.Ns}}db</i>
              </div>
              <div class="sensor-desc">
                <span>{{$t("噪声")}}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="sensor-pm" v-if="current.PM2_5||current.PM2_5==0">
          <div class="sensor-center ksflex text-align:center">
            <div style="text-align:center;flex:1">
              <img src="../../../assets/img/PM25.png">
              <div id="PM2_5" class="animated">
                <i style="font-size:150%">{{current.PM2_5}}μg/m³</i>
              </div>
              <div class="sensor-desc">
                <span>PM2.5</span>
              </div>
            </div>
          </div>
        </div>
        <div id="sensor-pm10" v-if="current.PM10||current.PM10==0">
          <div class="sensor-center ksflex text-align:center">
            <div style="text-align:center;flex:1">
              <img src="../../../assets/img/PM10.png">
              <div id="PM10" class="animated">
                <i style="font-size:150%">{{current.PM10}}μg/m³</i>
              </div>
              <div class="sensor-desc">
                <span>PM10</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
#sensordetail {
  height: 100%;
  color: white;
}
</style>

<script>
import {formatDate} from "@/assets/utils/datef";
export default {
  data: function() {
    return {
      current: {},
      loading: false,
      updateTime: "",
      sensor:{}
    };
  },
  mounted: function() {
    this.select();
  },
  methods: {
    getDm(dm){
      var array=dm.split(" ");
      return this.$t(array[0])+" "+array[1];
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/device/GetSensorData", this.$route.query.sid, true)
        .then(success => {
          this.current = success.SensorData;
          this.sensor=success;
          this.loading = false;
          this.updateTime=success.CollectTime;
        })
        .catch(err => {
          this.$throw(err);
          this.loading = false;
        });
    }
  },
  watch:{
        '$store.state.dataHubChange':function(){
          try{
            var data=this.$store.state.dataHub;
            if(data.CtgSid=="005"){
                if(this.sensor.DeviceSid==data.DeviceSid){
                    var keys=Object.keys(this.current);
                    keys.forEach(x=>{
                        var obj=data.Data[x];
                        if(obj!==this.current[x]){
                            document.getElementById(x).classList.remove("flip");
                            window.setTimeout((ele)=>{
                                document.getElementById(ele).classList.add("flip");
                            },500,x)
                        }
                    })
                    this.current=data.Data;
                    var da=formatDate(new Date(),"yyyy/MM/dd hh:mm:ss");
                    this.updateTime=da;
                }
            }
          }catch(err){console.error(err)}
        }
    }

};
</script>
