import { render, staticRenderFns } from "./timeaxis.vue?vue&type=template&id=613d96a9&scoped=true"
import script from "./timeaxis.vue?vue&type=script&lang=js"
export * from "./timeaxis.vue?vue&type=script&lang=js"
import style0 from "./timeaxis.vue?vue&type=style&index=0&id=613d96a9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "613d96a9",
  null
  
)

export default component.exports