var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white"},[_c('actionbar',{attrs:{"select":true,"modify":true,"del":true,"insert":true,"reboot":true,"ntp":true,"light":true,"screen":true,"volume":true,"init":true,"refresh":true},on:{"select":_vm.select,"insert":_vm.insert,"modify":_vm.modify,"delete":_vm.del,"volume":_vm.vol,"reboot":_vm.reboot,"ntp":_vm.ntp,"light":_vm.light,"screen":_vm.screen,"init":_vm.init,"refresh":_vm.refresh}}),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"stripe":"","data":_vm.items,"highlight-current-row":"","empty-text":_vm.$t('暂无数据')},on:{"current-change":_vm.currentRow}},[_c('el-table-column',{attrs:{"label":_vm.$t('序号'),"prop":"Serial"}}),_c('el-table-column',{attrs:{"label":_vm.$t('设备名'),"prop":"DeviceName"}}),_c('el-table-column',{attrs:{"label":_vm.$t('设备类型')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.DeviceType.TypeName))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('高度')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.DeviceNovaLed.Height))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('宽度')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.DeviceNovaLed.Width))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('版本')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.DeviceNovaLed.Version))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('地址'),"prop":"Address"}}),_c('el-table-column',{attrs:{"label":_vm.$t('在线状态')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('i',{class:[
            'fa',
            scope.row.Status == 1 ? 'stateOn' : 'stateOff',
            'fa-2x',
            'fa-signal',
          ]})]}}])})],1),_c('modify',{ref:"modify",attrs:{"mapitem":_vm.mapitem,"modaldata":_vm.modalData,"title":_vm.title,"labels":_vm.labels,"url":"/api/led/AddNovaLed"},on:{"submited":_vm.submited}}),_c('deletedata',{ref:"del",attrs:{"sid":_vm.sid,"url":"/api/led/DeleteNovaLed","title":_vm.$t('删除')},on:{"deleted":_vm.deleted}}),_c('b-modal',{ref:"novalControlModal",attrs:{"id":"novalControlModal","size":"ms","title":_vm.$t('控制'),"body-text-variant":"dark"},on:{"ok":_vm.novaSubmit}},[_c('div',[_vm._v(_vm._s(_vm.controlTitle)+_vm._s(_vm.controlDesc))]),(_vm.controlData.option == 2 || _vm.controlData.option == 3)?_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.controlData.value),callback:function ($$v) {_vm.$set(_vm.controlData, "value", $$v)},expression:"controlData.value"}}):_vm._e(),(
        _vm.controlData.option == 1 ||
        _vm.controlData.option == 4 ||
        _vm.controlData.option == 5
      )?_c('b-form-select',{model:{value:(_vm.controlData.value),callback:function ($$v) {_vm.$set(_vm.controlData, "value", $$v)},expression:"controlData.value"}},[_c('b-form-select-option',{attrs:{"value":"1"}},[_vm._v("开")]),_c('b-form-select-option',{attrs:{"value":"0"}},[_vm._v("关")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }