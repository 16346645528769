<template>
  <div class="domain">
    <alerter ref="alert"></alerter>
    <router-view></router-view>
  </div>
</template>
<style>
html,
body {
  width: 100%;
  height: 100%;
  /* font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif !important; */
}
.domain {
  width: 100%;
  height: 100%;
}
.hiden {
  display: none;
}
.el-table__row.current-row > td {
  background-color: khaki !important;
}
</style>
<script>
import alerter from "./components/alerter";
export default {
  mounted: function() {
    //获取语言
    var lan = this.$cookies.get("lan");
    this.$store.commit("setLan", lan);
  },
  data: function() {
    return {
      loading: {}
    };
  },
  components: {
    alerter
  },
  watch: {
    "$store.state.isError": function() {
      this.$message(this.$store.state.errorMsg);
    }
  },
  methods: {
    showAlert(msg) {
      this.$refs.alert.showAlert(msg);
    },

    titleCase5(str) {
      return str.toLowerCase().replace(/( |^)[A-Z]/g, L => L.toUpperCase());
    },
    toUpperCase(jsonObj) {
      if (typeof jsonObj == "object") {
        for (var key in jsonObj) {
          jsonObj[key.substring(0, 1).toUpperCase() + key.substring(1)] =
            jsonObj[key];
          delete jsonObj[key];
        }
        return jsonObj;
      }
      return jsonObj;
    }
  },
  created: function() {
    this.$ksajax.initCall(
      success => {
        if (process.env.NODE_ENV == "development") {success;
        }
        this.loading.close();
      },
      error => {
        //this.isloading=false;
        this.loading.close();
        let msg = error.RetMsg || error;
        msg = msg.message || msg;
        this.$refs.alert.showAlert(msg);
      },
      () => {
        //this.isloading=true;
        this.loading = this.$loading({
          fullscreen: true
        });
      }
    );
  }
};
</script>

