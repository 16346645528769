<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      :play="true"
      :stop="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
      v-on:play="play"
      v-on:stop="stop"
    ></actionbar>
    <el-table stripe=""
      :data="items"
      style="width: 100%"
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
      <el-table-column :label="$t('播放音量')" prop="Volume"></el-table-column>
      <el-table-column :label="$t('播放方式')" prop="type">
        <template slot-scope="row">{{row.Control==0?'顺序播放':'随机播放'}}</template>
      </el-table-column>
      <el-table-column :label="$t('结束控制')">
        <template slot-scope="row">{{row.EndType==0?'次数控制':'时长控制'}}</template>
      </el-table-column>
    </el-table>
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('名称')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Name" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('播放音量')" :label-cols="2">
          <b-form-input type="number" max="100" v-model="modalData.Volume" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('播放方式')" :label-cols="2">
          <b-select v-model="modalData.Control">
            <option value="0">顺序播放</option>
            <option value="1">随机播放</option>
          </b-select>
        </b-form-group>
        <b-form-group v-bind:label="$t('结束控制')" :label-cols="2">
          <b-select v-model="modalData.EndType">
            <option value="0">播放次数</option>
            <option value="1">播放时长</option>
          </b-select>
        </b-form-group>

        <b-form-group
          v-bind:label="modalData.EndType==0?$t('播放次数'):$t('播放时长')+'(秒)'"
          :label-cols="2"
        >
          <b-form-input type="number" v-model="modalData.EndValue" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('音频资源')" :label-cols="2">
          <b-form-select
            id="form-Tids"
            required
            multiple
            v-model="modalData.Resources"
            :options="res"
          ></b-form-select>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width:100px" v-on:click="hiden">Cancel</b-button>
            <b-button type="submit" style="width:100px" variant="primary">OK</b-button>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/nova/DeleteBroadRes"
      :title="$t('删除')"
    ></deletedata>
    <b-modal
      id="publishmodal"
      class="ksmodal"
      ref="publishmodal"
      size="lg"
      :title="title"
      body-text-variant="dark"
      @ok="publishStage"
      @shown="publishShown"
    >
      <el-table
        ref="multipleTable"
        :data="devices"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :empty-text="$t('暂无数据')"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column :label="$t('名称')" prop="DeviceName"></el-table-column>
        <el-table-column :label="$t('宽度')" prop="Width"></el-table-column>
        <el-table-column :label="$t('高度')" prop="Height"></el-table-column>
        <el-table-column :label="$t('位置')" prop="Address"></el-table-column>
      </el-table>
    </b-modal>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import deletedata from "@/components/deletedata";
export default {
  components: { actionbar, tablepage, deletedata },
  data: function() {
    return {
      types: [
        { text: "文本", value: "0" },
        { text: "MP3", value: "1" }
      ],
      modalData: this.getResetData(),
      fileList: [],
      fileData: {},
      title: "",
      items: [],
      current: null,
      sid: "",
      res: [],
      devices: [],
      deviceSlected: [],
      ctype: 0
    };
  },
  mounted: function() {
    this.select();
  },
  methods: {
    publishShown() {
      this.$refs.multipleTable.clearSelection();
    },
    handleSelectionChange(val) {
      this.deviceSlected = val;
    },
    publishStage() {
      var url = "/api/nova/RealTimePlay";
      if (this.ctype == 1) {
        url = "/api/nova/RealTimeStop";
      }
      var data = {
        Sid: this.current.Sid,
        Devices: []
      };
      if (this.deviceSlected && this.deviceSlected.length > 0) {
        this.deviceSlected.forEach(x => {
          data.Devices.push(x.Serial);
        });
      } else {
        throw this.$t("请选择数据");
      }
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise(url, data, true)
        .then(() => {
          this.loading.close();
        })
        .catch(err => {
          this.loading.close();
          this.$throw(err);
        });
    },
    play() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.getDevice(() => {
        this.title = "播放";
        this.ctype = 0;
      });
    },
    stop() {
      this.getDevice(() => {
        this.title = "播放";
        this.ctype = 1;
      });
    },
    getDevice(call) {
      new Promise((res, rej) => {
        if (this.devices.length != 0) {
          res();
        } else {
          this.loading = this.$loading();
          let obj = {
            Index: 0,
            Count: 10000
          };
          this.$ksajax
            .ksPostPromise("/api/led/GetNovaLeds", obj, true)
            .then(data => {
              this.devices = data.Items;
              res();
              this.loading.close();
            })
            .catch(err => {
              rej(err);
              this.loading.close();
            });
        }
      })
        .then(() => {
          if (call) {
            call();
          }
          this.$refs.publishmodal.show();
        })
        .catch(err => {
          this.$throw(err);
        });
    },
    currentRow(val) {
      this.current = val;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePreview() {},
    Uploaded(val, file) {
      this.modalData.Content = val.Data.Url;
      this.modalData.Size = val.Data.Length;
      this.modalData.Md5 = val.Data.Md5;
      if (val.RetCode == 0) {
        file.url = val.Data;
        this.fileList.push(file);
      } else {
        throw val.RetMsg;
      }
    },
    getResetData() {
      return {
        Sid: "",
        Name: "播放列表名称",
        Control: 0,
        EndType: 0,
        EndValue: 1,
        Volume: 50,
        Resources: []
      };
    },
    select() {
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/nova/GetPlayList", "", true)
        .then(data => {
          this.$loading().close();
          this.items = data;
        })
        .catch(err => {
          this.$loading().close();
          this.$throw(err);
        });
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    insert() {
      this.getRes(() => {
        this.modalData = this.getResetData();
        this.$refs.modallg.show();
      });
    },
    getRes(call) {
      this.$loading();
      new Promise((res, rej) => {
        if (this.res.length != 0) {
          res();
        } else {
          this.$ksajax
            .ksPostPromise("/api/nova/GetBroadRes", "", true)
            .then(data => {
              data.forEach(x => {
                (x.text = x.Name), (x.value = x.Sid);
              });
              this.res = data;
              res();
            })
            .catch(err => {
              rej(err);
            });
        }
      })
        .then(() => {
          this.$loading().close();
          if (this.res.length == 0) {
            throw "请先上传音频资源";
          }
          if (call) {
            call();
          }
        })
        .catch(err => {
          this.$loading().close();
          this.$throw(err);
        });
    },
    hiden() {
      this.$refs.modallg.hide();
    },
    modify() {
      this.getRes(() => {
        if (this.current == null) {
          throw this.$t("选择一条数据");
        }
        this.modalData = this.getResetData();
        this.modalData.Sid = this.current.Sid;
        this.modalData.Name = this.current.Name;
        this.modalData.Control = this.current.Control;
        this.modalData.EndType = this.current.EndType;
        this.modalData.EndValue = this.current.EndValue;
        this.modalData.Volume = this.current.Volume;
        this.modalData.Resources = [];
        this.current.Resources.forEach(x => {
          this.modalData.Resources.push(x.Sid);
        });
        this.$refs.modallg.show();
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.modalData.Resources.length == 0) {
        throw this.$t("请至少选择一个音频资源");
      }
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/nova/AddPlayList", this.modalData, true)
        .then(() => {
          this.$loading().close();
          this.hiden();
          this.select();
        })
        .catch(err => {
          this.$loading().close();
          this.$throw(err);
        });
    },
    deleted() {
      this.select();
    }
  }
};
</script>