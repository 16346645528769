<template>
  <div>
    <el-table :data="lights" tooltip-effect="dark" style="width: 100%">
      <el-table-column prop="name" label="灯具名称"></el-table-column>
      <el-table-column prop="addr" label="灯控器地址"></el-table-column>
      <el-table-column prop="type" label="设备类型"></el-table-column>
      <el-table-column prop="online" label="在线状态">
        <template slot-scope="scope">
          <i :class="scope.row.online==0?'fa fa-signal stateOn':'fa fa-signal stateOff'"></i>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="设备状态">
        <template slot-scope="scope">
          <i :class="scope.row.state==1?'fa fa-lightbulb-o stateOn':'fa fa-lightbulb-o stateOff'"></i>
        </template>
      </el-table-column>

      <el-table-column prop="online" label="灯具数据">
        <template>
          <a href="#" @click="$refs.lightmodel.show()">灯具数据</a>
        </template>
      </el-table-column>
      <el-table-column prop="online" label="电能数据">
        <template>
          <a href="#"  @click="$refs.dmodel.show()">电能数据</a>
        </template>
      </el-table-column>
    </el-table>
    <pageable v-on:currentChange="currentChange" :perPage="count" :totalPage="totalPage"></pageable>
    <b-modal
      id="modallg"
      ref="lightmodel"
      size="lg"
      title="灯控历史数据"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
    <el-table :data="hdata" tooltip-effect="dark" style="width: 100%">
      <el-table-column prop="v" label="电压(V)"></el-table-column>
      <el-table-column prop="a" label="电流(V)"></el-table-column>
      <el-table-column prop="g" label="有功功率(kw/h)"></el-table-column>
      <el-table-column prop="w" label="无功功率(kw/h)"></el-table-column>
      <el-table-column prop="hz" label="频率(hz)"></el-table-column>
      <el-table-column prop="l" label="亮度(%)"></el-table-column>
      <el-table-column prop="s" label="状态"></el-table-column>
      <el-table-column prop="t" label="上报时间"></el-table-column>
      </el-table>
    </b-modal>
     <b-modal
      id="modallg"
      ref="dmodel"
      size="lg"
      title="电能历史数据"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
    <el-table :data="ddata" tooltip-effect="dark" style="width: 100%">
      <el-table-column prop="y" label="有功电能(kw/h)"></el-table-column>
      <el-table-column prop="w" label="无功电能(V)"></el-table-column>
      <el-table-column prop="s" label="视在电能(kw/h)"></el-table-column>
      <el-table-column prop="ys" label="运行时长(小时)"></el-table-column>
      <el-table-column prop="t" label="上报时间"></el-table-column>
      </el-table>
    </b-modal>
  </div>
</template>
<script>
import fake from "@/assets/utils/fake";
import pageable from "@/components/pageable";
export default {
  components: { pageable },
  data() {
    return {
      lights: [],
      count: 15,
      totalPage: 0,
      index: 0,
      hdata:[],
      ddata:[]
    };
  },
  mounted() {
    this.getData();
    this.getHLData();
    this.getDData();
  },
  methods: {
    currentChange(val) {
      this.index = (val - 1) * this.count;
      this.getData();
    },
    getData() {
      var ls = fake.getFakeLightDeath();
      var lights = [];
      var i = 0;
      ls.forEach(x => {
        i += 1;
        lights.push({
          name: x.name,
          addr: "57784181DS" + i,
          type: "勤上单灯控制器V2.0",
          online:0,
          state:0
        });
      });
      this.lights = lights.splice(this.index, this.count);
      this.totalPage = ls.length;
    },
    
    getRandomIntInclusive(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值
    },
    getRandomIntInclusive2(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return (Math.random() * (max - min + 1) + min).toFixed(2); //含最大值，含最小值
    },
    getHLData(){
        for(var i=0;i<27;i++){
            this.hdata.push({
                v:this.getRandomIntInclusive2(210,220),
                a:this.getRandomIntInclusive2(1.5,2.7),
                g:this.getRandomIntInclusive(97,140),
                w:0.00,
                hz:50,
                l:100.00,
                s:"开灯",
                t:"2022-07-07"
            })
        }
    },
    getDData(){
        for(var i=0;i<27;i++){
            this.ddata.push({
                y:this.getRandomIntInclusive2(57,69),
                w:0.00,
                s:0.00,
                ys:this.getRandomIntInclusive2(378,412),
                t:"2022-07-07"
            })
        }
    }
  }
};
</script>