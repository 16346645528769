<template>
  <div class="white">
    <actionbar
      :insert="true"
      :del="true"
      v-on:insert="insert"
      v-on:delete="del"
    >
    </actionbar>

    <el-table
      :data="items"
      style="width: 100%"
      highlight-current-row
      @current-change="currentRow"
      stripe=""
      :empty-text="$t('暂无数据')"
    >
      <el-table-column :label="$t('序号')" type="index"></el-table-column>
      <el-table-column :label="$t('头像')" width="250">
        <template slot-scope="scope">
          <b-img
            :src="scope.row.FaceUrl"
            width="220"
            v-if="scope.row.FaceUrl"
          ></b-img>
          <b-img
            src="/img/unknow.png"
            width="220"
            v-if="!scope.row.FaceUrl"
          ></b-img>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('描述')"
        prop="Describe"
        :show-overflow-tooltip="false"
      ></el-table-column>
      <!--<el-table-column :label="$t('操作')" prop="IsEnabled">
                <template slot-scope="scope">
                    <el-button size="mini" type="danger" @click="del(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>-->
    </el-table>
    <el-pagination
      style="text-align: center"
      layout=" prev, pager, next, total,sizes"
      @current-change="current_change"
      @size-change="pagesize_change"
      :current-page="currentPage"
      :page-sizes="[5, 10, 50, 100, 200]"
      :page-size="pagesize"
      :total="total"
      background
    >
    </el-pagination>

    <!-- 新增、修改对话框 -->
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="modalTitle"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-container>
          <b-row>
            <!-- 左边 -->
            <b-col>
              <el-upload
                class="avatar-uploader"
                action="/api/Upload/FileUploadSingle"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="modalData.ImageUrl"
                  :src="modalData.ImageUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </b-col>
            <!-- 右边 -->
            <b-col>
              <b-form-group v-bind:label="$t('人脸库')" :label-cols="2">
                <b-form-input
                  type="text"
                  v-model="setName"
                  readonly
                ></b-form-input>
              </b-form-group>
              <b-form-group v-bind:label="$t('备注')" :label-cols="2">
                <b-form-textarea
                  rows="3"
                  v-model="modalData.Describe"
                  placeholder="请输入备注"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group :label-cols="2">
                <p>
                  仅支持jpg、png、gif、bmp格式，文件大小不超过1M，且尺寸在1280*1280像素以内
                </p>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width: 100px" v-on:click="hiden(modallg)"
              >Cancel</b-button
            >
            <b-button type="submit" style="width: 100px" variant="primary"
              >OK</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- 删除多好看 -->
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/face/RemoveFace"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
import alarmAction from "@/components/alarmAction";
import { Link } from "@/assets/utils/datef";
export default {
  components: { actionbar, tablepage, modify, deletedata, alarmAction },
  data: function () {
    return {
      radio: 0,
      modalTitle: this.$t("添加人脸"),
      setName: "",
      modalData: {},
      loading: {},
      items: [],
      total: 0, // 默认数据总数
      pagesize: 5, // 每页的数据条数
      currentPage: 1, // 默认开始页面

      index: 0,
      sid: "", // 待删除的sid
      current: null,
      link: Link().filter(function (x) {
        return x.state == 1;
      }),
      selfChoose: {
        radio: null,
        devs: [],
        selected: {},
      },
      flagChoose: {
        radio: null,
        devs: [],
        selected: {},
      },
      leds: [],
    };
  },
  methods: {
    select(noloading, index) {
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      new Promise((res, rej) => {
        this.setName = this.$route.query.setname;
        this.modalData.FaceSetSid = this.$route.query.sid;
        let obj = {
          FaceSetSid: this.$route.query.sid,
          Index: (this.currentPage - 1) * this.pagesize,
          Count: this.pagesize,
        };
        this.$ksajax
          .ksPostPromise("/api/face/GetFacesBySetSid", obj, true)
          .then((data) => {
            this.items = data.Items;
            this.total = data.TotalCount;
            res();
          })
          .catch((err) => {
            rej(err);
          });
      })
        .then(() => {
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.select();
    },
    pagesize_change: function (pagesize) {
      this.pagesize = pagesize;
      this.currentPage = 1;
      this.select();
    },

    // 新增对话框
    insert() {
      this.modalData.Sid = "";
      this.modalData.ImageUrl = "";
      this.modalData.Describe = "";

      this.modalTitle = this.$t("创建人脸库");
      this.$refs.modallg.show();
    },
    // 隐藏对话框
    hiden(name) {
      if (!name) {
        this.$refs.modallg.hide();
      } else {
        this.$refs[name].hide();
      }
    },
    // 提交对话框
    onSubmit(evt) {
      evt.preventDefault();
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/face/RegisterFace", this.modalData, true)
        .then(() => {
          this.loading.close();
          this.hiden();
          this.select();
        })
        .catch((err) => {
          this.$throw(err);
          this.hiden();
        });
    },

    // 删除集合
    del(index, row) {
      if (index > 0 && row != null) {
        this.sid = row.Sid;
        this.$refs.del.show();
      }
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },

    currentRow(val) {
      this.current = val;
    },

    submited() {
      this.select();
    },

    deleted() {
      this.select();
    },

    handleAvatarSuccess(res) {
      this.modalData.ImageUrl = res.Data;
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";

      const iRet = isJPG || isGIF || isPNG;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!iRet) {
        throw this.$t("图片格式不正确");
      }
      if (!isLt2M) {
        throw this.$t("图片大小要小于2M");
      }
      return iRet && isLt2M;
    },
  },
  mounted: function () {
    this.select();
  },
};
</script>
