<template>
  <div class="white">
    <actionbar v-bind:select="true" v-bind:modify="true" v-on:select="select" v-on:modify="modify"></actionbar>

    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>
    <b-modal
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      class="ksmodal"
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-tabs>
        <b-tab :title="$t('车辆入场')">
          <alarmAction :car="true" :alarm="true" :value="modalData.inAlarm" :filter="true"></alarmAction>
          <hr />
        </b-tab>
        <b-tab :title="$t('车辆出场')">
          <alarmAction :car="true" :alarm="true" :value="modalData.outAlarm" :filter="true"></alarmAction>
          <hr />
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import alarmAction from "@/components/alarmAction";
import { Link } from "@/assets/utils/datef";
export default {
  components: { actionbar, tablepage, modify, alarmAction },
  data: function() {
    return {
      radio: 0,
      title: this.$t("修改"),
      modalData: this.resetData(),
      loading: {},
      cols: [
        { name: this.$t("停车场名称"), value: "ParkName" },
        { name: this.$t("设备名"), value: "DeviceName" },
        { name: this.$t("序号"), value: "Serial" }, 
        { name: this.$t("车辆入场"), value: "State", subvalue: "inAlarm" },
        { name: this.$t("车辆出场"), value: "State", subvalue: "outAlarm" },
      ],
      items: [],
      total: 0,
      count: 2000,
      index: 0,
      sid: "",
      current: null,
      link: Link().filter(function(x) {
        return x.state == 1;
      }),
      selfChoose: {
        radio: null,
        devs: [],
        selected: {}
      },
      flagChoose: {
        radio: null,
        devs: [],
        selected: {}
      },
      leds: []
    };
  },
  methods: {
    select(noloading, index) {
      if (!noloading) {
        if (!index) { 
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      new Promise((res, rej) => {
        this.$ksajax
          .ksPostPromise("/api/ParkCtl/GetDeviceParkVideoList", "", true)
          .then(data => {
            data.forEach(x => {
              x.inAlarm = {
                State: "关闭",
                LinkageActions: []
              };
              x.outAlarm={
                State: "关闭",
                LinkageActions: []
              };
            });
            this.items = data;
            this.total = data.length;
            res();
          })
          .catch(err => {
            rej(err);
          });
      })
        .then(() => {
          return new Promise((res, rej) => {
            let obj = {
              Index: this.index,
              Count: this.count,
              DevCtgSid: "201"
            };
            this.$ksajax
              .ksPostPromise("/api/device/GetSensorLinkages", obj, true)
              .then(data => {
                console.log(data);
                this.items.forEach(x => {
                  data.Items.forEach(y => {
                    if (y.LinkageActions) {
                      y.LinkageActions.forEach(l => {
                        l.link = this.link.find(o => o.value == l.LinkageType);
                        l.guid = this.guid();
                      });
                    }
                    if (y.DeviceSid == x.Sid) {
                      if (y.DataKind == 700) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.inAlarm = y;
                      }
                      if (y.DataKind == 701) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.outAlarm = y;
                      }
                    }
                  });
                });
                res();
              })
              .catch(err => {
                rej(err);
              });
          });
        })
        .then(() => {
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch(err => {
          this.$throw(err);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    guid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
      }
      return guid;
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.ksmodel.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.modalData.inAlarm = {
        Sid: this.current.inAlarm ? this.current.inAlarm.Sid : null,
        LinkageName: "车辆入场",
        IsEnabled: this.current.inAlarm ? this.current.inAlarm.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 700,
        ThresholdValue: 1,
        CompareSymbol: this.current.inAlarm
          ? this.current.inAlarm.CompareSymbol
          : 0,
        LinkageActions: this.current.inAlarm
          ? this.current.inAlarm.LinkageActions
          : [],
        IsContrary: this.current.inAlarm ? this.current.inAlarm.IsContrary : false
      };
      
      this.modalData.outAlarm = {
        Sid: this.current.outAlarm ? this.current.outAlarm.Sid : null,
        LinkageName: "车辆出场",
        IsEnabled: this.current.outAlarm ? this.current.outAlarm.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 701,
        ThresholdValue: 1,
        CompareSymbol: this.current.outAlarm
          ? this.current.outAlarm.CompareSymbol
          : 0,
        LinkageActions: this.current.outAlarm
          ? this.current.outAlarm.LinkageActions
          : [],
        IsContrary: this.current.outAlarm ? this.current.outAlarm.IsContrary : false
      };
      this.$refs.modallg.show();
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      return {
        inAlarm: { LinkageActions: [] },
        outAlarm: { LinkageActions: [] }
      };
    },
    submited() {
      this.select();
    },
    deleted() {
      this.select();
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    addItem(val) {
      if (val == 0) {
        //self
        var dev = this.selfChoose.devs.find(
          x => x.Sid == this.selfChoose.selected
        );
        var action = this.link.find(x => x.value == this.selfChoose.radio);
        this.modalData.self.LinkageActions.push({
          Sid: "",
          DeviceSid: dev.Sid,
          DeviceName: dev.DeviceName,
          LinkageType: action.value,
          Content: null,
          link: action
        });
      }
    },
    selfChange(val) {
      if ([0, 1, 2, 3, 4].indexOf(val) != -1) {
        if (this.leds.length == 0) {
          let obj = {
            Index: 0,
            Count: 1000
          };
          this.loading = this.$loading();
          this.$ksajax
            .ksPostPromise("/api/device/GetDeviceLedAds", obj, true)
            .then(x => {
              this.leds = x.Items;
              this.selfChoose.devs = x.Items;
              this.selfChoose.selected = this.leds[0].Sid;
              this.loading.close();
            })
            .catch(err => {
              this.$throw(err);
              this.loading.close();
            });
        } else {
          this.selfChoose.devs = this.leds;
          this.selfChoose.selected = this.leds[0].Sid;
        }
      }
    }
  },
  mounted: function() {
    this.select();
  }
};
</script>
