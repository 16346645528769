<template>
  <div class="Stands white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      v-bind:insert="true"
      v-bind:del="true"
      v-bind:sync="true"
      :init="true"
      v-on:select="select"
      v-on:insert="insert"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:init="init"
      v-on:sync="sync"
    ></actionbar>
    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>

    <modify
      ref="modify"
      :mapitem="mapitem"
      :modaldata="modalData"
      :title="title"
      :labels="labels"
      v-on:submited="submited"
      url="/api/Asset/AddLStand"
    ></modify>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/Asset/DelLStand"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<style>
.admin {
  background-color: white;
}
</style>

<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
export default {
  data: function() {
    return {
      modalData: {
        Sid: "",
        Serial: "",
        TypeSid: "",
        Lat: 0,
        Lng: 0,
        Address: "",
        Description: ""
      },
      labels: this.getLable(),
      title: "",
      cols: [
        { name: this.$t("序号"), value: "Serial" },
        { name: this.$t("描述"), value: "Description" },
        { name: this.$t("位置"), value: "Address" },
        { name: this.$t("详情"), value: "url", link: true }
      ],
      items: [],
      index: 0,
      count: 15,
      total: 0,
      current: null,
      sid: "",
      mapitem: {
        value: "Address",
        require: true,
        subvalue: false,
        Position: {
          Lat: "Lat",
          Lng: "Lng"
        }
      }
    };
  },
  components: {
    actionbar,
    tablepage,
    modify,
    deletedata
  },
  created: function() {
    if (this.$store.state.lStandType.length === 0) {
      this.$store.dispatch("lStandTypeAction");
    }
  },
  mounted: function() {
    this.select();
  },
  methods: {
    sync(){
       if (!this.current) {
        this.$throw(this.$t("选择一条数据"), this);
      }
      this.$loading();
       this.$ksajax
            .ksPostPromise("/api/asset/AsyncSensor", this.current.DeviceSid, true).then(()=>{
              this.$loading().close();
            }).catch(err=>{
              this.$throw(err);
              this.$loading().close();
            })
    },
    showModify() {
      this.labels = this.getLable();
      this.$refs.modify.show();
    },

    getLable() {
      return [
        {
          label: this.$t("序号"),
          type: "text",
          value: "Serial",
          require: true
        },
        {
          label: this.$t("描述"),
          type: "text",
          value: "Description",
          require: true
        },
        {
          label: this.$t("类型"),
          type: "select",
          value: "TypeSid",
          require: true,
          // "bind":[
          //     {"text":"智慧灯杆",value:"201809261223085461005533"}
          // ]
          bind: this.$store.state.lStandType
        },
        {
          label: this.$t("倾斜检测"),
          type: "select",
          value: "HasSensor",
          require: true,
          // "bind":[
          //     {"text":"智慧灯杆",value:"201809261223085461005533"}
          // ]
          bind: [
            { text: this.$t("是"), value: true },
            { text: this.$t("否"), value: false }
          ]
        },
        {
          label: this.$t("绑定传感器"),
          type: "text",
          value: "TcpSensorSerial",
          require: true
        },
        {
          label: this.$t("位置"),
          type: "map",
          value: "Address",
          require: true
        }
      ];
    },
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      return {
        Sid: "",
        Serial: "",
        TypeSid: "",
        Lat: 0,
        Lng: 0,
        Address: "",
        Description: "",
        HasSensor: "true",
        TcpSensorSerial:""
      };
    },
    select() {
      let req = {
        Index: this.index,
        Count: this.count
      };
      var self = this;
      this.$ksajax.ksPostData(
        "/api/asset/GetLampStands",
        req,
        true,
        success => {
          success.Items.forEach(element => {
            element.url = `/admin/stand/standinfo?sid=${element.DeviceSid}`;
          });
          self.total = success.TotalCount;
          self.items = success.Items;
        },
        () => {},
        false
      );
    },
    insert() {
      this.title = this.$t("新增");
      this.modalData = this.resetData();
      this.showModify();
    },
    modify() {
      if (!this.current) {
        this.$throw(this.$t("选择一条数据"), this);
      } else {
        this.modalData = {
          Sid: this.current.Sid,
          Serial: this.current.Serial,
          TypeSid: this.current.LampStandType.Sid,
          Lat: this.current.Lat,
          Lng: this.current.Lng,
          Address: this.current.Address,
          Description: this.current.Description,
          HasSensor: this.current.HasSensor + "",
          TcpSensorSerial:this.current.TcpSensorSerial
        };
        this.title = this.$t("修改");
        this.showModify();
      }
    },
    del() {
      if (!this.current) {
        this.$throw("请先选择一打数据", this);
      } else {
        this.sid = this.current.Sid;
        this.$refs.del.show();
      }
    },
    tableCreated() {
      // this.select();
    },
    currentPage(val) {
      this.index = (val - 1) * this.count;
      this.select();
    },
    submited() {
      this.index = (1 - 1) * this.count;
      this.select();
    },
    deleted() {
      this.index = (1 - 1) * this.count;
      this.select();
    },
    init(){
         if (!this.current) {
        this.$throw("请先选择一打数据", this);
      }
      var data={
          Serial:this.current.Serial,
          Type:24,
          Data:0
      }
      this.$loading();
      this.$ksajax.ksPostPromise("/api/lorawan/Config",data,true).then(()=>{
          this.$loading().close();
      }).catch(err=>{
          this.$throw(err);
          this.$loading().close();
      })
    }
  }
};
</script>

