<template>
    <div class="content">
        <mainMenu ref="mainmenu"  sid="/Admin/Stand"></mainMenu>
        <div class="subrouter">
        <router-view></router-view></div>
    </div>
</template>
<style>
.admin{
    background-color: white;
}
</style>
<script>
import mainMenu from '../../components/mainMenu'
export default {
    components:{
        mainMenu
    }
}
</script>

