<template>
    <div class="content">
        <mainMenu ref="mainmenu"  sid="/Admin/Parking"></mainMenu>
        <div class="subrouter">
        <router-view></router-view></div>
    </div>
</template>
<script>
import mainMenu from '../../components/mainMenu'
export default {
    components:{
        mainMenu
    }
}
</script>

