export default {
    guid() {
        var guid = "";
        for (var i = 1; i <= 32; i++) {
            var n = Math.floor(Math.random() * 16.0).toString(16);
            guid += n;
            if ((i == 8) || (i == 12) || (i == 16) || (i == 20))
                guid += "-";
        }
        return guid;
    },
    getFakeGateways() {
        return [{
            "name": "围面前路网关",
            "lat": 22.98987,
            "lng": 114.000364,
            "sid": "cb96c183-e9d3-783c-7011-3e02cfe3ab44",
            "serial": "3f365b67-79f8-2757-704c-3f4f5aaa431a"
        }, {
            "name": "常平大道1号网关",
            "lat": 22.982664,
            "lng": 114.018598,
            "sid": "6a656f35-215a-2318-909a-1240f686caef",
            "serial": "a19e1e57-abc6-c14c-9c68-41e51df89a95"
        }, {
            "name": "常平大道2号网关",
            "lat": 22.981578,
            "lng": 114.037508,
            "sid": "c49f67d3-4ac2-f624-7f20-161718f77618",
            "serial": "cbccf278-6616-9c72-abcb-2705192431e2"
        }, {
            "name": "常平大道3号网关",
            "lat": 22.987494,
            "lng": 114.037846,
            "sid": "462d3c46-0e39-08f1-6703-1ad1fc9a19f8",
            "serial": "9d8fc438-e6ce-4e09-08b6-eb031aa15771"
        }, {
            "name": "常平大道4号网关",
            "lat": 22.994369,
            "lng": 114.032084,
            "sid": "97b627cd-1f52-3d9c-ae3b-3dd4dc54837c",
            "serial": "86122707-adb7-5895-f370-ad41ec48ed60"
        }, {
            "name": "工业三路1号网关",
            "lat": 22.956591,
            "lng": 114.024381,
            "sid": "f9d40564-aad4-2db9-38f9-48ac320df115",
            "serial": "f1db3171-31c8-92d8-39e3-3d6c1eca5296"
        }, {
            "name": "工业三路1号网关",
            "lat": 23.008856,
            "lng": 114.031966,
            "sid": "25f00d9d-2bd2-49f3-285c-8654bd9f1705",
            "serial": "40ce4cdb-68bc-4192-1d55-6e8e0feecaeb"
        }, {
            "name": "工业三路2号网关",
            "lat": 22.974802,
            "lng": 113.994399,
            "sid": "98469612-9307-b822-8404-1c2416610b0e",
            "serial": "582f9790-bce9-efa6-e641-07a78c8abba9"
        }, {
            "name": "工业三路3号网关",
            "lat": 23.003504,
            "lng": 114.062388,
            "sid": "24248edd-1eeb-1288-e8d0-6cff185fa700",
            "serial": "d9d6a669-ef65-172f-af70-11970fd4930d"
        }, {
            "name": "工业三路4号网关",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "f8c41a76-c60a-835a-c256-7b95585f2f85",
            "serial": "5bc14152-a742-2ca2-99ac-5ed6ba843d66"
        }]
    },
    getFakeLight(){
        var gs=this.getFakeGateways();
        var datas=[];
        gs.forEach(x=>{
            for(var i=0;i<45;i++){
                var name=x.name.replace("网关","灯具");
                name=`${name}_${i}号`;
                var state=0;
                if(i==100||i==102||i==142||i==145){
                    state=1;
                }
                if(i==2||i==65||i==67||i==68){
                    state=2;
                }
                datas.push({
                    name:name,
                    "lat": 22.9975,
                    "lng": 114.022032,
                    sid:this.guid(),
                    serial:this.guid(),
                    state:state
                })
            }
        })
        return datas;
    },
    getFakeLightDeath(){
        return [{
            "name": "围面前路灯具_0号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "261b17f1-0ec1-3fd7-68c9-bd5fc4b9055e",
            "serial": "08f7fb9e-7090-c3e4-1cc9-e3a641366b0b",
            "state": 0
        }, {
            "name": "围面前路灯具_1号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e7eabb52-5b6b-bc9c-edeb-488b40fd426d",
            "serial": "0b386c4c-0876-7591-390c-c931125c6398",
            "state": 0
        }, {
            "name": "围面前路灯具_2号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e5fc2697-e900-0fce-bae6-3d3dcf39624e",
            "serial": "47014cfb-9da4-4386-2510-7197a3c9e256",
            "state": 2
        }, {
            "name": "围面前路灯具_3号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "1244482e-b621-b623-0ae2-3c4496c81e2c",
            "serial": "fd8c2d14-9e46-41a0-d03e-877ca90fa7b3",
            "state": 0
        }, {
            "name": "围面前路灯具_4号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "29946bb3-5c66-bcaf-b972-8cd05a06f685",
            "serial": "b6463f27-2cc8-d371-c522-0ef66b7045d9",
            "state": 0
        }, {
            "name": "围面前路灯具_5号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "330e574f-a08d-f440-c9b1-b170851db4bb",
            "serial": "699e3a74-5f5f-0a3c-0d56-bf45e4ad88c3",
            "state": 0
        }, {
            "name": "围面前路灯具_6号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "350a639c-da80-8bd4-21fd-2a9bc6a252a6",
            "serial": "a9055489-ddc0-b71d-a3d8-925c0f94e2f0",
            "state": 0
        }, {
            "name": "围面前路灯具_7号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "875fdc59-a6a8-f325-a8b8-c9166598d426",
            "serial": "5baa4969-6c3a-4c73-c9d0-1ea812c355b4",
            "state": 0
        }, {
            "name": "围面前路灯具_8号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "9841f7f0-711a-001a-57ef-4e5f62aea3cc",
            "serial": "7370c362-e10f-8725-987e-d334d33f74bd",
            "state": 0
        }, {
            "name": "围面前路灯具_9号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ee229ff5-06d0-7369-1cf9-436500dc0d87",
            "serial": "d7eaac37-24d4-2720-71ce-ff827fa1d396",
            "state": 0
        }, {
            "name": "围面前路灯具_10号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "5e841931-ae16-cfb1-21c8-cbfc88fafc04",
            "serial": "b3dca2a9-6bd0-f64d-30c0-5ddfe94cfc09",
            "state": 0
        }, {
            "name": "围面前路灯具_11号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "2ebcf059-1e12-d8d2-d4fc-b94463c4ea0a",
            "serial": "7fa3c626-c5af-8f48-0812-836bd25ffea5",
            "state": 0
        }, {
            "name": "围面前路灯具_12号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "28505d15-3824-d9d1-2f6e-c4c55e77942f",
            "serial": "9c03fc44-b366-70d2-ebc1-51748d5b2ddf",
            "state": 0
        }, {
            "name": "围面前路灯具_13号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "b5555ade-cd7a-2739-056f-d1f299602bcd",
            "serial": "9c518255-34c4-d69e-0a5a-5869e3ba00ee",
            "state": 0
        }, {
            "name": "围面前路灯具_14号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "9337be44-55d4-5eb3-ba6d-119ae1b93021",
            "serial": "9caf669e-b42d-f6ab-37ac-83fadf647237",
            "state": 0
        }, {
            "name": "围面前路灯具_15号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "4b0d57fd-9df1-a0d3-fad0-a46c3f0febda",
            "serial": "fafb652d-1188-6997-27e9-c70f967ff787",
            "state": 0
        }, {
            "name": "围面前路灯具_16号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "f0d96b2d-3861-9cc9-0759-ba892460ca16",
            "serial": "4fd184f0-8370-9043-f122-f22dce1acef0",
            "state": 0
        }, {
            "name": "围面前路灯具_17号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ad284d38-d3aa-d590-633e-edae631853b3",
            "serial": "96e62e42-9fe9-1a3c-2e3e-fafd45f6d1b4",
            "state": 0
        }, {
            "name": "围面前路灯具_18号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "58482ace-fb08-8bfe-31b9-72b54d894359",
            "serial": "f31df7dd-ac7d-9d3d-2773-9ba1a455701b",
            "state": 0
        }, {
            "name": "围面前路灯具_19号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "30b2a136-d3c2-ea56-7215-12d6234beb7f",
            "serial": "5f74da86-31c8-655a-0c0d-c6400bb51f85",
            "state": 0
        }, {
            "name": "围面前路灯具_20号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "df62eb69-a4d7-ac85-65d5-01765b3d4f91",
            "serial": "0a057e9f-60c6-be4c-efa3-566481020f57",
            "state": 0
        }, {
            "name": "围面前路灯具_21号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e507da93-9504-73a2-0e20-c2100969ffaa",
            "serial": "8ea73d74-f000-533e-b037-89a7d90d39f6",
            "state": 0
        }, {
            "name": "围面前路灯具_22号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "b4310a2c-ea13-def3-c983-c86eca908fba",
            "serial": "5ea8e089-e413-ff61-cad4-f5fe80b5e6e0",
            "state": 0
        }, {
            "name": "围面前路灯具_23号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ac3c5653-af3b-b94d-851b-a5eb810e917d",
            "serial": "2d838975-7e44-a9b3-5ccd-34065fdb6d0d",
            "state": 0
        }, {
            "name": "围面前路灯具_24号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "35e810b7-1afa-5c5c-f78b-db1a65d1f824",
            "serial": "8d1d1bd9-d45c-302c-2d69-165b7fdb3b5f",
            "state": 0
        }, {
            "name": "围面前路灯具_25号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "88a2bd3c-791a-78f9-69ad-fe05291a1b35",
            "serial": "c7b0ef6e-7478-b055-d6f9-78fd2ecfa0f0",
            "state": 0
        }, {
            "name": "围面前路灯具_26号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ccf1904b-afde-4b7b-389f-03ce0d10ee78",
            "serial": "cfa83dfd-0979-1225-b7ca-664b54134b4e",
            "state": 0
        }, {
            "name": "围面前路灯具_27号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "f5367320-4d29-fefd-15f4-169bd1b0baea",
            "serial": "bf6fe4d0-966b-de7a-e45c-9adb8e96daa3",
            "state": 0
        }, {
            "name": "围面前路灯具_28号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "54441b10-95a5-4614-de95-1295610029b5",
            "serial": "ad3c3f59-1d1b-6768-e089-cdf3fa14ff9e",
            "state": 0
        }, {
            "name": "围面前路灯具_29号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "cf77eaab-74bb-bd93-b6db-46d90eccce4c",
            "serial": "bc1b6704-59c5-eda0-cac5-340dfdc51f3e",
            "state": 0
        }, {
            "name": "围面前路灯具_30号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "88ff4aa2-b82a-6e20-adf8-9bec9905c7af",
            "serial": "87f0171d-7336-fb60-767e-fdfc4b583029",
            "state": 0
        }, {
            "name": "围面前路灯具_31号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "7ba798e5-d3ec-ec30-cbcd-5e74bfd843c1",
            "serial": "674e0cad-ef62-7ff9-81f9-2438a4999627",
            "state": 0
        }, {
            "name": "围面前路灯具_32号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "62208028-88c6-213e-0c1a-69a0055c8ec9",
            "serial": "3a952601-a431-1701-48e1-f1c0956d7046",
            "state": 0
        }, {
            "name": "围面前路灯具_33号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "9d05536d-8dcb-efc3-0662-0ed2e206326b",
            "serial": "eefc5b1f-13ad-0503-48ca-b9d06be7cbfa",
            "state": 0
        }, {
            "name": "围面前路灯具_34号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e697497e-091f-238c-bdd4-d930725b8bdf",
            "serial": "d55ac941-f44a-b6df-815e-d0da7fff3127",
            "state": 0
        }, {
            "name": "围面前路灯具_35号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e44a44c8-3d7e-9ef8-70fe-a8185848cea6",
            "serial": "20ade52a-d42c-59c4-509c-473f94f3a2c3",
            "state": 0
        }, {
            "name": "围面前路灯具_36号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "62db69e2-f1f0-8e04-4d1c-0e810b512881",
            "serial": "c063872c-4b52-1556-612e-abebcbbc97dc",
            "state": 0
        }, {
            "name": "围面前路灯具_37号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "12765b45-1829-f887-280d-c035f6cd65bd",
            "serial": "ef40ba06-a93b-67dc-6f6b-acb2cf0d78b0",
            "state": 0
        }, {
            "name": "围面前路灯具_38号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "81ab2978-a759-6230-8dc9-6e47f76f5d93",
            "serial": "b1889415-1b2a-a692-c77f-555af9c65727",
            "state": 0
        }, {
            "name": "围面前路灯具_39号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "30b710f5-aab6-82e6-0b1c-0045c02e36ac",
            "serial": "24f7d53c-6d9e-059e-5a6f-2ec18e7a45ef",
            "state": 0
        }, {
            "name": "围面前路灯具_40号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "5531fbb0-d1b3-2b4c-7967-dfa7413606a8",
            "serial": "b0e3e830-c5d7-65c3-c5b1-71f24bfa059e",
            "state": 0
        }, {
            "name": "围面前路灯具_41号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ea1434de-d3e5-faaa-9cc2-dffc3e185927",
            "serial": "813ee718-335b-5419-a95e-e93b77195f6b",
            "state": 0
        }, {
            "name": "围面前路灯具_42号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "4de9033e-d129-116e-3fcf-690f2b956f18",
            "serial": "9ad9583b-fa75-93ba-2162-5db4147ac22c",
            "state": 0
        }, {
            "name": "围面前路灯具_43号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "4e6ec02e-a336-f4ad-b27a-368d56f88ec0",
            "serial": "24689009-371e-cda9-d45a-2c21001b113a",
            "state": 0
        }, {
            "name": "围面前路灯具_44号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "41b5a12a-0ef9-19d5-1f7b-360b74b6faca",
            "serial": "e6a25be5-4286-d5bc-3752-5f2a85d65f56",
            "state": 0
        }, {
            "name": "常平大道1号灯具_0号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "4670e282-b2d6-83d2-e49c-7d3a681c07a6",
            "serial": "208341b3-d7f9-5772-6c49-d7a8b36d2b69",
            "state": 0
        }, {
            "name": "常平大道1号灯具_1号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ff655e75-5e5d-6c10-45be-2e9c1b4afbc1",
            "serial": "7843e91e-08da-0e26-6ffb-0f7f9164dcec",
            "state": 0
        }, {
            "name": "常平大道1号灯具_2号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "31eec99c-9e22-2b6c-0a1f-c6b373c77b62",
            "serial": "16258552-03cd-f545-d7da-e456ba3db8bb",
            "state": 2
        }, {
            "name": "常平大道1号灯具_3号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e5285827-2e1f-9494-3c2c-02e82cf570d5",
            "serial": "a17291ce-b686-1ca1-6415-21e61f9a7e5b",
            "state": 0
        }, {
            "name": "常平大道1号灯具_4号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "39ad5837-573b-28ba-1d00-45621bda3761",
            "serial": "083ab8ec-ae7e-640c-1b5f-e7327f93fa6b",
            "state": 0
        }, {
            "name": "常平大道1号灯具_5号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "3205981f-050c-021a-c77f-d1a215c89c07",
            "serial": "3b16fed3-040e-b781-2dbb-4ab685bdf33e",
            "state": 0
        }, {
            "name": "常平大道1号灯具_6号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ef38e884-c83e-6d73-8b44-dd5a14224d41",
            "serial": "a2a6fc64-965f-e979-1d5b-25e093f691c4",
            "state": 0
        }, {
            "name": "常平大道1号灯具_7号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "bab87f32-acb2-7590-c180-358180dd37f2",
            "serial": "8ca512a9-bf90-82c3-b8c3-1ef1d38c0f10",
            "state": 0
        }, {
            "name": "常平大道1号灯具_8号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "df7f53ba-b323-6bf2-30bb-5bd0103f556f",
            "serial": "38626ae2-096f-e212-bd90-eed13e61ce59",
            "state": 0
        }, {
            "name": "常平大道1号灯具_9号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "bd705d60-2ad7-cc4c-9480-3646b6392ae6",
            "serial": "d234f2af-6f36-b6c4-0937-ea57a9dcc202",
            "state": 0
        }, {
            "name": "常平大道1号灯具_10号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "76a4aee8-78fa-b28b-0aec-6d8e978cee92",
            "serial": "57bd6d89-7445-e4f7-b5ca-b1301109d365",
            "state": 0
        }, {
            "name": "常平大道1号灯具_11号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "bb85ddab-edf2-cab3-445f-6688a2c2a2b8",
            "serial": "0113dc79-06d4-44b5-e838-fa042fe3afc8",
            "state": 0
        }, {
            "name": "常平大道1号灯具_12号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "5254c60c-83f3-1747-f4b0-1ac9c14022aa",
            "serial": "414bda7f-4fee-1078-dee9-2caf5725b9c1",
            "state": 0
        }, {
            "name": "常平大道1号灯具_13号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "9fef4b9f-3a0c-aba4-860d-bd2ee75d319f",
            "serial": "b96bf1b0-9e98-3100-5df1-e7924e25e943",
            "state": 0
        }, {
            "name": "常平大道1号灯具_14号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "a6e92ff5-ad88-0073-0f39-d3f33fbea6bb",
            "serial": "095d7a3a-0574-fa84-f30d-c8fda8040c99",
            "state": 0
        }, {
            "name": "常平大道1号灯具_15号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "2e7cf95d-ef37-8545-4345-3a6953aada3b",
            "serial": "bf91ed3b-c696-53bb-a44d-1fabe9fdb0a4",
            "state": 0
        }, {
            "name": "常平大道1号灯具_16号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "a0644c3b-6c7c-e998-1952-ec782ac06e21",
            "serial": "4d7b46ca-10c6-b2cb-9f4d-5d65bdd7d29d",
            "state": 0
        }, {
            "name": "常平大道1号灯具_17号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "4dbaac8a-f13f-a76f-b873-3de2fca9bdd9",
            "serial": "cddf9b3f-6a96-1de1-eae8-9da9e61408fe",
            "state": 0
        }, {
            "name": "常平大道1号灯具_18号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "c71cec31-e2c0-e76f-9724-c5946930b614",
            "serial": "5312bc28-3fd7-f902-98e8-69ad284810b1",
            "state": 0
        }, {
            "name": "常平大道1号灯具_19号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "fc6bf87c-2b67-7260-6439-99b12144beb7",
            "serial": "f2b7ef29-2d5f-61f2-2498-1f6717a15f2c",
            "state": 0
        }, {
            "name": "常平大道1号灯具_20号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "7b67ac4c-77e9-7203-387e-9e659d830615",
            "serial": "e4cc189d-4627-c1f5-b5d2-322e415275b7",
            "state": 0
        }, {
            "name": "常平大道1号灯具_21号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "64741423-e86e-c4ee-1cec-e31b426ddc41",
            "serial": "94dcfa72-829e-987f-bc73-62c5d3f43515",
            "state": 0
        }, {
            "name": "常平大道1号灯具_22号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "f9e58be5-2fa7-0132-dac6-4f343a499afc",
            "serial": "dfc567af-d8b6-c960-dffe-a779efa67491",
            "state": 0
        }, {
            "name": "常平大道1号灯具_23号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "6f9a93f6-5757-765c-f400-0a920ab5af1f",
            "serial": "08cfaa0b-9b24-4e2e-ffed-93c2de3995d5",
            "state": 0
        }, {
            "name": "常平大道1号灯具_24号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ea90c9bb-1b1e-48a8-64ca-fe1220276814",
            "serial": "162fcc07-5fb0-9acf-eed1-66fc628a0453",
            "state": 0
        }, {
            "name": "常平大道1号灯具_25号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ea6a83d8-d0ed-3c12-03a6-216897acfcc2",
            "serial": "3cbdae4e-a1d3-e6a9-ca7f-916c1b48d1bc",
            "state": 0
        }, {
            "name": "常平大道1号灯具_26号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "0f21731f-0b0a-beb2-4a3a-42be895029f3",
            "serial": "dc991047-68c8-c2d5-6be5-7fa303d6de0c",
            "state": 0
        }, {
            "name": "常平大道1号灯具_27号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "bcf03525-dec1-d91a-2bf0-2833b3a175d4",
            "serial": "cc45b033-a5f5-6c0c-7ced-6d746474fd8b",
            "state": 0
        }, {
            "name": "常平大道1号灯具_28号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e5496857-c22b-5e73-2e8e-c5eaf625e1ac",
            "serial": "e099e5ad-9864-5ec5-b56a-d0442661cd06",
            "state": 0
        }, {
            "name": "常平大道1号灯具_29号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "cf980cf7-526a-fee4-7198-ac58b72bb43d",
            "serial": "465e66c7-15c2-9925-1893-fe34a4e51937",
            "state": 0
        }, {
            "name": "常平大道1号灯具_30号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "9ed18a11-e954-e4f0-ec77-575fdc6ac950",
            "serial": "4c00e752-499a-f084-17dd-99abb4dc00e5",
            "state": 0
        }, {
            "name": "常平大道1号灯具_31号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "0581d1cd-b74b-c8fe-6d3a-7b80a67bb93c",
            "serial": "1693b7b0-0a75-d658-0db4-44a6d357d472",
            "state": 0
        }, {
            "name": "常平大道1号灯具_32号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "2a48e05b-8de8-d655-b6a3-c6a228c39f01",
            "serial": "039625df-5468-65cb-c54e-23ebaabbddb6",
            "state": 0
        }, {
            "name": "常平大道1号灯具_33号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "8b255f64-b29b-550d-1279-2662ff5ded17",
            "serial": "87466b9b-e5d3-0ce8-8698-a1e638edb647",
            "state": 0
        }, {
            "name": "常平大道1号灯具_34号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "3165ce8d-1146-f64b-c11c-b6bdf630a70c",
            "serial": "c2a4be9e-74e0-0df2-8265-73e6069feedd",
            "state": 0
        }, {
            "name": "常平大道1号灯具_35号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "3aabcc0e-6234-9f05-0d58-b6657ac53749",
            "serial": "84467dcf-5e8d-933b-2676-68511a40cd97",
            "state": 0
        }, {
            "name": "常平大道1号灯具_36号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "2f468efd-8ea3-b388-4299-24ddafde778c",
            "serial": "0d19b1dc-1983-1b52-3fb3-9f76c343d80c",
            "state": 0
        }, {
            "name": "常平大道1号灯具_37号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "cdc57843-a1d4-b072-bc00-5a381aeb85dc",
            "serial": "fa552ab3-1808-35b3-5b75-069f94df3071",
            "state": 0
        }, {
            "name": "常平大道1号灯具_38号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "d6b7bb76-7a73-46c4-14be-a91cd419a09e",
            "serial": "46a98c44-e03b-ed4a-96cb-99603ee4065b",
            "state": 0
        }, {
            "name": "常平大道1号灯具_39号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "fa853378-4c66-55a1-00d6-1b8dbd2ad222",
            "serial": "21883470-d2f6-0fca-5e34-56247ae5d779",
            "state": 0
        }, {
            "name": "常平大道1号灯具_40号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ce5dc34d-9546-4d19-32ae-c4cbfaffae4b",
            "serial": "9ab620e8-b7ef-3ee8-f74b-42c0b37a5795",
            "state": 0
        }, {
            "name": "常平大道1号灯具_41号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "d0777520-1222-0539-ea14-69d3e27c25ad",
            "serial": "5d6f45a6-e933-431a-ebb0-4af6e520f568",
            "state": 0
        }, {
            "name": "常平大道1号灯具_42号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "76aa3059-86c1-c209-0e4e-2da3805c669a",
            "serial": "23b57192-03b3-5e72-edf5-b0ecf4ea9d1c",
            "state": 0
        }, {
            "name": "常平大道1号灯具_43号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "b544929c-6c5b-5b5d-2729-acab176da89f",
            "serial": "77e5f646-a5d0-bd05-23df-9f2212e70dc1",
            "state": 0
        }, {
            "name": "常平大道1号灯具_44号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "0d6b022e-0625-3d28-c98e-73e38b22794a",
            "serial": "34cd632e-3983-0906-22b1-b17e254b4b7d",
            "state": 0
        }, {
            "name": "常平大道2号灯具_0号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ef37b1cc-3723-0595-a2a2-a6754d5b2737",
            "serial": "3f0a5222-fb20-3d0b-e2cc-02ff77d86252",
            "state": 0
        }, {
            "name": "常平大道2号灯具_1号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "1c5c9547-e789-16ea-8330-4b618eb68307",
            "serial": "c54363d3-879d-da0c-d1ea-99bdf140de1f",
            "state": 0
        }, {
            "name": "常平大道2号灯具_2号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "1f185af0-7996-4d27-64e9-1fe12cbd2fe6",
            "serial": "87652c91-99fb-022a-4008-f62e9a4fc46b",
            "state": 2
        }, {
            "name": "常平大道2号灯具_3号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "d5e40829-049b-4331-87ce-3472809c4b5a",
            "serial": "e35ac416-aecf-0bbd-78c7-08baf71d20c6",
            "state": 0
        }, {
            "name": "常平大道2号灯具_4号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "937c1053-8747-e762-3c01-1c1da9b08176",
            "serial": "cd3518f0-35d3-4aa9-8a44-7798ee563dba",
            "state": 0
        }, {
            "name": "常平大道2号灯具_5号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "14e3277e-788c-358e-5244-088a4eadcfe7",
            "serial": "00e0b81d-e13a-7bed-471c-23f18e6339f5",
            "state": 0
        }, {
            "name": "常平大道2号灯具_6号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "19b93acd-0495-e7da-0e7d-d38651f8e2d2",
            "serial": "d266773c-f6e4-8d50-084d-02df391a841d",
            "state": 0
        }, {
            "name": "常平大道2号灯具_7号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "92570f75-88d7-29f7-e1a7-1ef64e65e7da",
            "serial": "ec83295e-5381-1fba-34fb-92b7d3f3589d",
            "state": 0
        }, {
            "name": "常平大道2号灯具_8号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "34da6660-17f1-18a1-79e4-2019d3766bc3",
            "serial": "6cbcdd4e-9ae9-1333-93ef-06898cbb1dff",
            "state": 0
        }, {
            "name": "常平大道2号灯具_9号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "1032095a-3ce9-61f1-464c-63ab44111d8b",
            "serial": "deb06c88-002c-50ce-a5b7-a9c8a0e8e7b6",
            "state": 0
        }, {
            "name": "常平大道2号灯具_10号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ca8802b6-cc4f-c505-cb23-457ad866d6cf",
            "serial": "86099554-1d05-8119-92e3-89d999f50586",
            "state": 0
        }, {
            "name": "常平大道2号灯具_11号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "5561709f-5df6-7b1a-74b3-e9fdc7447346",
            "serial": "5cdb43a1-e488-eebe-3b62-6048690b2e2b",
            "state": 0
        }, {
            "name": "常平大道2号灯具_12号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "7537161a-06f5-a231-cea6-c16a7cca4f87",
            "serial": "c28510c7-f452-1542-e1e3-fe86dc06c3fc",
            "state": 0
        }, {
            "name": "常平大道2号灯具_13号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "24793142-1c91-d729-2272-934a4a4ed6aa",
            "serial": "b5a1601c-6c3c-8fae-857c-1e5ddbf53ef3",
            "state": 0
        }, {
            "name": "常平大道2号灯具_14号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "0416d6fe-5419-3f9f-0bea-f3757e35e639",
            "serial": "d0ba92b5-ba34-df0c-0e63-10665c19fbd6",
            "state": 0
        }, {
            "name": "常平大道2号灯具_15号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "bbf518aa-6ea9-277f-239c-e66d8ca6c892",
            "serial": "9ceb09cc-da23-7c64-a223-c7bed6e672ad",
            "state": 0
        }, {
            "name": "常平大道2号灯具_16号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "c2a86ba3-b903-08ca-8091-19136a74dc7a",
            "serial": "85f3c94b-de86-3095-ab0e-70adb4c3bf0f",
            "state": 0
        }, {
            "name": "常平大道2号灯具_17号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "00a22ac2-e1a1-b085-cda7-abe481563bbd",
            "serial": "7687ebbb-c8f8-da0f-9882-398121bbf617",
            "state": 0
        }, {
            "name": "常平大道2号灯具_18号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "42e733a0-677e-b093-00e3-bd1518f515ed",
            "serial": "ac49cdba-b8bf-c2a1-668f-eefb3bf95f00",
            "state": 0
        }, {
            "name": "常平大道2号灯具_19号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "19bc948e-cf4b-795b-8a04-6ec9529e94d3",
            "serial": "ce79c165-eb82-34ba-46dc-af3758990d5f",
            "state": 0
        }, {
            "name": "常平大道2号灯具_20号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e75d8335-0bd7-83f9-41e3-f7968d5cb4de",
            "serial": "7afc81ad-c0fd-fe29-0ead-b4fdd37b76b7",
            "state": 0
        }, {
            "name": "常平大道2号灯具_21号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "2de6d76f-4834-63b1-5dee-68c09b25bffd",
            "serial": "8483ba1c-78af-8d90-58eb-965b8df64346",
            "state": 0
        }, {
            "name": "常平大道2号灯具_22号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "c7fec928-f5f3-0c6d-d754-6e8e25af1983",
            "serial": "837c9306-77cd-042e-07d0-90740ead441c",
            "state": 0
        }, {
            "name": "常平大道2号灯具_23号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "1f543557-cefd-de55-f040-aa552b82def3",
            "serial": "8026fc3e-1238-b8e9-0286-89fa8daaeddc",
            "state": 0
        }, {
            "name": "常平大道2号灯具_24号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "f73c525c-77b7-66d5-f78f-217660ab13c4",
            "serial": "ea1102e3-d909-01f6-7584-ea694bb37382",
            "state": 0
        }, {
            "name": "常平大道2号灯具_25号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "9d1978ff-9fab-be2b-2b6d-4d6ebd29b301",
            "serial": "eab7b259-110f-4330-a83d-399e04be3a41",
            "state": 0
        }, {
            "name": "常平大道2号灯具_26号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "d554e77e-72e7-e9ac-5468-0ff9b49925c0",
            "serial": "26c77fb8-b3e4-4edd-7670-70d43c5c50e5",
            "state": 0
        }, {
            "name": "常平大道2号灯具_27号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "b98dd88d-bf6d-1aa5-1657-ca8b3149b3e9",
            "serial": "62dff6f3-46aa-267f-54b6-0d56afa13cee",
            "state": 0
        }, {
            "name": "常平大道2号灯具_28号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "66033d21-0456-6bf7-d083-bbe9b9dc0d74",
            "serial": "e94c322d-41a9-27d0-b2d5-6bcf3df23604",
            "state": 0
        }, {
            "name": "常平大道2号灯具_29号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "c8ca4eca-908c-2a92-68c2-7b8f5c7348a3",
            "serial": "6e503f95-8795-ab4e-9b8d-34b65ea58da5",
            "state": 0
        }, {
            "name": "常平大道2号灯具_30号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "9cccb21c-e158-b63a-fba6-889a549a5062",
            "serial": "7ad2d0f6-d62f-fd82-7b63-2c0d1fec701b",
            "state": 0
        }, {
            "name": "常平大道2号灯具_31号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "756f17c3-c174-5737-1594-7b972a7563de",
            "serial": "7f1a6c1c-c7e1-baef-896d-075d0850716e",
            "state": 0
        }, {
            "name": "常平大道2号灯具_32号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "b871590a-7c06-567d-4578-8b04949eecff",
            "serial": "8333d8f3-563f-6e14-ebed-c02e428612b5",
            "state": 0
        }, {
            "name": "常平大道2号灯具_33号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "a97113de-04a2-51da-3425-57694d09e8f5",
            "serial": "bf3320d4-0b3c-b8f6-13de-309a473c12f1",
            "state": 0
        }, {
            "name": "常平大道2号灯具_34号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "65056909-e958-b7a3-ab28-9d0f3126f3e6",
            "serial": "7f3d7808-d2d9-f0c2-9cb0-8f9d204ba620",
            "state": 0
        }, {
            "name": "常平大道2号灯具_35号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "59a4bc41-bd49-63e7-4f44-d568ae4812f9",
            "serial": "85d69c79-211c-26cc-bf8e-cd4e85e96bfd",
            "state": 0
        }, {
            "name": "常平大道2号灯具_36号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "2b083f1b-7fb2-204e-7d12-91fc1239b9a9",
            "serial": "7012037d-6e5a-9042-9515-b4e53006ff22",
            "state": 0
        }, {
            "name": "常平大道2号灯具_37号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "2438af8c-2e30-8148-4387-a8ee6721831e",
            "serial": "659cbad7-9be2-f710-70b2-1e59d9668b66",
            "state": 0
        }, {
            "name": "常平大道2号灯具_38号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "74c17c66-c255-44ef-d207-3a9f4c50a955",
            "serial": "4040398c-e83f-97db-f9cf-52dc0de2eb54",
            "state": 0
        }, {
            "name": "常平大道2号灯具_39号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "15b9f54b-e118-40c6-7540-a7c23bc42a30",
            "serial": "94291e11-4460-a54c-f12a-0c2e6017fc08",
            "state": 0
        }, {
            "name": "常平大道2号灯具_40号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "9e28936a-6b8d-56e3-29f8-108ce727f679",
            "serial": "7d0815d9-d941-3c0a-bd11-d0e326cae60e",
            "state": 0
        }, {
            "name": "常平大道2号灯具_41号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e3a2d5f7-5fed-8866-4d9a-f81bb6af2616",
            "serial": "79764d4c-f5b4-4524-4637-f9c40a0c1f70",
            "state": 0
        }, {
            "name": "常平大道2号灯具_42号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "b7945a7a-ea32-1741-2a46-89b083405f54",
            "serial": "fc674a9a-2b7d-a06d-ef64-e8eed857b3d1",
            "state": 0
        }, {
            "name": "常平大道2号灯具_43号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "03a3e75b-8037-484e-8e47-3e123a484da2",
            "serial": "d01b0812-1a4a-7e50-87b5-225d4f503b3a",
            "state": 0
        }, {
            "name": "常平大道2号灯具_44号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "04937775-7ef9-a4f5-dac0-854e49b51ef1",
            "serial": "1fbb3c99-24f3-be89-8cd9-381a7172f5c3",
            "state": 0
        }, {
            "name": "常平大道3号灯具_0号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "cec614ff-10c1-0996-45c0-7aa20b051f5a",
            "serial": "4952132d-cc72-2bed-d0b0-1eeea58195dd",
            "state": 0
        }, {
            "name": "常平大道3号灯具_1号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "b8695191-c0a4-573f-082c-4ca0c5e6c4be",
            "serial": "a645ca26-d917-f77b-74a0-1faafef567f0",
            "state": 0
        }, {
            "name": "常平大道3号灯具_2号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e64759fe-d30e-9041-4d78-62f18f684eea",
            "serial": "21c81843-4831-8f11-3a7d-5d956fe3184c",
            "state": 2
        }, {
            "name": "常平大道3号灯具_3号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "0e98d485-3f86-1314-11f5-9462b746c4b4",
            "serial": "bd740835-5ce1-3616-2157-a5ffbee34615",
            "state": 0
        }, {
            "name": "常平大道3号灯具_4号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "f39cbcd5-719f-f476-92ee-8376c66590be",
            "serial": "3aa48486-5b52-cba1-01d0-c437e5bb8588",
            "state": 0
        }, {
            "name": "常平大道3号灯具_5号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "db7778a1-c9e5-2712-a932-0a1c8f2ad174",
            "serial": "387252aa-3d2c-9b99-0de8-853b65de426d",
            "state": 0
        }, {
            "name": "常平大道3号灯具_6号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "40c239c7-8ac2-84e9-77a1-8241363121e6",
            "serial": "f427db75-1b14-85d1-25ef-84b8b024d93a",
            "state": 0
        }, {
            "name": "常平大道3号灯具_7号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ad2506ed-9c98-6ef5-089b-3e24d8c5170d",
            "serial": "add8e48f-9bde-6e89-2735-6d4d311a981c",
            "state": 0
        }, {
            "name": "常平大道3号灯具_8号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "3a49a6e5-6622-e00a-a362-5208f0754b99",
            "serial": "d7890a30-61c3-6454-fd31-e7f5a0d487d5",
            "state": 0
        }, {
            "name": "常平大道3号灯具_9号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "7b19d34e-86fa-370b-41ff-af8f815ba6c5",
            "serial": "65f46dcf-10ed-707f-4f6b-194296eb5eec",
            "state": 0
        }, {
            "name": "常平大道3号灯具_10号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "6ef0f1aa-249b-5b8e-fca6-7956066f7201",
            "serial": "0a60dc73-45ab-b9dc-e975-0b0c8dbe8894",
            "state": 0
        }, {
            "name": "常平大道3号灯具_11号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e88665e7-dd43-56ad-2186-13f20527ac7f",
            "serial": "4fece7eb-74f4-37f5-7190-678fc6decec5",
            "state": 0
        }, {
            "name": "常平大道3号灯具_12号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "f0d2a684-fa5c-5c8a-2f21-430f615be5b0",
            "serial": "65087f60-f279-6770-751f-e2e0c413f6ec",
            "state": 0
        }, {
            "name": "常平大道3号灯具_13号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "f841a6bf-2c8c-f034-8850-31c160fcbf67",
            "serial": "038ecc9f-30bf-55c0-75ce-290d0c4d47eb",
            "state": 0
        }, {
            "name": "常平大道3号灯具_14号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "a8327ea4-2b1f-b4e0-d6bd-92ee7dad272d",
            "serial": "039b5b95-a7c8-d0b2-f707-19d791ca2b71",
            "state": 0
        }, {
            "name": "常平大道3号灯具_15号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "36c05be5-c736-cfa1-260c-cc776ee80522",
            "serial": "5bbe0ae7-cbce-3be8-7c38-0bc95bf54e2f",
            "state": 0
        }, {
            "name": "常平大道3号灯具_16号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "1b7409c5-d63b-e6e2-b738-a988b1e1e034",
            "serial": "3bf06177-977d-007a-81e5-0065c40779e4",
            "state": 0
        }, {
            "name": "常平大道3号灯具_17号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "d35676a6-624a-69e9-ecd7-f5adf76ce354",
            "serial": "766812e0-ecd3-1a57-c473-6fc54afb1df8",
            "state": 0
        }, {
            "name": "常平大道3号灯具_18号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "9932b002-ffe8-fc32-db37-18b2d8859dad",
            "serial": "0c47e4a9-cb89-9fe7-40e8-095990746d42",
            "state": 0
        }, {
            "name": "常平大道3号灯具_19号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "85e4feea-c39c-f38e-a278-13c78600d3a0",
            "serial": "1c2095c9-924c-1417-4a93-f8ca1c270bb3",
            "state": 0
        }, {
            "name": "常平大道3号灯具_20号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "a331fb87-e483-2c98-86cb-f172cc82dd60",
            "serial": "127354fc-461e-2202-6502-ca49590fc595",
            "state": 0
        }, {
            "name": "常平大道3号灯具_21号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e5d8da76-3a59-b84f-6736-2c66432ef679",
            "serial": "827fb937-8c2a-d61d-e869-32c6a1abd342",
            "state": 0
        }, {
            "name": "常平大道3号灯具_22号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "4046a393-8eb2-fc04-754c-d27e0f5c238d",
            "serial": "ae3cc471-f47a-cde7-c477-151b7e544015",
            "state": 0
        }, {
            "name": "常平大道3号灯具_23号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e3f0fe83-198c-6ccd-772d-e893990e4990",
            "serial": "a5520ebd-2d07-07e8-02fe-dadd9cac355b",
            "state": 0
        }, {
            "name": "常平大道3号灯具_24号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "b986882b-0bc2-f236-29b5-ac31dd16a4f6",
            "serial": "d777eede-9553-1bc9-da9a-37ad140b24e3",
            "state": 0
        }, {
            "name": "常平大道3号灯具_25号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ac3bbc0e-cd8a-022a-9fc8-987b934b575d",
            "serial": "a2337f1e-d599-58c2-5332-63f18a0a7cb2",
            "state": 0
        }, {
            "name": "常平大道3号灯具_26号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "40b75349-47a8-d6c9-63e8-b108eb391b7b",
            "serial": "b818741c-ea5c-7c50-b7f6-7a52922d7eeb",
            "state": 0
        }, {
            "name": "常平大道3号灯具_27号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ad9adf07-3760-b489-a2a9-71e540b87f4d",
            "serial": "d06ffaac-8c0c-b5b2-ce07-be72102c7550",
            "state": 0
        }, {
            "name": "常平大道3号灯具_28号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "bee7d1e2-86be-5464-7d38-1953a63843b3",
            "serial": "a7a2f230-ab8a-c4cc-5c02-b2868a8ef5b6",
            "state": 0
        }, {
            "name": "常平大道3号灯具_29号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "3f827147-be3d-f5c0-fd4d-eabdc637cf80",
            "serial": "d1a67923-86cd-47f4-80d2-e7955bfda998",
            "state": 0
        }, {
            "name": "常平大道3号灯具_30号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "0c3febc6-2985-ad8e-9294-80f9dc08d691",
            "serial": "60b0b3a9-1bc4-1bb8-74c1-c253c7a0a889",
            "state": 0
        }, {
            "name": "常平大道3号灯具_31号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e98e980d-ef94-633d-9fcd-bccdd76c26fd",
            "serial": "f0a30a6a-8555-520c-ff20-393852967f9f",
            "state": 0
        }, {
            "name": "常平大道3号灯具_32号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e6ccd72b-18e1-8b33-6223-16db558d157c",
            "serial": "e34c9f9b-dac2-2f23-4674-62068cc1d26f",
            "state": 0
        }, {
            "name": "常平大道3号灯具_33号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "753a0d4d-edec-8131-7622-25f0a6ca0c2f",
            "serial": "269847dc-2ec8-9a46-21b5-9703e9710071",
            "state": 0
        }, {
            "name": "常平大道3号灯具_34号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "8a784bfc-d408-ec4f-ad87-3fbaba5e84ea",
            "serial": "0be566c3-c17d-5025-35bd-f7753c208f24",
            "state": 0
        }, {
            "name": "常平大道3号灯具_35号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "1804cc6f-8d83-e6ed-fcd2-44dfe6f36f81",
            "serial": "3064cb46-6880-9a09-b9c6-196968d059b1",
            "state": 0
        }, {
            "name": "常平大道3号灯具_36号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e5eb3a76-694c-6ea6-9f55-a84101d02e23",
            "serial": "281e6150-e1a1-a27f-2415-ca146adc44cc",
            "state": 0
        }, {
            "name": "常平大道3号灯具_37号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "bd30a659-a3d8-7d3d-911c-dd3d1cb07f73",
            "serial": "4bb29d07-5bae-11bb-0326-f80527ece91f",
            "state": 0
        }, {
            "name": "常平大道3号灯具_38号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "8c3ebbc0-6613-4028-1a10-c37cc8276dca",
            "serial": "1886ca8b-a514-4ddd-b0ff-8f397d01999c",
            "state": 0
        }, {
            "name": "常平大道3号灯具_39号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "005c2630-e6bf-f98e-08cc-eb695102dfdc",
            "serial": "8a7ead96-1693-572c-e8e6-33363097b2a1",
            "state": 0
        }, {
            "name": "常平大道3号灯具_40号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "8d70659b-ced6-d4d1-d65d-0947bc6d8a60",
            "serial": "346ae678-1d73-63bb-40a0-f24b706c892a",
            "state": 0
        }, {
            "name": "常平大道3号灯具_41号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "8434cc34-69a9-6d95-5eff-d5214c1bdfd8",
            "serial": "f7384b46-733f-171f-35e0-9e4db3a0c85b",
            "state": 0
        }, {
            "name": "常平大道3号灯具_42号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "a1d30839-c43e-5140-3d24-7027cb92856b",
            "serial": "064e0f3d-7eea-c1fd-d63d-07940a2e941c",
            "state": 0
        }, {
            "name": "常平大道3号灯具_43号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "eb65f030-cf54-c4a0-98de-92c60f96a168",
            "serial": "3ca4611b-6cad-a61f-8ac0-d39d9b921011",
            "state": 0
        }, {
            "name": "常平大道3号灯具_44号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "bd0fed11-efd5-23b6-2543-5bd6782a787b",
            "serial": "16943922-39ae-42a0-6204-d41fa5d5975a",
            "state": 0
        }, {
            "name": "常平大道4号灯具_0号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "27da732a-b71f-fee8-013c-77d56f7ba1b7",
            "serial": "e289eb23-02f8-ddb5-111f-2684a4743df8",
            "state": 0
        }, {
            "name": "常平大道4号灯具_1号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "03f5ba25-00f9-61ab-8c03-ae88cda01b55",
            "serial": "7058c7ac-ca37-1a2b-5fd1-99c8249b030e",
            "state": 0
        }, {
            "name": "常平大道4号灯具_2号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "84cb55ae-08a0-0214-3bf7-1adbe7987b18",
            "serial": "5cb54af2-f728-f672-71b7-519aef5e7492",
            "state": 2
        }, {
            "name": "常平大道4号灯具_3号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "8cdaec17-1cd0-055d-86c1-ee3873192f57",
            "serial": "1970a06a-d9b8-30ea-765d-d4119b75ca25",
            "state": 0
        }, {
            "name": "常平大道4号灯具_4号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "3b69d24c-beb4-9dc3-b18e-6c040391060a",
            "serial": "23de48f5-1a37-1d53-cee2-93395336435b",
            "state": 0
        }, {
            "name": "常平大道4号灯具_5号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "cd25fbbd-4bc3-ed7f-d095-6d5080f30558",
            "serial": "33dbddab-2a6a-9378-42e0-338ffa75897e",
            "state": 0
        }, {
            "name": "常平大道4号灯具_6号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "742173f5-2a25-8be5-c43b-11d55577942a",
            "serial": "f88f862f-36ec-109d-f31a-c70082e31276",
            "state": 0
        }, {
            "name": "常平大道4号灯具_7号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "d7048685-a9da-2993-9ac3-c496cc534b55",
            "serial": "72c4959b-bf38-001e-d080-498217feffa5",
            "state": 0
        }, {
            "name": "常平大道4号灯具_8号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "c20c0865-7ceb-e4f9-6013-0a602cf5e576",
            "serial": "3d65b8ca-88a3-43fe-3064-ce0ca79796d9",
            "state": 0
        }, {
            "name": "常平大道4号灯具_9号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "8f654a98-71aa-d6f1-2aae-44024ed9b4d6",
            "serial": "2967cf28-4244-63f3-4b80-acdd32ecc4e5",
            "state": 0
        }, {
            "name": "常平大道4号灯具_10号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ef3bb039-3816-d88d-bed6-6903c67e2adb",
            "serial": "f53cf9ff-41ed-86da-0f6f-77b591be62e4",
            "state": 0
        }, {
            "name": "常平大道4号灯具_11号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "8cd5d81f-baa3-4e22-f984-0e3794025eb4",
            "serial": "9bc6e30b-4ae1-0de0-7919-fa703510465d",
            "state": 0
        }, {
            "name": "常平大道4号灯具_12号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e60a4e7d-5502-b133-35a5-26a3e61f954f",
            "serial": "2f0b593d-3539-d8e9-c667-a99d690525cf",
            "state": 0
        }, {
            "name": "常平大道4号灯具_13号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "d071ffcd-2a3d-0cb4-f512-558d10be6feb",
            "serial": "5c949811-8e7b-5381-3eea-9a8831df737f",
            "state": 0
        }, {
            "name": "常平大道4号灯具_14号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "fac250e4-f016-81a8-7cc3-32eca1db410b",
            "serial": "77b3d8db-150b-4cd7-84b7-059de365f945",
            "state": 0
        }, {
            "name": "常平大道4号灯具_15号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ab76d89b-fb46-e52d-7b45-0b05bcc7d5c1",
            "serial": "bc481811-655f-4073-fbbd-43108fcb7488",
            "state": 0
        }, {
            "name": "常平大道4号灯具_16号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "07b04eac-f4e4-46d1-ab05-19a427e9d7e6",
            "serial": "bc16f2fe-4c1e-be9e-1e76-040916f09f82",
            "state": 0
        }, {
            "name": "常平大道4号灯具_17号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "2141a259-4ab9-33cf-b0d2-a96e6a5c9443",
            "serial": "b507e0d4-0ce9-fa1d-4b16-ce577d93dc8a",
            "state": 0
        }, {
            "name": "常平大道4号灯具_18号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "d77126d8-9b69-8496-6d54-a5517eaf7d25",
            "serial": "d58fba8f-f024-387c-2812-0ad508ecd75f",
            "state": 0
        }, {
            "name": "常平大道4号灯具_19号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "c636ac40-992f-c174-3e16-8c0e787c6815",
            "serial": "6dcf5da0-fd67-51a2-8cec-449d240c53fc",
            "state": 0
        }, {
            "name": "常平大道4号灯具_20号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "6cd42380-79d5-5198-c335-ee339afb2341",
            "serial": "60ed0e2c-16ec-aa73-6b37-026c56c6082e",
            "state": 0
        }, {
            "name": "常平大道4号灯具_21号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "4d5cd3c1-1281-c771-c391-a893aa93125d",
            "serial": "39922e5c-4d66-bd75-6919-b495c12ee29c",
            "state": 0
        }, {
            "name": "常平大道4号灯具_22号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "7ccc44a7-bfeb-650e-689f-5d60a3cc0b06",
            "serial": "f10e81e2-7255-0e12-d335-2dca6f004521",
            "state": 0
        }, {
            "name": "常平大道4号灯具_23号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "2a74e010-42a1-6192-a822-d4aaaa49febd",
            "serial": "f6740729-c2c3-c53c-8bbf-b8ee255bab18",
            "state": 0
        }, {
            "name": "常平大道4号灯具_24号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "f9018e60-6318-746d-41a1-19b55f3762d3",
            "serial": "aacaefc2-7123-4f26-dd59-cf26b2844785",
            "state": 0
        }, {
            "name": "常平大道4号灯具_25号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "13f16299-5718-25b2-d976-cf26beffd6de",
            "serial": "f35db42d-da16-707a-7ad0-817f7c68a341",
            "state": 0
        }, {
            "name": "常平大道4号灯具_26号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "dcb69f31-9a02-f534-d6a1-d010e91ee308",
            "serial": "6a8ccd67-96e9-5360-baaa-6e4ba2b23fa8",
            "state": 0
        }, {
            "name": "常平大道4号灯具_27号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "7c015551-4e57-aeea-8734-43dd95670cde",
            "serial": "883658be-65a7-05fd-f629-c6b9ee406a9f",
            "state": 0
        }, {
            "name": "常平大道4号灯具_28号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "2047bae5-bdd3-40f9-6b4c-3a8867f4a406",
            "serial": "61801835-95b8-64b8-88de-703b647ecf24",
            "state": 0
        }, {
            "name": "常平大道4号灯具_29号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "6efbcf4a-afe7-2e97-48cb-3074eeaf5845",
            "serial": "685591d7-c102-1f8e-040b-707ffd0c98ff",
            "state": 0
        }, {
            "name": "常平大道4号灯具_30号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "d40c0be4-2f7f-1c74-4039-b2bdd8d0ab84",
            "serial": "87bab779-2886-4cb2-a3f9-46b31ef1267b",
            "state": 0
        }, {
            "name": "常平大道4号灯具_31号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "8a4e74af-06aa-6bb8-b618-dcb0060d8f33",
            "serial": "d1df1261-ac87-3313-c2a4-cbad6092337b",
            "state": 0
        }, {
            "name": "常平大道4号灯具_32号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "6573a38b-788b-8743-65ee-aafda805f342",
            "serial": "5365eb18-e89f-042d-fe9f-98419b37dead",
            "state": 0
        }, {
            "name": "常平大道4号灯具_33号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "9b7be6f5-e304-f710-8e1b-89ae4c74dbf9",
            "serial": "522514ad-c6e7-f877-6ce3-9cb2238a4175",
            "state": 0
        }, {
            "name": "常平大道4号灯具_34号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "e2a56a53-4c45-05de-d487-b64fb2b25cf1",
            "serial": "25d7786a-1678-e791-1303-8b1873c25792",
            "state": 0
        }, {
            "name": "常平大道4号灯具_35号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "0e3541e8-ac47-0e54-f822-f31c58473080",
            "serial": "9985faf2-48cd-ee6f-7c92-75b75dea7ffa",
            "state": 0
        }, {
            "name": "常平大道4号灯具_36号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "dd067fc1-1972-33e9-7edc-318cbdd01c41",
            "serial": "960f51b0-a0e7-e121-3c80-c007f1755290",
            "state": 0
        }, {
            "name": "常平大道4号灯具_37号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "86e846a6-62c0-694e-f343-77af7ce01597",
            "serial": "a1e21f60-faf9-5374-27a8-f2ac86db1d6a",
            "state": 0
        }, {
            "name": "常平大道4号灯具_38号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "4c52c2ba-ad0e-04a9-ebe9-33e3866f272a",
            "serial": "39d17eb8-9a8c-0762-b9bb-731a072c1d83",
            "state": 0
        }, {
            "name": "常平大道4号灯具_39号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "aa2c6f29-5992-884f-4f2d-ac306cf5fdc0",
            "serial": "c8f0755e-d9d7-55e9-c4b3-53879ba35100",
            "state": 0
        }, {
            "name": "常平大道4号灯具_40号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "71e1adb8-d044-d840-2656-743f9ecb1453",
            "serial": "b8dc7430-e4a2-aa64-8a90-68f7121da41c",
            "state": 0
        }, {
            "name": "常平大道4号灯具_41号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "35d816cd-6c39-7535-740f-3d2ccd381a73",
            "serial": "c679be23-ce42-b051-fe62-65238b2d3f26",
            "state": 0
        }, {
            "name": "常平大道4号灯具_42号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "15c356a2-c923-4a57-78df-7ebd30066e54",
            "serial": "9f4f4ba2-cfff-75ac-bdbd-3dfb34446f9e",
            "state": 0
        }, {
            "name": "常平大道4号灯具_43号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "978c7519-6cc5-3a55-9b06-f4e471ba802f",
            "serial": "f7ba37a3-b858-53cf-a330-8a059cf2abfa",
            "state": 0
        }, {
            "name": "常平大道4号灯具_44号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "0cfd52f7-82ba-dcf4-e079-b3965e55311a",
            "serial": "71992749-9293-18ee-dc3e-75df4815b8a4",
            "state": 0
        }, {
            "name": "工业三路1号灯具_0号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "2f05a265-6170-d838-b3da-1ba1584e430a",
            "serial": "ea57ed66-f1bc-73b1-121a-05d3d777febe",
            "state": 0
        }, {
            "name": "工业三路1号灯具_1号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "8825f9f1-41a6-acc8-86ce-5da6227b72ff",
            "serial": "e0f2a75d-c4f4-b8b7-c9d1-4e6854018296",
            "state": 0
        }, {
            "name": "工业三路1号灯具_2号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "8e904312-4f37-7b63-6702-bd83a3a85153",
            "serial": "0091b0b8-de4f-c221-9d1b-0342599b6632",
            "state": 2
        }, {
            "name": "工业三路1号灯具_3号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "db39460f-680f-dc12-ab9f-2275ccb3ec26",
            "serial": "458d93d4-8d46-9cc0-7026-51e057b2ad46",
            "state": 0
        }, {
            "name": "工业三路1号灯具_4号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "8b2875e4-9862-2723-d436-95bd727d331a",
            "serial": "1d0d041f-9ea4-a087-d590-9d83f1a90442",
            "state": 0
        }, {
            "name": "工业三路1号灯具_5号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "d5dd9169-f799-325a-159a-cdf9ae49d5ef",
            "serial": "f0a4b6f9-981c-a1e3-06f0-0c0d604c07c6",
            "state": 0
        }, {
            "name": "工业三路1号灯具_6号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "37ac8879-368f-1e5d-651a-c18bd98a32fb",
            "serial": "664384ce-ab80-846e-3a25-4f89a3435771",
            "state": 0
        }, {
            "name": "工业三路1号灯具_7号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ca46a409-eba3-abf9-1d3a-61fbbbf8fd55",
            "serial": "f492744b-7988-4cc5-b0ec-cd7cf27df249",
            "state": 0
        }, {
            "name": "工业三路1号灯具_8号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "84ba71fd-b15b-6dac-055b-2612aeac3061",
            "serial": "7e3fe064-d8b1-99c1-de8d-743764cf387a",
            "state": 0
        }, {
            "name": "工业三路1号灯具_9号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "293ae889-7e7f-b766-8595-f9e1d40e38f7",
            "serial": "6ffd976c-f36a-20db-07e8-75f82ab3393c",
            "state": 0
        }, {
            "name": "工业三路1号灯具_10号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "ec9f5f1a-e65f-ca94-b515-689500b3a71f",
            "serial": "75f7e17e-4303-5a2c-bb22-b0f24c555d0d",
            "state": 0
        }, {
            "name": "工业三路1号灯具_11号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "68d710d7-1ff7-d9dc-ce33-d3828635fe08",
            "serial": "50ce2555-a548-b6f2-690e-eedf730d0e11",
            "state": 0
        }, {
            "name": "工业三路1号灯具_12号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "7d7960b2-f9f7-2db7-0e37-59f8d5c81a0d",
            "serial": "472c7640-477c-9e9d-1484-8b2c7e87f499",
            "state": 0
        }, {
            "name": "工业三路1号灯具_13号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "07451f46-00ef-49c3-839a-f533f276a613",
            "serial": "1238f0ce-fbb0-1694-6fdb-33ffcdc807e8",
            "state": 0
        }, {
            "name": "工业三路1号灯具_14号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "c905045a-435c-c81d-5cd3-bf52c6171bfe",
            "serial": "eb71c958-14cf-dcfe-5173-31fbc0e90af0",
            "state": 0
        }, {
            "name": "工业三路1号灯具_15号",
            "lat": 22.9975,
            "lng": 114.022032,
            "sid": "cec1608b-cdc1-def7-cbf2-e91ef0ab3b8c",
            "serial": "cbff1d49-d797-84b1-ad20-7baa8d87ba13",
            "state": 0
        }, {
            "name": "工业三路1号灯具_16号",
            "lat": 22.942261,
            "lng": 114.02392,
            "sid": "49435dd6-f66d-50e5-13d1-8441a6b79b02",
            "serial": "e741be1f-26aa-af1d-bd33-fd87b78bea8a",
            "state": 0
        }, {
            "name": "工业三路1号灯具_17号",
            "lat": 22.943842,
            "lng": 114.021689,
            "sid": "ce3a2ee4-889a-553a-4a9a-ad8b9e9f7e52",
            "serial": "12a9b0df-6b09-1c51-16ec-37e3a8517f77",
            "state": 0
        }, {
            "name": "工业三路1号灯具_18号",
            "lat": 22.945818,
            "lng": 114.019886,
            "sid": "69edddcf-3a03-3ca1-67d8-4c85ee02e193",
            "serial": "612a835c-6126-1d36-760a-988266dcd33e",
            "state": 0
        }, {
            "name": "工业三路1号灯具_19号",
            "lat": 22.94811,
            "lng": 114.018084,
            "sid": "78d73723-f07c-96e6-17ae-70070bd7bb96",
            "serial": "23cb2f25-0a08-fdb5-7743-4a0b0827e5fc",
            "state": 0
        }, {
            "name": "工业三路1号灯具_20号",
            "lat": 22.940918,
            "lng": 114.029328,
            "sid": "9e9fc603-38d7-285c-b7d6-77534e1e6170",
            "serial": "5440a72f-279a-0c27-30f6-0b12b3c7482b",
            "state": 0
        }, {
            "name": "工业三路1号灯具_21号",
            "lat": 22.942103,
            "lng": 114.032675,
            "sid": "d201ed7a-a830-8084-dd49-273832c899d0",
            "serial": "35e41dee-60b5-e078-e32b-5c8b85ede918",
            "state": 0
        }, {
            "name": "工业三路1号灯具_22号",
            "lat": 22.942973,
            "lng": 114.034563,
            "sid": "986b8519-5fcf-b80e-0f76-78b8520872bf",
            "serial": "af7b52b1-76a4-16b2-f771-04ffd75e8df5",
            "state": 0
        }, {
            "name": "工业三路1号灯具_23号",
            "lat": 22.943526,
            "lng": 114.036709,
            "sid": "322f29a5-5a9f-75db-513f-8acebb7f3e98",
            "serial": "7a3d1be8-b3b9-863e-7608-9fbb4355b2e2",
            "state": 0
        }, {
            "name": "工业三路1号灯具_24号",
            "lat": 22.945423,
            "lng": 114.038254,
            "sid": "f1e26a2b-e430-d5a4-5c33-c678c4296e4d",
            "serial": "0a74b6a5-34d0-0449-ce31-1f9efa1ebaf1",
            "state": 0
        }, {
            "name": "工业三路1号灯具_25号",
            "lat": 22.947241,
            "lng": 114.0404,
            "sid": "3bc3d34c-b6e0-b244-00d4-83a627b91a24",
            "serial": "d488fdf0-786b-66e2-cef3-a8a052031971",
            "state": 0
        }, {
            "name": "工业三路1号灯具_26号",
            "lat": 22.948506,
            "lng": 114.042889,
            "sid": "57fd3aaf-904b-a86e-bd93-ed5d345282eb",
            "serial": "db869974-b3d0-0c2b-b011-1e0cdd3540dd",
            "state": 0
        }, {
            "name": "工业三路1号灯具_27号",
            "lat": 22.950719,
            "lng": 114.043833,
            "sid": "2921f039-2e8f-68cc-7eb7-4ce937aa9350",
            "serial": "80e2b918-7976-31b1-e46e-5204052a0b4d",
            "state": 0
        }, {
            "name": "工业三路1号灯具_28号",
            "lat": 22.952141,
            "lng": 114.045721,
            "sid": "d86c8e2d-bcf9-3638-ea5e-ffbf4b914fb6",
            "serial": "360b87bb-245b-c214-b291-3236ace9bc67",
            "state": 0
        }, {
            "name": "工业三路1号灯具_29号",
            "lat": 22.957516,
            "lng": 114.046665,
            "sid": "e9952d51-82ab-a2bb-ddfb-8c9cf74a7e14",
            "serial": "cb57f93d-7135-ccd0-7c9f-3afb8c70c378",
            "state": 0
        }, {
            "name": "工业三路1号灯具_30号",
            "lat": 22.947873,
            "lng": 114.016024,
            "sid": "cf1f27b7-f212-9a0f-5727-b5daffcc3c64",
            "serial": "81743d51-cbfe-a71b-f2f6-86397086c7b7",
            "state": 0
        }, {
            "name": "工业三路1号灯具_31号",
            "lat": 22.946609,
            "lng": 114.012676,
            "sid": "cc2680b8-b0ae-b4ee-a539-c9d56f0053df",
            "serial": "d42bfb5d-b647-25cd-59fe-87a6264bce2f",
            "state": 0
        }, {
            "name": "工业三路1号灯具_32号",
            "lat": 22.947241,
            "lng": 114.010273,
            "sid": "092e95c0-dbd1-bd41-62c9-5a7b3deb6602",
            "serial": "c8b06c38-de11-4e35-edff-4d5df64afcc6",
            "state": 0
        }, {
            "name": "工业三路1号灯具_33号",
            "lat": 22.949612,
            "lng": 114.009243,
            "sid": "1176b821-862f-ffd3-b602-7db3201809a9",
            "serial": "957ef2a4-e1ed-20f3-274b-6e2a51afa468",
            "state": 0
        }, {
            "name": "工业三路1号灯具_34号",
            "lat": 22.951746,
            "lng": 114.008127,
            "sid": "6c8b40fd-6ea2-ca07-f0b2-1a51cfef5fe0",
            "serial": "c52a7581-c3b3-05e4-73af-db7762fa5d48",
            "state": 0
        }, {
            "name": "工业三路1号灯具_35号",
            "lat": 22.954196,
            "lng": 114.00787,
            "sid": "7f817c94-4f7b-c17a-8412-cb591a3abd36",
            "serial": "ca58facd-c454-a5a6-8a71-3412ec422f22",
            "state": 0
        }, {
            "name": "工业三路1号灯具_36号",
            "lat": 22.956014,
            "lng": 114.007269,
            "sid": "11b4a5ba-714c-c198-1649-2575c7cc1ca8",
            "serial": "af6bfb22-96dd-bafc-8274-0bc3f46acd9e",
            "state": 0
        }, {
            "name": "工业三路1号灯具_37号",
            "lat": 22.958306,
            "lng": 114.006926,
            "sid": "5415a533-a671-d11c-60a7-687323902531",
            "serial": "c5c1ddcb-d606-a8e7-9be4-9711f7b18aca",
            "state": 0
        }, {
            "name": "工业三路1号灯具_38号",
            "lat": 22.962178,
            "lng": 114.006497,
            "sid": "6010edb5-fd7e-335a-2d40-276d1e87e9b6",
            "serial": "db858cad-1723-14ae-f828-7be3e57893c6",
            "state": 0
        }, {
            "name": "工业三路1号灯具_39号",
            "lat": 22.963917,
            "lng": 114.005553,
            "sid": "e04fb778-cd94-1c5d-a9d2-a540cfd47bf7",
            "serial": "854cd29c-f591-c5a4-d258-d47c23718807",
            "state": 0
        }, {
            "name": "工业三路1号灯具_40号",
            "lat": 22.965577,
            "lng": 114.004179,
            "sid": "08e7c63c-6ac8-357f-e803-55d48e4009ad",
            "serial": "c2f7ddaf-6d07-5784-8457-374b4128b6e6",
            "state": 0
        }, {
            "name": "工业三路1号灯具_41号",
            "lat": 22.967473,
            "lng": 114.002806,
            "sid": "347bda8b-e556-80c7-75b5-8f73f71590ba",
            "serial": "ea893e2f-db62-e626-067d-f1642cf9ad46",
            "state": 0
        }, {
            "name": "工业三路1号灯具_42号",
            "lat": 22.970555,
            "lng": 114.001175,
            "sid": "7afeb967-e770-495b-8009-35d7aae5f1d2",
            "serial": "4bacc187-f0de-c86a-c1fc-7bc3d0279d1a",
            "state": 0
        }, {
            "name": "工业三路1号灯具_43号",
            "lat": 23.002003,
            "lng": 114.031731,
            "sid": "38b87f30-a0e5-d683-39cd-94f369a79ede",
            "serial": "3edd430c-64a4-9eaa-2425-ed58f8a54e30",
            "state": 0
        }, {
            "name": "工业三路1号灯具_44号",
            "lat": 23.0039,
            "lng": 114.033447,
            "sid": "f41db58e-8496-7faf-4f6d-03be870ed8ef",
            "serial": "8e8804b6-e431-8394-5d02-2cb8056d93cb",
            "state": 0
        }, {
            "name": "工业三路1号灯具_0号",
            "lat": 23.005796,
            "lng": 114.035164,
            "sid": "2760313a-f886-3551-c3b1-50d44c458b04",
            "serial": "52546a30-be23-3fea-e658-1f0f0a9a2dc9",
            "state": 0
        }, {
            "name": "工业三路1号灯具_1号",
            "lat": 23.006507,
            "lng": 114.036194,
            "sid": "30358da0-ea3a-686b-9479-810dd6ee3deb",
            "serial": "32137bda-b98b-1cbc-8003-9dc5cee007cd",
            "state": 0
        }, {
            "name": "工业三路1号灯具_2号",
            "lat": 23.006665,
            "lng": 114.037567,
            "sid": "dfa9644e-a857-4507-2b43-cddab595cb04",
            "serial": "41635152-665f-bb57-078e-9e2870999062",
            "state": 2
        }, {
            "name": "工业三路1号灯具_3号",
            "lat": 23.006744,
            "lng": 114.040142,
            "sid": "278730ff-9331-3573-5b7f-037e5c7b9e47",
            "serial": "65fb10e4-1c59-9aac-30ca-a464fee98a7e",
            "state": 0
        }, {
            "name": "工业三路1号灯具_4号",
            "lat": 23.007297,
            "lng": 114.042202,
            "sid": "a2dfc75d-319f-2fa6-9efa-96dd2d0256e3",
            "serial": "bbe224ef-4eac-8594-9dd0-2c128e6a19bd",
            "state": 0
        }, {
            "name": "工业三路1号灯具_5号",
            "lat": 23.007534,
            "lng": 114.043833,
            "sid": "5e3de134-c0fc-9742-476b-ac454096cebd",
            "serial": "d6081cdd-a6aa-c96e-cb01-d8f039b8ff3d",
            "state": 0
        }, {
            "name": "工业三路1号灯具_6号",
            "lat": 23.007455,
            "lng": 114.046494,
            "sid": "009b686e-71c5-5557-c85e-56ae7d40c595",
            "serial": "74772e66-eaf3-2bb7-31e6-a5ca561fa05a",
            "state": 0
        }, {
            "name": "工业三路1号灯具_7号",
            "lat": 23.007771,
            "lng": 114.048125,
            "sid": "319af83c-2be7-9e89-20bc-a96ea33ee1b8",
            "serial": "61fe69c5-0c39-55c7-7ea6-253977a5b6e8",
            "state": 0
        }, {
            "name": "工业三路1号灯具_8号",
            "lat": 23.008245,
            "lng": 114.050356,
            "sid": "e9233640-e4ea-09d2-8bfd-e21a5134f732",
            "serial": "82d98acb-c7c4-858a-877e-2348accf2c6b",
            "state": 0
        }, {
            "name": "工业三路1号灯具_9号",
            "lat": 23.008324,
            "lng": 114.05233,
            "sid": "c9c27c6f-00e0-dee3-638c-6ed508c10d7e",
            "serial": "422e693a-2882-3444-ec81-af2d87e6f51f",
            "state": 0
        }, {
            "name": "工业三路1号灯具_10号",
            "lat": 23.00785,
            "lng": 114.055163,
            "sid": "149da197-065c-b9c9-7641-caaf7c1608f6",
            "serial": "52ecad27-e929-5497-b715-ddeff75863ca",
            "state": 0
        }, {
            "name": "工业三路1号灯具_11号",
            "lat": 23.007929,
            "lng": 114.057051,
            "sid": "26c3a29e-687f-5ff3-c17e-47a74de74c0b",
            "serial": "18caea26-c2a9-a231-7168-7f73b7d02c10",
            "state": 0
        }, {
            "name": "工业三路1号灯具_12号",
            "lat": 23.00785,
            "lng": 114.058939,
            "sid": "d1c72eaf-2648-dffc-cbb2-807bb7b21240",
            "serial": "a35d895a-baa9-1905-86f6-10f741027384",
            "state": 0
        }, {
            "name": "工业三路1号灯具_13号",
            "lat": 23.006823,
            "lng": 114.0616,
            "sid": "ebf3e269-91cc-4ab4-fb67-1cb5bd2e76db",
            "serial": "3b3a37b1-1b3e-4104-5ac7-c75f8d31edee",
            "state": 0
        }, {
            "name": "工业三路1号灯具_14号",
            "lat": 23.006112,
            "lng": 114.062973,
            "sid": "6e98c327-319d-5d08-4ca0-9329590f79a5",
            "serial": "7fe0608e-1ce0-a52d-6438-35803543f341",
            "state": 0
        }, {
            "name": "工业三路1号灯具_15号",
            "lat": 23.005401,
            "lng": 114.065462,
            "sid": "36c6fc77-af57-1e14-a0f3-7745836fd8f1",
            "serial": "c73d25c3-7700-6393-6cd1-9cd6158c6452",
            "state": 0
        }, {
            "name": "工业三路1号灯具_16号",
            "lat": 23.004374,
            "lng": 114.066921,
            "sid": "5bfc097f-db72-ac36-c545-a7384204d816",
            "serial": "d59d4d67-024a-f1b6-d9ba-52ed736b6d14",
            "state": 0
        }, {
            "name": "工业三路1号灯具_17号",
            "lat": 23.003742,
            "lng": 114.068295,
            "sid": "665f5480-d860-6f09-a9f5-61eab49d25b6",
            "serial": "7c117649-b34a-b9fd-b0ca-71170e1dde7f",
            "state": 0
        }, {
            "name": "工业三路1号灯具_18号",
            "lat": 23.001529,
            "lng": 114.069153,
            "sid": "66b12ee8-59ce-b9b7-093e-c8f00a8a173f",
            "serial": "8123790c-ca0a-07c0-e312-1f73d7d7b960",
            "state": 0
        }, {
            "name": "工业三路1号灯具_19号",
            "lat": 22.999159,
            "lng": 114.069668,
            "sid": "342b5afe-f9bb-da83-9d9c-f896b9c79437",
            "serial": "9a3916e3-4e32-847f-f1cc-e93a45db5f74",
            "state": 0
        }, {
            "name": "工业三路1号灯具_20号",
            "lat": 22.995683,
            "lng": 114.070269,
            "sid": "92d1dbc3-c8d6-027d-100a-d513831cf744",
            "serial": "67fbb0f3-2052-e9d3-30bf-bf5495c796bc",
            "state": 0
        }, {
            "name": "工业三路1号灯具_21号",
            "lat": 22.992917,
            "lng": 114.070955,
            "sid": "4b900cb2-8564-b345-24e3-fcc688e45495",
            "serial": "8c2de749-4608-abd6-bbfb-be59a1387cba",
            "state": 0
        }, {
            "name": "工业三路1号灯具_22号",
            "lat": 22.990468,
            "lng": 114.071041,
            "sid": "5fbd0610-497e-33a5-fb04-574b1254aad0",
            "serial": "01446bea-9898-556c-aef8-e62dab4b38f2",
            "state": 0
        }, {
            "name": "工业三路1号灯具_23号",
            "lat": 22.987782,
            "lng": 114.071642,
            "sid": "e7b4de84-8cfc-45cd-c077-f5077253bcca",
            "serial": "1999c625-ccee-f7f0-9f7f-6cba706e9505",
            "state": 0
        }, {
            "name": "工业三路1号灯具_24号",
            "lat": 22.984305,
            "lng": 114.071642,
            "sid": "7c19621f-3acc-4225-fcb9-78fb22fe89bf",
            "serial": "f4a10fa7-ef5a-1f7b-f064-acf0cbae9242",
            "state": 0
        }, {
            "name": "工业三路1号灯具_25号",
            "lat": 22.981302,
            "lng": 114.071299,
            "sid": "49b85d81-f639-9d6b-968d-a1d0bc95060a",
            "serial": "1d8c9757-c187-0f94-0387-cbcb75831803",
            "state": 0
        }, {
            "name": "工业三路1号灯具_26号",
            "lat": 22.977983,
            "lng": 114.072415,
            "sid": "61424e27-f8e8-2228-dcb7-dcb9cf55477b",
            "serial": "ae530d03-8148-6854-6dbf-322b9b6a68a0",
            "state": 0
        }, {
            "name": "工业三路1号灯具_27号",
            "lat": 22.963759,
            "lng": 114.062201,
            "sid": "92904c74-77f1-66de-e3f7-46945d771b0c",
            "serial": "870e42dc-ff26-3e60-8566-27807e69846b",
            "state": 0
        }, {
            "name": "工业三路1号灯具_28号",
            "lat": 22.966683,
            "lng": 114.062887,
            "sid": "03c40fa9-6f09-27b7-ff25-b83c0d73d0a4",
            "serial": "2a85efbe-784c-8156-9e15-948e39f0c916",
            "state": 0
        }, {
            "name": "工业三路1号灯具_29号",
            "lat": 22.968105,
            "lng": 114.064432,
            "sid": "687d1aed-eab6-98ab-9cf1-43ec9d3493bc",
            "serial": "f3898602-52ef-b74a-6edf-96b62c606501",
            "state": 0
        }, {
            "name": "工业三路1号灯具_30号",
            "lat": 22.970713,
            "lng": 114.064776,
            "sid": "356b2dda-bb62-307e-5b51-5753fc928da3",
            "serial": "178ab69d-6dca-506d-5021-32722ae4158c",
            "state": 0
        }, {
            "name": "工业三路1号灯具_31号",
            "lat": 22.972294,
            "lng": 114.066235,
            "sid": "ae46da1e-69b6-ceea-976e-3d1918372e02",
            "serial": "5d0a30d1-70d2-7456-2f26-e43d02b197ce",
            "state": 0
        }, {
            "name": "工业三路1号灯具_32号",
            "lat": 22.973321,
            "lng": 114.066921,
            "sid": "bbe80125-1ee6-1721-8124-242ab3abafb5",
            "serial": "0ae87daa-76c2-73bc-5053-e8dc9229086b",
            "state": 0
        }, {
            "name": "工业三路1号灯具_33号",
            "lat": 22.974111,
            "lng": 114.068638,
            "sid": "9bf78312-96ad-e984-8cea-91d4a39f8044",
            "serial": "f3b16ac4-fdc3-81b9-30d2-28c3bbcb78c3",
            "state": 0
        }, {
            "name": "工业三路1号灯具_34号",
            "lat": 22.975771,
            "lng": 114.070269,
            "sid": "e1d3fa4c-71b7-9518-4929-9a4413078ef0",
            "serial": "928ab5ee-9b84-aec6-c770-d11932c8da82",
            "state": 0
        }, {
            "name": "工业三路1号灯具_35号",
            "lat": 22.978062,
            "lng": 114.069582,
            "sid": "fffbcd24-d3ab-e0c8-1416-503611bf34d6",
            "serial": "8edd2782-547b-f2b1-d58e-1b2b4550980f",
            "state": 0
        }, {
            "name": "工业三路1号灯具_36号",
            "lat": 22.980038,
            "lng": 114.06778,
            "sid": "fbd15963-02f2-d9ac-4a44-e5957a5cd121",
            "serial": "c7acb27c-2c75-55bf-93f5-e5645f758a25",
            "state": 0
        }, {
            "name": "工业三路1号灯具_37号",
            "lat": 22.981934,
            "lng": 114.066235,
            "sid": "16d3f19a-1ec6-3f2e-052e-33e615973341",
            "serial": "c1d1756f-e5af-b6fc-6aff-1aae26303569",
            "state": 0
        }, {
            "name": "工业三路1号灯具_38号",
            "lat": 22.981776,
            "lng": 114.063574,
            "sid": "41bad4df-b66b-bc66-30c1-c34b47411f4c",
            "serial": "24827c04-9e66-b52f-c56e-7cbdb8f593e8",
            "state": 0
        }, {
            "name": "工业三路1号灯具_39号",
            "lat": 22.982487,
            "lng": 114.056278,
            "sid": "fe251039-d552-a6ca-af27-c762367578cc",
            "serial": "6860e75e-a768-ba42-5876-7ceea4389033",
            "state": 0
        }, {
            "name": "工业三路1号灯具_40号",
            "lat": 22.982487,
            "lng": 114.053875,
            "sid": "76318bd8-5458-8fe1-d60b-4f9ccf86d0f7",
            "serial": "8ebabcb8-3951-1e9c-68e4-2c9055e45f0d",
            "state": 0
        }, {
            "name": "工业三路1号灯具_41号",
            "lat": 22.958069,
            "lng": 113.946415,
            "sid": "c5edb4ae-2db9-96cc-a1c6-5eef4020d6aa",
            "serial": "41fff70a-c8ee-7cd1-67d2-a3749ab2757a",
            "state": 0
        }, {
            "name": "工业三路1号灯具_42号",
            "lat": 22.957595,
            "lng": 113.948303,
            "sid": "11ee9048-e63a-6dee-374c-bb97527d65c5",
            "serial": "8d9b5f14-c165-09c6-27e3-291f4574442e",
            "state": 0
        }, {
            "name": "工业三路1号灯具_43号",
            "lat": 22.959017,
            "lng": 113.951308,
            "sid": "0a9bde5b-c370-9207-f18c-f9579a84369c",
            "serial": "57f4ac94-4278-061e-b4f2-a5ca7b88f87f",
            "state": 0
        }, {
            "name": "工业三路1号灯具_44号",
            "lat": 22.959017,
            "lng": 113.953453,
            "sid": "2c2fd6f0-6d9b-7b1c-265e-ac23ec904d45",
            "serial": "839f9748-39a1-ad40-ad49-9618a62058c2",
            "state": 0
        }, {
            "name": "工业三路2号灯具_0号",
            "lat": 22.959886,
            "lng": 113.955857,
            "sid": "be76a25c-891b-b173-1893-4c6d9e70625e",
            "serial": "612f2db4-7733-cf9b-fff2-3ac5bdacb040",
            "state": 0
        }, {
            "name": "工业三路2号灯具_1号",
            "lat": 22.959886,
            "lng": 113.957401,
            "sid": "c1235f7d-366c-3a3a-df57-2559aab34330",
            "serial": "2c170323-a990-4755-74a2-780dc4f9b444",
            "state": 0
        }, {
            "name": "工业三路2号灯具_2号",
            "lat": 22.960282,
            "lng": 113.95929,
            "sid": "bc36e149-88b7-6a26-d1bd-58eec8b9059b",
            "serial": "c358b1f7-d470-9dd3-4598-47aa18de1cd4",
            "state": 2
        }, {
            "name": "工业三路2号灯具_3号",
            "lat": 22.95957,
            "lng": 113.962208,
            "sid": "3b7a173b-c363-a556-59de-5fbd6e1da315",
            "serial": "62654d73-664c-6f2e-eea8-e65f75f3868b",
            "state": 0
        }, {
            "name": "工业三路2号灯具_4号",
            "lat": 22.959807,
            "lng": 113.964525,
            "sid": "b72e9ff9-6cb6-4079-5ff3-f1fa301fe9a3",
            "serial": "d3407151-fcd8-26a8-ca06-2627444e221e",
            "state": 0
        }, {
            "name": "工业三路2号灯具_5号",
            "lat": 22.959728,
            "lng": 113.967015,
            "sid": "e4ceda84-ac3b-2b25-fd88-e0c9771486d7",
            "serial": "e9d244e9-353a-f864-9ddc-9c39a4653363",
            "state": 0
        }, {
            "name": "工业三路2号灯具_6号",
            "lat": 22.95957,
            "lng": 113.968817,
            "sid": "862c6353-0bc8-0130-deaf-8de7dd525717",
            "serial": "f6381293-dca6-f220-06d8-b40ddb6a5b31",
            "state": 0
        }, {
            "name": "工业三路2号灯具_7号",
            "lat": 22.960045,
            "lng": 113.971049,
            "sid": "41896454-fd02-7461-0446-e67316ec4683",
            "serial": "c369951f-d8f0-fc62-533c-4b82c79cf8b1",
            "state": 0
        }, {
            "name": "工业三路2号灯具_8号",
            "lat": 22.960045,
            "lng": 113.973452,
            "sid": "3fd8683d-e353-237c-48f0-8ac5bed22925",
            "serial": "a7b544cc-385e-5c65-46b2-68c7e9669e77",
            "state": 0
        }, {
            "name": "工业三路2号灯具_9号",
            "lat": 22.960598,
            "lng": 113.974653,
            "sid": "1fcbb276-1f5e-b245-7892-f05b93d14b35",
            "serial": "33f67860-986d-0f65-5773-f341e935213a",
            "state": 0
        }, {
            "name": "工业三路2号灯具_10号",
            "lat": 22.960361,
            "lng": 113.976198,
            "sid": "e0333d01-63a0-f748-b9eb-a11c4b6ff6b4",
            "serial": "1635d570-dc03-a62c-f62f-74abf0a63e9b",
            "state": 0
        }, {
            "name": "工业三路2号灯具_11号",
            "lat": 22.96044,
            "lng": 113.981005,
            "sid": "1bed2bf6-769d-5bf7-fd4c-64c53049f662",
            "serial": "b276696c-a61d-c74f-d406-e3ec2cef3b4c",
            "state": 0
        }, {
            "name": "工业三路2号灯具_12号",
            "lat": 22.960282,
            "lng": 113.977915,
            "sid": "bf04a0db-ec66-4bee-c490-c34e0486b274",
            "serial": "cb4ede06-062e-2ee4-6f7a-144d4527c173",
            "state": 0
        }, {
            "name": "工业三路2号灯具_13号",
            "lat": 22.978695,
            "lng": 114.000574,
            "sid": "6d293655-45de-3441-31e4-4955925b6c69",
            "serial": "c0c43e83-7b18-559a-c1fd-59d5f602901a",
            "state": 0
        }, {
            "name": "工业三路2号灯具_14号",
            "lat": 22.97743,
            "lng": 113.998085,
            "sid": "bd115629-86cc-4f22-58ed-41ecfaf39b56",
            "serial": "da75300f-84d3-26a3-fdbc-a2681d3661fc",
            "state": 0
        }, {
            "name": "工业三路2号灯具_15号",
            "lat": 22.979406,
            "lng": 113.996454,
            "sid": "4ea6da09-c433-bd3c-7dcf-e4956a1115b8",
            "serial": "337716ab-86f8-5eef-f379-3de556769ffa",
            "state": 0
        }, {
            "name": "工业三路2号灯具_16号",
            "lat": 22.981934,
            "lng": 113.995596,
            "sid": "2289fdff-9de5-b76d-c92f-a73d311bee1f",
            "serial": "e9fb173b-497b-58bc-0695-6db418a16fcf",
            "state": 0
        }, {
            "name": "工业三路2号灯具_17号",
            "lat": 22.98391,
            "lng": 113.994309,
            "sid": "8a1182ba-68e5-605e-c164-81c6a1e88b09",
            "serial": "06fb5480-dd5f-5c03-98ea-020de26805b3",
            "state": 0
        }, {
            "name": "工业三路2号灯具_18号",
            "lat": 22.986359,
            "lng": 113.994652,
            "sid": "4fab687e-7356-2f8a-41ea-69d9c33950b5",
            "serial": "849fe540-fc7a-32a7-70ed-ffff147f8a07",
            "state": 0
        }, {
            "name": "工业三路2号灯具_19号",
            "lat": 22.98952,
            "lng": 113.993622,
            "sid": "5a96ab75-3beb-0953-0a61-c40a95a83d6a",
            "serial": "f6f00a7f-bb5b-d6b1-928e-956f4bf35780",
            "state": 0
        }, {
            "name": "工业三路2号灯具_20号",
            "lat": 22.990942,
            "lng": 113.995854,
            "sid": "d3648ae6-c57f-ce0c-a181-d6c9f7fee507",
            "serial": "465d5404-893d-7d52-57d4-752e132ed666",
            "state": 0
        }, {
            "name": "工业三路2号灯具_21号",
            "lat": 22.992127,
            "lng": 113.998343,
            "sid": "9356aed1-f695-3269-41a5-cfd53a15d346",
            "serial": "e0aa2bae-0a6d-8cf2-c1e3-7a6686db2328",
            "state": 0
        }, {
            "name": "工业三路2号灯具_22号",
            "lat": 22.992917,
            "lng": 114.002119,
            "sid": "e431196d-5e0d-a4eb-6775-24cb80286761",
            "serial": "3b829db5-db5c-f00a-beda-33c7e5b48997",
            "state": 0
        }, {
            "name": "工业三路2号灯具_23号",
            "lat": 22.995051,
            "lng": 114.001776,
            "sid": "c8bf3824-2985-8e77-65e2-db37a76dc792",
            "serial": "d826e00b-dd48-b902-aaae-09069bf96086",
            "state": 0
        }, {
            "name": "工业三路2号灯具_24号",
            "lat": 22.997026,
            "lng": 114.002291,
            "sid": "8a673a39-f87a-6756-f6d1-018cefd428a6",
            "serial": "723da550-420e-2081-79cc-c71a1520ac88",
            "state": 0
        }, {
            "name": "工业三路2号灯具_25号",
            "lat": 22.999554,
            "lng": 114.002119,
            "sid": "64a8fc11-1713-23ab-937e-fb04c305c17f",
            "serial": "895faa2d-0c46-c6de-65a2-11d1d88b42ac",
            "state": 0
        }, {
            "name": "工业三路2号灯具_26号",
            "lat": 23.001687,
            "lng": 114.002634,
            "sid": "ff898beb-c326-bb32-1e26-1c601a24054e",
            "serial": "3256d83e-e81f-52f6-b14b-9021ed2a3c4c",
            "state": 0
        }, {
            "name": "工业三路2号灯具_27号",
            "lat": 23.003426,
            "lng": 114.005209,
            "sid": "6c37644e-90d3-6c48-4a67-74fd006a0368",
            "serial": "5b9f934c-a099-e5fe-1c1b-6d408adf6018",
            "state": 0
        }, {
            "name": "工业三路2号灯具_28号",
            "lat": 22.96044,
            "lng": 113.981262,
            "sid": "21da0fa4-6bbe-d6a4-6e8b-a4a64dd07a67",
            "serial": "43a65358-e3fe-edbb-01c9-1c1aa049b6fa",
            "state": 0
        }, {
            "name": "工业三路2号灯具_29号",
            "lat": 22.960914,
            "lng": 113.983237,
            "sid": "64807f96-dd67-ac2b-ee3e-37dd221bc6a1",
            "serial": "6fe982e3-1cea-daff-7763-162e44042fd2",
            "state": 0
        }, {
            "name": "工业三路2号灯具_30号",
            "lat": 22.961862,
            "lng": 113.985296,
            "sid": "8dcc2a2f-2e6d-058d-e095-6e83c0d9cb18",
            "serial": "ffff24d0-025b-de08-97d6-b462c32c6d8e",
            "state": 0
        }, {
            "name": "工业三路2号灯具_31号",
            "lat": 22.963048,
            "lng": 113.986756,
            "sid": "e37524b0-3615-b9a9-2dcc-f9a0b2c7a090",
            "serial": "37a1d6fc-b466-ffaa-8fce-d1ebf9e3761d",
            "state": 0
        }, {
            "name": "工业三路2号灯具_32号",
            "lat": 22.964075,
            "lng": 113.988558,
            "sid": "c1909d4b-c3da-1848-1cfb-b504a90b3734",
            "serial": "794ad2a1-af4b-35e7-16cb-33d3470428cf",
            "state": 0
        }, {
            "name": "工业三路2号灯具_33号",
            "lat": 22.964786,
            "lng": 113.989502,
            "sid": "0548dc7c-b1d7-77c1-1038-57bf8160b6a4",
            "serial": "8b94efd7-90bc-d4b8-cba8-c4484e7273d3",
            "state": 0
        }, {
            "name": "工业三路2号灯具_34号",
            "lat": 22.966288,
            "lng": 113.990446,
            "sid": "036fce6d-8c45-f4f4-578b-b0dbf6f2e3bc",
            "serial": "52dc576f-774a-0d44-113b-7c500d6c697a",
            "state": 0
        }, {
            "name": "工业三路2号灯具_35号",
            "lat": 22.967394,
            "lng": 113.992249,
            "sid": "ff3b6e45-e915-8928-c77c-a1d30ac109c1",
            "serial": "197c3a9b-f2c5-418e-15d9-a61ff19796d5",
            "state": 0
        }, {
            "name": "工业三路2号灯具_36号",
            "lat": 22.969291,
            "lng": 113.993965,
            "sid": "a01e3d88-6962-b16a-cc88-749e2c66bc5e",
            "serial": "bd9ce409-743b-9533-68d0-95aec2bfe301",
            "state": 0
        }, {
            "name": "工业三路2号灯具_37号",
            "lat": 22.969844,
            "lng": 113.995424,
            "sid": "9e687da1-74e7-225d-1c83-6097a59b098e",
            "serial": "b04bef62-2e03-4e0c-7019-f307c37d47cb",
            "state": 0
        }, {
            "name": "工业三路2号灯具_38号",
            "lat": 22.971662,
            "lng": 113.997399,
            "sid": "56315fda-3bc5-6941-7e6f-78c2bf5a8ab7",
            "serial": "9c504c39-5a16-39e1-69c3-c025b44b96e5",
            "state": 0
        }, {
            "name": "工业三路2号灯具_39号",
            "lat": 22.972847,
            "lng": 113.999373,
            "sid": "9dbfb970-fa9b-cc3a-a242-58a944a76fb1",
            "serial": "f32f3590-70da-9dc9-2e40-7b90fb35e40d",
            "state": 0
        }, {
            "name": "工业三路2号灯具_40号",
            "lat": 22.974823,
            "lng": 114.001347,
            "sid": "77cde341-c6b7-c0b6-8456-09d9b1d39a6c",
            "serial": "43cf0321-0f10-55c7-98e9-62a33d02c67b",
            "state": 0
        }, {
            "name": "工业三路2号灯具_41号",
            "lat": 22.976956,
            "lng": 114.003321,
            "sid": "cf488b54-eebb-e121-ff3e-9fcace241df1",
            "serial": "0bd2d906-b804-136e-128c-ecde9aaf25c8",
            "state": 0
        }, {
            "name": "工业三路2号灯具_42号",
            "lat": 22.978616,
            "lng": 114.00684,
            "sid": "675ee194-6d54-145d-43bd-d20653e61bc0",
            "serial": "4fa46004-c0ce-9e83-71c9-73909c416d32",
            "state": 0
        }, {
            "name": "工业三路2号灯具_43号",
            "lat": 22.980986,
            "lng": 114.009072,
            "sid": "f6751b55-565d-c1b1-eab3-3cd38a755683",
            "serial": "8d15146e-3009-a3b3-e28b-7aee4c895fb5",
            "state": 0
        }, {
            "name": "工业三路2号灯具_44号",
            "lat": 22.984226,
            "lng": 114.013449,
            "sid": "c5f537ef-e311-335f-aa7d-2011a0dda296",
            "serial": "b73df929-d4b2-11ba-6dda-dc4fce5ff115",
            "state": 0
        }, {
            "name": "工业三路3号灯具_0号",
            "lat": 22.984621,
            "lng": 114.016196,
            "sid": "8fac64c9-6564-dbed-5249-0845541cca40",
            "serial": "a8454773-9286-b717-920c-a42d107dee6c",
            "state": 0
        }, {
            "name": "工业三路3号灯具_1号",
            "lat": 22.984779,
            "lng": 114.019543,
            "sid": "f2684b81-fafb-7976-7f2f-6fd52125083f",
            "serial": "c81d3f93-a5b8-a048-b304-aada6ce45256",
            "state": 0
        }, {
            "name": "工业三路3号灯具_2号",
            "lat": 22.984068,
            "lng": 114.022804,
            "sid": "816cef50-15b4-6a73-7ddf-2b7dd740205a",
            "serial": "11ae222f-e2e1-db1d-b17e-4aea7cd7a17c",
            "state": 2
        }, {
            "name": "工业三路3号灯具_3号",
            "lat": 22.983989,
            "lng": 114.025379,
            "sid": "895dc075-a49f-cc0b-96cd-10ff44bb0c03",
            "serial": "1c5879b6-2a97-bfcd-8c2e-673dc82c93b1",
            "state": 0
        }, {
            "name": "工业三路3号灯具_4号",
            "lat": 22.98312,
            "lng": 114.027697,
            "sid": "8451dc70-02ec-43a5-0cfa-cd6c16afb0d5",
            "serial": "e21364e7-e433-c72c-4700-e352f9d33abb",
            "state": 0
        }, {
            "name": "工业三路3号灯具_5号",
            "lat": 22.982566,
            "lng": 114.0301,
            "sid": "61a48eb2-1428-7536-6303-801b0201308b",
            "serial": "163125e7-aea6-7120-7e9f-d3def9f69b7b",
            "state": 0
        }, {
            "name": "工业三路3号灯具_6号",
            "lat": 22.982645,
            "lng": 114.032418,
            "sid": "eb70a1c6-ff5e-b82c-2bbd-83bd6a8b0c3f",
            "serial": "8250518d-69f0-fcee-ee2b-880803792fb2",
            "state": 0
        }, {
            "name": "工业三路3号灯具_7号",
            "lat": 22.982645,
            "lng": 114.034477,
            "sid": "ee26d54d-b540-539f-fcfe-33353c45d00f",
            "serial": "bcc61b96-2dfc-b371-ae1e-1cb4c611424f",
            "state": 0
        }, {
            "name": "工业三路3号灯具_8号",
            "lat": 22.984068,
            "lng": 114.035851,
            "sid": "7d9f5980-8f39-ae42-eada-59e7a017622f",
            "serial": "23c7db3a-973a-a939-ce83-ad1f20fbe52b",
            "state": 0
        }, {
            "name": "工业三路3号灯具_9号",
            "lat": 22.954117,
            "lng": 114.046322,
            "sid": "0f5f5fcc-9182-233f-5e78-eea35f904f83",
            "serial": "73169bcb-00c3-caa6-7a2a-986528ac3818",
            "state": 0
        }, {
            "name": "工业三路3号灯具_10号",
            "lat": 22.982329,
            "lng": 114.058338,
            "sid": "7a8dc0a4-ab30-fb15-984f-64d40ca10de6",
            "serial": "0dde974a-2b34-be6c-94cb-34849fbc9a06",
            "state": 0
        }, {
            "name": "工业三路3号灯具_11号",
            "lat": 22.982725,
            "lng": 114.011389,
            "sid": "24db9c22-8548-d01c-4869-71cb1a986901",
            "serial": "816388fc-8725-3677-eba6-7d89df911a33",
            "state": 0
        }, {
            "name": "工业三路3号灯具_12号",
            "lat": 23.001766,
            "lng": 114.024864,
            "sid": "406a390e-3b5f-978e-c757-03fcf16d7167",
            "serial": "21f50c92-014f-83b2-2c39-1b8a26888838",
            "state": 0
        }, {
            "name": "工业三路3号灯具_13号",
            "lat": 22.983831,
            "lng": 114.039541,
            "sid": "7bd6d3fd-3afe-26e3-c612-97da5afd57c0",
            "serial": "69e0ba3c-8b71-ccd3-2a1e-f09c43b5e3ee",
            "state": 0
        }, {
            "name": "工业三路3号灯具_14号",
            "lat": 22.983278,
            "lng": 114.042803,
            "sid": "000dbdca-d6ae-2500-335b-d99670a2de17",
            "serial": "434765ca-e2c4-e818-9bc8-caece67727a6",
            "state": 0
        }, {
            "name": "工业三路3号灯具_15号",
            "lat": 22.963838,
            "lng": 114.059969,
            "sid": "22a09efb-8686-8756-3e57-30b99711d9da",
            "serial": "fd794bfe-41df-c6f8-66b1-317baf3e69af",
            "state": 0
        }, {
            "name": "工业三路3号灯具_16号",
            "lat": 22.960282,
            "lng": 114.063402,
            "sid": "5331a6e0-725d-8a3f-c6b2-4255a50740e0",
            "serial": "50129a77-8e73-8145-5ac8-2aa74e48b522",
            "state": 0
        }, {
            "name": "工业三路3号灯具_17号",
            "lat": 22.959807,
            "lng": 114.058253,
            "sid": "bb4c8cb0-d372-6f88-6bd6-6ca66eb4d24b",
            "serial": "c969bf3c-d606-eb55-d1fa-801730faac25",
            "state": 0
        }, {
            "name": "工业三路3号灯具_18号",
            "lat": 22.959096,
            "lng": 114.05542,
            "sid": "67473d05-2a62-6638-12fb-76bedb847809",
            "serial": "87a82d65-d921-d502-efba-103154d8b89c",
            "state": 0
        }, {
            "name": "工业三路3号灯具_19号",
            "lat": 22.95878,
            "lng": 114.052502,
            "sid": "3d7a03ec-9b1e-3c25-59a6-7c2686974683",
            "serial": "0413441e-7385-688b-7ef8-69fc981c9114",
            "state": 0
        }, {
            "name": "工业三路3号灯具_20号",
            "lat": 22.958701,
            "lng": 114.049755,
            "sid": "a01edaec-5980-d530-219e-f456248118bf",
            "serial": "858f0269-c257-7343-83a5-9bbab1c214cd",
            "state": 0
        }, {
            "name": "工业三路3号灯具_21号",
            "lat": 22.961309,
            "lng": 114.049412,
            "sid": "1fa09e19-68ed-b9dc-5bf6-35de5eb11e44",
            "serial": "8282103d-47d6-146c-65e6-9e7c6b6440a3",
            "state": 0
        }, {
            "name": "工业三路3号灯具_22号",
            "lat": 22.964312,
            "lng": 114.049498,
            "sid": "b13a5a80-a273-467b-2cec-3d7d576cf529",
            "serial": "d5025a8c-5d71-d631-b18b-9c7c526bd974",
            "state": 0
        }, {
            "name": "工业三路3号灯具_23号",
            "lat": 22.966446,
            "lng": 114.050614,
            "sid": "2158d0cf-76d6-1064-ceab-d989c1501d69",
            "serial": "dd3efdcc-b39e-cd37-ad01-92ae3e61eecd",
            "state": 0
        }, {
            "name": "工业三路3号灯具_24号",
            "lat": 22.969607,
            "lng": 114.051043,
            "sid": "92618567-633c-0c9a-2d5d-a7bcd4e52b35",
            "serial": "92fea658-c01f-1b99-f36e-13859b0594f0",
            "state": 0
        }, {
            "name": "工业三路3号灯具_25号",
            "lat": 22.972452,
            "lng": 114.050871,
            "sid": "5f34d45e-4a02-638b-847d-4efb03774461",
            "serial": "fac3608b-9d00-e19e-b816-e264ad0c38d3",
            "state": 0
        }, {
            "name": "工业三路3号灯具_26号",
            "lat": 22.974506,
            "lng": 114.051043,
            "sid": "af260c79-eb9f-8b02-121a-2321fbc1e103",
            "serial": "c9cbd947-06bb-578f-4c42-10f026c6ad23",
            "state": 0
        }, {
            "name": "工业三路3号灯具_27号",
            "lat": 22.977114,
            "lng": 114.051729,
            "sid": "3fb54b1a-790d-75aa-9077-e42b3f8f40f5",
            "serial": "663859f6-4722-1a05-13c8-fa4775f5b906",
            "state": 0
        }, {
            "name": "工业三路3号灯具_28号",
            "lat": 22.979564,
            "lng": 114.051987,
            "sid": "6498730e-1456-c469-5667-698e78a129df",
            "serial": "c7dc4921-7e8d-8e68-1807-795eef3c9695",
            "state": 0
        }, {
            "name": "工业三路3号灯具_29号",
            "lat": 22.982329,
            "lng": 114.051472,
            "sid": "cff4b907-0b07-ff63-04ca-eb606a9ff833",
            "serial": "b97b27ee-3b5c-b385-dead-451c1895c5e5",
            "state": 0
        }, {
            "name": "工业三路3号灯具_30号",
            "lat": 22.983989,
            "lng": 114.050785,
            "sid": "5a8aad7f-ac5e-27c2-0cc2-724ccf31da86",
            "serial": "31b64245-6cbf-e30c-841b-0784a6b96c8c",
            "state": 0
        }, {
            "name": "工业三路3号灯具_31号",
            "lat": 22.98628,
            "lng": 114.049584,
            "sid": "977cc82c-3250-ce8a-b1f8-c4a26906492e",
            "serial": "d765aeaf-9cef-6fd5-9ba6-0604e1508726",
            "state": 0
        }, {
            "name": "工业三路3号灯具_32号",
            "lat": 22.988177,
            "lng": 114.04864,
            "sid": "a6ebfa2b-17a2-c6ce-2930-801a3ee3be85",
            "serial": "65ce5649-6971-dd6b-4b93-731f42170550",
            "state": 0
        }, {
            "name": "工业三路3号灯具_33号",
            "lat": 22.989441,
            "lng": 114.046751,
            "sid": "30d9b34c-3020-28a7-59fd-9e9021b2fa35",
            "serial": "ea8ffdd9-2a0b-b691-1301-134914c7b29f",
            "state": 0
        }, {
            "name": "工业三路3号灯具_34号",
            "lat": 22.990389,
            "lng": 114.045035,
            "sid": "e75ec280-81ef-0abf-9898-95c3e8fc287f",
            "serial": "f6855e6e-a20c-ebc9-c68e-3b2f4818c783",
            "state": 0
        }, {
            "name": "工业三路3号灯具_35号",
            "lat": 22.991258,
            "lng": 114.043146,
            "sid": "48633954-7b03-ccec-9842-751d1d2feaef",
            "serial": "f0f14465-171d-f52e-d53c-d7169c996f41",
            "state": 0
        }, {
            "name": "工业三路3号灯具_36号",
            "lat": 22.992285,
            "lng": 114.041344,
            "sid": "12a74d73-34d3-d886-fe7d-375419d67dab",
            "serial": "e17509ac-69da-b382-6976-03c29a8996a7",
            "state": 0
        }, {
            "name": "工业三路3号灯具_37号",
            "lat": 22.993233,
            "lng": 114.038941,
            "sid": "e66a0de3-9d74-d30d-23d8-4082a50e32dc",
            "serial": "e81ce799-c5f5-0038-f118-f556fe3ff69a",
            "state": 0
        }, {
            "name": "工业三路3号灯具_38号",
            "lat": 22.994814,
            "lng": 114.036967,
            "sid": "8a0542be-3930-e80f-71fc-c58c1ca545ce",
            "serial": "99fc41a6-d77b-9302-6ffd-76e3cfe06ef1",
            "state": 0
        }, {
            "name": "工业三路3号灯具_39号",
            "lat": 22.960045,
            "lng": 114.061257,
            "sid": "91f6a63d-f642-4323-1e59-bca6c7f91fac",
            "serial": "f2a68882-ad79-7f15-f4d4-5c731dac3317",
            "state": 0
        }, {
            "name": "工业三路3号灯具_40号",
            "lat": 22.982883,
            "lng": 114.047524,
            "sid": "42105f5c-dee6-3747-2bf7-01b24ddacb15",
            "serial": "8b6d38db-0fb3-588c-bdec-2a7dea8ba717",
            "state": 0
        }, {
            "name": "工业三路3号灯具_41号",
            "lat": 22.983515,
            "lng": 114.044691,
            "sid": "995a9bab-0ddc-1de0-c5bc-b18b969940f1",
            "serial": "4f30767d-7ae0-0ccb-89ca-ee74ef54eacd",
            "state": 0
        }, {
            "name": "工业三路3号灯具_42号",
            "lat": 23.000344,
            "lng": 114.022547,
            "sid": "f2407609-847c-3bbd-8108-e68d78acd5cf",
            "serial": "eb057968-6f7b-0d78-5c43-66277dec4b0b",
            "state": 0
        }, {
            "name": "工业三路3号灯具_43号",
            "lat": 22.984068,
            "lng": 114.037911,
            "sid": "e4f8ae73-3e72-6de3-dbf3-bc8a4a15bb06",
            "serial": "71617eca-348a-910a-418d-7776fe7cf8f2",
            "state": 0
        }, {
            "name": "工业三路3号灯具_44号",
            "lat": 22.996789,
            "lng": 114.034563,
            "sid": "2abde037-57f3-b6a5-20db-080f7cab881f",
            "serial": "b23bbd32-5de0-c258-03b4-3c5ff4169c5b",
            "state": 0
        }, {
            "name": "工业三路4号灯具_0号",
            "lat": 22.998843,
            "lng": 114.032246,
            "sid": "967a7739-5e1f-1e6d-6c79-6bbb6db55423",
            "serial": "7d8a0b7d-9e00-5ff8-485f-ff2fc9b992d1",
            "state": 0
        }, {
            "name": "工业三路4号灯具_1号",
            "lat": 23.000897,
            "lng": 114.029843,
            "sid": "d12d64c7-e891-b5e2-fdcc-9abc9860b1e1",
            "serial": "abce7dfc-ece8-68b4-658c-66f654d2f563",
            "state": 0
        }, {
            "name": "工业三路4号灯具_2号",
            "lat": 23.002952,
            "lng": 114.028298,
            "sid": "b7ea3464-cabd-2dc6-edad-8feba571347c",
            "serial": "3e73b0ac-5932-f4f8-f776-0d99cc8745e6",
            "state": 2
        }, {
            "name": "工业三路4号灯具_3号",
            "lat": 23.00548,
            "lng": 114.027096,
            "sid": "7369065b-d209-749d-3707-d668584b2829",
            "serial": "34120c7b-4f0c-6825-1b9b-c1a5452d40cb",
            "state": 0
        }, {
            "name": "工业三路4号灯具_4号",
            "lat": 23.008403,
            "lng": 114.026495,
            "sid": "0667782a-069d-8e41-327c-6439f843f53a",
            "serial": "08cf615e-f800-e9c7-425e-f5d3ee87a3cd",
            "state": 0
        }, {
            "name": "工业三路4号灯具_5号",
            "lat": 22.973163,
            "lng": 113.947703,
            "sid": "2ae39a14-0b25-57cf-1d52-c717ccffdcfd",
            "serial": "8c76320e-3fda-b937-d0f6-02bc38187bc4",
            "state": 0
        }, {
            "name": "工业三路4号灯具_6号",
            "lat": 22.973321,
            "lng": 113.949763,
            "sid": "512890cc-29ec-8d06-a4fb-850a93aba31e",
            "serial": "4c5770db-077a-54d2-e014-472f7c07e6f0",
            "state": 0
        }, {
            "name": "工业三路4号灯具_7号",
            "lat": 22.974902,
            "lng": 113.951222,
            "sid": "ec311b52-b15f-c497-571b-e6a9c1d6f368",
            "serial": "205cb0ed-15f2-255d-59c7-2c6339f8a09c",
            "state": 0
        }, {
            "name": "工业三路4号灯具_8号",
            "lat": 22.975613,
            "lng": 113.952938,
            "sid": "cc23d7a1-8187-81d1-d81a-539df9c0e94a",
            "serial": "73f19fcd-d31b-26d9-47c8-d7f1954f6b28",
            "state": 0
        }, {
            "name": "工业三路4号灯具_9号",
            "lat": 22.977193,
            "lng": 113.954312,
            "sid": "1f49f31a-af4d-5702-86a4-e54b0e6ca65a",
            "serial": "20b8a1b8-9b2f-a184-0a51-38ee09af4265",
            "state": 0
        }, {
            "name": "工业三路4号灯具_10号",
            "lat": 22.978932,
            "lng": 113.956028,
            "sid": "8746cc8c-f751-9815-3299-164ee6f91acc",
            "serial": "0d60a5ff-0c5e-f525-0d70-d8bde9ca7702",
            "state": 0
        }, {
            "name": "工业三路4号灯具_11号",
            "lat": 22.980512,
            "lng": 113.958346,
            "sid": "52532b3c-b00d-ab1a-d418-ba6dc62316fe",
            "serial": "efd0fddd-6315-067a-9cdd-5ee3341e2399",
            "state": 0
        }, {
            "name": "工业三路4号灯具_12号",
            "lat": 22.982013,
            "lng": 113.960148,
            "sid": "f4729099-84bb-261f-b1a0-e53694db204d",
            "serial": "581150ac-55ba-e12d-a23c-4f80a7104720",
            "state": 0
        }, {
            "name": "工业三路4号灯具_13号",
            "lat": 22.983831,
            "lng": 113.961607,
            "sid": "9315d562-f883-bb8f-9c8f-04a02b226089",
            "serial": "d29b8540-b149-821a-6d5a-f65e0c60aba4",
            "state": 0
        }, {
            "name": "工业三路4号灯具_14号",
            "lat": 22.985095,
            "lng": 113.963495,
            "sid": "79558cd4-7943-fb76-c6c2-89742d46ee7f",
            "serial": "43c5fd00-f656-9ff2-b474-96cc20e2b8f5",
            "state": 0
        }, {
            "name": "工业三路4号灯具_15号",
            "lat": 22.986833,
            "lng": 113.964611,
            "sid": "b4816208-3943-c830-1964-4ea24899737f",
            "serial": "ff893839-6b67-7534-8d15-961131b47875",
            "state": 0
        }, {
            "name": "工业三路4号灯具_16号",
            "lat": 22.987624,
            "lng": 113.965985,
            "sid": "ce61a293-85d5-2633-16f2-d1a760b0682f",
            "serial": "bcdf607e-ea9c-bb34-c887-66daa7016cc2",
            "state": 0
        }, {
            "name": "工业三路4号灯具_17号",
            "lat": 22.988335,
            "lng": 113.968302,
            "sid": "3620680b-5089-e26e-474a-ccc7f9437bcb",
            "serial": "70fbf861-0e7e-4cf9-4c3b-2784e7d2c227",
            "state": 0
        }, {
            "name": "工业三路4号灯具_18号",
            "lat": 22.988809,
            "lng": 113.97019,
            "sid": "17bddbcb-30fc-7f5c-6fb9-3826456511a8",
            "serial": "dc4d553a-c898-68fe-6499-34aed954e95b",
            "state": 0
        }, {
            "name": "工业三路4号灯具_19号",
            "lat": 22.99031,
            "lng": 113.972765,
            "sid": "d5a0d761-c4d9-1ac1-08b9-161967213f7c",
            "serial": "84d38994-c2d2-0eb1-2c09-6a3f5fa62b54",
            "state": 0
        }, {
            "name": "工业三路4号灯具_20号",
            "lat": 22.991021,
            "lng": 113.974482,
            "sid": "39717881-e78a-a49d-f22a-99afcc1f5ec3",
            "serial": "2220d141-6d1d-dae8-b75d-c2b1241c2dc8",
            "state": 0
        }, {
            "name": "工业三路4号灯具_21号",
            "lat": 22.99268,
            "lng": 113.975941,
            "sid": "09664b04-d918-d825-cd2a-7cfbe8fea409",
            "serial": "d1eab8cb-775c-eddc-6554-905226cf8f81",
            "state": 0
        }, {
            "name": "工业三路4号灯具_22号",
            "lat": 22.994261,
            "lng": 113.977486,
            "sid": "e6072eb9-e67a-e826-7f5a-7d8f18dc41b7",
            "serial": "043ffd86-828c-484a-79c2-137fb07dc9c6",
            "state": 0
        }, {
            "name": "工业三路4号灯具_23号",
            "lat": 22.995762,
            "lng": 113.978859,
            "sid": "2d4af1da-6088-3be0-5d22-968172d83687",
            "serial": "091ca8b3-ce24-46bc-9875-e0b06992a6fb",
            "state": 0
        }, {
            "name": "工业三路4号灯具_24号",
            "lat": 22.998053,
            "lng": 113.982893,
            "sid": "9163746c-927e-f1f3-9b00-102f395bb9c3",
            "serial": "27de094d-dad6-36f5-ab1e-3166a03c9ced",
            "state": 0
        }, {
            "name": "工业三路4号灯具_25号",
            "lat": 22.998606,
            "lng": 113.985125,
            "sid": "1371f71e-e1f4-416d-ecd5-4dc6714f94ba",
            "serial": "13cceec3-8765-5a1d-7306-bac40351c997",
            "state": 0
        }, {
            "name": "工业三路4号灯具_26号",
            "lat": 22.999475,
            "lng": 113.987185,
            "sid": "07067728-1250-57b2-ed0e-0e83e017cd4a",
            "serial": "9fb0617d-935c-50eb-8cd3-5ee3bbd5ca41",
            "state": 0
        }, {
            "name": "工业三路4号灯具_27号",
            "lat": 23.000107,
            "lng": 113.989245,
            "sid": "044b93b2-785e-0eba-33c5-7652ec2a54ff",
            "serial": "dd3dd2ff-3d49-79dd-6217-0f31e3af49ff",
            "state": 0
        }, {
            "name": "工业三路4号灯具_28号",
            "lat": 23.001134,
            "lng": 113.991734,
            "sid": "3f23319d-fc8a-a28b-c337-ad4953024cfc",
            "serial": "9fee7408-1d95-556a-1e0e-a0026e0cba50",
            "state": 0
        }, {
            "name": "工业三路4号灯具_29号",
            "lat": 23.002003,
            "lng": 113.994738,
            "sid": "9e914704-6c7f-82c3-78e9-cb2e0d9e7a9f",
            "serial": "6d7a514a-7800-e0ef-fd78-a2f60dbb395f",
            "state": 0
        }, {
            "name": "工业三路4号灯具_30号",
            "lat": 23.002398,
            "lng": 113.997399,
            "sid": "b79bf17d-fbe2-40f0-ceda-753f35fb826e",
            "serial": "80046c68-2d3a-9175-9418-c9dc1dd507bf",
            "state": 0
        }, {
            "name": "工业三路4号灯具_31号",
            "lat": 23.003268,
            "lng": 113.999459,
            "sid": "f32b2aa7-c684-5ce0-a176-7981d7a7aa69",
            "serial": "b0e44021-b3d2-a41b-b4ea-1c8d70d764a6",
            "state": 0
        }, {
            "name": "工业三路4号灯具_32号",
            "lat": 23.004137,
            "lng": 114.001604,
            "sid": "0ebab111-132e-ded9-9173-f0250ce2c51d",
            "serial": "34d506e4-ddb4-8616-28c3-e5beb33f2aa4",
            "state": 0
        }, {
            "name": "工业三路4号灯具_33号",
            "lat": 23.005006,
            "lng": 114.003493,
            "sid": "c45524d6-5631-7a90-ae0e-ed14934e60ed",
            "serial": "e507a076-df23-20cd-6f48-534bc96382d8",
            "state": 0
        }, {
            "name": "工业三路4号灯具_34号",
            "lat": 23.00548,
            "lng": 114.005553,
            "sid": "df498cf7-2a69-a2aa-7329-7b39c8bde551",
            "serial": "78ba54e7-738e-7a88-a565-74898249cbf8",
            "state": 0
        }, {
            "name": "工业三路4号灯具_35号",
            "lat": 23.006112,
            "lng": 114.007097,
            "sid": "14dba324-1a4b-213f-eee1-53d2033928d4",
            "serial": "5f418737-b590-8650-5a92-479a54b1b54b",
            "state": 0
        }, {
            "name": "工业三路4号灯具_36号",
            "lat": 23.006744,
            "lng": 114.008986,
            "sid": "36c74404-c422-b5b4-0c44-d39a0aed2467",
            "serial": "19decc07-9705-9b81-77fd-dcd8bdc802a8",
            "state": 0
        }, {
            "name": "工业三路4号灯具_37号",
            "lat": 23.007139,
            "lng": 114.011046,
            "sid": "ea62c2ce-6811-17ff-73d3-c2aa8ea2ad3a",
            "serial": "9628e55a-d19e-273c-2636-139dc813a4bd",
            "state": 0
        }, {
            "name": "工业三路4号灯具_38号",
            "lat": 23.007455,
            "lng": 114.01302,
            "sid": "41ee09f7-0a51-62ee-1c62-268d1fa2752f",
            "serial": "543ede33-cb31-c504-b9b4-f95350b98b88",
            "state": 0
        }, {
            "name": "工业三路4号灯具_39号",
            "lat": 23.007692,
            "lng": 114.015423,
            "sid": "cd69284d-e6dd-b865-0eb5-39d1f5c63d75",
            "serial": "50f4bf61-b17a-ecd7-7aed-4f7da698ac06",
            "state": 0
        }, {
            "name": "工业三路4号灯具_40号",
            "lat": 23.007771,
            "lng": 114.017655,
            "sid": "e2bbf50c-4360-65a6-d847-554e14325b65",
            "serial": "11016ac4-e672-041a-c209-7b3112a4358d",
            "state": 0
        }, {
            "name": "工业三路4号灯具_41号",
            "lat": 23.00785,
            "lng": 114.019715,
            "sid": "c63ce3cb-f8da-2502-928e-83f416a558f7",
            "serial": "d48acef5-abff-a22b-fe60-8651b1280846",
            "state": 0
        }, {
            "name": "工业三路4号灯具_42号",
            "lat": 23.008245,
            "lng": 114.021517,
            "sid": "06fe3e0e-adbf-72b8-2f19-8c87979d26f8",
            "serial": "7c5d56d7-e520-c0ab-74cc-53f18445939f",
            "state": 0
        }, {
            "name": "工业三路4号灯具_43号",
            "lat": 23.008442,
            "lng": 114.023362,
            "sid": "62355a6b-77cb-5b53-2f63-6a280a60b01f",
            "serial": "1c9f36a7-9923-58b7-ec84-8083e3f860aa",
            "state": 0
        }, {
            "name": "工业三路4号灯具_44号",
            "lat": 22.996789,
            "lng": 113.980662,
            "sid": "2aba7326-a832-3558-2175-cd9d4f15d6a8",
            "serial": "28d1dd6f-0bff-b5b0-cc29-3cf9ea1ecd54",
            "state": 0
        }]
    }
}