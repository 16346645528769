<template>
  <div class="white">
    <actionbar v-bind:select="true" v-bind:modify="true" v-on:select="select" v-on:modify="modify"></actionbar>

    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>
    <b-modal
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      class="ksmodal"
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-tabs>
        <b-tab :title="$t('温度预警')">
          <alarmAction :car="true" :alarm="true" :value="modalData.temp" :filter="true"></alarmAction>
          <hr />
        </b-tab>
        <b-tab :title="$t('湿度预警')">
          <alarmAction :car="true" :alarm="true" :value="modalData.humid" :filter="true"></alarmAction>
          <hr />
        </b-tab>
        <b-tab :title="$t('漏水预警')">
          <alarmAction :car="true" :alarm="true" :value="modalData.water" :filter="true"></alarmAction>
          <hr />
        </b-tab>
        <b-tab :title="$t('烟雾预警')">
          <alarmAction :car="true" :alarm="true" :value="modalData.fire" :filter="true"></alarmAction>
          <hr />
        </b-tab>
        <b-tab :title="$t('漏电')">
          <alarmAction :car="true" :alarm="true" :value="modalData.fail" :filter="true"></alarmAction>
          <hr />
        </b-tab>
      </b-tabs>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/device/DelWaterLevel"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>

<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
import alarmAction from "@/components/alarmAction";
import { Link } from "@/assets/utils/datef";
export default {
  components: { actionbar, tablepage, modify, deletedata, alarmAction },
  data: function() {
    return {
      radio: 0,
      title: this.$t("修改"),
      modalData: this.resetData(),
      loading: {},
      cols: [
        { name: this.$t("设备名"), value: "DeviceName" },
        {
          name: this.$t("设备类型"),
          value: "TypeName",
          subvalue: "DeviceType"
        },
        {
          name: this.$t("设备型号"),
          value: "ModelNumber",
          subvalue: "DeviceType"
        },
        { name: this.$t("温度预警"), value: "State", subvalue: "temp" },
        { name: this.$t("湿度预警"), value: "State", subvalue: "humid" },
        { name: this.$t("漏水预警"), value: "State", subvalue: "water" },
        { name: this.$t("烟雾预警"), value: "State", subvalue: "fire" },
        { name: this.$t("漏电预警"), value: "State", subvalue: "fail" },
      ],
      items: [],
      total: 0,
      count: 2000,
      index: 0,
      sid: "",
      // labels: this.getResetLable(),
      current: null,
      link: Link().filter(function(x) {
        return x.state == 1;
      }),
      selfChoose: {
        radio: null,
        devs: [],
        selected: {}
      },
      flagChoose: {
        radio: null,
        devs: [],
        selected: {}
      },
      leds: []
    };
  },
  methods: {
    select(noloading, index) {
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      new Promise((res, rej) => {
        this.$ksajax
          .ksPostPromise("/api/box/getdevices", "", true)
          .then(data => {
            console.log(data);
            data.forEach(x => {
              x.temp = {
                State: "关闭",
                LinkageActions: []
              };
              x.humid={
                State: "关闭",
                LinkageActions: []
              };
              x.water={
                State: "关闭",
                LinkageActions: []
              };
              x.fire={
                State: "关闭",
                LinkageActions: []
              };
              x.fail={
                State: "关闭",
                LinkageActions: []
              };
            });
            this.items = data;
            this.total = data.length;
            res();
          })
          .catch(err => {
            rej(err);
          });
      })
        .then(() => {
          return new Promise((res, rej) => {
            let obj = {
              Index: this.index,
              Count: this.count,
              DevCtgSid: "018"
            };
            this.$ksajax
              .ksPostPromise("/api/device/GetSensorLinkages", obj, true)
              .then(data => {
                this.items.forEach(x => {
                  data.Items.forEach(y => {
                    if (y.LinkageActions) {
                      y.LinkageActions.forEach(l => {
                        l.link = this.link.find(o => o.value == l.LinkageType);
                        l.guid = this.guid();
                      });
                    }
                    console.log(y);
                    if (y.DeviceSid == x.Sid) {
                      if (y.DataKind == 16) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.temp = y;
                      }
                      if (y.DataKind == 17) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.humid = y;
                      }
                      if (y.DataKind == 30) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.water = y;
                      }
                      if (y.DataKind == 53) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.fire = y;
                      }
                      if (y.DataKind == 123) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.fail = y;
                      }
                    }
                  });
                });
                res();
              })
              .catch(err => {
                rej(err);
              });
          });
        })
        .then(() => {
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch(err => {
          this.$throw(err);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    guid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
      }
      return guid;
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.ksmodel.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.modalData.temp = {
        Sid: this.current.temp ? this.current.temp.Sid : null,
        LinkageName: "温度预警",
        IsEnabled: this.current.temp ? this.current.temp.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 16,
        ThresholdValue: 1,
        CompareSymbol: this.current.temp
          ? this.current.temp.CompareSymbol
          : 0,
        LinkageActions: this.current.temp
          ? this.current.temp.LinkageActions
          : [],
        IsContrary: this.current.temp ? this.current.temp.IsContrary : false
      };
      
      this.modalData.humid = {
        Sid: this.current.humid ? this.current.humid.Sid : null,
        LinkageName: "湿度预警",
        IsEnabled: this.current.humid ? this.current.humid.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 17,
        ThresholdValue: 1,
        CompareSymbol: this.current.humid
          ? this.current.humid.CompareSymbol
          : 0,
        LinkageActions: this.current.humid
          ? this.current.humid.LinkageActions
          : [],
        IsContrary: this.current.humid ? this.current.humid.IsContrary : false
      };
      this.modalData.water = {
        Sid: this.current.water ? this.current.water.Sid : null,
        LinkageName: "漏水预警",
        IsEnabled: this.current.water ? this.current.water.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 30,
        ThresholdValue: 1,
        CompareSymbol: this.current.water
          ? this.current.water.CompareSymbol
          : 0,
        LinkageActions: this.current.water
          ? this.current.water.LinkageActions
          : [],
        IsContrary: this.current.water ? this.current.water.IsContrary : false
      };
      this.modalData.fire = {
        Sid: this.current.fire ? this.current.fire.Sid : null,
        LinkageName: "烟雾预警",
        IsEnabled: this.current.fire ? this.current.fire.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 53,
        ThresholdValue: 1,
        CompareSymbol: this.current.fire
          ? this.current.fire.CompareSymbol
          : 0,
        LinkageActions: this.current.fire
          ? this.current.fire.LinkageActions
          : [],
        IsContrary: this.current.fire ? this.current.fire.IsContrary : false
      };
      this.modalData.fail = {
        Sid: this.current.fail ? this.current.fail.Sid : null,
        LinkageName: "漏电",
        IsEnabled: this.current.fail ? this.current.fail.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 123,
        ThresholdValue: 1,
        CompareSymbol: this.current.fail
          ? this.current.fail.CompareSymbol
          : 0,
        LinkageActions: this.current.fail
          ? this.current.fail.LinkageActions
          : [],
        IsContrary: this.current.fail ? this.current.fail.IsContrary : false
      };
      this.$refs.modallg.show();
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      return {
        temp: { LinkageActions: [] },
        humid: { LinkageActions: [] },
        water: { LinkageActions: [] },
        fire: { LinkageActions: [] },
        fail: { LinkageActions: [] }
      };
    },
    submited() {
      this.select();
    },
    deleted() {
      this.select();
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    addItem(val) {
      if (val == 0) {
        //self
        var dev = this.selfChoose.devs.find(
          x => x.Sid == this.selfChoose.selected
        );
        var action = this.link.find(x => x.value == this.selfChoose.radio);
        this.modalData.self.LinkageActions.push({
          Sid: "",
          DeviceSid: dev.Sid,
          DeviceName: dev.DeviceName,
          LinkageType: action.value,
          Content: null,
          link: action
        });
      }
    },
    selfChange(val) {
      if ([0, 1, 2, 3, 4].indexOf(val) != -1) {
        if (this.leds.length == 0) {
          let obj = {
            Index: 0,
            Count: 1000
          };
          this.loading = this.$loading();
          this.$ksajax
            .ksPostPromise("/api/device/GetDeviceLedAds", obj, true)
            .then(x => {
              this.leds = x.Items;
              this.selfChoose.devs = x.Items;
              this.selfChoose.selected = this.leds[0].Sid;
              this.loading.close();
            })
            .catch(err => {
              this.$throw(err);
              this.loading.close();
            });
        } else {
          this.selfChoose.devs = this.leds;
          this.selfChoose.selected = this.leds[0].Sid;
        }
      }
    }
  },
  mounted: function() {
    this.select();
  }
};
</script>
