<template>
  <div>
    <el-table
      :data="items"
      style="width: 100%"
      highlight-current-row
      stripe
      :fit="true"
      @current-change="handleCurrentChange"
      :empty-text="$t('暂无数据')"
    >
      <!-- <el-table-column  v-for="it in cols" :key="it.name"
        :prop="it.value"
        :label="it.name">
      </el-table-column> -->
      <el-table-column v-for="it in cols" :key="it.name" :label="it.name">
        <template slot-scope="scope">
          <div v-if="it.pic">
            <img
              v-if="!it.subvalue"
              :src="scope.row[it.value]"
              style="width: 80px; height: auto"
            />
            <img
              v-else
              :src="scope.row[it.subvalue][it.value]"
              style="width: 80px; height: auto"
            />
          </div>
          <div v-else-if="it.link">
            <a
              v-if="scope.row[it.value].indexOf('http') != -1"
              :href="scope.row[it.value]"
              target="_blank"
              >{{ it.name }}</a
            >
            <router-link v-else :to="scope.row[it.value]">{{
              it.name
            }}</router-link>
          </div>
          <div v-else>
            <div v-if="!it.sub">
              <span v-if="!it.subvalue">
                <p v-if="!it.state">{{ scope.row[it.value] }}</p>
                <i
                  v-if="it.state"
                  :class="[
                    'fa',
                    it.state == 1 ? 'fa-signal' : 'fa-power-off',
                    scope.row[it.value] == 0 ? 'stateOff' : 'stateOn',
                    'fa-2x',
                  ]"
                ></i>
              </span>
              <span v-if="it.subvalue">
                <p v-if="!it.state">{{ scope.row[it.subvalue][it.value] }}</p>
                <i
                  v-if="it.state"
                  :class="[
                    'fa',
                    it.state == 1 ? 'fa-signal' : 'fa-power-off',
                    scope.row[it.subvalue][it.value] == 0
                      ? 'stateOff'
                      : 'stateOn',
                    'fa-2x',
                  ]"
                ></i>
              </span>
            </div>
            <div v-else>
              <span v-if="it.sub == 0">
                <p v-if="!it.state">{{ scope.row[it.value] }}</p>
                <i
                  v-if="it.state"
                  :class="[
                    'fa',
                    it.state == 1 ? 'fa-signal' : 'fa-power-off',
                    scope.row[it.value] == 0 ? 'stateOff' : 'stateOn',
                    'fa-2x',
                  ]"
                ></i>
              </span>
              <span v-if="it.sub == 1">
                <p v-if="!it.state">{{ scope.row[it.subvalue][it.value] }}</p>
                <i
                  v-if="it.state"
                  :class="[
                    'fa',
                    it.state == 1 ? 'fa-signal' : 'fa-power-off',
                    scope.row[it.subvalue][it.value] == 0
                      ? 'stateOff'
                      : 'stateOn',
                    'fa-2x',
                  ]"
                ></i>
              </span>
              <span v-if="it.sub == 2">
                <p v-if="!it.state">
                  {{ scope.row[it.subvalue][it.thvalue][it.value] }}
                </p>
                <i
                  v-if="it.state"
                  :class="[
                    'fa',
                    it.state == 1 ? 'fa-signal' : 'fa-power-off',
                    scope.row[it.subvalue][it.thvalue][it.value] == 0
                      ? 'stateOff'
                      : 'stateOn',
                    'fa-2x',
                  ]"
                ></i>
              </span>
              <span v-if="it.sub == 3">
                <p v-if="!it.state">
                  {{ scope.row[it.subvalue][it.thvalue][it.fovalue][it.value] }}
                </p>
                <i
                  v-if="it.state"
                  :class="[
                    'fa',
                    it.state == 1 ? 'fa-signal' : 'fa-power-off',
                    scope.row[it.subvalue][it.thvalue][it.fovalue][it.value] ==
                    0
                      ? 'stateOff'
                      : 'stateOn',
                    'fa-2x',
                  ]"
                ></i>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <b-pagination
      v-if="total > perpage"
      size="md"
      :total-rows="total"
      v-model="currentPage"
      :per-page="perpage"
      class="pagin"
      align="center"
      :first-text="$t('首页')"
      :prev-text="$t('上一页')"
      :next-text="$t('下一页')"
      :last-text="$t('最后一页')"
    ></b-pagination>
  </div>
</template>
<style>
</style>
<script>
export default {
  computed: {
    getStateClass(state, value) {
      let cls = "";
      switch (state) {
        case 1:
          //在线状态
          cls = "fa fa-signal";
          if (value == 0) {
            cls = `${cls} stateOff`;
          } else {
            cls = `${cls} stateOn`;
          }
          break;
        case 2:
          cls = "fa fa-signal";
          if (value == 0) {
            cls = `${cls} stateOff`;
          } else {
            cls = `${cls} stateOn`;
          }
          break;
        default:
          throw "不支持的参数";
      }
      return cls;
    },
  },
  props: {
    total: Number,
    perpage: Number,
    items: Array,
    cols: Array,
    cpage: Number,
  },
  data: function () {
    return {
      currentPage: 1,
      currentRow: 0,
    };
  },
  watch: {
    currentPage: function () {
      this.$emit("currentPage", this.currentPage);
    },
    cpage: function () {
      this.currentPage = this.cpage;
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.currentRow = val;
      this.$emit("currentRow", val);
    },
    reset() {
      this.currentPage = 1;
    },
  },
  mounted: function () {
    this.$emit("created");
  },
  setData(value) {
    this.total = value.total;
    this.items = value.items;
    this.cols = value.cols;
  },
};
</script>
