<template>
  <div class="info_price">
    <div class="container" style="padding: 25px 0 15px 0">
      <div>
        <span style="font-size: 30px">套餐价格 </span>
        具体需求及价格请联系业务咨询
      </div>
      <el-table
        :data="items"
        class="borderTable"
        id="priceTable"
        highlight-current-row
        :span-method="objectSpanMethod"
        :empty-text="$t('暂无数据')"
      >
        <el-table-column label="">
          <template slot-scope="slot">
            <strong>{{ slot.row.Parent }}</strong>
          </template>
        </el-table-column>
        <el-table-column label="普通客户">
          <template slot-scope="slot">
            {{ slot.row.Normal ? slot.row.Name : "" }}
          </template>
        </el-table-column>
        <el-table-column label="VIP客户">
          <template slot-scope="slot">
            {{ slot.row.Vip ? slot.row.Name : "" }}
          </template>
        </el-table-column>
        <el-table-column label="订制客户">
          <template slot-scope="slot">
            {{
              slot.row.Custom == "联系商务"
                ? "联系商务"
                : slot.row.Custom
                ? slot.row.Name
                : ""
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<style scoped>
.info_price {
  padding-top: 70px;
}
.el-table{
    background-color: darkslateblue;
}
</style>

<script>
export default {
  data: function () {
    return {
      items: this.getPriceData(),
    };
  },
  mounted: function () {
    this.$nextTick(() => {
      // var ele = document.getElementById("info_menu");
      // if (ele) {
      //   ele.style.backgroundColor = "#333333";
      // }
      // document.getElementById("info_footer").style.position = "relative";
    });
  },
  methods: {
    objectSpanMethod(row) {
      if (row.columnIndex == 0) {
        var index = 0;
        var ele = null;
        if (row.rowIndex != 0) {
          ele = this.items[row.rowIndex - 1];
        } else {
          index = 1;
        }
        if (ele) {
          if (ele.Parent != row.row.Parent) {
            index = 1;
          }
        }

        if (index == 0) {
          return {
            rowspan: 1,
            colspan: 0,
          };
        } else {
          this.items.forEach((element) => {
            if (element.Parent == row.row.Parent) {
              index += 1;
            }
          });
          return {
            rowspan: index - 1,
            colspan: 1,
          };
        }
      } else {
        return [1, 1];
      }
    },
    getPriceData() {
      return [
        {
          Sid: 0,
          Name: "工业设计手册V1",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "灯杆设计",
        },
        {
          Sid: 1,
          Name: "免费更改",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "软件平台logo",
        },
        {
          Sid: 1,
          Name: "免费部署",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "云平台部署",
        },
        {
          Sid: 1,
          Name: "单灯控制",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "聚合平台",
        },
        {
          Sid: 1,
          Name: "视频监控",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "聚合平台",
        },
        {
          Sid: 1,
          Name: "环境监测",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "聚合平台",
        },
        {
          Sid: 1,
          Name: "信息发布",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "聚合平台",
        },
        {
          Sid: 1,
          Name: "无线网络",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "聚合平台",
        },
        {
          Sid: 1,
          Name: "公共广播",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "聚合平台",
        },
        {
          Sid: 1,
          Name: "紧急呼叫",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "聚合平台",
        },
        {
          Sid: 1,
          Name: "水文监测",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "聚合平台",
        },
        {
          Sid: 1,
          Name: "井盖监测",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "聚合平台",
        },
        {
          Sid: 1,
          Name: "车位管理",
          Normal: true,
          Vip: true,
          Custom: true,
          Parent: "聚合平台",
        },
        {
          Sid: 1,
          Name: "水文监测与信息发布、公共广播互联",
          Normal: false,
          Vip: true,
          Custom: true,
          Parent: "互联平台",
        },
        {
          Sid: 1,
          Name: "环境监测与信息发布、公共广播互联",
          Normal: false,
          Vip: true,
          Custom: true,
          Parent: "互联平台",
        },
        {
          Sid: 1,
          Name: "井盖监测与信息发布、公共广播互联",
          Normal: false,
          Vip: false,
          Custom: true,
          Parent: "互联平台",
        },
        {
          Sid: 1,
          Name: "车位管理与信息发布、公共广播互联",
          Normal: false,
          Vip: false,
          Custom: true,
          Parent: "互联平台",
        },
        {
          Sid: 1,
          Name: "水文监测与单灯控制互联",
          Normal: false,
          Vip: false,
          Custom: true,
          Parent: "互联平台",
        },
        {
          Sid: 1,
          Name: "井盖监测与单灯控制互联",
          Normal: false,
          Vip: false,
          Custom: true,
          Parent: "互联平台",
        },
        {
          Sid: 1,
          Name: "其他互联订制",
          Normal: false,
          Vip: false,
          Custom: true,
          Parent: "互联平台",
        },
        {
          Sid: 1,
          Name: "人脸识别",
          Normal: false,
          Vip: false,
          Custom: true,
          Parent: "智慧平台",
        },
        {
          Sid: 1,
          Name: "越界报警",
          Normal: false,
          Vip: false,
          Custom: true,
          Parent: "智慧平台",
        },
        {
          Sid: 1,
          Name: "与其他平台对接",
          Normal: false,
          Vip: false,
          Custom: true,
          Parent: "智慧平台",
        },
        {
          Sid: 1,
          Name: "相关应用开发",
          Normal: false,
          Vip: false,
          Custom: true,
          Parent: "智慧平台",
        },
        {
          Sid: 1,
          Name: "联系商务",
          Normal: "联系商务",
          Vip: "联系商务",
          Custom: "联系商务",
          Parent: "服务价格",
        },
      ];
    },
    getData() {
      return [
        {
          Sid: 0,
          Name: "灯杆设计",
          List: [
            {
              Sid: 0,
              Name: "工业设计手册V1",
              Normal: true,
              Vip: true,
              Custom: true,
            },
          ],
        },
        {
          Sid: 1,
          Name: "软件平台logo",
          List: [
            {
              Sid: 1,
              Name: "免费更改",
              Normal: true,
              Vip: true,
              Custom: true,
            },
          ],
        },
        {
          Sid: 2,
          Name: "云平台部署",
          List: [
            {
              Sid: 1,
              Name: "免费部署",
              Normal: true,
              Vip: true,
              Custom: true,
            },
          ],
        },
        {
          Sid: 3,
          Name: "聚合平台",
          List: [
            {
              Sid: 1,
              Name: "单灯控制",
              Normal: true,
              Vip: true,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "视频监控",
              Normal: true,
              Vip: true,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "环境监测",
              Normal: true,
              Vip: true,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "信息发布",
              Normal: true,
              Vip: true,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "无线网络",
              Normal: true,
              Vip: true,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "公共广播",
              Normal: true,
              Vip: true,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "公共广播",
              Normal: true,
              Vip: true,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "紧急呼叫",
              Normal: true,
              Vip: true,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "水文监测",
              Normal: true,
              Vip: true,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "井盖监测",
              Normal: true,
              Vip: true,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "车位管理",
              Normal: true,
              Vip: true,
              Custom: true,
            },
          ],
        },
        {
          Sid: 2,
          Name: "互联平台",
          List: [
            {
              Sid: 1,
              Name: "水文监测与信息发布、公共广播互联",
              Normal: false,
              Vip: true,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "环境监测与信息发布、公共广播互联",
              Normal: false,
              Vip: true,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "井盖监测与信息发布、公共广播互联",
              Normal: false,
              Vip: false,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "车位管理与信息发布、公共广播互联",
              Normal: false,
              Vip: false,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "水文监测与单灯控制互联",
              Normal: false,
              Vip: false,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "井盖监测与单灯控制互联",
              Normal: false,
              Vip: false,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "其他互联订制",
              Normal: false,
              Vip: false,
              Custom: true,
            },
          ],
        },
        {
          Sid: 2,
          Name: "智慧平台",
          List: [
            {
              Sid: 1,
              Name: "人脸识别",
              Normal: false,
              Vip: false,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "越界报警",
              Normal: false,
              Vip: false,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "与其他平台对接",
              Normal: false,
              Vip: false,
              Custom: true,
            },
            {
              Sid: 1,
              Name: "相关应用开发",
              Normal: false,
              Vip: false,
              Custom: true,
            },
          ],
        },
        // {
        //     "Sid":2,
        //     "Name":"服务价格",
        //     "List":[
        //         {
        //         "Sid":1,
        //          "Name":"以上服务免费",
        //         "Normal":true,
        //         "Vip":true,
        //         "Custom":"联系商务"
        //       }
        //     ],
        // }
      ];
    },
  },
};
</script>
