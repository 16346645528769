<template>
  <div v-if="show">
    <div class="m_menu">
      <b-navbar id="m_menu" toggleable="md" type="dark">
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

        <b-navbar-brand :to="home">
          <b-img :src="$store.state.project.Logo" style="height: 35px"></b-img>
        </b-navbar-brand>
        <b-collapse is-nav id="nav_collapse">
          <b-navbar-nav style="width: 80%" id="nav_center">
            <router-link
              v-for="item in menu.slice(0, 6)"
              v-bind:class="['mainmenu_box', item.active ? 'active' : '']"
              :key="item.Sid"
              :to="item.Path"
            >
              <div class="mainmenu_box_img">
                <b-img width="30px" :src="item.Pic"></b-img>
                <span
                  style="font-size: 0.8em; color: white; padding-left: 5px"
                  >{{ item.Name }}</span
                >
              </div>
              <div
                class="mainmenu_box_border"
                style="border-bottom: 2px solid #007de4; width: 100%"
              ></div>
            </router-link>
            <div class="mainmenu_box_img">
              <b-img
                width="30px"
                :src="baseUrl + '/img/new/ci_gdnr.png'"
              ></b-img>
              <span style="font-size: 0.8em; color: white; padding-left: 5px">{{
                $t("更多")
              }}</span>
            </div>
            <b-nav-item-dropdown right>
              <b-dropdown-item
                v-for="it in menu.slice(6, menu.length)"
                :key="it.Sud"
                :to="it.Path"
                >{{ it.Name }}</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto lghide">
            <b-nav-item>
              <router-link to="/admin/setting">
                <i
                  style="font-size: 20px; color: white"
                  class="fa fa-cog fa-fw"
                ></i>
              </router-link>
            </b-nav-item>
            <b-nav-item>
              <router-link to="/admin/alarm">
                <i
                  style="font-size: 20px; color: white"
                  class="fa fa-bell-o fa-fw"
                ></i>
              </router-link>
            </b-nav-item>
            <b-nav-item-dropdown right>
              <template slot="button-content">
                <em>{{ userName }}</em>
              </template>
              <b-dropdown-item to="/admin/setting/password">{{
                $t("修改密码")
              }}</b-dropdown-item>
              <b-dropdown-item @click="logout">{{
                $t("安全退出")
              }}</b-dropdown-item>
            </b-nav-item-dropdown>
            <el-radio-group
              v-model="lang"
              size="mini"
              fill="black"
              style="margin-right: 20px; width: 120px"
              @change="lanChange"
            >
              <el-radio-button value="cn" label="zh"></el-radio-button>
              <el-radio-button value="en" label="en"></el-radio-button>
            </el-radio-group>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <div class="sonmenu" v-if="sunMenu">
      <b-navbar toggleable="md" type="dark" variant="dark" class="borderNav">
        <b-navbar-toggle target="nav_collapse_son"></b-navbar-toggle>

        <b-navbar-brand>{{ sunMenu.Name }}</b-navbar-brand>

        <b-collapse is-nav id="nav_collapse_son" style="margin-left: 5%">
          <b-navbar-nav
            v-for="item in sunMenu.Sons"
            :key="item.Sid"
            style="margin-right: 5%"
          >
            <b-nav-item pills v-bind:to="item.Path">{{ item.Name }}</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <!-- navbar-1.vue -->
    </div>
  </div>
  <div v-else></div>
</template>
<style>
.navbar-collapse.show #nav_center .mainmenu_box .mainmenu_box_img > span {
  display: inline;
}
#nav_center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainmenu_box:hover .mainmenu_box_img > span {
  display: inline;
}
.mainmenu_box:hover .mainmenu_box_border {
  visibility: visible;
}
.mainmenu_box:hover .mainmenu_box_img {
  margin-bottom: 10px;
  opacity: 1;
}
.mainmenu_box_border {
  visibility: hidden;
}
.mainmenu_box_img > span {
  /* display: none; */
}
.mainmenu_box {
  margin-right: 20px;
  cursor: pointer;
}
.mainmenu_box_img {
  margin-bottom: 10px;
  opacity: 0.5;
}
.mainmenu_center {
  /* margin-left: 10% */
}
</style>
<script>
import config from "../assets/utils/config";
export default {
  props: ["sid"],
  data: function () {
    return {
      baseUrl: config.BaseUrl,
      logopic: require("../assets/img/cute/logo.png"),
      lang: "zh",
      menu: [],
      sunMenu: null,
      userName: this.$cookies.get("username"),
      home:
        this.$cookies.get("ksCustom") == "true"
          ? "/admin/nhome"
          : "/admin/home",
      isNeture: config.ISNETURE,
      show: !config.ISNETURE && config.Theme == 0,
    };
  },
  mounted: function () {
    this.lang = this.$cookies.get("lan");
    if (!this.isNeture) {
      this.init();
    }
  },
  methods: {
    logout() {
      //删除cookie
      this.$cookies.set("token", null, 0);
      if (this.$cookies.get("ksCustom") == "true") {
        this.$router.push({ path: "/info/home" });
      } else {
        this.$router.push({ path: "/info/login?reload=true" });
      }
    },
    lanChange() {
      //先切换语言
      this.$i18n.locale = this.lang;
      this.$cookies.set("lan", this.$i18n.locale, 365);
      this.$store.dispatch(
        "menuAction",
        () => {
          this.init();
        },
        (err) => {
          this.$throw(err);
        }
      );
      this.$emit("langchange", this.lang);
    },
    init() {
      var m = this.$store.getters.getSonMenu("0");
      var hasCityBroadcast = false;
      m.forEach((x) => {
        x.active = false;
        if (x.Sid === "test9") {
          hasCityBroadcast = true;
        }
      });
      this.menu = m;
      var lan = this.$cookies.get("lan");
      if (this.sid == "setting") {
        this.sunMenu = {
          Sons: [],
        };
        if (lan == "zh") {
          this.sunMenu.Name = "Setting";
          if (hasCityBroadcast === true) {
            this.sunMenu.Sons.push(
              {
                Path: "/admin/setting/password",
                Name: "修改密码",
                Sid: 1,
              },
              {
                Path: "/admin/setting/broadset",
                Name: "广播服务器",
                Sid: 2,
              },
              {
                Path: "/admin/setting/cityBroadcast",
                Name: "城市广播初始化",
                Sid: 3,
              }
            );
          } else {
            this.sunMenu.Sons.push(
              {
                Path: "/admin/setting/password",
                Name: "修改密码",
                Sid: 1,
              },
              {
                Path: "/admin/setting/broadset",
                Name: "广播服务器",
                Sid: 2,
              }
            );
          }
        } else {
          this.sunMenu.Name = "Setting";
          if (hasCityBroadcast === true) {
            this.sunMenu.Sons.push(
              {
                Path: "/admin/setting/password",
                Name: "Modify password",
                Sid: 1,
              },
              {
                Path: "/admin/setting/broadset",
                Name: "BroadCast server",
                Sid: 2,
              },
              {
                Path: "/admin/setting/cityBroadcast",
                Name: "City BroadCast Init",
                Sid: 3,
              }
            );
          } else {
            this.sunMenu.Sons.push(
              {
                Path: "/admin/setting/password",
                Name: "Modify password",
                Sid: 1,
              },
              {
                Path: "/admin/setting/broadset",
                Name: "BroadCast server",
                Sid: 2,
              }
            );
          }
        }
      } else if (this.sid == "alarm") {
        this.sunMenu = {
          Sons: [],
        };
        if (lan == "zh") {
          this.sunMenu.Name = "告警设置";
          this.sunMenu.Sons.push(
            {
              Path: "/admin/setting/alarmer",
              Name: "报警接收人",
              Sid: 1,
            },
            {
              Path: "/admin/setting/alarms",
              Name: "告警信息",
              Sid: 2,
            },
            {
              Path: "/admin/setting/partment",
              Name: "部门管理",
              Sid: 3,
            }
          );
        } else {
          this.sunMenu.Name = "Alarm setting";
          this.sunMenu.Sons.push(
            {
              Path: "/admin/setting/alarmer",
              Name: "Alarm reciver",
              Sid: 1,
            },
            {
              Path: "/admin/setting/alarms",
              Name: "Alarm list",
              Sid: 2,
            },
            {
              Path: "/admin/setting/partment",
              Name: "Partment",
              Sid: 3,
            }
          );
        }
      } else if (this.sid) {
        this.sunMenu = this.$store.getters.getSonMenuPath(this.sid);
      }
    },
  },
};
</script>

