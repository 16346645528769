<template>
  <div
    style="width: 100%; height: 100%; position: relative"
    v-loading="loading"
    ref="canvas_box"
  >
    <img :src="borderImg" style="display: none" id="borderImg" />
    <img :src="coverImg" style="display: none" id="coverImg" />
    <canvas id="canvas_border" style="position: absolute; z-index: 0"></canvas>
    <canvas id="canvas_cover" style="position: absolute; z-index: 0"></canvas>
  </div>
</template>
<script>
export default {
  props: ["sid", "objvalue"],
  data() {
    return {
      cover: {},
      loading: true,
      coverCurrentAngel: 0,
      coverValue: 0,
      borderRate: 0.489,
      coverRate: 0.201,
      startPosition: [],
      converStartPosition: [],
      coverWidth: 0,
      coverHeight: 0,
      borderHeight: 0,
      borderWidth: 0,
      canvas: null,
      canvas_cover: null,
      borderImg: require("@/assets/img/ic_jinggai.png"),
      coverImg: require("@/assets/img/ic_giazi.png"),
      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" },
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" },
      ]),
      startCoverValueThread: null,
      startCoverThread: null,
      alarm: false,
      leftBottomLoading: false,
      name: "",
    };
  },
  watch: {
    objvalue: function () {
      this.cover.Angle = this.coverValue = this.objvalue.Data.Ag || 0;
      console.log(this.objvalue,"conver");
    },
    sid: function () {
      this.select();
    },
    "$store.state.alarmHubChange": function () {
      var data = this.$store.state.alarmHub;
      if (data.DeviceSid == this.sid) {
        this.cover.Angle = this.coverValue = data.Data.Ag;
      }
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      console.log("init");
      this.canvas = document.getElementById("canvas_border");
      this.canvasCover = document.getElementById("canvas_cover");
      this.canvas.width = this.$refs.canvas_box.clientWidth;
      this.canvas.height = this.$refs.canvas_box.clientHeight;
      this.canvasCover.width = this.$refs.canvas_box.clientWidth;
      this.canvasCover.height = this.$refs.canvas_box.clientHeight;
      var width = this.canvas.width / 3;
      var height = width * this.borderRate;
      if (height > this.canvas.height) {
        height = this.canvas.height * 0.25;
        width = height / this.borderRate;
      }
      this.startPosition = [width, (this.canvas.height - height) / 2];
      this.borderHeight = height;
      this.borderWidth = width;
      var img = document.getElementById("borderImg");
      img.onload = this.drawBorder;
      this.drawBorder();
      var r = 0.76359;
      this.coverWidth = this.borderWidth * r;
      this.coverHeight = this.coverWidth * this.coverRate;
      this.converStartPosition = [
        this.startPosition[0] + (this.borderWidth - this.coverWidth) / 2,
        this.startPosition[1],
      ];
      var coverImg = document.getElementById("coverImg");
      coverImg.onload = this.drawCover;
      this.drawCover();
      this.select();
    },
    select() {
      if(this.objvalue){
      this.loading = false;
      this.cover.Angle = this.coverValue = this.objvalue.Data.Ag || 0;
        return;
      }
      var sid = this.sid;
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/device/GetWellCoverInfo", sid, true)
        .then((data) => {
          this.loading = false;
          this.cover = data;
          this.coverValue = data.Angle;
          this.name = data.DeviceName;
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    drawBorder() {
      var ctx = this.canvas.getContext("2d");
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineWidth = 10;
      ctx.strokeStyle = "white";
      var img = document.getElementById("borderImg");
      img = new Image();
      img.src = this.borderImg;
      ctx.drawImage(
        img,
        this.startPosition[0],
        this.startPosition[1],
        this.borderWidth,
        this.borderHeight
      );
      ctx.stroke();
      ctx.closePath();
    },
    drawCover() {
      var ctx = this.canvasCover.getContext("2d");
      ctx.clearRect(0, 0, this.coverWidth, this.coverHeight);
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineWidth = 10;
      ctx.strokeStyle = "white";
      var img = document.getElementById("coverImg");
      img = new Image();
      img.src = this.coverImg;
      ctx.drawImage(
        img,
        this.converStartPosition[0],
        this.converStartPosition[1],
        this.coverWidth,
        this.coverHeight
      );
      ctx.stroke();
      ctx.closePath();
      window.startCoverThread = this.startCover();
    },
    drawCoverAngle(value) {
      var ctx = this.canvasCover.getContext("2d");

      ctx.clearRect(0, 0, this.canvasCover.width, this.canvasCover.height);
      ctx.save();
      ctx.translate(this.converStartPosition[0], this.converStartPosition[1]);
      ctx.beginPath();
      ctx.rotate((360 - value) * (Math.PI / 180));
      var img = document.getElementById("coverImg");
      img = new Image();
      img.src = this.coverImg;
      ctx.drawImage(img, 0, 0, this.coverWidth, this.coverHeight);
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    },
    startCover() {
      return window.setInterval(() => {
        if (this.coverCurrentAngel != this.coverValue) {
          if (this.coverCurrentAngel > this.coverValue) {
            this.coverCurrentAngel -= 5;
            if (this.coverCurrentAngel < this.coverValue) {
              this.coverCurrentAngel = this.coverValue;
            }
          } else {
            this.coverCurrentAngel += 5;
            if (this.coverCurrentAngel > this.coverValue) {
              this.coverCurrentAngel = this.coverValue;
            }
          }
          this.drawCoverAngle(this.coverCurrentAngel);
        }
      }, 100);
    },
    startCoverValue() {
      return window.setInterval(() => {
        this.coverValue = parseInt(Math.random() * 180);
        if (this.coverValue >= 90) {
          this.alarm = true;
        } else {
          this.alarm = false;
          this.coverValue = 0;
        }
      }, 6000);
    },
  },
  destroyed: function () {
    window.clearInterval(this.startCoverValueThread);
    window.clearInterval(this.startCoverThread);
  },
};
</script>