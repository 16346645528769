<template>
  <div style="width:100%;height:100%">
    <b-form ref="actonForm" class="needs-validation" @submit="onSubmit">
      <div v-if="!alarm">
        <b-form-group v-if="!car" v-bind:label="$t('阈值')" :label-cols="2">
          <b-form-input type="number" v-model="modelValue.ThresholdValue" required></b-form-input>
        </b-form-group>
        <b-form-group v-if="car" v-bind:label="$t('阈值')" :label-cols="2">
          <b-form-radio-group v-model="modelValue.ThresholdValue">
            <b-form-radio value="0">{{$t("空闲")}}</b-form-radio>
            <b-form-radio value="1">{{$t("占用")}}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </div>
      <b-form-group v-if="!car" v-bind:label="$t('符号')" :label-cols="2">
        <b-form-radio-group v-model="modelValue.CompareSymbol">
          <b-form-radio value="-1">{{$t("小于")}}</b-form-radio>
          <b-form-radio value="0">{{$t("等于")}}</b-form-radio>
          <b-form-radio value="1">{{$t("大于")}}</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-form-group v-bind:label="$t('状态')" :label-cols="2">
        <b-form-radio-group v-model="modelValue.IsEnabled">
          <b-form-radio value="true">{{$t("启用")}}</b-form-radio>
          <b-form-radio value="false">{{$t("禁用")}}</b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <b-form-group v-bind:label="$t('不符合条件时执行相反动作')" :label-cols="2">
        <b-form-radio-group v-model="modelValue.IsContrary">
          <b-form-radio value="true">{{$t("启用")}}</b-form-radio>
          <b-form-radio value="false">{{$t("禁用")}}</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-form-group v-bind:label="$t('动作')" :label-cols="2">
        <b-form-radio-group v-model="choose.radio" :options="link" @change="actionChange"></b-form-radio-group>
      </b-form-group>
      <b-form-group
        v-bind:label="$t('联动设备')"
        :label-cols="2"
        v-if="choose.devs.length!=0"
        class="inlineGroup"
      >
        <b-form-select
          v-model="choose.selected"
          :options="choose.devs"
          value-field="Sid"
          text-field="DeviceName"
          style="width:60%"
        ></b-form-select>
        <el-button type="primary" @click="addItem" round size="small">{{$t('新增')}}</el-button>
      </b-form-group>

      <!-- <el-button
        v-if="choose.radio==30||choose.radio==31"
        type="primary"
        @click="addWater"
        round
        size="small"
      >{{$t('新增')}}</el-button>-->
      <b-form-group v-bind:label="$t('联动')" :label-cols="2">
        <div v-for="item in modelValue.LinkageActions" class="sceneTemp" :key="item.guid">
          <div style="position:absolute;right:10px;top:-5px">
            <i class="fa fa-trash-o" style="cursor:pointer" @click="delItem(item.guid)"></i>
          </div>
          <b-form-group v-bind:label="$t('联动类型')" :label-cols="2">
            <b-form-text>{{item.link.text}}</b-form-text>
          </b-form-group>
          <b-form-group v-bind:label="$t('设备名称')" :label-cols="2">
            <b-form-text>{{item.DeviceName}}</b-form-text>
          </b-form-group>
          <b-form-group v-bind:label="$t('状态')" :label-cols="2">
            <b-form-select v-model="item.Status">
              <option value="0">关</option>
              <option value="1">开</option>
            </b-form-select>
          </b-form-group>
          <div v-if="item.Status==1||modelValue.IsContrary=='true'">
            <b-form-group v-bind:label="$t('内容')" :label-cols="2" v-if="item.LinkageType==1">
              <b-form-input type="text" v-model="item.Content.content" required></b-form-input>
            </b-form-group>
            <b-form-group v-bind:label="$t('字体')" :label-cols="2" v-if="item.LinkageType==1">
              <div>
                <div style="width:100px;float:left;">
                  <div>{{$t('字体大小')}}</div>
                  <b-form-select
                    :options="fontSize"
                    v-model="item.Content.style['font-size']"
                    required
                    v-b-tooltip.hover
                    :title="$t('字体大小')"
                    style="width:100px"
                  ></b-form-select>
                </div>

                <div style="width:100px;float:left;">
                  <div>{{$t('斜体')}}</div>
                  <b-form-select
                    v-model="item.Content.style['font-style']"
                    required
                    v-b-tooltip.hover
                    :title="$t('斜体')"
                    style="width:100px"
                  >
                    <option value="normal">{{$t('正常')}}</option>
                    <option value="italic">{{$t('斜体')}}</option>
                  </b-form-select>
                </div>
                <div style="width:100px;float:left;">
                  <div>{{$t('粗体')}}</div>
                  <b-form-select
                    v-model="item.Content.style['font-weight']"
                    required
                    v-b-tooltip.hover
                    :title="$t('粗体')"
                    style="width:100px"
                  >
                    <option value="normal">{{$t('正常')}}</option>
                    <option value="bold">{{$t('粗体')}}</option>
                  </b-form-select>
                </div>
                <div style="width:100px;float:left;">
                  <div>{{$t('背景颜色')}}</div>
                  <el-color-picker v-model="item.Content.style['background-color']"></el-color-picker>
                </div>
                <div style="width:100px;float:left;">
                  <div>{{$t('字体颜色')}}</div>
                  <el-color-picker v-model="item.Content.style['color']"></el-color-picker>
                </div>
                <div
                  style="width:100%;float:left;"
                  :style="item.Content.style"
                >{{item.Content.content}}</div>
                <div class="clear"></div>
              </div>
            </b-form-group>

            <b-form-group v-bind:label="$t('图片')" :label-cols="2" v-if="item.LinkageType==2">
              <el-upload
                :on-success="Uploaded"
                class="upload-demo"
            :action="baseUrl+'/api/Upload/FileUploadSingle'"
                :file-list="item.fileList"
                :data="item.fileData"
                :limit="1"
                list-type="picture"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-upload-="beforeUpload(item.guid)"
                accept="image/gif, image/jpeg, image/png"
              >
                <el-button size="small" type="primary">{{$t('上传到服务器')}}</el-button>
                <div slot="tip" class="el-upload__tip">{{$t('图像上传类型')}}</div>
              </el-upload>
            </b-form-group>
            <b-form-group v-bind:label="$t('视频')" :label-cols="2" v-if="item.LinkageType==3">
              <el-upload
                :on-success="Uploaded"
                class="upload-demo"
            :action="baseUrl+'/api/Upload/FileUploadSingle'"
                :file-list="item.fileList"
                :data="item.fileData"
                :limit="1"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-upload-="beforeUpload(item.guid)" 
                accept=".mp4"
              >
                <el-button size="small" type="primary">{{$t('上传到服务器')}}</el-button>
                <div slot="tip" class="el-upload__tip">
                  {{$t('MP4上传类型')}}
                  <a :href="baseUrl+'/doc/format.zip'">格式工厂下载</a>
                </div>
              </el-upload>
            </b-form-group>
            <b-form-group v-bind:label="$t('内容')" :label-cols="2" v-if="item.LinkageType==11">
              <b-form-input type="text" v-model="item.Content" required></b-form-input>
            </b-form-group>
            <b-form-group v-bind:label="$t('内容')" :label-cols="2" v-if="item.LinkageType==52||item.LinkageType==54||item.LinkageType==55">
              <b-form-input type="text" v-model="item.Content" required></b-form-input>
            </b-form-group>
            <b-form-group v-bind:label="$t('节目')" :label-cols="2" v-if="item.LinkageType==0">
              <b-form-select
                v-model="item.Content"
                :options="ledResource"
                value-field="Sid"
                text-field="Name"
              ></b-form-select>
            </b-form-group>
            <b-form-group v-bind:label="$t('节目')" :label-cols="2" v-if="item.LinkageType==50">
              <b-form-select
                v-model="item.Content"
                :options="NovaProgs"
                value-field="Sid"
                text-field="name"
              ></b-form-select>
            </b-form-group>
            <b-form-group v-bind:label="$t('音频资源')" :label-cols="2" v-if="item.LinkageType==51">
              <b-form-select
                v-model="item.Content"
                :options="NovaVideos"
                value-field="Sid"
                text-field="Name"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              v-bind:label="$t('音频资源')"
              :label-cols="2"
              v-if="item.LinkageType==53"
            >
              <b-form-select
                v-model="item.Content"
                :options="SPonVideos"
                value-field="Name"
                text-field="Name"
              ></b-form-select>
            </b-form-group>

            <b-form-group v-bind:label="$t('预置点')" :label-cols="2" v-if="item.LinkageType==20">
              <b-form-select
                v-model="item.Content"
                :options="item.Presets"
                value-field="Index"
                text-field="Name"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
      </b-form-group>
      <div style="text-align:center">
        <b-button type="button" style="width:40%" variant="primary" v-on:click="onSubmit">保存</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import { Link } from "@/assets/utils/datef";
import itc from "@/assets/utils/itchttp.js"; 
import config from "@/assets/utils/config";
export default {
  props: ["value", "devsid", "devname", "filter", "car", "alarm"],
  data: function() {
    return {
      baseUrl:config.BaseUrl,
      fileList: [],
      fileData: {},
      link: Link(this.$i18n.locale).filter(function(x) {
        return x.state == 1;
      }),
      choose: {
        radio: null,
        devs: [],
        selected: null
      },
      alarmLamps: [],
      leds: [],
      broads: [],
      resource: [],
      ledResource: [],
      lights: [],
      alarms: [],
      modelValue: {
        LinkageActions: []
      },
      fontSize: [
        { text: this.$t("X1"), value: "1em" },
        { text: this.$t("X2"), value: "2em" },
        { text: this.$t("X3"), value: "3em" },
        { text: this.$t("X4"), value: "4em" },
        { text: this.$t("X5"), value: "5em" }
      ],
      loading: {},
      Presets: [],
      NovaDevices: [],
      NovaProgs: [],
      NovaVideos: [],
      SPonDevices:[],
      SPonVideos:[],
      BoxDevices:[]
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: function() {
        this.modelValue = this.value || {
          LinkageActions: []
        };
        this.modelValue.LinkageActions = this.modelValue.LinkageActions || [];
        var filter = this.modelValue.LinkageActions.filter(
          x => x.LinkageType == 20
        );
        if (filter && filter.length != 0) {
          this.loading = this.$loading();
          this.$ksajax
            .ksPostPromise("/api/device/GetAllVideoPresets", "", true)
            .then(r => {
              this.alarms = r.filter(dd => dd.Presets.length != 0);
              filter.forEach(z => {
                var device = this.alarms.find(o => o.Sid == z.DeviceSid);
                if (device) {
                  z.Presets = device.Presets;
                }
              });
              this.loading.close();
            })
            .catch(err => {
              this.$throw(err);
              this.loading.close();
            });
        }
        this.modelValue.LinkageActions.forEach(x => {
          if (x.LinkageType == 1) {
            if (typeof x.Content != "object") {
              x.Content = JSON.parse(x.Content);
            }
          }
          if (x.LinkageType == 2 || x.LinkageType == 3) {
            x.fileList = [];
            x.fileList.push({ url: x.Content });
          }
        });
      }
    }
  },
  methods: {
    actionChange(val) {
      if ([0, 1, 2, 3, 4].indexOf(val) != -1) {
        if (this.leds.length == 0) {
          let obj = {
            Index: 0,
            Count: 1000
          };
          this.loading = this.$loading();
          this.$ksajax
            .ksPostPromise("/api/device/GetDeviceLedAds", obj, true)
            .then(x => {
              this.leds = x.Items;
              this.choose.devs = x.Items;
              this.choose.selected = this.leds[0].Sid;
              this.loading.close();
              if (this.choose.radio == 0) {
                if (this.ledResource.length == 0) {
                  this.loading = this.$loading();
                  this.$ksajax
                    .ksPostPromise("/api/led/GetLedStage", "", true)
                    .then(success => {
                      this.ledResource = success;
                      this.loading.close();
                    })
                    .catch(err => {
                      this.$throw(err);
                      this.loading.close();
                    });
                }
              }
            })
            .catch(err => {
              this.$throw(err);
              this.loading.close();
            });
        } else {
          this.choose.devs = this.leds;
          this.choose.selected = this.leds[0].Sid;
        }
      } else if ([11, 12, 10].indexOf(val) != -1) {
        this.loading = this.$loading();
        new Promise((res, rej) => {
          if (this.broads.length == 0) {
            let obj = {
              Index: 0,
              Count: 1000
            };
            this.$ksajax
              .ksPostPromise("/api/device/GetDeviceBroadcasts", obj, true)
              .then(x => {
                this.broads = x.Items;
                this.choose.devs = x.Items;
                this.choose.selected = this.broads[0].Sid;
                res();
              })
              .catch(err => {
                rej(err);
              });
          } else {
            this.choose.devs = this.broads;
            this.choose.selected = this.broads[0].Sid;
            res();
          }
        })
          .then(() => {
            return new Promise((res, rej) => {
              if (this.resource.length == 0) {
                let data = {
                  DirId: this.$store.state.project.BroadcastDirId
                };
                itc
                  .postData(
                    this.$store.state.broadcast.url,
                    "MLListDir",
                    data,
                    this.$store.state.broadcast.session
                  )
                  .then(success => {
                    this.resource = success.Items;
                    res();
                  })
                  .catch(error => {
                    rej(error);
                  });
              } else {
                res();
              }
            });
          })
          .then(() => {
            this.loading.close();
          })
          .catch(err => {
            this.$throw(err);
            this.loading.close();
          });
      } else if ([30, 31].indexOf(val) != -1) {
        new Promise((res, rej) => {
          this.loading = this.$loading();
          if (this.alarmLamps.length == 0) {
            if (!this.filter) {
              this.alarmLamps = [
                { Sid: this.modelValue.DeviceSid, DeviceName: "当前水位设备" }
              ];
              res();
            } else {
              let obj = {
                Index: 0,
                Count: 100000
              };
              this.$ksajax
                .ksPostPromise("/api/device/GetWaterLevels", obj, true)
                .then(data => {
                  this.alarmLamps = data.Items;
                  res();
                })
                .catch(err => {
                  rej(err);
                });
            }
          } else {
            res();
          }
        })
          .then(() => {
            this.loading.close();
            this.choose.devs = this.alarmLamps;
            if (this.alarmLamps.length != 0) {
              this.choose.selected = this.alarmLamps[0].Sid;
            }
          })
          .catch(err => {
            this.loading.close();
            this.$throw(err);
          });
      } else if (val == 40) {
        if (this.lights.length == 0) {
          let obj = {
            Method: "read",
            SZ: {
              platform: 1,
              type: 4,
              skip: 0,
              limit: 100000,
              name: ""
            }
          };
          this.loading = this.$loading();
          this.$ksajax
            .ksPostPromise("/api/agent/RequestSzApi", obj, true)
            .then(x => {
              var array = [];
              x.Items.forEach(d => {
                array.push(d.Custom);
              });
              this.lights = array;
              if (this.lights.length == 0) {
                throw "没有发现任何灯具";
              }
              this.choose.devs = array;
              this.choose.selected = this.lights[0].Sid;
              this.loading.close();
            })
            .catch(err => {
              this.$throw(err);
              this.loading.close();
            });
        } else {
          this.choose.devs = this.lights;
          this.choose.selected = this.lights[0].Sid;
        }
      } else if (val == 20) {
        //联动摄像头
        new Promise((res, rej) => {
          this.loading = this.$loading();
          if (this.alarms.length == 0) {
            this.$ksajax
              .ksPostPromise("/api/device/GetAllVideoPresets", "", true)
              .then(data => {
                data.forEach(r => {
                  if (r.Presets.length != 0) {
                    this.alarms.push(r);
                  }
                });
                res();
              })
              .catch(err => {
                rej(err);
              });
          } else {
            res();
          }
        })
          .then(() => {
            this.loading.close();
            if (this.alarms.length == 0) {
              this.$throw("没有设置任何预置点");
            } else {
              this.choose.devs = this.alarms;
              this.choose.selected = this.alarms[0].Sid;
            }
          })
          .catch(err => {
            this.$throw(err);
            this.loading.close();
          });
      } else if (val == 50) {
        new Promise((res, rej) => {
          this.loading = this.$loading();
          this.getNovaDevice()
            .then(() => {
              this.choose.devs = this.NovaDevices;
              this.choose.selected = this.NovaDevices[0].Sid;
              this.getNovaProg()
                .then(() => res())
                .catch(err => {
                  rej(err);
                });
            })
            .catch(err => {
              rej(err);
            });
        })

          .then(() => {
            this.loading.close();
          })
          .catch(err => {
            this.$throw(err);
            this.loading.close();
          });
      } else if (val == 51||val==55) {
        new Promise((res, rej) => {
          this.getNovaDevice().then(() => {
            this.choose.devs = this.NovaDevices;
            this.choose.selected = this.NovaDevices[0].Sid;
            if(val==55){
              res();
            }else{
            this.getNovaVideo()
              .then(() => {
                res();
              })
              .catch(err => rej(err));
            }
          });
        })
          .then(() => {
            this.loading.close();
          })
          .catch(err => {
            this.$throw(err);
            this.loading.close();
          });
      }
      else if(val==52){
        this.getShiBangDevice().then(()=>{
            this.choose.devs = this.SPonDevices;
            this.choose.selected = this.SPonDevices[0].Sid;
        })
      }
      else if(val==53){
        this.getShiBangDevice().then(()=>{
            this.choose.devs = this.SPonDevices;
            this.choose.selected = this.SPonDevices[0].Sid;
            this.getShibangVideo().then(()=>{
              console.log(this.SPonVideos);
            })
        });
      }
      else if(val==54){
        this.getBoxDevice().then(()=>{
          this.choose.devs=this.BoxDevices;
          if(this.choose.devs&&this.choose.devs.length>0&& this.choose.devs[0].Sid){
            this.choose.selected=this.choose.devs[0].Sid;
          }
        })
      }
    },
    getBoxDevice(){
      return new Promise((res, rej) => {
        this.$ksajax
          .ksPostPromise("/api/box/getdevices", "", true)
          .then(data => {
            this.BoxDevices = data;
          console.log(this.items);
            res();
          })
          .catch(err => {
            rej(err);
          });
      });
    },
    getShiBangDevice(){
      var obj={
        PageIndex:1,
        PageCount:1000
      }
      return new Promise((res, rej) => {
        this.$ksajax
          .ksPostPromise("/api/CityBroadcast/Terminal/Local/GetList", obj, true)
          .then(data => {
            console.log(data);
            this.SPonDevices = data.Items;
            res();
          })
          .catch(err => {
            rej(err);
          });
      });
    },
    getShibangVideo() {
      return new Promise((res, rej) => {
        var obj={
          PageIndex:0,
          PageCount:1000,
          Filter:"*"
        }
        this.$ksajax
          .ksPostPromise("/api/CityBroadcast/Media/GetList", obj, true)
          .then(data => {
            this.SPonVideos = data.Rows;
            res();
          })
          .catch(err => {
            rej(err);
          });
      });
    },
    getNovaVideo() {
      return new Promise((res, rej) => {
        this.$ksajax
          .ksPostPromise("/api/nova/GetBroadRes", "", true)
          .then(data => {
            this.NovaVideos = data;
            res();
          })
          .catch(err => {
            rej(err);
          });
      });
    },
    getNovaProg() {
      return new Promise((res, rej) => {
        this.$ksajax
          .ksPostPromise("/api/led/GetNovaProg", "", true)
          .then(data => {
            this.NovaProgs = data;
            if (this.NovaProgs.length == 0) {
              rej("没有发现任何节目");
            }
            res();
          })
          .catch(err => {
            rej(err);
          });
      }); 
    },
    getNovaDevice() {
      return new Promise((res, rej) => {
        if (this.NovaDevices.length != 0) {
          res();
        } else {
          var obj = {
            Index: 0,
            Count: 1000
          };
          this.$ksajax
            .ksPostPromise("/api/led/GetNovaLeds", obj, true)
            .then(data => {
              this.NovaDevices = data.Items;
              if (this.NovaDevices.length == 0) {
                rej("没有发现任何设备");
              }
              res();
            })
            .catch(err => {
              rej(err);
            });
        }
      });
    },
    delItem(guid) {
      var index = -1;
      for (var i = 0; i < this.modelValue.LinkageActions.length; i++) {
        if (this.modelValue.LinkageActions[i].guid == guid) {
          index = i;
          break;
        }
      }
      if (index != -1) {
        this.modelValue.LinkageActions.splice(index, 1);
      }
    },
    addWater() {
      var action = this.link.find(x => x.value == this.choose.radio);
      this.modelValue.LinkageActions = this.modelValue.LinkageActions || [];
      // var exsit=this.modelValue.LinkageActions.find((x)=>x.LinkageType==action.value);
      //   if(exsit){
      //       throw "该阈值已经添加"+action.text;
      //   }
      var obj = {
        Sid: "",
        DeviceSid: this.modelValue.DeviceSid,
        DeviceName: this.modelValue.DeviceName,
        LinkageType: action.value,
        Content: "1",
        link: action,
        guid: this.guid()
      };
      if (
        this.modelValue.LinkageActions.find(
          x => x.DeviceSid == obj.DeviceSid && x.LinkageType == obj.LinkageType
        )
      ) {
        throw "该阈值已经添加了该设备的联动";
      }
      this.modelValue.LinkageActions.push(obj);
    },
    addItem() {
      if (this.fileGuid) {
        var ele = this.modelValue.LinkageActions.find(
          x => x.guid == this.fileGuid
        );
        if (ele && ele.fileList.length == 0) {
          throw "请先上传文件";
        }
      }
      var dev = this.choose.devs.find(x => x.Sid == this.choose.selected);
      var action = this.link.find(x => x.value == this.choose.radio);
      this.modelValue.LinkageActions = this.modelValue.LinkageActions || [];
      //   var exsit=this.modelValue.LinkageActions.find((x)=>x.LinkageType==action.value);
      //   if(exsit){
      //       throw "该阈值已经添加"+action.text;
      //   }
      var obj = {
        Sid: "",
        DeviceSid: dev.Sid,
        DeviceName: dev.DeviceName,
        LinkageType: action.value,
        Content: "",
        link: action,
        guid: this.guid()
      };
      if (action.value == 30 || action.value == 31) {
        obj.Content = "1";
        if (
          this.modelValue.LinkageActions.find(
            x =>
              x.DeviceSid == obj.DeviceSid && x.LinkageType == obj.LinkageType
          )
        ) {
          throw "该阈值已经添加了该设备的联动";
        }
      }
      if (action.value == 20) {
        obj.Presets = dev.Presets;
      }
      if (this.choose.radio == 1) {
        obj.Content = {
          content: "test",
          style: {}
        };
      } else if (this.choose.radio == 2 || this.choose.radio == 3) {
        obj.Content = "";
        obj.fileData = {};
        obj.fileList = [];
      } else if (this.choose.radio == 40) {
        obj.Content = "1";
      }
      //   if(this.modelValue.LinkageActions.find(x=>x.DeviceSid==obj.DeviceSid)){
      //       throw "该阈值已经添加了该设备的联动";
      //   }
      this.modelValue.LinkageActions.push(obj);
    },
    guid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
      }
      return guid;
    },

    handleRemove(file, fileList) {
      var ele = this.modelValue.LinkageActions.find(
        x => x.guid == this.fileGuid
      );
      ele.fileList = fileList;
    },
    handlePreview() {},
    Uploaded(response, file) {
      var ele = this.modelValue.LinkageActions.find(
        x => x.guid == this.fileGuid
      );
      if (response.RetCode == 0) {
        file.url = response.Data;
        ele.fileList.push(file);
        ele.Content = file.url;
      } else {
        throw response.RetMsg;
      }
    },
    beforeUpload(guid) {
      this.fileGuid = guid;
    },
    onSubmit(evn) {
      evn.preventDefault();
      this.loading = this.$loading();
      var d = JSON.parse(JSON.stringify(this.modelValue));
      d.LinkageActions.forEach(x => {
        if (x.LinkageType == 1) {
          x.Content = JSON.stringify(x.Content);
        }
      });
      if (this.car) {
        d.CompareSymbol = 0;
      }
      this.$ksajax
        .ksPostPromise("/api/device/AddSensorLinkage", d, true)
        .then(() => {
          this.loading.close();
          this.$throw("保存成功");
          this.$emit("saved");
        })
        .catch(err => {
          this.loading.close();
          this.$throw(err);
        });
    }
  },
  mounted: function() {
    if (this.filter) {
      this.link = this.link.filter(x => x.value != 31);
    }
  }
};
</script>
