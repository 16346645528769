<template>
  <div class="white">
    <actionbar v-bind:select="true" v-on:select="select"></actionbar>
    <el-table stripe="" class="kstable" :data="items" style="width: 100%" highlight-current-row v-loading="loading" :empty-text="$t('暂无数据')">
      <el-table-column prop="TaskName" :label="this.$t('任务名称')"></el-table-column>
      <el-table-column prop="CreateTime" :label="this.$t('创建时间')"></el-table-column>
      <el-table-column prop="CreateUser" :label="this.$t('操作人')"></el-table-column>
      <el-table-column prop="DeviceSerial" :label="this.$t('设备序列号')"></el-table-column>
      <el-table-column prop="Precent" :label="this.$t('进度')"></el-table-column>
      <el-table-column prop="state" :label="this.$t('状态')"></el-table-column>
    </el-table>
    <pageable v-on:currentChange="currentChange" :perPage="count" :totalPage="total"></pageable>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import pageable from "@/components/pageable";
export default {
    components:{actionbar,pageable},
  data: function() {
    return {
      items: [],
      loading:true,
      total:0,
      index:0,
      count:30,
      currentPage:1
    };
  },
  mounted:function(){
      this.select();
  },
  watch:{
      '$store.state.novaPlayHubChange':function(){
          var data=this.$store.state.novaPlayHub;
          this.items.forEach(x=>{
              if(x.Sid==data.Sid){
                  x.Status=data.Status;
                  x.state=x.Status==0?"发送失败":x.Status==1?"下载中":"成功";
                  x.Precent=data.Precent;
                  x.Process=data.Process
              }
          })
      }
  },
  methods: {
      currentChange(val){
          if(val!=this.currentPage){
              this.index=(val-1)*this.count;
              this.currentPage=val;
              this.select();
          }
      },
    select() {
        this.loading=true;
        var obj={
            Index:this.index,
            Count:this.count
        }
          this.$ksajax
        .ksPostPromise("/api/led/GetNovaLog", obj, true).then(data=>{
            this.total=data.TotalCount;
            data.Items.forEach(x=>{
                x.state=x.Status==0?"发送失败":x.Status==1?"下载中":"成功";
            })
            this.items=data.Items;
            this.loading=false;
        }).catch(err=>{
            this.loading=false;
            this.$throw(err);
        })
    }
  }
};
</script>

