export function GetWeek(array) {
    var weeks = [
        { value: 1, text: "星期一" },
        { value: 2, text: "星期二" },
        { value: 3, text: "星期三" },
        { value: 4, text: "星期四" },
        { value: 5, text: "星期五" },
        { value: 6, text: "星期六" },
        { value: 7, text: "星期天" }
    ];
    if (!array) {
        return weeks;
    } else {
        return weeks.filter(x => {
            return array.find(y => y == x.value);
        });
    }
}
export function GroupArray(groupF, array) {
    var map = {};
    var ret = [];
    array.forEach(x => {
        if (!map[x[groupF]]) {
            map[x[groupF]] = x[groupF];
            ret.push({
                group: x[groupF],
                data: [x]
            });
        } else {
            ret.forEach(y => {
                if (y.group == x[groupF]) {
                    y.data.push(x);
                }
            })
        }
    });
    return ret;
}
export function GetQueryString(url, name) {
    var obj = {};
    var keyvalue = [];
    var key = "",
        value = "";
    var paraString = url.substring(url.indexOf("?") + 1, url.length).split("&");
    for (var i in paraString) {
        keyvalue = paraString[i].split("=");
        key = keyvalue[0];
        value = keyvalue[1];
        obj[key] = value;
    }
    return obj[name];
}
export function GetGuid() {
    var guid = "";
    for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if ((i == 8) || (i == 12) || (i == 16) || (i == 20))
            guid += "-";
    }
    return guid;
}
export function GetResName(value) {
    var types = [
        { text: "文本", value: 0, type: "MultiLineText" },
        { text: "图片", value: 1, type: "Image" },
        { text: "视频", value: 2, type: "Video" },
        { text: "滚动文本", value: 3, type: "SingleLineText" },
        { text: "滚动图片", value: 4, type: "SingleLineText" },
        { text: "传感器数据", value: 5, type: "WebURL" },
        { text: "摄像头", value: 6, type: "LiveVideo" },
        { text: "通用报警图片", value: 7, type: "Image" },
        { text: "灯具亮度数据", value: 8, type: "Light Brightness" }
    ]
    var ret = types.find(x => x.value == value);
    if (ret) {
        return ret;
    } else {
        return null;
    }
}
export function GetNovaResName(value) {
    var types = [
        { text: "文本", value: 0, type: "ARCH_TEXT" },
        { text: "图片", value: 1, type: "PICTURE" },
        { text: "视频", value: 2, type: "VIDEO" },
        { text: "滚动文本", value: 3, type: "ARCH_TEXT" },
        { text: "滚动图片", value: 4, type: "BOX" },
        { text: "传感器数据", value: 5, type: "SIMPLE_RSS" },
        { text: "摄像头", value: 6, type: "STREAM_MEDIA" },
        { text: "通用报警图片", value: 7, type: "PICTURE" },
        { text: "灯具亮度数据", value: 8, type: "Light Brightness" }
    ]
    var ret = types.find(x => x.value == value);
    if (ret) {
        return ret;
    } else {
        return null;
    }
}
export function formatDate(date, fmt) {
    function padLeftZero(str) {
        return ('00' + str).substr(str.length);
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    }
    for (let k in o) {
        let str = o[k] + '';
        if (new RegExp(`(${k})`).test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
}
export function sensorFormat(sensor) {
    var array = [];
    if (!sensor || sensor.Dm || sensor.Dm == 0) {
        array.push({ text: "风向", value: "Dm", pix: "deg", de: 2, val: sensor.Dm, kind: 11 });
    }
    if (!sensor || sensor.Sm || sensor.Sm == 0) {
        array.push({ text: "风速", value: "Sm", pix: "m/s", de: 3, val: sensor.Sm, kind: 14 });
    }
    if (!sensor || sensor.Ta || sensor.Ta == 0) {
        array.push({ text: "温度", value: "Ta", pix: "℃", de: 4, val: sensor.Ta, kind: 16 });
    }
    if (!sensor || sensor.Ua || sensor.Ua == 0) {
        array.push({ text: "湿度", value: "Ua", pix: "%", de: 5, val: sensor.Ua, kind: 17 });
    }
    if (!sensor || sensor.Pa || sensor.Pa == 0) {
        array.push({ text: "气压", value: "Pa", pix: "hPa", de: 6, val: sensor.Pa, kind: 18 });
    }
    if (!sensor || sensor.Rc || sensor.Rc == 0) {
        array.push({ text: "降雨量", value: "Rc", pix: "mm", de: 7, val: sensor.Rc, kind: 21 });
    }
    if (!sensor || sensor.Ns || sensor.Ns == 0) {
        array.push({ text: "噪声", value: "Ns", pix: "dB", de: 13, val: sensor.Ns, kind: 26 });
    }
    if (!sensor || sensor.PM2_5 || sensor.PM2_5 == 0) {
        array.push({ text: "PM2.5", value: "PM2_5", pix: "μg/m³", de: 14, val: sensor.PM2_5, kind: 28 });
    }
    if (!sensor || sensor.PM10 || sensor.PM10 == 0) {
        array.push({ text: "PM10", value: "PM10", pix: "μg/m³", de: 15, val: sensor.PM10, kind: 29 });
    }

    if (!sensor || sensor.Wl || sensor.Wl == 0) {
        array.push({ text: "水位", value: "Wl", pix: "mm", de: 16, val: sensor.Wl, kind: 30 });
    }
    if (!sensor || sensor.Ag || sensor.Ag == 0) {
        array.push({ text: "角度", value: "Ag", pix: "deg", de: 17, val: sensor.Ag, kind: 31 });
    }
    if (!sensor || sensor.Ss || sensor.Ss == 0) {
        array.push({ text: "车位状态", value: "Ss", pix: "", de: 18, val: sensor.Ss, kind: 32 });
    }
    if (!sensor || sensor.Srt || sensor.Srt==0){
        array.push({ text: "敏感词", value: "Srt", pix: "", de: "", val: sensor.Srt, kind: 33 });
    }
    return array;
}
export function getJson(str) {
    try {
        var obj = JSON.parse(str);
        if (typeof (obj) == "object") {
            return obj;
        }
        else {
            return str;
        }
    }
    catch (err) {
        return str;
    }
}
export function getInAnimates(isIn) {
    if (isIn) {
        return [
            { text: "反弹", value: "bounceIn" },
            { text: "渐入", value: "fadeIn" },
            { text: "从左渐入", value: "slideInLeft" },
            { text: "从右渐入", value: "slideInRight" },
            { text: "从下渐入", value: "slideInDown" },
            { text: "从上渐入", value: "slideInUp" },
            { text: "水平翻转", value: "flipInX" },
            { text: "垂直翻转", value: "flipInY" },
            { text: "速度模拟", value: "lightSpeedIn" },
            { text: "旋转", value: "rotateIn" },
            { text: "缩放", value: "zoomIn" },
        ];
    } else {
        return [
            { text: "反弹", value: "bounceOut" },
            { text: "渐出", value: "fadeOut" },
            { text: "从左渐出", value: "slideOutLeft" },
            { text: "从右渐出", value: "slideOutRight" },
            { text: "从下渐出", value: "slideOutDown" },
            { text: "从上渐出", value: "slideOutUp" },
            { text: "水平翻转", value: "flipOutX" },
            { text: "垂直翻转", value: "flipOutY" },
            { text: "速度模拟", value: "lightSpeedOut" },
            { text: "旋转", value: "rotateOut" },
            { text: "缩放", value: "zoomOut" },
        ];
    }

}
export function Link(isEn) {
    if (isEn != "en") {
        return [
            { value: 0, text: "广告屏播放节目", en: "Programming", state: 1 },
            { value: 1, text: "广告屏显示文本", en: "Display text", state: 0 },
            { value: 2, text: "广告屏显示图片", en: "Display picture", state: 0 },
            { value: 3, text: "广告屏播放视频", en: "Video view", state: 0 },
            { value: 4, text: "广告屏显示摄像头画面", en: "Display Camera", state: 0 },
            { value: 10, text: "广播设备播放节目", en: "Audio Program", state: 1 },
            { value: 11, text: "广播设备播放文本合成的音频", en: "Audio text-synthesized", state: 1 },
            { value: 12, text: "广播设备播放 MP3 音频", en: "Audio MP3", state: 0 },
            { value: 20, text: "联动附近指定摄像头", en: "Linkage near specified camera", state: 1 },
            { value: 30, text: "警示灯发出警报声音", en: "Warning light sounds an alarm", state: 1 },
            { value: 31, text: "水泵抽水", en: "Pumping water", state: 1 },
            { value: 40, text: "控制灯具", en: "Control Lamp", state: 1 },
            { value: 50, text: "诺瓦显示屏播放节目", en: "Nova Led programming", state: 1 },
            { value: 51, text: "诺瓦显示屏播放音频", en: "Nova Led  programming", state: 1 },
            { value: 55, text: "诺瓦显示屏播放文本", en: "Nova Led  Text programming", state: 1 },
            { value: 52, text: "世邦音柱播放文本音频", en: "Audio text-synthesized", state: 1 },
            { value: 53, text: "世邦音柱播放MP3音频", en: "Audio MP3", state: 1 },
            { value: 54, text: "多箱合一主锁开", en: "Box Main Lock", state: 1 },
        ]

    } else {

        return [
            { value: 0, zh: "广告屏播放节目", text: "Programming", state: 1 },
            { value: 1, zh: "广告屏显示文本", text: "Display text", state: 0 },
            { value: 2, zh: "广告屏显示图片", text: "Display picture", state: 0 },
            { value: 3, zh: "广告屏播放视频", text: "Video view", state: 0 },
            { value: 4, zh: "广告屏显示摄像头画面", text: "Display Camera", state: 0 },
            { value: 10, zh: "广播设备播放节目", text: "Audio Program", state: 1 },
            { value: 11, zh: "广播设备播放文本合成的音频", text: "Audio text-synthesized", state: 1 },
            { value: 12, zh: "广播设备播放 MP3 音频", text: "Audio MP3", state: 0 },
            { value: 20, zh: "联动附近指定摄像头", text: "Linkage near specified camera", state: 1 },
            { value: 30, zh: "警示灯发出警报声音", text: "Warning light sounds an alarm", state: 1 },
            { value: 31, zh: "水泵抽水", text: "Pumping water", state: 1 },
            { value: 40, zh: "控制灯具", text: "Control Lamp", state: 1 },
            { value: 50, text: "诺瓦显示屏播放节目", en: "Nova Led programming", state: 1 },
            { value: 51, text: "诺瓦显示屏播放音频", en: "Nova Led Audio programming", state: 1 },
            { value: 55, text: "诺瓦显示屏播放文本", en: "Nova Led  Text programming", state: 1 },
            { value: 52, text: "世邦音柱播放文本音频", en: "Audio text-synthesized", state: 1 },
            { value: 53, text: "世邦音柱播放MP3音频", en: "Audio MP3", state: 1 },
            { value: 54, text: "多箱合一主锁开", en: "Box Main Lock", state: 1 },
        ]
    }
}
export function AlarmTypes() {
    return [
        { value: 0, text: "设备离线", data: 0 },
        { value: 1, text: "设备上线", data: 0 },
        { value: 2, text: "设备故障", data: 0 },
        { value: 3, text: "设备通信故障", data: 0 },
        { value: 4, text: "设备意外打开", data: 0 },
        { value: 5, text: "设备意外关闭", data: 0 },
        { value: 6, text: "设备漏电", data: 0 },
        { value: 7, text: "设备被盗", data: 0 },
        { value: 8, text: "超出量程", data: 0 },
        { value: 9, text: "数据异常", data: 0 },
        { value: 20, text: "移动物体检测结束", data: 0 },
        { value: 21, text: "检测到移动物体", data: 0 },
        { value: 22, text: "越界检测结束", data: 0 },
        { value: 23, text: "检测到越界", data: 0 },
        { value: 24, text: "紧急呼叫求助恢复", data: 0 },
        { value: 25, text: "紧急呼叫求助告警", data: 0 },
        { value: 26, text: "业务咨询结束", data: 0 },
        { value: 27, text: "业务咨询开始", data: 0 },
        { value: 30, text: "人形检测结束", data: 0 },
        { value: 31, text: "检测到人形", data: 0 },
        { value: 32, text: "人脸检测结束", data: 0 },
        { value: 33, text: "检测到人脸", data: 0 },
        { value: 34, text: "人脸识别结束", data: 0 },
        { value: 35, text: "识别到人脸", data: 0 },
        { value: 40, text: "车辆检测结束", data: 0 },
        { value: 41, text: "检测到车辆", data: 0 },
        { value: 42, text: "车牌识别结束", data: 0 },
        { value: 43, text: "识别到车辆", data: 0 },
        { value: 44, text: "车位空闲", data: 0 },
        { value: 45, text: "车位被占用", data: 0 },
        { value: 50, text: "亮度低于阈值", data: 1 },
        { value: 51, text: "亮度到达阈值", data: 1 },
        { value: 52, text: "亮度高于阈值", data: 1 },
        { value: 53, text: "温度低于阈值", data: 1 },
        { value: 54, text: "温度到达阈值", data: 1 },
        { value: 55, text: "温度高于阈值", data: 1 },
        { value: 56, text: "湿度低于阈值", data: 1 },
        { value: 57, text: "湿度到达阈值", data: 1 },
        { value: 58, text: "湿度高于阈值", data: 1 },
        { value: 59, text: "风速低于阈值", data: 1 },
        { value: 60, text: "风速到达阈值", data: 1 },
        { value: 61, text: "风速高于阈值", data: 1 },
        { value: 62, text: "气压低于阈值", data: 1 },
        { value: 63, text: "气压到达阈值", data: 1 },
        { value: 64, text: "气压高于阈值", data: 1 },
        { value: 65, text: "降雨量低于阈值", data: 1 },
        { value: 66, text: "降雨量到达阈值", data: 1 },
        { value: 67, text: "降雨量高于阈值", data: 1 },
        { value: 68, text: "太阳辐射弱于阈值", data: 1 },
        { value: 69, text: "太阳辐射到达阈值", data: 1 },
        { value: 70, text: "太阳辐射强于阈值", data: 1 },
        { value: 71, text: "紫外强度弱于阈值", data: 1 },
        { value: 72, text: "紫外强度到达阈值", data: 1 },
        { value: 73, text: "紫外强度强于阈值", data: 1 },
        { value: 74, text: "噪声低于阈值", data: 1 },
        { value: 75, text: "噪声到达阈值", data: 1 },
        { value: 76, text: "噪声大于阈值", data: 1 },
        { value: 77, text: "PM2.5 颗粒物浓度低于阈值", data: 1 },
        { value: 78, text: "PM2.5 颗粒物浓度到达阈值", data: 1 },
        { value: 79, text: "PM2.5 颗粒物浓度高于阈值", data: 1 },
        { value: 80, text: "PM10 颗粒物浓度低", data: 1 },
        { value: 81, text: "PM10 颗粒物浓度到达指定值", data: 1 },
        { value: 82, text: "PM10 颗粒物浓度高于阈值", data: 1 },
        { value: 83, text: "水位低于阈值", data: 1 },
        { value: 84, text: "水位到达阈值", data: 1 },
        { value: 85, text: "水位高于阈值", data: 1 },
        { value: 86, text: "角度小于阈值", data: 1 },
        { value: 87, text: "角度到达阈值", data: 1 },
        { value: 88, text: "角度大于阈值", data: 1 },
        { value: 89, text: "CH2O 浓度低于阈值", data: 0 },
        { value: 90, text: "CH2O 浓度到达阈值", data: 0 },
        { value: 91, text: "CH2O 浓度高于阈值", data: 0 },
        { value: 92, text: "TVOC 浓度低于阈值", data: 0 },
        { value: 93, text: "TVOC 浓度到达阈值", data: 0 },
        { value: 94, text: "TVOC 浓度高于阈值", data: 0 },
        { value: 95, text: "CO2 浓度低于阈值", data: 0 },
        { value: 96, text: "CO2 浓度到达阈值", data: 0 },
        { value: 97, text: "CO2 浓度高于阈值", data: 0 },
        { value: 98, text: "CO 浓度低于阈值", data: 0 },
        { value: 99, text: "CO 浓度到达阈值", data: 0 },
        { value: 100, text: "CO 浓度高于阈值", data: 0 },
        { value: 101, text: "SO2 浓度低于阈值", data: 0 },
        { value: 102, text: "SO2 浓度到达阈值", data: 0 },
        { value: 103, text: "SO2 浓度高于阈值", data: 0 },
        { value: 104, text: "NO2 浓度低于阈值", data: 0 },
        { value: 105, text: "NO2 浓度到达阈值", data: 0 },
        { value: 106, text: "NO2 浓度高于阈值", data: 0 },
        { value: 107, text: "O3 浓度低于阈值", data: 0 },
        { value: 108, text: "O3 浓度到达阈值", data: 0 },
        { value: 109, text: "O3 浓度高于阈值", data: 0 },
        { value: 110, text: "NO 浓度低于阈值", data: 0 },
        { value: 111, text: "NO 浓度到达阈值", data: 0 },
        { value: 112, text: "NO 浓度高于阈值", data: 0 },
        { value: 113, text: "H2S 浓度低于阈值", data: 0 },
        { value: 114, text: "H2S 浓度到达阈值", data: 0 },
        { value: 115, text: "H2S 浓度高于阈值", data: 0 },
        { value: 116, text: "H2 浓度低于阈值", data: 0 },
        { value: 117, text: "H2 浓度到达阈值", data: 0 },
        { value: 118, text: "H2 浓度高于阈值", data: 0 },
        { value: 119, text: "NH3 浓度低于阈值", data: 0 },
        { value: 120, text: "NH3 浓度到达阈值", data: 0 },
        { value: 121, text: "NH3 浓度高于阈值", data: 0 },
        { value: 200, text: "电流低于阈值", data: 1 },
        { value: 202, text: "电流高于阈值", data: 1 },
        { value: 203, text: "电压低于阈值", data: 1 },
        { value: 205, text: "电压高于阈值", data: 1 },
        { value: 206, text: "功率低于阈值", data: 1 },
        { value: 208, text: "功率高于阈值", data: 1 },
        { value: 210, text: "功率因数低", data: 0 },
        { value: 213, text: "电源功率损耗", data: 0 },
        { value: 220, text: "总电流低", data: 0 },
        { value: 222, text: "总电流高", data: 0 },
        { value: 223, text: "电源电压低", data: 0 },
        { value: 225, text: "电源电压高", data: 0 },
        { value: 226, text: "总功率低", data: 0 },
        { value: 228, text: "总功率高", data: 0 },
        { value: 229, text: "总功率因数低", data: 0 },
        { value: 240, text: "相1电流低", data: 0 },
        { value: 242, text: "相1电流高", data: 0 },
        { value: 243, text: "相1电压低", data: 0 },
        { value: 245, text: "相1电压高", data: 0 },
        { value: 246, text: "相1有功功率低", data: 0 },
        { value: 248, text: "相1有功功率高", data: 0 },
        { value: 249, text: "相1功率因数低", data: 0 },
        { value: 260, text: "相2电流低", data: 0 },
        { value: 262, text: "相2电流高", data: 0 },
        { value: 263, text: "相2电压低", data: 0 },
        { value: 265, text: "相2电压高", data: 0 },
        { value: 266, text: "相2有功功率低", data: 0 },
        { value: 268, text: "相2有功功率高", data: 0 },
        { value: 269, text: "相2功率因数低", data: 0 },
        { value: 280, text: "相3电流低", data: 0 },
        { value: 282, text: "相3电流高", data: 0 },
        { value: 283, text: "相3电压低", data: 0 },
        { value: 285, text: "相3电压高", data: 0 },
        { value: 286, text: "相3有功功率高", data: 0 },
        { value: 288, text: "相3有功功率高", data: 0 },
        { value: 289, text: "相3功率因数低", data: 0 },
        { value: 302, text: "中性线电流高", data: 0 },
        { value: 400, text: "超过预设月份能耗", data: 0 },
        { value: 401, text: "超过年度能耗比例", data: 0 },
        { value: 500, text: "调度任务执行失败", data: 0 },
        { value: 704, text: "抽烟识别", data: 0 },
        { value: 705, text: "摔倒识别", data: 0 },
        { value: 1000, text: "未知告警", data: 0 }
    ]
}

export function GetNovaInmation() {
    return [
        { value: "NONE", text: "无特效" },
        { value: "RANDOM", text: "随机动画" },
        { value: "LEFT_EXPAND_IN", text: "从左侧扩展进入" },
        { value: "TOP_EXPAND_IN", text: "从顶部扩展进入" },
        { value: "RIGHT_EXPAND_IN", text: "从右侧扩展进入" },
        { value: "BOTTOM_EXPAND_IN", text: "从底部扩展进入" },
        { value: "MIDDLE_EXPAND", text: "从中间向外扩展" },
        { value: "MIDDLE_TO_HORIZONTAL_EXPAND", text: "水平方向扩展" },
        { value: "MIDDLE_TO_VERTICAL_EXPAND", text: "垂直方向扩展" },
        { value: "LEFT_MOVE_IN", text: "从左侧移动进入" },
        { value: "TOP_MOVE_IN", text: "从顶部移动进入" },
        { value: "RIGHT_MOVE_IN", text: "从右侧移动进入" },
        { value: "BOTTOM_MOVING_IN", text: "从底部移动进入" },
        { value: "LEFT_SHRINK_IN", text: "从左侧缩进" },
        { value: "TOP_SHRINK_IN", text: "从顶部缩进" },
        { value: "RIGHT_SHRINK_IN", text: "从右侧缩进" },
        { value: "BOTTOM_SHRINK_IN", text: "从底部缩进" },
        { value: "VERTICAL_SHRINK", text: "从上下向中间缩进" },
        { value: "HORIZONTAL_SHRINK_IN", text: "从左右向中间缩进" },
        { value: "SCROLL_UNROLLING_FROM_UP", text: "从顶部向下滚动铺展" },
        { value: "SCROLL_UNROLLING_FROM_BOTTOM", text: "从底部向上滚动铺展" },
        { value: "SHUT_DOOR_HORIZONTAL", text: "水平方向关门" },
        { value: "SHUT_DOOR_VERTICAL", text: "垂直方向关门" },
        { value: "SHUTTER_DOWN", text: "下百叶窗" },
        { value: "SHUTTER_RIGHT", text: "右百叶窗" },
        { value: "SPECIAL_ZOOM", text: "变焦全屏" },
        { value: "TILING", text: "中文名称暂时未定" },
        { value: "RADAR", text: "雷达" },
        { value: "VERTICAL_CROSS_MERGE", text: "上下齿合" },
        { value: "FADE_IN", text: "淡入" },
        { value: "FADE_OUT", text: "淡出" },
        { value: "STACKING_FROM_LEFT", text: "从左向右堆积" },
        { value: "STACKING_FROM_UP", text: "从上向下堆积" },
        { value: "STACKING_FROM_RIGHT", text: "从右向左堆积" },
        { value: "STACKING_FROM_DOWN", text: "从下向上堆积" },
        { value: "LASER_LEFT", text: "左镭射" },
        { value: "LASER_TOP", text: "上镭射" },
        { value: "LASER_RIGHT", text: "右镭射" },
        { value: "LASER_BOTTOM", text: "下镭射" },
        { value: "MARQUEE_LEFT", text: "向左滚动" },
        { value: "MARQUEE_UP", text: "向上滚动" },
        { value: "MARQUEE_RIGHT", text: "向右滚动" },
        { value: "MARQUEE_DOWN", text: "向下滚动" },
        { value: "REVEAL_RENDER_TO_BOTTOM", text: "向下展开" },
        { value: "REVEAL_RENDER_TO_UP", text: "向上展开" },
        { value: "REVEAL_RENDER_MIDDLE_TO_VERTICAL", text: "中间向上下展开" },
        { value: "REVEAL_RENDER_VERTICAL_TO_MIDDLE", text: "上下向中间展开" }

    ]
}