<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
    >
    </actionbar>

    <roadStudPage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></roadStudPage>

    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form>
        <b-form-group :label="$t('灯具设备')" label-cols="2">
          <b-form-select
            :options="lampDevices"
            v-model="lampDeviceSid"
            :disabled="sid === '' ? false : true"
          ></b-form-select>
        </b-form-group>
        <b-form-group :label="$t('视频设备')" label-cols="2">
          <b-form-select
            :options="videoDevices"
            v-model="videoDeviceSid"
          ></b-form-select>
        </b-form-group>

        <b-form-group :label="$t('工作模式')" label-cols="2">
          <b-form-select
            :options="modeArray"
            v-model="modeSelected"
          ></b-form-select>
        </b-form-group>

        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button
              style="width: 100px"
              variant="secondary"
              v-on:click="hiden"
              >{{ $t("取消") }}</b-button
            >
            <b-button
              type="button"
              style="width: 100px"
              variant="primary"
              v-on:click="onSubmit"
              >{{ $t("确认") }}</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>

    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/roadstud/Delete"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import deletedata from "@/components/deletedata";
import roadStudPage from "./components/roadStudPage.vue";

export default {
  components: { actionbar, roadStudPage, deletedata },
  data: function () {
    return {
      loading: {},
      cols: [
        { name: this.$t("设备名称"), value: "DeviceName" },
        { name: this.$t("工作模式"), value: "ModeName" },
        { name: this.$t("视频设备"), value: "DeviceVideoName" },
        { name: this.$t("验证码"), value: "Code" },
        { name: this.$t("摄像头序列号"), value: "DeviceVideoSerial" },
        { name: this.$t("Ip地址"), value: "Ip" },
        { name: this.$t("端口"), value: "Port" },
        { name: this.$t("用户名"), value: "Username" },
        { name: this.$t("密码"), value: "Password" },
      ],
      items: [],
      total: 0,
      count: 6,
      index: 0,
      sid: "",
      title: "",
      current: null,
      lampDevices: [],
      lampDeviceSid: "",
      videoDevices: [],
      videoDeviceSid: "",
      modeArray: [
        { value: 0, text: "关闭" },
        { value: 1, text: "闪烁" },
        { value: 2, text: "常亮" },
        { value: 3, text: "强闪烁" },
      ],
      modeSelected: 0,
    };
  },
  methods: {
    selectLight: function () {
      let obj = {
        Method: "read",
        SZ: {
          platform: 1,
          type: 4,
          skip: 0,
          limit: 1000000,
          name: "",
        },
        Custom: {
          Index: 0,
          Count: 10000,
        },
      };
      this.$ksajax
        .ksPostPromise("/api/agent/RequestSzApi", obj, true)
        .then((data) => {
          console.log("datatt:", data);
          this.lampDevices = [];
          data.Items.forEach((element) => {
            const dist = {};
            dist.value = element.Custom.Sid;
            dist.text = element.Custom.DeviceName;
            this.lampDevices.push(dist);
          });
          console.log("lampDevices:", this.lampDevices);
        })
        .catch((err) => {
          this.$throw(err);
        });
    },
    selectVideo: function () {
      let req = {
        Index: 0,
        Count: 10000,
      };
      this.$ksajax.ksPostData(
        "/api/device/GetDeviceVideos",
        req,
        true,
        (data) => {
          console.log("datav:", data);
          this.videoDevices = [];
          data.Items.forEach((element) => {
            const dist = {};
            dist.value = element.Sid;
            dist.text = element.DeviceName;
            this.videoDevices.push(dist);
          });
          console.log("videoDevices:", this.videoDevices);
        },
        () => {},
        false
      );
    },
    select() {
      this.loading = this.$loading({ fullScreen: true });

      let obj = {
        Index: this.index,
        Count: this.count,
      };
      this.$ksajax
        .ksPostPromise("/api/roadstud/GetRoadStudList", obj, true)
        .then((data) => {
          console.log("lid:", data);
          this.items = data.Items;
          this.items.forEach((element) => {
            if (element.Mode === 0) {
              element.ModeName = "关闭";
            } else if (element.Mode === 1) {
              element.ModeName = "闪烁";
            } else if (element.Mode === 2) {
              element.ModeName = "常亮";
            } else if (element.Mode === 3) {
              element.ModeName = "强闪烁";
            }
          });
          this.total = data.TotalCount;
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }

      this.sid = this.current.Sid;
      this.lampDeviceSid = this.current.Sid;
      this.videoDeviceSid = this.current.DeviceVideoSid;
      this.modeSelected = parseInt(this.current.Mode);
      this.title = this.$t("修改");
      this.$refs.modallg.show();
    },
    insert() {
      this.title = this.$t("新增");
      this.sid = "";
      this.lampDeviceSid = "";
      this.videoDeviceSid = "";
      this.modeSelected = 0;
      this.$refs.modallg.show();
    },
    tableCreated() {},
    currentPage(val) {
      this.index = (val - 1) * this.count;
      this.select();
    },
    currentRow(val) {
      this.current = val;
    },
    onSubmit(evt) {
      console.log("submit");
      evt.preventDefault();

      if (this.sid !== "") {
        if (!this.videoDeviceSid) {
          this.$throw(this.$t("请把资料填写完整"), this);
          return;
        }
        this.loading = this.$loading({ fullScreen: true });
        this.$ksajax
          .ksPostPromise(
            "/api/roadstud/Update",
            {
              sid: this.sid,
              DeviceVideoSid: this.videoDeviceSid,
              Mode: parseInt(this.modeSelected),
            },
            true
          )
          .then(() => {
            this.select();
            this.loading.close();
            this.hiden();
          })
          .catch((err) => {
            this.loading.close();
            this.$throw(err);
          });
      } else {
        if (!this.lampDeviceSid || !this.videoDeviceSid) {
          this.$throw(this.$t("请把资料填写完整"), this);
          return;
        }
        this.loading = this.$loading({ fullScreen: true });
        this.$ksajax
          .ksPostPromise(
            "/api/roadstud/Add",
            {
              DeviceLampSid: this.lampDeviceSid,
              DeviceVideoSid: this.videoDeviceSid,
              Mode: parseInt(this.modeSelected),
            },
            true
          )
          .then(() => {
            this.select();
            this.loading.close();
            this.hiden();
          })
          .catch((err) => {
            this.loading.close();
            this.$throw(err);
          });
      }
    },

    deleted() {
      this.select();
    },
    hiden() {
      this.$refs.modallg.hide();
    },
  },
  mounted: function () {
    this.select();
    this.selectLight();
    this.selectVideo();
  },
};
</script>
