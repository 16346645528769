<template>
    <div>
        <b-pagination 
        :size="size" 
        :total-rows="totalPage"
         v-model="currentPage" 
         :per-page="perPage"
          class="pagin" 
          :align="align"
      :first-text="$t('首页')"
      :prev-text="$t('上一页')"
      :next-text="$t('下一页')"
      :last-text="$t('最后一页')"
          @change="change"
          ></b-pagination>
    </div>
</template>
<script>
export default {
    props:{
        totalPage:{
            defalut:30,
            type:Number
        },
        size:{
            type:String,
            defalut:"md"
        },
        perPage:{
            defalut:30,
            type:Number
        },
        align:{
            type:String,
            defalut:"right"
        }
    },
    data:function(){
        return{
            currentPage:1
        }
    },
    methods:{
        change(val){
            this.$emit("currentChange",val);
        }
    }
}
</script>

