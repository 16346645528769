<template>
    <div class="white stand" style="height:100%">
          <el-table
          ref="multipleTable"
          :data="items"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="name" label="设备名称"></el-table-column>
          <el-table-column prop="addr" label="设备地址"></el-table-column>
           <el-table-column prop="online" label="在线状态">
            <template slot-scope="scope">
              <i :class="scope.row.online==1?'fa fa-signal stateOn':'fa fa-signal stateOff'"></i>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="设备类型"></el-table-column>
          <el-table-column prop="model" label="设备型号"></el-table-column>
          <el-table-column prop="gateway" label="所处网关"></el-table-column>
          <el-table-column prop="cvalue" label="漏电电流"></el-table-column>
          <el-table-column prop="mtime" label="上报时间"></el-table-column>
          </el-table>
    </div>
</template>
<script>
export default {
    data(){
        return {
            items:[]
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        getData(){
            this.items=[
                {
                name:"围面前路漏电监测",
                online:1,
                addr:"0001",
                mtime:"2022/07/07",
                type:"勤上智能漏电监测仪",
                model:"KS-LD003",
                gateway:"围面前路网关1号",
                cvalue:"0.7A"
            },
                {
                name:"工业三路路头漏电监测",
                online:1,
                addr:"0001",
                mtime:"2022/07/07",
                type:"勤上智能漏电监测仪",
                model:"KS-LD003",
                gateway:"工业三路网关2号",
                cvalue:"0.9A"
            },
                {
                name:"工业三路路尾漏电监测",
                online:1,
                addr:"0001",
                mtime:"2022/07/07",
                type:"勤上智能漏电监测仪",
                model:"KS-LD003",
                gateway:"工业三路网关5号",
                cvalue:"0.5A"
            },
                {
                name:"常平中心小学校门口漏电监测",
                online:1,
                addr:"0001",
                mtime:"2022/07/07",
                type:"勤上智能漏电监测仪",
                model:"KS-LD003",
                gateway:"常平中心小学网关",
                cvalue:"1.2A"
            },
                {
                name:"勤上光电门口漏电监测仪",
                online:1,
                addr:"0001",
                mtime:"2022/07/07",
                type:"勤上智能漏电监测仪",
                model:"KS-LD003",
                gateway:"勤上光电网关1号",
                cvalue:"0.7A"
            },
                {
                name:"篮球场漏电监测仪",
                online:1,
                addr:"0001",
                mtime:"2022/07/07",
                type:"勤上智能漏电监测仪",
                model:"KS-LD003",
                gateway:"勤上光电网关1号",
                cvalue:"0.9A"
            },
                {
                name:"社区漏电监测仪",
                online:1,
                addr:"0001",
                mtime:"2022/07/07",
                type:"勤上智能漏电监测仪",
                model:"KS-LD003",
                gateway:"围面前路网关1号",
                cvalue:"0.9A"
            },
                {
                name:"员工宿舍漏电监测仪",
                online:1,
                addr:"0001",
                mtime:"2022/07/07",
                type:"勤上智能漏电监测仪",
                model:"KS-LD003",
                gateway:"勤上光电网关2号",
                cvalue:"0.25A"
            }
            ]
        }
    }
}
</script>