<template>
    <div class="mtimmer">
        <b-form ref="form" @submit="onSubmit">
        <b-form-group v-if="!insert"
                    horizontal
                    label="Id"
                    label-for="form-id">
        <b-form-input id="form-id"
                      type="text"
                      v-model="form.ID"
                      readonly
                      placeholder="Enter email">
        </b-form-input>
        </b-form-group> 
        <b-form-group :label="$t('名称')" label-for="form-name">
            <b-form-input id="form-name" type="text" required v-model="form.Name"></b-form-input>
        </b-form-group>
        
        <b-form-group :label="$t('播放音量')" label-for="form-PlayVol">
            <b-form-input id="form-PlayVol" type="number" required v-model="form.PlayVol"></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('开始时间')" label-for="form-name">
            <el-date-picker required  v-model="form.StartTime" type="date" :placeholder="$t('日期')"></el-date-picker>
             <el-time-picker required v-model="form.StartTime" :placeholder="$t('日期')"></el-time-picker>
        </b-form-group>
        
        <b-form-group :label="$t('播放时长')" label-for="radios1">
            <b-form-radio-group id="radios1" v-model="MethodType" :options="Methods" name="radioOpenions"></b-form-radio-group>
                 <b-form-input v-if='MethodType==0'  id="form-RepeatTime" type="number" required v-model="form.RepeatTime" :placeholder='$t("请输入重复次数")'></b-form-input>
                <b-form-input v-if='MethodType==1'  id="form-Length" type="number" required v-model="form.Length" :placeholder='$t("请输入节目时长")'></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('播放方式')" label-for="form-PlayMode">
            <b-form-radio-group id="form-PlayMode" required v-model="form.PlayMode" :options="PlaymModel" name="form-PlayMode"></b-form-radio-group>
        </b-form-group>

        <b-form-group :label="$t('启用状态')" label-for="form-Enable">
            <b-form-radio-group id="form-Enable" required v-model="form.Enable" :options="Enable" name="form-Enable"></b-form-radio-group>
        </b-form-group>

        <!-- <b-form-group :label="$t('播放状态')" label-for="form-Status">
            <b-form-radio-group id="form-Status" v-model="form.Status" :options="Status" name="form-Status"></b-form-radio-group>
        </b-form-group> -->
        
        <b-form-group :label="$t('定时终端')" label-for="form-Tids">
            <b-form-select id="form-Tids" required  multiple :select-size="4" v-model="form.Tids" :options="$store.getters.getBroadById()"></b-form-select>
        </b-form-group>
        <b-form-group :label="$t('播放文件')" label-for="form-ProgIds">
            <b-form-select id="form-ProgIds" required  multiple :select-size="4" v-model="form.ProgIds" :options="$store.getters.getMediaById()"></b-form-select>
        </b-form-group>

        <b-form-group :label="$t('类型')" label-for="form-type">
            <b-form-radio-group id="form-Type" required v-model="form.Type" :options="Types" name="form-Type"></b-form-radio-group>
            <div v-if="form.Type==1" >
                    <b-form-group :label='$t("相隔天数")' label-for="form-EveryDay">
                        <b-form-input  id="form-EveryDay" type="number" required v-model="form.DayItem.Every" :placeholder='$t("相隔天数")'></b-form-input>
                    </b-form-group>
            </div>
            <div v-if="form.Type==2">
                <b-form-checkbox-group id="checkboxes1" name="flavour1" v-model="form.WeekItem.DaysInWeek" :options="Weeks"></b-form-checkbox-group>
                    <b-form-group :label='$t("相隔星期")' label-for="form-EveryDay1">
                            <b-form-input  id="form-EveryDay1" type="number" required v-model="form.WeekItem.Every" :placeholder='$t("相隔星期")'></b-form-input>
                    </b-form-group>
            </div>
            <div  v-if="form.Type==3">
                <b-form-checkbox-group id="checkboxes2" name="flavour2" v-model="form.MonthItem.MonthsInYear" :options="Moths"></b-form-checkbox-group>
                    <b-form-group horizontal  :label="$t('每月的第几天')" label-for="form-DayInMonth">
                            <b-form-input  id="form-DayInMonth" type="number" required v-model="form.MonthItem.DayInMonth[0]" :placeholder="$t('每月的第几天')"></b-form-input>
                    </b-form-group>
            </div>
        </b-form-group>
         <b-form-group>
             
          <b-button type="submit" variant="primary" style="width:100%">{{$t('提交')}}</b-button>
         </b-form-group>
        </b-form>
    </div> 
</template>
<script>
import {formatDate} from '@/assets/utils/datef.js' 
export default {
    props:['form',"insert","MethodsSelected"],
    watch:{
        "MethodsSelected":function(){
            this.MethodType=this.MethodsSelected;
        }
    },
    data:function(){
        return{
            Moths:[
                {value:1, text: this.$t("一月")},
                {value:2, text: this.$t("二月")},
                {value:3,text: this.$t("三月")},
                {value:4,text:this.$t("四月")},
                {value:5,text:this.$t("五月")},
                {value:6,text:this.$t("六月")},
                {value:7,text:this.$t("七月")},
                {value:8,text:this.$t("八月")},
                {value:9,text:this.$t("九月")},
                {value:10,text:this.$t("十月")},
                {value:11,text:this.$t("十一月")},
                {value:12,text:this.$t("十二月")}
            ],
            Weeks:[
                 { value:1, text: this.$t("星期一")},
                 { value:2, text: this.$t("星期二")},
                 { value:3, text: this.$t("星期三")},
                 { value:4, text: this.$t("星期四")},
                 { value:5, text: this.$t("星期五")},
                 { value:6, text: this.$t("星期六")},
                 { value:7, text: this.$t("星期天")}
            ],
            Methods:[
                { value:0, text: this.$t("重复次数")},
                { value:1, text: this.$t("节目时长")},
            ],
            PlaymModel:[
                { value:0, text: this.$t("顺序播放")},
                { value:1, text: this.$t("随机播放")},
            ],
            Enable:[
                { value:0, text: this.$t("禁用")},
                { value:1, text: this.$t("启用")},
            ],
            Status:[
                  { value:0, text: this.$t("停止")}, 
                   { value:1, text: this.$t("正在播放")},
            ],
            Types:[
                  { value:1, text: this.$t("每天任务")},
                  { value:2, text: this.$t("每周任务")},
                  { value:3, text: this.$t("每月任务")},
                  { value:4, text: this.$t("一次性任务")},
            ],
            MethodType:0
        }
    },
    methods:{
        onSubmit(evt){
           evt.preventDefault();
           if(this.insert){
               this.form.ID=-1;
           }
           if(this.MethodType==0){
               delete this.form.Length;
               this.form.RepeatTime=this.form.RepeatTime*1;
           }else{
               delete this.form.RepeatTime;
               this.form.Length=this.form.Length*1;
           }
           this.form.StartTime=formatDate(new Date(this.form.StartTime),'yyyy-MM-dd hh:mm:ss');
           switch(this.form.Type){
               case 1:
               delete this.form.WeekItem;
               delete this.form.MonthItem;
               break;
               case 2:
               delete this.form.DayItem;
               delete this.form.MonthItem;
               break;
               case 3:
               delete this.form.WeekItem;
               delete this.form.DayItem;
               break;
               case 4:
               delete this.form.MonthItem;
               delete this.form.WeekItem;
               delete this.form.DayItem;
               break;
               default:
               throw this.$t("不支持的参数")
           }
           if(this.form.PlayVol){
               this.form.PlayVol=parseInt(this.form.PlayVol);
           }
           else{
               this.form.PlayVol=30;
           }
           this.$emit("submit",this.form);
        },
    }
}
</script>
