<template>
    <div class="ks_box">
        <div class="lt ksborder"></div>
        <div class="rt ksborder"></div>
        <div class="lb ksborder"></div>
        <div class="rb ksborder"></div>
        <slot></slot>
    </div>
</template>
<style scoped>
.ks_box{
width:100%;height:100%;position:relative;
border: 1px solid #3274b8
}
.ks_box .ksborder{
    position:absolute;
    width: 20px;
    height: 20px;
}
.ks_box .lt{
    left: -1px;
    top: -1px;
    border-top: 1px solid #00e2fb;
    border-left: 1px solid #00e2fb;
}
.ks_box .rt{
    right: -1px;;
    top: -1px;;
    border-top: 1px solid #00e2fb;
    border-right: 1px solid #00e2fb;
}
.ks_box .lb{
    left: -1px;;
    bottom: -1px;;
    border-bottom: 1px solid #00e2fb;
    border-left: 1px solid #00e2fb;
}
.ks_box .rb{
    right: -1px;;
    bottom: -1px;;
    border-bottom: 1px solid #00e2fb;
    border-right: 1px solid #00e2fb;
}
</style>

<script>
export default {
    
}
</script>
