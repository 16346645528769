<template>
  <div style="height:100%;">
    <b-alert show variant="warning">
      <b-form-radio-group
        v-if="type==0||type==1"
        v-model="selected"
        name="radioOpenions"
        style="margin-bottom:10px;display:inline-block"
      >
        <b-form-radio value="0">{{$t('单灯控制')}}</b-form-radio>
        <b-form-radio value="1">{{$t('网关控制')}}</b-form-radio>
      </b-form-radio-group>
      <el-button v-on:click="open(true)" type="primary" round size="small">{{$t('开灯')}}</el-button>
      <el-button
        v-on:click="open(null)"
        type="primary"
        round
        size="small"
        style="margin-right:0;"
      >{{$t('关灯')}}</el-button>
      <el-button
        v-on:click="read"
        type="primary"
        round
        size="small"
        style="margin-right:20px;"
      >{{$t('读取并刷新')}}</el-button>
      <span>
        <span class="kstitle">{{$t('调光')}}</span>
        <el-slider v-model="slideValue" style="width:150px;display:inline-block" @change="change"></el-slider>
      </span>
    </b-alert>
    <el-table
      ref="multipleTable"
      :data="items"
      stripe=""
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      @select="select"
     :empty-text="$t('暂无数据')"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column :sortable="true" :sort-method="sortByName" :label="$t('名称')">
        <template slot-scope="scope">{{scope.row.Custom.DeviceName}}</template>
      </el-table-column>
      <el-table-column  :sortable="true" :sort-method="sortByState"  :label="$t('开关状态')">
        <template slot-scope="scope">{{scope.row.SZ.on==1?$t('开灯'):$t('关灯')}}</template>
      </el-table-column>
      <el-table-column :label="$t('在线状态')">
        <template slot-scope="scope">{{scope.row.SZ.online==0?$t('离线'):$t('在线')}}</template>
      </el-table-column>
      <el-table-column :label="$t('电流')+'(A)'">
        <template slot-scope="scope">{{scope.row.SZ.current}}</template>
      </el-table-column>
      <el-table-column :label="$t('电压')+'(V)'">
        <template slot-scope="scope">{{scope.row.SZ.voltage}}</template>
      </el-table-column>
      <el-table-column :label="$t('有功功率')+'(W)'">
        <template slot-scope="scope">{{scope.row.SZ.active_power}}</template>
      </el-table-column>
      <el-table-column :label="$t('无功功率')+'(Var)'">
        <template slot-scope="scope">{{scope.row.SZ.reactive_power}}</template>
      </el-table-column>
      <el-table-column :label="$t('频率')+'HZ'">
        <template slot-scope="scope">{{scope.row.SZ.frequency}}</template>
      </el-table-column>
      <el-table-column :label="$t('亮度')+'%'">
        <template slot-scope="scope">{{scope.row.SZ.bri}}</template>
      </el-table-column>
      <el-table-column :label="$t('温度')+'℃'">
        <template slot-scope="scope">{{scope.row.SZ.temperature}}</template>
      </el-table-column>
      <el-table-column :label="$t('更新时间')+''">
        <template slot-scope="scope">{{scope.row.SZ.modifyDate}}</template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  //type 0 网关 1 网关分组 2 软件分组
  props: ["items", "type", "gateways", "sid"],
  data: function() {
    return {
      loading: {},
      slideValue: 0,
      controlType: 0,
      selected: 0,
      selectedDevice: null
    };
  },
  methods: {
    sortByState(obj1, obj2) {
      let val1 = obj1.SZ.State;
      let val2 = obj2.SZ.State;
      return val1 - val2;
    },
    sortByName(obj1, obj2) {
      let val1 = obj1.SZ.Name;
      let val2 = obj2.SZ.Name;
      return val1 - val2;
    },
    handleSelectionChange(val) {
      this.selectedDevice = val;
    },
    change(value) {
      new Promise((res, rej) => {
        var array = [];
        var req = {
          Method: "send"
        };
        var data = {
          platform: 1,
          cmds: []
        };
        if (this.selected == 0) {
          //单灯控制
          if (!this.selectedDevice || this.selectedDevice.length == 0) {
            throw this.$t("选择一条数据");
          }
          array = this.selectedDevice;
          var i = 0;
          array.forEach(x => {
            i++;
            data.cmds.push({
              code: 1,
              headSerial: i,
              uid: x.SZ.lamp_ctrl_id,
              addr: x.SZ.addr,
              gatewayAddr: x.SZ.gateway_addr,
              control: {
                devtype: x.SZ.lampControllerType,
                bri: value
              }
            });
          });
        } else {
          //网关控制
          if (!this.gateways || this.gateways.length == 0) {
            throw this.$t("当前没有网关");
          }
           var j = 0;
          this.gateways.forEach(x => {
            j++;
            data.cmds.push({
              code: 38,
              headSerial: j,
              uid: x.uid,
              addr: x.addr,
              control: {
                devtype: x.type,
                bri: value
              }
            });
          });
        }
        req.SZ = data;
        this.loading = this.$loading({ fullScreen: false });
        this.$ksajax
          .ksPostPromise("/api/agent/RequestSzApi", req, true)
          .then(success => {
            this.loading.close();
            res(success);
            
          })
          .catch(err => {
            this.loading.close();
            rej(err);
          });
      })
        .then(() => {
          //this.$emit("refresh",{type:this.type,gateways:this.gateways,sid:null})
           
        })
        .catch(err => {
          this.$throw(err);
        });
    },
    open(value) {
      new Promise((res, rej) => {
        var array = [];
        var req = {
          Method: "send"
        };
        var data = {
          platform: 1,
          cmds: []
        };
        if (this.selected == 0) {
          //单灯控制
          if (!this.selectedDevice || this.selectedDevice.length == 0) {
            throw this.$t("选择一条数据");
          }
          array = this.selectedDevice;
          var i = 0;
          array.forEach(x => {
            i++;
            data.cmds.push({
              code: 1,
              headSerial: i,
              uid: x.SZ.lamp_ctrl_id,
              addr: x.SZ.addr,
              gatewayAddr: x.SZ.gateway_addr,
              control: {
                devtype: x.SZ.lampControllerType,
                on: value ? 1 : 0
              }
            });
          });
        } else {
          //网关控制
          if (!this.gateways || this.gateways.length == 0) {
            throw this.$t("当前没有网关");
          }
          var j = 0;
          this.gateways.forEach(x => {
            j++;
            data.cmds.push({
              code: 38,
              headSerial: j,
              uid: x.uid,
              addr: x.addr,
              control: {
                devtype: x.type,
                on: value ? 1 : 0
              }
            });
          });
        }
        req.SZ = data;
        this.loading = this.$loading({ fullScreen: true });
        this.$ksajax
          .ksPostPromise("/api/agent/RequestSzApi", req, true)
          .then(success => {
            this.loading.close();
            res(success);
          })
          .catch(err => {
            this.loading.close();
            rej(err);
          });
      })
        .then(() => {
          //this.$emit("refresh",{type:this.type,gateways:this.gateways,sid:null})
        })
        .catch(err => {
          this.$throw(err);
        });
    },
    read() {
      new Promise((res, rej) => {
        var array = [];
        var req = {
          Method: "send"
        };
        var data = {
          platform: 1,
          cmds: []
        };
        if (this.selected == 0) {
          //单灯控制
          if (!this.selectedDevice || this.selectedDevice.length == 0) {
            throw this.$t("请至少选择一个设备");
          }
          array = this.selectedDevice;
          var i = 0;
          array.forEach(x => {
            i++;
            data.cmds.push({
              code: 1,
              headSerial: i,
              uid: x.SZ.lamp_ctrl_id,
              addr: x.SZ.addr,
              gatewayAddr: x.SZ.gateway_addr,
              control: {
                devtype: x.SZ.lampControllerType,
                read: 1
              }
            });
          });
        } else {
          //网关控制
          if (!this.gateways || this.gateways.length == 0) {
            throw this.$t("当前没有网关");
          }
          var j = 0;
          this.gateways.forEach(x => {
            j++;
            data.cmds.push({
              code: 38,
              headSerial: j,
              uid: x.uid,
              addr: x.addr,
              control: {
                devtype: x.type,
                read: 1
              }
            });
          });
        }
        req.SZ = data;
        this.loading = this.$loading({ fullScreen: true });
        this.$ksajax
          .ksPostPromise("/api/agent/RequestSzApi", req, true)
          .then(success => {
            this.loading.close();
            res(success);
          })
          .catch(err => {
            this.loading.close();
            rej(err);
          });
      })
        .then(() => {
          this.$emit("refresh", {
            type: this.type,
            gateways: this.gateways,
            sid: this.sid
          });
        })
        .catch(err => {
          this.$throw(err);
        });
    },
    select(a, b) {a;b
      // if(!b.SZ.online||b.SZ.online==0){
      //         this.$refs.multipleTable.toggleRowSelection(b);
      //         this.$throw("设备不在线");
      // }
    }
  }
};
</script>
