<template>
  <div class="actionbar bg-purple-dark">
    <b-alert variant="success" show class="actionbar-content">
      <el-button
        v-on:click="$emit('select')"
        v-if="select"
        type="primary"
        icon="el-icon-refresh"
        round
        size="small"
        >{{ $t("查询") }}</el-button
      >
      <el-button
        v-on:click="$emit('insert')"
        v-if="insert"
        type="primary"
        icon="el-icon-plus"
        round
        size="small"
        >{{ $t("新增") }}</el-button
      >
      <el-button
        v-on:click="$emit('modify')"
        v-if="modify"
        type="primary"
        icon="el-icon-edit"
        round
        size="small"
        >{{ $t("修改") }}</el-button
      >
      <el-button
        v-on:click="$emit('delete')"
        v-if="del"
        type="primary"
        icon="el-icon-delete"
        round
        size="small"
        >{{ $t("删除") }}</el-button
      >
      <el-button
        v-on:click="$emit('detail')"
        v-if="detail"
        type="primary"
        icon="el-icon-search"
        round
        size="small"
        >{{ $t("详情") }}</el-button
      >
      <el-button
        v-on:click="$emit('path')"
        v-if="path"
        type="primary"
        icon="el-icon-search"
        round
        size="small"
        >{{ $t("路径") }}</el-button
      >
      <el-button
        v-on:click="$emit('test')"
        v-if="test"
        type="primary"
        icon="el-icon-delete"
        round
        size="small"
        >{{ $t("测试") }}</el-button
      >
      <el-button
        v-on:click="$emit('unknowFace')"
        v-if="unknowFace"
        type="primary"
        icon="el-icon-refresh"
        round
        size="small"
        >{{ $t("未注册人脸") }}</el-button
      >
      <el-button
        v-on:click="$emit('sync')"
        v-if="sync"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-cloud-download"></i>
        {{ $t("同步") }}
      </el-button>
      <el-button
        v-on:click="$emit('volume')"
        v-if="volume"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-volume-up"></i>
        {{ $t("修改音量") }}
      </el-button>
      <el-button
        v-on:click="$emit('save')"
        v-if="save"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-save"></i>
        {{ $t("保存") }}
      </el-button>
      <el-button
        v-on:click="$emit('publish')"
        v-if="publish"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-cloud-download"></i>
        {{ $t("发布") }}
      </el-button>
      <el-button
        v-on:click="$emit('publish')"
        v-if="send"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-cloud-download"></i>
        {{ $t("下发") }}
      </el-button>
      <el-button
        v-on:click="$emit('view')"
        v-if="view"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-windows"></i>
        {{ $t("预览") }}
      </el-button>
      <el-button
        v-on:click="$emit('reboot')"
        v-if="reboot"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-window-restore"></i>
        {{ $t("重启") }}
      </el-button>
      <el-button
        v-on:click="$emit('refresh')"
        v-if="refresh"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-refresh"></i>
        {{ $t("刷新页面") }}
      </el-button>
      <el-button
        v-on:click="$emit('read')"
        v-if="read"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-refresh"></i>
        {{ $t("读取数据") }}
      </el-button>
      <el-button
        v-on:click="$emit('alarm')"
        v-if="alarm"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-refresh"></i>
        {{ $t("报警配置") }}
      </el-button>
      <el-button
        v-on:click="$emit('xwalk')"
        v-if="xwalk"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-hourglass-start"></i>
        {{ $t("开启程序") }}
      </el-button>
      <el-button
        v-on:click="$emit('fixc')"
        v-if="fixc"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-hourglass-start"></i>
        {{ $t("修复视频") }}
      </el-button>
      <el-button
        v-on:click="$emit('password')"
        v-if="password"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-hourglass-start"></i>
        {{ $t("修改密码") }}
      </el-button>
      <el-button
        v-on:click="$emit('partment')"
        v-if="partment"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-window-restore"></i>
        {{ $t("修改部门") }}
      </el-button>

      <el-button
        v-on:click="$emit('bind')"
        v-if="bind"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-window-restore"></i>
        {{ $t("绑定服务器") }}
      </el-button>

      <el-button
        v-on:click="$emit('init')"
        v-if="init"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-window-restore"></i>
        {{ $t("初始化") }}
      </el-button>
      <el-button
        v-on:click="$emit('ntp')"
        v-if="ntp"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-clock-o"></i>
        {{ $t("NTP对时") }}
      </el-button>
      <el-button
        v-on:click="$emit('light')"
        v-if="light"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-lightbulb-o"></i>
        {{ $t("修改亮度") }}
      </el-button>
      <el-button
        v-on:click="$emit('screen')"
        v-if="screen"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-television"></i>
        {{ $t("屏幕开关") }}
      </el-button>
      <el-button
        v-on:click="$emit('play')"
        v-if="play"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-play"></i>
        {{ $t("播放") }}
      </el-button>
      <el-button
        v-on:click="$emit('stop')"
        v-if="stop"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-stop"></i>
        {{ $t("停止") }}
      </el-button>
      <el-button
        v-on:click="$emit('exportFun')"
        v-if="exportFun"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-cloud-download"></i>
        {{ $t("导出") }}
      </el-button>
      <el-button
        v-on:click="$emit('toggleOn')"
        v-if="toggleOn"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-toggle-on"></i>
        {{ $t("定时任务启用") }}
      </el-button>
      <el-button
        v-on:click="$emit('toggleOff')"
        v-if="toggleOff"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-toggle-off"></i>
        {{ $t("定时任务禁用") }}
      </el-button>
      <el-button
        v-on:click="$emit('playMedia')"
        v-if="playMedia"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-play"></i>
        {{ $t("播放文件") }}
      </el-button>
      <el-button
        v-on:click="$emit('playText')"
        v-if="playText"
        type="primary"
        round
        size="small"
      >
        <i class="fa fa-play"></i>
        {{ $t("播放文本") }}
      </el-button>
    </b-alert>
  </div>
</template>
<style>
.actionbar-content {
  padding: 5px 0 5px 0;
}
i.fa {
  padding-right: 10px;
}
.action {
  margin-left: 5px;
}
</style>
<script>
export default {
  props: {
    path: Boolean,
    detail: Boolean,
    select: Boolean,
    insert: Boolean,
    modify: Boolean,
    del: Boolean,
    sync: Boolean,
    test: Boolean,
    play: Boolean,
    stop: Boolean,
    volume: Boolean,
    save: Boolean,
    publish: Boolean,
    view: Boolean,
    refresh: Boolean,
    reboot: Boolean,
    read: Boolean,
    alarm: Boolean,
    xwalk: Boolean,
    fixc: Boolean,
    password: Boolean,
    partment: Boolean,
    bind: Boolean,
    unknowFace: Boolean,
    init: Boolean,
    send: Boolean,
    ntp: Boolean,
    screen: Boolean,
    light: Boolean,
    exportFun: Boolean,
    toggleOn: Boolean,
    toggleOff: Boolean,
    playMedia: Boolean,
    playText: Boolean,
  },
};
</script>
