<template>
    <div class="info_down">
        <h2 style="margin-left:20px;">资料下载</h2>
        <hr style="color:#21b384; border-color:#21b384 !important;" />
        <div class="container" style="padding-bottom:5%" v-loading="loading">

            <div class="row down_row_header">
                <div class="col">资料名称</div>
                <div class="col">版本号</div>
                <div class="col">更新日期</div>
                <div class="col">更新说明</div>
                <div class="col">下载</div>
            </div>

            <div v-for="item in list" class="row down_row" v-bind:key="item.Sid">
                <div class="col">
                    <!-- <i class="fa fa-file-pdf-o fa-2x"></i> -->
                    <b-img class="faceimg" :src="item.Face"></b-img>
                    <span style="font-weight:bold">{{item.Name}}</span>
                </div>
                <div class="col">{{item.Version}}</div>
                <div class="col">{{item.UpdateTime}}</div>
                <div class="col">{{item.Desc}}</div>

                <div class="col" style="text-align:center">
                    <b-button variant="primary" @click="downLoad(item.Path)">下载({{item.Size}}mb)</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.faceimg{
    width:50px;
}
    .info_down {
        padding-top: 70px;
    }

    .down_row_left::after {
        clear: both;
    }

    .downtext {
        float: left;
        padding-left: 25px;
    }

    .downbox {
        width: 150px;
        height: 150px;
        border: 1px solid rgb(204, 204, 204);
        text-align: center;
        padding-top: 25px;
        float: left;
    }

    .down_row .col-lg-3 {
        /*border: 1px solid red;*/
        align-items: center;
        display: flex;
    }

    .down_row .col, .down_row_header .col {
        /*border: 1px solid red;*/
        text-align: center;
    }

    .down_row .col p{
        margin:0;
        font-size:15px;
        color:#999;
    }

    .down_row {
        margin-top: 5px;
        padding: 25px;
        background-color: #e7e3d3;
    }

    .down_row_header {
        padding: 25px;
        background-color: #e7e3d3;
    }
    .down_row_header div{
        font-weight:bold;
    }
    #home_down {
        padding-top: 65px;
    }
</style>

<script>
    export default {
        data:function(){
            return{
                list:[],
                loading:true
            }
        },
        methods: {
            downLoad(url) {
                window.location.href = url;
            },
            select(){
                 this.loading=true;
                 this.$ksajax.ksPostPromise("/api/file/getdownloadfiles","",false).then(data=>{
                     this.list=data;
                     this.loading=false;
                 }).catch(err=>{
                     this.$throw(err);
                     this.loading=false;
                 })
            }
        },
        mounted: function () {
            this.$nextTick(() => {
                // var ele = document.getElementById("info_menu");
                // if (ele) {
                //     ele.style.backgroundColor = "#333333";
                // }
                // // document.getElementById("info_footer").style.position = "fixed";
                // this.select();
            });
        }
    };
</script>
