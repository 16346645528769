<template>
  <div class="Stands white" v-loading="loading">
    <div class="funView">
      <div class="seleView">
        <el-select v-model="parkSid" @change="select">
          <el-option
            v-for="(item, index) in parkList"
            :key="index"
            :value="item.Sid"
            :label="item.Name"
          ></el-option>
        </el-select>
      </div>
      <actionbar class="btnView" v-bind:select="true" v-on:select="select">
      </actionbar>
    </div>
    <parkPage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></parkPage>
  </div>
</template>
<style >
.admin {
  background-color: white;
}
.funView {
  display: flex;
  align-items: center;
  background: rgb(209, 237, 219);
}
.seleView {
  width: 120px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.el-input__inner {
  height: 32px;
  font-size: 12px;
}
.el-input__suffix {
  display: flex;
  align-items: center;
}
.btnView {
  flex: 1;
  height: 44px;
}
.actionbar-content {
  border: none;
}
</style>

<script>
import actionbar from "@/components/actionbar";
import parkPage from "./components/parkPage.vue";
export default {
  data: function () {
    return {
      cols: [
        { name: this.$t("停车场名称"), value: "ParkName" },
        { name: this.$t("设备名"), value: "DeviceName" },
        { name: this.$t("序号"), value: "Serial" },
        { name: this.$t("设备类型"), value: "deviceTName" },
        { name: this.$t("设备验证码"), value: "Code" },
        { name: this.$t("设备Ip"), value: "Ip" },
        { name: this.$t("设备端口"), value: "Port" },
        { name: this.$t("更新时间"), value: "UpdateTime" },
        { name: this.$t("在线状态"), value: "State", state: 1 },
      ],
      items: [],
      index: 0,
      count: 5,
      total: 0,
      current: null,
      parkSid: "",
      loading: false,
      parkList: [],
    };
  },
  mounted: function () {
    this.getParkList();
  },
  components: {
    actionbar,
    parkPage,
  },
  created: function () {},
  methods: {
    currentRow(val) {
      this.current = val;
    },
    getParkList() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkList", "", true)
        .then((data) => {
          if (data.length != 0) {
            data.push({ Sid: "", Name: "全部" });
            this.parkList = data;
            this.loading = false;
            this.parkSid = this.parkList[0].Sid;
            this.select();
          } else {
            this.$throw(this.$t("数据为空"), this);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise(
          "/api/ParkCtl/GetDeviceParkVideoList",
          this.parkSid,
          true
        )
        .then((data) => {
          data.forEach((element) => {
            element.deviceTName = JSON.parse(element.DeviceTypeName).zh;
          });
          this.total = data.length;
          var array = [];
          var i = this.index;
          for (i; i < this.index + this.count; i++) {
            if (i >= data.length) {
              break;
            }
            array.push(data[i]);
          }
          this.items = array;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },

    tableCreated() {
      // this.select();
    },
    currentPage(val) {
      this.index = (val - 1) * this.count;
      this.select();
    },
  },
};
</script>

