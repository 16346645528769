<template>
    <div class="white">
        <actionbar v-bind:select='true' v-bind:modify='true'
        v-on:select='select'
        v-on:modify='modify'
        >
        </actionbar>
        <tablepage ref="tableref" id="tableref"
         v-on:created='tableCreated'
         v-on:currentPage='currentPage'
         v-on:currentRow='currentRow'
         :cols='cols'
         :items='items'
         :total='total'
         :perpage='count'
        ></tablepage>
         <b-modal ref="modallg" size="lg" v-bind:title="title" hide-footer class="ksmodal"
         header-bg-variant="success"
         body-text-variant="dark">
                  <b-tabs>
                        <b-tab :title="$t('人脸检测')" active>
                            <hr>
                            <alarmAction  :car="true" :alarm="true" :value="modalData.faceCheck"  :filter="true"></alarmAction>
                        </b-tab>
                        <b-tab :title="$t('人脸识别')">
                            <alarmAction  :car="true" :alarm="true" :value="modalData.faceNog"  :filter="true"></alarmAction>
                            <hr>
                        </b-tab>
                        <b-tab :title="$t('人形检测')">
                            <alarmAction  :car="true" :alarm="true" :value="modalData.personCheck"  :filter="true"></alarmAction>
                            <hr>
                        </b-tab>
                        <b-tab :title="$t('口罩检测')">
                            <alarmAction  :car="true" :alarm="true" :value="modalData.maskCheck"  :filter="true"></alarmAction>
                            <hr>
                        </b-tab>
                  </b-tabs>
         </b-modal>
    </div>
</template>

<script>
import actionbar from '@/components/actionbar'
import tablepage from '@/components/tablepage'
import modify from '@/components/modify'
import alarmAction from '@/components/alarmAction'
import {Link} from '@/assets/utils/datef'
export default {
    components:{actionbar,tablepage,modify,alarmAction},
    data:function(){
        return{
            radio:0,
            title:this.$t("修改"),
            modalData:this.resetData(),
            loading:{},
            cols:[
                {name:this.$t("设备名"),value:"DeviceName"},
                {name:this.$t("设备类型"),value:"TypeName",subvalue:"DeviceType"},
                {name:this.$t("设备型号"),value:"ModelNumber",subvalue:"DeviceType"},
                {name:this.$t("人脸检测"),value:"State",subvalue:"faceCheck"},
                {name:this.$t("人脸识别"),value:"State",subvalue:"faceNog"},
                {name:this.$t("人形检测"),value:"State",subvalue:"personCheck"},
                {name:this.$t("口罩检测"),value:"State",subvalue:"maskCheck"},
            ],
            items:[],
            total:0,
            count:2000,
            index:0,
            sid:"",
            current:null,
            link:Link().filter(function(x){return x.state==1}),
        }
    },
    methods:{
        select(noloading,index){
            if(!noloading){
                if(!index){
                    this.index=0;
                }
                this.loading=this.$loading({fullScreen:true});
            }
             new Promise((res,rej)=>{
                let obj={
                    Index:this.index,
                    Count:this.count
                }
                this.$ksajax.ksPostPromise("/api/device/GetDeviceVideos",obj,true).then((data)=>{
                    this.items=[];
                    data.Items.forEach(x => {
                        if(x.DeviceType.Sid=="0151"){
                            x.faceCheck={
                                LinkageActions:[]};
                            x.faceNog={
                                LinkageActions:[]};
                            x.personCheck={
                                LinkageActions:[]};
                            x.maskCheck={
                                LinkageActions:[]};
                        this.items.push(x);
                        }
                    });
                    res();
                }).catch(err=>{
                    rej(err);
                })
             }).then(()=>{
                 return new Promise((res,rej)=>{
                     let obj={
                    Index:this.index,
                    Count:this.count,
                    DevCtgSid:"001"
                    }
                    this.$ksajax.ksPostPromise("/api/device/GetSensorLinkages",obj,true).then((data)=>{
                        this.items.forEach((x)=>{
                            data.Items.forEach((y)=>{
                                if(y.LinkageActions){
                                    y.LinkageActions.forEach((l)=>{
                                        l.link=this.link.find(o=>o.value==l.LinkageType);
                                        l.guid=this.guid();
                                    })
                                }
                                if(y.DeviceSid==x.Sid){
                                    y.State=y.IsEnabled==0?"关闭":"开启"
                                    if(y.DataKind==2){
                                        x.personCheck=y;
                                    }
                                    if(y.DataKind==3){
                                        x.faceCheck=y;
                                    }
                                    if(y.DataKind==4){
                                        x.faceNog=y;
                                    }
                                    if(y.DataKind==69){
                                        x.maskCheck=y;
                                    }
                                }
                            })
                        })
                        res();
                    }).catch(err=>{
                        rej(err);
                    })
                 })
             }).then(()=>{
                 if(this.loading.close){
                     this.loading.close();
                 }
             }).catch((err)=>{
                 this.$throw(err);
                 if(this.loading.close){
                     this.loading.close();
                 }
             })
        },
        guid(){
            var guid = "";
            for (var i = 1; i <= 32; i++){
            var n = Math.floor(Math.random()*16.0).toString(16);
            guid +=   n;
            if((i==8)||(i==12)||(i==16)||(i==20))
                guid += "-";
            }
            return guid; 
                },
        del(){
            if(this.current==null)
            {
                throw this.$t("选择一条数据");
            }
            this.sid=this.current.Sid;
            this.$refs.ksmodel.show();
        },
        modify(){
            if(this.current==null)
            {
                throw this.$t("选择一条数据");
            }
                this.modalData.faceCheck={
                    "Sid": this.current.faceCheck?this.current.faceCheck.Sid:null,
                    "LinkageName": "人脸检测",
                    "IsEnabled":  this.current.faceCheck?this.current.faceCheck.IsEnabled:false,
                    "DeviceSid": this.current.Sid,
                    "DataKind": 3,
                    "ThresholdValue": 1,
                    "CompareSymbol": this.current.faceCheck?this.current.faceCheck.CompareSymbol:0,
                    "LinkageActions":this.current.faceCheck?this.current.faceCheck.LinkageActions:[],
                    "IsContrary":this.current.faceCheck?this.current.faceCheck.IsContrary:false,
                }
                this.modalData.faceNog={
                    "Sid": this.current.faceNog?this.current.faceNog.Sid:null,
                    "LinkageName": "人脸识别",
                    "IsEnabled":  this.current.faceNog?this.current.faceNog.IsEnabled:false,
                    "DeviceSid": this.current.Sid,
                    "DataKind": 4,
                    "ThresholdValue": 1,
                    "CompareSymbol": this.current.faceNog?this.current.faceNog.CompareSymbol:0,
                    "LinkageActions":this.current.faceNog?this.current.faceNog.LinkageActions:[],
                    "IsContrary":this.current.faceNog?this.current.faceNog.IsContrary:false,
                }
                
                this.modalData.personCheck={
                    "Sid": this.current.personCheck?this.current.personCheck.Sid:null,
                    "LinkageName": "人形检测",
                    "IsEnabled":  this.current.personCheck?this.current.personCheck.IsEnabled:false,
                    "DeviceSid": this.current.Sid,
                    "DataKind": 2,
                    "ThresholdValue": 1,
                    "CompareSymbol": this.current.personCheck?this.current.personCheck.CompareSymbol:0,
                    "LinkageActions":this.current.personCheck?this.current.personCheck.LinkageActions:[],
                    "IsContrary":this.current.personCheck?this.current.personCheck.IsContrary:false,
                }
            
                this.modalData.maskCheck={
                    "Sid": this.current.maskCheck?this.current.maskCheck.Sid:null,
                    "LinkageName": "口罩检测",
                    "IsEnabled":  this.current.maskCheck?this.current.maskCheck.IsEnabled:false,
                    "DeviceSid": this.current.Sid,
                    "DataKind": 69,
                    "ThresholdValue": 1,
                    "CompareSymbol": this.current.maskCheck?this.current.maskCheck.CompareSymbol:0,
                    "LinkageActions":this.current.maskCheck?this.current.maskCheck.LinkageActions:[],
                    "IsContrary":this.current.maskCheck?this.current.maskCheck.IsContrary:false,
                }
            this.$refs.modallg.show();
        },
        tableCreated(){},
        currentPage(){
        },
        currentRow(val){
            this.current=val;
        },
        resetData(){
           return   {
             faceCheck:{LinkageActions:[]},
              faceNog:{LinkageActions:[]},
              personCheck:{LinkageActions:[]},
           }
        }
        },
    mounted:function(){
        this.select();
    }
}
</script>
