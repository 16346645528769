<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"  :sync="true"
      :insert="true"
      :del="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
        v-on:sync='sync'
    >
    </actionbar>

    <tablepage
      ref="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
      @shown="onModalLoaded"
    >
      <b-form @submit="onSubmit">
        <b-form-group :label="$t('设备类型')" label-cols="2">
          <b-form-select
            :options="$store.getters.getDeviceCustom('004')"
            v-model="form.Lamp.SZ.property.type"
          ></b-form-select>
        </b-form-group>
        <b-form-group :label="$t('设备名')" label-cols="2">
          <b-form-input v-model="form.Lamp.SZ.property.name"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('序号')" label-cols="2">
          <b-form-input v-model="form.Lamp.SZ.addr"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('灯杆')" label-cols="2">
          <b-form-select
            :options="$store.state.lStands"
            v-model="form.Lamp.Custom.LStandSid"
          ></b-form-select>
        </b-form-group>
        <b-form-group :label="$t('灯控器')" label-cols="2">
          <b-form-select
            :options="controls"
            v-model="form.Control.SZ.property.type"
          ></b-form-select>
        </b-form-group>
        <div
          v-show="
            form.Control.SZ.property.type == 4 ||
            form.Control.SZ.property.type == 23
          "
        >
          <b-form-group :label="$t('网关')" label-cols="2">
            <b-form-select
              :options="gateways"
              v-model="form.Control.SZ.property.gateway_id"
              value-field="uid"
              text-field="name"
              @change="gateChange"
            ></b-form-select>
          </b-form-group>
          <b-form-group :label="$t('回路')" label-cols="2">
            <b-form-select v-model="form.Control.SZ.property.section_id">
              <option v-if="selectCount >= 1" value="1">1</option>
              <option v-if="selectCount >= 2" value="2">2</option>
              <option v-if="selectCount >= 3" value="3">3</option>
              <option v-if="selectCount >= 4" value="4">4</option>
              <option v-if="selectCount >= 5" value="5">5</option>
              <option v-if="selectCount >= 6" value="6">6</option>
              <option v-if="selectCount >= 7" value="7">7</option>
              <option v-if="selectCount >= 8" value="8">8</option>
            </b-form-select>
          </b-form-group>
        </div>
        <div
          v-show="
            form.Control.SZ.property.type == 16 ||
            form.Control.SZ.property.type == 13
          "
        >
          <b-form-group :label="$t('注册包')" label-cols="2">
            <b-form-input
              v-model="form.Lamp.SZ.property.register_pkg"
            ></b-form-input>
          </b-form-group>
        </div>
        <b-form-group :label="$t('位置')" label-cols="2">
          <b-form-input
            type="text"
            v-model="form.Lamp.SZ.property.address"
            placeholder="Address"
            v-on:keyup.enter.native="setAddress"
          >
          </b-form-input>
          <ksmap
            ref="map"
            v-on:mapMoveEnd="mapMoveEnd"
            width="100%"
            :showCenter="true"
            height="200px"
            v-bind:lat="0"
            v-bind:lng="0"
            v-bind:zoom="25"
          >
          </ksmap>
        </b-form-group>

        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width: 100px" v-on:click="hidden">Cancel</b-button>
            <b-button
              type="button"
              style="width: 100px"
              variant="primary"
              v-on:click="onSubmit"
              >OK</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import ksmap from "@/components/map";
export default {
  components: { actionbar, tablepage, ksmap },
  data: function () {
    return {
      selectCount: 4,
      gateways: [],
      loading: {},
      title: "",
      cols: [
        {
          name: this.$t("设备名"),
          value: "DeviceName",
          subvalue: "Custom",
          sub: 1,
        },
        { name: this.$t("设备序号"), value: "addr", subvalue: "SZ", sub: 1 },
        {
          name: this.$t("设备类型"),
          value: "TypeName",
          subvalue: "Custom",
          thvalue: "DeviceType",
          sub: 2,
        },
        {
          name: this.$t("设备型号"),
          value: "ModelNumber",
          subvalue: "Custom",
          thvalue: "DeviceType",
          sub: 2,
        },
        {
          name: this.$t("网关名称"),
          value: "gatewayName",
          subvalue: "SZ",
          sub: 1,
        },
        { name: this.$t("创建时间"), value: "addDate", subvalue: "SZ", sub: 1 },
        {
          name: this.$t("修改时间"),
          value: "modifyDate",
          subvalue: "SZ",
          sub: 1,
        },
        {
          name: this.$t("在线状态"),
          value: "online",
          subvalue: "SZ",
          sub: 1,
          state: 1,
        },
        { name: this.$t("详情"), value: "url", link: true },
      ],
      items: [],
      index: 0,
      count: 10,
      total: 0,
      current: null,
      sid: "",
      form: this.getResetdata(),
      method: "",
      controls: [
        { value: 4, text: "灯控器" },
        { value: 13, text: "透传直连UDP灯控器" },
        { value: 14, text: "中兴NB灯控器" },
        { value: 15, text: "华为NB灯控器" },
        { value: 16, text: "透传直连TCP灯控器" },
        { value: 19, text: "eLTE灯控器" },
        { value: 23, text: "V3灯控器" },
        { value: 24, text: "华为NB灯控器-1" },
        { value: 25, text: "透传直连UDP灯控器-1" },
        { value: 26, text: "LoRa灯控器" },
        { value: 27, text: "LoRa-V灯控器" },
        { value: 29, text: "移动onenet灯控器" },
        { value: 30, text: "LoRa灯控器-1" },
        { value: 31, text: "LoRa-m灯控器" },
        { value: 32, text: "联通NB灯控器" },
        { value: 33, text: "LoRa-W灯控器" },
        { value: 34, text: "LoRa灯控器-V3" },
        { value: 35, text: "LoRa-V灯控器-V3" },
        { value: 36, text: "LoRa-M灯控器-V3" },
        { value: 37, text: "LoRa-W灯控器-V3" },
        { value: 39, text: "透传直连UDP灯控器-V3" },
        { value: 40, text: "中兴NB灯控器-V3" },
        { value: 41, text: "华为NB灯控器-V3" },
        { value: 42, text: "透传直连TCP灯控器-V3" },
        { value: 43, text: "eLTE灯控器-V3" },
        { value: 44, text: "移动onenet灯控器-V3" },
        { value: 45, text: "联通NB灯控器-V3" },
        { value: 46, text: "移动onenet灯控器-1" },
        { value: 75, text: "CAT_ONE" },
        { value: 62, text: "电信AEP灯控器" },
        { value: 63, text: "电信AEP灯控器-v3" },
        { value: 64, text: "电信AEP灯控器-1" },
      ],
      skip:0
    };
  },
  mounted: function () {
    this.select();
  },
  methods: {
        sync(){this.loading=this.$loading({fullScreen:true});
             this.$ksajax.ksPostPromise("/api/device/syncszlightcontrol","",true).then(()=>{
                this.loading.close();
                this.select();
            }).catch((err)=>{
                this.$throw(err);
                this.loading.close();
            })
        },
    select(noloading) {
      if (!noloading) {
        this.loading = this.$loading({ fullScreen: true });
      }
      let obj = {
        Method: "read",
        SZ: {
          platform: 1,
          type: 4,
          skip: this.skip,
          limit: this.count,
          name: "",
        },
        Custom: {
          Index: this.skip,
          Count: this.count,
        },
      };
      this.$ksajax
        .ksPostPromise("/api/agent/RequestSzApi", obj, true)
        .then((success) => {
          success.Items.forEach((x) => {
            x.url = `/admin/stand/Commondevice?sid=${x.Custom.Sid}`;
            x.Custom.DeviceType.TypeName = this.$t(
              x.Custom.DeviceType.TypeName
            );
          });
          this.items = success.Items;
          this.total = success.TotalCount;
          this.loading.close();
        })
        .catch((err) => {
          this.$throw(err);
          this.loading.close();
        });
    },
    modify() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      this.title = this.$t("修改");
      this.method = "update";
      this.form = this.getModifydata(this.current);
      this.show();
    },
    show() {
      this.gateways = [];
      this.loading = this.$loading({ fullScreen: true });
      let obj = {
        Method: "read",
        SZ: {
          platform: 1,
          type: 1,
          skip: 0,
          limit: 10000,
          name: "",
        },
      };
      this.$ksajax
        .ksPostPromise("/api/agent/RequestSzApi", obj, true)
        .then((success) => {
          success.Items.forEach((x) => {
            x.SZ.Sid = x.Custom.Sid;
            this.gateways.push(x.SZ);
          });
          this.loading.close();
          this.$refs.modallg.show();
        })
        .catch((err) => {
          this.$throw(err);
          this.loading.close();
        });
    },
    del() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      this.$confirm(this.$t("删除确认"), this.$t("删除"), {
        confirmButtonText: this.$t("确认"),
        cancelButtonText: this.$t("退出"),
        type: "warning",
      })
        .then(() => {
          this.loading = this.$loading({ fullScreen: true });
          this.delAction();
        })
        .catch((err) => {
          this.$throw(err, this);
          this.loading.close();
        });
    },
    delAction() {
      var cdata = {
        Method: "delete",
        SZ: {
          platform: 1,
          type: 3,
          uid: this.current.SZ.lamp_ctrl_id,
          property: {
            type: this.current.SZ.lampControllerType,
          },
        },
      };
      var ldata = {
        Method: "delete",
        SZ: {
          platform: 1,
          type: 4,
          uid: this.current.SZ.uid,
        },
      };
      //删除灯
      this.$ksajax
        .ksPostPromise("/api/agent/RequestSzApi", ldata, true)
        .then(() => {
          return new Promise((res, rej) => {
            //删除灯控制器
            this.$ksajax
              .ksPostPromise("/api/agent/RequestSzApi", cdata, true)
              .then(() => {
                res();
              })
              .catch((err) => {
                rej(err);
              });
          });
        })
        .then(() => {
          this.loading.close();
          this.select();
        })
        .catch((err) => {
          this.$throw(err, this);
          this.loading.close();
        });
    },
    insert() {
      this.form = this.getResetdata();
      this.title = this.$t("新增");
      this.method = "create";
      this.show();
    },
    tableCreated() {},
    currentPage(val) {
      this.skip=(val-1)*this.count
      this.select()
    },
    currentRow(val) {
      this.current = val;
    },
    getModifydata(data) {
      return {
        Control: {
          Method: "update",
          SZ: {
            platform: 1,
            type: 3,
            uid: data.SZ.lamp_ctrl_id,
            addr: data.SZ.addrStr,
            property: {
              type: data.SZ.lampControllerType,
              addr: data.SZ.addr,
              division_id: "",
              organization_id: "",
              project_id: "",
              lamp_post_type: 3,
              lamp_post_number: data.SZ.lampPoleNumber,
              gateway_id: data.SZ.gatewayId,
              section_id: data.SZ.sectionId,
              register_pkg: data.SZ.register_pkg,
            },
          },
        },
        Lamp: {
          Method: "update",
          SZ: {
            platform: 1,
            type: 4,
            uid: data.SZ.uid,
            addr: data.SZ.addrStr,
            property: {
              name: data.SZ.name,
              type: data.SZ.type,
              addr: data.SZ.addr,
              division_id: "",
              organization_id: "",
              project_id: "",
              latitude: data.SZ.latitude
                ? data.SZ.latitude
                : data.SZ.latitudeMap,
              longitude: data.SZ.longitude
                ? data.SZ.longitude
                : data.SZ.longitudeMap,
              lamp_ctrl_id: data.SZ.lamp_ctrl_id,
              port_id: 1,
              encryption: 1,
              poll_interval: 60,
              formula: 20,
            },
          },
          Custom: {
            DTypeSid: data.Custom.DeviceType.Sid,
            LStandSid:data.Custom.LampStand? data.Custom.LampStand.Sid:"",
            GatewaySid: "",
          },
        },
      };
    },
    getResetdata() {
      return {
        Lamp: {
          Method: "create",
          SZ: {
            platform: 1,
            type: 4,
            addr: "",
            property: {
              type: 5,
              division_id: "",
              organization_id: "",
              project_id: "",
              name: "",
              longitude: 0,
              latitude: 0,
              lamp_ctrl_id: "",
              port_id: 1,
              encryption: 1,
              poll_interval: 60,
              formula: 20,
            },
          },
          Custom: {
            DTypeSid: "",
            LStandSid: "",
            GatewaySid: "",
          },
        },
        Control: {
          Method: "create",
          SZ: {
            platform: 1,
            type: 3,
            addr: "",
            property: {
              type: 4,
              division_id: "",
              organization_id: "",
              project_id: "",
              lamp_post_type: 3,
              lamp_post_number: "",
              gateway_id: "",
              section_id: 1,
              encryption: 1,
              poll_interval: 60,
              formula: 20,
              register_pkg: "",
            },
          },
        },
      };
    },
    mapMoveEnd(latlng) {
      this.form.Lamp.SZ.property.latitude = latlng.lat + "";
      this.form.Lamp.SZ.property.longitude = latlng.lng + "";
      this.$refs.map.getAddressByPostion(latlng.lat, latlng.lng, (address) => {
        let add = address.regeocode.formattedAddress;
        this.form.Lamp.SZ.property.address = add;
      });
    },
    setAddress() {
      this.$refs.map.setAddress(this.form.Lamp.SZ.property.address);
    },
    onModalLoaded() {
      this.$refs.map.setCenter(
        this.form.Lamp.SZ.property.latitude,
        this.form.Lamp.SZ.property.longitude
      );
    },
    hidden() {
      this.$refs.modallg.hide();
    },
    onSubmit(evn) {
      evn.preventDefault();
      this.form.Lamp.SZ.property.division_id = this.$store.state.sz.DivisionId;
      this.form.Lamp.SZ.property.organization_id =
        this.$store.state.sz.OrganizationId;
      this.form.Lamp.SZ.property.project_id = this.$store.state.sz.ProjectId;
      this.form.Control.SZ.property.division_id =
        this.$store.state.sz.DivisionId;
      this.form.Control.SZ.property.organization_id =
        this.$store.state.sz.OrganizationId;
      this.form.Control.SZ.property.project_id = this.$store.state.sz.ProjectId;
      var array = this.$store.getters.getDeviceCustom("004");
      for (var i = 0; i < array.length; i++) {
        if (this.form.Lamp.SZ.property.type == array[i].value) {
          this.form.Lamp.Custom.DTypeSid = array[i].sid;
          break;
        }
      }
      for (var j = 0; j < this.gateways.length; j++) {
        if (this.gateways[j].uid == this.form.Control.SZ.property.gateway_id) {
          this.form.Lamp.Custom.GatewaySid = this.gateways[j].Sid;
        }
      }
      this.form.Control.Method = this.method;
      this.form.Lamp.Method = this.method;
      this.form.Control.SZ.property.lamp_post_number = this.form.Lamp.SZ.addr;
      this.loading = this.$loading({ fullScreen: true });
      if (this.method == "update") {
        delete this.form.Control.addr;
        delete this.form.Lamp.addr;
      }
      this.form.Control.SZ.addr = this.form.Lamp.addr;
      this.form.Lamp.SZ.addr = `${this.form.Lamp.SZ.addr.toLowerCase()}`;
      this.form.Control.SZ.addr = `${this.form.Lamp.SZ.addr.toLowerCase()}`;
      if (
        this.form.Control.SZ.property.type == 16 ||
        this.form.Control.SZ.property.type == 13
      ) {
        delete this.form.Control.SZ.property.gateway_id;
      }
      if (
        this.form.Control.SZ.property.type == 4 ||
        this.form.Control.SZ.property.type == 23
      ) {
        delete this.form.Control.SZ.property.register_pkg;
      }
      //新增灯控制器
      this.$ksajax
        .ksPostPromise("/api/agent/RequestSzApi", this.form.Control, true)
        .then((data) => {
          this.form.Lamp.SZ.property.lamp_ctrl_id = data;
        })
        .then(() => {
          //新增灯具
          this.form.Lamp.SZ.property.type = this.form.Lamp.SZ.property.type * 1;
          return new Promise((res, rej) => {
            this.$ksajax
              .ksPostPromise("/api/agent/RequestSzApi", this.form.Lamp, true)
              .then((da) => {
                res(da);
              })
              .catch((err) => {
                rej(err);
              });
          });
        })
        .then(() => {
          this.hidden();
          this.select(true);
        })
        .catch((err) => {
          console.error("tag", err);
          this.$throw(err, this);
          this.hidden();
          this.loading.close();
        });
    },
    gateChange(val) {
      this.gateways.forEach((x) => {
        if (x.uid == val) {
          this.selectCount = x.section_count;
        }
      });
    },
  },
};
</script>

