<template>
  <div style="color:white;height:100%;">
    <div id="todetail">
      <router-link :to="`/admin/stand/commondevice?sid=${$route.query.sid}`">{{this.$t("详情")}}</router-link>
    </div>
    <div class="detailview">
      <div class="detailview_1">
        <div>
          <box style="position:relative">
            <div id="centerTop" style="position:absolute;top:20px;left:20px">
              <div style="font-size:1.5rem">{{this.$t("车位检测")}}</div>
            </div>
            <div id="leftTop" style="width:100%;height:100%"></div>
          </box>
        </div>
      </div>
      <div class="detailview_1">
        <div>
          <box id="canvas_box" style="position:relative">
            <div style="padding:20px 0 0 20px;width:100%;" class="smhide">
              <div style="font-size:1.5rem">{{item.DeviceName}}</div>
            </div>
            <div style="position:absolute;text-align:center;width:100%;height:100%;top:10%">
              <img :src="borderImg" style="max-height:90%;max-width:100%">
            </div>
            <div
              id="carAni"
              style="position:absolute;text-align:center;width:100%;height:100%;padding-top:20px;top:10%"
              :class="carClass"
            >
              <img :src="coverImg" style="max-height:90%;max-width:100%">
            </div>
          </box>
        </div>
      </div>
      <div class="detailview_1">
        <div>
          <box>
            <div class="ksflexcol" style="height:100%;">
              <div style="padding:20px 0 0 20px;width:100%;">
                <div style="font-size:1.5rem">{{this.$t("参数设置")}}</div>
                <div class="content_text" style="font-size:1.2rem">Set</div>
              </div>
              <div class="ksflexcol" style="flex:1;width:100%;padding-top:20px;font-size:1.0rem">
                <div class="row ksrow" style="flex:1;width:100%;">
                  <div class="col" style="text-align:center">{{this.$t("状态")}}</div>
                  <div
                    class="col"
                    style="text-align:center"
                  >{{item.SpaceStatus==1?$t("占用"):$t("空闲")}}</div>
                </div>

                <div class="row ksrow" style="flex:1;width:100%;">
                  <div class="col" style="text-align:center">{{this.$t("信号强度")}}</div>
                  <div class="col" style="text-align:center">{{item.Rssi}}</div>
                </div>
                <div class="row ksrow" style="flex:1;width:100%;">
                  <div class="col" style="text-align:center">{{this.$t("电池电压")}}</div>
                  <div class="col" style="text-align:center">{{item.Voltage}}</div>
                </div>
                <div class="row ksrow" style="flex:1;width:100%;">
                  <div class="col" style="text-align:center">{{this.$t("在线状态")}}</div>
                  <div class="col" style="text-align:center">{{item.Status==1?$t("在线"):$t("离线")}}</div>
                </div>
              </div>
            </div>
          </box>
        </div>
      </div>
      <div class="detailview_2">
        <div>
          <box>
            <div id="leftBottom" style="width:100%;height:100%" v-loading="leftBottomLoading"></div>
          </box>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.animateIn {
  animation: carin 3s;
  opacity: 1;
}
.animateOut {
  animation: carout 3s;
  opacity: 0;
}
@keyframes carin {
  from {
    top: 40%;
    opacity: 0;
  }
  to {
    top: 10%;
    opacity: 1;
  }
}
@keyframes carout {
  from {
    top: 10%;
    opacity: 1;
  }
  to {
    top: 40%;
    opacity: 0;
  }
}
</style>
<script>
import box from "@/components/box1.vue";
import alarm from "@/components/alarm";
export default {
  components: { box, alarm },
  data: function() {
    return {
      loading: false,
      item: {},
      carClass: "animateIn",
      borderImg: require("@/assets/img/ic_ppppp.png"),
      coverImg: require("@/assets/img/ic_car.png"),
      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" }
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" }
      ]),
      startCoverValueThread: null,
      startCoverThread: null,
      alarm: false,
      leftBottomLoading: false
    };
  },
  watch: {
    alarm: function() {
      if (this.alarm) {
        this.carClass = "animateIn";
      } else {
        this.carClass = "animateOut";
      }
    },

    "$store.state.alarmHubChange": function() {
      try {
        var data = this.$store.state.alarmHub;
        if (data.Serial == this.item.Serial) {
          this.item.SpaceStatus = data.Data.Ss;
          if (this.item.SpaceStatus == 0) {
            this.alarm = false;
          } else {
            this.alarm = true;
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted: function() {
    this.$nextTick(() => {
      this.leftTop = window.echarts.init(document.getElementById("leftTop"));
      this.leftBottom = window.echarts.init(
        document.getElementById("leftBottom")
      );
      this.beginLeftTopChart();
      //this.startCar();
      // this.startCoverThread=this.startCover();
      this.select();
    });
  },
  methods: {
    leftBottomChat() {
      this.leftBottomLoading = true;
      this.$ksajax
        .ksPostPromise(
          "/api/device/GetRecentlyParkingSpaceDatas",
          this.$route.query.sid,
          true
        )
        .then(data => {
          var data1 = [];
          data.forEach(x => {
            var m = new Date().getMonth();
            var sub = {
              Time: `${m}/${x[0].Day}`,
              Data: []
            };
            x.forEach(y => {
              var t = ("0" + y.Hour).substr(-2);
              sub.Data.push({
                Time: `${t}:00`,
                Count: y.Wl
              });
            });
            data1.push(sub);
          });
          this.beginLeftBottomChart(data1);
          this.leftBottomLoading = false;
        })
        .catch(err => {
          this.leftBottomLoading = false;
          this.$throw(err);
        });
    },
    select() {
      var sid = this.$route.query.sid;
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/device/GetParkingSpaceInfo", sid, true)
        .then(data => {
          this.loading = false;
          this.item = data;
          this.alarm = data.SpaceStatus == 0 ? false : true;
          this.carClass = data.SpaceStatus == 0 ? "animateOut" : "animateIn";
          this.leftBottomChat();
        })
        .catch(err => {
          this.loading = false;
          this.$throw(err);
        });
    },
    beginLeftTopChart() {
      var font = 12;
      if (window.innerWidth > 1366) {
        font = 20;
      }
      var on = 30;
      var sub = on + 0;
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          left: 20,
          bottom: 20,
          orient: "vertical",
          data: [this.$t("报警次数")],
          textStyle: {
            color: "#ffffff",
            fontWeight: "normal"
          }
        },
        series: [
          {
            name: this.$t("报警率"),
            type: "pie",
            radius: ["30%", "60%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: [
                  `{title|${this.$t("总数量")}}`,
                  `{count|${sub}}`
                ].join("\n"),
                show: true,
                position: "center",
                rich: {
                  title: {
                    color: "white",
                    fontSize: font
                  },
                  count: {
                    color: "#8495c2",
                    fontSize: font,
                    padding: [0, 0, 10, 0]
                  }
                }
              }
            },
            labelLine: {
              normal: {
                show: true
              }
            },
            data: [{ value: on, name: this.$t("报警次数") }],
            color: [this.color2]
          }
        ],
        grid: {
          top: 0,
          right: 100,
          bottom: 0,
          containLabel: false
        }
      };
      this.leftTop.setOption(option, true);
      window.addEventListener("resize", () => {
        this.leftTop.resize();
      });
    },
    startCoverValue() {
      return window.setInterval(() => {
        this.coverValue = parseInt(Math.random() * 180);
        if (this.coverValue >= 90) {
          this.alarm = true;
        } else {
          this.alarm = false;
          this.coverValue = 0;
        }
      }, 6000);
    },
    beginLeftBottomChart(data) {
      var datas = [];
      var names = [];
      for (var j = 0; j < data.length; j++) {
        var area = {};
        var line = "#00e5fc";
        if (j == 0) {
          area = {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(24,203,231,0.7)" },
                { offset: 0.5, color: "rgba(24,203,231,0.5)" },
                { offset: 1, color: "rgba(24,203,231,0.2)" }
              ])
            }
          };
        }
        if (j == 1) {
          area = {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(149,143,69,0.9)" },
                { offset: 0.5, color: "rgba(149,143,69,0.5)" },
                { offset: 1, color: "rgba(24,203,231,0.2)" }
              ])
            }
          };
          line = "#86fc21";
        }
        if (j == 2) {
          area = {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(87,141,170,0.5)" },
                { offset: 0, color: "rgba(87,141,170,0.4)" },
                { offset: 1, color: "rgba(24,203,231,0.2)" }
              ])
            }
          };
          line = "#6cce38";
        }
        names.push(data[j].Time);
        var online = [];
        var labels = [];
        for (var i = 0; i < data[j].Data.length; i++) {
          var obj = data[j].Data;
          online.push(obj[i].Count);
          labels.push(obj[i].Time);
        }
        datas.push({
          name: data[j].Time,
          type: "line",
          stack: data[j].Time,
          label: {
            normal: {
              show: false,
              position: "top",
              color: "white"
            }
          },
          smooth: true,
          sampling: "average",
          data: online,
          itemStyle: {
            color: line
          },
          areaStyle: area
        });
      }
      var x = {
        type: "category",
        boundaryGap: false,
        data: labels
      };
      var y = {
        splitLine: {
          lineStyle: {
            color: "#8495c2"
          }
        },
        type: "value"
      };
      var option = this.getOption(x, y, datas);
      (option.title = {
        show: true,
        text: this.$t("横向对比分析"),
        padding: [20, 0, 0, 20],
        textStyle: {
          color: "#ffffff"
        }
      }),
        (option.legend = {
          data: names,
          textStyle: {
            color: "#ffffff"
          }
        });
      this.leftBottom.setOption(option, true);
      window.addEventListener("resize", () => {
        this.leftBottom.resize();
      });
    },
    getOption(xAxis, yAxis, datas) {
      var obj = {
        textStyle: {
          color: "#8495c2"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "25%",
          containLabel: true
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: datas,
        legend: {
          data: [],
          textStyle: {
            color: "#ffffff"
          },
          orient: "vertical",
          right: 50
        }
      };
      return obj;
    },
    startCar() {
      this.startCarThread = window.setInterval(() => {
        this.alarm = !this.alarm;
      }, 1000 * 10);
    }
  },
  destroyed: function() {
    window.clearInterval(this.startCarThread);
  }
};
</script>

