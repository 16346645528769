<template>
  <div class="modify">
    <b-modal
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      body-text-variant="white"
      @shown="onModalLoaded"
      content-class="ksmod"
    >
      <b-form @submit="onSubmit">
        <b-form-group
          v-for="item in labels"
          :key="item.value"
          v-if="item.type !== 'map' && item.type !== 'select'"
          v-bind:label="item.label"
          :label-cols="2"
        >
          <b-form-input
            v-if="item.subvalue"
            :readonly="item.readonly"
            v-bind:type="item.type"
            v-model="modaldata[item.subvalue][item.value]"
            v-bind:required="item.require"
            v-bind:placeholder="item.label"
          >
          </b-form-input>
          <b-form-input
            v-else
            :readonly="item.readonly"
            v-bind:type="item.type"
            v-model="modaldata[item.value]"
            v-bind:required="item.require"
            v-bind:placeholder="item.label"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          v-for="item in labels"
          :key="item.value"
          v-if="item.type == 'select'"
          v-bind:label="item.label"
          :label-cols="2" 
        >
          <b-form-select
            v-if="item.subvalue"
            :options="item.bind"
            v-bind:type="item.type"
            v-model="modaldata[item.subvalue][item.value]"
            v-bind:required="item.require"
            v-bind:placeholder="item.label"
          >
          </b-form-select>
          <b-form-select
            v-else
            :options="item.bind"
            v-bind:type="item.type"
            v-model="modaldata[item.value]"
            v-bind:required="item.require"
            v-bind:placeholder="item.label"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group
          v-for="item in labels"
          :key="item.value"
          v-if="item.type == 'map'"
          v-bind:label="item.label"
          :label-cols="2"
        >
          <b-form-input
            v-if="!mapitem.subvalue"
            type="text"
            v-model="modaldata[mapitem.value]"
            v-bind:required="item.require"
            placeholder="Address"
            v-on:keyup.enter.native="setAddress"
          >
          </b-form-input>
          <b-form-input
            v-else
            type="text"
            v-model="modaldata[mapitem.subvalue][mapitem.value]"
            v-bind:required="item.require"
            placeholder="Address"
            v-on:keyup.enter.native="setAddress"
          >
          </b-form-input>
          <div style="margin: 10px 0 0 10px">
            <span>经度：</span
            ><b-form-input
              style="width: 40%; display: inline"
              v-model="clng"
              v-on:keyup.enter.native="onModalLoaded"
            ></b-form-input>
            <span style="padding-left: 20px">纬度：</span
            ><b-form-input
              style="width: 40%; display: inline"
              v-model="clat"
              v-on:keyup.enter.native="onModalLoaded"
            ></b-form-input>
          </div>
          <ksmap
            v-if="!mapitem.subvalue"
            ref="map"
            v-on:mapMoveEnd="mapMoveEnd"
            width="100%"
            :showCenter="true"
            height="200px"
            v-bind:lat="modaldata[mapitem.Position.Lat]"
            v-bind:lng="modaldata[mapitem.Position.Lng]"
            v-bind:zoom="25"
          >
          </ksmap>
          <ksmap
            v-else
            ref="map"
            v-on:mapMoveEnd="mapMoveEnd"
            width="100%"
            :showCenter="true"
            height="200px"
            v-bind:lat="modaldata[mapitem.subvalue][mapitem.Position.Lat]"
            v-bind:lng="modaldata[mapitem.subvalue][mapitem.Position.Lng]"
            v-bind:zoom="25"
          >
          </ksmap>
        </b-form-group>
        <b-form-group>
          <div class="modal-footer">
            <b-button variant="secondary" v-on:click="hidden">{{
              $t("取消")
            }}</b-button>
            <b-button type="button" variant="primary" v-on:click="onSubmit">{{
              $t("确认")
            }}</b-button>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<style>
.modal-foot {
  text-align: right;
}
</style>
<script>
import ksmap from "@/components/map";
export default {
  data: function () {
    return {
      Lat: 0,
      Lng: 0,
    };
  },
  components: { ksmap },
  computed: {
    clat: {
      get: function () {
        return this.modaldata.Lat;
      },
      set: function (value) {
        this.modaldata.Lat = value * 1;
      },
    },

    clng: {
      get: function () {
        return this.modaldata.Lng;
      },
      set: function (value) {
        this.modaldata.Lng = value * 1;
      },
    },
  },
  props: ["modaldata", "labels", "title", "url", "mapitem"],
  methods: {
    show() {
      this.$refs.modallg.show();
    },
    hidden() {
      this.$refs.modallg.hide();
    },
    mapMoveEnd(latlng) {
      this.$refs.map[0].getAddressByPostion(latlng.lat, latlng.lng, (data) => {
        if (!this.mapitem) {
          this.modaldata.Address = data.regeocode.formattedAddress;
          this.modaldata.Lat = latlng.lat;
          this.modaldata.Lng = latlng.lng;
        } else {
          if (!this.mapitem.subvalue) {
            this.modaldata[this.mapitem.value] =
              data.regeocode.formattedAddress;
            this.modaldata[this.mapitem.Position.Lat] = latlng.lat;
            this.modaldata[this.mapitem.Position.Lng] = latlng.lng;
          } else {
            this.modaldata[this.mapitem.subvalue][this.mapitem.value] =
              data.regeocode.formattedAddress;
            this.modaldata[this.mapitem.subvalue][this.mapitem.Position.Lat] =
              latlng.lat;
            this.modaldata[this.mapitem.subvalue][this.mapitem.Position.Lng] =
              latlng.lng;
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$ksajax.ksPostData(this.url, this.modaldata, true, (success) => {
        this.$emit("submited", success);
        this.hidden();
      });
    },
    setAddress() {
      this.$refs.map[0].setAddress(this.modaldata.Address);
    },
    onModalLoaded() {
      if (this.$refs.map && this.$refs.map[0]) {
        if (!this.modaldata.Lat || this.modaldata.Lat === 0) {
          this.modaldata.Lat = 39.908714;
        }
        if (!this.modaldata.Lng || this.modaldata.Lng === 0) {
          this.modaldata.Lng = 116.397056;
        }
        this.$refs.map[0].setCenter(this.modaldata.Lat, this.modaldata.Lng);
      }
    },
  },
};
</script>
