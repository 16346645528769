<template>
  <div ref="piechart" style="width: 100%; height: 100%"></div>
</template>
<script>
export default {
  props: ["smooth"],
  data: function () {
    return {
      chart: null,
      data: null,
      legend: null,
      color: null,
      emphasis: null,
      xdata: null,
      title: null,
    };
  },
  methods: {
    getOption() {
      var opt = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "cross", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        // legend: this.legend,
        series: [],
        grid: {
          left: "3%",
          right: "3%",
          bottom: "0%",
          top: this.title && this.title.show ? "15%" : "3%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.2,
              type: "dashed",
            },
          },
        },
        xAxis: {
          type: "category",
          data: this.xdata,
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.2,
              type: "dashed",
            },
          },
          // axisLabel: {
          //   interval: 0,
          //   formatter: function (value) {
          //     var ret = value.split("");
          //     var r = ret.join("\n");
          //     return r;
          //   },
          //   fontSize: 10,
          // },
          axisTick: {
            alignWithLabel: true,
          },
        },
        color: this.color,
        textStyle: {
          color: "#fff",
        },
        title: this.title,
      };
      //   opt.title = this.pietitle;
      this.data.forEach((x) => {
        opt.series.push({
          name: x.name,
          type: "line",
          smooth: this.smooth,
          stack: x.name,
          label: {
            normal: {
              show: true,
              position: "top",
              color: "white",
              fontSize: 15,
            },
          },
          data: x.data,
          areaStyle: {
            normal: {
              color: x.color,
            },
          },
        });
      });
      return opt;
    },
    isIe: function () {
      if (!!window.ActiveXObject || "ActiveXObject" in window) {
        return true;
      } else {
        return false;
      }
    },
    setAndInit(data, legend, color, emphasis, xdata, title) {
      this.data = data;
      this.legend = legend;
      if (this.legend) {
        this.legend.textStyle = { color: "#fff" };
      }
      this.color = color;
      this.emphasis = emphasis;
      this.xdata = xdata;
      this.title = title;
      if (this.title) {
        if (this.title.textStyle) {
          this.title.textStyle.color = "#fff";
        } else {
          this.title.textStyle = { color: "#fff" };
        }
      }
      this.init();
    },
    init() {
      if (!this.chart) {
        this.chart = window.echarts.init(this.$refs.piechart);
      }else{
        this.chart.clear();
      }
      var obj = this.getOption();
      this.chart.setOption(obj);
      window.addEventListener("resize", () => {
        this.chart.resize();
      });
      return this.chart;
    },
  },
};
</script>