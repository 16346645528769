<template>
    <div class="main_over">
        <div class="main_over_bg">
            <div style="height:100%" id="myChart">
            </div>
        </div>
        <div id="main_top">
            <div id="main_top_left"> 
                <router-link :to="home">
                <b-img :src="logopic" fluid></b-img>
            </router-link>
            </div>
            <div id="main_top_center">{{$t("智慧城市")}}</div>
            <div id="main_top_right">
                <i class="fa fa-user-circle-o"></i>
                <span class="content_text">{{userName}}</span>
            </div>
        </div>
        <div class="container-fluid" id="main_content">
            <div class="row" style="height:100%">
                <div class="col-sm-12 col-lg-4 col-md-4" id="main_cotnent_left"  style="height:100%;">
                    <div class="main_content_left_top">
                        <div style="height:100%;width:100%;" id="onlineChat"></div>
                    </div>
                    <div class="main_content_left_bottom">
                        <div style="height:100%;width:100%;" id="leftBottomChat"></div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-4 col-md-4">
                        <div style="height:10%">
                            <div class="row" style="text-align:center">
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">{{$t("智慧城市")}}</span>
                                </div>
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                            </div>
                        </div>
                        <div style="height:60%" id="myChart">
                            
                        </div>
                        <div style="height:30%">
                            
                            <div class="row" style="text-align:center">
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                            </div>
                            
                            <div class="row" style="text-align:center">
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="col-sm-12 col-lg-4 col-md-4" id="main_cotnent_left"  style="height:100%;">
                    <div class="main_content_right_top">
                        <div class="main_flex">
                            <div id="rightTopChat"></div>
                            <div style="position:relative">
                                <alarm ref="alarm" right="15%"></alarm>
                                <div style="padding:20px 0 0 5px">
                                    <div style="color:white;font-size:24px">告警分析</div>
                                    <div style="font-size:80%;" class="content_text">Alarm Analysis</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main_content_right_bottom">
                         <div style="height:100%;width:100%;" id="rightBottomChat"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.main_over{
    width: 100%;
    height: 100%;
}
.main_over_bg{
    position: absolute;
    height: 100%;
    width: 100%;
}

.main_content_map{
    position:absolute;
    height: 100%;
    width: 100%;
}

.col_flex{
    display:-moz-flex;
    display:-o-flex;
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-items: center;
}
.col_flex>div{
    width: 100%;
}
.main_flex{
    display:-moz-flex;
    display:-o-flex;
    display: flex;
    height: 100%;
    flex-direction: column
}
.main_flex>div{
    flex: 1;
    height: 100%;
    margin-bottom: 20px;
    background-image:  url("../assets/img/cute/kuang_9.png");
    background-size: 100% 100%;
}
.main_content_right_top{
    height: calc(50% - 20px);
}
.main_content_right_bottom{
    height: calc(50% - 20px);
    background-image: url("../assets/img/cute/kuang_9.png");
    background-size: 100% 100%;
    margin-bottom: 20px;
    padding-top: 30px;
}
.main_content_left_top{
    height: calc(50% - 20px);
    background-image:  url("../assets/img/cute/kuang_9.png");
    background-size: 100% 100%;
    margin-bottom: 20px;
    padding-top: 30px;
}
.main_content_left_bottom{
    height: calc(50% - 20px);
    background-image:  url("../assets/img/cute/kuang_9.png");
    background-size: 100% 100%;
    margin-bottom: 20px;
    padding-top: 30px;
}
#main_content{
    height: calc(100% - 104px);
    position: relative;
}
#main_content_center{
    flex: 1;
}
#main_content_right{
    width: 560px;
    margin-right: 20px;
}
#main_top{
    height: 84px;
    position: relative;
    display:-moz-flex;
    display:-o-flex;
    display: flex;
    margin-bottom: 20px;
}
#main_top_right>i{
    color:mediumslateblue;
    margin-right: 10px;
    font-size: 1.5em
    
}
#main_top_right>span{
    font-size: 0.8em
}
</style>

<script>
import alarm from '@/components/alarm'
export default {
    components:{alarm},
    data:function(){
        return {
             home:this.$cookies.get("ksCustom")=="true"?"/admin/nhome":"/admin/home",
             userName:this.$cookies.get("username"),
            myChart:null,
            logopic:require("@/assets/img/cute/logo.png"),
            onlineChat:null,
            leftBottomChat:null,
            rightTopChat:null,
            rightBottomChat:null,color1:new window.echarts.graphic.LinearGradient( 0, 0, 1, 0,
                        [  {offset: 0, color: '#2978c9'},
                            {offset: 0.5, color: '#00b2e4'},
                            {offset: 1, color: '#00e7fd'}]),
            color2:new window.echarts.graphic.LinearGradient( 0, 0, 1, 0,
                        [  {offset: 0, color: '#ffad42'},
                            {offset: 0.5, color: '#ffc54b'},
                            {offset: 1, color: '#ffd450'}]),
             loading:true
        }
    },
    mounted:function(){
        this.onlineChat = window.echarts.init(document.getElementById("onlineChat"));
        this.leftBottomChat = window.echarts.init(document.getElementById("leftBottomChat"));
        this.rightTopChat=window.echarts.init(document.getElementById("rightTopChat"));
        this.rightBottomChat=window.echarts.init(document.getElementById("rightBottomChat"));
        this.myChart=window.echarts.init(document.getElementById("myChart")); 
         this.beginMyChat();
         this.select();
    },
    methods:{
         getOption(xAxis,yAxis,datas){
            var obj={
                textStyle:
                 { 
                     color: '#8495c2'
                 },
                 tooltip : {
                     trigger: 'axis',
                     axisPointer : {   
                         type : 'shadow'   
                    }
                },
                grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                },
                xAxis:  xAxis, 
                yAxis: yAxis,
                     series:datas,
                      legend:{
                          data:[], 
                            textStyle:
                                { 
                                    color: '#ffffff'
                                },
                        orient: 'vertical',
                        right: 50,
                }
            }
                return obj;
        },  
        beginOnlineChart(data){
            var labels=[];
               var online=[];
                var offline=[];
                for(var i=0;i<data.length;i++){
                    online.push(data[i].OnlineCount);
                    offline.push(data[i].OfflineCount);
                    labels.push(data[i].CategoryName);
                }
                var datas=[];
            datas.push({
                name: '在线',
                type: 'bar',
                stack: '总量',
                label: {
                    normal: {
                    show: true,
                    position: 'insideRight',
                    color:"white"
                    }
                    },
                    itemStyle:{
                        normal: {
                        color: this.color1
                        }
                    },
                        data: online
                    });
                datas.push({
                  name: '离线',
                  type: 'bar',
                  stack: '总量',
                  label: {
                      normal: {
                            show: true,
                            position: 'insideRight',
                            color:"white"
                            }
                    },
                    itemStyle: {
                        normal: {
                        color: this.color2
                        }
                    },
                    data: offline
            });
            var x={splitLine:{show: false},
                type:'value'
            }
            var y={
                type:"category",splitLine:{show: false},
                data:labels
            }
             var option=this.getOption(x,y,datas);
             option.title= {
                     show: true,
                     text:"设备在线统计",
                     subtext:"Online Statistics",
                     "padding":[0, 0, 0 ,50], 
                     textStyle:
                        { 
                            color: '#ffffff'
                        },
                    },
            option.legend.data.push("在线");
            option.legend.data.push("离线");
             this.onlineChat.setOption(option);
        },
        beginLeftBottomChart(data){
                var online=[];
                var labels=[];
                for(var i=0;i<data.length;i++){
                    online.push(data[i].TotalCount);
                    labels.push(data[i].CategoryName);
                }
                var datas=[];
                datas.push({
                    name: '数量',
                    type: 'line',
                    stack: '总量', 
                    label: {
                      normal: {
                            show: true,
                            position: 'top',
                            color:"white"
                            }
                    }, smooth:true,
                     data: online,
                     itemStyle:{
                         color:"#1b85c9"
                     },
                    areaStyle:  {normal: {
                                            color: new window.echarts.graphic.LinearGradient(
                                                    0, 0, 0, 1,
                                                    [
                                                        {offset: 0, color: '#00749a'},
                                                        {offset: 0.5, color: '#123e62'},
                                                        {offset: 1, color: '#132f57'}
                                                    ]
                                            )
                                        }},
                            });
                var x={
                    type:"category",
                    boundaryGap : false,
                    data:labels
                }
                var y={
                    splitLine:{
                        lineStyle:{
                            color:"#8495c2"
                        }
                    },
                    type:"value"
                }
                var option=this.getOption(x,y,datas);
                option.title= {
                        show: true,
                        text:"设备数量统计",
                        subtext:"Quantity  Statistics",
                        "padding":[0, 0, 0 ,50], 
                        textStyle:
                            { 
                                color: '#ffffff'
                            },
                        },
                 this.leftBottomChat.setOption(option);
        }, 
        beginRightTopChart(data){
            var on=data.LightUpCount;
            var off=data.LightOffCount;
            var sub=on+off;
            var option = {
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b}: {c} ({d}%)"
            },
            legend: {
                left:5,
                bottom:5,
                orient:"vertical",
                data:['亮灯','关灯'], 
                            textStyle:
                                { 
                                    color: '#ffffff'
                                }
            },
            series: [
                {
                    name:'亮灯率',
                    type:'pie',
                    radius: ['60%', '90%'],
                    center: ['70%', '50%'],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            formatter:[ '{title|总灯数}',`{count|${sub}}`].join('\n'),
                            show: true,
                            position: 'center',
                            rich:{
                                title:{
                                    color:"white",
                                    fontSize:20
                                },
                                count:{
                                    color:"#8495c2",
                                    fontSize:14,
                                    padding: [ 0, 0, 10,0],
                                }
                            }
                        },
                    },
                    labelLine: {
                        normal: {
                            show: true
                        }
                    },
                    data:[
                        {value:on, name:'关灯'},
                        {value:off, name:'亮灯'},
                        ],
                    color:[this.color1,this.color2]
                    },
                    
                ],
                grid: {
                    top:0, 
                    right: 100,
                    bottom: 0,
                    containLabel: false
                }
            };
            option.title= {
                     show: true,
                     text:"灯具亮灯率",
                     subtext:"Online Statistics",
                     "padding":[20, 0, 0 ,5], 
                     textStyle:
                        { 
                            color: '#ffffff'
                        },
                    }
            this.rightTopChat.setOption(option,true);
        },
        beginRightBottom(data){
                var online=[];
                var labels=[];
                for(var i=0;i<data.length;i++){
                    online.push(data[i].Energy+10);
                    labels.push(data[i].Year+"."+data[i].Month);
                }
                var datas=[];
                datas.push({
                    name: '数量',
                    type: 'bar',
                    stack: '总量', 
                    smooth:true,
                     data: online, barCategoryGap:'50%',
                     itemStyle:{
                         color:this.color1
                     },});
                var x={
                    type:"category",
                    data:labels,splitLine:{show: false},
                    z:10
                }
                var y={
                    type:"value",splitLine:{show: false},
                    z:10
                }
                var option=this.getOption(x,y,datas);
                option.title= {
                        show: true,
                        text:"能耗统计",
                        subtext:"Energie  Statistics",
                        "padding":[0, 0, 0 ,50], 
                        textStyle:
                            { 
                                color: '#ffffff'
                            },
                        },
            this.rightBottomChat.setOption(option,true);
        },
        beginMyChat(){
           var option = {
                globe: {   
                    autoRotateSpeed:5,
                    globeRadius:80,
                    backgroundColor: '#000',
                    baseTexture:require("../assets/img/earth.jpg") ,
                    heightTexture: require('../assets/img/earth_4k.jpg'),
                    displacementScale: 0.1,
                    environment: require('../assets/img/beijing.png'),
                    shading: 'lambert',
                    light: {
                        ambient: {
                            intensity: 0.5
                        },
                        main: {
                            intensity: 1.5
                        }
                    },
                    layers: [{
                        type: 'blend',
                        blendTo: 'emission',
                        texture: require('../assets/img/night.jpg')
                    }, {
                        type: 'overlay',
                        texture:require( '../assets/img/clouds.png'),
                        shading: 'lambert',
                        distance: 5
                    }]
                },
                series: []
            }
            this.myChart.setOption(option,true);
        },select(loading){
            if(loading){
               this.loading=true; 
            }
            else{
                this.loading=false;
            }
            //查询数据
            this.$ksajax.ksPostPromise("/api/overview/GetDashboardData","",true).then(data=>{
                //在线率
                this.beginOnlineChart(data.Onlines);
                this.beginLeftBottomChart(data.Devices);
                this.beginRightTopChart(data.LightUpRate);
                this.beginRightBottom(data.Energies);
                this.loading=false;
            }).catch(err=>{
                this.$throw(err);
                this.loading=false;
            })
        }
    }
}
</script>
