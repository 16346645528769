<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
    ></actionbar>

    <!-- <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage> -->
    <el-table stripe=""
      :data="items"
      style="width: 100%"
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column
        :label="$t('设备名称')"
        prop="DeviceName"
      ></el-table-column>
      <el-table-column :label="$t('设备类型')">
        <template slot-scope="scope">
          <span>{{ scope.row.DeviceType.TypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('设备型号')">
        <template slot-scope="scope">
          <span>{{ scope.row.DeviceType.ModelNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作系统')" prop="OsType"></el-table-column>
      <el-table-column
        :label="$t('网络协议')"
        prop="ProtocolType"
      ></el-table-column>
      <el-table-column :label="$t('在线状态')">
        <template slot-scope="scope">
          <i :class="['fa fa-2x fa-signal',scope.row.Status == 0 ? 'stateOff' : 'stateOn']"></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('识别日志')">
        <template slot-scope="scope">
          <router-link :to="scope.row.Log">{{$t('识别日志')}}</router-link>
        </template>
      </el-table-column>
        <el-table-column :label="$t('识别状态')">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.IsProcess"
            active-color="#13ce66"
            inactive-color="#999"
            @change="setVoice(scope.row.Sid,scope.row.IsProcess)"
          ></el-switch>
        </template>
      </el-table-column>
    </el-table>
    <modify
      ref="modify"
      :mapitem="mapitem"
      :modaldata="modalData"
      :title="title"
      :labels="labels"
      v-on:submited="submited"
      url="/api/device/AddXFVoice"
    ></modify>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/device/DelXFVoice"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
export default {
  components: { actionbar, tablepage, modify, deletedata },
  data: function () {
    return {
      mapitem: {
        value: "Address",
        require: true,
        subvalue: false,
        Position: {
          Lat: "Lat",
          Lng: "Lng",
        },
      },
      modalData: this.resetData(),
      loading: {},
      cols: [
        { name: this.$t("设备名"), value: "DeviceName" },
        {
          name: this.$t("设备类型"),
          value: "TypeName",
          subvalue: "DeviceType",
        },
        {
          name: this.$t("设备型号"),
          value: "ModelNumber",
          subvalue: "DeviceType",
        },
        { name: this.$t("操作系统"), value: "OsType" },
        { name: this.$t("网络协议"), value: "ProtocolType" },
        { name: this.$t("在线状态"), value: "Status", state: 1 },
        { name: this.$t("识别记录"), value: "Log", link: true },
      ],
      items: [],
      total: 0,
      count: 20,
      index: 0,
      sid: "",
      labels: this.getResetLable(),
      title: "",
      current: null,
    };
  },
  methods: {
    setVoice(sid,action){
      var obj={
        Sid:sid,
        IsProcess:action||action==1
      };
        this.loading = this.$loading({ fullScreen: true });
         this.$ksajax
        .ksPostPromise("/api/device/EnableXFVoice", obj, true).then(()=>{
          this.loading.close();
        }).catch(err=>{
          this.loading.close();
          this.$throw(err);
        });
    },
    select(noloading, index) {
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      let obj = {
        Index: this.index,
        Count: this.count,
      };
      this.$ksajax
        .ksPostPromise("/api/device/GetDeviceXFVoices", obj, true)
        .then((data) => {
          this.items = data.Items;
          this.total = data.Items.length;
          this.items.forEach((x) => {
            x.Log = `/Admin/Voice/Logs?Sid=${x.Sid}`;
          });
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.modalData = {
        Sid: this.current.Sid,
        DeviceName: this.current.DeviceName,
        Lat: this.current.Lat,
        Lng: this.current.Lng,
        Address: this.current.Address,
        Serial: this.current.Serial,
        DTypeSid: this.current.DeviceType.Sid,
        OsType: "Linux",
        ProtocolType: "EVS",
        WakeUpWord: "闪电侠",
      };
      this.$refs.modify.show();
    },
    insert() {
      this.modalData = this.resetData();
      this.$refs.modify.show();
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      return {
        Sid: "",
        DeviceName: "语音识别设备",
        Lat: 23.452,
        Lng: 123.452,
        Address: "",
        Serial: "",
        DTypeSid: "1601",
        OsType: "Linux",
        ProtocolType: "EVS",
        WakeUpWord: "闪电侠",
      };
    },
    getResetLable() {
      return [
        {
          label: this.$t("设备名"),
          type: "text",
          value: "DeviceName",
          require: true,
        },
        {
          label: this.$t("序号"),
          type: "text",
          value: "Serial",
          require: true,
        },
        {
          label: this.$t("类型"),
          type: "select",
          value: "DTypeSid",
          require: true,
          bind: this.$store.getters.getDeviceType("016"),
        },
        {
          label: this.$t("位置"),
          type: "map",
          value: "Address",
          require: true,
        },
      ];
    },
    submited() {
      this.select();
    },
    deleted() {
      this.select();
    },
  },
  mounted: function () {
    this.select();
  },
};
</script>
