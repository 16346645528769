<template>
  <div ref="test" style="width: 100%; height: 100%" v-loading="loading"></div>
</template>
<style scoped>
</style>
<script>
import word from "../../../../assets/utils/wordradom";
export default {
  props: ["stringvalue"],
  data() {
    return {
      loading: false,
    };
  },
  watch:{
    stringvalue(){
      this.select();
    }
  },
  methods: {
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/voice/GetSensitiveWordType", "", true)
        .then((data) => {
          this.loading = false;
          var words = [];
          data.forEach((element) => {
            words.push({
              word: element,
              weight:  element == this.stringvalue ?60:parseInt(Math.random() * (42 - 12 + 1) + 24, 10),
              color: element == this.stringvalue ? "red" : "white",
            });
          });
          var opt = {
            words: words,
            minFont: 10,
            maxFont: 40,
            padding_left: 1,
            word_common_classes: "WordClass",
          };
           word(this.$refs.test,opt);
        }).catch(err=>{
            this.$throw(err);
            this.loading=false;
        });
    },
  },
  mounted() {
    this.select();
  },
};
</script>