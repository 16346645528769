<template>
  <div class="nhome_main ksflexcol nmain">
    <div class="nhome_main_header" style="width:100%">
      <ksmenu :tran=true></ksmenu>
    </div>
    <div class="nhome_main_center" style="flex:1;width:100%;padding-top:4%">
        <homeBox v-for="item in menu" :key="item.index"
         :class="item.cls" :box="{start:item.color.start,end:item.color.end,pic:item.color.pic,title:item.title,path:item.path}"></homeBox>
        <div style="clear:both"></div>
    </div>
        <div style="clear:both"></div>
  </div>
</template>
<script>
import homeBox from "../components/homeBox"
import ksmenu from "../components/ksmenu"
import config from "@/assets/utils/config"
export default {
    components:{homeBox,ksmenu},
  data() {
    return {
      baseUrl:config.BaseUrl,
      lang:"zh",
      menu:[],
      userName:this.$cookies.get("username")
    };
  },
  mounted(){
    this.init();
  },
  watch:{
    "$store.state.menu":function(){
      this.init();
    }
  },
  methods: {
    getColors(path){
      var array=[
        {
          path:"/Admin/Cam",
          start:"#759DD8",
          end:"#759DD8",
          pic:`${this.baseUrl}/img/newhome/ic_spjk.png`
        }, {
          path:"/Admin/Broad",
          start:"#D67048",
          end:"#D67048",
          pic:`${this.baseUrl}/img/newhome/ic_gb.png`
        },{
          path:"/Admin/Stand",
          start:"#496DEF",
          end:"#1536B0",
          pic:`${this.baseUrl}/img/newhome/ic_jjxx.png`
        },{
          path:"/Admin/Light",
          start:"#54C4E0",
          end:"#54C4E0",
          pic:`${this.baseUrl}/img/newhome/ic_zm.png`
        },{
          path:"/Admin/Sensor",
          start:"#BCEE7D",
          end:"#4B9B58",
          pic:`${this.baseUrl}/img/newhome/ic_sj.png`
        },{
          path:"/Admin/Led",
          start:"#55A658",
          end:"#55A658",
          pic:`${this.baseUrl}/img/newhome/ic_xxfb.png`
        },{
          path:"/Admin/OView",
          start:"#48C0FF",
          end:"#1379FA",
          pic:`${this.baseUrl}/img/newhome/ic_sj.png`
        },{
          path:"/Admin/Wifi",
          start:"#065FE6",
          end:"#065FE6",
          pic:`${this.baseUrl}/img/newhome/ic_wl.png`
        },{
          path:"/Admin/Water",
          start:"#3F8BFF",
          end:"#3F8BFF",
          pic:`${this.baseUrl}/img/newhome/ic_sw.png`
        },{
          path:"/Admin/Conver",
          start:"#8989B0",
          end:"#8989B0",
          pic:`${this.baseUrl}/img/newhome/ic_jg.png`
        },{
          path:"/Admin/Car",
          start:"#5D7A94",
          end:"#5D7A94",
          pic:`${this.baseUrl}/img/newhome/ic_cw.png`
        },{
          path:"/Admin/Alarms",
          start:"#DF4C56",
          end:"#DF4C56",
          // pic:`${this.baseUrl}/img/newhome/ic_hl.png`
        },{
          path:"/Admin/Face",
          start:"#8F74EA",
          end:"#8F74EA",
          pic:`${this.baseUrl}/img/newhome/ic_rlsb.png`
        },{
          path:"/Home",
          start:"#686AF4",
          end:"#3C3CC0",
          pic:`${this.baseUrl}/img/newhome/ic_hl.png`
        },{
          path:"/Admin/XiXun",
          start:"#55A658",
          end:"#55A658",
          pic:`${this.baseUrl}/img/newhome/ic_xxfb.png`
        },{
          path:"/Admin/Hydrant",
          start:"#DF4C56",
          end:"#DF4C56",
          pic:`${this.baseUrl}/img/newhome/ic_xfs.png`
        },{
          path:"/Admin/Nova",
          start:"#55A658",
          end:"#55A658",
          pic:`${this.baseUrl}/img/newhome/ic_xxfb.png`
        },{
          path:"/Admin/NovaBroad",
          start:"#D67048",
          end:"#D67048",
          pic:`${this.baseUrl}/img/newhome/ic_gb.png`
        },{
          path:"/Admin/NwLed",
          start:"#55A658",
          end:"#55A658",
          pic:`${this.baseUrl}/img/newhome/ic_xxfb.png`
        }
      ];
      var obj=array.find(x=>x.path.toLowerCase()==path.toLowerCase());
      if(obj){
        return {
          color:obj,
          random:false
        }
      }else{
        return{
          color:array[Math.round(Math.random()*18)],
          random:true
        }
      }
    },
    init(){
      var m=this.$store.getters.getSonMenu("0");
      var data=[];
      var j=1
      var x2=[1,6,9,10,13,16];
      for(var i=0;i<m.length;i++){
        var cls="homex1";
        if(x2.indexOf(j)!=-1){
          cls="homex2";
        }
        var obj=this.getColors(m[i].Path);
        if(obj.random||!obj.color.pic){
          obj.color.pic=m[i].Pic;
        }
        obj.cls=cls;
        obj.title=m[i].Name;
        obj.index=i;
        obj.path=m[i].Path;
        if(obj.path.toLowerCase()=='/home'){
          obj.path='/admin/nhome';
        }
        data.push(obj);
        if(j==18){
          j=0;
        }
        j+=1;
      }
      var v=data.length%6;
      if(v>0&&v<=2){
        for(var k=0;k<v;k++){
          data[data.length-1-k].cls="homex1";
        }
        for(var u=data.length-1-v;u>=0;u--){
          if(v<=0){
            break;
          }
          if(data[u].cls=='homex2'){
            data[u].cls="homex1";
            v--;
          }
        }
      }
      if(v>=3){
        data[data.length-1].cls="homex2"
      }
      this.menu=data;
    }, logout() {
      //删除cookie
      this.$cookies.set("token", null, 0);
      if(this.$route.path.toLowerCase()=="/admin/nhome"){
        this.$router.push({ path: "/info/home?reload=true" });
      }else{
      this.$router.push({ path: "/info/login?reload=true" });}
    },
    lanChange() {
      //先切换语言
      this.$i18n.locale = this.lang;
      this.$cookies.set("lan", this.$i18n.locale, 365);
      this.$store.dispatch(
        "menuAction",
        () => {
          this.init();
        },
        (err) => {
          this.$throw(err);
        }
      );
    }
  },
};
</script>
<style scoped>
.nhome_main {
  padding: 10px 7%;
  height: 100%;
}
.el-radio-button {
  margin-bottom: 0px !important;
}
</style>