<template>
  <b-row class="white netcam">
    <b-col class="sidecontent">
      <netvideo
        ref="netplay"
        :showRight="false"
        :playdata="playdata"
      ></netvideo>
    </b-col>
    <div class="selView">
      <el-select v-model="modeSelect" @change="selectMode">
        <el-option
          v-for="(item, index) in modeList"
          :key="index"
          :value="item.value"
          :label="item.text"
        ></el-option>
      </el-select>
    </div>
  </b-row>
</template>
<style>
.selView {
  position: absolute;
  right: 5px;
  width: 100px;
}
</style>
<script>
import netvideo from "@/components/netvideo";
export default {
  components: { netvideo },
  data: function () {
    return {
      playdata: {},
      roadStudItem: {},
      modifyModal: {},
      modeList: [
        { value: 0, text: "关闭" },
        { value: 1, text: "闪烁" },
        { value: 2, text: "常亮" },
        { value: 3, text: "强闪烁" },
        { value: 4, text: "人形感应" },
      ],
      modeSelect: 0,
    };
  },
  methods: {
    selectMode() {
      console.log("select");
      this.loading = this.$loading({ fullScreen: true });
      var mod=parseInt(this.modeSelect);
      if(mod==4)
      {
        mod=3;
      }
      this.$ksajax
        .ksPostPromise(
          "/api/roadstud/Update",
          {
            sid: this.modifyModal.sid,
            DeviceVideoSid: this.modifyModal.DeviceVideoSid,
            // Mode: parseInt(this.modeSelect),
             Mode: mod
          },
          true
        )
        .then(() => {
          this.loading.close();
        })
        .catch((err) => {
          this.loading.close();
          this.$throw(err);
        });
    },
    clickItem(DeviceVideoSid) {
      let choose = false;
      let device = {};
      choose = this.roadStudItem.active = !this.roadStudItem.active;

      device = this.roadStudItem;

      if (choose) {
        //播放
        var req = this.getPlayReqData(DeviceVideoSid, "sd", false);
        var url = "/api/device/GetPayNet";
        this.$ksajax.ksPostData(url, req, true, (success) => {
          this.playdata.data = success;
          this.playdata.isPlay = true;
          this.playdata.device = device;
          this.$refs.netplay.start();
          console.log("playdata2:", this.playdata);
        });
      } else {
        //停止播放
        this.playdata.isPlay = false;
        this.$refs.netplay.stop();
      }
    },
    getPlayReqData(sid, hd) {
      return {
        EzopenType: 0,
        Channel: 1,
        Definition: hd,
        PlaybackSource: 0,
        Sid: sid,
      };
    },
  },
  destroyed: function () {
    window.alarmDoAction = null;
    window.stopYSPlay = null;
    window.stopYSSound = null;
  },
  mounted: function () {
    console.log("route:", this.$route);
    //获取道钉信息
    this.loading = this.$loading({ fullScreen: true });
    this.$ksajax
      .ksPostPromise("/api/roadstud/Get", this.$route.query.sid, true)
      .then((data) => {
        this.loading.close();
        console.log("datars:", data);
        var obj = {
          text: data.DeviceVideoName,
          active: false,
          sid: data.DeviceVideoSid,
          serial: data.DeviceVideoSerial,
        };
        var obj2 = {
          sid: data.Sid,
          DeviceVideoSid: data.DeviceVideoSid,
          Mode: parseInt(data.Mode),
        };
        this.modifyModal = obj2;
        this.roadStudItem = obj;
        this.modeSelect = data.Mode;
        this.clickItem(data.DeviceVideoSid);
      })
      .catch((err) => {
        this.$throw(err, this);
        this.loading.close();
      });
  },
};
</script>

