<template>
<div style="height:100%;">
  <div class="abimg" v-show="center" @click="center=false">
    <div style="text-align:right">
    <el-button type="primary">关闭大图</el-button>
    </div>
    <iframe style="width:100%;height:100%" :src="centerPic" @click="center=false">
    </iframe>
  </div>
  <el-tabs v-model="activeName" style="height:100%" type="border-card">
    <el-tab-pane label="基本信息" name="0">
      <div style="width: 100%; height: 100%" class="clearfixCol">
        <div class="lawdetail lawdetailpic" v-if="carPic">
          <img
            :src="carPic"
            style="height: auto; width: 100%; max-width: 100%"
            @click="showCenter(carPic)"
          />
        </div>
        <div class="lawdetail lawdetailInfo ksflexcol">
          <div style="height: 50px; text-align: center">
            <img :src="platPic" style="max-height: 100%; max-width: 100%" />
          </div>
          <el-row class="law_row">
            <el-col :span="8">抓拍日期</el-col>
            <el-col :span="16">{{ CreateTime }}</el-col>
          </el-row>
          <el-row class="law_row">
            <el-col :span="8">车牌</el-col>
            <el-col :span="16">{{ plat }}</el-col>
          </el-row>
          <el-row class="law_row">
            <el-col :span="8">车辆品牌</el-col>
            <el-col :span="16">{{ VehicleLogoRecog }}</el-col>
          </el-row>
          <el-row class="law_row">
            <el-col :span="8">车辆类型</el-col>
            <el-col :span="16">{{ VehicleType }}</el-col>
          </el-row>
          <el-row class="law_row">
            <el-col :span="8">违法行为</el-col>
            <el-col :span="16">{{ IllegalType }}</el-col>
          </el-row>
          <el-row class="law_row">
            <el-col :span="8">驾驶位安全带</el-col>
            <el-col :span="16">{{ PilotSafebelt }}</el-col>
          </el-row>
          <el-row class="law_row">
            <el-col :span="8">副驾驶位安全带</el-col>
            <el-col :span="16">{{ CopilotSafebelt }}</el-col>
          </el-row>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane v-for="(item,index) in descPics" :key="index" :name="(index+1)+''" :label="`图片${index+1}`">
      <img :src="item" style="width:auto;height:100%;max-width:100%" @click="showCenter(item)"/>
    </el-tab-pane>
  </el-tabs>
</div>
</template>
<style scoped>
img{
  cursor: pointer;
}
.abimg{
  background-color: white;
  position: fixed;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  z-index: 10000;
}
.lawdetail {
  height: 100%;
  width: 50%;
  float: left;
}
.law_row {
  padding: 2px 5px;
  margin: 0px;
  flex: 1;
  width: 100%;
}
.lawdetailpic {
  display: flex;
  align-items: center;
}
</style>
<script>
export default {
  props: ["value"],
  data() {
    return {
      centerPic:"",
      center:false,
      activeName: "0",
      descPics:[],
      carPic: "",
      platPic: "",
      plat: "",
      VehicleLogoRecog: "",
      VehicleType: "",
      IllegalType: "",
      CreateTime: "",
      PilotSafebelt: "",
      CopilotSafebelt: "",
    };
  },
  watch: {
    value() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    showCenter(pic){
      this.centerPic=pic;
      this.center=true;
    },
    init() {
      if (this.value && this.value.Law) {
        var obj = JSON.parse(this.value.Law.Pics);
        if (obj && obj[0]) {
          this.carPic = obj[0];
          this.platPic = this.value.Law.PlatePic;
        }
        this.descPics=[];
        if(obj.length>1){
          obj.forEach(element => {
            this.descPics.push(element);
          });
        }
        console.log(this.descPics);
        this.plat = this.value.Car.License;
        this.VehicleLogoRecog = this.value.Car.VehicleLogoRecog;
        this.VehicleType = this.value.Law.VehicleType;
        this.IllegalType = this.value.Law.IllegalType;
        this.CreateTime = new Date(this.value.Law.CreateTime).toLocaleString();
        this.PilotSafebelt =
          this.value.Law.PilotSafebelt == 0
            ? "未知"
            : this.value.Law.PilotSafebelt == 1
            ? "正常"
            : "未系";
        this.CopilotSafebelt =
          this.value.Law.CopilotSafebelt == 0
            ? "未知"
            : this.value.Law.CopilotSafebelt == 1
            ? "正常"
            : "未系";
      }
      this.activeName= "0"
    },
  },
};
</script>