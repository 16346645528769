
<template>
    <div class="white">
        <actionbar :insert='false' :modify='false' :del="true" :detail='false'
                   v-on:insert='insert'
                   v-on:modify='modify'
                   v-on:delete='del'
                   v-on:detail='detail'>
        </actionbar>

        <div class="photo_wall">
            <div class="photo_item" v-for="(item,index) in items" :key="item">
                <el-checkbox-group v-model="curChecked">
                    <img :src="item.FaceUrl" @click="imgClick(item)" />
                    <el-checkbox v-model="item.IsSelected" :label="index + (currentPage-1)*pagesize+1 +' - '+ item.DetectTime" @change="checkChange(item.Sid)" border></el-checkbox>
                </el-checkbox-group>
            </div>
        </div>

        <el-pagination style="text-align:center;"
                       layout=" prev, pager, next, total,sizes"
                       @current-change="current_change"
                       @size-change="pagesize_change"
                       :current-page="currentPage"
                       :page-sizes="[12,36,60,120]"
                       :page-size="pagesize"
                       :total="total"
                       background>
        </el-pagination>

        <!-- 创建人脸库对话框 -->
        <b-modal id="modallg"
                 ref="modallg"
                 size="lg"
                 v-bind:title="modalTitle"
                 hide-footer
                 header-bg-variant="success"
                 body-text-variant="dark">

            <b-form @submit="onSubmit">
                <b-row>
                    <b-col>
                        <b-img src="/img/unknow.png" style="width:230px;"></b-img>
                        <p style="color:#808080">仅支持jpg、png、gif、bmp格式，文件大小不超过1M，且尺寸在1280*1280像素以内</p>
                    </b-col>
                    <b-col cols="8">
                        <b-form-group v-bind:label="$t('姓名')" :label-cols="2">
                            <b-form-input type="text" v-model="modalData.Name" required placeholder="请输入姓名（必填）"></b-form-input>
                        </b-form-group>
                        <b-form-group v-bind:label="$t('身份证')" :label-cols="2">
                            <b-form-input type="text" v-model="modalData.IdNumber"></b-form-input>
                        </b-form-group>
                        <b-form-group v-bind:label="$t('民族')" :label-cols="2">
                            <b-form-select :options="initData.Nation" v-model="modalData.Nation"></b-form-select>
                        </b-form-group>
                        <b-form-group v-bind:label="$t('类型')" :label-cols="2">
                            <b-form-select :options="initData.Type" v-model="modalData.Type"></b-form-select>
                        </b-form-group>
                        <b-form-group v-bind:label="$t('生日')" :label-cols="2">
                            <el-date-picker required v-model="modalData.Birthday" type="date"></el-date-picker>
                        </b-form-group>
                        <b-form-group v-bind:label="$t('住址')" :label-cols="2">
                            <b-form-input type="text" v-model="modalData.Address"></b-form-input>
                        </b-form-group>
                        <b-form-group v-bind:label="$t('性别')" :label-cols="2">
                            <b-form-radio-group v-model="modalData.Sex">
                                <b-form-radio :value="0">男</b-form-radio>
                                <b-form-radio :value="1">女</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group v-bind:label="$t('状态')" :label-cols="2">
                            <b-form-radio-group v-model="modalData.IsEnabled">
                                <b-form-radio :value="0">禁用</b-form-radio>
                                <b-form-radio :value="1">启用</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group v-bind:label="$t('广播提示语')" :label-cols="2">
                            <b-form-input type="text" v-model="modalData.VoiceText"></b-form-input>
                        </b-form-group>
                        <b-form-group v-bind:label="$t('备注')" :label-cols="2">
                            <b-form-input type="textarea" v-model="modalData.Describe" autosize placeholder="请输入备注"></b-form-input>
                        </b-form-group>

                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <hr>
                            <div class="modal-foot" style="text-align:center;">
                                <b-button style="width:125px;margin-right:10px;" v-on:click="selectDialog()">录入到已有库</b-button>
                                <b-button style="width:100px;margin-right:10px;" v-on:click="hiden(modallg)">取消</b-button>
                                <b-button type="submit" style="width:100px; margin-left:10px;" variant="primary">新增</b-button>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-modal>

        <!-- 创建人脸库对话框 -->
        <!-- 新增、修改对话框 -->
        <b-modal id="modallg2"
                 ref="modallg2"
                 size="lg"
                 v-bind:title="modalTitle2"
                 hide-footer
                 header-bg-variant="success"
                 body-text-variant="dark">

            <b-form @submit="onSubmit1">
                <b-container>
                    <b-row>
                        <!-- 左边 -->
                        <b-col>
                            <el-upload class="avatar-uploader"
                                       action="/api/Upload/FileUploadSingle"
                                       :show-file-list="false"
                                       :on-success="handleAvatarSuccess"
                                       :before-upload="beforeAvatarUpload">
                                <img v-if="modalData2.ImageUrl" :src="modalData2.ImageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <b-form-group :label-cols="2">
                                <p>仅支持jpg、png、gif、bmp格式，文件大小不超过1M，且尺寸在1280*1280像素以内</p>
                            </b-form-group>
                        </b-col>
                        <!-- 右边 -->
                        <b-col>
                            <b-form-group v-bind:label="$t('人脸库')" :label-cols="2">
                                <!--<b-form-input type="text" v-model="modalData2.FaceSetName" readonly></b-form-input>-->
                                <el-select v-model="faceSetSid" filterable placeholder="Select" style="width:100%;">
                                    <el-option v-for="item in facesetArray" :key="item.Sid" :label="item.Name" :value="item.Sid"></el-option>
                                </el-select>
                            </b-form-group>
                            <b-form-group v-bind:label="$t('设备')" :label-cols="2">
                                <b-form-input type="text" v-model="modalData2.DeviceSid" readonly></b-form-input>
                            </b-form-group>
                            <b-form-group v-bind:label="$t('备注')" :label-cols="2">
                                <b-form-textarea rows="3" v-model="modalData2.Describe" placeholder="请输入备注"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-container>
                <b-form-group>
                    <hr>
                    <div class="modal-foot">
                        <b-button style="width:100px" v-on:click="hiden(modallg2)">Cancel</b-button>
                        <b-button type="submit" style="width:100px" variant="primary">OK</b-button>
                    </div>
                </b-form-group>
            </b-form>
        </b-modal>

        <!-- 删除多好看 -->
        <deletedata ref="del"
                    v-on:deleted="deleted"
                    :sid="sid"
                    url="/api/face/DeleteFace"
                    :title="$t('删除')"></deletedata>

    </div>
</template>

<style>
    .photo_wall {
    }

        .photo_wall .photo_item {
            display: inline-block;
            justify-content: center;
            vertical-align: middle;
            /*border:1px solid red;*/
            padding: 20px;
            margin: 0 20px;
        }

            .photo_wall .photo_item img {
                width: 210px;
                height: 207px;
                cursor: pointer;
            }

            .photo_wall .photo_item .el-checkbox {
                display: block;
            }
</style>

<script>
    import actionbar from '@/components/actionbar'
    import tablepage from '@/components/tablepage'
    import modify from '@/components/modify'
    import deletedata from '@/components/deletedata'
    import alarmAction from '@/components/alarmAction'
    import { Link } from '@/assets/utils/datef'

    export default {
        components: { actionbar, tablepage, modify, deletedata, alarmAction },
        data: function () {
            return {
                curChecked: [], // 当前选择框
                radio: 0,
                modalTitle: this.$t("创建人脸库"),
                modalTitle2: this.$t("注册人脸"),
                modalData: {},
                modalData2: {},
                initData: {
                    Nation: [
                        { text: "汉族", value: "汉族" },
                        { text: "其他民族", value: "其他民族" },
                    ],
                    Type: [
                        { text: "陌生人", value: "0" },
                        { text: "嫌疑人", value: "1" },
                        { text: "普通客人", value: "2" },
                        { text: "重要客人", value: "3" },
                    ]
                },
                loading: {},

                items: [],
                total: 0,// 默认数据总数
                pagesize: 12,// 每页的数据条数
                currentPage: 1,// 默认开始页面

                index: 0,
                sid: "",
                current: null,
                link: Link().filter(function (x) { return x.state == 1 }),
                selfChoose: {
                    radio: null,
                    devs: [],
                    selected: {}
                },
                flagChoose: {
                    radio: null,
                    devs: [],
                    selected: {}
                },
                leds: [],
                facesetArray:[],
                faceSetSid:"",
            }
        },
        methods: {
            handleAvatarSuccess() { },
            beforeAvatarUpload() { },
            select(noloading, index) {
                if (!noloading) {
                    if (!index) {
                        this.index = 0;
                    }
                    this.loading = this.$loading({ fullScreen: true });
                }
                new Promise((res, rej) => {
                    let obj = {
                        Index: (this.currentPage - 1) * this.pagesize,
                        Count: this.pagesize
                    }
                    this.$ksajax.ksPostPromise("/api/face/GetUnregisteredFaces", obj, true).then((data) => {
                        data.Items.forEach(x => {
                            x.IsSelected = false;
                        });
                        this.items = data.Items;
                        this.total = data.TotalCount;
                        res();
                    }).catch(err => {
                        rej(err);
                    })
                }).then(() => {
                    if (this.loading.close) {
                        this.loading.close();
                    }
                }).catch((err) => {
                    this.$throw(err);
                    if (this.loading.close) {
                        this.loading.close();
                    }
                })
            },
            current_change: function (currentPage) {
                this.currentPage = currentPage;
                this.select();
            },
            pagesize_change: function (pagesize) {
                this.pagesize = pagesize;
                this.currentPage = 1;
                this.select();

            },
           insert() {
            // 新增对话框
                this.modalData = {
                    Sid: "",
                    Name: "",
                    Sex: "",
                    Type: "",
                    Nation: "",
                    Birthday: "",
                    Address: "",
                    IdNumber: "",
                    IsEnabled: true,
                    VoiceText: "",
                    Describe: ""
                };

                this.modalTitle = this.$t("创建人脸库");
                this.$refs.modallg.show();
            },
            insert2(data) {
            // 新增对话框
                if(data == null) {
                    data = this.facesetArray[0];
                    this.modalData2.FaceSetSid = data.Sid;
                    this.modalData2.FaceSetName = data.Name;
                    this.modalData2.Describe = data.Describe;
                    this.modalData2.ImageUrl = data.CoverUrl;
                } else {
                    this.modalData2 = {
                        Sid: null,      // 为空表示新增
                        FaceSetSid: data.FaceSet.Sid, // 人脸集合Sid
                        FaceSetName: data.FaceSet.Name,
                        DeviceSid: data.DeviceName,  // 抓拍设备Sid
                        ImageUrl: data.FaceUrl,   // 人脸地址
                        Describe: data.Describe,
                    };
                    this.faceSetSid = data.FaceSet.Sid;
                }
                this.modalTitle2 = this.$t("注册人脸");
                this.$refs.modallg2.show();
            },
            selectDialog(){
                //let that = this;
                this.$refs.modallg.hide();
                this.getFacesets();

            },
            getFacesets(data){
            // 获取人脸集合列表
                //this.loading = this.$loading();
                this.$ksajax
                    .ksPostPromise("/api/face/GetFaceSets",{"Index":0,"Count":1000}, true)
                    .then((r) => {
                       //this.loading.close();
                       this.facesetArray = r.Items;
                       this.insert2(data);
                    })
                    .catch(err => {
                        this.$throw(err);
                        //this.loading.close();
                    });
            },
            hiden(name) {
            // 隐藏对话框
                if (!name) {
                    this.$refs.modallg.hide();
                    this.$refs.modallg2.hide();
                }
                else {

                    this.$refs[name].hide();
                }
            },
            onSubmit(evt) {
            // 提交对话框
                evt.preventDefault();
                this.loading = this.$loading();
                this.$ksajax
                    .ksPostPromise("/api/face/AddFaceSet", this.modalData, true)
                    .then(() => {
                        this.loading.close();
                        this.hiden();
                        this.select();
                    })
                    .catch(err => {
                        this.$throw(err);
                        this.loading.close();
                        this.hiden();
                    });
            },
            onSubmit1(evt) {
            // 提交对话框
                evt.preventDefault();
                this.loading = this.$loading();
                this.$ksajax
                    .ksPostPromise("/api/face/RegisterFace", this.modalData2, true)
                    .then(() => {
                        this.loading.close();
                        this.hiden();
                        this.select();
                    })
                    .catch(err => {
                        this.$throw(err);
                        this.hiden();
                        this.loading.close();
                    });
            },

            checkChange(sid) {
                if (sid) {
                    this.sid = sid;
                    if (this.curChecked.length > 1) this.curChecked.shift();
                    //this.curChecked.push(sid);
                }
            },
            del() {
            // 删除集合
                if (this.curChecked == null) {
                    throw this.$t("选择一条数据");
                }
                // this.sid = this.curChecked;
                this.curChecked = [];
                this.$refs.del.show();
            },

            modify() {
            // 修改对话框
                if (this.current == null) {
                    throw this.$t("选择一条数据");
                }
                this.modalTitle = this.$t("修改人脸库");
                if (this.current) {
                    this.modalData = this.current;
                }
                else {
                    this.modalData = {
                        Sid: "",
                        Name: "",
                        Sex: "",
                        Type: "",
                        Nation: "",
                        Birthday: "",
                        Address: "",
                        IdNumber: "",
                        IsEnabled: true,
                        VoiceText: "",
                        Describe: ""
                    };
                }
                this.$refs.modallg.show();
            },

            currentRow(val) {
                this.current = val;
            },

            submited() {
                this.select();
            },

            deleted() {
                this.select();
            },
            // 查看详情
            detail() {
                if (this.current == null) { throw this.$t("选择一条数据"); }
                this.$router.push('/admin/face/detail?sid=' + this.current.Sid + "&setname=" + this.current.Name);
            },
            // 点击图片添加人脸
            imgClick(data) {
                // 查看有没有库
                if(data.FaceSet === null){
                    this.insert();
                } else {
                    this.getFacesets(data);
                    //this.insert2(data);
                }
            },
        },
        mounted: function () {
            this.select();
        },
        watch:{
            faceSetSid: function(val){
                var fs = this.facesetArray.find(function(element){
                    return element.Sid === val;
                });
                this.modalData2.FaceSetSid = fs.Sid;
                this.modalData2.FaceSetName = fs.Name;
                this.modalData2.Describe = fs.Describe;
                this.modalData2.ImageUrl = fs.CoverUrl;
            }
        }
    }
</script>
