<template>
  <div class="white">
    <actionbar
      :insert="true"
      :modify="true"
      :del="true"
      :detail="true"
      :select="true"
      v-on:insert="insert"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:detail="detail"
      v-on:select="select"
    >
    </actionbar>

    <el-table
    stripe=""
      :data="items"
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column
        :label="$t('序号')"
        type="index"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column :label="$t('封面')" width="50">
        <template slot-scope="scope">
          <b-img
            :src="scope.row.FaceUrl"
            width="40"
          ></b-img>
          <!--<p>{{scope.row.CoverUrl}}</p>-->
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('姓名')"
        prop="Name"
        width="250"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column :label="$t('性别')">
        <template slot-scope="scope">
          <span v-if="scope.row.Sex == 0">男</span>
          <span v-if="scope.row.Sex == 1">女</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('民族')" prop="Nation">
        <template slot-scope="scope">
          <span v-if="scope.row.Nation">{{ scope.row.Nation }}</span>
          <span v-if="!scope.row.Nation">-</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('出生')" prop="Birthday" width="120">
        <template slot-scope="scope">
          <span
            v-if="scope.row.Birthday && scope.row.Birthday != '0001/01/01'"
            >{{ scope.row.Birthday }}</span
          >
          <span v-if="!scope.row.Birthday">-</span>
          <span v-if="scope.row.Birthday == '0001/01/01'">-</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('部门')" prop="FacePartyName">
      </el-table-column>
      <!-- <el-table-column :label="$t('类型')" prop="Type">
        <template slot-scope="scope">
          <span v-if="scope.row.Type == 0">研发部</span>
          <span v-if="scope.row.Type == 1">市场部</span>
          <span v-if="scope.row.Type == 2">业务部</span>
          <span v-if="scope.row.Type == 3">公司高层</span>
          <span v-if="scope.row.Type == 3">来访客人</span>
        </template>
      </el-table-column> -->
      <el-table-column :label="$t('状态')" prop="IsEnabled">
        <template slot-scope="scope">
          <span v-if="scope.row.IsEnabled">启用</span>
          <span v-if="!scope.row.IsEnabled">禁用</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('住址')" prop="Address">
        <template slot-scope="scope">
          <span v-if="scope.row.Address">{{ scope.row.Address }}</span>
          <span v-if="!scope.row.Address">-</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('广播提示语')" prop="VoiceText" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.VoiceText">{{ scope.row.VoiceText }}</span>
          <span v-if="!scope.row.VoiceText">-</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('备注')" prop="Describe" width="250">
        <template slot-scope="scope">
          <span v-if="scope.row.Describe">{{ scope.row.Describe }}</span>
          <span v-if="!scope.row.Describe">-</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      layout=" prev, pager, next, total,sizes"
      @current-change="current_change"
      @size-change="pagesize_change"
      :current-page="currentPage"
      :page-sizes="[10, 50, 100, 200]"
      :page-size="pagesize"
      :total="total"
      background
    >
    </el-pagination>

    <!-- 新增、修改对话框 -->
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="modalTitle"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        
        <b-form-group  v-bind:label="$t('封面')" :label-cols="2">
          <el-upload
            :on-success="Uploaded"
            class="upload-demo"
            :action="baseUrl+'/api/Upload/FileUploadSingle'"
            :file-list="fileList"
            :data="fileData"
            :limit="1"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            accept=".jpg"
          >
            <el-button size="small" type="primary">{{$t('上传到服务器')}}</el-button>
            <div slot="tip" class="el-upload__tip">
               只支持jpg格式的人脸图片
            </div>
          </el-upload>
        </b-form-group>
        <b-form-group v-bind:label="$t('姓名')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.Name"
            required
            placeholder="请输入姓名（必填）"
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('身份证')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.IdNumber"></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('民族')" :label-cols="2">
          <b-form-select
            :options="initData.Nation"
            v-model="modalData.Nation"
          ></b-form-select>
        </b-form-group>
        <b-form-group v-bind:label="$t('类型')" :label-cols="2">
          <b-form-select
            text-field="Name"
            value-field="Sid"
            :options="partys"
            v-model="modalData.FacePartySid"
          ></b-form-select>
        </b-form-group>
        <b-form-group v-bind:label="$t('生日')" :label-cols="2">
          <el-date-picker
            required
            v-model="modalData.Birthday"
            type="date"
          ></el-date-picker>
        </b-form-group>
        <b-form-group v-bind:label="$t('住址')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Address"></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('性别')" :label-cols="2">
          <b-form-radio-group v-model="modalData.Sex">
            <b-form-radio :value="0">男</b-form-radio>
            <b-form-radio :value="1">女</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group v-bind:label="$t('状态')" :label-cols="2">
          <b-form-radio-group v-model="modalData.IsEnabled">
            <b-form-radio :value="false">禁用</b-form-radio>
            <b-form-radio :value="true">启用</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group v-bind:label="$t('广播提示语')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.VoiceText"
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('备注')" :label-cols="2">
          <b-form-input
            type="textarea"
            v-model="modalData.Describe"
            autosize
            placeholder="请输入备注"
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width: 100px" v-on:click="hiden(modallg)"
              >Cancel</b-button
            >
            <b-button type="submit" style="width: 100px" variant="primary"
              >OK</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- 删除多好看 -->
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/face/DelFaceSet"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>

<script>
import actionbar from "@/components/actionbar";
import {BaseUrl} from "@/assets/utils/config"
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
import alarmAction from "@/components/alarmAction";
import { Link } from "@/assets/utils/datef";
export default {
  components: { actionbar, tablepage, modify, deletedata, alarmAction },
  data: function () {
    return {
      baseUrl:BaseUrl,
      fileList:[],
      fileData:{},
      radio: 0,
      modalTitle: this.$t("创建人脸库"),
      modalData: {},
      initData: {
        Nation: [
          { text: "汉族", value: "汉族" },
          { text: "其他民族", value: "其他民族" },
        ],
        Type: [
          { text: "研发部", value: "0" },
          { text: "市场部", value: "1" },
          { text: "业务部", value: "2" },
          { text: "公司高层", value: "3" },
          { text: "来访客人", value: "4" },
        ],
      },
      loading: {},

      items: [],
      total: 0, // 默认数据总数
      pagesize: 10, // 每页的数据条数
      currentPage: 1, // 默认开始页面
      curIndex: 0,
      index: 0,
      sid: "",
      current: null,
      link: Link().filter(function (x) {
        return x.state == 1;
      }),
      selfChoose: {
        radio: null,
        devs: [],
        selected: {},
      },
      flagChoose: {
        radio: null,
        devs: [],
        selected: {},
      },
      leds: [],
      partys:[]
    };
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    Uploaded(response, file) {
      if (response.RetCode == 0) {
        file.url = response.Data;
        this.fileList.push(file);
        this.modalData.FaceUrl=file.url;
      } else {
        throw response.RetMsg;
      }
    },
    handlePreview() {},
    unknowFace() {
      // 查看更多人脸
      this.$router.push("/admin/face/MoreFace");
    },
    getPartys(){
      if(this.partys.length==0){
         this.loading = this.$loading({ fullScreen: true });
          this.$ksajax
          .ksPostPromise("/api/face/GetFacePartys", "", true).then(data=>{
            
            this.loading.close();
            this.partys=data;
            console.log(this.partys);
          }).catch(err=>{
            
          this.$throw(err);
          if (this.loading.close) {
            this.loading.close();
          }
          });
      }
    },
    select(noloading, index) {
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      new Promise((res, rej) => {
        let obj = {
          Index: (this.currentPage - 1) * this.pagesize,
          Count: this.pagesize,
        };
        this.$ksajax
          .ksPostPromise("/api/face/GetFaceSets", obj, true)
          .then((data) => {
            //data.Items.forEach(x => {
            //if (!x.Nation) x.Nation = "---";
            //if (!x.IdNumber) x.IdNumber = "---";
            //if (!x.Address) x.Address = "---";
            //if (!x.VoiceText) x.VoiceText = "---";
            //if (!x.Describe) x.Describe = "---";
            //});
            this.items = data.Items;
            this.total = data.TotalCount;
            res();
          })
          .catch((err) => {
            rej(err);
          });
      })
        .then(() => {
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err);
          if (this.loading.close) {
            this.loading.close();
          }
        });
        this.getPartys();
    },
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.curIndex = (this.currentPage - 1) * this.pagesize;
      this.select();
    },
    pagesize_change: function (pagesize) {
      this.pagesize = pagesize;
      this.currentPage = 1;
      this.curIndex = (this.currentPage - 1) * this.pagesize;
      this.select();
    },
    indexMethod: function (index) {
      return index + this.curIndex + 1;
    },
    // 新增对话框
    insert() {
      this.modalData = {
        Sid: "",
        Name: "",
        Sex: "",
        Type: "",
        Nation: "",
        Birthday: "",
        Address: "",
        IdNumber: "",
        IsEnabled: true,
        VoiceText: "",
        Describe: "",
      };

      this.modalTitle = this.$t("创建人脸库");
      this.$refs.modallg.show();
    },
    // 隐藏对话框
    hiden(name) {
      if (!name) {
        this.$refs.modallg.hide();
      } else {
        this.$refs[name].hide();
      }
    },
    // 提交对话框
    onSubmit(evt) {
      evt.preventDefault();
      this.loading = this.$loading();
      this.modalData.Type=0;
      this.$ksajax
        .ksPostPromise("/api/face/AddFaceSet", this.modalData, true)
        .then(() => {
          this.loading.close();
          this.hiden();
          this.select();
        })
        .catch((err) => {
          this.$throw(err);
          this.hiden();
        });
    },

    // 删除集合
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },

    // 修改对话框
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.modalTitle = this.$t("修改人脸库");
      if (this.current) {
        this.modalData = this.current;
        console.log(this.modalData);
      } else {
        this.modalData = {
          Sid: "",
          Name: "",
          Sex: "",
          Type: "",
          Nation: "",
          Birthday: "",
          Address: "",
          IdNumber: "",
          IsEnabled: true,
          VoiceText: "",
          Describe: "",
        };
      }

      this.$refs.modallg.show();
    },
    currentRow(val) {
      this.current = val;
    },

    submited() {
      this.select();
    },
    deleted() {
      this.select();
    },
    // 查看详情
    detail() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.$router.push(
        "/admin/face/detail?sid=" +
          this.current.Sid +
          "&setname=" +
          this.current.Name
      );
    },
  },
  mounted: function () {
    this.select();
  },
};
</script>
