<template>
  <div>
    <textarea id="editor_id" name="content" style="width:700px;height:300px;" v-model="value">
</textarea>
  </div>
</template>
<script>
export default {
    props:{
        value:{
            type:String
        }
    },
  data: function() {
    return {
      editor: null,
    };
  },
  mounted: function() {
    this.$nextTick(() => {
      this.editor = window.KindEditor.create("#editor_id",this.getOption());
      console.log(this.editor,"tet");
    });
  },
  methods: {
      getHtml(){
          return this.editor.html();
      },
      setHtml(val){
          this.editor.html(val);
      },
      afterChange(){
          if(this.editor){
          this.$emit("input",this.editor.html())

          }
      },
    getOption() {
      return {
          afterChange:this.afterChange,
        width: "100%",
        height: "100%",
        items: [
          "source",
          "|",
          "undo",
          "redo",
          "|",
          "preview",
          "code",
          "cut",
          "copy",
          "paste",
          "plainpaste",
          "wordpaste",
          "|",
          "justifyleft",
          "justifycenter",
          "justifyright",
          "justifyfull",
          "clearhtml",
          "quickformat",
          "selectall",
          "|",
          "fontname",
          "fontsize",
          "|",
          "forecolor",
          "hilitecolor",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "lineheight",
          "removeformat",
          "|",
          "about",
          "fullscreen"
        ]
      };
    }
  }
};
</script>