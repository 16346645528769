<template>
    
    <div class="ligthview" v-loading="loading">
        <div class="ligthview_l">
            <div>
                <box>
                    <div class="ksflex" style="height:100%;padding:10px">
                        <div style="flex:1">
                            <div class="maxnumber">
                                {{current.TotalCount}}
                            </div>
                            <div class="content_text">{{$t("总数量")}}</div>
                        </div>
                        <div style="flex:1;text-align:right">
                            <img src="../../../../public/img/saveicon.png" />
                        </div>
                    </div>
                </box>
            </div>
        </div>
        <div class="ligthview_l">
            <div>
                <box>
                    <div class="ksflex" style="height:100%;padding:10px">
                        <div style="flex:1">
                            <div class="maxnumber">
                                {{current.OnlineCount}}
                            </div>
                            <div class="content_text">{{$t("在线数量")}}</div>
                        </div>
                        <div style="flex:1;text-align:right">
                            <img src="../../../../public/img/onlineicon.png" />
                        </div>
                    </div>
                </box>
            </div>
        </div>
        <div class="ligthview_l">
            <div>
                <box>
                    <div class="ksflex" style="height:100%;padding:10px">
                        <div style="flex:1">
                            <div class="maxnumber">
                                {{current.OfflineCount}}
                            </div>
                            <div class="content_text">{{$t("离线数量")}}</div>
                        </div>
                        <div style="flex:1;text-align:right">
                            <img src="../../../../public/img/lighticon.png" />
                        </div>
                    </div>
                </box>
            </div>
        </div>
        <div class="ligthview_2">
            <div>
                <box>
                    <div style="height:100%;width:100%" id="mychat">

                    </div>
                </box>
            </div>
        </div>
        <div class="ligthview_2">
            <div>
                <box>
                    <div style="height:100%;width:100%" id="mychat1">

                    </div>

                </box>
            </div>
        </div>
        <div class="ligthview_2">
            <div>
                <box>
                    <div style="height:100%;width:100%" id="leftBottom">

                    </div>
                </box>
            </div>
        </div>
        <div class="ligthview_2">
            <div>
                <box>
                    <div style="height:100%; width:100%" id="rightBottom">

                    </div>
                </box>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>
<script>
    import box from "@/components/box1.vue"
    export default {
        components: { box },
        data: function () {
            return {
                loading: false,
                current: {},
                mychat: null,
                mychat1: null,
                color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#2978c9' },
                    { offset: 0.5, color: '#00b2e4' },
                    { offset: 1, color: '#00e7fd' }]),
                color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#ffad42' },
                    { offset: 0.5, color: '#ffc54b' },
                    { offset: 1, color: '#ffd450' }]),
                color3: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#f7500f' },
                    { offset: 0.5, color: '#eec54b' },
                    { offset: 1, color: '#f7500f' }]),
            }
        },
        mounted: function () {
            this.select();
            this.mychat = window.echarts.init(document.getElementById("mychat"));
            this.mychat1 = window.echarts.init(document.getElementById("mychat1"));
            this.leftBottom = window.echarts.init(document.getElementById("leftBottom"));
            this.rightBottom = window.echarts.init(document.getElementById("rightBottom"));
        },
        methods: {
            getOption(xAxis, yAxis, datas) {
                var obj = {
                    textStyle:
                    {
                        color: '#8495c2'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: xAxis,
                    yAxis: yAxis,
                    series: datas,
                    legend: {
                        data: [],
                        textStyle:
                        {
                            color: '#ffffff'
                        },
                        orient: 'vertical',
                        right: 50,
                    }
                }
                return obj;
            },
            beginLeftTop(j, s) {
                var on = j
                var off = s;
                var sub = j + s
                var option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b}: {c} ({d}%)"
                    },
                    legend: {
                        left: 5,
                        bottom: 5,
                        orient: "vertical",
                        data: [this.$t('在线数量'), this.$t('离线数量')],
                        textStyle:
                        {
                            color: '#ffffff'
                        }
                    },
                    series: [
                        {
                            radius: ['60%', '90%'],
                            name: this.$t('总数量'),
                            type: 'pie',
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    formatter: [`{title|${this.$t('总数量')}}`, `{count|${sub}}`].join('\n'),
                                    show: true,
                                    position: 'center',
                                    rich: {
                                        title: {
                                            color: "white",
                                            fontSize: 20
                                        },
                                        count: {
                                            color: "#8495c2",
                                            fontSize: 14,
                                            padding: [0, 0, 10, 0],
                                        }
                                    }
                                },
                            },
                            labelLine: {
                                normal: {
                                    show: true
                                }
                            },
                            data: [
                                { value: on, name: this.$t('在线数量') },
                                { value: off, name: this.$t('离线数量') },
                            ],
                            color: [this.color1, this.color2, this.color3]
                        },

                    ],
                    grid: {
                        top: 0,
                        right: 100,
                        bottom: 0,
                        containLabel: false
                    }
                };
                option.title = {
                    show: true,
                    text: this.$t("设备分析"),
                    "padding": [20, 0, 0, 20],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                }
                this.mychat.setOption(option, true);

                window.addEventListener("resize", () => {
                    this.mychat.resize();
                });
            },
            beginLeftBottom(j, s) {
                var on = j
                var off = s;
                var sub = j + s;
                var option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b}: {c} ({d}%)"
                    },
                    legend: {
                        left: 5,
                        bottom: 5,
                        orient: "vertical",
                        data: [this.$t('已发布'), this.$t('未发布')],
                        textStyle:
                        {
                            color: '#ffffff'
                        }
                    },
                    series: [
                        {
                            radius: ['60%', '90%'],
                            name: this.$t('总数量'),
                            type: 'pie',
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    formatter: [`{title|${this.$t('总数量')}}`, `{count|${sub}}`].join('\n'),
                                    show: true,
                                    position: 'center',
                                    rich: {
                                        title: {
                                            color: "white",
                                            fontSize: 20
                                        },
                                        count: {
                                            color: "#8495c2",
                                            fontSize: 14,
                                            padding: [0, 0, 10, 0],
                                        }
                                    }
                                },
                            },
                            labelLine: {
                                normal: {
                                    show: true
                                }
                            },
                            data: [
                                { value: on, name: this.$t('已发布') },
                                { value: off, name: this.$t('未发布') },
                            ],
                            color: [this.color1, this.color3]
                        },

                    ],
                    grid: {
                        top: 0,
                        right: 100,
                        bottom: 0,
                        containLabel: false
                    }
                };
                option.title = {
                    show: true,
                    text: this.$t("节目分析"),
                    "padding": [20, 0, 0, 20],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                }
                this.leftBottom.setOption(option, true);

                window.addEventListener("resize", () => {
                    this.leftBottom.resize();
                });
            },
            beginRightBottom() {

                var option = {
                    textStyle:
                    {
                        color: '#8495c2'
                    },
                    color: [this.color3],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: [this.$t('在线数量'), this.$t('离线数量'), this.$t("开屏数量"), this.$t('总数量')],
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: this.$t('能耗分析'),
                            type: 'bar',
                            barWidth: '60%',
                            data: [this.current.OnlineCount, this.current.OfflineCount, this.current.OpenCount, this.current.TotalCount
                            ]
                        }
                    ]
                };
                 option.title= {
                     show: true,
                     text:this.$t("能耗分析"),
                     "padding":[20, 0, 0 ,20], 
                     textStyle:
                        { 
                            color: '#ffffff'
                        },
                    }
                this.mychat1.setOption(option, true);

                window.addEventListener("resize", () => {
                    this.mychat1.resize();
                });
            },
            beginRightBottomChart() {
                var data = this.current.Resources;
                var online = [];
                var labels = [];
                for (var i = 0; i < data.length; i++) {
                    online.push(data[i].Count);
                    labels.push(this.$t(`${data[i].Name}`));
                }
                var datas = [];
                datas.push({
                    name: this.$t('数量'),
                    type: 'line',
                    stack: this.$t('总数量'),
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            color: "white"
                        }
                    }, smooth: true,
                    data: online,
                    itemStyle: {
                        color: "#1b85c9"
                    },
                    areaStyle: {
                        normal: {
                            color: new window.echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: '#00749a' },
                                    { offset: 0.5, color: '#123e62' },
                                    { offset: 1, color: '#132f57' }
                                ]
                            )
                        }
                    },
                });
                var x = {
                    type: "category",
                    boundaryGap: false,
                    data: labels
                }
                var y = {
                    splitLine: {
                        lineStyle: {
                            color: "#8495c2"
                        }
                    },
                    type: "value"
                }
                var option = this.getOption(x, y, datas);
                option.title = {
                    show: true,
                    text: this.$t("资源分析"),
                    "padding": [5, 0, 0, 20],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                },
                    this.rightBottom.setOption(option);

                window.addEventListener("resize", () => {
                    this.rightBottom.resize();
                });
            },
            select() {
                this.loading = true;
                this.$ksajax.ksPostPromise("/api/overview/GetLedAdStatistics", "", true).then(data => {
                    this.loading = false;
                    data.OfflineCount = data.TotalCount - data.OnlineCount;
                    this.current = data;
                    this.beginLeftTop(this.current.OnlineCount, this.current.OfflineCount);
                    this.beginLeftBottom(this.current.Stage.PublishedCount, this.current.Stage.UnpublishedCount);
                    this.beginRightBottom();
                    this.beginRightBottomChart();

                }).catch(err => {
                    this.$throw(err);
                    this.loading = false;
                })
            }
        }
    }
</script>

