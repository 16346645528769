<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="item in items"
        href="#"
        variant="primary"
        :active="item.Active"
        @click="itemClick(item.SZ.uid)"
        :key="item.SZ.uid"
        >{{ item.SZ.name }}</b-list-group-item
      >
    </b-list-group>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      loading: {},
      items: [],
    };
  },
  mounted: function () {
    //查询网关列表
    let obj = {
      Method: "read",
      SZ: {
        platform: 1,
        type: 1,
        skip: 0,
        limit: 100000,
        name: "",
      },
    };
    this.loading = this.$loading({ fullScreen: true });
    this.$ksajax
      .ksPostPromise("/api/agent/RequestSzApi", obj, true)
      .then((data) => {
        data.Items.forEach((x) => {
          x.Active = false;
        });
        this.items = data.Items;
        this.loading.close();
      })
      .catch((err) => {
        this.loading.close();
        this.$throw(err);
      });
  },
  methods: {
    itemClick(uid) {
      this.items.forEach((x) => {
        if (x.SZ.uid == uid) {
          x.Active = true;
          this.$emit("itemclick", x.SZ);
        } else {
          x.Active = false;
        }
      });
    },
  },
};
</script>
