<template>
    <div id="ledtemplete white" :style="style">
         <template v-for="item in modalData.StateCotnents">
                    <vue-draggable-resizable :parent="true" 
                    :resizable="false" :x="item.X" :y="item.Y" :z="item.Z" :w="item.Width" 
                    :h="item.Height"
                    :key="item.Sid">
                    <div v-if="item.Data.Type==0||item.Data.Type==3" :style="item.Custom">
                        <div>{{item.Data.Content}}</div>
                    </div>
                    <div v-if="item.Data.Type==5||item.Data.Type==2" :style="item.Custom">
                        {{item.Data.Name}}
                    </div>
                    <div v-if="item.Data.Type==1" :style="item.Custom">
                        <b-img class="autoimg" :src="item.Data.Content"></b-img>
                    </div>
                    <div v-if="item.Data.Type==4" :style="item.Custom">
                        <b-img  class="autoimg" :src="JSON.parse(item.Data.Content)[0]"></b-img>
                    </div>
                    </vue-draggable-resizable>
                </template>
    </div>
</template>

<script>
export default {
    props:["modalData"],
    data:function(){
        return {
            style:{
                margin:"0px auto",
                position:"relative"
            }
        }
    },
    mounted:function(){
        this.style.width=this.modalData.Width+"px";
        this.style.height=this.modalData.Height+"px";
    }
}
</script>
