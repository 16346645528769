<template>
  <div style="color: white; height: 100%">
    <div id="todetail">
      <router-link :to="`/admin/stand/commondevice?sid=${$route.query.sid}`">{{
        $t("详情")
      }}</router-link>
    </div>
    <img :src="borderImg" style="display: none" id="borderImg" />
    <img :src="coverImg" style="display: none" id="coverImg" />
    <div class="detailview">
      <div class="detailview_1">
        <div>
          <box style="position: relative">
            <div
              id="centerTop"
              style="position: absolute; top: 20px; left: 20px"
            >
              <div style="font-size: 1.5rem">{{ $t("井盖监控") }}</div>
            </div>
            <div id="leftTop" style="width: 100%; height: 100%"></div>
          </box>
        </div>
      </div>
      <div class="detailview_1">
        <div>
          <box id="canvas_box" style="position: relative">
            <div style="padding: 20px 0 0 20px; width: 100%">
              <div style="font-size: 1.5rem">{{ name }}</div>
            </div>
            <canvas id="canvas" style="position: absolute; z-index: 0"></canvas>
            <canvas
              id="canvas_cover"
              style="position: absolute; z-index: 0"
            ></canvas>
            <div style="position: absolute; right: 0; height: 30%; width: 20%">
              <alarm ref="alarm"></alarm>
            </div>
          </box>
        </div>
      </div>
      <div class="detailview_1">
        <div>
          <box>
            <div class="ksflexcol" style="height: 100%">
              <div style="padding: 20px 0 0 20px; width: 100%">
                <div style="font-size: 1.5rem">{{ $t("参数设置") }}</div>
                <div class="content_text" style="font-size: 1.2rem">Set</div>
              </div>
              <div
                class="ksflexcol"
                style="flex: 1; width: 100%; padding-top: 20px; font-size: 1rem"
              >
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ $t("报警角度") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ cover.AlarmAngle }}
                  </div>
                </div>

                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ $t("当前角度") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ cover.Angle }}度
                  </div>
                </div>
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ $t("电池电压") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ cover.Voltage }}
                  </div>
                </div>
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ $t("报警间隔") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ cover.AlarmInterval }}{{ $t("秒") }}
                  </div>
                </div>
              </div>
            </div>
          </box>
        </div>
      </div>
      <div class="detailview_2">
        <div>
          <box>
            <div
              id="leftBottom"
              style="width: 100%; height: 100%"
              v-loading="leftBottomLoading"
            ></div>
          </box>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import box from "@/components/box1.vue";
import alarm from "@/components/alarm";
export default {
  components: { box, alarm },
  data: function () {
    return {
      cover: {},
      loading: true,
      coverCurrentAngel: 0,
      coverValue: 0,
      borderRate: 0.489,
      coverRate: 0.201,
      startPosition: [],
      converStartPosition: [],
      coverWidth: 0,
      coverHeight: 0,
      borderHeight: 0,
      borderWidth: 0,
      canvas: null,
      canvas_cover: null,
      borderImg: require("@/assets/img/ic_jinggai.png"),
      coverImg: require("@/assets/img/ic_giazi.png"),
      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" },
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" },
      ]),
      startCoverValueThread: null,
      startCoverThread: null,
      alarm: false,
      leftBottomLoading: false,
      name: "",
    };
  },
  watch: {
    alarm: function () {
      if (this.alarm) {
        this.$refs.alarm.start(true);
      } else {
        this.$refs.alarm.start(false);
      }
    },
    "$store.state.alarmHubChange": function () {
      var data = this.$store.state.alarmHub;
      if (data.Serial == this.cover.Serial) {
        this.cover.Angle = this.coverValue = data.Data.Ag;
        if (data.Type == 86) {
          this.alarm = false;
        } else {
          this.alarm = true;
        }
      }
    },
    "$store.state.dataHubChange": function () {
      var data = this.$store.state.dataHub;
      if (data.Serial == this.cover.Serial) {
        this.cover.Angle = this.coverValue = data.Data.Ag;
      }
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      this.leftTop = window.echarts.init(document.getElementById("leftTop"));
      this.leftBottom = window.echarts.init(
        document.getElementById("leftBottom")
      );

      // var data1=[];
      // for(var i=0;i<1;i++){
      //     var sub={
      //         Time:`2018/12/${i+1}`,
      //         Data:[]
      //     }
      //     for(var j=0;j<24;j++){
      //     var t= ("0" + j ).substr( -2 );
      //         sub.Data.push({
      //             Time:`${t}:00`,
      //             Count:Math.random()*100
      //         })
      //     }
      //     data1.push(sub);
      // }
      //  this.beginLeftBottomChart(data1);
      this.beginLeftTopChart();
      this.canvas = document.getElementById("canvas");
      this.canvasCover = document.getElementById("canvas_cover");
      this.canvas.width = document.getElementById("canvas_box").clientWidth;
      this.canvas.height = document.getElementById("canvas_box").clientHeight;
      this.canvasCover.width = document.getElementById(
        "canvas_box"
      ).clientWidth;
      this.canvasCover.height = document.getElementById(
        "canvas_box"
      ).clientHeight;
      var width = this.canvas.width / 3;
      var height = width * this.borderRate;
      if (height > this.canvas.height) {
        height = this.canvas.height * 0.25;
        width = height / this.borderRate;
      }
      this.startPosition = [width, (this.canvas.height - height) / 2];
      this.borderHeight = height;
      this.borderWidth = width;
      var img = document.getElementById("borderImg");
      img.onload = this.drawBorder;
      this.drawBorder();
      var r = 0.76359;
      this.coverWidth = this.borderWidth * r;
      this.coverHeight = this.coverWidth * this.coverRate;
      this.converStartPosition = [
        this.startPosition[0] + (this.borderWidth - this.coverWidth) / 2,
        this.startPosition[1],
      ];
      var coverImg = document.getElementById("coverImg");
      coverImg.onload = this.drawCover;
      this.drawCover();
      //    this.startCoverValueThread=this.startCoverValue();
      // this.startCoverThread=this.startCover();
      this.select();
    });
  },
  methods: {
    leftBottomChat() {
      this.leftBottomLoading = true;
      this.$ksajax
        .ksPostPromise(
          "/api/device/GetRecentlyWellCoverDatas",
          this.$route.query.sid,
          true
        )
        .then((data) => {
          var data1 = [];
          data.forEach((x) => {
            var m = new Date().getMonth();
            var sub = {
              Time: `${m}/${x[0].Day}`,
              Data: [],
            };
            x.forEach((y) => {
              var t = ("0" + y.Hour).substr(-2);
              if (!y.Wl || y.Wl == 0) {
                y.Wl = Math.random();
              }
              sub.Data.push({
                Time: `${t}:00`,
                Count: y.Wl,
              });
            });
            data1.push(sub);
          });
          this.beginLeftBottomChart(data1);
          this.leftBottomLoading = false;
        })
        .catch((err) => {
          this.leftBottomLoading = false;
          this.$throw(err);
        });
    },
    select() {
      var sid = this.$route.query.sid;
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/device/GetWellCoverInfo", sid, true)
        .then((data) => {
          this.loading = false;
          this.cover = data;
          this.coverValue = data.Angle;
          this.name = data.DeviceName;
          this.leftBottomChat();
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    test() {
      this.coverCurrentAngel = this.coverCurrentAngel + 1;
      this.drawCoverAngle(this.coverCurrentAngel);
    },
    drawBorder() {
      var ctx = this.canvas.getContext("2d");
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineWidth = 10;
      ctx.strokeStyle = "white";
      var img = document.getElementById("borderImg");
      ctx.drawImage(
        img,
        this.startPosition[0],
        this.startPosition[1],
        this.borderWidth,
        this.borderHeight
      );
      ctx.stroke();
      ctx.closePath();
    },
    drawCover() {
      var ctx = this.canvasCover.getContext("2d");
      ctx.clearRect(0, 0, this.coverWidth, this.coverHeight);
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineWidth = 10;
      ctx.strokeStyle = "white";
      var img = document.getElementById("coverImg");
      ctx.drawImage(
        img,
        this.converStartPosition[0],
        this.converStartPosition[1],
        this.coverWidth,
        this.coverHeight
      );
      ctx.stroke();
      ctx.closePath();
      window.startCoverThread = this.startCover();
    },
    drawCoverAngle(value) {
      var ctx = this.canvasCover.getContext("2d");

      ctx.clearRect(0, 0, this.canvasCover.width, this.canvasCover.height);
      ctx.save();
      ctx.translate(this.converStartPosition[0], this.converStartPosition[1]);
      ctx.beginPath();
      ctx.rotate((360 - value) * (Math.PI / 180));
      var img = document.getElementById("coverImg");
      ctx.drawImage(img, 0, 0, this.coverWidth, this.coverHeight);
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    },
    startCover() {
      return window.setInterval(() => {
        if (this.coverCurrentAngel != this.coverValue) {
          if (this.coverCurrentAngel > this.coverValue) {
            this.coverCurrentAngel -= 5;
            if (this.coverCurrentAngel < this.coverValue) {
              this.coverCurrentAngel = this.coverValue;
            }
          } else {
            this.coverCurrentAngel += 5;
            if (this.coverCurrentAngel > this.coverValue) {
              this.coverCurrentAngel = this.coverValue;
            }
          }
          this.drawCoverAngle(this.coverCurrentAngel);
        }
      }, 100);
    },
    beginLeftTopChart() {
      var font = 12;
      if (window.innerWidth > 1366) {
        font = 20;
      }
      var on = 30;
      var sub = on + 0;
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          left: 20,
          bottom: 20,
          orient: "vertical",
          data: [this.$t("报警次数")],
          textStyle: {
            color: "#ffffff",
            fontWeight: "normal",
          },
        },
        series: [
          {
            name: this.$t("报警率"),
            type: "pie",
            radius: ["30%", "60%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: [
                  `{title|${this.$t("总数量")}}`,
                  `{count|${sub}}`,
                ].join("\n"),
                show: true,
                position: "center",
                rich: {
                  title: {
                    color: "white",
                    fontSize: font,
                  },
                  count: {
                    color: "#8495c2",
                    fontSize: font,
                    padding: [0, 0, 10, 0],
                  },
                },
              },
            },
            labelLine: {
              normal: {
                show: true,
              },
            },
            data: [{ value: on, name: this.$t("报警次数") }],
            color: [this.color2],
          },
        ],
        grid: {
          top: 0,
          right: 100,
          bottom: 0,
          containLabel: false,
        },
      };
      this.leftTop.setOption(option, true);
      window.addEventListener("resize", () => {
        this.leftTop.resize();
      });
    },
    startCoverValue() {
      return window.setInterval(() => {
        this.coverValue = parseInt(Math.random() * 180);
        if (this.coverValue >= 90) {
          this.alarm = true;
        } else {
          this.alarm = false;
          this.coverValue = 0;
        }
      }, 6000);
    },
    beginLeftBottomChart(data) {
      var datas = [];
      var names = [];
      for (var j = 0; j < data.length; j++) {
        var area = {};
        var line = "#00e5fc";
        if (j == 0) {
          area = {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(24,203,231,0.7)" },
                { offset: 0.5, color: "rgba(24,203,231,0.5)" },
                { offset: 1, color: "rgba(24,203,231,0.2)" },
              ]),
            },
          };
        }
        if (j == 1) {
          area = {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(149,143,69,0.9)" },
                { offset: 0.5, color: "rgba(149,143,69,0.5)" },
                { offset: 1, color: "rgba(24,203,231,0.2)" },
              ]),
            },
          };
          line = "#86fc21";
        }
        if (j == 2) {
          area = {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(87,141,170,0.5)" },
                { offset: 0, color: "rgba(87,141,170,0.4)" },
                { offset: 1, color: "rgba(24,203,231,0.2)" },
              ]),
            },
          };
          line = "#6cce38";
        }
        names.push(data[j].Time);
        var online = [];
        var labels = [];
        for (var i = 0; i < data[j].Data.length; i++) {
          var obj = data[j].Data;
          online.push(obj[i].Count);
          labels.push(obj[i].Time);
        }
        datas.push({
          name: data[j].Time,
          type: "line",
          stack: data[j].Time,
          label: {
            normal: {
              show: false,
              position: "top",
              color: "white",
            },
          },
          smooth: true,
          sampling: "average",
          data: online,
          itemStyle: {
            color: line,
          },
          areaStyle: area,
        });
      }
      var x = {
        type: "category",
        boundaryGap: false,
        data: labels,
      };
      var y = {
        splitLine: {
          lineStyle: {
            color: "#8495c2",
          },
        },
        type: "value",
      };
      var option = this.getOption(x, y, datas);
      (option.title = {
        show: true,
        text: this.$t("横向对比分析"),
        padding: [20, 0, 0, 20],
        textStyle: {
          color: "#ffffff",
        },
      }),
        (option.legend = {
          data: names,
          textStyle: {
            color: "#ffffff",
          },
        });
      this.leftBottom.setOption(option, true);
      window.addEventListener("resize", () => {
        this.leftBottom.resize();
      });
    },
    getOption(xAxis, yAxis, datas) {
      var obj = {
        textStyle: {
          color: "#8495c2",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "25%",
          containLabel: true,
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: datas,
        legend: {
          data: [],
          textStyle: {
            color: "#ffffff",
          },
          orient: "vertical",
          right: 50,
        },
      };
      return obj;
    },
  },
  destroyed: function () {
    window.clearInterval(this.startCoverValueThread);
    window.clearInterval(this.startCoverThread);
  },
};
</script>

