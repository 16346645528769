import { render, staticRenderFns } from "./planTask.vue?vue&type=template&id=6cca2001&scoped=true"
import script from "./planTask.vue?vue&type=script&lang=js"
export * from "./planTask.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cca2001",
  null
  
)

export default component.exports