<template>
  <div v-if="current.Sid" class="ligthview" id="leddetail">
    <div id="todetail">
        <router-link :to="`/admin/stand/commondevice?sid=${$route.query.sid}`">{{$t("详情")}}</router-link>
    </div>
    <div id="leddetail_l" v-if="showStage">
      <boxtitle :data="stageTitle" v-on:titleClick="stageClick">
        <div id="leddetail_l_table" v-loading="loadingStage">
          <el-table stripe=""
            ref="multipleTable"
            :data="devices"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange" :empty-text="$t('暂无数据')"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
            <el-table-column :label="$t('宽度')" prop="Width"></el-table-column>
            <el-table-column :label="$t('高度')" prop="Height"></el-table-column>
            <el-table-column :label="$t('时长')" prop="Duration"></el-table-column>
          </el-table>
        </div>
      </boxtitle>
    </div>
    <div id="leddetail_l" v-if="!showStage">
      <boxtitle :data="stageTitle" v-on:titleClick="taskClick">
        <div id="leddetail_l_table" v-loading="loadingStage">
          <el-table
            :data="tasks"
            tooltip-effect="dark"
            highlight-current-row
            style="width: 100%"
            @current-change="handleSelectionChangeTask" :empty-text="$t('暂无数据')"
          >
            <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
            <el-table-column :label="$t('创建日期')" prop="CreateTime"></el-table-column>
            <el-table-column :label="$t('创建人')" prop="CreateUser"></el-table-column>
          </el-table>
        </div>
      </boxtitle>
    </div>
    <div id="leddetail_r">
      <div>
        <boxtitle :data="picTitle" v-on:titleClick="picClick" class="clearfix">
          <div class="clearfix" style="height:100%;width:100%">
            <div class="leddetail_r_t" v-loading="loadingPic">
              <b-img :src="ledPic" fluid class="ksimg"></b-img>
            </div>
            <div class="ksflexcol leddetail_r_b">
              <div class="row ksrow flex1">
                <div class="col">{{$t("名称")}}</div>
                <div class="col">{{current.DeviceName}}</div>
              </div>
              <div class="row ksrow flex1">
                <div class="col">{{$t("序号")}}</div>
                <div class="col">{{current.Serial}}</div>
              </div>
              <div class="row ksrow flex1">
                <div class="col">{{$t("型号")}}</div>
                <div class="col">{{current.DeviceType.TypeName}}</div>
              </div>
              <div class="row ksrow flex1">
                <div class="col">{{$t("宽度")}}</div>
                <div class="col">{{current.Width}}</div>
              </div>
              <div class="row ksrow flex1">
                <div class="col">{{$t("高度")}}</div>
                <div class="col">{{current.Height}}</div>
              </div>
              <div class="row ksrow flex1">
                <div class="col">{{$t("状态")}}</div>
                <div class="col">{{current.Status==0?$t("离线"):$t("在线")}}</div>
              </div>
            </div>
          </div>
        </boxtitle>
      </div>
      <div>
        <boxtitle :data="setTitle" v-on:titleClick="setClick" class="clearfix">
          <div
            class="clearfix ksflexcol"
            style="height:100%;width:100%;text-align:center"
            v-loading="loadingDevice"
          >
            <div class="row ksrow flex1">
              <div class="col">{{$t("开关屏幕")}}</div>
              <div class="col">
                <el-radio-group
                  v-model="switchValue"
                  size="mini"
                  fill="black"
                  style="margin-right:20px;width:100px;"
                  @change="switchLight"
                >
                  <el-radio-button value="open" label="关"></el-radio-button>
                  <el-radio-button value="close" label="开"></el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <div class="row ksrow flex1">
              <div class="col">{{$t("刷新页面")}}</div>
              <div class="col">
                <el-button type="primary" round size="small" @click="refresh">
                  <i class="fa fa-refresh"></i>
                  {{$t('刷新页面')}}
                </el-button>
              </div>
            </div>
            <div class="row ksrow flex1">
              <div class="col">  {{$t('开启程序')}}</div>
              <div class="col">
                <el-button type="primary" round size="small" @click="startXwalk">
                  <i class="fa fa-hourglass-start"></i>
                  {{$t('开启程序')}}
                </el-button>
              </div>
            </div>
            <div class="row ksrow flex1">
              <div class="col">{{$t('修复视频')}}</div>
              <div class="col">
                <el-button type="primary" round size="small" @click="fixCamra">
                  <i class="fa fa-hourglass-start"></i>
                  {{$t('修复视频')}}
                </el-button>
              </div>
            </div>
            <div class="row ksrow flex1">
              <div class="col">{{$t('重启设备')}}</div>
              <div class="col">
                <el-button type="primary" round size="small" @click="reboot">
                  <i class="fa fa-window-restore"></i>
                  {{$t('重启设备')}}
                </el-button>
              </div>
            </div>
          </div>
        </boxtitle>
      </div>
    </div>
  </div>
</template>
<style>
#leddetail {
  color: white;
}
.leddetail_r_t1 {
  width: 50%;
  height: 100%;
  float: left;
}
.leddetail_r_b1 {
  width: 50%;
  height: 100%;
  float: left;
}
.leddetail_r_t {
  width: 40%;
  height: 100%;
  text-align: center;
  padding: 15px;
  float: left;
}
.leddetail_r_b {
  width: 60%;
  height: 100%;
  float: left;
}
#leddetail #leddetail_l,
#leddetail #leddetail_r {
  height: 100%;
  float: left;
  width: 50%;
}
#leddetail #leddetail_l {
  padding: 5px;
  overflow: auto;
}
#leddetail #leddetail_r > div {
  padding: 5px;
  height: 50%;
}
@media (max-width: 1200px) {
  #leddetail #leddetail_l {
    height: 100%;
    float: left;
    width: 100%;
  }
  #leddetail #leddetail_r {
    height: 200%;
    float: left;
    width: 100%;
  }
  .leddetail_r_t {
    width: 100%;
    height: 100%;
  }
  .leddetail_r_b {
    width: 100%;
    height: 100%;
  }
  .leddetail_r_t1 {
    width: 100%;
    height: 100%;
  }
  .leddetail_r_b1 {
    width: 100%;
    height: 100%;
  }
}
</style>

<script>
import box from "@/components/box.vue";
import boxtitle from "@/components/boxtitle.vue";
import { BaseUrl } from '../../../assets/utils/config';
export default {
  components: { box, boxtitle },
  data: function() {
    return {
      tasks:[],
      currentTask:null,
      loadingDevice: false,
      loadingPic: false,
      switchValue: "",
      briModel: 0,
      briOpt: [
        { value: 0, label: "黑屏" },
        { value: 20, label: "暗" },
        { value: 40, label: "普通" },
        { value: 64, label: "明亮" }
      ],
      volModel: 0,
      volOpt: [
        { value: 0, label: "静音" },
        { value: 4, label: "安静" },
        { value: 8, label: "普通" },
        { value: 12, label: "大声" },
        { value: 15, label: "超大声" }
      ],
      stageTitle: {
        style: "fa fa-save",
        title: this.$t("发布节目"),
        action: this.$t("发布")
      },
      picTitle: {
        style: "fa fa-refresh",
        title: this.$t("实时画面"),
        action: this.$t("刷新")
      },
      setTitle: {
        style: "fa fa-save",
        title: this.$t("设备操作")
      },
      devices: [],
      deviceSlected: [],
      deviceSlected1: [],
      loadingStage: true,
      current: {},
      loading: {},

      ledPic: `${BaseUrl}/img/map/led.png`,
      showStage:true
    };
  },
  mounted: function() {
    if(this.$route.query.xx){
      this.showStage=false;
    }
    this.select();
  },
  methods: {
    briChange(val) {
      this.callLed(2, val);
    },
    valChange(val) {
      this.callLed(4, val);
    },
    switchLight(val) {
      this.callLed(0, val == "开" ? true : false);
    },
    select(init) {
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/device/GetLedDetail", this.$route.query.sid, true)
        .then(success => {
          this.loading.close();
          this.current = success;
          this.ledPic = this.current.DeviceType.Pic;
          if(this.showStage){
            this.selectStage();
          }else{
            this.selectTask();
          }
          if (!init) {
            this.picClick();
          }
          this.volModel = this.current.Volume;
          this.briModel = this.current.Brightness;
          this.switchValue = this.current.Switch == 1 ? "开" : "关";
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    taskClick(){
      if(!this.currentTask){
        throw "请选择节目";
      }
      if(!this.$route.query.sid){
        throw "请主入设备";
      }
      var data = {
        TaskSid: this.currentTask.Sid,
        Devices: [this.$route.query.sid]
      };
      this.loadingStage = true;
        this.$ksajax
        .ksPostPromise("/api/xixun/PublicPlay", data, true)
        .then(() => {
          this.$router.push({
          path: "/admin/XiXun/progLog"
        });
          this.loadingStage=false;
        })
        .catch(err => {
          this.loadingStage=false;
          this.$throw(err);
        });
    },
    stageClick() {
      this.loadingStage = true;
      var data = {
        Sid: this.current.Sid,
        Stages: []
      };
      if (this.deviceSlected && this.deviceSlected.length > 0) {
        this.deviceSlected.forEach(x => {
          data.Stages.push(x.Sid);
        });
      }
      this.$ksajax
        .ksPostPromise("/api/led/PublishLedByDev", data, true)
        .then(() => {
          this.loadingStage = false;
          this.select(true);
        })
        .catch(err => {
          this.$throw(err);
          this.loadingStage = false;
        });
    },
    picClick() {
      this.loadingPic = true;
      this.$ksajax
        .ksPostPromise("/api/led/GetScreen", this.current.Sid, true)
        .then(success => {
          this.ledPic = `data:image/gif;base64,${success.result}`;
          this.loadingPic = false;
        })
        .catch(err => {
          this.$throw(err);
          this.loadingPic = false;
        });
    },
    setClick() {},
    handleSelectionChange(val) {
      this.deviceSlected = val;
    },
    handleSelectionChangeTask(val){
      this.currentTask=val;
    },
    publishShown() {
      if (this.deviceSlected1 && this.deviceSlected1.length != 0) {
        this.deviceSlected1.forEach(x => {
          this.$refs.multipleTable.toggleRowSelection(x);
        });
      }
    },
    selectTask(){
      this.loadingStage = true;
      this.tasks = [];
       this.$ksajax
          .ksPostPromise("/api/xixun/GetProgTask", "", true)
          .then(data => {
            this.tasks = data;
          this.loadingStage = false;
          })
          .catch(err => {
          this.$throw(err);
          this.loadingStage = false;
          });
    },
    selectStage() {
      this.loadingStage = true;
      this.deviceSlected = [];
      new Promise((res, rej) => {
        this.$ksajax
          .ksPostPromise("/api/led/getledstage", "", true)
          .then(data => {
            this.devices = data;
            res();
          })
          .catch(err => {
            rej(err);
          });
      })
        .then(() => {
          this.deviceSlected1 = this.devices.filter(
            x => this.current.Stages.indexOf(x.Sid) != -1
          );
          this.publishShown();
          this.loadingStage = false;
        })
        .catch(err => {
          this.$throw(err);
          this.loadingStage = false;
        });
    },
    refresh() {
      this.$confirm(this.$t("刷新确认"), this.$t("刷新"), {
        confirmButtonText: this.$t("确定"),
        cancelButtonText: this.$t("取消"),
        type: "warning"
      })
        .then(() => {
          this.loadingDevice = true;
          this.$ksajax
            .ksPostPromise("/api/led/Fresh", this.current.Sid, true)
            .then(() => {
              this.$throw("刷新成功");
              this.loadingDevice = false;
            })
            .catch(err => {
              this.$throw(err);
              this.loadingDevice = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("已取消操作")
          });
        });
    },
    fixCamra() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }

          this.loadingDevice = true;
      this.$ksajax
        .ksPostPromise("/api/led/FixCamra", this.current.Sid, true)
        .then(() => {
          this.$throw("开启成功");
              this.loadingDevice = false;
        })
        .catch(err => {
          this.$throw(err);
              this.loadingDevice = false;
        });
    },
    startXwalk() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
          this.loadingDevice = true;
      this.$ksajax
        .ksPostPromise("/api/led/StartXwalk", this.current.Sid, true)
        .then(() => {
          this.$throw("开启成功");
              this.loadingDevice = false;
        })
        .catch(err => {
          this.$throw(err);
              this.loadingDevice = false;
        });
    },
    reboot() {
      this.$confirm(this.$t("重启确认"), this.$t("重启"), {
        confirmButtonText: this.$t("确定"),
        cancelButtonText: this.$t("取消"),
        type: "warning"
      })
        .then(() => {
          this.callLed(8, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("已取消操作")
          });
        });
    },
    callLed(fun, value) {
      var obj = {
        Sid: this.current.Sid,
        Fun: fun,
        Value: value
      };
      this.loadingDevice = true;
      this.$ksajax
        .ksPostPromise("/api/led/CallLed", obj, true)
        .then(data => {
          this.loadingDevice = false;
          switch (fun) {
            case 0:
              //开启屏幕
              break;
            case 1:
              //是否已经开启屏幕
              this.current.Switch = data.result ? 1 : 0;
              break;
            case 2:
              break;
            case 3:
              this.current.Brightness = data.result;
              break;
            case 4:
              break;
            case 5:
              this.current.Volume = data.result;
              break;
            case 6:
              this.current.Width = data.result;
              break;
            case 7:
              this.current.Height = data.result;
              break;
            case 8:
              break;
          }
          this.loading.close();
        })
        .catch(err => {
          this.loadingDevice = false;
          this.$throw(err);
          this.loading.close();
        });
    }
  }
};
</script>
