<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      :view="true"
      :reboot="true"
      :refresh="true"
      :xwalk="true"
      :publish="true"
      :fixc="true"
      :init="true"
      v-on:publish="publish"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
      v-on:view="view"
      v-on:reboot="reboot"
      v-on:refresh="refresh"
      v-on:xwalk="startXwalk"
      v-on:fixc="fixCamra"
      v-on:init="init"
    ></actionbar>

    <!-- <tablepage ref="tableref" id="tableref"
         v-on:created='tableCreated'
         v-on:currentPage='currentPage'
         v-on:currentRow='currentRow'
         :cols='cols'
         :items='items'
         :total='total'
         :perpage='count'
    ></tablepage>-->
    <el-table :data="items" style="width: 100%" highlight-current-row @current-change="currentRow" :empty-text="$t('暂无数据')" stripe="">
      <el-table-column :label="$t('序号')" prop="Serial"></el-table-column>
      <el-table-column :label="$t('设备名')" prop="DeviceName"></el-table-column>
      <el-table-column :label="$t('设备类型')">
        <template slot-scope="scope">
          <span>{{scope.row.DeviceType.TypeName}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('设备型号')">
        <template slot-scope="scope">
          <span>{{scope.row.DeviceType.ModelNumber}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('宽度')">
        <template slot-scope="scope">
          <span>{{scope.row.Width}}</span>
          <i
            class="fa fa-refresh"
            style="margin-left:5px;cursor:pointer"
            @click="callLed(scope.row.Sid,6)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('高度')" prop="Height">
        <template slot-scope="scope">
          <span>{{scope.row.Height}}</span>
          <i
            class="fa fa-refresh"
            style="margin-left:5px;cursor:pointer"
            @click="callLed(scope.row.Sid,7)"
          ></i>
        </template>
      </el-table-column>
      <!-- <el-table-column :label="$t('亮度')" prop="Brightness">
        <template slot-scope="scope">
          <b-form-select
            v-model="scope.row.Brightness"
            style="width:60px"
            @input="callLed(scope.row.Sid,2,scope.row.Brightness*1)"
          >
            <option value="0">0</option>
            <option value="16">16</option>
            <option value="32">32</option>
            <option value="64">64</option>
          </b-form-select>
          <i
            class="fa fa-refresh"
            style="margin-left:5px;cursor:pointer"
            @click="callLed(scope.row.Sid,3)"
          ></i>
        </template>
      </el-table-column> -->
      <!-- <el-table-column :label="$t('音量')">
        <template slot-scope="scope">
          <b-form-select
            v-model="scope.row.Volume"
            style="width:60px"
            @input="callLed(scope.row.Sid,4,scope.row.Volume*1)"
          >
            <option value="0">0</option>
            <option value="4">4</option>
            <option value="8">8</option>
            <option value="12">12</option>
            <option value="15">15</option>
          </b-form-select>
          <i
            class="fa fa-refresh"
            style="margin-left:5px;cursor:pointer"
            @click="callLed(scope.row.Sid,5)"
          ></i>
        </template>
      </el-table-column> -->
      <el-table-column :label="$t('位置')" prop="Address"></el-table-column>
      <el-table-column :label="$t('在线状态')">
        <template slot-scope="scope">
          <i :class="['fa',scope.row.Status==1?'stateOn':'stateOff','fa-2x','fa-signal']"></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('屏幕状态')">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.Switch"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#999"
            @change="callLed(scope.row.Sid,0,scope.row.Switch!=0?true:false)"
          ></el-switch>
          <i
            class="fa fa-refresh"
            style="margin-left:5px;cursor:pointer"
            @click="callLed(scope.row.Sid,1)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('详情')">
        <template slot-scope="scope">
          <router-link :to="`/admin/stand/leddetail?sid=${scope.row.Sid}`">{{$t('详情')}}</router-link>
        </template>
      </el-table-column>
    </el-table>
    <modify
      ref="modify"
      :mapitem="mapitem"
      :modaldata="modalData"
      :title="title"
      :labels="labels"
      v-on:submited="submited"
      url="/api/device/AddLedAd"
    ></modify>
    <b-modal
      id="viewModal"
      class="ksmodal"
      ref="viewModal"
      size="lg"
      title="预览"
      body-text-variant="dark"
    >
      <div :style="viewStyle">
        <viewled :sid="viewSid" :width="viewStyle.width" ref="ledtemp"></viewled>
      </div>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/device/DelLedAd"
      :title="$t('删除')"
    ></deletedata>

    <b-modal
      id="publishmodal"
      class="ksmodal"
      ref="publishmodal"
      size="lg"
      :title="$t('发布')"
      body-text-variant="dark"
      @ok="publishStage"
      @shown="publishShown"
    >
      <span class="kstitle">{{$t("LED发布大小")}}</span>
      <hr>
      <el-table
        ref="multipleTable"
        :data="devices"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange" :empty-text="$t('暂无数据')"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
        <el-table-column :label="$t('宽度')" prop="Width"></el-table-column>
        <el-table-column :label="$t('高度')" prop="Height"></el-table-column>
        <el-table-column :label="$t('时长')" prop="Duration"></el-table-column>
      </el-table>
    </b-modal>
       <b-modal
      id="initModel"
      ref="initModel"
      size="lg"
      :title="$t('初始化')"
      body-text-variant="dark"
      @ok="initView"
    >
    <div>{{$t("选择资源")}}</div>
       <b-form-select v-model="picModel" :options="picRes" text-field="Name" value-field="Content"></b-form-select>
       <b-img fluid="" :src="picModel"></b-img>
       </b-modal>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
import viewled from "@/components/viewled";
export default {
  components: { actionbar, tablepage, modify, deletedata, viewled },
  data: function() {
    return {
      picRes:[],
      picModel:"",
      viewWidth: 0,
      viewHeight: 0,
      viewStyle: {
        width: "100%",
        height: "100%",
        border: "1px solid"
      },
      viewSid: "",
      mapitem: {
        value: "Address",
        require: true,
        subvalue: false,
        Position: {
          Lat: "Lat",
          Lng: "Lng"
        }
      },
      modalData: this.resetData(),
      loading: {},
      items: [],
      total: 0,
      count: 20,
      index: 0,
      sid: "",
      labels: this.getResetLable(),
      title: "",
      current: null,
      devices: [],
      deviceSlected: [],
      deviceSlected1: []
    };
  },
  methods: {
    callLed(sid, fun, value) {
      var obj = {
        Sid: sid,
        Fun: fun,
        Value: value
      };
      this.loading = this.$loading({ fullScreen: true });
      this.$ksajax
        .ksPostPromise("/api/led/CallLed", obj, true)
        .then(data => {
          var index = -1;
          for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].Sid == sid) {
              index = i;
            }
          }
          if (index != -1) {
            switch (fun) {
              case 0:
                //开启屏幕
                break;
              case 1:
                //是否已经开启屏幕
                this.items[index].Switch = data.result ? 1 : 0;
                break;
              case 2:
                break;
              case 3:
                this.items[index].Brightness = data.result;
                break;
              case 4:
                break;
              case 5:
                this.items[index].Volume = data.result;
                break;
              case 6:
                this.items[index].Width = data.result;
                break;
              case 7:
                this.items[index].Height = data.result;
                break;
              case 8:
                break;
            }
          }
          this.loading.close();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    select(noloading, index) {
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      let obj = {
        Index: this.index,
        Count: this.count
      };
      this.$ksajax
        .ksPostPromise("/api/device/GetDeviceLedAds", obj, true)
        .then(data => {
          data.Items.forEach(element => {
            var sid = element.Sid;
            element.View = "/LedView/?Sid=" + sid;
          });
          this.items = data.Items;
          this.total = data.Items.length;
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch(err => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.modalData = {
        Sid: this.current.Sid,
        DeviceName: this.current.DeviceName,
        Lat: this.current.Lat,
        Lng: this.current.Lng,
        Address: this.current.Address,
        Serial: this.current.Serial,
        Width: this.current.Width,
        Height: this.current.Height,
        SensorAddress: "01",
        SN: this.current.SN,
        BaudRate: 9600,
        DTypeSid: this.current.DeviceType.Sid,
        LStandSid: this.current.LampStand?this.current.LampStand.Sid:""
      };
      this.$refs.modify.show();
    },
    insert() {
      this.modalData = this.resetData();
      this.$refs.modify.show();
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      return {
        Sid: "",
        DeviceName: "",
        Lat: 0,
        Lng: 0,
        Address: "",
        Serial: "",
        Width: 0,
        Height: 0,
        Switch: 0,
        Brightness: 0,
        Volume: 0,
        Screenshot: "",
        DTypeSid: "",
        LStandSid: ""
      };
    },
    getResetLable() {
      return [
        {
          label: this.$t("设备名"),
          type: "text",
          value: "DeviceName",
          require: true
        },
        {
          label: this.$t("高度"),
          type: "number",
          value: "Height",
          require: true
        },
        {
          label: this.$t("宽度"),
          type: "number",
          value: "Width",
          require: true
        },
        {
          label: this.$t("序号"),
          type: "text",
          value: "Serial",
          require: true
        },
        {
          label: this.$t("类型"),
          type: "select",
          value: "DTypeSid",
          require: true,
          bind: this.$store.getters.getDeviceType("006")
        },
        {
          label: this.$t("灯杆"),
          type: "select",
          value: "LStandSid",
          require: false,
          bind: this.$store.state.lStands
        },
        {
          label: this.$t("位置"),
          type: "map",
          value: "Address",
          require: true
        }
      ];
    },
    submited() {
      this.select();
    },
    deleted() {
      this.select();
    },
    view() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.viewSid = this.current.Sid;
      this.viewStyle.width = this.current.Width + "px";
      this.viewStyle.height = this.current.Height + "px";
      this.viewWidth = this.current.Width;
      this.viewHeight = this.current.Height;
      this.$refs.viewModal.show();
    },
    reboot() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.$confirm(this.$t("重启确认"), this.$t("重启"), {
        confirmButtonText: this.$t("确定"),
        cancelButtonText: this.$t("取消"),
        type: "warning"
      })
        .then(() => {
          this.callLed(this.current.Sid, 8, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("已取消操作")
          });
        });
    },
    refresh() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.$confirm(this.$t("刷新确认"), this.$t("刷新"), {
        confirmButtonText: this.$t("确定"),
        cancelButtonText: this.$t("取消"),
        type: "warning"
      })
        .then(() => {
          this.loading = this.$loading({ fullScreen: true });
          this.$ksajax
            .ksPostPromise("/api/led/Fresh", this.current.Sid, true)
            .then(() => {
              this.$throw("刷新成功");
              this.loading.close();
            })
            .catch(err => {
              this.$throw(err);
              this.loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("已取消操作")
          });
        });
    },
    fixCamra() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }

      this.loading = this.$loading({ fullScreen: true });
      this.$ksajax
        .ksPostPromise("/api/led/FixCamra", this.current.Sid, true)
        .then(() => {
          this.$throw("开启成功");
          this.loading.close();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    startXwalk() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.loading = this.$loading({ fullScreen: true });
      this.$ksajax
        .ksPostPromise("/api/led/StartXwalk", this.current.Sid, true)
        .then(() => {
          this.$throw("开启成功");
          this.loading.close();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    publish() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.loading = this.$loading();
      new Promise((res, rej) => {
        if (this.devices.length != 0) {
          res();
        } else {
          this.$ksajax
            .ksPostPromise("/api/led/getledstage", "", true)
            .then(data => {
              this.devices = data;
              res();
            })
            .catch(err => {
              rej(err);
            });
        }
      })
        .then(() => {
          this.deviceSlected1 = this.devices.filter(
            x => this.current.Stages.indexOf(x.Sid) != -1
          );
          this.loading.close();
          this.$refs.publishmodal.show();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    publishStage() {
      this.loading = this.$loading({ fullScreen: true });
      var data = {
        Sid: this.current.Sid,
        Stages: []
      };
      if (this.deviceSlected && this.deviceSlected.length > 0) {
        this.deviceSlected.forEach(x => {
          data.Stages.push(x.Sid);
        });
      }
      this.$ksajax
        .ksPostPromise("/api/led/PublishLedByDev", data, true)
        .then(() => {
          this.loading.close();
          this.$refs.publishmodal.hide();
          this.select();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    publishShown() {
      this.$refs.multipleTable.clearSelection();
      if (this.deviceSlected1 && this.deviceSlected1.length != 0) {
        this.deviceSlected1.forEach(x => {
          this.$refs.multipleTable.toggleRowSelection(x);
        });
      }
    },
    handleSelectionChange(val) {
      this.deviceSlected = val;
    },
    init(){
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      new Promise((res,rej)=>{
        if(this.picRes.length!=0){
          res();
        }else{
          this.loading = this.$loading({ fullScreen: true });
           this.$ksajax.ksPostPromise("/api/led/GetPicRes", "", true).then((r)=>{
             this.picRes=r;
             res();
           }).catch((err)=>{
             rej(err);
           })
        }
      }).then(()=>{
        this.$refs.initModel.show();
        this.loading.close();
      }).catch((err)=>{
          this.$throw(err);
          this.loading.close();
      })
    },
    initView(){
      var obj={
        Sid:this.current.Sid,
        PicPath:this.picModel
      }
      this.loading = this.$loading({ fullScreen: true });
      this.$ksajax.ksPostPromise("/api/led/InitView", obj, true).then(()=>{
        this.$refs.initModel.hide();
          this.loading.close();
      }).catch((err)=>{
          this.$throw(err);
          this.loading.close();
      })
    }
  },
  mounted: function() {
    this.select();
  }
};
</script>
