<template>
    <div class="delete">
         <b-modal id="modallg" ref="modallg" size="lg" v-bind:title="title"
         header-bg-variant="success"
         body-text-variant="dark"
         @ok='deletedata'
         >
         {{$t("删除确认")}}
        </b-modal>
    </div>
</template>
<style>

</style>
<script>
export default {
    props:['title','sid','url'],
    methods:{
        deletedata(){
            this.$ksajax.ksPostData(this.url,this.sid,true,
            (success)=>{
                this.$emit("deleted",success);
            });
        },
         show(){
            this.$refs.modallg.show();
        },
        hidden(){
             this.$refs.modallg.hide();
        },
    }
}
</script>
