<template>
  <div style="color:white;height:100%;" v-loading="loading">
    <div id="todetail">
      <!-- <el-buttom @click="alarmAction">测试</el-buttom> -->
      <router-link :to="`/admin/stand/commondevice?sid=${$route.query.sid}`">{{this.$t("详情")}}</router-link>
    </div>
    <div class="detailview">
      <div class="detailview_1">
        <div>
          <box style="position:relative">
            <div style="width:100%;height:100%">
              <div style="height:33.33%">
                <valueDetail :value="viewDetail.Voltage"></valueDetail>
                <valueDetail :value="viewDetail.ElectricCurrent"></valueDetail>
                <valueDetail :value="viewDetail.Power"></valueDetail>
                <valueDetail :value="viewDetail.Leakage"></valueDetail>
              </div>
              <div style="height:33.33%">
                <valueDetail :value="viewDetail.Energy"></valueDetail>
                <valueDetail :value="viewDetail.PowerGrid"></valueDetail>
                <valueDetail :value="viewDetail.Temperature"></valueDetail>
                <valueDetail :value="viewDetail.Humidity"></valueDetail>
              </div>
              <div style="height:33.33%">
                <valueDetail :value="viewDetail.Smoke"></valueDetail>
                <valueDetail :value="viewDetail.Water"></valueDetail>
                <valueDetail :value="viewDetail.UPS"></valueDetail>
                <valueDetail :value="viewDetail.AirConditioner"></valueDetail>
              </div>
            </div>
          </box>
        </div>
      </div>
      <div class="detailview_1">
        <div>
          <box id="canvas_box" style="position:relative">
            <div style="height:100%" ref="door"></div>
          </box>
        </div>
      </div>
      <div class="detailview_1">
        <div>
          <box>
            <div class="ksflexcol" style="height:100%">
              <div style="width:100%" class="ksflex">
                <div style="flex:1;text-align:center">{{currentCam.DeviceName}}</div>
                <div style="width:120px">
                  <el-select v-model="selectCam" placeholder="请选择摄像头" @change="change">
                    <el-option
                      v-for="item in Cams"
                      :key="item.Sid"
                      :label="item.DeviceName"
                      :value="item.Sid"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div style="flex:1;width:100%">
                <netvideo ref="netplay" :showRight="false" :playdata="playdata"></netvideo>
              </div>
            </div>
          </box>
        </div>
      </div>
      <div class="detailview_2">
        <div>
          <box>
            <linebar ref="line" :smooth="true"></linebar>
          </box>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.animateIn {
  animation: carin 3s;
  opacity: 1;
}
.animateOut {
  animation: carout 3s;
  opacity: 0;
}
@keyframes carin {
  from {
    top: 40%;
    opacity: 0;
  }
  to {
    top: 10%;
    opacity: 1;
  }
}
@keyframes carout {
  from {
    top: 10%;
    opacity: 1;
  }
  to {
    top: 40%;
    opacity: 0;
  }
}
</style>
<script>
import 'whatwg-fetch'
import box from "@/components/box1.vue";
import alarm from "@/components/alarm";
import netvideo from "@/components/netvideo";
import { AllInBoxScene } from "@/assets/utils/door.js";
import valueDetail from "./components/valueDetail";
import linebar from "@/components/chart/line.vue";
export default {
  data() {
    return {
      loading: false,
      door:null,
      sid: "",
      selectCam: "",
      viewDetail: this.defaultView(),
      currentCam: {
        DeviceName: "暂无摄像头",
        NVRChanel: -1
      },
      Cams: [
        {
          DeviceName: "暂无摄像头",
          NVRChanel: -1,
          Sid: "1"
        }
      ],
      playdata: {},
      lineColor1: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#FFB7440f"
          },
          {
            offset: 1,
            color: "#FFB744ff"
          }
        ]
      },

      color1: "#FFB744",
      color2: "#3197FA",
      color3: "#FF4848"
    };
  },
  components: { box, alarm, valueDetail, netvideo, linebar },
  mounted() {
    this.sid = this.$route.query.sid;
    this.init3D();
    this.select();
  },
  watch: {
    "$store.state.dataHubChange": function() {
      var data = this.$store.state.dataHub;
      try {
        var vdata = data.Data;
        if (data.DeviceSid == this.sid) {
          for (var item in this.viewDetail) {
            var obj = this.viewDetail[item];
            if (obj.Sid && obj.Sid == vdata.DeviceSid) {
              if (item == "Smoke" || item == "Water") {
                  obj.Value==vdata.Value==1?'正常':'报警';
              }else{
              obj.Value = vdata.Value;}
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    "$store.state.actionHubChange":function(){
        var data = this.$store.state.actionHub;
         if (data.DeviceSid == this.sid) {
             this.actionDoor(data.ActionType,data.Status==1);
         }
    },
    "$store.state.alarmHubChange":function(){ 
      var data = this.$store.state.alarmHub;
         if (data.DeviceSid == this.sid) {
           var alarm=data.Data.Value==1;
           switch(data.Type){
             case 55:
               this.viewDetail.Temperature.Alarm=alarm;
               break;
             case 58:
               this.viewDetail.Humidity.Alarm=alarm;
               break;
             case 85:
               this.viewDetail.Water.Alarm=alarm;
               break;
             case 135:
               this.viewDetail.Smoke.Alarm=alarm;
               break;
             case 2:
               this.viewDetail.PowerGrid.Alarm=alarm;
               break;
           }
           this.alarmAction(alarm);
         }
    }
  },
  methods: {
    alarmAction(alarm){
      if(alarm){
        this.door.changeMainBoxAlarm();
      }else{
        this.door.resetMainBoxAlarmState();
      }
    },
    initChart(json) {
      var obj = JSON.parse(json);
      var chart1 = {
        title: {
          show: true,
          text: "智慧综合箱耗能趋势分析",
          left: 10
        },
        data: [],
        color: [this.color1, this.color4, this.color2, this.color3],
        xdata: []
      };
      var ddata = [];
      obj.forEach(element => {
        ddata.push((element.Value * 1).toFixed(2));
        chart1.xdata.push(element.Name);
      });
      chart1.data = [
        {
          name: this.$t("耗能（kw/h）"),
          data: ddata,
          color: this.lineColor1
        }
      ];
      this.$refs.line.setAndInit(
        chart1.data,
        null,
        chart1.color,
        null,
        chart1.xdata,
        chart1.title
      );
    },
    change(val) {
      this.currentCam = this.Cams.find(x => x.Sid == val);
      this.loading = true;
      var obj = {
        Sid: this.currentCam.Sid,
        EzopenType: 0,
        Channel: this.currentCam.NVRChanel,
        Definition: 0,
        PlayParameter: {
          IsMute: true
        }
      };
      this.$ksajax
        .ksPostPromise("/api/box/GetBoxVideoPlayNet", obj, true)
        .then(data => {
          this.loading = false;
          this.playdata.data = data;
          this.playdata.isPlay = true;
          this.playdata.device = {
            DeviceName: this.currentCam.DeviceName,
            Sid: this.currentCam.Sid
          };
          this.$refs.netplay.start();
        })
        .catch(error => {
          this.$throw(error);
          this.loading = false;
        });
    },
    defaultView() {
      return {
        AirConditioner: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        },
        ElectricCurrent: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        },
        Energy: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        },
        Humidity: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        },
        Power: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        },
        PowerFactor: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        },
        PowerGrid: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        },
        Smoke: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        },
        Temperature: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        },
        UPS: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        },
        Voltage: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        },
        Water: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        },
        Leakage: {
          Alarm: false,
          AlarmPic: "",
          Pic: "",
          Value: "",
          Name: "",
          Fix: ""
        }
      };
    },
    init3D() {
      var scene = new AllInBoxScene(this.$refs.door, {});
      scene.init();
    //   scene.setAspect();
    //   //  scene.addAxisHelper();
    //   scene.addCameraControl();
    //   scene.addPointLight();
    //   scene.addEnvironmentLight();
    //   scene.loadMainBox();
    //   scene.loadDoor();
    //   scene.renderScene();
      this.door=scene;
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/box/getdevicedetail", this.sid, true)
        .then(data => {
          this.loading = false;
          this.viewDetail = data.ValueDetail;
          this.Cams = data.CamDetail.Cameras;
          this.initChart(data.ChartData);
          data.Accessers.forEach(x=>{
              this.actionDoor(x.AccesserType,x.Value==1);
          })
          // this.viewDetail.Water.Alarm=alarm;
          // this.actionDoor(2,true);
          console.log(data);
        })
        .catch(err => {
          this.$throw(err);
          this.loading = false;
        });
    },
    actionDoor(type,open){
        var door=undefined;
        switch(type){
            case 0:
                door="ldoor"
                break;
            case 1:
                door="rdoor"
                break;
            case 2:
                door="fldoor"
                break;
            case 3:
                door="frdoor"
                break;
            case 4:
                door="bldoor"
                break;
            case 5:
                door="brdoor"
                break;
        }
        console.log(door);
        if(door){
            if(open){
                this.door.openDoor(door);
            }else{
                this.door.closeDoor(door);
            }
        }
    }
  }
};
</script>