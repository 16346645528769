<template>
    <div :style="style" class="ksflexcol imgs_box">
        <div id="imgs_sc">
            <el-carousel style="height:100%;width:100%" ref="carousel" v-on:change="changeFun">
                <el-carousel-item v-for="item in data.Pics" :key="item" :name="item">
                    <div style="text-align:center;height:100%">
                        <b-img fluid class="ksimg" :src="item"></b-img>
                        <b-img fluid class="ksimg" v-lazy="item"></b-img>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div id="imgs_img" class="ksflex">
            <div>
                <i class="fa fa-chevron-left fa-3x" style="cursor: pointer;" @click="setScroll(true)" @mouseover="autoScroll(true)"></i>
            </div>
            <div class="imgs_img_img" ref="imgcontent">
                <b-img v-for="item in data.Pics" :key="'img'+item" v-lazy="item" fluid class="ksimg"
                       style="padding-right:40px;cursor: pointer;" @click="setActive(item)"></b-img>
            </div>
            <div>
                <i class="fa fa-chevron-right fa-3x" style="cursor: pointer;" @click="setScroll(false)" @mouseover="autoScroll(false)"></i>
            </div>
        </div>
        <div v-if="data&&data.Desc" id="imgs_text">
            {{data.Desc}}
        </div>
    </div>
</template>

<style>
    .imgs_box {
        width: 100%;
        height: 100%;
        padding: 0 10% 0 10%
    }

    .imgs_img_cotnent {
        height: 100%;
        width: 200px;
    }

    .imgs_img_img {
        flex: 1;
        overflow: hidden;
        height: 100%;
        text-align: center;
        margin-top: 20px;
        white-space: nowrap;
    }

    .ksimgs > div {
        width: 100%;
    }

    #imgs_sc {
        padding-top:30px;
        flex: 1;
        width: 100%;
        background-color: #fff;
    }

    #imgs_img {
        height: 150px;
        width: 100%
    }

    #imgs_text {
        height: 100px;
        width: 100%;
        margin-top: 20px;
    }
</style>

<script>
    export default {
        props: ["data", "height"],
        data: function () {
            return {
                style: {
                    height: this.height
                },
                distance:0,
                curIndex:1
            }
        },
        methods: {
            changeFun(val, oldVal) {
                this.oldIndex = oldVal;
                this.curIndex = val;
            },
            setActive(item) {
                this.$refs.carousel.setActiveItem(item);
            },
            setScroll(left) {
                if (left) {
                    this.distance += 50;
                } else {
                    this.distance += 50;
                }
            },
            autoScroll(left) {
                var value = this.$refs.imgcontent.scrollLeft;
                if (left) {
                    this.animate(value - 300, -1, 3);
                } else {
                    this.animate(value + 300, 1, 3);
                }
            },
            animate(des, direc, speed) {
                if (this.temp) {
                    window.clearInterval(this.temp);
                    this.temp = null;
                }
                this.distance = this.$refs.imgcontent.scrollLeft;
                this.temp = window.setInterval(() => {
                    if ((direc === -1 && des < this.distance) || (direc === 1 && des > this.distance)) {
                        this.distance += speed * direc;
                    } else {
                        this.transitionEnd = true;
                        window.clearInterval(this.temp);
                    }
                    this.$refs.imgcontent.scroll(this.distance, 0);
                }, 20);
            },
           
        }
    }
</script>