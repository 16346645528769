<template>
  <div class="ligthview" v-loading="loading">
    <div class="ligthview_l">
      <div>
        <box>
          <div class="ksflex" style="height: 100%; padding: 10px">
            <div style="flex: 1">
              <div class="maxnumber">
                {{ current.DeviceCount }}
              </div>
              <div class="content_text">{{ $t("摄像头数量") }}</div>
            </div>
            <div style="flex: 1; text-align: right">
              <img src="../../../../public/img/saveicon.png" />
            </div>
          </div>
        </box>
      </div>
    </div>
    <div class="ligthview_l">
      <div>
        <box>
          <div class="ksflex" style="height: 100%; padding: 10px">
            <div style="flex: 1">
              <div class="maxnumber">
                {{ current.FaceSetCount }}
              </div>
              <div class="content_text">{{ $t("人脸采集数量") }}</div>
            </div>
            <div style="flex: 1; text-align: right">
              <img src="../../../../public/img/onlineicon.png" />
            </div>
          </div>
        </box>
      </div>
    </div>
    <div class="ligthview_l">
      <div>
        <box>
          <div class="ksflex" style="height: 100%; padding: 10px">
            <div style="flex: 1">
              <div class="maxnumber">
                {{ current.MessageCount }}
              </div>
              <div class="content_text">{{ $t("消息数量") }}</div>
            </div>
            <div style="flex: 1; text-align: right">
              <img src="../../../../public/img/lighticon.png" />
            </div>
          </div>
        </box>
      </div>
    </div>
    <div class="ligthview_2">
      <div>
        <box>
          <div style="height: 100%; width: 100%" id="chatLeft"></div>
          <div class="container">
            <div class="container_item">
              {{ $t("普通客人") }}
              <span class="num_b">{{ mydataChat1.num1 }}</span
              >{{ $t("位") }}
            </div>
            <div class="container_item">
              {{ $t("重要客人")
              }}<span class="num_b">{{ mydataChat1.num2 }}</span
              >{{ $t("位") }}
            </div>
            <div class="container_item">
              {{ $t("陌生人") }}
              <span class="num_b">{{ mydataChat1.num3 }}</span
              >{{ $t("位") }}
            </div>
            <div class="container_item">
              {{ $t("嫌疑人") }}<span class="num_b">{{ mydataChat1.num4 }}</span
              >{{ $t("位") }}
            </div>
          </div>
        </box>
      </div>
    </div>
    <div class="ligthview_2">
      <div>
        <box>
          <div style="height: 100%; width: 100%" id="chatRight"></div>
          <div class="container">
            <div class="container_item">
              {{ $t("男性") }}<span class="num_b">{{ mydataChat2.num1 }}</span
              >{{ $t("位") }}
            </div>
            <div class="container_item">
              {{ $t("女性") }}<span class="num_b">{{ mydataChat2.num2 }}</span
              >{{ $t("位") }}
            </div>
            <div class="container_item">
              {{ $t("未知") }}<span class="num_b">{{ mydataChat2.num3 }}</span
              >{{ $t("位") }}
            </div>
          </div>
        </box>
      </div>
    </div>
    <div class="ligthview_3" style="height: 46%">
      <div>
        <box>
          <div
            style="height: 100%; width: 100%; padding-top: 20px"
            id="chatBottom"
          ></div>
        </box>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<style>
/* 人脸识别文字 */
.ligthview .ligthview_2 .container {
  position: absolute;
  top: 0;
  right: 0;
  text-align: left;
  width: 30%;
  z-index: 1;
  padding: 30px 20px;
}

.ligthview .ligthview_2 .container .container_item {
  color: #eee;
  min-width: 173px;
}

.ligthview .ligthview_2 .container .container_item .num_b {
  color: #fff;
  font-size: 36px;
  padding: 0 5px;
}

.ligthview .ligthview_3 > div {
  padding: 5px;
  width: 100%;
  height: 100%;
}

.ligthview_3 {
  width: 100%;
  height: 42%;
}
</style>

<script>
import box from "@/components/box.vue";
export default {
  components: { box },
  data: function () {
    return {
      loading: false,
      current: {},
      mydataChat1: {},
      mydataChat2: {},
      chatLeft: null,
      chatRight: null,
      chatBottom: null,
      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" },
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" },
      ]),
      color3: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#f7500f" },
        { offset: 0.5, color: "#eec54b" },
        { offset: 1, color: "#f7500f" },
      ]),
      color4: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#f7510f" },
        { offset: 0.5, color: "#ee154b" },
        { offset: 1, color: "#f75020" },
      ]),
    };
  },
  mounted: function () {
    this.select();
    this.chatLeft = window.echarts.init(document.getElementById("chatLeft"));
    this.chatRight = window.echarts.init(document.getElementById("chatRight"));
    this.rightBottom = window.echarts.init(
      document.getElementById("chatBottom")
    );
    this.chatBottom = window.echarts.init(
      document.getElementById("chatBottom")
    );
  },
  methods: {
    getOption(xAxis, yAxis, datas) {
      var obj = {
        textStyle: {
          color: "#8495c2",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: datas,
        legend: {
          data: [],
          textStyle: {
            color: "#ffffff",
          },
          orient: "vertical",
          right: 50,
        },
      };
      return obj;
    },
    beginLeftTop(num1, num2, num3, num4) {
      this.mydataChat1 = {
        num1: num1,
        num2: num2,
        num3: num3,
        num4: num4,
      };
      var sum = num1 + num2 + num3 + num4;
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          left: 5,
          bottom: 5,
          orient: "vertical",
          data: [
            this.$t("普通客人"),
            this.$t("重要客人"),
            this.$t("陌生人"),
            this.$t("嫌疑人"),
          ],
          textStyle: {
            color: "#ffffff",
          },
        },
        series: [
          {
            radius: ["60%", "90%"],
            name: this.$t("总数量"),
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: [
                  `{title|${this.$t("总数量")}}`,
                  `{count|${sum}}`,
                ].join("\n"),
                show: true,
                position: "center",
                rich: {
                  title: {
                    color: "white",
                    fontSize: 22,
                  },
                  count: {
                    color: "#8495c2",
                    fontSize: 34,
                    padding: [0, 0, 10, 0],
                  },
                },
              },
            },
            labelLine: {
              normal: {
                show: true,
              },
            },
            data: [
              { value: num1, name: this.$t("普通客人") },
              { value: num2, name: this.$t("重要客人") },
              { value: num3, name: this.$t("陌生人") },
              { value: num4, name: this.$t("嫌疑人") },
            ],
            color: [this.color1, this.color2, this.color3, this.color4],
          },
        ],
        grid: {
          top: 0,
          right: 100,
          bottom: 0,
          containLabel: false,
        },
      };
      option.title = {
        show: true,
        text: this.$t("人物识别比例"),
        subtext: "Person Ratio",
        padding: [20, 0, 0, 20],
        textStyle: {
          color: "#ffffff",
        },
      };
      this.chatLeft.setOption(option, true);

      window.addEventListener("resize", () => {
        this.chatLeft.resize();
      });
    },
    beginLeftBottom(j, s, t) {
      this.mydataChat2 = {
        num1: j,
        num2: s,
        num3: t,
      };
      var on = j;
      var off = s;
      var sub = j + s + t;
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          left: 5,
          bottom: 5,
          orient: "vertical",
          data: [this.$t("男性"), this.$t("女性"), this.$t("未知")],
          textStyle: {
            color: "#ffffff",
          },
        },
        series: [
          {
            radius: ["60%", "90%"],
            name: this.$t("总数量"),
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: [
                  `{title|${this.$t("总数量")}}`,
                  `{count|${sub}}`,
                ].join("\n"),
                show: true,
                position: "center",
                rich: {
                  title: {
                    color: "white",
                    fontSize: 22,
                  },
                  count: {
                    color: "#8495c2",
                    fontSize: 34,
                    padding: [0, 0, 10, 0],
                  },
                },
              },
            },
            labelLine: {
              normal: {
                show: true,
              },
            },
            data: [
              { value: on, name: this.$t("男性") },
              { value: off, name: this.$t("女性") },
              { value: t, name: this.$t("未知") },
            ],
            color: [this.color1, this.color3, this.color4],
          },
        ],
        grid: {
          top: 0,
          right: 100,
          bottom: 0,
          containLabel: false,
        },
      };
      option.title = {
        show: true,
        text: this.$t("性别比例"),
        //subtext: "Sex Ratio",
        padding: [20, 0, 0, 20],
        textStyle: {
          color: "#ffffff",
        },
      };
      this.chatRight.setOption(option, true);

      window.addEventListener("resize", () => {
        this.chatRight.resize();
      });
    },
    beginChatBottom(arrTrend) {
      //var data = this.current.Resources;
      var online = [];
      var labels = [];
      for (var i = 0; i < arrTrend.length; i++) {
        online.push(arrTrend[i].Count);
        labels.push(arrTrend[i].Month + "/" + arrTrend[i].Day);
      }

      var datas = [];
      datas.push({
        name: this.$t("数量"),
        type: "line",
        stack: "总数量",
        label: {
          normal: {
            show: true,
            position: "top",
            color: "white",
          },
        },
        smooth: true,
        data: online,
        itemStyle: {
          color: "#1b85c9",
        },
        areaStyle: {
          normal: {
            color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#00749a" },
              { offset: 0.5, color: "#123e62" },
              { offset: 1, color: "#132f57" },
            ]),
          },
        },
      });
      var x = {
        type: "category",
        boundaryGap: false,
        data: labels,
      };
      var y = {
        splitLine: {
          lineStyle: {
            color: "#8495c2",
          },
        },
        type: "value",
      };
      var option = this.getOption(x, y, datas);
      (option.title = {
        show: true,
        text: this.$t("人脸信息趋势"),
        subtext: "Face Recognition Trends",
        padding: [0, 0, 0, 20],
        textStyle: {
          color: "#ffffff",
        },
      }),
        this.chatBottom.setOption(option);

      window.addEventListener("resize", () => {
        this.chatBottom.resize();
      });
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/overview/GetFaceStatistics", "", true)
        .then((data) => {
          this.loading = false;
          this.current = data;
          var df = data.FaceRecognition;
          // 普通客人、重要客人、陌生人、嫌疑人
          this.beginLeftTop(
            df.RegularCount,
            df.VipCount,
            df.StrangerCount,
            df.SuspectCount
          );
          var ds = data.Sex;
          // 男性、女性
          this.beginLeftBottom(ds.MaleCount, ds.FemaleCount, ds.UnknownCount);
          // 每天识别数量折线图
          this.beginChatBottom(data.RecognitionTrend);
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
  },
};
</script>

