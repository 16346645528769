<template>
  <div class="boradm white">
    <actionbar
      v-bind:select="true"
      v-bind:insert="true"
      v-bind:del="true"
      v-on:select="select"
      v-on:insert="insert"
      v-on:delete="del"
    >
    </actionbar>
    <cityBroadCastPage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></cityBroadCastPage>
    <b-modal id="fileupload" ref="fileupload" :title="$t('MP3文件上传')">
      <el-upload
        :on-exceed="handleExceed"
        :http-request="doUpload"
        :multiple="false"
        :limit="1"
        class="upload-demo"
        ref="upload"
        action="123"
        accept=".mp3"
        :auto-upload="false"
      >
        <el-button slot="trigger" size="small" type="primary">{{
          $t("选取文件")
        }}</el-button>
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="submitUpload"
          >{{ $t("上传到服务器") }}</el-button
        >
        <div slot="tip" class="el-upload__tip">{{ $t("只能上传MP3文件") }}</div>
      </el-upload>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/CityBroadcast/Media/Delete"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import cityBroadCastPage from "./components/cityBroadCastPage.vue";
import deletedata from "@/components/deletedata";
import cookies from "@/assets/utils/kscookie";
export default {
  data: function () {
    return {
      cols: [
        { name: this.$t("序号"), value: "Id" },
        { name: this.$t("名称"), value: "Name" },
        { name: this.$t("大小"), value: "Time" },
        { name: this.$t("类型"), value: "Type" },
      ],
      items: [],
      index: 0,
      count: 1500,
      total: 0,
      current: null,
      sid: "",
      loading: {},

      files: [],
    };
  },
  components: { actionbar, cityBroadCastPage, deletedata },
  mounted: function () {
    this.$nextTick(() => {
      this.select();
    });
  },
  methods: {
    select() {
      this.loading = this.$loading({ fullscreen: true });
      let obj = {
        PageIndex: this.index,
        PageCount: this.count,
      };
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/Media/GetList", obj, true)
        .then((data) => {
          console.log("data:", data);
          this.loading.close();
          this.items = data.Rows;
          this.total = parseInt(data.Total);
        })
        .catch((error) => {
          this.loading.close();
          this.$throw(error);
        });
    },
    insert() {
      this.$refs.fileupload.show();
    },
    del() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      console.log("tid:", this.current.Id);
      this.sid = this.current.Name;
      this.$refs.del.show();
    },
    deleted() {
      this.select();
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    submitUpload() {
      this.$refs.upload.submit();
    },

    handleExceed(files) {
      this.$message.warning(
        `已选择了${files.length}个文件，如需更新请先删除已选择文件`
      );
    },

    doUpload(param) {
      console.log("param:", param);
      this.loading = this.$loading({ fullscreen: true });
      const formData = new FormData();
      formData.append("Token", cookies.get("token"));
      formData.append("Data", param.file);
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/Media/Add", formData, false)
        .then((data) => {
          console.log("data:", data);
          this.loading.close();
          this.$refs.fileupload.hide();
          this.select();
        })
        .catch((error) => {
          this.loading.close();
          this.$throw(error);
        });
    },
  },
};
</script>

