<template>
  <div id="acdetail" class="clearfix">
    <div class="ac_40">
      <boxtitle :data="infoTitle"  v-on:titleClick="getAcInfo" >
          <div class="ksflexcol" style="height:100%;color:white;" v-loading="loading_acinfo">
            <div class="flex1 row ksrow">
              <div class="col">{{$t("设备名称")}}</div>
              <div class="col">{{acinfo.DeviceName}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t("内置名称")}}</div>
              <div class="col">{{acinfo.AcName}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t("品牌")}}</div>
              <div class="col">{{acinfo.Vendor}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t("软件版本")}}</div>
              <div class="col">{{acinfo.SoftVer}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t("硬件版本")}}</div>
              <div class="col">{{acinfo.HardVer}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t("内存容量")}}</div>
              <div class="col">{{acinfo.Monory}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t("内存使用")}}</div>
              <div class="col">{{acinfo.MonoryLite}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t("IP地址")}}</div>
              <div class="col">{{acinfo.IP}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t("重启设备")}}</div>
              <div class="col"><el-button type="primary" round size="small" @click="reboot">
                  <i class="fa fa-window-restore"></i>
                  {{$t('重启设备')}}
                </el-button></div>
            </div>
          </div>
      </boxtitle>
    </div>
    <div class="ac_60">
      <div class="ac_h_35" v-loading="loading_apinfo"> 
        <boxtitle :data="apTitle"  v-on:titleClick="getApInfo">
          <el-table stripe="" :data="apinfo" :empty-text="$t('暂无数据')">
            <el-table-column :label="$t('名称')" prop="DeviceName"></el-table-column>
            <el-table-column :label="$t('ID')" prop="ApId"></el-table-column>
            <el-table-column :label="$t('IP地址')" prop="ApIp"></el-table-column>
            <el-table-column :label="$t('Mac')" prop="ApMac"></el-table-column>
            <el-table-column :label="$t('终端数量')" prop="ClientCount"></el-table-column>
            <el-table-column :label="$t('状态')" prop="Status"></el-table-column>
            <el-table-column :label="$t('品牌')" prop="Vendor"></el-table-column>
          </el-table>
        </boxtitle></div>
      <div class="ac_h_65" v-loading="loading_userinfo"> 
        <boxtitle :data="userTitle"  v-on:titleClick="getUserInfo">
          <el-table :data="userinfo" :empty-text="$t('暂无数据')">
            <el-table-column :label="$t('AP 型号')" prop="Model"></el-table-column>
            <el-table-column :label="$t('AP IP地址')" prop="ApIp"></el-table-column>
            <el-table-column :label="$t('AP Mac地址')" prop="ApMac"></el-table-column>
            <el-table-column :label="$t('通道')" prop="Signal"></el-table-column>
            <el-table-column :label="$t('Mac地址')" prop="Mac"></el-table-column>
            <el-table-column :label="$t('上线时间')" prop="OnlineTime"></el-table-column>
            <el-table-column :label="$t('SSID')" prop="SSID"></el-table-column>
            <el-table-column :label="$t('信号')" prop="Chanel"></el-table-column>
          </el-table>
        </boxtitle></div>
    </div>
  </div>
</template>
<style>
#acdetail {
  height: 100%;
  width: 100%;
}
#acdetail .ac_40 {
  height: 100%;
  width: 40%;
  float: left;
  padding: 5px;
}
#acdetail .ac_60 {
  padding: 5px;
  height: 100%;
  width: 60%;
  float: left;
}
#acdetail .ac_60 .ac_h_65 {
  padding-top: 5px;
  height: 65%;
  width: 100%;
}
#acdetail .ac_60 .ac_h_35 {
  height: 35%;
  width: 100%;
}
@media (max-width:769px){
    #acdetail .ac_40{
        width:100%;
    }
    #acdetail .ac_60{
        width:100%;
        height:150%
    }
}
</style>

<script>
import boxtitle from "@/components/boxtitle.vue";
export default {
  components: { boxtitle },
  data: function() {
    return {
      infoTitle: {
        title: this.$t("设备信息"),
        style: "fa fa-refresh",
        action: this.$t("刷新")
      },
      apTitle: {
        title: this.$t("AP信息"),
        style: "fa fa-refresh",
        action: this.$t("刷新")
      },
      userTitle: {
        title: this.$t("用户信息"),
        style: "fa fa-refresh",
        action: this.$t("刷新")
      },
      loading_acinfo:false,
      loading_apinfo:false,
      loading_userinfo:false,
      userinfo:[],
      apinfo:[],
      acinfo:{}
    };
  },
  mounted:function(){
    this.getAcInfo();
    this.getApInfo();
    this.getUserInfo();
  },
  methods:{
    reboot(){},
    getApInfo(){
      this.loading_apinfo=true;
       this.$ksajax.ksPostPromise("/api/ac/getapinfo",[this.$route.query.sid],true).then(data=>{
         this.apinfo=data;
         this.loading_apinfo=false;
       }).catch(err=>{
         this.loading_apinfo=false;
         this.$throw(err);
       })
    },
    getAcInfo(){
      this.loading_acinfo=true;
       this.$ksajax.ksPostPromise("/api/ac/getacinfo",this.$route.query.sid,true).then(data=>{
         this.acinfo=data;
         this.loading_acinfo=false;
       }).catch(err=>{
         this.loading_acinfo=false;
         this.$throw(err);
       })
    },
    getUserInfo(){
      this.loading_userinfo=true;
       this.$ksajax.ksPostPromise("/api/ac/getuserinfo",[this.$route.query.sid],true).then(data=>{
         this.userinfo=data;
         this.loading_userinfo=false;
       }).catch(err=>{
         this.loading_userinfo=false;
         this.$throw(err);
       })
    }
  }
};
</script>
