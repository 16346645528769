<template>
  <div class="homeBox" @click="to">
    <div class="homeBox-content">
      <div :style="style">
        <div style="position:absolute;right:0;" class="homebox_img">
          <img :src="this.box.pic" style="width:100%;" />
        </div>
        <div
          style="position:absolute;bottom:0;padding-bottom:15px;padding-left:20px"
        >{{this.box.title}}</div>
        <div class="homebox_top"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    box: Object,
  },
  data() {
    return {
      style: this.getBackground(),
    };
  },
  mounted() {
    this.style.background = `linear-gradient(${this.box.start},${this.box.end})`;
  },
  methods: {
    to() {
      if(this.box.path.indexOf("http:")!=-1){
        window.location.href=this.box.path;
      }else{
        this.$router.push({ path: this.box.path });
      }
    },
    getBackground() {
      return {
        position: "relative",
        height: "100%",
        background: "",
        "border-radius": "3%",
      };
    },
  },
};
</script>
<style scoped>
.homex1 {
  width: 12.5%;
}

.homex2 {
  width: 25%;
}
.homeBox {
  position: relative;
  color: white;
  float: left;
  cursor: pointer;
}
.homex2.homeBox:after {
  content: "";
  display: block;
  padding-bottom: 50%;
}
.homex1.homeBox:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.homeBox-content {
  padding: 0px 10px 10px 0px;
}
.homex1.homeBox .homeBox-content {
  position: absolute;
  width: 100%;
  height: 100%;
}
.homex2.homeBox .homeBox-content {
  position: absolute;
  width: 100%;
  height: 100%;
}
.homebox_img {
  margin: 15px;
}
.homex1.homeBox .homebox_img {
  width: 40%;
}
.homex2.homeBox .homebox_img {
  width: 20%;
}
.homebox_top {
  position: absolute;
  border-radius: 3%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.homebox_top:hover {
  background-color: rgba(0, 0, 0, 0);
  border: solid 1px;
}
@media (max-width: 960px) {
  .homex1 {
    width: 50%;
  }
  .homex2 {
    width: 100%;
  }
}
</style>