<template>
  <div class="Stands white" v-loading="loading">
    <div class="funView">
      <div class="logSeleView">
        <el-select v-model="parkSid" @change="select" class="logSe">
          <el-option
            v-for="(item, index) in parkList"
            :key="index"
            :value="item.Sid"
            :label="item.Name"
          ></el-option>
        </el-select>
        <el-select
          v-model="fieldNameCode"
          class="logSe1"
          placeholder="请选择查询条件"
        >
          <el-option value="0" label="车牌"></el-option>
          <el-option value="1" label="泊位号"></el-option>
          <el-option value="2" label="入场时间"></el-option>
          <el-option value="-1" label="全部"></el-option>
        </el-select>
        <el-input
          class="vInPut"
          v-model="value"
          placeholder="请输入内容"
          v-if="fieldNameCode === '0' || fieldNameCode === '1'"
        ></el-input>
        <el-date-picker
          v-model="dateTime"
          type="datetimerange"
          format="yyyy/MM/dd HH:mm"
          value-format="yyyy/MM/dd HH:mm"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          v-if="fieldNameCode === '2'"
        >
        </el-date-picker>
      </div>
      <actionbar
        class="btnView"
        v-bind:select="true"
        v-bind:exportFun="true"
        v-on:select="select"
        v-on:exportFun="exportFun"
      >
      </actionbar>
    </div>
    <parkPage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></parkPage>

    <b-modal
      ref="modal"
      :title="$t('详情')"
      centered
      content-class="ksmod"
      :ok-title="$t('确定')"
      hide-footer
      size="xl"
    >
      <div style="height: 350px">
        <logDetail :value="current"></logDetail>
      </div>
    </b-modal>
  </div>
</template>
<style>
.admin {
  background-color: white;
}
.funView {
  display: flex;
  align-items: center;
  background: rgb(209, 237, 219);
}
.logSeleView {
  /* border: 1px solid red; */
  /* width: 200px; */
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.el-input__inner {
  height: 32px;
  font-size: 12px;
}
.el-input__suffix {
  display: flex;
  align-items: center;
}
.btnView {
  flex: 1;
  height: 44px;
}
.actionbar-content {
  border: none;
}
.logSe {
  width: 100px;
}
.logSe1 {
  width: 150px;
}
.vInPut {
  width: 150px;
}

.el-input__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import actionbar from "@/components/actionbar";
import parkPage from "./components/parkPage.vue";
import logDetail from "./components/logDetail.vue";

export default {
  data: function () {
    return {
      cols: [
        { name: this.$t("停车场名称"), value: "ParkName" },
        { name: this.$t("泊位名称"), value: "ParkPortName" },
        { name: this.$t("泊位号"), value: "PortCode" },
        { name: this.$t("车牌"), value: "Plate" },
        { name: this.$t("入场时间"), value: "InTime" },
        // { name: this.$t("出场时间"), value: "OutTime" },
        // { name: this.$t("费用"), value: "Charge" },
        // { name: this.$t("入场图片"), value: "InPic", pic: true },
        // { name: this.$t("出场图片"), value: "OutPic", pic: true },
      ],
      items: [],
      index: 0,
      count: 5,
      total: 0,
      current: null,
      parkSid: "",
      loading: false,
      parkList: [],
      value: "",
      fieldName: "-1",

      fieldNameCode: "",
      dateTime: [],
    };
  },
  mounted: function () {
    this.getParkList();
  },
  components: {
    actionbar,
    parkPage,
    logDetail,
  },
  created: function () {},
  methods: {
    currentRow(val) {
      this.current = val;
      if (this.current) {
        this.$refs.modal.show();
      }
    },
    getParkList() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkList", "", true)
        .then((data) => {
          if (data.length != 0) {
            data.push({ Sid: "", Name: "全部" });
            this.parkList = data;
            this.loading = false;
            this.parkSid = this.parkList[0].Sid;
            this.select();
          } else {
            this.$throw(this.$t("数据为空"), this);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    select() {
      console.log("dateTime:", this.dateTime);
      var fieldName = "";
      var valueContent = "";
      var startTime = "";
      var endtime = "";
      if (this.fieldNameCode === "0" && this.value) {
        fieldName = "Plate";
        valueContent = this.value;
        startTime = "";
        endtime = "";
      } else if (this.fieldNameCode === "1" && this.value) {
        fieldName = "PortCode";
        valueContent = this.value;
        startTime = "";
        endtime = "";
      } else if (this.fieldNameCode === "2" && this.dateTime.length > 1) {
        fieldName = "InTime";
        valueContent = "";
        startTime = this.dateTime[0];
        endtime = this.dateTime[1];
      } else {
        fieldName = "";
        valueContent = "";
        startTime = "";
        endtime = "";
      }
      const data = {
        parkSid: this.parkSid,
        index: this.index,
        count: this.count,
        value: valueContent,
        fieldName: fieldName,
        startTime: startTime,
        endTime: endtime,
      };

      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkLogList", data, true)
        .then((data) => {
          this.items = data.Items;
          this.total = data.TotalCount;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },

    tableCreated() {
      // this.select();
    },

    currentPage(val) {
      this.index = (val - 1) * this.count;
      this.select();
    },
    exportFun() {
      console.log("导出");
      console.log("dateTime:", this.dateTime);
      var fieldName = "";
      var valueContent = "";
      var startTime = "";
      var endtime = "";
      if (this.fieldNameCode === "0" && this.value) {
        fieldName = "Plate";
        valueContent = this.value;
        startTime = "";
        endtime = "";
      } else if (this.fieldNameCode === "1" && this.value) {
        fieldName = "PortCode";
        valueContent = this.value;
        startTime = "";
        endtime = "";
      } else if (this.fieldNameCode === "2" && this.dateTime.length > 1) {
        fieldName = "InTime";
        valueContent = "";
        startTime = this.dateTime[0];
        endtime = this.dateTime[1];
      } else {
        fieldName = "";
        valueContent = "";
        startTime = "";
        endtime = "";
      }
      const data = {
        parkSid: this.parkSid,
        index: this.index,
        count: this.count,
        value: valueContent,
        fieldName: fieldName,
        startTime: startTime,
        endTime: endtime,
      };

      this.loading = true;
      this.$ksajax
        .ksPostExport("/api/ExcelExport/ParkLogs", data, true)
        .then((data) => {
          console.log("导出data:", data);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
  },
};
</script>

