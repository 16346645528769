<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
    ></actionbar>
    <el-table stripe=""
      :data="items"
      style="width: 100%"
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
      <el-table-column :label="$t('类型')" prop="type"></el-table-column>
      <el-table-column :label="$t('声音性别')" prop="gender"></el-table-column>
      <el-table-column :label="$t('大小')+'(kb)'" prop="Size"></el-table-column>
      <el-table-column :label="$t('内容')" prop="Content"></el-table-column>
    </el-table>
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('类型')" :label-cols="2">
          <b-form-select :options="types" v-model="modalData.Type" required></b-form-select>
        </b-form-group>
        <b-form-group v-bind:label="$t('名称')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Name" required></b-form-input>
        </b-form-group>
        <b-form-group v-if="modalData.Type==0" v-bind:label="$t('内容')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Content" required></b-form-input>
        </b-form-group>
        <b-form-group v-if="modalData.Type==0" v-bind:label="$t('声音性别')" :label-cols="2">
          <b-select v-model="modalData.Gender">
            <option value="0">男声</option>
            <option value="1">女声</option>
          </b-select>
        </b-form-group>
        <b-form-group v-if="modalData.Type==1" v-bind:label="'MP3'" :label-cols="2">
          <el-upload
            :on-success="Uploaded"
            class="upload-demo"
            :action="baseUrl+'/api/Upload/FileUploadSingleMd5'"
            :file-list="fileList"
            :data="fileData"
            :limit="1"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            accept=".mp3"
          >
            <el-button size="small" type="primary">{{$t('上传到服务器')}}</el-button>
          </el-upload>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width:100px" v-on:click="hiden">Cancel</b-button>
            <b-button type="submit" style="width:100px" variant="primary">OK</b-button>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/nova/DeleteBroadRes"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import deletedata from "@/components/deletedata";
export default {
  components: { actionbar, tablepage, deletedata },
  data: function() {
    return {
      types: [
        { text: "文本", value: "0" },
        { text: "MP3", value: "1" }
      ],
      modalData: this.getResetData(),
      fileList: [],
      fileData: {},
      title: "",
      items: [],
      current: null,
      sid:""
    };
  },
  mounted: function() {
    this.select();
  },
  methods: {
    currentRow(val) {
      this.current = val;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePreview() {},
    Uploaded(val,file) {
      this.modalData.Content = val.Data.Url;
      this.modalData.Size = val.Data.Length;
      this.modalData.Md5 = val.Data.Md5;
       if (val.RetCode == 0) {
        file.url = val.Data;
        this.fileList.push(file);
      } else {
        throw val.RetMsg;
      }
    },
    getResetData() {
      return {
        Sid: "",
        Type: 0,
        Content: "请输入需要转化为音频的文本",
        Size: 0,
        Md5: "",
        Name: "资源名称",
        Gender: 0
      };
    },
    select() {
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/nova/GetBroadRes", "", true)
        .then(data => {
          this.$loading().close();
          this.items = data;
          this.items.forEach(x => {
            x.type = x.Type == 0 ? "语音" : "Mp3";
            x.gender =x.Type == 0? x.Gender == 0 ? "男声" : "女声":"",
            (x.Size = x.Type == 0 ? x.Content.length : x.Size);
          });
        })
        .catch(err => {
          this.$loading().close();
          this.$throw(err);
        });
    },
    del() {
          if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    insert() {
      this.modalData = this.getResetData();
      this.$refs.modallg.show();
    },
    hiden() {
      this.$refs.modallg.hide();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.modalData = this.getResetData();
      this.modalData.Name = this.current.Name;
      this.modalData.Content = this.current.Content;
      this.modalData.Type = this.current.Type;
      this.modalData.Gender = this.current.Gender;
      this.modalData.Size=this.current.Size;
      this.modalData.Sid=this.current.Sid;
      if (this.modalData.Type == 1) {
        this.fileList = [];
        this.fileList.push({
          name: this.current.Name,
          url: this.current.Content
        });
      }
       this.$refs.modallg.show();
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.fileList.length != 1 && this.modalData.Type == 1) {
        throw this.$t("请上传一首Mp3");
      }
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/nova/AddBroadRes", this.modalData, true)
        .then(() => {
          this.$loading().close();
          this.hiden();
          this.select();
        })
        .catch(err => {
          this.$loading().close();
          this.$throw(err);
        });
    },
    deleted(){
        this.select();
    }
  }
};
</script>