<template>
    <div :style="style">
        <div v-for="item in modalData.StateCotnents" :key="item.Sid" :style="item.style">
            <div v-if="item.Data.Type==0" :style="item.Custom">
                {{item.Data.Content}}
            </div>
            <div v-if="item.Data.Type==1" :style="item.Custom">
                <b-img class="autoimg" :src="item.Data.Content"></b-img>
            </div>
            <div v-if="item.Data.Type==2" :style="item.Custom">
                <video controls="" autoplay="true" name="media" width="100%" height="100%" loop="loop">
                    <source :src="item.Data.Content" type="video/mp4">
                </video>
            </div>
            <div v-if="item.Data.Type==3" :style="item.Custom">
                <textscroll :data="item.Data.Content" :speed="item.Custom.speed"></textscroll>
            </div>
            <div v-if="item.Data.Type==4" style="height:100%;">
                 <el-carousel trigger="click" style="height:100%;">
                    <el-carousel-item v-for="e in JSON.parse(item.Data.Content)" :key="e" indicator-position="none">
                        <div :style="item.Custom">
                            <b-img class="autoimg" :src="e"></b-img>
                        </div>
                    </el-carousel-item>
                 </el-carousel>
            </div>
            <sensorinfo v-if="item.Data.Type==5" :data="item" :compute="true"></sensorinfo>
            
            <div v-if="item.Data.Type==6" :style="item.Custom">
                <video :id="item.Sid" poster="" controls playsInline webkit-playsinline autoplay style="max-width:100%">
                    <source :src="item.Data.Content" type="application/x-mpegURL" />
                </video>
            </div>
        </div>
    </div>
</template>
<style>
.el-carousel__container{
    height: 100% !important;
}
</style>

<script> 
import textscroll from "@/components/textscroll";
import sensorinfo from "@/components/sensorinfo";
export default {
    components:{textscroll,sensorinfo},
    props:["modalData"],
    data:function(){
        return {
            style:{
                position:"relative",
                border:"1px solid",
                width:"100%",
                height:"100%",
                margin:"0 auto"
            },
            scrollTimer:null,
            camras:[]
        }
    },
    watch:{
        "modalData":function(){
        this.style.width=this.modalData.Width+"px";
        this.style.height=this.modalData.Height+"px";
        this.$nextTick(()=>{
            this.init();
        })
        }
    },
    mounted:function(){
        this.style.width=this.modalData.Width+"px";
        this.style.height=this.modalData.Height+"px";
        this.$nextTick(()=>{
            this.init();
        })
    },
    methods:{
        setData(data){
        this.modalData=data;
        },
        onResizing(){
         },
         onDrag(){
         },
         onActive(){
         },
         onDeActive(){
         },
         init(){
          this.camras=[];
          if(this.modalData.StateCotnents){
                 this.modalData.StateCotnents.forEach(element => {
                     if(element.Data.Type==6){
                         if(document.getElementById(element.Sid)){
                             var value=  new window.EZUIPlayer(element.Sid);
                             this.camras.push({
                                 sid:element.Sid,
                                 player:value
                             })
                         }
                     }
                 });
             }
         },
         stop(){
            var list=document.getElementsByTagName("video");
            if(list&&list.length!=0){
                list.forEach(x=>{
                    x.pause();
                })
            }
         },
         start(){
            var list=document.getElementsByTagName("video");
            if(list&&list.length!=0){
                list.forEach(x=>{
                    x.start();
                })
            }
         }
    },
    destroyed:function(){
        this.stop();
    }
}
</script>
