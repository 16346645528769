<template>
  <div style="width: 100%; height: 100%">
    <component
      ref="com"
      v-bind:is="currentComponent"
      :sid="vdata.deviceSid"
      :stringvalue="vdata.stringValue"
      :objvalue="vdata.objectValue"
    ></component>
  </div>
</template>
<script>
import car from "../../car/components/cardetail.vue";
import cover from "../../conver/components/converdetail.vue";
import water from "../../water/components/waterdetail.vue";
import sensor from "../../sensor/components/sensordetail";
import stand from "../../stand/components/standDetail";
import hydrant from "../../hydrant/components/hydrantdetail";
import voice from "../../voice/components/voicedetail";
import light from "./light.vue";
import led from "./led.vue";
import txt from "./text.vue";
import piccom from "./pic.vue";
import carcom from "./car.vue";
export default {
  props: ["vdata"],
  watch: {
    vdata: {
      immediate: true,
      deep: true,
      handler() {
        console.log(this.vdata,"console");
        this.fresh();
      },
    },
  },
  components: { car, cover, water,sensor,stand ,hydrant,light,led,txt,piccom,carcom,voice},
  data(){
      return{
          currentComponent:null
      }
  },
  methods: {
    fresh() {
       this.currentComponent=this.getCurrentComponent(this.vdata.CtgSid);
    //    this.$refs.com.init();
    },
    getCurrentComponent(type) {
      var com = null;
      switch (type) {
        case "009":
          com = "water";
          break;
        case "010":
          com = "cover";
          break;
        case "011":
          com = "car";
          break;
        case "005":
          com = "sensor";
          break;
        case "099":
          com = "stand";
          break;
        case "012":
          com = "hydrant";
          break;
        case "004":
          com = "light";
          break;
        case "006":
          com = "led";
          break;
        case "015":
          com = "txt";
          break;
        case "001":
          com = "piccom";
          break;
          case "200":
            com="carcom";
            break;
          case "016":
            com="voice";
            break;
      }
      return com;
    },
  },
};
</script>