<template>
  <div style="width:100%;height:100%" class="ksflex" v-loading="loading">
    <div v-for="item in datas" 
    :key="item.value" 
    :class="item.cls" 
    :ref="item.value"
    >
      <div class="sensor_content_pic">
        <img :src="item.pic"/>
      </div>
      <div class="sensor_content_value animated" :ref="`${item.value}_text`">
         {{item.val}}{{item.pix}}
      </div>
      <div class="sensor_content_text">
        {{item.text}}
      </div>
    </div>
  </div>
</template>
<style scoped>
.sensor_content{
  height: 100%;
  flex: 1;
  background-image: linear-gradient(0deg, rgba(51, 150, 250, 0.498),rgba(51, 150, 250, 0));
  margin: 1px;
  justify-content: center
}
.sensor_content_check .sensor_content_pic,.sensor_content_check .sensor_content_value{
  display: block !important;
}
.sensor_content_check.sensor_content{
  /* width: 30%; */
  flex: 20%;
  background-image: linear-gradient(0deg, rgba(255, 72, 72, 0.498),rgba(255, 72, 72, 0));
}
.sensor_content{
  transition: all 0.5s ease 0s;;
}
.sensor_content:hover{
  /* width: 30%; */
  flex: 20%;
  background-image: linear-gradient(0deg, rgba(255, 72, 72, 0.498),rgba(255, 72, 72, 0));
}
.sensor_content:hover .sensor_content_pic,.sensor_content:hover .sensor_content_value{
 display: block !important;
}
.sensor_content_check.unshowpic .sensor_content_text{
  width: 100% !important;
}
.unshowpic .sensor_content_text{
  text-align: center;
  font-size: 14px;
  width: 14px;
  word-wrap:break-word;
}
.unshowpic .sensor_content_pic,
.unshowpic .sensor_content_value
{
  display: none;
}
.showshowpic{
  display: block;
}
</style>
<script>
import {sensorFormat} from "@/assets/utils/datef"
import { BaseUrl } from '../../../../assets/utils/config';
export default {
  props:["sid","stringvalue","showpic"],
  data(){
    return{
      loading:false,
      datas:[]
    }
  },
  mounted(){
    this.select();
  },
  watch:{
    "$store.state.dataHubChange":function(){
       var data = this.$store.state.dataHub;
       if (data.CtgSid == "005"&&this.sid == data.DeviceSid) {
         this.datas.forEach(x=>{
           if(x.val!=data.Data[x.value]){
             x.val=data.Data[x.value];
             var element=this.$refs[`${x.value}_text`];
             if(element){
                element[0].classList.remove("flip");
             }
              window.setTimeout(
                  (ele) => {
                     ele.classList.add("flip");
                  },
                  500,
                  element[0]
                );
           }
         });
       }
    },
    sid:function(){
      this.select();
    },
    stringvalue:function(){
      this.select();
    }
  },
  methods:{
    
    change(value){
       var c=this.showpic?"showpic":"unshowpic"
      this.datas.forEach(x=>{
          x.pic=`${BaseUrl}/nicon/sensor/${x.value}.png`;
        if(x.value!=value){
          x.cls="sensor_content ksflexcol"+" "+c;
        }else{
          x.cls="sensor_content ksflexcol sensor_content_check"+" "+c;
          if(this.stringvalue&&this.stringvalue==x.value){
           x.pic=`${BaseUrl}/nicon/sensor/${x.value}_a.png`;
          }
        }
      });
    },
    select(){
      this.loading=true;
      this.$ksajax.ksPostPromise("/api/device/GetSensorDatas", [this.sid], true).then(data=>{
        if(data.length==0){
          throw ("没有找到数据");
        }
        var keys=sensorFormat(data[0].SensorData);
        keys.forEach(element => {
          var c=this.showpic?"showpic":"unshowpic"
          element.cls="sensor_content ksflexcol"+" "+c
          if(element.kind==this.stringvalue){
            element.cls="sensor_content ksflexcol sensor_content_check"+" "+c
            element.pic=`${BaseUrl}/nicon/sensor/${element.value}_a.png`;
          }else{
            element.pic=`${BaseUrl}/nicon/sensor/${element.value}.png`;
          }
        });
        this.datas=keys;
        this.loading=false;
      }).catch(err=>{
        this.$throw(err);
        this.loading=false;
      })
    },
     action(data, sid) {
      if (data.Dm || data.Dm == 0) {
        if (data.Dm > 337.5 && data.Dm <= 22.5) {
          data.Dm = this.dmFormat("北风", data.Dm);
        }
        if (data.Dm > 22.5 && data.Dm <= 67.5) {
          data.Dm = this.dmFormat("东北风", data.Dm);
        }
        if (data.Dm > 67.5 && data.Dm <= 112.5) {
          data.Dm = this.dmFormat("东风", data.Dm);
        }
        if (data.Dm > 112.5 && data.Dm <= 157.5) {
          data.Dm = this.dmFormat("东南风", data.Dm);
        }
        if (data.Dm > 157.5 && data.Dm <= 202.5) {
          data.Dm = this.dmFormat("南风", data.Dm);
        }
        if (data.Dm > 202.5 && data.Dm <= 247.5) {
          data.Dm = this.dmFormat("西南风", data.Dm);
        }
        if (data.Dm > 247.5 && data.Dm <= 292.5) {
          data.Dm = this.dmFormat("西风", data.Dm);
        }
        if (data.Dm > 292.5 && data.Dm <= 337.5) {
          data.Dm = this.dmFormat("西北风", data.Dm);
        }
      }
      if (sid == "201810241212573190592165") {
        data.PM2_5 = 10;
        var ran = parseInt(Math.random() * 10);
        data.PM2_5 += ran;
      }
    }
  }
}
</script>