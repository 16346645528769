<template>
  <div class="white" style="height:100%">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :insert="true"
      :del="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:insert="insert"  
      v-on:delete="del"
    ></actionbar>
    <el-table stripe=""
      :data="items"
      style="width: 100%"
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
    </el-table>

    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      :title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('名称')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Name" required></b-form-input>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width:100px" v-on:click="hiden('modallg')">Cancel</b-button>
            <b-button type="submit" style="width:100px" variant="primary">OK</b-button>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- 删除多好看 -->
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/face/DelFacePartys"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import deletedata from "@/components/deletedata";
export default {
  components: { actionbar,deletedata },
  data: function() {
    return {
      current: {},
      items: [],
      loading: {},
      modalData: {},
      templetes: [],
      title: "",
      sid:""
    };
  },
  mounted: function() {
    this.select();
  },
  methods: {
    select() {
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/face/GetFacePartys", "", true)
        .then(r => {
          this.items = r;
          this.loading.close();
        })
        .catch(err => {
          this.loading.close();
          this.$throw(err);
        });
    },
    modify() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
          this.modalData = {
            Sid: this.current.Sid,
            Name: this.current.Name
          };
          this.title = this.$t("修改");
          this.$refs.modallg.show();
    },
    insert() {
        this.modalData.Sid=null;
        this.modalData.Name="";
        this.title = this.$t("新增");
        this.$refs.modallg.show();
    },
    currentRow(val) {
      this.current = val;
    },
    hiden(name) {
      if (!name) {
        this.$refs.modallg.hide();
      } else {
        this.$refs[name].hide();
      }
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/face/ModifyFaceParty", this.modalData, true)
        .then(() => {
          this.loading.close();
          this.hiden();
          this.select();
        })
        .catch(err => {
          this.$throw(err);
          this.hiden();
        });
    },
    del(){

      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    deleted(){
        this.select();
    }
  }
};
</script>
