<template>
  <div class="info_body" v-loading="!projected">
    <b-navbar id="info_menu" toggleable="md" type="dark" >
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-navbar-brand to="/info/login">
        <b-img
          v-if="!show"
          :src="projectData.Logo"
          style="max-height: 40px"
        ></b-img>
        <b-img
          v-else
          :src="baseUrl+'/upload/202009111601130530631487.png'"
          style="max-height: 40px"
        ></b-img>
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav
          fill
          justified
          style="width: 100%"
          v-show="projectData.HasMenu && !show"
        >
          <b-nav-item pills to="/info/login">{{ $t("登录首页") }}</b-nav-item>
          <b-nav-item pills to="/info/stand">{{ $t("智慧灯杆") }}</b-nav-item>
          <b-nav-item pills to="/info/price">{{ $t("服务价格") }}</b-nav-item>
          <!-- <b-nav-item pills to="/info/soft">{{ $t("软件平台") }}</b-nav-item> -->
          <!-- <b-nav-item pills to="/info/product">{{ $t("成功案例") }}</b-nav-item> -->
          <!-- <b-nav-item pills to="/info/download">{{$t("资料下载")}}</b-nav-item> -->
          <b-nav-item pills to="/info/contact">{{ $t("联系我们") }}</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-form-radio-group
            buttons
            button-variant="outline-primary"
            v-model="lang"
            class="lanswitch"
            @change="lanChange"
          >
            <el-radio-group
              v-model="lang"
              size="mini"
              fill="black"
              style="margin-right: 20px"
              @change="lanChange"
            >
              <el-radio-button value="cn" label="zh"></el-radio-button>
              <el-radio-button value="en" label="en"></el-radio-button>
            </el-radio-group>
          </b-form-radio-group>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="info_content">
      <router-view style="padding-bottom: 70px"></router-view>
    </div>
    <div class="bottom1">
      <span style="padding-right:10px;">@2020-2022 Kingsunsmart.com 版权所有</span>
      备案号：<a target="_blank" href="http://beian.miit.gov.cn" style="color:white">粤ICP备17060943 </a>
    </div>
  </div>
</template>
<style>
.info_body{
  height: 100%;
}
.bottom1{
  /* position: fixed; */
  height: 50px;
  bottom: -50px;
  width: 100%;
  color: white;
  background-color: #333;
  line-height: 50px;
  padding-left: 50px;
  z-index: 100;
  font-size: 14px;
}
.info_footer {
  background-color: #333;
  height: 70px;
  color: #ffffffbf;
  font-size: 12px;
  width: 100%;
  bottom: 0px;
}
.info_footer > div {
  flex: 1;
}
.lanswitch {
  width: 120px;
}
.trannav {
  position: absolute;
  z-index: 2;
  width: 100%;
}
@media (max-width: 768px) {
  .trannav {
    background: #6b6b65 !important;
  }
}
.info_menu {
  /* position: absolute; */
  width: 100%;
  height: 70px;
  z-index: 2;
  border-bottom: 1px solid #6b6b65;
}
.info_content {
  /* position: absolute; */
  z-index: 1;
  width: 100%;
  height: 100%;
}
</style>

<script>
import config from "@/assets/utils/config";
export default {
  data: function () {
    return {
      baseUrl:config.BaseUrl,
      lang: "zh",
      projected: false,
      projectData: { Logo: "" },
      footStyle: {
        display: "block",
      },
      show: config.ISNETURE || config.Theme != 0,
    };
  },
  methods: {
    lanChange() {
      //先切换语言
      this.$i18n.locale = this.lang;
      this.$cookies.set("lan", this.$i18n.locale, 365);
    },
    loadProject() {
      if (this.$store.state.projectData) {
        this.projected = true;
        this.projectData = this.$store.state.projectData;
      } else {
        //获取域名
        var domain = {
          DomainName: window.location.host,
        };
        this.$ksajax
          .ksPostPromise("/api/smart/GetWebInfo", domain, false)
          .then((data) => {
            this.projected = true;
            if (config.ISNETURE||config.Theme!=0) {
              data.HasMenu = false;
              this.footStyle.display = "none";
              data.ProjectName = this.$t("智慧城市平台");
              data.IsNetrue = true;
            }
            this.$store.commit("setProjectData", data);
            this.projectData = this.$store.state.projectData;
            document.title = data.ProjectName || "智慧城市平台";
          })
          .catch((err) => {
            this.$throw(err);
          });
      }
    },
  },
  mounted: function () {
    this.lang = this.$cookies.get("lan");
    this.$i18n.locale = this.lang;
    this.$store.commit("setProject", null);
    this.loadProject();
     var ele = document.getElementById("info_menu");
      if (ele) {
        ele.style.backgroundColor = "#333333";
      }
  },
};
</script>
