<template>
  <div class="info_productdetail">
      <div class="container">
          <div class="row">
            <div class="soft_title">
                {{item.Name}}
                <hr />
            </div>
                <p>{{item.Desc}}</p>
          </div>
          <div class="row">
            <div class="soft_title">
                 现场图片
                <hr />
            </div>
            <!-- <b-img v-for="i in item.Pics" :src="i" :key="i"  style="margin-top:5px"  fluid=""></b-img> -->
            <div style="">
                
            <img v-for="i in item.Pics"  :src="i" :key="i"  style="width:100%;height:auto;max-width:100%;overflow:hidden;margin-top:10px"/>
            </div>
          </div>
      </div>
  </div>
</template>
<style>
.info_productdetail {
  padding-top: 70px;
}
</style>

<script>
import { BaseUrl } from '../../assets/utils/config';
export default {
    data:function(){
        return{
            item:{}
        }
    },
  methods: {
    getData() {
      return [
        {
          Sid: 0,
          Name: "贵阳市智慧商业区及景观亮化项目",
          Content:
            "市西滨河商业街定位为富有贵州地域文化特色的现代时尚街区，本次设计范围在商业街的基础上，继续塑造市西路滨河景观夜景设计",
          Desc:
            "市西滨河商业街定位为富有贵州地域文化特色的现代时尚街区，本次设计范围在商业街的基础上，继续塑造市西路滨河景观夜景设计，水与街的互动与融合是一种自然而然的过程，由此带来的繁华与活力也是一种必然的演化过程。智慧庭院灯沿河道两边安装，数量约150套。主要有水位监测、人流密集度监测、人脸识别、公共广播、无线网络等功能。当前方三公里水位超过警戒线后，通过广播告知现场人员撤离，监控中心预警，河道围栏关闭；当摄像头检测到商业街道人员密集度达到预警值后，通过广播告知现场游客，监控中心预警；摄像头抓取现场人脸，与公安系统对接。",
          Pics: [
            `${BaseUrl}/img/case/gy1.jpg`,
            `${BaseUrl}/img/case/gy2.jpg`,
            `${BaseUrl}/img/case/gy3.jpg`,
            `${BaseUrl}/img/case/gy4.jpg`,
            `${BaseUrl}/img/case/gy5.jpg`,
            `${BaseUrl}/img/case/gy6.jpg`,
            `${BaseUrl}/img/case/gy7.jpg`,
          ]
        },
        {
          Sid: 1,
          Name: "南昌新建城基础和公共配套设施项目",
          Content:
            "南昌新建城项目位于新建区长堎新区，打造集生态宜居、活力商贸、文化休闲等功能为一体的现代化高品质复合型宜居新城",
          Desc:
            "南昌新建城项目位于新建区长堎新区，总面积约3000亩，主要开发建设住宅、商业、基础和公共配套设施，打造集生态宜居、活力商贸、文化休闲等功能为一体的现代化高品质复合型宜居新城。对十三条道路进行整体设计及施工，由低、中、高三种灯杆配置型号，共计768杆，集成LED照明，视频监控、无线网络、一键呼叫、信息发布、RFID等功能。",
          Pics: [
            `${BaseUrl}/img/case/nc1.jpg`,
            `${BaseUrl}/img/case/nc2.jpg`,
            `${BaseUrl}/img/case/nc3.jpg`,
            `${BaseUrl}/img/case/nc4.jpg`,
            `${BaseUrl}/img/case/nc5.jpg`,
            `${BaseUrl}/img/case/nc6.jpg`,
            `${BaseUrl}/img/case/nc7.jpg`,
            `${BaseUrl}/img/case/nc8.jpg`,
          ]
        },
        {
          Sid: 2,
          Name: "韶关智慧城市",
          Content: "联合广东广电网络，建设基于城市光纤的智慧城市试点",

          Desc:
            "联合广东广电网络，建设基于城市光纤的智慧城市试点。项目位于韶关西桥梁公园，集成LED照明、环境监测、无线网络、视频监控、公共广播、紧急呼叫、信息发布、井盖监测、车位管理等功能。",
          Pics: [
            `${BaseUrl}/img/case/sg1.jpg`]
        }
      ];
    }
  },
  mounted: function() {
    var sid=this.$route.query.Sid;
    this.item=this.getData().find(x=>x.Sid==sid);
    this.$nextTick(() => {
      // var ele = document.getElementById("info_menu");
      // if (ele) {
      //   ele.style.backgroundColor = "#333333";
      // }
    });
  }
};
</script>
