<template>
    <div class="box_v">
        <div  :class="value&&value.Alarm?'box_value box_value_alarm':'box_value box_value_normal'">
            <div class="box_value_content ksflexcol">
                <div>{{value.Value}}{{value.Fix}}</div>
                <div style="flex:1" class="box_value_content_img ksflex">
                    <div style="flex:1;font-size:14px">{{value.Name}}</div>
                    <div style="flex:1">
                        <img style="width:70%" :src="value.Alarm?value.AlarmPic:value.Pic"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:["value"]
}
</script>