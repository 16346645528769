<template>
  <div class="detailview" v-loading="loading">
    <div class="detailview_1" style="width: 25%">
      <div>
        <box style="position: relative">
          <div
            style="height: 100%; width: 100%; padding-top: 20px"
            id="chatLeft"
          ></div>
        </box>
      </div>
    </div>
    <div class="detailview_1" style="width: 50%">
      <div>
        <box id="canvas_box">
          <div class="ksflex" style="width: 100%; height: 100%">
            <div style="flex: 1; height: 100%">
              <netvideo :playdata="playdata" ref="camininet" v-if="!IsISC"></netvideo>
              <natpay :data="playdata" ref="camininat" v-else :count="1" :showbar="true"></natpay>
            </div>
            <div style="width: 120px; height: 100%;overflow:auto;" ref="imgcon" class="hiden_scroll">
                <div  v-for="item in facePics" :key="item.sid" style="text-align:center;padding:5px">
                    <b-img :src="item.path" style="width:70%"></b-img>
                </div>
            </div>
          </div>
        </box>
      </div>
    </div>
    <div class="detailview_1" style="width: 25%">
      <div>
        <box>
          <div
            style="height: 100%; width: 100%; padding-top: 20px"
            id="chatLeft2"
          ></div>
        </box>
      </div>
    </div>
    <div class="detailview_2">
      <div>
        <box>
          <div
            style="height: 100%; width: 100%; padding-top: 20px"
            id="chatTop"
          ></div
        ></box>
      </div>
    </div>
  </div>
</template>
<script>
import box from "@/components/box1.vue";
import netvideo from "@/components/netvideo";
import natpay from '@/components/natpay'
export default {
  watch: {
    "$store.state.faceHubChange": function () {
      var data = this.$store.state.faceHub;
      var type = data.Type;
      var sid = data.Sid;
      switch (type) {
        case 0: // 0表示人脸检测信息。
          // 过滤非本摄像头
          if (this.sid === data.DeviceSid) this.getFaceId(sid);
          break;
        case 1: // 1表示人脸告警信息。
          if (this.sid === data.DeviceSid) this.getFaceId(sid);
          break;
        default:
          this.$throw("-类型");
          break;
      }
    },
  },
  components: { box, netvideo,natpay },
  data() {
    return {
      facePics: [
        {
          sid: Math.random(),
          path: "/img/unknow.png",
        },
      ],
      chatLeft: null,
      chatTop: null,
      chatLeft2: null,
      current: null,
      playdata: {},
      IsISC:false,
      loading: false,
      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" },
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" },
      ]),
      color3: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#f7500f" },
        { offset: 0.5, color: "#eec54b" },
        { offset: 1, color: "#f7500f" },
      ]),
      color4: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#f7510f" },
        { offset: 0.5, color: "#ee154b" },
        { offset: 1, color: "#f75020" },
      ]),
      color5: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#6BC416" },
        { offset: 0.5, color: "#119857" },
        { offset: 1, color: "#066336" },
      ]),
      color6: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#4A89CC" },
        { offset: 0.5, color: "#175698" },
        { offset: 1, color: "#073463" },
      ]),
      color7: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#E766E5" },
        { offset: 0.5, color: "#EF34D7" },
        { offset: 1, color: "#9B118A" },
      ]),
      color8: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#F4F4F4" },
        { offset: 0.5, color: "#E9E9E9" },
        { offset: 1, color: "#BBBBBB" },
      ]),
      color9: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#BFBFCA" },
        { offset: 0.5, color: "#C0BBC6" },
        { offset: 1, color: "#B5BBC0" },
      ]),
      color10: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#323232" },
        { offset: 0.5, color: "#282828" },
        { offset: 1, color: "#211010" },
      ]),
      colorArr: [
        this.color1,
        this.color2,
        this.color3,
        this.color4,
        this.color5,
        this.color6,
        this.color7,
        this.color8,
        this.color9,
        this.color10,
      ],
    };
  },
  mounted() {
    this.chatLeft = window.echarts.init(document.getElementById("chatLeft"));
    this.chatLeft2 = window.echarts.init(document.getElementById("chatLeft2"));
    this.chatTop = window.echarts.init(document.getElementById("chatTop"));
    this.select();
  },
  methods: {
      test(){
          this.facePics.push({
            sid: Math.random(),
          path: "/img/unknow.png",
          });
          this.$refs.imgcon.scrollTop=this.$refs.imgcon.scrollHeight;
      },
    play(dev) { 
      var url = "/api/device/GetPayNat";
      this.IsISC=dev.IsISC;
      var sid = this.$route.query.sid;
      var req = this.package(sid, "hd");
      if(dev.IsISC){
      // 局域网播放
      this.$ksajax.ksPostPromise(url,sid,true).then(data=>{
          this.$refs.camininat.start(data,()=>{
                },(err)=>{
                    this.$throw(err);
                });
      })
      }else{

      // 外网播放
       url = "/api/device/GetPayNet";
      this.$ksajax.ksPostData(url, req, true, (success) => {
        this.playdata.data = success;
        this.playdata.play = true;
        this.playdata.device={
          sid:dev.Sid,
          serial:dev.Serial
        };
        this.$refs.camininet.start();
      });
      }
    },

    getFaceId(sid) {
      this.$ksajax
        .ksPostPromise("/api/face/GetFaceBySid", sid, true)
        .then((data) => {
          this.facePics.push({
            sid: data.Sid,
            path: data.FaceUrl,
          });
          this.$refs.imgcon.scrollTop=this.$refs.imgcon.scrollHeight;
        })
        .catch(() => {
          // this.$throw(err);
        });
    },
    // 广域网请求头封装
    package(sid, hd) {
      return {
        EzopenType: 0,
        Channel: 1,
        Definition: hd,
        PlaybackSource: 0,
        Sid: sid,
      };
    },
    select() {
      this.sid = this.$route.query.sid;
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/face/GetStatisticsByDevSid", this.sid, true)
        .then((data) => {
          this.loading = false;
          this.current = data;
          // 普通客人、重要客人、陌生人、嫌疑人
          //this.beginLeftBarGraph(df.RegularCount, df.VipCount, df.StrangerCount, df.SuspectCount);
          //var ageNameArr = [this.$t('10岁以下'), this.$t('10~20岁'), this.$t('20~30岁'), this.$t('30~40岁'), this.$t('40~50岁'), this.$t('50~60岁'), this.$t('60~70岁'), this.$t('70~80岁'), this.$t('80岁以上'), this.$t('未知')];
          var ageNameArr = [
            this.$t("10后"),
            this.$t("00后"),
            this.$t("90后"),
            this.$t("80后"),
            this.$t("70后"),
            this.$t("60后"),
            this.$t("50后"),
            this.$t("40后"),
            this.$t("80岁以上"),
            this.$t("未知"),
          ];
          this.pieAgeChart(data.Age, ageNameArr, this.chatLeft);
          //   // 人物类型分布
          var df = data.FaceRecognition;
          this.piePersonType(
            [df.RegularCount, df.VipCount, df.StrangerCount, df.SuspectCount],
            this.chatLeft2
          );
          //   var ds = data.Sex;
          //   // 男性、女性
          //   this.beginLeftBottom(ds.MaleCount, ds.FemaleCount, ds.UnknownCount);
          //   // 每天识别数量折线图
          //   this.beginChatBottom(data.Age);
          //   // 每天识别数量折线图
          this.beginChatTop(data.RecognitionTrend);
          this.play(data.DeviceInfo);
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
    beginChatTop(arrTrend) {
      var online = [];
      var labels = [];
      for (var i = 0; i < arrTrend.length; i++) {
        online.push(arrTrend[i].Count);
        labels.push(arrTrend[i].Month + "/" + arrTrend[i].Day);
      }

      var datas = [];
      datas.push({
        name: this.$t("数量"),
        type: "line",
        stack: this.$t("总数量"),
        label: {
          normal: {
            show: true,
            position: "top",
            color: "white",
            fontSize: 20,
          },
        },
        symbolSize: 15,
        smooth: true,
        data: online,
        itemStyle: {
          //color: this.color2,
          color: "#ffb669",
          borderWidth: 1,
        },
        areaStyle: {
          normal: {
            color: new window.echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              //[
              //    { offset: 0, color: '#00749a' },
              //    { offset: 0.5, color: '#123e62' },
              //    { offset: 1, color: '#132f57' }
              //],
              [
                { offset: 0, color: "#ffb669" },
                { offset: 0.5, color: "#ea413f" },
                { offset: 1, color: "#132f57" },
              ]
            ),
          },
        },
        lineStyle: {
          width: 1,
        },
        markPoint: {
          symbolSize: 100,
        },
      });
      var x = {
        type: "category",
        boundaryGap: false,
        data: labels,
      };
      var y = {
        splitLine: {
          lineStyle: {
            color: "#8495c2",
          },
        },
        type: "value",
      };
      var option = this.getOption(x, y, datas);
      (option.title = {
        show: true,
        text: this.$t("人脸信息趋势"),
        subtext: "Face Recognition Trends",
        padding: [0, 0, 0, 20],
        textStyle: {
          color: "#ffffff",
        },
      }),
        this.chatTop.setOption(option);

      window.addEventListener("resize", () => {
        this.chatTop.resize();
      });
    },

    getOption(xAxis, yAxis, datas) {
      var obj = {
        textStyle: {
          color: "#8495c2",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: datas,
        legend: {
          data: [],
          textStyle: {
            color: "#ffffff",
          },
          orient: "vertical",
          right: 50,
        },
      };
      return obj;
    },
    // 人物类型分布
    piePersonType(numArr, object) {
      this.numArr = numArr;
      var sum = numArr.reduce(function (x, y) {
        return x + y;
      });
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          left: 5,
          bottom: 5,
          orient: "vertical",
          data: [
            this.$t("普通客人"),
            this.$t("重要客人"),
            this.$t("陌生人"),
            this.$t("嫌疑人"),
          ],
          textStyle: {
            color: "#ffffff",
          },
        },
        series: [
          {
            radius: ["40%", "50%"],
            name: this.$t("总数量"),
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: [
                  `{title|${this.$t("总数量")}}`,
                  `{count|${sum}}`,
                ].join("\n"),
                show: true,
                position: "center",
                rich: {
                  title: {
                    color: "white",
                    fontSize: 18,
                  },
                  count: {
                    color: "#8495c2",
                    fontSize: 20,
                    padding: [0, 0, 10, 0],
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "30",
                  fontWeight: "normal",
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              { value: numArr[0], name: this.$t("普通客人") },
              { value: numArr[1], name: this.$t("重要客人") },
              { value: numArr[2], name: this.$t("陌生人") },
              { value: numArr[3], name: this.$t("嫌疑人") },
            ],
            color: [this.color1, this.color2, this.color3, this.color4],
          },
        ],
        grid: {
          top: 0,
          right: 100,
          bottom: 0,
          containLabel: false,
        },
      };
      option.title = {
        show: true,
        text: "人物类型分布",
        subtext: "Person Type",
        padding: [0, 0, 0, 20],
        textStyle: {
          color: "#ffffff",
        },
      };
      object.setOption(option, true);

      window.addEventListener("resize", () => {
        object.resize();
      });
    },
    // 年龄分布饼状图
    pieAgeChart(numArr, nameArr, object) {
      this.numArr = numArr;
      //var sum = numArr.reduce(function (x, y) { return x + y; });
      var sum = 0;
      var dataArr = [];
      var count = 0;
      for (let d in numArr) {
        dataArr.push({ value: numArr[d], name: nameArr[count++] });
        sum += numArr[d];
      }

      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          left: 5,
          bottom: 5,
          orient: "vertical",
          data: nameArr,
          textStyle: {
            color: "#ffffff",
          },
        },
        series: [
          {
            startAngle: 45,
            radius: ["40%", "50%"],
            name: this.$t("总数量"),
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: [
                  `{title|${this.$t("总数量")}}`,
                  `{count|${sum}}`,
                ].join("\n"),
                show: true,
                position: "center",
                rich: {
                  title: {
                    color: "white",
                    fontSize: 18,
                  },
                  count: {
                    color: "#8495c2",
                    fontSize: 20,
                    padding: [0, 0, 10, 0],
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 20,
                  fontWeight: "normal",
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: dataArr,
            color: [
              this.color1,
              this.color2,
              this.color3,
              this.color4,
              this.color5,
              this.color6,
              this.color7,
              this.color8,
              this.color9,
              this.color10,
            ],
          },
        ],
        grid: {
          top: 0,
          right: 100,
          bottom: 0,
          containLabel: false,
        },
      };
      option.title = {
        show: true,
        text: this.$t("年齡分布"),
        subtext: "Person Type",
        padding: [0, 0, 0, 20],
        textStyle: {
          color: "#ffffff",
        },
      };
      object.setOption(option, true);
      window.addEventListener("resize", () => {
        object.resize();
      });
    },
  },
};
</script>