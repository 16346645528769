<template>
    <div class="natplay-face" ref="netplay">
        <div v-if="installed" id="div-action" :style="actionStyle">
            <i class="fa fa-arrows-alt fa-2x" @click="clickFullScreen()"></i>
            <i class="fa fa-minus fa-2x" @mousedown="PTZZoomout()" @mouseup="PTZZoomStop()"></i>
            <i class="fa fa-plus fa-2x" @mousedown="PTZZoomIn()" @mouseup="PTZZoomStop()"></i>
            <i :class="['fa','fa-2x',!play ? 'fa-play':'fa-pause']" v-on:click='play?stopRealPlay():startPlay()'></i>
        </div>
        <div v-if="installed" id="div-action2" :style="actionStyle2">
            <i :class="['fa','fa-2x',!play ? 'fa-play':'fa-pause']" v-on:click='play?stopRealPlay():startPlay()'></i>
            <i class="fa fa-plus fa-2x" @mousedown="PTZZoomIn()" @mouseup="PTZZoomStop()"></i>
            <i class="fa fa-minus fa-2x" @mousedown="PTZZoomout()" @mouseup="PTZZoomStop()"></i>
            <i class="fa fa-arrows-alt fa-2x" @click="clickFullScreen()"></i>
        </div>
        <div v-if='installed' class="netplay-play" :style="style">
            <div class="netplay-obj" v-if='installed' ref="obj" id="divPlugin">
            </div>
        </div>
        <div v-if='installed' id="div-action" :style="actionStyle" style="display:none;">
            <b-row>
                <b-col md="5" id="div-action-bar">
                    <i :class="['fa','fa-2x',!play ? 'fa-play':'fa-pause']" v-on:click='play?stopRealPlay():startPlay()'></i>
                    <!-- <i :class="['fa', 'fa-2x',talk?'fa-microphone':'fa-microphone-slash']" v-on:click='talk?stopTalk():startTalk()'></i> -->
                    <i :class="['fa','fa-2x',sound?'fa-volume-up':'fa-volume-off']" v-on:click='sound?startMetu():stopMetu()'></i>
                    <span :class="{active:wins==1}" @click="changeWindow(1)">1</span>
                    <span :class="{active:wins==2}" @click="changeWindow(2)">4</span>
                    <span :class="{active:wins==3}" @click="changeWindow(3)">9</span>
                </b-col>
                <b-col md="7" id="div-action-arrow">
                    <i class="fa fa-arrow-left fa-2x" @mousedown="mouseDownPTZControl(3)" @mouseup="mouseUpPTZControl()"></i>
                    <i class="fa fa-arrow-up fa-2x" @mousedown="mouseDownPTZControl(1)" @mouseup="mouseUpPTZControl()"></i>
                    <i class="fa fa-arrow-down fa-2x" @mousedown="mouseDownPTZControl(2)" @mouseup="mouseUpPTZControl()"></i>
                    <i class="fa fa-arrow-right fa-2x" @mousedown="mouseDownPTZControl(4)" @mouseup="mouseUpPTZControl()"></i>
                    <i class="fa fa-plus fa-2x" @mousedown="PTZZoomIn()" @mouseup="PTZZoomStop()"></i>
                    <i class="fa fa-minus fa-2x" @mousedown="PTZZoomout()" @mouseup="PTZZoomStop()"></i>
                    <i class="fa fa-arrows-alt fa-2x" @click="clickFullScreen()"></i>
                </b-col>
            </b-row>
        </div>
        <div v-if='!installed' style="color:red">
           <a :href="baseUrl+'/share/WebComponentsKit.zip'">{{$t("IE")}}</a>
        </div>
    </div>
</template>
<style scoped>
    #div-action-bar > i, #div-action-arrow > i {
        margin-left: 10px;
        cursor: pointer;
    }

    #div-action-bar > span {
        margin-left: 10px;
        cursor: pointer;
        display: inline-block;
        height: 30px;
        width: 30px;
        border: 1px solid blue;
        font-size: 20px;
        text-align: center;
    }

        #div-action-bar > span.active {
            border: 1px solid white;
        }

    .netplay-obj {
        position: absolute;
        background-color: white;
        width: 100%;
        height: 100%;
    }

    .natplay-face{
        width: 100%;
        height: 100%;
        background-color: black;
        /* position:relative; */
        color: white;
        padding:0 20px 12px 20px;
    }

    .netplay-play {
        position: absolute;
    }

    #frame-title {
        position: absolute;
        z-index: 1;
        width: 120px;
        height: 25px;
        top: 10px;
        right: 10px;
        filter: 'progid:DXImageTransform.Microsoft.Alpha(style=0,opacity=0)';
    }

    #frame-action {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 50px;
        bottom: 0px;
        filter: 'progid:DXImageTransform.Microsoft.Alpha(style=0,opacity=0)';
    }

    #div-title {
        position: absolute;
        z-index: 2;
        height: 25px;
        top: 10px;
        right: 10px;
    }

    #div-action {
        position: absolute;
        z-index: 2;
        width: 30px;
        height: 150px;
        bottom: 0px;
        left:10px;
    }
        #div-action i {
            cursor:pointer;
        }

    #div-action2 {
        position: absolute;
        z-index: 2;
        width: 200px;
        height: 30px;
        bottom: 10px;
        left: 10px;
    }

        #div-action2 i {
            cursor: pointer;
        }
</style>

<script>
import { BaseUrl } from '@/assets/utils/config'
    export default {
        props: ["count"],
        data: function () {
            return {
                baseUrl:BaseUrl,
                installed: true,
                style: {
                    height: "504px",
                    width: "1215px"
                },
                actionStyle: { },
                actionStyle2: { },
                play: false,
                talk: false,
                sound: true,
                currentIndex: 0,
                devices: [],
                wins: 1,
                winarray: [],
            }
        },
        mounted: function () {
            var that = this; 
            that.resize(); 
            window.onresize = function windowResize() {
                // 通过捕获系统的onresize事件触发我们需要执行的事件
                that.resize(); 
            }
        },
        methods: {
            resize() {
                this.wins = this.count;
                var pw = this.$refs.netplay.clientWidth;
                var ph = this.$refs.netplay.clientHeight;
                var h = pw * 9 / 16 * 1.0;
                var w = ph * 16 / 9 * 1.0;
                var sw = pw;
                var sh = ph;
                var left = 0;
                var top = 0;
                if (h > ph) {
                    sw = w;
                    left = (pw - w) / 2;
                    //this.actionStyle.display = 'block';
                    this.actionStyle2.display = 'none';
                    if (left < 30) left = (pw - w - 30) / 2;
                    if (left < 0) {
                        sw -= left;
                        left = 0;
                    }
                    left += 30;
                }
                else {
                    sh = h;
                    top = (ph - h) / 2;
                    if (top < 30) top = (ph - h - 30) / 2;
                    if (top < 0) {
                        sh += top;
                        top = 0;
                    }
                    this.actionStyle.display = 'none';
                    //this.actionStyle2.display = 'block';
                }
                this.style.width = sw + 'px';
                this.style.height = sh + 'px';
                this.style.left = left + "px";
                if (top>=0) this.style.top = top + 65 + "px";
                try {
                    this.init(sw, sh);
                }
                catch (ex) {
                    this.installed = false;
                }
            },
            init(w, h) {
                //var g_iWndIndex = 0; //可以不用设置这个变量，有窗口参数的接口中，不用传值，开发包会默认使用当前选择窗口
                // 检查插件是否已经安装过
                var iRet = window.WebVideoCtrl.I_CheckPluginInstall();
                if (-1 == iRet) {
                    this.installed = false;
                    return;
                }

                // 初始化插件参数及插入插件
                window.WebVideoCtrl.I_InitPlugin(w, h, {
                    bWndFull: true,     //是否支持单窗口双击全屏，默认支持 true:支持 false:不支持
                    iPackageType: 2,    //2:PS 11:MP4
                    iWndowType: 2,
                    bNoPlugin: true,
                    cbSelWnd: (xmlDoc) => {
                        this.currentIndex = parseInt(xmlDoc.getElementsByTagName("SelectWnd")[0].childNodes[0].nodeValue);
                        var exsit = false;
                        for (var i = 0; i < this.winarray.length; i++) {
                            if (this.winarray[i].index == this.currentIndex) {
                                this.play = this.winarray[i].play;
                                this.sound = this.winarray[i].sound;
                                exsit = true;
                            }
                        }
                        if (!exsit) {
                            this.play = false;
                            this.sound = false;
                        }
                    },
                    cbRemoteConfig: function () {
                        // showCBInfo("关闭远程配置库！");
                    },
                    cbInitPluginComplete: function () {
                        window.WebVideoCtrl.I_InsertOBJECTPlugin("divPlugin");
                        // 检查插件是否最新
                        if (-1 == window.WebVideoCtrl.I_CheckPluginVersion()) {
                            alert("检测到新的插件版本，双击开发包目录里的WebComponentsKit.exe升级！");
                            return;
                        }
                    }
                });
                window.WebVideoCtrl.I_ChangeWndNum(this.count)
            },
            start(device, scuess, error) {
                //登录
                this.login(device, () => {
                    var exsit = false;
                    var d = {};
                    for (var i = 0; i < this.devices.length; i++) {
                        if (this.devices[i].Sid === device.Sid) {
                            exsit = true;
                            this.devices[i].index = this.currentIndex
                            d = this.devices[i];
                            break;
                        }
                    }
                    if (!exsit) {
                        //如果不存在，则获取端口及通道
                        var dev = {};
                        dev.Sid = device.Sid;
                        dev.device = device;
                        var oport = this.getPort(device.Ip, device.Port);
                        dev.DevicePort = oport.iDevicePort;
                        dev.RSTPort = oport.iRtspPort;
                        //获取通道
                        this.getMC(device.Ip, device.Port,
                            function (id, name) {
                                //获取模拟通道成功
                                dev.CID = id;
                                dev.CNAME = name;
                            }, function () {
                                this.getDC(device.Ip, device.Port,
                                    function (id, name) {
                                        //获取数字通道成功
                                        dev.CID = id;
                                        dev.CNAME = name;
                                    }, function () {
                                        throw "获取通道失败";
                                    })
                            });
                        d = dev;
                    }
                    //播放
                    if (!exsit) {
                        d.play = true;
                        this.devices.push(d);
                    }
                    this.startPlay(d, scuess, error);
                    scuess();
                }, (msg) => {
                    error(msg);
                })
            },
            login(device, suc, err) {
                var iRet = window.WebVideoCtrl.I_Login(device.Ip, 1, device.Port, device.Username, device.Password, {
                    success: function (xmlDoc) {
                        suc(xmlDoc);
                    },
                    error: function (status) {
                        err('登录失败' + status);
                    }
                });

                if (-1 == iRet) {
                    suc();
                }
            },
            getPort(ip, port) {
                var szDeviceIdentify = `${ip}_${port}`;
                var oPort = window.WebVideoCtrl.I_GetDevicePort(szDeviceIdentify);
                if (oPort != null) {
                    return oPort;
                } else {
                    throw "获取端口失败";
                }
            },
            getMC(ip, port, suc, err) {
                var szDeviceIdentify = `${ip}_${port}`;
                window.WebVideoCtrl.I_GetAnalogChannelInfo(szDeviceIdentify, {
                    async: false,
                    success: function (xmlDoc) {
                        var id = xmlDoc.getElementsByTagName("VideoInputChannel")[0].getElementsByTagName("id")[0].childNodes[0].nodeValue;
                        var name = xmlDoc.getElementsByTagName("VideoInputChannel")[0].getElementsByTagName("name")[0].childNodes[0].nodeValue;
                        suc(id, name);
                    },
                    error: function () {
                        err();
                    }
                })
            },
            getDC(ip, port, suc, err) {
                var szDeviceIdentify = `${ip}_${port}`;
                window.WebVideoCtrl.I_GetDigitalChannelInfo(szDeviceIdentify, {
                    async: false,
                    success: function (xmlDoc) {
                        var id = xmlDoc.getElementsByTagName("InputProxyChannelStatus")[0].getElementsByTagName("id")[0].childNodes[0].nodeValue;
                        var name = xmlDoc.getElementsByTagName("InputProxyChannelStatus")[0].getElementsByTagName("name")[0].childNodes[0].nodeValue;
                        suc(id, name);
                    },
                    error: function () {
                        err();
                    }
                })
            },
            startRealPlay: function (ip, port, rtspport, cid, suc, err) {
                var szDeviceIdentify = `${ip}_${port}`;
                var szInfo = "";
                window.WebVideoCtrl.I_StartRealPlay(szDeviceIdentify, {
                    iRtspPort: rtspport,
                    iStreamType: 1,
                    iChannelID: cid,
                    bZeroChannel: false,
                    success: () => {
                        szInfo = "开始预览成功！";
                        if (suc) {
                            suc(this.currentIndex);
                        }
                    },
                    error: (status) => {
                        if (403 === status) {
                            szInfo = "设备不支持Websocket取流！";
                        } else {
                            szInfo = "开始预览失败！" + status;
                        }
                        if (err) {
                            err(szInfo);
                        }
                        throw szInfo;
                    }
                });
            },
            startPlay(device, suc, err) {
                var ip, port, rtspport, cid;
                if (!device) {
                    for (var i = 0; i < this.winarray.length; i++) {
                        if (this.winarray[i].index == this.currentIndex) {
                            device = this.winarray[i].device;
                        }
                    }
                }
                if (!device) {
                    this.$throw("请点击左侧设备");
                    throw "请点击左侧设备"
                }
                ip = device.device.Ip;
                port = device.device.Port;
                rtspport = device.RSTPort;
                cid = device.CID;
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);
                if (oWndInfo == null) {
                    this.startRealPlay(ip, port, rtspport, cid, (index) => {
                        this.play = true;
                        var exsit = false;
                        for (var i = 0; i < this.winarray.length; i++) {
                            if (this.winarray[i].index == index) {
                                exsit = true;
                                this.winarray[i].device = device;
                                this.winarray[i].play = true;
                                break;
                            }
                        }
                        if (!exsit) {
                            var obj = {};
                            obj.play = true;
                            obj.index = index;
                            obj.device = device;
                            obj.sound = false;
                            this.winarray.push(obj);
                        }
                        if (suc) {
                            suc(index);
                        }
                    }, err);
                }
                else {
                    //先停止
                    window.WebVideoCtrl.I_Stop({
                        success: () => {
                            this.startRealPlay(ip, port, rtspport, cid, (index) => {
                                var exsit = false;
                                for (var i = 0; i < this.winarray.length; i++) {
                                    if (this.winarray[i] == index) {
                                        exsit = true;
                                        this.winarray[i].device = device;
                                        this.winarray[i].play = true;
                                        break;
                                    }
                                }
                                if (!exsit) {
                                    var obj = {};
                                    obj.play = true;
                                    obj.index = index;
                                    obj.device = device;
                                    this.winarray.push(obj);
                                }
                                if (suc) {
                                    suc(index);
                                }
                            }, err);
                        }
                    });
                }
            },
            stopRealPlay() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);
                var szInfo = "";

                if (oWndInfo != null) {
                    window.WebVideoCtrl.I_Stop({
                        success: () => {
                            szInfo = "停止预览成功！";
                            for (var i = 0; i < this.winarray.length; i++) {
                                if (this.winarray[i].index == this.currentIndex) {
                                    this.winarray[i].play = false;
                                    this.play = false;
                                }
                            }
                        },
                        error: function () {
                            szInfo = "停止预览失败！";
                        }
                    });
                }szInfo;
            },
            changeWindow(c) {
                this.wins = c;
                window.WebVideoCtrl.I_ChangeWndNum(c);
            },
            startMetu() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);
                if (oWndInfo != null) {
                    this.sound = false;
                    for (var i = 0; i < this.winarray.length; i++) {
                        if (this.winarray[i].index == this.currentIndex) {
                            this.winarray[i].sound = false;
                            this.sound = false;
                        }
                    }
                    window.WebVideoCtrl.I_CloseSound();
                }
            },
            stopMetu() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);
                if (oWndInfo != null) {
                    var allWndInfo = window.WebVideoCtrl.I_GetWindowStatus();
                    // 循环遍历所有窗口，如果有窗口打开了声音，先关闭
                    for (var i = 0, iLen = allWndInfo.length; i < iLen; i++) {
                        oWndInfo = allWndInfo[i];
                        if (oWndInfo.bSound) {
                            window.WebVideoCtrl.I_CloseSound(oWndInfo.iIndex);
                        }
                    }
                    window.WebVideoCtrl.I_OpenSound();
                    for (var j = 0; i < this.winarray.length; j++) {
                        if (this.winarray[j].index == this.currentIndex) {
                            this.winarray[j].sound = true;
                            this.sound = true;
                        }
                    }
                }
            },
            mouseDownPTZControl(iPTZIndex) {
                var g_bPTZAuto = false;
                var iPTZSpeed = 4;
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);
                if (oWndInfo != null) {
                    if (9 == iPTZIndex && g_bPTZAuto) {
                        iPTZSpeed = 0;// 自动开启后，速度置为0可以关闭自动
                    } else {
                        g_bPTZAuto = false;// 点击其他方向，自动肯定会被关闭
                    }

                    window.WebVideoCtrl.I_PTZControl(iPTZIndex, false, {
                        iPTZSpeed: iPTZSpeed,
                        success: function () {
                            if (9 == iPTZIndex && g_bPTZAuto) {
                                //showOPInfo(oWndInfo.szDeviceIdentify + " 停止云台成功！");
                            } else {
                                //showOPInfo(oWndInfo.szDeviceIdentify + " 开启云台成功！");
                            }
                            if (9 == iPTZIndex) {
                                g_bPTZAuto = !g_bPTZAuto;
                            }
                        },
                        error: function () {
                            //showOPInfo(oWndInfo.szDeviceIdentify + " 开启云台失败！", status, xmlDoc);
                        }
                    });
                }
            },
            mouseUpPTZControl() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);
                if (oWndInfo != null) {
                    window.WebVideoCtrl.I_PTZControl(1, true, {
                        success: function () {
                            //showOPInfo(oWndInfo.szDeviceIdentify + " 停止云台成功！");
                        },
                        error: function () {
                            //showOPInfo(oWndInfo.szDeviceIdentify + " 停止云台失败！", status, xmlDoc);
                        }
                    });
                }
            },
            PTZZoomIn() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);

                if (oWndInfo != null) {
                    window.WebVideoCtrl.I_PTZControl(10, false, {
                        iWndIndex: this.currentIndex,
                        success: function () {
                        },
                        error: function () {
                        }
                    });
                }
            },
            PTZZoomout() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);

                if (oWndInfo != null) {
                    window.WebVideoCtrl.I_PTZControl(11, false, {
                        iWndIndex: this.currentIndex,
                        success: function () {
                        },
                        error: function () {
                        }
                    });
                }
            },
            PTZZoomStop() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);

                if (oWndInfo != null) {
                    window.WebVideoCtrl.I_PTZControl(11, true, {
                        iWndIndex: this.currentIndex,
                        success: function () {
                        },
                        error: function () {
                        }
                    });
                }
            },
            clickFullScreen() {
                window.WebVideoCtrl.I_FullScreen(true);
            }
        }
    }
</script>
