<template>
    <div class="white">
        <actionbar v-bind:select='true' v-bind:modify='true' :del="true" :insert="true"
                v-on:select='select'
                v-on:modify='modify'
                v-on:delete='del'
                v-on:insert="insert">
        </actionbar>
         <tablepage ref="tableref" id="tableref"
         v-on:created='tableCreated'
         v-on:currentPage='currentPage'
         v-on:currentRow='currentRow'
         :cols='cols'
         :items='items'
         :total='total'
         :perpage='count'
        ></tablepage>

         <b-modal ref="modallg" size="lg" v-bind:title="title" hide-footer class="ksmodal"
         header-bg-variant="success"
         body-text-variant="dark">
            <form>
                <b-form-group v-bind:label="$t('名称')" :label-cols="2">
                 <b-form-input type="text" v-model="modalData.LinkageName" required></b-form-input>
                </b-form-group>
                <b-form-group v-bind:label="$t('设备')" :label-cols="2">
                    <b-form-radio-group :options="devs" v-model="modalData.DeviceSid" value-field="Sid" text-field="DeviceName">
                    </b-form-radio-group>
                </b-form-group>
            </form>
            <alarmAction :value="modalData" v-on:saved="saved" :filter="true"></alarmAction>
         </b-modal>
         
        <deletedata ref="del"
            v-on:deleted="deleted"
            :sid="sid"
            url="/api/device/DelSensorLinkage"
            :title="$t('删除')"
        ></deletedata>
    </div>
</template>
<script>
import actionbar from '@/components/actionbar'
import alarmAction from '@/components/alarmAction'
import {sensorFormat,Link}  from '@/assets/utils/datef'
import tablepage from '@/components/tablepage'
import deletedata from '@/components/deletedata'
export default {
    components:{actionbar,alarmAction,tablepage,deletedata},
    data:function(){return {
        loading:null,
        modalData:this.resetData(),
        title:"",
        devs:[],
        sensorDatas:[],
        cols:[
                {name:this.$t("名称"),value:"LinkageName"},
                {name:this.$t("设备名称"),value:"DeviceName"},
                {name:this.$t("传感器数据"),value:"sensor"},
                {name:this.$t("阈值"),value:"Compare"},
            ],
            items:[],
            total:0,
            count:20,
            link:Link().filter(function(x){return x.state==1}),
            sid:""
    }},
    mounted:function(){
        this.select()
    },
    methods:{
        resetData(){
            return {
                "Sid": "",
                "LinkageName": "名称",
                "IsEnabled": true,
                "DeviceSid": "",
                "DataKind": 31,
                "ThresholdValue": 0,
                "CompareSymbol": 1, 
                "IsContrary": false, 
                "EnableAlarm": true,
                 LinkageActions:[]
            }
        },
        select(){
            this.loading=this.$loading();
            new Promise((res,rej)=>{
                var obj={
                Index:0,
                Count:1000,
                DevCtgSid:"010"
                    }
                this.$ksajax.ksPostPromise("/api/device/GetSensorLinkages",obj,true).then((data)=>{
                var array=sensorFormat(false);
                data.Items.forEach(x => {
                    var sensor=array.find(y=>y.kind==x.DataKind);
                    if(sensor){
                        x.sensor=sensor.text;
                    }
                    switch(x.CompareSymbol){
                        case -1:
                        x.Compare="<"+x.ThresholdValue;
                        break;
                        case 0:
                        x.Compare="="+x.ThresholdValue;
                        break;
                        case 1:
                        x.Compare=">"+x.ThresholdValue;
                        break;
                    }
                    if(x.LinkageActions){
                                    x.LinkageActions.forEach((l)=>{
                                        l.link=this.link.find(o=>o.value==l.LinkageType);
                                        l.guid=this.guid();
                                    })
                                }
                });
                  this.items=data.Items;
                  res();
              }).catch(err=>{
                  rej(err)
              })
            }).then(()=>{
                return new Promise((res,rej)=>{
                     //获取设备列表
                        let req={
                            Index:0,
                            Count:100000
                        };
                        this.$ksajax.ksPostPromise("/api/device/GetWellCovers",req,true).then((data)=>{
                            this.devs=data.Items;
                            res();
                        })
                        .catch((err)=>{
                            rej(err);
                        })
                })
            }).then(()=>{
                this.loading.close()
            }).catch(err=>{
                this.$throw(err);
                this.loading.close();
            })
            
        },
        modify(){ 
            if(this.current==null)
            {
                throw this.$t("选择一条数据");
            }
                this.modalData=this.current;
                this.$refs.modallg.show();
            },
        del(){
             if(this.current==null)
            {
                throw this.$t("选择一条数据");
            }
            this.sid=this.current.Sid;
            this.$refs.del.show();
        },
        insert(){
            this.modalData=this.resetData();
            this.$refs.modallg.show();
        },
        devChange(ele){
            if(ele){
            let obj=ele;
            this.loading=this.$loading({fullScreen:true});
            this.$ksajax.ksPostPromise("/api/sensor/GetSensorData",obj,true).then((data)=>{
                this.sensorDatas=sensorFormat(data.SensorData);
                this.loading.close();
            }).catch((err)=>{
                this.$throw(err);
                this.loading.close();
            })
           }

        },
        dataChange(val){val
        },
        currentPage(){

        },
        currentRow(val){
            this.current=val;
        },
        tableCreated(){},
        
        guid(){
            var guid = "";
            for (var i = 1; i <= 32; i++){
            var n = Math.floor(Math.random()*16.0).toString(16);
            guid +=   n;
            if((i==8)||(i==12)||(i==16)||(i==20))
                guid += "-";
            }
            return guid; 
                },
                
        deleted(){
            this.select();
         },
         saved(){
            this.$refs.modallg.hide();
             this.select();
         }
    }
}
</script>
