<template>
  <div class="info_down" style="height:100%">
    <h2 style="margin-left:20px;">联系我们</h2>
    <hr style="color:#21b384; border-color:#21b384 !important;" />
    <div class="container" style="height:100%">
      <div class="row" style="padding:20px 0 10px 0;height:65%">
        <ksmap
          ref="map"
          width="100%"
          :showCenter="false"
          height="100%"
          v-bind:lat="projectData.Lat"
          v-bind:lng="projectData.Lng"
          v-bind:zoom="zoom"
        ></ksmap>
      </div>

      <div class="row">
        <div class="col">
          <div class="col_title">
            <i class="fa fa-phone"></i>技术支持
          </div>
          <div style="font-size:26px;font-weight:2px">{{projectData.Tel}}</div>
        </div>
        <div class="col">
          <div class="col_title">
            <i class="fa fa-envelope-o fa-2x"></i>联系邮箱
          </div>
          <div style="font-size:26px;font-weight:2px">
            <a :href="'mailto:'+projectData.Email">{{projectData.Email}}</a>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top:10px;">
        <div class="col">
          <div class="col_title">
            <i class="fa fa-fax fa-2x"></i>公司传真
          </div>
          <div style="font-size:26px;font-weight:2px">{{projectData.Fax}}</div>
        </div>
        <div class="col">
          <div class="col_title">
            <i class="fa fa-map-marker fa-2x"></i>公司地址
          </div>
          <div style="font-size:18px;font-weight:2px;">{{projectData.Address}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.info_down {
  padding-top: 70px;
}
.col_title {
  font-size: 20px;
}
.col_title .fa {
  color: #21b384;
  font-size: 27px;
}
</style>

<script>
import ksmap from "@/components/map";
export default {
  components: { ksmap },
  data: function () {
    return {
      zoom: 16,
      projectData: (this.projectData = this.$store.state.projectData || {
        Lat: 22.986676,
        Lng: 114.015872,
      }),
    };
  },
  watch: {
    "$store.state.projectData": function () {
      this.projectData = this.$store.state.projectData;
      //   this.$map.setCenter( this.projectData.Lat,this.projectData.Lat);
      this.$map.setAddress(this.projectData.Address);
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      //this.$refs.map.addMarker(114.016566, 22.986676);
      // var ele = document.getElementById("info_menu");
      // if (ele) {
      //   ele.style.backgroundColor = "#333333";
      // }
      // document.getElementById("info_footer").style.position = "fixed";
    });
  },
};
</script>
