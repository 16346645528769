<template>
  <div class="Stands white" v-loading="loading">
    <div class="funView">
      <div class="seleView">
        <el-select v-model="pageParkSid" @change="select">
          <el-option
            v-for="(item, index) in pageParkList"
            :key="index"
            :value="item.Sid"
            :label="item.Name"
          ></el-option>
        </el-select>
      </div>
      <actionbar
        class="btnView"
        v-bind:select="true"
        v-bind:modify="true"
        v-bind:insert="true"
        v-bind:del="true"
        v-on:select="select"
        v-on:insert="insert"
        v-on:modify="modify"
        v-on:delete="del"
      >
      </actionbar>
    </div>
    <parkPage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></parkPage>

    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form-group
        v-bind:label="$t('停车场名称')"
        :label-cols="2"
        v-if="modalData.Sid === ''"
      >
        <b-form-select :options="parkList" v-model="parkSid" required>
        </b-form-select>
      </b-form-group>

      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('开始分钟数')" :label-cols="2">
          <b-form-input
            type="number"
            v-model="modalData.Start"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('结束分钟数')" :label-cols="2">
          <b-form-input
            type="number"
            v-model="modalData.End"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('费用/时')" :label-cols="2">
          <b-form-input
            type="number"
            v-model="modalData.Charge"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group v-bind:label="$t('备注')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.Remark"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width: 100px" v-on:click="hiden">Cancel</b-button>
            <b-button type="submit" style="width: 100px" variant="primary"
              >OK</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>

    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/ParkCtl/DeleteParkRule"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<style>
.admin {
  background-color: white;
}
.funView {
  display: flex;
  align-items: center;
  background: rgb(209, 237, 219);
}
.seleView {
  width: 120px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.el-input__inner {
  height: 32px;
  font-size: 12px;
}
.el-input__suffix {
  display: flex;
  align-items: center;
}
.btnView {
  flex: 1;
  height: 44px;
}
.actionbar-content {
  border: none;
}
</style>

<script>
import actionbar from "@/components/actionbar";
import parkPage from "./components/parkPage.vue";
import deletedata from "@/components/deletedata";
export default {
  data: function () {
    return {
      modalData: {
        Sid: "",
        Start: 0,
        End: 0,
        Charge: 0.0,
        Remark: "",
      },
      title: "",
      cols: [
        { name: this.$t("停车场名称"), value: "ParkName" },
        { name: this.$t("开始分钟数"), value: "Start" },
        { name: this.$t("结束分钟数"), value: "End" },
        { name: this.$t("费用（时）"), value: "Charge" },
        { name: this.$t("备注"), value: "Remark" },
      ],
      items: [],
      parkList: [],
      index: 0,
      count: 5,
      total: 0,
      current: null,
      sid: "",
      parkSid: "",
      loading: false,
      pageParkSid: "",
      pageParkList: [],
    };
  },
  mounted: function () {
    this.getPageParkList();
  },
  components: {
    actionbar,
    parkPage,
    deletedata,
  },
  created: function () {},
  methods: {
    hiden() {
      this.$refs.modallg.hide();
    },

    currentRow(val) {
      this.current = val;
    },
    getPageParkList() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkList", "", true)
        .then((data) => {
          if (data.length != 0) {
            data.push({ Sid: "", Name: "全部" });
            this.pageParkList = data;
            this.loading = false;
            this.pageParkSid = this.pageParkList[0].Sid;
            this.select();
          } else {
            this.$throw(this.$t("数据为空"), this);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkRuleList", this.pageParkSid, true)
        .then((data) => {
          this.total = data.length;
          var array = [];
          var i = this.index;
          for (i; i < this.index + this.count; i++) {
            if (i >= data.length) {
              break;
            }
            array.push(data[i]);
          }
          this.items = array;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    getParkList() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkList", "", true)
        .then((data) => {
          data.forEach((element) => {
            element.value = element.Sid;
            element.text = element.Name;
          });
          this.parkList = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    insert() {
      this.title = this.$t("新增");
      this.modalData = this.resetData();
      this.parkSid = "";
      this.getParkList();
      this.$refs.modallg.show();
    },
    resetData() {
      return {
        Sid: "",
        Start: 0,
        End: 0,
        Charge: 0.0,
        Remark: "",
      };
    },

    onSubmit() {
      if (parseInt(this.modalData.Start) > parseInt(this.modalData.End)) {
        this.$throw(this.$t("开始分钟数不能大于结束分钟数"), this);
        return;
      }
      if (this.modalData.Sid) {
        this.loading = true;
        this.$ksajax
          .ksPostPromise(
            "/api/ParkCtl/UpdateParkRule",
            {
              Sid: this.modalData.Sid,
              Start: parseInt(this.modalData.Start),
              End: parseInt(this.modalData.End),
              Charge: parseFloat(this.modalData.Charge),
              Remark: this.modalData.Remark,
            },
            true
          )
          .then(() => {
            this.select();
            this.loading = false;
            this.hiden();
          })
          .catch((err) => {
            this.loading = false;
            this.$throw(err);
          });
      } else {
        if (this.parkSid === "") {
          this.$throw(this.$t("请先选择停车场"), this);
          return;
        }
        this.loading = true;
        this.$ksajax
          .ksPostPromise(
            "/api/ParkCtl/AddparkRule",
            {
              parkSid: this.parkSid,
              Start: parseInt(this.modalData.Start),
              End: parseInt(this.modalData.End),
              Charge: parseFloat(this.modalData.Charge),
              Remark: this.modalData.Remark,
            },
            true
          )
          .then(() => {
            this.select();
            this.loading = false;
            this.hiden();
          })
          .catch((err) => {
            this.loading = false;
            this.$throw(err);
          });
      }
    },
    modify() {
      if (!this.current) {
        this.$throw(this.$t("选择一条数据"), this);
      } else {
        this.modalData = {
          Sid: this.current.Sid,
          Start: this.current.Start,
          End: this.current.End,
          Charge: this.current.Charge,
          Remark: this.current.Remark,
        };
        if (this.current.LampStand) {
          this.modalData.LStandSid = this.current.LampStand.Sid;
        }
        this.title = this.$t("修改");
        this.$refs.modallg.show();
      }
    },
    del() {
      if (!this.current) {
        this.$throw(this.$t("选择一条数据"), this);
      } else {
        this.sid = this.current.Sid;
        this.$refs.del.show();
      }
    },
    tableCreated() {
      // this.select();
    },
    currentPage(val) {
      this.index = (val - 1) * this.count;
      this.select();
    },

    deleted() {
      this.index = (1 - 1) * this.count;
      this.select();
    },
  },
};
</script>

