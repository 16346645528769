<template>
  <div style="color: white; height: 100%" v-loading="loading" class="ksflex">
    <div style="width: 150px; height: 100%" v-loading="routerLoading">
      <div
        v-for="item in items"
        :key="item.Sid"
        :class="
          currentSid == item.Sid
            ? 'slider_router slider_router_check'
            : 'slider_router'
        "
        @click="change(item.Sid)"
      >
        {{ item.Name }}
      </div>
    </div>
    <div class="detailview" style="flex: 1">
      <div class="detailview_1">
        <box style="position: relative">
          <div style="width: 100%; height: 50%">
            <pie
              v-loading="deviceOnlineLoading"
              :radius="true"
              :centerlable="'总数'"
              :size="'small'"
              ref="deviceOnline"
            ></pie>
          </div>
          <hr />
          <div style="width: 100%; height: 48%">
            <bar v-loading="deviceOnlineLoading" ref="deviceCount"></bar>
          </div>
        </box>
      </div>
      <div class="detailview_1">
        <box id="canvas_box" style="position: relative">
          <pie
            v-loading="lawTypeLoading"
            :radius="true"
            :centerlable="'总数'"
            :size="'small'"
            ref="lawType"
          ></pie>
        </box>
      </div>
      <div class="detailview_1">
        <box>
          <div style="width: 100%; height: 50%">
            <pie
              v-loading="plateTypeLoading"
              :radius="true"
              :centerlable="'总数'"
              :size="'small'"
              ref="carType"
            ></pie>
          </div>
          <hr />
          <div style="width: 100%; height: 48%">
            <linebar v-loading="plateTypeLoading" ref="plateType"></linebar>
          </div>
        </box>
      </div>
      <div class="detailview_2">
        <box v-loading="carCountLoading">
          <linebar ref="carCount" :smooth="true"></linebar>
        </box>
      </div>
    </div>
  </div>
</template>
<style scoped>
.slider_router {
  padding-left: 10px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}
.slider_router_check {
  color: cadetblue;
}
</style>
<script>
import box from "@/components/box1.vue";
import pie from "@/components/chart/pie.vue";
import bar from "@/components/chart/bar.vue";
import linebar from "@/components/chart/line.vue";
import {
  getLawByCode,
  getCarTypeByCode,
  getPlateColorByCode,
} from "@/assets/utils/hikUtil";
export default {
  components: { box, pie, bar, linebar },
  data() {
    return {
      deviceOnlineLoading:false,routerLoading:false,lawTypeLoading:false,plateTypeLoading:false,carCountLoading:false,
      currentSid: "",
      items: [],
      chartData: {},
      loading: false,
      deviceCount: null,
      deviceOnline: null,
      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" },
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" },
      ]),
      color3: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#f7500f" },
        { offset: 0.5, color: "#eec54b" },
        { offset: 1, color: "#f7500f" },
      ]),
      color4: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#f7510f" },
        { offset: 0.5, color: "#ee154b" },
        { offset: 1, color: "#f75020" },
      ]),
      color5: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#6BC416" },
        { offset: 0.5, color: "#119857" },
        { offset: 1, color: "#066336" },
      ]),
      color6: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#4A89CC" },
        { offset: 0.5, color: "#175698" },
        { offset: 1, color: "#073463" },
      ]),
      color7: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#E766E5" },
        { offset: 0.5, color: "#EF34D7" },
        { offset: 1, color: "#9B118A" },
      ]),
      color8: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#F4F4F4" },
        { offset: 0.5, color: "#E9E9E9" },
        { offset: 1, color: "#BBBBBB" },
      ]),
      color9: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#BFBFCA" },
        { offset: 0.5, color: "#C0BBC6" },
        { offset: 1, color: "#B5BBC0" },
      ]),
      color10: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#323232" },
        { offset: 0.5, color: "#282828" },
        { offset: 1, color: "#211010" },
      ]),
      colorArr: [],
      lineColor1: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#FFB7440f",
          },
          {
            offset: 1,
            color: "#FFB744ff",
          },
        ],
      },
      lineColor2: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#3197FA0f",
          },
          {
            offset: 1,
            color: "#3197FAff",
          },
        ],
      },
      lineColor3: {
        type: "linear",
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: "#FF48480f",
          },
          {
            offset: 1,
            color: "#FF4848ff",
          },
        ],
      },
    };
  },
  mounted() {
    this.selectRouter();
    // this.select();
    this.colorArr = [
      this.color1,
      this.color2,
      this.color3,
      this.color4,
      this.color5,
      this.color6,
      this.color7,
      this.color8,
      this.color9,
      this.color10,
    ];
  },
  methods: {
    initDeviceOnline() {
      this.deviceOnlineLoading=true;
       this.$ksajax
        .ksPostPromise("/api/hikctl/GetEPoliceDeviceChart", this.currentSid, true).then((data1)=>{
          this.deviceOnlineLoading=false;
          this.chartData.Online=data1.Online;
          var data = [
        {
          name: "在线",
          value: this.chartData.Online.Online,
        },
        {
          name: "离线",
          value: this.chartData.Online.Offline,
        },
      ];
      var legend = {
        orient: "vertical",
        left: 10,
        data: ["在线", "离线"],
        bottom: 5,
        show: true,
      };
      var title = {
        show: true,
        text: "设备在线情况分析",
        left: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      var color = [this.color1, this.color2];
      this.$refs.deviceOnline.setAndInit(data, legend, color, null, title);
      this.chartData.PicCount=data1.PicCount;
      this.initDeviceCount();
        }).catch((err=>{
          this.$throw(err);
          this.deviceOnlineLoading=false;
        }))
      
    },
    initDeviceCount() {
      var title = {
        show: true,
        text: "设备抓图数量分析",
        left: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      var data = [
        {
          name: "抓图数量",
          data: [],
        },
      ];
      var xdata = [];
      this.chartData.PicCount.forEach((x) => {
        data[0].data.push(x.Count);
        xdata.push(x.DeviceName);
      });
      var legend = {
        orient: "vertical",
        left: 10,
        data: xdata,
        bottom: 5,
        show: true,
      };
      this.$refs.deviceCount.setAndInit(
        data,
        legend,
        [this.color3],
        null,
        xdata,
        title,
        xdata,
        "30%"
      );
    },
    initLawType() {
      this.lawTypeLoading=true;
       this.$ksajax
        .ksPostPromise("/api/hikctl/GetEpoliceLawChart", this.currentSid, true).then(data1=>{
          this.chartData.LawType=data1;
          var data = [];
      var names = [];
      this.chartData.LawType.forEach((x) => {
        var name = getLawByCode(x.IllegalType);
        data.push({
          name: name,
          value: x.Count,
        });
        names.push(name);
      });
      var legend = {
        orient: "vertical",
        left: 10,
        data: names,
        bottom: 5,
        show: true,
      };
      var title = {
        show: true,
        text: "违法情况分析",
        left: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      this.$refs.lawType.setAndInit(data, legend, this.colorArr, null, title);
          this.lawTypeLoading=false;
        }).catch(err=>{
          this.$throw(err);
          this.lawTypeLoading=false;
        })
      
    },
    initCarType() {
       this.plateTypeLoading=true;
       this.$ksajax
        .ksPostPromise("/api/hikctl/GetEpoliceCarChart", this.currentSid, true).then(data1=>{
          this.chartData.CarType=data1.CarType;
         this.chartData.PlateType=data1.PlateType;
         this.initPlateType();
       this.plateTypeLoading=false;
        var data = [];
      var names = [];
      this.chartData.CarType.forEach((x) => {
        var name = getCarTypeByCode(x.VehicleType);
        data.push({
          name: name,
          value: x.Count,
        });
        names.push(name);
      });
      var legend = {
        orient: "vertical",
        left: 10,
        data: names,
        bottom: 5,
        show: true,
        type: "scroll",
        pageTextStyle: {
          color: "#fff",
        },
      };
      var title = {
        show: true,
        text: "车辆类型分析",
        right: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      this.$refs.carType.setAndInit(data, legend, this.colorArr, null, title);
        }).catch(err=>{
          
       this.plateTypeLoading=false;
       this.$throw(err);
        })
     
    },
    initPlateType() {
      var title = {
        show: true,
        text: "车牌类型数量分析",
        right: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      var data = [
        {
          name: "数量",
          data: [],
        },
      ];
      var xdata = [];
      this.chartData.PlateType.forEach((x) => {
        data[0].data.push(x.Count);
        var name = getPlateColorByCode(x.PlateColor);
        xdata.push(name);
      });
      var legend = {
        orient: "vertical",
        left: 10,
        data: xdata,
        bottom: 5,
        show: true,
      };
      this.$refs.plateType.setAndInit(
        data,
        legend,
        [this.color2],
        null,
        xdata,
        title
      );
    },
    initcarCount() {
      this.carCountLoading=true;
       this.$ksajax
        .ksPostPromise("/api/hikctl/GetEpoliceItmeChart", this.currentSid, true).then(data1=>{
          this.carCountLoading=false;
          this.chartData.Datas=data1;
      var title = {
        show: true,
        text: "车流量分析",
        left: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      var data = [
        {
          name: "数量",
          data: [],
          color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#ffb669" },
            { offset: 0.5, color: "#ea413f" },
            { offset: 1, color: "#132f57" },
          ]),
        },
      ];
      var xdata = [];
      this.chartData.Datas.forEach((x) => {
        data[0].data.push(x.Count);
        xdata.push(x.Day);
      });
      var legend = {
        orient: "vertical",
        left: 10,
        data: xdata,
        bottom: 5,
        show: true,
      };
      this.$refs.carCount.setAndInit(
        data,
        legend,
        [this.color1],
        null,
        xdata,
        title
      );
        }).catch(err=>{
          this.$throw(err);
          this.carCountLoading=false;
        })
    },
    selectRouter() {
      this.routerLoading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetRouter", "", true)
        .then((data) => {
          this.items = data;
          this.routerLoading = false;
          if (this.items.length != 0) {
            this.change(this.items[0].Sid);
          }
        })
        .catch((err) => {
          this.routerLoading = false;
          this.$throw(err);
        });
    },
    change(val) {
      this.currentSid = val;
      this.select();
    },
    select() {
      
          this.initDeviceOnline();
          this.initLawType();
          this.initCarType();
          this.initcarCount();
      // this.loading = true;
      // this.$ksajax
      //   .ksPostPromise("/api/hikctl/GetViewData", this.currentSid, true)
      //   .then((data) => {
      //     this.chartData = data;
      //     this.loading = false;
      //     this.initDeviceOnline();
      //     this.initDeviceCount();
      //     this.initLawType();
      //     this.initCarType();
      //     this.initPlateType();
      //     this.initcarCount();
      //   })
      //   .catch((err) => {
      //     this.$throw(err);
      //     this.loading = false;
      //   });
    },
  },
};
</script>


