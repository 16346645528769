<template>
  <div class="Stands white" v-loading="loading">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      v-bind:insert="true"
      v-bind:del="true"
      v-on:select="select"
      v-on:insert="insert"
      v-on:modify="modify"
      v-on:delete="del"
    >
    </actionbar>
        <deletedata ref="del"
            v-on:deleted="select"
            :sid="sid"
            url="/api/oview/DelOview"
            :title="$t('删除')"
        ></deletedata>
    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="0"
      :perpage="-1"
    ></tablepage>
    <modify
      ref="modify"
      :modaldata="modalData"
      :title="'设置'"
      :labels="labels"
      v-on:submited="select()"
      url="/api/oview/setoview"
    ></modify>
  </div>
</template>
<script>
import actionbar from "../../../components/actionbar.vue";
import tablepage from "../../../components/tablepage.vue";
import deletedata from "../../../components/deletedata.vue";
import modify from "../../../components/modify.vue";
export default {
  components: { actionbar, tablepage, modify,deletedata },
  data() {
    return {
      sid:"",
      loading: false,
      items: [],
      current: null,
      cols: [
        { name: this.$t("设备名称"), value: "DeviceName" },
        { name: this.$t("全景通道"), value: "Chanel" },
        { name: this.$t("清晰度"), value: "Definition" },
        { name: this.$t("辅助摄像头"), value: "DeviceName", subvalue: "Video" },
        { name: this.$t("编辑"), value: "edit",link:true },
        { name: this.$t("全景视图"), value: "detail",link:true },
      ],
      modalData: {
        Sid: "",
        VideoSid: "",
        Chanel:1,
        Time:60,
        Definition:0
      },
      devices: [],
      labels: [],
    };
  },
  mounted() {
    this.getDevices();
    this.select();
    this.labels = this.getLabel()
  },
  methods: {
    getLabel(){
        return [
      {
        label: this.$t("全景摄像头"),
        type: "select",
        value: "Sid",
        require: true,
        "bind":this.devices
      },
      {
        label: this.$t("全景通道"),
        type: "number",
        value: "Chanel",
        require: true,
      },
      {
        label: this.$t("辅助摄像头"),
        type: "select",
        value: "VideoSid",
        require: true,
        "bind":this.devices
      },
      {
        label: this.$t("自动关闭时间"),
        type: "number",
        value: "Time",
        require: true,
      },
      {
        label: this.$t("视频清晰度"),
        type: "select",
        value: "Definition",
        require: true,
        "bind":[
          {text:"标清",value:0},
          {text:"高清",value:1},
        ]
      },
    ];
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/OView/GetOViews", "", true)
        .then((data) => {
          data.forEach(x=>{
              x.detail=`/Admin/Panor/Detail?sid=${x.Sid}`;
              x.edit=`/Admin/Panor/Detail?sid=${x.Sid}&endit=true`;
          })
          this.items = data;
          this.loading = false;
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
    insert() {
         this.labels = this.getLabel();
         this.$refs.modify.show();
    },
    modify() {
        if(!this.current){
            throw "请选择一个设备";
        }
         this.labels = this.getLabel();
         this.modalData.Sid=this.current.Sid;
         this.modalData.VideoSid=this.current.Video.Sid;
         this.modalData.Chanel=this.current.Chanel;
         this.modalData.Time=this.current.AutoCloseTime;
         this.$refs.modify.show();
    },
    del() {
      if(!this.current){
            throw "请选择一个设备";
        }
        this.sid=this.current.Sid;
        this.$refs.del.show();
    },
    getDevices() {
      if (this.devices.length == 0) {
        var ret = {
          Index: 0,
          Count: 100000,
        };
        this.loading = true;
        this.$ksajax
          .ksPostPromise("/api/device/GetDeviceVideos", ret, true)
          .then((data) => {
            this.loading = false;
            this.devices = [];
            data.Items.forEach((x) => {
              this.devices.push({
                value: x.Sid,
                text: x.DeviceName,
              });
            });
            console.log(this.devices);
          })
          .catch((err) => {
            this.loading = false;
            this.$throw(err);
          });
      }
    },
  },
};
</script>