<template>
  <div
    class="pointer"
    :style="style"
    :draggable="true"
    @dragend="dragend"
    @drag="drag"
    @mouseover="mouseover"
    @mouseleave="mouseleave"
  >
    <div>
      <div v-if="current.direct == 0">
        <div class="pointer_content" :style="styleV">
          <img
            :src="current.bottom"
            style="width: 100%; height: auto; max-height: 100%"
          />
          <div class="pointer_content_1">
            <span style="display: inline-block; width: 70%">
              {{ vdata.DeviceName.substring(0,6) }}
            </span>
            <i
              class="el-icon-delete"
              style="cursor: pointer"
              @click="$emit('delete', vdata.Sid)"
            ></i>
          </div>
        </div>

        <el-tooltip :placement="placement" manual :value="Alarm.Show&&Alarm.Content!=null&&Alarm.Content.length!=0">
          <div slot="content">{{ Alarm.Content }}</div>
          <img
            :src="Alarm.Show ? cir_err : current.pic"
            style="height: 15px"
            @click="$emit('hclick', vdata)"
          />
        </el-tooltip>
      </div>
      <div v-if="current.direct == 1">
        <el-tooltip :placement="placement" manual :value="Alarm.Show&&Alarm.Content!=null&&Alarm.Content.length!=0">
          <div slot="content">{{ Alarm.Content }}</div>
          <img
            :src="Alarm.Show ? cir_err : current.pic"
            style="height: 15px"
            @click="$emit('hclick', vdata)"
          />
        </el-tooltip>
        <div class="pointer_content" :style="styleV">
          <img
            :src="current.bottom"
            style="width: 100%; height: auto; max-height: 100%"
          />
          <div class="pointer_content_1">
            <span style="display: inline-block; width: 70%">
              {{ vdata.DeviceName.substring(0,6) }}</span
            >
            <i
              class="el-icon-delete"
              style="cursor: pointer"
              @click="$emit('delete', vdata.Sid)"
            ></i>
          </div>
        </div>
      </div>
      <div v-if="current.direct == 2">
        <div class="pointer_content_v" :style="styleV">
          <img
            :src="current.bottom"
            style="width: 25px; height: auto; max-height: 100%"
          />
          <div class="pointer_content_1">
            <div class="pointer_content_vt">
              {{ vdata.DeviceName.substring(0,6) }}
            </div>
            <i
              class="el-icon-delete"
              style="cursor: pointer"
              @click="$emit('delete', vdata.Sid)"
            ></i>
          </div>
        </div>
        <div style="text-align: center; height: 15px; line-height: 0">
          <el-tooltip :placement="placement" manual :value="Alarm.Show&&Alarm.Content!=null&&Alarm.Content.length!=0">
            <div slot="content">{{ Alarm.Content }}</div>
            <img
              :src="Alarm.Show ? cir_err : current.pic"
              style="height: 100%; width: auto"
              @click="$emit('hclick', vdata)"
            />
          </el-tooltip>
        </div>
      </div>
      <div v-if="current.direct == 3">
        <div style="text-align: center; height: 15px; line-height: 0">
          <el-tooltip :placement="placement" manual :value="Alarm.Show&&Alarm.Content!=null&&Alarm.Content.length!=0">
            <div slot="content">{{ Alarm.Content }}</div>
            <img
              :src="Alarm.Show ? cir_err : current.pic"
              style="height: 100%; width: auto"
              @click="$emit('hclick', vdata)"
            />
          </el-tooltip>
        </div>
        <div class="pointer_content_v" :style="styleV">
          <img
            :src="current.bottom"
            style="width: 25px; height: auto; max-height: 100%"
          />
          <div class="pointer_content_1">
            <div class="pointer_content_vb">
              {{ vdata.DeviceName.substring(0,6) }}
            </div>
            <i
              class="el-icon-delete"
              style="cursor: pointer"
              @click="$emit('delete', vdata.Sid)"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.pointer {
  position: absolute;
  z-index: 2;
}
.pointer_content_v {
  position: relative;
}
.pointer_content {
  display: inline-block;
  height: 25px;
  position: relative;
}
.pointer_content_1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
  font-weight: bold;
  color: #002d60;
}
.pointer_content_vb {
  writing-mode: vertical-lr;
  vertical-align: center;
  text-align: center;
  /* padding-top: 20px; */
  height: 70%;
}
.pointer_content_vt {
  writing-mode: vertical-lr;
  vertical-align: center;
  text-align: center;
  /* padding-top: 10px; */
  height: 70%;
}
</style>
<script>
export default {
  props: ["parent", "vdata"],
  data() {
    return {
      placement: "top",
      style: {
        top: "0px",
        left: "0px",
      },
      styleV: {
        visibility: "hidden",
      },
      Alarm: {
        Content: "",
        Show: false,
        Data: {},
      },
      cir: require("@/assets/img/hr/cir_normal.png"),
      ci_normal: require("@/assets/img/hr/cir_normal.png"),
      ci_cam_normal: require("@/assets/img/hr/cir_cam_normal.png"),
      cir_err: require("@/assets/img/hr/cir.gif"),
      cir_bottom_err: require("@/assets/img/hr/cir_bottom_err.png"),
      cir_bottom_right: require("@/assets/img/hr/cir_bottom_right.png"),
      cir_bottom_left: require("@/assets/img/hr/cir_bottom_left.png"),
      cir_bottom_top: require("@/assets/img/hr/cir_bottom_top.png"),
      cir_bottom_bottom: require("@/assets/img/hr/cir_bottom_bottom.png"),
      current: {
        pic: require("@/assets/img/hr/cir_normal.png"),
        bottom: require("@/assets/img/hr/cir_bottom_right.png"),
        direct: 1,
      },
    };
  },
  mounted() {
    this.placement =
      this.vdata.Direct == 0
        ? "right"
        : this.vdata.Direct == 1
        ? "left"
        : this.vdata.Direct == 2
        ? "bottom"
        : "top";
    this.init();
  },
  watch: {
    vdata: {
      handler() {
        this.Alarm = this.vdata.Alarm;
        this.cir=this.vdata.DCategorySid=='001'||this.vdata.DCategorySid=='015'?this.ci_cam_normal:this.ci_normal;
        this.current.pic=this.cir;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    mouseover() {
      this.styleV.visibility = "visible";
    },
    mouseleave() {
      this.styleV.visibility = "hidden";
    },
    init() {
      this.style.left = this.parent.width / this.vdata.WidthPersent + "px";
      this.style.top = this.parent.height / this.vdata.HeightPersent + "px";
      switch (this.vdata.Direct) {
        case 0:
          this.current.bottom = this.cir_bottom_left;
          break;
        case 1:
          this.current.bottom = this.cir_bottom_right;
          break;
        case 2:
          this.current.bottom = this.cir_bottom_top;
          break;
        case 3:
          this.current.bottom = this.cir_bottom_bottom;
          break;
      }
      this.current.direct = this.vdata.Direct;
    },
    dragend() {
      var form = {
        HeightPersent: this.vdata.HeightPersent,
        WidthPersent: this.vdata.WidthPersent,
        Sid: this.vdata.Sid,
      };
      this.$ksajax.ksPostPromise(
        "/api/oview/ModifyOviewDevicePostion",
        form,
        true
      );
    },
    drag(e) {
      if (e.pageY != 0) {
        this.vdata.WidthPersent = this.parent.width / e.pageX;
        var offset =
          e.pageY - (document.body.clientHeight - this.parent.height);
        this.vdata.HeightPersent = this.parent.height / offset;
        this.init();
      }
    },
  },
};
</script>