<template>
  <div class="ligthview" v-loading="loading">
    <div class="ligthview_l">
      <div>
        <box>
          <div class="ksflex" style="height:100%;padding:10px">
            <div style="flex:1">
              <div class="maxnumber">{{current.AcOnlineCount}}</div>
              <div class="content_text">{{$t("在线AC")}}</div>
            </div>
            <div style="flex:1;text-align:right">
              <img src="../../../../public/img/saveicon.png">
            </div>
          </div>
        </box>
      </div>
    </div>
    <div class="ligthview_l">
      <div>
        <box>
          <div class="ksflex" style="height:100%;padding:10px">
            <div style="flex:1">
              <div class="maxnumber">{{current.ApOnlineCount}}</div>
              <div class="content_text">{{$t("在线AP")}}</div>
            </div>
            <div style="flex:1;text-align:right">
              <img src="../../../../public/img/onlineicon.png">
            </div>
          </div>
        </box>
      </div>
    </div>
    <div class="ligthview_l">
      <div>
        <box>
          <div class="ksflex" style="height:100%;padding:10px">
            <div style="flex:1">
              <div class="maxnumber">{{current.TerminalOnlineCount}}</div>
              <div class="content_text">{{$t("在线终端")}}</div>
            </div>
            <div style="flex:1;text-align:right">
              <img src="../../../../public/img/lighticon.png">
            </div>
          </div>
        </box>
      </div>
    </div>
    <div class="ligthview_2">
      <div>
        <box>
          <div style="height:100%;width:100%" id="mychat"></div>
        </box>
      </div>
    </div>
    <div class="ligthview_2">
      <div>
        <box>
          <div style="height:100%; width:100%" id="mychat1"></div>
        </box>
      </div>
    </div>
    <div class="ligthview_0">
      <div>
          <boxtitle :data="userTitle"  v-on:titleClick="getUserInfo">
          <el-table stripe="" :data="userinfo" :empty-text="$t('暂无数据')">
            <el-table-column :label="$t('AP 型号')" prop="Model"></el-table-column>
            <el-table-column :label="$t('AP IP地址')" prop="ApIp"></el-table-column>
            <el-table-column :label="$t('AP Mac地址')" prop="ApMac"></el-table-column>
            <el-table-column :label="$t('通道')" prop="Signal"></el-table-column>
            <el-table-column :label="$t('Mac地址')" prop="Mac"></el-table-column>
            <el-table-column :label="$t('上线时间')" prop="OnlineTime"></el-table-column>
            <el-table-column :label="$t('SSID')" prop="SSID"></el-table-column>
            <el-table-column :label="$t('信号')" prop="Chanel"></el-table-column>
          </el-table>
        </boxtitle>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>
<script>
import box from "@/components/box1.vue";
import boxtitle from "@/components/box1.vue";
export default {
  components: { box,boxtitle},
  data: function() {
    return {
        userinfo:[],
      userTitle: {
        title: this.$t("用户信息"),
        style: "fa fa-refresh",
        action: this.$t("刷新")
      },
      loading: false,
      current: {},
      mychat: null,
      mychat1: null,
      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" }
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" }
      ]),
      color3: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#f7500f" },
        { offset: 0.5, color: "#eec54b" },
        { offset: 1, color: "#f7500f" }
      ])
    };
  },
  mounted: function() {
    this.mychat = window.echarts.init(document.getElementById("mychat"));
    this.mychat1 = window.echarts.init(document.getElementById("mychat1"));
    //this.select();
    this.doselect();
  },
  methods: {
    getApInfo(){
        return new Promise((res,rej)=>{
            this.$ksajax.ksPostPromise("/api/ac/getapinfo",null,true).then((data)=>{
                var array=[];
                if(data&&data.length!=0){
                    this.current.AcOnlineCount=data[0].count;
                    this.current.ApOnlineCount=0
                    this.current.TerminalOnlineCount=0
                    data.forEach(x=>{
                        if(x.Status==1){
                            this.current.ApOnlineCount+=1;
                        }
                        this.current.TerminalOnlineCount+=x.ClientCount;
                        var obj={
                            TotalCount:x.ClientCount,
                            DeviceName:x.DeviceName
                        }
                        array.push(obj);
                    });
                }else{
                    var obj={
                        TotalCount:0,
                        DeviceName:""
                    }
                    array.push(obj);
                }
                this.beginLeftTop(array);
                this.beginRightTop();
                res();
            }).catch(err=>{
                rej(err);
            })
        })
    },
    getUserInfo(){
          return new Promise((res,rej)=>{
            this.$ksajax.ksPostPromise("/api/ac/getuserinfo",null,true).then((data)=>{
                this.userinfo=data;
                res();
            }).catch(err=>{
                rej(err);
            })
        })
    },
    doselect(){
        this.loading=true;
        this.getApInfo().then(()=>{
           return this.getUserInfo();
        }).then(()=>{
            this.loading=false;
        }).catch(err=>{
            this.loading=false;
            this.$throw(err);
        })
    },
    getOption(xAxis, yAxis, datas) {
      var obj = {
        textStyle: {
          color: "#8495c2"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: datas,
        legend: {
          data: [],
          textStyle: {
            color: "#ffffff"
          },
          orient: "vertical",
          right: 50
        }
      };
      return obj;
    },
    beginLeftTop(data) {
      var sers = [];
      var names = [];
      var sub = 0;
      data.forEach(x => {
        sers.push({
          value: x.TotalCount,
          name: x.DeviceName
        });
        names.push(x.DeviceName);
        sub += x.TotalCount;
      });

      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          left: 5,
          bottom: 5,
          orient: "vertical",
          data: names,
          textStyle: {
            color: "#ffffff"
          }
        },
        series: [
          {
            radius: ["60%", "90%"],
            name: this.$t("总数量"),
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: [`{title|${this.$t('总数量')}}`, `{count|${sub}}`].join("\n"),
                show: true,
                position: "center",
                rich: {
                  title: {
                    color: "white",
                    fontSize: 20
                  },
                  count: {
                    color: "#8495c2",
                    fontSize: 14,
                    padding: [0, 0, 10, 0]
                  }
                }
              }
            },
            labelLine: {
              normal: {
                show: true
              }
            },
            data: sers,
            color: [this.color1, this.color2, this.color3]
          }
        ],
        grid: {
          top: 0,
          right: 100,
          bottom: 0,
          containLabel: false
        }
      };
      option.title = {
        show: true,
        text: this.$t("实时用户分析"),
        padding: [20, 0, 0, 20],
        textStyle: {
          color: "#ffffff"
        }
      };
      this.mychat.setOption(option, true);
      window.addEventListener("resize", () => {
        this.mychat.resize();
      });
    },
    beginRightTop() {
      var option = {
        textStyle: {
          color: "#8495c2"
        },
        color: [this.color3],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: [this.$t("在线AC"), this.$t("在线AP"), this.$t("在线终端")],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            splitLine: {
              lineStyle: {
                color: "#8495c2"
              },
              show: false
            },
            type: "value"
          }
        ],
        series: [
          {
            name: this.$t("数量"),
            type: "bar",
            barWidth: "60%",
            data: [
              this.current.AcOnlineCount,
              this.current.ApOnlineCount,
              this.current.TerminalOnlineCount
            ]
          }
        ]
      };
      this.mychat1.setOption(option, true);
      window.addEventListener("resize", () => {
        this.mychat1.resize();
      });
    },
    beginLeftBottomChart() {
      var data = this.current.CumulativeTerminals;
      var online = [];
      var labels = [];
      for (var i = 0; i < data.length; i++) {
        online.push(data[i].TerminalCount);
        labels.push(`${data[i].Year}.${data[i].Month}`);
      }
      var datas = [];
      datas.push({
        name: this.$t("数量"),
        type: "line",
        stack: this.$t("总数量"),
        label: {
          normal: {
            show: true,
            position: "top",
            color: "white"
          }
        },
        smooth: true,
        data: online,
        itemStyle: {
          color: "#1b85c9"
        },
        areaStyle: {
          normal: {
            color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#00749a" },
              { offset: 0.5, color: "#123e62" },
              { offset: 1, color: "#132f57" }
            ])
          }
        }
      });
      var x = {
        type: "category",
        boundaryGap: false,
        data: labels
      };
      var y = {
        splitLine: {
          lineStyle: {
            color: "#8495c2"
          }
        },
        type: "value"
      };
      var option = this.getOption(x, y, datas);
      (option.title = {
        show: true,
        text: this.$t("累计用户统计"),
        subtext: "Cumulative user statistics",
        padding: [5, 0, 0, 20],
        textStyle: {
          color: "#ffffff"
        }
      }),
        this.leftBottom.setOption(option);
      window.addEventListener("resize", () => {
        this.leftBottom.resize();
      });
    },
    beginRightBottom() {
      var datas1 = [];
      var datas2 = [];
      var names = [this.$t("上行流量"), this.$t("下行流量")];
      var xs = [];
      this.current.MonthNetFlows.forEach(x => {
        datas1.push(x.Upstream);
        xs.push(`${x.Year}.${x.Month}`);
      });
      this.current.MonthNetFlows.forEach(x => {
        datas2.push(x.Downstream);
      });
      var datas = [];
      datas.push({
        name: this.$t("上行流量"),
        type: "line",
        stack: "总量1",
        smooth: true,
        areaStyle: {},
        data: datas1
      });
      datas.push({
        name: this.$t("下行流量"),
        type: "line",
        smooth: true,
        stack: "总量2",
        areaStyle: {},
        data: datas2
      });
      var x = {
        type: "category",
        boundaryGap: false,
        data: xs
      };
      var y = {
        splitLine: {
          lineStyle: {
            color: "#8495c2"
          },
          show: false
        },
        type: "value"
      };
      var option = this.getOption(x, y, datas);
      option.title = {
        show: true,
        text: "横向对比分析",
        subtext: "Cross sectional analysis",
        padding: [5, 0, 0, 5],
        textStyle: {
          color: "#ffffff"
        }
      };
      (option.legend = {
        data: names,
        textStyle: {
          color: "#ffffff"
        }
      }),
        (option.color = [this.color2, this.color3]);
      this.rightBottom.setOption(option, true);
      window.addEventListener("resize", () => {
        this.rightBottom.resize();
      });
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/overview/GetWlanStatistics", "", true)
        .then(data => {
          // data.AcOnlineCount= data.AcOnlineCount+parseInt(Math.random()*10)
          // data.ApOnlineCount= data.ApOnlineCount+parseInt(Math.random()*10)
          // data.TerminalOnlineCount= data.TerminalOnlineCount+parseInt(Math.random()*10)
          // data.CumulativeTerminals.forEach(x=>{
          //     x.TerminalCount=x.TerminalCount+parseInt(Math.random()*10)
          // })
          // data.MonthNetFlows.forEach(x=>{
          //     x.Upstream=x.Upstream+parseInt(Math.random()*10)
          //     x.Downstream=x.Downstream+parseInt(Math.random()*10)
          // })
          this.loading = false;
          data.OfflineCount = data.TotalCount - data.OnlineCount;
          this.current = data;
          this.beginLeftTop(this.current.RealTimeOnlineTerminals);
          this.beginRightTop();
          this.beginLeftBottomChart();
          this.beginRightBottom();
        })
        .catch(err => {
          this.$throw(err);
          this.loading = false;
        });
    }
  }
};
</script>

