<template>
  <div class="detailview">
    <div class="detailview_1" style="width: 50%">
      <div>
        <box style="position: relative">
          <natpay   v-loading="loading"
            :data="playdata"
            ref="camininat"
            :count="playCount"
            :showbar="true"
            v-on:current="current"
          ></natpay>
        </box>
      </div>
    </div>
    <div class="detailview_1" style="width: 50%" >
      <div>
        <box id="canvas_box">
          <lawDetail  v-loading="loading"
            :value="currentLaw"
            class="tran_tab"
          ></lawDetail>
        </box>
      </div>
    </div>
    <div class="detailview_2" style="width: 50%" >
      <div>
        <box> <div style="height: 100%; width: 100%" id="chart" v-loading="chartLoading"></div></box>
      </div>
    </div>
    <div class="detailview_2" style="width: 50%">
      <div>
        <box   v-loading="loading">
          <div style="height: 100%; overflow: auto" ref="tableScroll" >
            <el-table
              :data="items"
              style="width: 100%"
              highlight-current-row
              @current-change="handleCurrentChange"
            >
              <el-table-column label="日期">
                <template slot-scope="scope">
                  {{ scope.row.Law.CreateTime }}
                </template>
              </el-table-column>
              <el-table-column label="车牌">
                <template slot-scope="scope">
                  {{ scope.row.Car.License }}
                </template>
              </el-table-column>
              <el-table-column label="违法行为">
                <template slot-scope="scope">
                  {{ scope.row.Law.IllegalType }}
                </template>
              </el-table-column>
              <el-table-column label="车辆品牌">
                <template slot-scope="scope">
                  {{ scope.row.Car.VehicleLogoRecog }}
                </template>
              </el-table-column>
              <el-table-column label="车辆类型">
                <template slot-scope="scope">
                  {{ scope.row.Law.VehicleType }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </box>
      </div>
    </div>
  </div>
</template>
<script>
import box from "@/components/box1.vue";
import netvideo from "@/components/netvideo";
import natpay from "@/components/natpay";
import lawDetail from "./components/lawDetail.vue";
import {
  getLawByCode,
  getCarByCode,
  getCarTypeByCode,
} from "@/assets/utils/hikUtil";
export default {
  components: { box, netvideo, natpay, lawDetail },
  data() {
    return {
      playDevs: [],
      playCount: 1,
      currentLaw: {},
      loading: false,
      sid: "",
      chartData: [],
      items: [
        {
          Law: {},
          Car: {},
        },
      ],
      playdata: {},
      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" },
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" },
      ]),
      color3: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#f7500f" },
        { offset: 0.5, color: "#eec54b" },
        { offset: 1, color: "#f7500f" },
      ]),
      color4: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#f7510f" },
        { offset: 0.5, color: "#ee154b" },
        { offset: 1, color: "#f75020" },
      ]),
      color5: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#6BC416" },
        { offset: 0.5, color: "#119857" },
        { offset: 1, color: "#066336" },
      ]),
      color6: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#4A89CC" },
        { offset: 0.5, color: "#175698" },
        { offset: 1, color: "#073463" },
      ]),
      color7: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#E766E5" },
        { offset: 0.5, color: "#EF34D7" },
        { offset: 1, color: "#9B118A" },
      ]),
      color8: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#F4F4F4" },
        { offset: 0.5, color: "#E9E9E9" },
        { offset: 1, color: "#BBBBBB" },
      ]),
      color9: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#BFBFCA" },
        { offset: 0.5, color: "#C0BBC6" },
        { offset: 1, color: "#B5BBC0" },
      ]),
      color10: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#323232" },
        { offset: 0.5, color: "#282828" },
        { offset: 1, color: "#211010" },
      ]),
      colorArr: [
        this.color1,
        this.color2,
        this.color3,
        this.color4,
        this.color5,
        this.color6,
        this.color7,
        this.color8,
        this.color9,
        this.color10,
      ],
      chart: null,
      chartLoading:false
    };
  },
  mounted() {
    this.sid = this.$route.query.sid;
    this.chart = window.echarts.init(document.getElementById("chart"));
    this.select();
    this.initChart();
  },
  watch: {
    "$store.state.carPoliceHubChange": function () {
      try {
        var data = this.$store.state.carPoliceHub;
        // if (data.CtgSid == "009") {
        //   if (this.mainData.DeviceSid == data.DeviceSid) {
        //     this.mainData.Wl = data.Data.Wl;
        //   }
        // }
        if (data.RouterSid.indexOf(this.sid) != -1) {
          this.toUpperCase(data.Law);
          this.toUpperCase(data.Law.Car);
          this.toUpperCase(data.Law.Law);
          if (data.Law.Law) {
            data.Law.Law.IllegalType = getLawByCode(data.Law.Law.IllegalType);
            data.Law.Law.VehicleType = getCarTypeByCode(
              data.Law.Law.VehicleType
            );
          }
          if (data.Law.Car) {
            data.Law.Car.VehicleLogoRecog = getCarByCode(
              data.Law.Car.VehicleLogoRecog
            );
          }
          this.items.push(data.Law);
          this.currentLaw = data.Law;
          this.scrollTable();
          this.freshChart();
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  methods: {
    current(val){
      val=val||0;
      if(val>=this.playDevs.length){
        val=0;
      }
      var dev=this.playDevs[val];
      this.$refs.camininat.start(
         dev,
          () => {
          },
          () => {
          },
          val
        );
    },
    freshChart() {
      var length = this.chartData.length;
      if (this.chartData[length - 1]) {
        this.chartData[length - 1].count += 1;
      }
      this.chart.clear();
      this.beginChatTop(this.chartData);
    },
    initChart(){
      this.chartLoading=true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetRouterDetailChart", this.sid, true).then(data=>{
          this.chartData=data;
          console.log(this.chartData,"chartdata");
          this.beginChatTop(this.chartData);
      this.chartLoading=false;
        }).catch(err=>{
          this.$throw(err);
      this.chartLoading=false;
        })
    },
    scrollTable() {
      var ele = this.$refs.tableScroll;
      ele.scrollTop = ele.scrollHeight;
    },
    toUpperCase(jsonObj) {
      if (typeof jsonObj == "object") {
        for (var key in jsonObj) {
          jsonObj[key.substring(0, 1).toUpperCase() + key.substring(1)] =
            jsonObj[key];
          delete jsonObj[key];
        }
        return jsonObj;
      }
      return jsonObj;
    },
    handleCurrentChange(val) {
      this.currentLaw = val;
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetRouterDetail", this.sid, true)
        .then((data) => {
          console.log(data);
          data.Items.forEach((element) => {
            if (element.Law) {
              element.Law.IllegalType = getLawByCode(element.Law.IllegalType);
              element.Law.VehicleType = getCarTypeByCode(
                element.Law.VehicleType
              );
            }
            if (element.Car) {
              element.Car.VehicleLogoRecog = getCarByCode(
                element.Car.VehicleLogoRecog
              );
            }
          });
          this.items = data.Items;
          if (this.items.length > 0) {
            this.currentLaw = this.items[0];
          }
          this.loading = false;
          // this.chartData = data.Chart;
          // this.beginChatTop(data.Chart);
          this.playCount = data.Devices.length;
          var devs = [];
          data.Devices.forEach((x) => {
            var playData = {
              Ip: x.Ip,
              Port: 80,
              Username: x.UserName,
              Password: x.PassWord,
              Sid: x.Sid,
            };
            devs.push(playData);
          });
          this.playDevs=devs;
          this.play(0);
          this.$nextTick(() => {
            this.scrollTable();
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    play(index) {
      if (this.playDevs.length <= index) {
        return;
      } else {
        var dev = this.playDevs[index];
        this.$refs.camininat.start(
         dev,
          () => {
            var s1=index+1;
            this.play(s1);
          },
          () => {
            var s2=index+1;
            this.play(s2);
          },
          index
        );
      }
    },
    getOption(xAxis, yAxis, datas) {
      var obj = {
        textStyle: {
          color: "#8495c2",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: datas,
        legend: {
          data: [],
          textStyle: {
            color: "#ffffff",
          },
          orient: "vertical",
          right: 50,
        },
      };
      return obj;
    },
    beginChatTop(arrTrend) {
      var online = [];
      var labels = [];
      for (var i = 0; i < arrTrend.length; i++) {
        online.push(arrTrend[i].count);
        labels.push(arrTrend[i].date);
      }

      var datas = [];
      datas.push({
        name: this.$t("数量"),
        type: "line",
        stack: this.$t("总数量"),
        label: {
          normal: {
            show: true,
            position: "top",
            color: "white",
            fontSize: 20,
          },
        },
        symbolSize: 15,
        smooth: true,
        data: online,
        itemStyle: {
          //color: this.color2,
          color: "#ffb669",
          borderWidth: 1,
        },
        areaStyle: {
          normal: {
            color: new window.echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              //[
              //    { offset: 0, color: '#00749a' },
              //    { offset: 0.5, color: '#123e62' },
              //    { offset: 1, color: '#132f57' }
              //],
              [
                { offset: 0, color: "#ffb669" },
                { offset: 0.5, color: "#ea413f" },
                { offset: 1, color: "#132f57" },
              ]
            ),
          },
        },
        lineStyle: {
          width: 1,
        },
        markPoint: {
          symbolSize: 100,
        },
      });
      var x = {
        type: "category",
        boundaryGap: false,
        data: labels,
      };
      var y = {
        splitLine: {
          lineStyle: {
            color: "#8495c2",
          },
        },
        type: "value",
      };
      var option = this.getOption(x, y, datas);
      (option.title = {
        show: true,
        text: this.$t("车流量统计"),
        padding: [20, 0, 0, 20],
        textStyle: {
          color: "#ffffff",
        },
      }),
        this.chart.setOption(option);

      window.addEventListener("resize", () => {
        this.chart.resize();
      });
    },
  },
};
</script>