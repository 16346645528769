<template>
  <div style="color: white; height: 100%">
    <div id="todetail">
      <router-link :to="`/admin/stand/commondevice?sid=${$route.query.sid}`">{{
        this.$t("详情")
      }}</router-link>
    </div>
    <div class="detailview">
      <div class="detailview_1">
        <div>
          <box style="position: relative">
            <div
              id="centerTop"
              style="position: absolute; top: 20px; left: 20px"
            >
              <!-- <div style="font-size: 1.5rem">{{ sensorData.DeviceName }}</div> -->
            </div>
            <div id="leftTop" style="width: 100%; height: 100%"></div>
          </box>
        </div>
      </div>
      <div class="detailview_1">
        <div>
          <box id="canvas_box" style="position: relative">
            
              <div
                style="position: absolute; right: 0; height: 30%; width: 20%"
              >
                <alarm ref="alarm"></alarm>
              </div>
            <div :style="canvasStyle">
              <canvas
                ref="canvas_lamp"
                style="position: absolute; left: 0; bottom: 0"
              ></canvas>
              <canvas
                ref="canvas_wall"
                style="position: absolute; left: 0; bottom: 0"
              ></canvas>
            </div>
          </box>
        </div>
      </div>
      <div class="detailview_1">
        <div>
          <box v-loading="loadingCanvas">
            <div class="ksflexcol" style="height: 100%">
              <div style="padding: 20px 0 0 20px; width: 100%">
                <div style="font-size: 1.5rem">{{ this.$t("灯杆参数") }}</div>
              </div>
              <div
                class="ksflexcol"
                style="flex: 1; width: 100%; padding-top: 20px; font-size: 1rem"
              >
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ this.$t("名称") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ standInfo.StandName }}
                  </div>
                </div>
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ this.$t("角度") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ sensorData.Angle+"°" }}
                  </div>
                </div>
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ this.$t("电压") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ sensorData.SensorVoltage +"V" }}
                  </div>
                </div>
                <div
                  class="row ksrow"
                  style="flex: 1; width: 100%"
                  v-show="!sensorData.TcpSensor"
                >
                  <div class="col" style="text-align: center">
                    {{ this.$t("信号强度") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ sensorData.Lsnr }}
                  </div>
                </div>
                <div
                  class="row ksrow"
                  style="flex: 1; width: 100%"
                  v-if="sensorData.TcpSensor"
                >
                  <div class="col" style="text-align: center">
                    {{ this.$t("漏电电流") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ sensorData.TcpSensor.AC_Leakel +"mA"}}
                  </div>
                </div>
                
                <div
                  class="row ksrow"
                  style="flex: 1; width: 100%"
                  v-if="sensorData.TcpSensor"
                >
                  <div class="col" style="text-align: center">
                    {{ this.$t("三维角度") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{
                      `X:${sensorData.TcpSensor.AngelX}  Y:${sensorData.TcpSensor.AngelY}  Z:${sensorData.TcpSensor.AngelZ}`
                    }}
                  </div>
                </div>
                <div
                  class="row ksrow"
                  style="flex: 1; width: 100%"
                  v-if="sensorData.TcpSensor"
                >
                  <div class="col" style="text-align: center">
                    {{ this.$t("校验角度") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{
                      `X:${sensorData.TcpSensor.AngelJX}  Y:${sensorData.TcpSensor.AngelJY}  Z:${sensorData.TcpSensor.AngelJZ}`
                    }}
                  </div>
                </div>
                
                <div
                  class="row ksrow"
                  style="flex: 1; width: 100%"
                  v-if="sensorData.TcpSensor"
                >
                  <div class="col" style="text-align: center">
                    {{ this.$t("温度") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{
                      sensorData.TcpSensor.In_t02+"℃"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </box>
        </div>
      </div>
      <div class="detailview_2">
        <div>
          <box v-loading="leftBottomLoading">
            <div id="leftBottom" style="width: 100%; height: 100%"></div>
          </box>
        </div>
      </div>
    </div>
    <img :src="lampData.pic" id="lampPic" @load="drawLamp(false)" hidden />
    <img :src="wallData.pic" id="wallPic" @load="drawWall(false)" hidden />
  </div>
</template>
<script>
import box from "@/components/box1.vue";
import alarm from "@/components/alarm";
export default {
  components: { box, alarm },
  data() {
    return {
      alarmTrgger: false,
      loadingCanvas: false,
      leftBottomLoading: false,
      /**画布父元素的css */
      canvasStyle: {
        position: "absolute",
        left: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      },
      animate: {
        angle: 0,
        currentAngel: 0,
        thread: null,
        speed: 20,
      },
      /**画布初始化参数 */
      initData: {
        /**原图尺寸 */
        size: {
          width: 340,
          height: 424,
        },
        /**渲染尺寸 */
        rendSize: {
          width: 0,
          height: 0,
        },
      },
      sensorData: {
        TcpSensor: {},
      },
      /**底部墙渲染数据 */
      wallData: {
        //画片
        pic: require("@/assets/img/lamppost-base.png"),
        //canvas上下文
        ctx: null,
        //原图尺寸
        size: {
          width: 370,
          height: 34,
        },
        //渲染尺寸
        rendSize: {
          width: 0,
          height: 0,
        },
        //起点坐标
        startPos: {
          x: 0,
          y: 0,
        },
      },
      lampData: {
        //画片
        pic: require("@/assets/img/lamppost.png"),
        //canvas上下文
        ctx: null,
        //原图尺寸
        size: {
          width: 181,
          height: 387,
        },
        //渲染尺寸
        rendSize: {
          width: 0,
          height: 0,
        },
        //起点坐标
        startPos: {
          x: 0,
          y: 0,
        },
        offsetX: 80,
        RBX: 164,
        offsetRBX: 164,
      },
      leftTop: {},
      leftBottom: {},
      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" },
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" },
      ]),
      standInfo:{}
    };
  },
  watch: {
    "animate.angle": function () {
      this.startBoltAnimate();
    },
    "$store.state.dataHubChange": function () {
      var data = this.$store.state.dataHub;
      if (data.Serial == this.sensorData.Serial) {
        if (data.Data.Ag || data.Data.Ag == 0) {
          this.animate.angle = this.sensorData.Angle =Math.round( data.Data.Ag);
        }
        if (this.sensorData.TcpSensor) {
          this.sensorData.TcpSensor.AC_Leakel = data.Data.Leakel;
          this.sensorData.TcpSensor.AngelX = data.Data.Tx;
          this.sensorData.TcpSensor.AngelY = data.Data.Ty;
          this.sensorData.TcpSensor.AngelZ = data.Data.Tz;
        }
      }
    },
    "$store.state.alarmHubChange": function () {
      var data = this.$store.state.alarmHub;
      if (data.Serial == this.sensorData.Serial) {
        if (data.Data.Leakel || data.Data.Leakel == 0) {
          this.sensorData.TcpSensor.AC_Leakel = data.Data.Leakel;
          this.alarmTrgger =data.Type==6? true:false;
        }
      }
    },
     alarmTrgger: function() {
      if (this.alarmTrgger) {
        this.$refs.alarm.start(true);
      } else {
        this.$refs.alarm.start(false);
      }
    }
  },
  mounted() {
    this.leftTop = window.echarts.init(document.getElementById("leftTop"));
    this.leftBottom = window.echarts.init(
      document.getElementById("leftBottom")
    );
    this.beginLeftTopChart({ AlarmCount: 10, EventCount: 20 });
    //调整画布位置及大小
    //父元素大小
    this.getSensorData();
    this.leftBottomChat();
    var parentWidth = document.getElementById("canvas_box").clientWidth;
    var parentHeight = document.getElementById("canvas_box").clientHeight;
    //画布宽度最大值为父元素宽度
    this.initData.rendSize.width =
      this.initData.size.width > parentWidth
        ? parentWidth
        : this.initData.size.width;
    //根据比率设置画布高度
    var rate = this.initData.size.width / this.initData.size.height;
    this.initData.rendSize.height = this.initData.size.height / rate;
    //画面高度最大值为父元素高度
    if (this.initData.rendSize.height > parentHeight) {
      this.initData.rendSize.height = parentHeight;
      //再次调整宽度
      this.initData.rendSize.width = this.initData.rendSize.height * rate;
    }
    //设置画布大小
    this.canvasStyle.width = `${this.initData.rendSize.width}px`;
    this.canvasStyle.height = `${this.initData.rendSize.height}`;
    if (this.initData.rendSize.width < parentWidth) {
      //画布居中
      var diff = parentWidth - this.initData.rendSize.width;
      this.canvasStyle.left = `${diff / 2}px`;
    }
    //设置最底部墙的渲染数据
    var rateWall = this.wallData.size.width / this.wallData.size.height;
    this.wallData.rendSize.width = this.initData.rendSize.width;
    this.wallData.rendSize.height = this.initData.rendSize.width / rateWall;
    this.wallData.startPos.x = 0;
    this.wallData.startPos.y =
      this.initData.rendSize.height - this.wallData.rendSize.height;
    this.$refs.canvas_wall.width = this.initData.rendSize.width;
    this.$refs.canvas_wall.height = this.initData.rendSize.height;
    this.wallData.ctx = this.$refs.canvas_wall.getContext("2d");
    //设置灯杆的渲染数据
    var rateLamp = this.lampData.size.width / this.lampData.size.height;
    var rateLampWidth = this.lampData.size.width / this.initData.size.width;
    this.lampData.rendSize.width = this.initData.rendSize.width * rateLampWidth;
    this.lampData.rendSize.height = this.lampData.rendSize.width / rateLamp;
    var rateLampOffset =
      this.initData.size.width / this.initData.rendSize.width;
    this.lampData.startPos.x = this.lampData.offsetX / rateLampOffset;
    this.lampData.startPos.y =
      this.initData.rendSize.height -
      (this.wallData.rendSize.height + this.lampData.rendSize.height);
    this.lampData.offsetRBX = this.lampData.RBX / rateLampOffset;
    this.$refs.canvas_lamp.width = this.initData.rendSize.width;
    this.$refs.canvas_lamp.height = this.initData.rendSize.height;
    this.lampData.ctx = this.$refs.canvas_lamp.getContext("2d");
  },
  methods: {
    beginLeftTopChart(data) {
      var font = 12;
      if (window.innerWidth > 1366) {
        font = 20;
      }
      var on = data.AlarmCount;
      var off = data.EventCount;
      var sub = on + off;
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          left: 20,
          bottom: 20,
          orient: "vertical",
          data: [this.$t("报警次数"), this.$t("开阈次数")],
          textStyle: {
            color: "#ffffff",
            fontWeight: "normal",
          },
        },
        series: [
          {
            name: this.$t("报警率"),
            type: "pie",
            radius: ["30%", "60%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: [
                  `{title|${this.$t("总数量")}}`,
                  `{count|${sub}}`,
                ].join("\n"),
                show: true,
                position: "center",
                rich: {
                  title: {
                    color: "white",
                    fontSize: font,
                  },
                  count: {
                    color: "#8495c2",
                    fontSize: font,
                    padding: [0, 0, 10, 0],
                  },
                },
              },
            },
            labelLine: {
              normal: {
                show: true,
              },
            },
            data: [
              { value: on, name: this.$t("报警次数") },
              { value: off, name: this.$t("开阈次数") },
            ],
            color: [this.color1, this.color2],
          },
        ],
        grid: {
          top: 0,
          right: 100,
          bottom: 0,
          containLabel: false,
        },
      };
      this.leftTop.setOption(option, true);
      window.addEventListener("resize", () => {
        this.leftTop.resize();
      });
    },
    drawWall() {
      this.wallData.ctx.drawImage(
        document.getElementById("wallPic"),
        this.wallData.startPos.x,
        this.wallData.startPos.y,
        this.wallData.rendSize.width,
        this.wallData.rendSize.height
      );
    },
    drawLamp(angle) {
      angle = angle || this.animate.angle;
      this.lampData.ctx.clearRect(
        0,
        0,
        this.initData.rendSize.width,
        this.initData.rendSize.height
      );
      this.lampData.ctx.save();
      //以栓柱右下角为旋转基点
      this.lampData.ctx.translate(
        this.lampData.offsetRBX,
        this.initData.rendSize.height - this.wallData.rendSize.height
      );
      this.lampData.ctx.rotate(((360 - angle) * Math.PI) / 180);
      //以栓柱右下角为中心点，原来的起点坐标灰新坐标系中的位置
      var cx = -(this.lampData.offsetRBX - this.lampData.startPos.x);
      var cy = -this.lampData.rendSize.height;
      this.lampData.ctx.drawImage(
        document.getElementById("lampPic"),
        cx,
        cy,
        this.lampData.rendSize.width,
        this.lampData.rendSize.height
      );
      this.lampData.ctx.restore();
    },
    getSensorData() {
      var sid = this.$route.query.sid;
      this.$ksajax
        .ksPostPromise("/api/asset/GetLampStandInfo", sid, true)
        .then((data) => {
          this.standInfo=data;
          if (data.TcpSensor) {
            this.hasSensor=true;
            data.SensorVoltage = data.TcpSensor.AC_V;
            data.Lsnr = 100;
            data.Angle =Math.round(Math.max(
              Math.abs(data.TcpSensor.AngelJX - data.TcpSensor.AngelX),
              Math.abs(data.TcpSensor.AngelJY - data.TcpSensor.AngelY),
              Math.abs(data.TcpSensor.AngelJZ - data.TcpSensor.AngelZ)
            ));
          this.sensorData = data;
          }else{
              this.hasSensor=false;
            this.sensorData={
              Angle:data.Angle,
              AlarmAngle:data.AlarmAngle,
              SensorVersion:data.SensorVersion,
              SensorVoltage:data.SensorVoltage,
              AlarmStatus:data.AlarmStatus,
              AlarmAngl:data.AlarmAngle,
              RecoverAngle:data.RecoverAngle,
              Lsnr:data.Lsnr,
              Rssi:data.Rssi
            }
          }
          this.animate.angle =
            this.sensorData.Angle > 90 ? 90 : this.sensorData.Angle;
        });
    },
    startBoltAnimate() {
      this.animate.thread = window.setInterval(() => {
        if (this.animate.angle == this.animate.currentAngel||this.animate.angle==0) {
          if (this.animate.thread) {
            window.clearInterval(this.animate.thread);
            this.animate.thread = null;
          }
        }
        if (this.animate.angle > this.animate.currentAngel) {
          this.animate.currentAngel += 1;
        } else {
          this.animate.currentAngel -= 1;
        }
        this.drawLamp(this.animate.currentAngel);
      }, this.animate.speed);
    },
    leftBottomChat() {
      this.leftBottomLoading = true;
      this.$ksajax
        .ksPostPromise(
          "/api/device/GetRecentlyFireHydrantDatas",
          "202008141430249960592188",
          true
        )
        .then((data) => {
          var data1 = [];
          data.forEach((x) => {
            var m = new Date().getMonth();
            var sub = {
              Time: `${m}/${x[0].Day}`,
              Data: [],
            };
            x.forEach((y) => {
              var t = ("0" + y.Hour).substr(-2);
              if (!y.Wg || y.Wg == 0) {
                y.Wg = Math.random();
              }
              sub.Data.push({
                Time: `${t}:00`,
                Count: y.Wg,
              });
            });
            data1.push(sub);
          });
          this.beginLeftBottomChart(data1);
          this.leftBottomLoading = false;
        })
        .catch((err) => {
          this.leftBottomLoading = false;
          this.$throw(err);
        });
    },
    beginLeftBottomChart(data) {
      var datas = [];
      var names = [];
      for (var j = 0; j < data.length; j++) {
        var area = {};
        var line = "#00e5fc";
        if (j == 0) {
          area = {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(24,203,231,0.7)" },
                { offset: 0.5, color: "rgba(24,203,231,0.5)" },
                { offset: 1, color: "rgba(24,203,231,0.2)" },
              ]),
            },
          };
        }
        if (j == 1) {
          area = {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(149,143,69,0.9)" },
                { offset: 0.5, color: "rgba(149,143,69,0.5)" },
                { offset: 1, color: "rgba(24,203,231,0.2)" },
              ]),
            },
          };
          line = "#86fc21";
        }
        if (j == 2) {
          area = {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(87,141,170,0.5)" },
                { offset: 0, color: "rgba(87,141,170,0.4)" },
                { offset: 1, color: "rgba(24,203,231,0.2)" },
              ]),
            },
          };
          line = "#6cce38";
        }
        names.push(data[j].Time);
        var online = [];
        var labels = [];
        for (var i = 0; i < data[j].Data.length; i++) {
          var obj = data[j].Data;
          online.push(obj[i].Count);
          labels.push(obj[i].Time);
        }
        datas.push({
          name: data[j].Time,
          type: "line",
          stack: data[j].Time,
          label: {
            normal: {
              show: false,
              position: "top",
              color: "white",
            },
          },
          smooth: true,
          sampling: "average",
          data: online,
          itemStyle: {
            color: line,
          },
          areaStyle: area,
        });
      }
      var x = {
        type: "category",
        boundaryGap: false,
        data: labels,
      };
      var y = {
        splitLine: {
          lineStyle: {
            color: "#8495c2",
          },
        },
        type: "value",
      };
      var option = this.getOption(x, y, datas);
      (option.title = {
        show: true,
        text: this.$t("横向对比分析"),
        padding: [20, 0, 0, 20],
        textStyle: {
          color: "#ffffff",
        },
      }),
        (option.legend = {
          data: names,
          textStyle: {
            color: "#ffffff",
          },
        });
      this.leftBottom.setOption(option, true);
      window.addEventListener("resize", () => {
        this.leftBottom.resize();
      });
    },

    getOption(xAxis, yAxis, datas) {
      var obj = {
        textStyle: {
          color: "#8495c2",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "25%",
          containLabel: true,
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: datas,
        legend: {
          data: [],
          textStyle: {
            color: "#ffffff",
          },
          orient: "vertical",
          right: 50,
        },
      };
      return obj;
    },
  },
};
</script>

