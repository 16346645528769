var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_over"},[_vm._m(0),_c('div',{attrs:{"id":"main_top"}},[_c('div',{attrs:{"id":"main_top_left"}},[_c('router-link',{attrs:{"to":_vm.home}},[_c('b-img',{attrs:{"src":_vm.logopic,"fluid":""}})],1)],1),_c('div',{attrs:{"id":"main_top_center"}},[_vm._v(_vm._s(_vm.$t("智慧城市")))]),_c('div',{attrs:{"id":"main_top_right"}},[_c('i',{staticClass:"fa fa-user-circle-o"}),_c('span',{staticClass:"content_text"},[_vm._v(_vm._s(_vm.userName))])])]),_c('div',{staticClass:"container-fluid",attrs:{"id":"main_content"}},[_c('div',{staticClass:"row",staticStyle:{"height":"100%"}},[_vm._m(1),_c('div',{staticClass:"col-sm-12 col-lg-4 col-md-4"},[_c('div',{staticStyle:{"height":"10%"}},[_c('div',{staticClass:"row",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"col"},[_c('span',{staticStyle:{"color":"white","font-size":"2em","display":"block"}},[_vm._v("2546")]),_c('span',{staticClass:"content_text"},[_vm._v(_vm._s(_vm.$t("智慧城市")))])]),_vm._m(2),_vm._m(3)])]),_c('div',{staticStyle:{"height":"60%"},attrs:{"id":"myChart"}}),_vm._m(4)]),_c('div',{staticClass:"col-sm-12 col-lg-4 col-md-4",staticStyle:{"height":"100%"},attrs:{"id":"main_cotnent_left"}},[_c('div',{staticClass:"main_content_right_top"},[_c('div',{staticClass:"main_flex"},[_c('div',{attrs:{"id":"rightTopChat"}}),_c('div',{staticStyle:{"position":"relative"}},[_c('alarm',{ref:"alarm",attrs:{"right":"15%"}}),_vm._m(5)],1)])]),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_over_bg"},[_c('div',{staticStyle:{"height":"100%"},attrs:{"id":"myChart"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-lg-4 col-md-4",staticStyle:{"height":"100%"},attrs:{"id":"main_cotnent_left"}},[_c('div',{staticClass:"main_content_left_top"},[_c('div',{staticStyle:{"height":"100%","width":"100%"},attrs:{"id":"onlineChat"}})]),_c('div',{staticClass:"main_content_left_bottom"},[_c('div',{staticStyle:{"height":"100%","width":"100%"},attrs:{"id":"leftBottomChat"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticStyle:{"color":"white","font-size":"2em","display":"block"}},[_vm._v("2546")]),_c('span',{staticClass:"content_text"},[_vm._v("智慧灯杆")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('span',{staticStyle:{"color":"white","font-size":"2em","display":"block"}},[_vm._v("2546")]),_c('span',{staticClass:"content_text"},[_vm._v("智慧灯杆")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"30%"}},[_c('div',{staticClass:"row",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"col"},[_c('span',{staticStyle:{"color":"white","font-size":"2em","display":"block"}},[_vm._v("2546")]),_c('span',{staticClass:"content_text"},[_vm._v("智慧灯杆")])]),_c('div',{staticClass:"col"},[_c('span',{staticStyle:{"color":"white","font-size":"2em","display":"block"}},[_vm._v("2546")]),_c('span',{staticClass:"content_text"},[_vm._v("智慧灯杆")])]),_c('div',{staticClass:"col"},[_c('span',{staticStyle:{"color":"white","font-size":"2em","display":"block"}},[_vm._v("2546")]),_c('span',{staticClass:"content_text"},[_vm._v("智慧灯杆")])])]),_c('div',{staticClass:"row",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"col"},[_c('span',{staticStyle:{"color":"white","font-size":"2em","display":"block"}},[_vm._v("2546")]),_c('span',{staticClass:"content_text"},[_vm._v("智慧灯杆")])]),_c('div',{staticClass:"col"},[_c('span',{staticStyle:{"color":"white","font-size":"2em","display":"block"}},[_vm._v("2546")]),_c('span',{staticClass:"content_text"},[_vm._v("智慧灯杆")])]),_c('div',{staticClass:"col"},[_c('span',{staticStyle:{"color":"white","font-size":"2em","display":"block"}},[_vm._v("2546")]),_c('span',{staticClass:"content_text"},[_vm._v("智慧灯杆")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"20px 0 0 5px"}},[_c('div',{staticStyle:{"color":"white","font-size":"24px"}},[_vm._v("告警分析")]),_c('div',{staticClass:"content_text",staticStyle:{"font-size":"80%"}},[_vm._v("Alarm Analysis")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_content_right_bottom"},[_c('div',{staticStyle:{"height":"100%","width":"100%"},attrs:{"id":"rightBottomChat"}})])
}]

export { render, staticRenderFns }