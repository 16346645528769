<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      :reboot="true"
      :publish="true"
      :sync="true"
      :init="true"
      v-on:publish="publish"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
      v-on:reboot="reboot"
      v-on:sync="sync"
      v-on:init="initView"
    ></actionbar>
    <el-table stripe="" :data="items" style="width: 100%" highlight-current-row @current-change="currentRow" :empty-text="$t('暂无数据')">
      <el-table-column :label="$t('序号')" prop="Serial"></el-table-column>
      <el-table-column :label="$t('设备名')" prop="DeviceName"></el-table-column>
      <el-table-column :label="$t('设备类型')">
        <template slot-scope="scope">
          <span>{{scope.row.DeviceType.TypeName}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('设备型号')">
        <template slot-scope="scope">
          <span>{{scope.row.DeviceType.ModelNumber}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('宽度')">
        <template slot-scope="scope">
          <span>{{scope.row.Width}}</span>
          <i
            class="fa fa-refresh"
            style="margin-left:5px;cursor:pointer"
            @click="callLed(scope.row.Sid,6)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('高度')" prop="Height">
        <template slot-scope="scope">
          <span>{{scope.row.Height}}</span>
          <i
            class="fa fa-refresh"
            style="margin-left:5px;cursor:pointer"
            @click="callLed(scope.row.Sid,7)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('位置')" prop="Address"></el-table-column>
      <el-table-column :label="$t('在线状态')">
        <template slot-scope="scope">
          <i :class="['fa',scope.row.Status==1?'stateOn':'stateOff','fa-2x','fa-signal']"></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('屏幕状态')">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.Switch"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#999"
            @change="callLed(scope.row.Sid,0,scope.row.Switch!=0?true:false)"
          ></el-switch>
          <i
            class="fa fa-refresh"
            style="margin-left:5px;cursor:pointer"
            @click="callLed(scope.row.Sid,1)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('详情')">
        <template slot-scope="scope">
          <router-link :to="`/admin/stand/leddetail?sid=${scope.row.Sid}&xx=true`">{{$t('详情')}}</router-link>
        </template>
      </el-table-column>
    </el-table>
    <modify
      ref="modify"
      :mapitem="mapitem"
      :modaldata="modalData"
      :title="title"
      :labels="labels"
      v-on:submited="submited"
      url="/api/device/AddLedAd"
    ></modify>
    <b-modal
      id="viewModal"
      class="ksmodal"
      ref="viewModal"
      size="lg"
      title="预览"
      body-text-variant="dark"
    >
      <div :style="viewStyle">
        <viewled :sid="viewSid" :width="viewStyle.width" ref="ledtemp"></viewled>
      </div>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/device/DelLedAd"
      :title="$t('删除')"
    ></deletedata>

    <b-modal
      id="publishmodal"
      class="ksmodal"
      ref="publishmodal"
      size="lg"
      :title="$t('发布')"
      body-text-variant="dark"
      @ok="publishStage"
      @shown="publishShown"
    >
      <b-form>
         <b-form-group v-bind:label="$t('任务')" :label-cols="2">
           <b-form-radio-group :options="tasks" v-model="currentTask" value-field="Sid" text-field="Name" @change="taskChange"></b-form-radio-group>
        </b-form-group>
          <b-form-group
          v-bind:label="item.Prog.name"
          :label-cols="2"
          v-for="item in currentTaskData.Items"
          :key="item.Sid"
        >
          <div class="sceneTemp">
            <div style="text-align:right;margin-bottom:5px;">
              <i class="fa fa-trash-o" style="cursor:pointer" @click="delItem(item.Sid)"></i>
            </div>
            <b-form-group v-bind:label="$t('优先级')" :label-cols="2">
              <b-form-input type="number" v-model="item.Priority" readonly></b-form-input>
            </b-form-group>
            <b-form-group v-bind:label="$t('重复次数')" :label-cols="2">
              <b-form-input type="number" v-model="item.RepeatTimes" readonly></b-form-input>
            </b-form-group>
            <b-form-group
              v-bind:label="$t('时间安排')"
              :label-cols="2"
              v-for="time in item.Schedules"
              :key="time.Sid"
            >
              <div class="sceneTemp">
                <b-form-group v-bind:label="$t('日期')" :label-cols="2">
                  <b-form-checkbox disabled
                    v-model="time.DateType"
                    value="All"
                    unchecked-value="Range"
                  >{{$t('不限')}}</b-form-checkbox>
                  <div v-if="time.DateType=='Range'">
                    <el-date-picker readonly
                      v-model="time.StartDate"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>-
                    <el-date-picker readonly
                      v-model="time.EndDate"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                  </div>
                </b-form-group>
                <b-form-group v-bind:label="$t('时间')" :label-cols="2">
                  <b-form-checkbox disabled
                    v-model="time.TimeType"
                    value="All"
                    unchecked-value="Range"
                  >不限</b-form-checkbox>
                  <div v-if="time.TimeType=='Range'">
                    <el-time-picker readonly
                      v-model="time.StartTime"
                      placeholder="选择时间"
                      value-format="HH:mm"
                    ></el-time-picker>-
                    <el-time-picker v-model="time.EndTime" placeholder="选择时间" value-format="HH:mm"></el-time-picker>
                  </div>
                </b-form-group>
                <b-form-group v-bind:label="$t('星期')" :label-cols="2">
                  <b-form-checkbox disabled
                    v-model="time.FilterType"
                    value="None"
                    unchecked-value="Week"
                  >不限</b-form-checkbox>
                  <b-check-group disabled
                    v-if="time.FilterType=='Week'"
                    :options="Weeks"
                    v-model="time.WeekFilter"
                  ></b-check-group>
                </b-form-group>
              </div>
            </b-form-group>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="initModel"
      ref="initModel"
      size="lg"
      :title="$t('初始化')"
      body-text-variant="dark"
      @ok="initView"
    >
      <div>{{$t("选择资源")}}</div>
      <b-form-select v-model="picModel" :options="picRes" text-field="Name" value-field="Content"></b-form-select>
      <b-img fluid :src="picModel"></b-img>
    </b-modal>
    <b-modal
      id="syncModel"
      ref="syncModel"
      size="lg"
      :title="$t('同步')"
      body-text-variant="dark"
      @ok="syncModel"
    >
    <b-form>
         <b-form-group v-bind:label="$t('同步方式')" :label-cols="2">
           <b-form-radio-group :options="syncs" v-model="currentSyncsData.time"></b-form-radio-group>
        </b-form-group>
        <div v-if="currentSyncsData.time=='serial'">
          <b-form-group v-bind:label="$t('亮度同步')" :label-cols="2">
            <b-form-checkbox v-model="currentSyncsData.brightness"></b-form-checkbox>
        </b-form-group>
          <b-form-group v-bind:label="$t('声音同步')" :label-cols="2">
            <b-form-checkbox v-model="currentSyncsData.volume"></b-form-checkbox>
        </b-form-group>
          <b-form-group v-bind:label="$t('屏幕开关同步')" :label-cols="2">
            <b-form-checkbox v-model="currentSyncsData.screenSwitch"></b-form-checkbox>
        </b-form-group>
          <b-form-group v-bind:label="$t('同步码')" :label-cols="2">
          <b-form-input v-model="currentSyncsData.identificationCode"></b-form-input>
        </b-form-group>
          <b-form-group v-bind:label="$t('刷新频率')+'(ms)'" :label-cols="2">
          <b-form-input v-model="currentSyncsData.delaySync" type="number"></b-form-input>
        </b-form-group>
        </div>
        <div v-if="currentSyncsData.time=='ntp'">
          <b-form-group v-bind:label="$t('同步间隔')+'(分钟)'" :label-cols="2">
          <b-form-input v-model="currentSyncsData.checkNtpTime" type="number"></b-form-input>
        </b-form-group>
        </div>
    </b-form>
    </b-modal>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
import viewled from "@/components/viewled";
export default {
  components: { actionbar, tablepage, modify, deletedata, viewled },
  data: function() {
    return {
      picRes: [],
      picModel: "",
      viewWidth: 0,
      viewHeight: 0,
      viewStyle: {
        width: "100%",
        height: "100%",
        border: "1px solid"
      },
      viewSid: "",
      mapitem: {
        value: "Address",
        require: true,
        subvalue: false,
        Position: {
          Lat: "Lat",
          Lng: "Lng"
        }
      },
      modalData: this.resetData(),
      loading: {},
      items: [],
      total: 0,
      count: 20,
      index: 0,
      sid: "",
      labels: this.getResetLable(),
      title: "",
      current: null,
      devices: [],
      deviceSlected: [],
      deviceSlected1: [],
      tasks:[],
      currentTask:"",
      currentTaskData:{},
      Weeks: [
        { value: 1, text: this.$t("星期一") },
        { value: 2, text: this.$t("星期二") },
        { value: 3, text: this.$t("星期三") },
        { value: 4, text: this.$t("星期四") },
        { value: 5, text: this.$t("星期五") },
        { value: 6, text: this.$t("星期六") },
        { value: 7, text: this.$t("星期天") }
      ],
      syncs:[
        {text:"硬件同步",value:"serial"},
        {text:"GPS同步",value:"gps"},
        {text:"网络同步",value:"ntp"},
      ],
      currentSyncs:"ntp",
      currentSyncsData:this.getSyncData()
    };
  },
  methods: {
    getSyncData(){
      return {
          "sid":null,
          "cardId":null,	//目标设备序列号
          "type":"setTimeSync",	//命令字符串固定不可变
          "time":"ntp",		//可选serial、gps和ntp，依次为模块校时、gps校时和网络校时
          "brightness":false,	//当time=“serial”时，可选serial则亮度依据主模块调整，否则设置为none
          "volume":false,		//当time="serial“时，可选serial则音量依据主模块调整，否则设置为none
          "screenSwitch":false,	//当time="serial"时，可选serial则屏幕开关设置依据主模块调整，否则设置为none
          "identificationCode":"KingSun",	//同步码，只在time=”serial“生效，需要同步的设备同步码必须一致
          "delaySync":1,		//time=”serial"时的同步间隔时长，指定程序校时的频度，单位毫秒
          "checkNtpTime":1		//time="ntp“时的同步间隔时长，单位分钟
      }
    },
    syncModel(){
      this.loading=this.$loading();
       this.$ksajax
        .ksPostPromise("/api/xixun/AsyncScreen", this.currentSyncsData, true).then(()=>{
          this.loading.close();
        }).catch((err)=>{
          this.$throw(err);
          this.loading.close();
        })
    },
    sync(){
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.currentSyncsData=this.getSyncData();
      this.currentSyncsData.sid=this.current.Sid;
      if(this.current.TaskSid){
        this.currentSyncsData.identificationCode=this.current.TaskSid;
      }else{
        this.currentSyncsData.identificationCode="KingSun";
      }
      if(this.current.Async){
        this.currentSyncsData.time=this.current.Async.Time;
        this.currentSyncsData.brightness=this.current.Async.Brightness;
        this.currentSyncsData.volume=this.current.Async.Volume;
        this.currentSyncsData.screenSwitch=this.current.Async.ScreenSwitch;
        this.currentSyncsData.delaySync=this.current.Async.DelaySync;
        this.currentSyncsData.checkNtpTime=this.current.Async.CheckNtpTime;
      }
      this.$refs.syncModel.show();
    },
    taskChange(val){
      this.currentTaskData=this.tasks.find(x=>x.Sid==val);
    },
    callLed(sid, fun, value) {
      var obj = {
        Sid: sid,
        Fun: fun,
        Value: value
      };
      this.loading = this.$loading({ fullScreen: true });
      this.$ksajax
        .ksPostPromise("/api/led/CallLed", obj, true)
        .then(data => {
          var index = -1;
          for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].Sid == sid) {
              index = i;
            }
          }
          if (index != -1) {
            switch (fun) {
              case 0:
                //开启屏幕
                break;
              case 1:
                //是否已经开启屏幕
                this.items[index].Switch = data.result ? 1 : 0;
                break;
              case 2:
                break;
              case 3:
                this.items[index].Brightness = data.result;
                break;
              case 4:
                break;
              case 5:
                this.items[index].Volume = data.result;
                break;
              case 6:
                this.items[index].Width = data.result;
                break;
              case 7:
                this.items[index].Height = data.result;
                break;
              case 8:
                break;
            }
          }
          this.loading.close();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    select(noloading, index) {
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      let obj = {
        Index: this.index,
        Count: this.count
      };
      this.$ksajax
        .ksPostPromise("/api/device/GetDeviceLedAds", obj, true)
        .then(data => {
          data.Items.forEach(element => {
            var sid = element.Sid;
            element.View = "/LedView/?Sid=" + sid;
          });
          this.items = data.Items;
          this.total = data.Items.length;
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch(err => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.modalData = {
        Sid: this.current.Sid,
        DeviceName: this.current.DeviceName,
        Lat: this.current.Lat,
        Lng: this.current.Lng,
        Address: this.current.Address,
        Serial: this.current.Serial,
        Width: this.current.Width,
        Height: this.current.Height,
        SensorAddress: "01",
        SN: this.current.SN,
        BaudRate: 9600,
        DTypeSid: this.current.DeviceType.Sid,
        LStandSid: this.current.LampStand.Sid
      };
      this.$refs.modify.show();
    },
    insert() {
      this.modalData = this.resetData();
      this.$refs.modify.show();
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      return {
        Sid: "",
        DeviceName: "",
        Lat: 0,
        Lng: 0,
        Address: "",
        Serial: "",
        Width: 0,
        Height: 0,
        Switch: 0,
        Brightness: 0,
        Volume: 0,
        Screenshot: "",
        DTypeSid: "",
        LStandSid: ""
      };
    },
    getResetLable() {
      return [
        {
          label: this.$t("设备名"),
          type: "text",
          value: "DeviceName",
          require: true
        },
        {
          label: this.$t("高度"),
          type: "number",
          value: "Height",
          require: true
        },
        {
          label: this.$t("宽度"),
          type: "number",
          value: "Width",
          require: true
        },
        {
          label: this.$t("序号"),
          type: "text",
          value: "Serial",
          require: true
        },
        {
          label: this.$t("类型"),
          type: "select",
          value: "DTypeSid",
          require: true,
          bind: this.$store.getters.getDeviceType("006")
        },
        {
          label: this.$t("灯杆"),
          type: "select",
          value: "LStandSid",
          require: false,
          bind: this.$store.state.lStands
        },
        {
          label: this.$t("位置"),
          type: "map",
          value: "Address",
          require: true
        }
      ];
    },
    submited() {
      this.select();
    },
    deleted() {
      this.select();
    },
    view() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.viewSid = this.current.Sid;
      this.viewStyle.width = this.current.Width + "px";
      this.viewStyle.height = this.current.Height + "px";
      this.viewWidth = this.current.Width;
      this.viewHeight = this.current.Height;
      this.$refs.viewModal.show();
    },
    reboot() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.$confirm(this.$t("重启确认"), this.$t("重启"), {
        confirmButtonText: this.$t("确定"),
        cancelButtonText: this.$t("取消"),
        type: "warning"
      })
        .then(() => {
          this.callLed(this.current.Sid, 8, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("已取消操作")
          });
        });
    },
    publish() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.loading = this.$loading();
      this.currentTask=this.current.TaskSid;
      new Promise((res, rej) => {
        if (this.tasks.length != 0) {
          res();
        } else {
          this.$ksajax
            .ksPostPromise("/api/xixun/GetProgTask", "", true)
            .then(data => {
              this.tasks = data;
              this.currentTaskData=this.tasks.find(x=>x.Sid==this.currentTask);
              res();
            })
            .catch(err => {
              rej(err);
            });
        }
      })
        .then(() => {
          this.loading.close();
          this.$refs.publishmodal.show();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    publishStage() {
      if(!this.currentTask){
        throw "请选择节目";
      }
       
      var data = {
        TaskSid: this.currentTask,
        Devices: [this.current.Sid]
      };
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/xixun/PublicPlay", data, true)
        .then(() => {
          this.$router.push({
          path: "/admin/XiXun/progLog"
        });
          this.loading.close();
        })
        .catch(err => {
          this.loading.close();
          this.$throw(err);
        });
    },
    publishShown() {
      if (this.deviceSlected1 && this.deviceSlected1.length != 0) {
        this.deviceSlected1.forEach(x => {
          this.$refs.multipleTable.toggleRowSelection(x);
        });
      }
    },
    handleSelectionChange(val) {
      this.deviceSlected = val;
    },
    init() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      new Promise((res, rej) => {
        if (this.picRes.length != 0) {
          res();
        } else {
          this.loading = this.$loading({ fullScreen: true });
          this.$ksajax
            .ksPostPromise("/api/led/GetPicRes", "", true)
            .then(r => {
              this.picRes = r;
              res();
            })
            .catch(err => {
              rej(err);
            });
        }
      })
        .then(() => {
          this.$refs.initModel.show();
          this.loading.close();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    initView() {
      this.$confirm("请确认对该设备进行初始化操作","初始化",{
        confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(()=>{
        this.loading=this.$loading();
         this.$ksajax
            .ksPostPromise("/api/XiXun/InitXiXun", this.current.Sid, true).then(()=>{
              this.loading.close();
            }).catch((err)=>{
              this.$throw(err);
              this.loading.close();
            })
      }).catch(()=>{
        this.$message({
          type:"info",
          message:"已取消操作"
        })
      })
    }
  },
  mounted: function() {
    this.select();
  }
};
</script>
