<template>
  <div class="Stands white" v-loading="loading">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      v-bind:insert="true"
      v-bind:sync="true"
      v-on:select="select"
      v-on:insert="insert"
      v-on:modify="modify"
      v-on:sync="sync"
    >
    </actionbar>
    <parkPage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></parkPage>
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      @shown="onModalLoaded"
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group
          v-bind:label="$t('停车场编号')"
          :label-cols="2"
          v-if="modalData.Sid === ''"
        >
          <b-form-input type="text" v-model="parkCode" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('停车场别名')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.Alias"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('竖向泊位数量')" :label-cols="2">
          <b-form-input
            type="number"
            v-model="modalData.VCount"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('横向泊位数量')" :label-cols="2">
          <b-form-input
            type="number"
            v-model="modalData.HCount"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group v-bind:label="$t('位置')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.Address"
            required
            :placeholder="$t('请输入位置')"
            v-on:keyup.enter.native="setAddress"
          >
          </b-form-input>

          <div style="margin: 10px 0 0 10px">
            <span>经度：</span
            ><b-form-input
              style="width: 40%; display: inline"
              v-model="modalData.ParkLongitude"
              v-on:keyup.enter.native="onModalLoaded"
            ></b-form-input>
            <span style="padding-left: 20px">纬度：</span
            ><b-form-input
              style="width: 40%; display: inline"
              v-model="modalData.ParkLatitude"
              v-on:keyup.enter.native="onModalLoaded"
            ></b-form-input>
          </div>

          <ksmap
            ref="map"
            v-on:mapMoveEnd="mapMoveEnd"
            width="100%"
            :showCenter="true"
            height="200px"
            v-bind:lat="parseFloat(modalData.ParkLatitude)"
            v-bind:lng="parseFloat(modalData.ParkLongitude)"
            v-bind:zoom="25"
          >
          </ksmap>
        </b-form-group>

        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button
              style="width: 100px"
              variant="secondary"
              v-on:click="hiden"
              >{{ $t("取消") }}</b-button
            >
            <b-button
              type="button"
              style="width: 100px"
              variant="primary"
              v-on:click="onSubmit"
              >{{ $t("确认") }}</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<style>
.admin {
  background-color: white;
}
</style>
<script>
import actionbar from "@/components/actionbar";
import parkPage from "./components/parkPage.vue";
import ksmap from "@/components/map";

export default {
  data: function () {
    return {
      modalData: {
        Sid: "",
        Alias: "",
        VCount: 0,
        HCount: 0,
        ParkLatitude: 0,
        ParkLongitude: 0,
        Address: "",
      },
      title: "",
      cols: [
        { name: this.$t("停车场名称"), value: "Name" },
        { name: this.$t("停车场别名"), value: "Alias" },
        { name: this.$t("设备数量"), value: "DeviceCount" },
        { name: this.$t("竖向泊位数量"), value: "VCount" },
        { name: this.$t("横向泊位数量"), value: "HCount" },
        { name: this.$t("泊位总数量"), value: "ParkPortCount" },
        { name: this.$t("位置"), value: "Address" },
        { name: this.$t("详情"), value: "url", link: true },
      ],
      items: [],
      index: 0,
      count: 5,
      total: 0,
      current: null,
      loading: false,
      parkCode: "",
    };
  },
  mounted: function () {
    this.select();
  },
  components: {
    actionbar,
    parkPage,
    ksmap,
  },
  created: function () {},
  methods: {
    mapMoveEnd(latlng) {
      this.$refs.map.getAddressByPostion(latlng.lat, latlng.lng, (data) => {
        this.modalData.Address = data.regeocode.formattedAddress;
        this.modalData.ParkLatitude = latlng.lat;
        this.modalData.ParkLongitude = latlng.lng;
      });
    },
    onModalLoaded() {
      if (this.$refs.map) {
        if (!this.modalData.ParkLatitude || this.modalData.ParkLatitude === 0) {
          this.modalData.ParkLatitude = 39.908714;
        }
        if (
          !this.modalData.ParkLongitude ||
          this.modalData.ParkLongitude === 0
        ) {
          this.modalData.ParkLongitude = 116.397056;
        }
        this.$refs.map.setCenter(
          this.modalData.ParkLatitude,
          this.modalData.ParkLongitude
        );
      }
    },
    setAddress() {
      this.$refs.map.setAddress(this.modalData.Address);
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkList", "", true)
        .then((data) => {
          data.forEach((element) => {
            element.url = "/admin/Parking/detail?sid=" + element.Sid;
          });
          this.items = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    insert() {
      this.title = this.$t("新增");
      this.modalData = this.resetData();
      this.parkCode = "";
      this.$refs.modallg.show();
    },
    resetData() {
      return {
        Sid: "",
        Alias: "",
        VCount: 0,
        HCount: 0,
        ParkLatitude: 0,
        ParkLongitude: 0,
        Address: "",
      };
    },
    modify() {
      if (!this.current) {
        this.$throw(this.$t("选择一条数据"), this);
      } else {
        this.modalData = {
          Sid: this.current.Sid,
          Alias: this.current.Alias,
          VCount: this.current.VCount,
          HCount: this.current.HCount,
          ParkLatitude: this.current.ParkLatitude,
          ParkLongitude: this.current.ParkLongitude,
          Address: this.current.Address,
        };
        if (this.current.LampStand) {
          this.modalData.LStandSid = this.current.LampStand.Sid;
        }
        this.title = this.$t("修改");
        this.$refs.modallg.show();
      }
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (
        parseInt(this.modalData.VCount) < 0 ||
        parseInt(this.modalData.HCount) < 0
      ) {
        this.$throw(this.$t("泊位数量不能少于0"), this);
        return;
      }

      if (this.modalData.Sid) {
        if (
          parseInt(this.modalData.VCount) + parseInt(this.modalData.HCount) !==
          this.current.ParkPortCount
        ) {
          this.$throw(this.$t("泊位数量与总数量不符"), this);
          return;
        }
        this.loading = true;
        this.$ksajax
          .ksPostPromise(
            "/api/ParkCtl/UpdatePart",
            {
              Sid: this.modalData.Sid,
              Alias: this.modalData.Alias,
              VCount: parseInt(this.modalData.VCount),
              HCount: parseInt(this.modalData.HCount),
              ParkLatitude: parseFloat(this.modalData.ParkLatitude),
              ParkLongitude: parseFloat(this.modalData.ParkLongitude),
              Address: this.modalData.Address,
            },
            true
          )
          .then(() => {
            this.select();
            this.loading = false;
            this.hiden();
          })
          .catch((err) => {
            this.loading = false;
            this.$throw(err);
          });
      } else {
        this.loading = true;
        this.$ksajax
          .ksPostPromise(
            "/api/ParkCtl/Addpark",
            {
              Code: this.parkCode,
              Alias: this.modalData.Alias,
              VCount: parseInt(this.modalData.VCount),
              HCount: parseInt(this.modalData.HCount),
              ParkLatitude: parseFloat(this.modalData.ParkLatitude),
              ParkLongitude: parseFloat(this.modalData.ParkLongitude),
              Address: this.modalData.Address,
            },
            true
          )
          .then(() => {
            this.select();
            this.loading = false;
            this.hiden();
          })
          .catch((err) => {
            this.loading = false;
            this.$throw(err);
          });
      }
    },
    sync() {
      if (!this.current) {
        this.$throw(this.$t("选择一条数据"), this);
      } else {
        this.loading = true;
        this.$ksajax
          .ksPostPromise("/api/ParkCtl/SyncPart", this.current.Sid, true)
          .then(() => {
            this.select();
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$throw(err);
          });
      }
    },
    tableCreated() {
      // this.select();
    },
    currentPage(val) {
      this.index = (val - 1) * this.count;
      this.select();
    },
    currentRow(val) {
      this.current = val;
    },
    hiden() {
      this.$refs.modallg.hide();
    },
  },
};
</script>

