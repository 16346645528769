<template>
  <div class="white">
    <actionbar v-bind:select="true" v-bind:modify="true" v-on:select="select" v-on:modify="modify"></actionbar>

    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>
    <b-modal
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      class="ksmodal"
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-tabs>
        <b-tab :title="$t('安全线')" active>
          <hr />
          <alarmAction :value="modalData.self"></alarmAction>
        </b-tab>

        <b-tab :title="$t('抽水线')">
          <alarmAction :value="modalData.flag"></alarmAction>
          <hr />
        </b-tab>

        <b-tab :title="$t('报警线')">
          <alarmAction :value="modalData.alarm"></alarmAction>
          <hr />
        </b-tab>
      </b-tabs>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/device/DelWaterLevel"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>

<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
import alarmAction from "@/components/alarmAction";
import { Link } from "@/assets/utils/datef";
export default {
  components: { actionbar, tablepage, modify, deletedata, alarmAction },
  data: function() {
    return {
      radio: 0,
      title: this.$t("修改"),
      modalData: this.resetData(),
      loading: {},
      cols: [
        { name: this.$t("设备名"), value: "DeviceName" },
        {
          name: this.$t("设备类型"),
          value: "TypeName",
          subvalue: "DeviceType"
        },
        {
          name: this.$t("设备型号"),
          value: "ModelNumber",
          subvalue: "DeviceType"
        },
        { name: this.$t("报警线"), value: "ThresholdValue", subvalue: "alarm" },
        { name: this.$t("抽水线"), value: "ThresholdValue", subvalue: "flag" },
        { name: this.$t("安全线"), value: "ThresholdValue", subvalue: "self" }
      ],
      items: [],
      total: 0,
      count: 2000,
      index: 0,
      sid: "",
      labels: this.getResetLable(),
      current: null,
      link: Link().filter(function(x) {
        return x.state == 1;
      }),
      selfChoose: {
        radio: null,
        devs: [],
        selected: {}
      },
      flagChoose: {
        radio: null,
        devs: [],
        selected: {}
      },
      leds: []
    };
  },
  methods: {
    delItem(type, guid) {
      var index = -1;
      if (type == 0) {
        for (var i = 0; i < this.modalData.self.LinkageActions.length; i++) {
          if (this.modalData.self.LinkageActions[i].guid == guid) {
            index = i;
            break;
          }
        }
        if (index != -1) {
          this.modalData.self.LinkageActions.splice(index, 1);
        }
      } else if (type == 1) {
        for (var j = 0; j < this.modalData.flag.LinkageActions.length; j++) {
          if (this.modalData.flag.LinkageActions[j].guid == guid) {
            index = j;
            break;
          }
        }
        if (index != -1) {
          this.modalData.flag.LinkageActions.splice(index, 1);
        }
      } else if (type == 2) {
        for (var k = 0; k < this.modalData.alarm.LinkageActions.length; k++) {
          if (this.modalData.alarm.LinkageActions[k].guid == guid) {
            index = k;
            break;
          }
        }
        if (index != -1) {
          this.modalData.alarm.LinkageActions.splice(index, 1);
        }
      }
    },
    select(noloading, index) {
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      new Promise((res, rej) => {
        let obj = {
          Index: this.index,
          Count: this.count
        };
        this.$ksajax
          .ksPostPromise("/api/device/GetWaterLevels", obj, true)
          .then(data => {
            data.Items.forEach(x => {
              x.alarm = {
                LinkageActions: []
              };
              x.flag = {
                LinkageActions: []
              };
              x.self = {
                LinkageActions: []
              };
            });
            this.items = data.Items;
            this.total = data.Items.length;
            res();
          })
          .catch(err => {
            rej(err);
          });
      })
        .then(() => {
          return new Promise((res, rej) => {
            let obj = {
              Index: this.index,
              Count: this.count,
              DevCtgSid: "009"
            };
            this.$ksajax
              .ksPostPromise("/api/device/GetSensorLinkages", obj, true)
              .then(data => {
                this.items.forEach(x => {
                  data.Items.forEach(y => {
                    if (y.LinkageActions) {
                      y.LinkageActions.forEach(l => {
                        l.link = this.link.find(o => o.value == l.LinkageType);
                        l.guid = this.guid();
                      });
                    }
                    if (y.DeviceSid == x.Sid) {
                      if (y.LinkageName == "抽水线") {
                        x.flag = y;
                      }
                      if (y.LinkageName == "报警线") {
                        x.alarm = y;
                      }
                      if (y.LinkageName == "安全线") {
                        x.self = y;
                      }
                    }
                  });
                });
                res();
              })
              .catch(err => {
                rej(err);
              });
          });
        })
        .then(() => {
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch(err => {
          this.$throw(err);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    guid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
      }
      return guid;
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.ksmodel.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.modalData.flag = {
        Sid: this.current.flag ? this.current.flag.Sid : null,
        LinkageName: "抽水线",
        IsEnabled: this.current.flag ? this.current.flag.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 30,
        ThresholdValue: this.current.flag
          ? this.current.flag.ThresholdValue
          : null,
        CompareSymbol: this.current.flag ? this.current.flag.CompareSymbol : 0,
        LinkageActions: this.current.flag
          ? this.current.flag.LinkageActions
          : [],
        IsContrary: this.current.flag ? this.current.flag.IsContrary : false
      };
      this.modalData.alarm = {
        Sid: this.current.alarm ? this.current.alarm.Sid : null,
        LinkageName: "报警线",
        IsEnabled: this.current.alarm ? this.current.alarm.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 30,
        ThresholdValue: this.current.alarm
          ? this.current.alarm.ThresholdValue
          : null,
        CompareSymbol: this.current.alarm
          ? this.current.alarm.CompareSymbol
          : 0,
        LinkageActions: this.current.alarm
          ? this.current.alarm.LinkageActions
          : [],
        IsContrary: this.current.alarm ? this.current.alarm.IsContrary : false
      };
      this.modalData.self = {
        Sid: this.current.self ? this.current.self.Sid : null,
        LinkageName: "安全线",
        IsEnabled: this.current.self ? this.current.self.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 30,
        ThresholdValue: this.current.self
          ? this.current.self.ThresholdValue
          : null,
        CompareSymbol: this.current.self ? this.current.self.CompareSymbol : 0,
        LinkageActions: this.current.self
          ? this.current.self.LinkageActions
          : [],
        IsContrary: this.current.self ? this.current.self.IsContrary : false
      };

      this.$refs.modallg.show();
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      return {
        alarm: { LinkageActions: [] },
        self: { LinkageActions: [] },
        flag: { LinkageActions: [] }
      };
    },
    getResetLable() {
      return [
        {
          label: this.$t("设备名"),
          type: "text",
          value: "DeviceName",
          require: true
        },
        {
          label: this.$t("序号"),
          type: "text",
          value: "Serial",
          require: true
        },
        {
          label: this.$t("类型"),
          type: "select",
          value: "DTypeSid",
          require: true,
          bind: this.$store.getters.getDeviceType("009")
        },
        {
          label: this.$t("位置"),
          type: "map",
          value: "Address",
          require: true
        },
        {
          label: this.$t("量程"),
          type: "number",
          value: "MeasuringRange",
          require: true,
          defalut: 9000
        },
        {
          label: this.$t("基值"),
          type: "number",
          value: "BasicValue",
          require: true,
          defalut: 3
        },
        {
          label: this.$t("单位量"),
          type: "number",
          value: "UnitAmount",
          require: true,
          defalut: 3000
        }
      ];
    },
    submited() {
      this.select();
    },
    deleted() {
      this.select();
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    addItem(val) {
      if (val == 0) {
        //self
        var dev = this.selfChoose.devs.find(
          x => x.Sid == this.selfChoose.selected
        );
        var action = this.link.find(x => x.value == this.selfChoose.radio);
        this.modalData.self.LinkageActions.push({
          Sid: "",
          DeviceSid: dev.Sid,
          DeviceName: dev.DeviceName,
          LinkageType: action.value,
          Content: null,
          link: action
        });
      }
    },
    selfChange(val) {
      if ([0, 1, 2, 3, 4].indexOf(val) != -1) {
        if (this.leds.length == 0) {
          let obj = {
            Index: 0,
            Count: 1000
          };
          this.loading = this.$loading();
          this.$ksajax
            .ksPostPromise("/api/device/GetDeviceLedAds", obj, true)
            .then(x => {
              this.leds = x.Items;
              this.selfChoose.devs = x.Items;
              this.selfChoose.selected = this.leds[0].Sid;
              this.loading.close();
            })
            .catch(err => {
              this.$throw(err);
              this.loading.close();
            });
        } else {
          this.selfChoose.devs = this.leds;
          this.selfChoose.selected = this.leds[0].Sid;
        }
      }
    }
  },
  mounted: function() {
    this.select();
  }
};
</script>
