<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      :publish="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
      v-on:publish="publish"
    ></actionbar>
 <b-modal
      id="publishmodal"
      class="ksmodal"
      ref="publishmodal"
      size="lg"
      :title="$t('发布')"
      body-text-variant="dark"
      @ok="publishStage"
      @shown="publishShown"
    >
      <el-table stripe=""
        ref="multipleTable"
        :data="devices"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange" :empty-text="$t('暂无数据')"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column :label="$t('名称')" prop="DeviceName"></el-table-column>
        <el-table-column :label="$t('宽度')" prop="Width"></el-table-column>
        <el-table-column :label="$t('高度')" prop="Height"></el-table-column>
        <el-table-column :label="$t('位置')" prop="Address"></el-table-column>
      </el-table>
    </b-modal>
    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>

    <!-- <modify ref="modify"
         :modaldata='modalData'
         :title='title'
         :labels='labels'
         v-on:submited='submited'
         url="/api/led/AddNovaProg"
    ></modify>-->
    <b-modal
      id="modify"
      ref="modify"
      class="lgModal"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('名称')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Name" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('日期')" :label-cols="2">
          <el-date-picker
            v-model="modalData.StartDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>-
          <el-date-picker
            v-model="modalData.EndDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </b-form-group>
         <b-form-group v-bind:label="$t('节目')" :label-cols="2">
             <b-checkbox-group :options="progs" text-field="name" value-field="Sid" v-model="modalData.Progs"></b-checkbox-group>
        </b-form-group>
         <div style="text-align:center">
          <b-button type="submit" style="width:70%">{{$t("保存")}}</b-button>
        </div>
      </b-form>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/led/DeleteNovaTask"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
import { formatDate } from "@/assets/utils/datef";
export default {
  components: { actionbar, tablepage, modify, deletedata },
  data: function() {
    return {
      Weeks: [
        { value: 0, text: this.$t("星期一") },
        { value: 1, text: this.$t("星期二") },
        { value: 2, text: this.$t("星期三") },
        { value: 3, text: this.$t("星期四") },
        { value: 4, text: this.$t("星期五") },
        { value: 5, text: this.$t("星期六") },
        { value: 6, text: this.$t("星期天") }
      ],

      text: "test",
      modalData: this.resetData(),
      loading: {},
      cols: [
        { name: this.$t("名称"), value: "Name" },
        { name: this.$t("开始日期"), value: "StartDate" },
        { name: this.$t("结束日期"), value: "EndDate" },
        { name: this.$t("创建日期"), value: "CreateTime" }
      ],
      items: [],
      total: 0,
      count: 20,
      index: 0,
      sid: "",
      labels: this.getResetLable(),
      title: "",
      current: null,
      progs: [],
      devices:[],
      deviceSlected:[]
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.deviceSlected = val;
    },
     publishStage() {
      var data = {
        Sid: this.current.Sid,
        Devices: []
      };
      if (this.deviceSlected && this.deviceSlected.length > 0) {
        this.deviceSlected.forEach(x => {
          data.Devices.push(x.Serial);
        });
      } else {
        throw this.$t("请选择数据");
      }
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/PublishNovaTask", data, true)
        .then(() => {
          this.$router.push({
          path: "/admin/nova/log"
        });
          this.loading.close();
        })
        .catch(err => {
          this.loading.close();
          this.$throw(err);
        });
    },
    publishShown() {
      this.$refs.multipleTable.clearSelection();
    },
    publish() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      new Promise((res, rej) => {
        if (this.devices.length != 0) {
          res();
        } else {
          this.loading = this.$loading();
          let obj = {
            Index: 0,
            Count: 10000
          };
          this.$ksajax
            .ksPostPromise("/api/led/GetNovaLeds", obj, true)
            .then(data => {
              this.devices = data.Items;
              res();
              this.loading.close();
            })
            .catch(err => {
              rej(err);
              this.loading.close();
            });
        }
      })
        .then(() => {
          this.$refs.publishmodal.show();
        })
        .catch(err => {
          this.$throw(err);
        });
    },
    select() {
      this.loading = this.$loading({ fullScreen: true });
      this.$ksajax
        .ksPostPromise("/api/led/GetNovaTask", "", true)
        .then(data => {
          this.items = data;
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch(err => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
        this.getProgs(() => {
        this.modalData = this.resetData();
        this.modalData.Sid=this.current.Sid;
        this.modalData.StartDate=formatDate(new Date(this.current.StartDate),"yyyy-MM-dd");
        this.modalData.EndDate=formatDate(new Date(this.current.EndDate),"yyyy-MM-dd");
        this.modalData.Progs=JSON.parse(this.current.Progs);
        this.$refs.modify.show();
        this.title=this.$t("修改");
      });
    },
    insert() {
      this.getProgs(() => {
        this.modalData = this.resetData();
        this.$refs.modify.show();
        this.title=this.$t("新增");
      });
    },
    getProgs(fun) {
      this.loading = this.$loading({ fullScreen: true });
      new Promise((res, rej) => {
        if (this.progs.length != 0) {
          res();
        } else {
          this.$ksajax
            .ksPostPromise("/api/led/GetNovaProg", "", true)
            .then(data => {
              this.progs = data;
              res();
            })
            .catch(err => {
              rej(err);
            });
        }
      })
        .then(() => {
          this.loading.close();
          if (fun) {
            fun();
          }
        })
        .catch(err => {
          this.loading.close();
          this.$throw(err);
        });
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      var obj= {
        StartDate: formatDate(new Date(), "yyyy-MM-dd"),
        EndDate: formatDate(new Date(), "yyyy-MM-dd"),
        Sid:"",
        Name: "名称",
        Plan: [
          {
            weekDays: [0, 1, 2, 3, 4, 5, 6],
            startTime: "00:00",
            endTime: "23:59"
          }
        ],
        Progs: []
      };
      var str=JSON.stringify(obj.Plan);
      obj.Plan=str;
      return obj;
    },
    getResetLable() {
      return [
        {
          label: this.$t("名称"),
          type: "text",
          value: "Name",
          require: true
        },
        {
          label: this.$t("长度"),
          type: "number",
          value: "Width",
          require: true
        },
        {
          label: this.$t("高度"),
          type: "number",
          value: "Height",
          require: true
        }
      ];
    },
    submited() {
      this.select();
    },
    deleted() {
      this.select();
    },
      onSubmit(event) {
      event.preventDefault();
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/AddNovaTask", this.modalData, true)
        .then(() => {
          this.$refs.modify.hide();
          this.loading.close();
          this.select();
        })
        .catch(err => {
          this.loading.close();
          this.$refs.modify.hide();
          this.$throw(err);
        });
    },
  },
  mounted: function() {
    this.select();
  }
};
</script>
