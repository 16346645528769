<template>
    <!-- 详情页 -->
    <b-modal id="modallg" ref="modallg" size="lg" v-bind:title="title" hide-footer header-bg-variant="success" body-text-variant="dark" @shown="onModalLoaded">
        <iframe allowtransparency="true" frameborder="0" scrolling="no" style="position: absolute; z-index: -1; width: 100%; height: 100%; top:0; left:0;"></iframe>

        <b-form @submit="onSubmit">
            <b-container>
                <b-row>
                    <!-- 左边 -->
                    <b-col>
                        <p style="text-align:center;">抓拍图像</p>
                        <img v-if="!curItem.PicUrl" src="/img/unknow.png" style="max-width:315px;">
                        <img v-if="curItem.PicUrl" :src="curItem.PicUrl" style="max-width:315px;">
                    </b-col>
                    <!-- 右边 -->
                    <b-col>
                        <div class="chunk_content_1">
                            <div class="left_2">
                                <label class="face1_2_title">抓拍人脸</label>
                                <b-img v-if="!curItem.ImageUrl" class="face1_2" src="/img/unknow.png"></b-img>
                                <b-img v-if="curItem.ImageUrl" class="face1_2" :src="curItem.ImageUrl"></b-img>
                            </div>
                            <div class="middle_2">
                                <div class="similar_num">{{curItem.Similarity*100 || 0 | fix0}}%</div>
                                <h3>相似度</h3>
                            </div>
                            <div class="right_2">
                                <label class="face2_2_title">人脸库</label>
                                <b-img v-if="!curItem.FaceUrl" class="face2_2" src="/img/unknow.png"></b-img>
                                <b-img v-if="curItem.FaceUrl" class="face2_2" :src="curItem.FaceUrl"></b-img>
                            </div>
                        </div>
                        <!-- 人形检测 -->
                        <div class="chunk_title_1" v-if="curItem.Type==0">人形检测</div>
                        <div class="chunk_content_1" v-if="curItem.Type==0">
                            <div class="chunk_item_1">性别: {{curItem.Sex==0?"男":"女" || "未知"}}</div>
                            <div class="chunk_item_1">上衣颜色: {{curItem.JacetColor || "未知"}}</div>
                            <div class="chunk_item_1"> 是否骑车: {{curItem.IsRide?"是":"否"}}</div>
                            <div class="chunk_item_1">是否带帽: {{curItem.IsHat?"是":"否"}}</div>
                            <div class="chunk_item_1">下装类型: {{curItem.TrousersType || "未知"}}</div>
                            <div class="chunk_item_1">下装颜色: {{curItem.TrousersColor || "未知"}}</div>
                            <div class="chunk_item_1">发型: {{curItem.HairStyle || "未知"}}</div>
                            <div class="chunk_item_1">是否拎东西: {{curItem.IsThings?"是":"否"}}</div>
                        </div>
                        <!-- 人脸检测 -->
                        <div class="chunk_title_1" v-if="curItem.Type==1">人脸检测</div>
                        <div class="chunk_content_1" v-if="curItem.Type==1">
                            <div class="chunk_item_1">性别: {{curItem.Sex==0?"男":"女" || "未知"}}</div>
                            <div class="chunk_item_1">年龄: {{curItem.Age}}</div>
                            <div class="chunk_item_1">是否戴眼镜: {{curItem.IsGlass?"是":"否"}}</div>
                        </div>
                        <!-- 人脸对比 -->
                        <div class="chunk_title_1" v-if="curItem.Type==2">人脸对比</div>
                        <div class="chunk_content_1" v-if="curItem.Type==2">
                            <div class="chunk_item_1" :title="curItem.Name">姓名: {{curItem.Name || "未知"}}</div>
                            <div class="chunk_item_1">性别: {{curItem.SexString || "未知"}}</div>
                            <div class="chunk_item_1">年龄: {{curItem.Age || "未知"}}</div>
                            <div class="chunk_item_1">民族: {{curItem.Nation || "未知"}}</div>
                            <div class="chunk_item_2">身份证: {{curItem.IdNumber || "未知"}}</div>
                            <div class="chunk_item_2">住址: {{curItem.Address || "未知"}}</div>
                            <div class="chunk_item_2">备注: {{curItem.Describe || "未知"}}</div>
                        </div>

                        <div class="chunk_title_1"></div>
                        <div class="chunk_content_1">
                            <div class="chunk_item_2">抓拍设备: {{curItem.DeviceName || "未知"}}</div>
                            <div class="chunk_item_2">抓拍时间: {{curItem.DetectTime || "未知"}}</div>
                        </div>

                    </b-col>
                </b-row>
            </b-container>
            <b-form-group>
                <hr>
                <div class="modal-foot">
                    <b-button style="width:100px" v-on:click="hidden()">取消</b-button>
                    <!--<b-button type="submit" style="width:100px" variant="primary">OK</b-button>-->
                </div>
            </b-form-group>
        </b-form>

    </b-modal>
</template>

<style>
    .chunk_content_1 {
        /*min-height: 10%;*/
        /*border: 1px solid red;*/
        margin: 0 15px;
        position: relative;
    }

        .chunk_content_1 .left_1, .chunk_content_1 .right_1 {
            width: 50%;
            display: inline-block;
            position: relative;
            vertical-align: top;
        }

        .chunk_content_1 .face1 {
            width: 161px;
            height: 168px;
            text-align: center;
        }

        .chunk_content_1 .vip {
            position: absolute;
            top: 0;
            background-color: rgba(255, 106, 0,0.5);
            text-align: center;
        }

        .chunk_content_1 .face1_title {
            color: #ff6a00;
            font-size: 1rem;
            display: block;
            padding-left: 45px;
        }

        .chunk_content_1 h3 {
            font-size: 1.5rem;
            color: #000;
            margin-top: 16px;
        }

        .chunk_content_1 .face2 {
            width: 110px;
            height: 115px;
        }

        .chunk_content_1 .face2_title {
            color: #ff6a00;
            font-size: 1rem;
            padding-left: 13%;
            display: block;
        }

        .chunk_content_1 .similar_num {
            background-color: #fff;
            color: darkgreen;
            font-size: 2.5rem;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 80px;
            top: -10px;
            font-weight: 700;
        }

        .chunk_content_1 .chunk_item_1 {
            width: 50%;
            color: #000;
            display: inline-block;
            padding-bottom: 5px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .chunk_content_1 .chunk_item_2 {
            width: 100%;
            color: #000;
            display: inline-block;
            padding-bottom: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .chunk_content_1 .chunk_item_91px {
            width: 91px;
            color: #000;
            display: inline-block;
            margin: 0 10px;
            text-align: center;
        }

        .chunk_content_1 .chunk_toolbar {
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            text-align: right;
        }

        .chunk_content_1 .middle_2 {
            position: absolute;
            left: 33%;
            bottom: -18px;
            z-index: 1;
        }

        .chunk_content_1 .left_2, .chunk_content_1 .right_2 {
            width: 49.3%;
            display: inline-block;
            position: relative;
            vertical-align: top;
            text-align: center;
            /*border:1px solid red;*/
        }

        .chunk_content_1 .face1_2 {
            width: 130px;
            height: 131.14px;
            text-align: center;
        }

        .chunk_content_1 .face1_2_title {
            color: #000;
            font-size: 0.5rem;
            display: block;
            margin-top: 5px;
        }

        .chunk_content_1 .face2_2 {
            width: 130px;
            height: 131.14px;
            text-align: center;
        }

        .chunk_content_1 .face2_2_title {
            color: #ff6a00;
            font-size: 0.5rem;
            display: block;
            margin-top: 5px;
        }

    .modal-foot {
        text-align: right
    }
</style>

<script>
    export default {
        data: function () {
            return {
                msgSid: null,
                curItem: {},
            }
        },
        props: [
            //'msgSid',
            'title',
        ],
        filters: {
            fix0: function (value) {
                value = Number(value);
                return value.toFixed(0);
            }
        },
        methods: {
            show() {
                this.$refs.modallg.show();
            },
            hidden() {
                this.$refs.modallg.hide();
            },
            onSubmit(evt) {
                evt.preventDefault();

            },
            onModalLoaded() {

            },
            detail(sid) {
                if (sid) {
                    this.msgSid = sid;
                }
                if (this.msgSid == null) {
                    throw this.$t("请输入Sid33");
                }
                this.title = this.$t("人脸识别详情");
                //this.loading = this.$loading();
                this.$ksajax
                    .ksPostPromise("/api/face/GetMessageDetail", this.msgSid, true)
                    .then((r) => {
                        this.curItem = r;
                        //this.loading.close();
                        this.$refs.modallg.show();
                    })
                    .catch(err => {
                        this.$throw(err);
                    });
            },

        }
    }
</script>
