<template>
    <div ref="scroll" style="width:100%;height:100%;position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;white-space: nowrap;">
        <span :style="style1" ref="ele1" v-html="data"></span>
        <span :style="style2" ref="ele2"  v-html="data">{{data}}</span>
    </div>
</template>
<script>
export default {
    props:["data","speed"],
    data:function(){return {
        style1:{},
        style2:{},
        scrollTimmer:null,
        position1:0,
        position2:0,
        init:false 
    }},
    watch:{
        "data":function(){
            window.clearInterval(this.scrollTimmer);
            this.style={};
            this.style1={};
            this.style2={};
            this.position1=0;
            this.position2=0;
            this.init=false;
            this.timmer();
        },
        speed:function(){
            window.clearInterval(this.scrollTimmer);
            this.timmer();
        }
    },
    mounted:function(){
        this.$nextTick(()=>{
            this.timmer();
        })
    },
    methods:{
        timmer(){
            this.scrollTimmer=window.setInterval(()=>{
                var ele=this.$refs.scroll;
                var elel1=this.$refs.ele1;
                var parent=ele.parentElement;
                var pwidth=parent.clientWidth;
                if(ele){
                if(pwidth==0){
                    //还没加载元素
                }
                else{
                    if(this.init){
                        //如果已经初始化
                        this.position1-=1;
                        this.position2-=1;
                        var w1=pwidth;
                        var w2=pwidth;
                        if(elel1.clientWidth>pwidth){
                            w1=elel1.clientWidth;
                            w2=elel1.clientWidth;
                        }
                        if(this.position1<-1*w1){
                            this.position1=w1;
                        }
                        if(this.position2<-1*w2){
                            this.position2=w2;
                        }
                        this.style1.left=this.position1+"px";
                        this.style2.left=this.position2+"px";
                        
                         this.style1={
                            position:"absolute",
                            left:this.position1+"px",
                            height:"100%",
                            top:0,
                            "line-height":parent.clientHeight+"px"
                        }
                        this.style2={
                            position:"absolute",
                            left:this.position2+"px",
                            height:"100%",
                            top:0,
                            "line-height":parent.clientHeight+"px"
                        }
                    }else{
                        //如果没有初始化
                        parent.style.position="relative";
                        this.position1=1*pwidth;
                        this.position2=this.position1+elel1.clientWidth;
                        if(elel1.clientWidth<pwidth){
                            this.position2=this.position1+1*this.position1;
                        }
                         this.style1={
                            position:"absolute",
                            left:this.position1+"px",
                            height:"100%",
                            top:0,
                            "line-height":parent.clientHeight+"px"
                        }
                        this.style2={
                            position:"absolute",
                            left:this.position2+"px",
                            height:"100%",
                            top:0,
                            "line-height":parent.clientHeight+"px"
                        }
                        this.init=true;
                    }
                    }
                }
                else{
                    this.style={};
                    this.style1={};
                    this.style2={};
                    this.position1=0;
                    this.position2=0;
                    this.init=false;
                    if(this.scrollTimmer){
                        window.clearInterval(this.scrollTimmer);
                    }
                }
            },this.speed)
        }
        
    },
    destroyed:function(){
            window.clearInterval(this.scrollTimmer);
    }
}
</script>
