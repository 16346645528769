<template>
  <div class="white">
    <actionbar
      v-bind:select="false"
      :insert="true"
      v-bind:modify="false"
      :del="true"
      v-on:insert="insert"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
    >
    </actionbar>

    <el-table
      :data="items"
      stripe=""
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column :label="$t('序号')" type="index"></el-table-column>
      <el-table-column
        :label="$t('设备名称')"
        prop="DeviceName"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        :label="$t('序列号')"
        prop="Serial"
      ></el-table-column>
      <el-table-column
        :label="$t('设备型号')"
        prop="VendorType"
      ></el-table-column>
      <el-table-column :label="$t('状态')" prop="Status">
        <template slot-scope="scope">
          <span v-if="scope.row.Status == 1">{{ $t("在线") }}</span>
          <span v-if="scope.row.Status == 0">{{ $t("离线") }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('AiKit事件')" prop="Status">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.AiKitStatusFlag"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="elswitch(scope.row.Serial, scope.row.AiKitStatus)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column :label="$t('位置')" prop="Address"></el-table-column>
      <el-table-column :label="$t('备注')" prop="Describe" >
        <template slot-scope="scope">
          <span v-if="scope.row.Describe">{{ scope.row.Describe }}</span>
          <span v-if="!scope.row.Describe">-</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('查看')" prop="">
        <template slot-scope="scope">
          <router-link
            :to="`/admin/Face/DevView2?sid=${scope.row.Sid}&isLocal=false`"
            >{{ $t("统计信息") }}</router-link
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      layout=" prev, pager, next, total,sizes"
      @current-change="current_change"
      @size-change="pagesize_change"
      :current-page="currentPage"
      :page-sizes="[10, 50, 100, 200]"
      :page-size="pagesize"
      :total="total"
      background
    >
    </el-pagination>

    <!-- 新增、修改对话框 -->
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="modalTitle"
      body-text-variant="dark"
      @ok="publishStage"
      @shown="publishShown"
    >
      <el-table
        ref="multipleTable"
        :data="devices"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :empty-text="$t('暂无数据')"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column :label="$t('序号')" type="index"></el-table-column>
        <el-table-column
          :label="$t('设备名称')"
          prop="DeviceName"
        ></el-table-column>
        <el-table-column :label="$t('序列号')" prop="Serial"></el-table-column>
        <el-table-column
          :label="$t('设备型号')"
          prop="VendorType"
        ></el-table-column>
        <el-table-column :label="$t('状态')" prop="Status">
          <template slot-scope="scope">
            <span v-if="scope.row.Status == 1">{{ $t("在线") }}</span>
            <span v-if="scope.row.Status == 0">{{ $t("离线") }}</span>
          </template>
        </el-table-column>
      </el-table>
    </b-modal>

    <!-- 删除多好看 -->
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/device/SetAiKitVideo"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>

<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
import alarmAction from "@/components/alarmAction";
export default {
  components: { actionbar, tablepage, modify, deletedata, alarmAction },
  data: function () {
    return {
      radio: 0,
      modalTitle: this.$t("选择设备"),
      loading: {},
      items: [],
      allItems: [],
      total: 0, // 默认数据总数
      pagesize: 10, // 每页的数据条数
      currentPage: 1, // 默认开始页面

      devices: [], // 待添加设备
      deviceSlected: [], // 已选设备列表

      index: 0,
      sid: "",
      current: null,
    };
  },
  methods: {
    // 获取设备列表
    select(noloading, index) {
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      new Promise((res, rej) => {
        let obj = {
          Index: this.currentPage - 1,
          Count: this.pagesize,
          IsBinding: true, // 是否绑定AiKit任务
        };
        this.$ksajax
          .ksPostPromise("/api/device/GetAiKitVideos", obj, true)
          .then((data) => {
            data.Items.forEach((x) => {
              x.AiKitStatusFlag = x.AiKitStatus == 1 ? true : false;
            });
            this.items = data.Items;
            this.total = data.TotalCount;
            res();
          })
          .catch((err) => {
            rej(err);
          });
      })
        .then(() => {
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.select();
    },
    pagesize_change: function (pagesize) {
      this.pagesize = pagesize;
      this.currentPage = 1;
      this.select();
    },
    // 新增对话框
    insert() {
      this.loading = this.$loading();
      new Promise((res, rej) => {
        let obj = {
          Index: 0,
          Count: 1000,
          IsBinding: false, // 是否绑定AiKit任务
        };
        this.$ksajax
          .ksPostPromise("/api/device/GetAiKitVideos", obj, true)
          .then((data) => {
            this.devices = data.Items;
            this.loading.close();
            res();
            this.$refs.modallg.show();
          })
          .catch((err) => {
            rej(err);
            this.$throw(err);
            this.loading.close();
          });
      });
    },
    // 隐藏对话框
    hiden(name) {
      if (!name) {
        this.$refs.modallg.hide();
      } else {
        this.$refs[name].hide();
      }
    },
    // 提交对话框
    onSubmit(evt) {
      evt.preventDefault();
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/face/AddFaceSet", this.modalData, true)
        .then(() => {
          this.select();
          this.loading.close();
          this.hiden();
        })
        .catch((err) => {
          this.$throw(err);
          this.hiden();
        });
    },

    // 删除集合
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = {
        Serial: this.current.Serial,
        IsBinding: false,
      };
      this.$refs.del.show();
    },
    deleted() {
      this.select();
    },

    // 修改对话框
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.modalTitle = this.$t("修改人脸库");
      if (this.current) {
        this.modalData = this.current;
      } else {
        this.modalData = {
          Sid: "",
          Name: "",
          Sex: "",
          Type: "",
          Nation: "",
          Birthday: "",
          Address: "",
          IdNumber: "",
          IsEnabled: true,
          VoiceText: "",
          Describe: "",
        };
      }

      this.$refs.modallg.show();
    },
    currentRow(val) {
      this.current = val;
    },

    submited() {
      this.select();
    },
    // 查看详情
    detail() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.$router.push(
        "/admin/face/detail?sid=" +
          this.current.Sid +
          "&setname=" +
          this.current.Name
      );
    },
    // 对话框OK
    publishStage() {
      this.loading = this.$loading({ fullScreen: true });
      var data = {
        Serial: "",
        IsBinding: true,
      };
      if (this.deviceSlected && this.deviceSlected.length > 0) {
        this.deviceSlected.forEach((x) => {
          this.loading = this.$loading({ fullScreen: true });
          data.Serial = x.Serial;
          this.$ksajax
            .ksPostPromise("/api/device/SetAiKitVideo", data, true)
            .then(() => {
              this.loading.close();
              this.hiden();
              this.select();
            })
            .catch((err) => {
              this.loading.close();
              this.hiden();
              this.$throw(err);
            });
        });
      }
    },
    // 加载数据
    publishShown() {},
    // 选择操作
    handleSelectionChange(val) {
      this.deviceSlected = val;
    },
    // switch change 事件
    elswitch(serial, aiKitStatus) {
      let obj = {
        Serial: serial,
        AiKitStatus: aiKitStatus == 1 ? 0 : 1, // 是否绑定AiKit任务
      };
      this.$ksajax
        .ksPostPromise("/api/device/SetAiKitStatus", obj, true)
        .then(() => {
          this.select();
        })
        .catch((err) => {
          this.$throw(err);
        });
    },
  },
  mounted: function () {
    this.select();
  },
};
</script>
