<template>
  <div
    class="white"
    v-loading="loading"
    style="position: relative; height: 100%"
  >
    <div style="position: absolute; right: 20px; top: 10px; z-index: 999">
      <i
        class="fa fa-table fa-2x"
        style="cursor: pointer; color: 8495c2"
        @click="tabled"
      ></i>
    </div>
    <ksmap
      v-show="map"
      ref="map"
      width="100%"
      :showCenter="false"
      height="100%"
      v-bind:lat="0"
      v-bind:lng="0"
      v-bind:zoom="25"
    >
    </ksmap>
    <div v-show="!map">
      <el-table
        class="kstable"
        :data="items"
        stripe=""
        style="width: 100%"
        highlight-current-row
        :empty-text="$t('暂无数据')"
      >
        <el-table-column
          prop="DeviceName"
          :label="$t('设备名称')"
        ></el-table-column>
        <el-table-column
          prop="ModeName"
          :label="$t('工作模式')"
        ></el-table-column>
        <el-table-column
          prop="DeviceVideoName"
          :label="$t('视频设备')"
        ></el-table-column>

        <el-table-column prop="Code" :label="$t('验证码')"></el-table-column>
        <el-table-column
          prop="DeviceVideoSerial"
          :label="$t('摄像头序列号')"
        ></el-table-column>
        <el-table-column prop="Ip" :label="$t('Ip地址')"></el-table-column>
        <el-table-column prop="Port" :label="$t('端口')"></el-table-column>
        <el-table-column
          prop="Username"
          :label="$t('用户名')"
        ></el-table-column>
        <el-table-column prop="Password" :label="$t('密码')"></el-table-column>

        <el-table-column :label="$t('详情')">
          <template slot-scope="scope">
            <router-link :to="'/admin/roadStud/details?sid=' + scope.row.Sid">{{
              $t("详情")
            }}</router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import ksmap from "@/components/map";
import { BaseUrl } from "@/assets/utils/config";
export default {
  components: { ksmap },
  data: function () {
    return {
      loading: true,
      map: true,
      items: [],
    };
  },
  mounted: function () {
    this.select();
  },
  methods: {
    tabled() {
      this.map = !this.map;
      if (this.map) {
        this.$refs.map.setFilter(0);
      }
    },
    select() {
      let obj = {
        Index: 0,
        Count: 100000,
      };
      this.$ksajax
        .ksPostPromise("/api/roadstud/GetRoadStudList", obj, true)
        .then((data) => {
          this.loading = false;
          var device = [];
          this.items = data.Items;
          this.items.forEach((element) => {
            if (element.Mode === 0) {
              element.ModeName = "关闭";
            } else if (element.Mode === 1) {
              element.ModeName = "闪烁";
            } else if (element.Mode === 2) {
              element.ModeName = "常亮";
            } else if (element.Mode === 3) {
              element.ModeName = "强闪烁";
            }
          });
          data.Items.forEach((x) => {
            device.push({
              lat: x.Lat,
              lng: x.Lng,
              icon: `${BaseUrl}/img/new/daoding.png`,
              url: "/admin/roadStud/details?sid=" + x.Sid,
              title: x.DeviceName,
            });
          });
          this.$refs.map.addDevice(device, 0);
        })
        .catch((err) => {
          this.$throw(err, this);
          this.loading = false;
        });
    },
  },
};
</script>
