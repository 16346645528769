<template>
    <div style="height:100%;width:100%">
    <div class="ks_box_1 ksflexcol" style="position:relative" v-if="!show">
        <div style="width:100%;height:15px" class="ksflex box_boder_right">
            <div style="height:100%;width:15px" :class="this.trans?'border_angel_l_tran':'border_angel_l'"></div>
            <div :class="['box_boder_top',this.trans?'box_tran':'box_tran_n']" style="height:100%;flex:1;"></div>
        </div>
        <div :class="['box_boder_right', 'box_boder_left', 'hiden_scroll',this.trans?'box_tran':'box_tran_n']" style="width:100%;flex:1;overflow:auto;">
            <slot></slot>
        </div>
        <div style="width:100%;height:15px" class="ksflex box_boder_left">
            <div  :class="['box_boder_bottom',this.trans?'box_tran':'box_tran_n']"  style="height:100%;flex:1;"></div>
            <div style="height:100%;width:15px" :class="this.trans?'border_angel_tran':'border_angel'"></div>
        </div>
        <div class="box_title" v-if="title">
            {{title}}
        </div>
        <div style="position:absolute;right:15px;top:1px;font-size:12px;text-decoration: underline;cursor: pointer;" @click="actionEvent">
            <span>{{action}}</span>
        </div>
    </div>
    <div v-else style="width:100%;height:100%">
        <box>
            <slot></slot>
        </box>
    </div>
    </div>
</template>
<style scoped>
.ks_box_1 .box_boder_bottom{
    border-bottom: 1px solid #3274b8;
}
.ks_box_1 .box_boder_left{
    border-left: 1px solid #3274b8;
}
.ks_box_1 .box_boder_right{
    border-right: 1px solid #3274b8;
}
.ks_box_1 .box_boder_top{
    border-top: 1px solid #3274b8;
}
.border_angel{
  background:linear-gradient(-45deg,transparent 48%, #3274b8 48%, rgb(50, 116, 184) 52%, rgba(2, 43, 126, 0.31) 52%)
}
.border_angel_l{
  background:linear-gradient(-45deg,rgba(2, 43, 126, 0.31) 48%, #3274b8 48%, #3274b8 52%, transparent 52%)
}

.border_angel_tran{
  background:linear-gradient(-45deg,transparent 48%, #3274b8 48%, #3274b8 52%, #022c7d 52%)
}
.border_angel_l_tran{
  background:linear-gradient(-45deg,#022c7d 48%, #3274b8 48%, #3274b8 52%, transparent 52%)
}
.box_tran{
    background-color:  #022c7d;
}
.box_tran_n{
    background-color:  rgba(2, 44, 125, 0.31);
}
</style>
<script>
import config from "../assets/utils/config"
import box from "../components/box"
export default {
    components:{box},
    props:["trans","title","action"],
    mounted:function(){
        if(!this.trans){
            this.angelStyle="border_angel_tran",
            this.angelLeftStyle="border_angel_l_tran"
        }
    },
    data:function(){
        return{ 
            bg:require("@/assets/img/nstyle/box_smal.png"),
            angelStyle:"border_angel",
            angelLeftStyle:"border_angel_l",
            show:!config.ISNETURE&&config.Theme==0
        }
    },
    methods:{
        actionEvent(){
            this.$emit("actionEvent");
        }
    }
}
</script>
