<template>
    <div class="ks_box">
        <div class="lt ksborder"></div>
        <div class="rt ksborder"></div>
        <div class="lb ksborder"></div>
        <div class="rb ksborder"></div>
        <div class="ks_box_content ksflexcol">
            <div class="ks_box_content_title">
                <div class="row ksrow" style="padding: 5px 0 5px 0">
                    <div class="col">{{data?data.title:"Title"}}</div>
                    <div class="col" style="text-align:right">
                        <el-button v-on:click="$emit('titleClick')"  v-if='data.style' type="primary"  round  size="small">
                            <i :class="data?data.style:'fa fa-windows'"></i>{{data?data.action:"action"}}  
                        </el-button>
                    </div>
                </div>
                <hr>
            </div>
            <div class="ks_box_content_content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<style scoped>
.ks_box_content{
    height: 100%;
}
.ks_box_content_title{
    width:100%;
    color: white;
    font-size: 20px;
}
.ks_box_content_content{
    flex: 1;
    width: 100%;
    overflow: auto;
}
.ks_box{
width:100%;height:100%;position:relative;
border: 1px solid #3274b8
}
.ks_box .ksborder{
    position:absolute;
    width: 20px;
    height: 20px;
}
.ks_box .lt{
    left: -1px;
    top: -1px;
    border-top: 1px solid #00e2fb;
    border-left: 1px solid #00e2fb;
}
.ks_box .rt{
    right: -1px;;
    top: -1px;;
    border-top: 1px solid #00e2fb;
    border-right: 1px solid #00e2fb;
}
.ks_box .lb{
    left: -1px;;
    bottom: -1px;;
    border-bottom: 1px solid #00e2fb;
    border-left: 1px solid #00e2fb;
}
.ks_box .rb{
    right: -1px;;
    bottom: -1px;;
    border-bottom: 1px solid #00e2fb;
    border-right: 1px solid #00e2fb;
}
</style>

<script>
export default {
    props:["data"]
}
</script>
