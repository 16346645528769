<template>
  <div class="main">
    <div>
      <div class="main_menu">
        <div class="mainmenu_left">
          <router-link to="/login">
            <b-img :src="logopic" fluid style="padding-left:10%;"></b-img>
          </router-link>
        </div>
        <div class="ksflex mainmenu_center" style="flex:1;white-space: nowrap;"></div>
        <div class="mainmenu_right;" style="text-align:right;flex:1">
          <el-radio-group
            v-model="lang"
            size="mini"
            fill="black"
            style="margin-right:20px"
            @change="lanChange"
          >
            <el-radio-button value="cn" label="zh"></el-radio-button>
            <el-radio-button value="en" label="en"></el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="login">
        <b-container>
          <b-row>
            <b-col cols="12" md="8" lg="6" offset-lg="3" offset-md="2">
              <div class="login-form">
                <b-form @submit="onSubmit" v-if="show">
                  <b-form-group
                    id="exampleInputGroup1"
                    v-bind:label="$t('用户名')"
                    label-for="exampleInput1"
                  >
                    <b-form-input
                      id="exampleInput1"
                      type="text"
                      v-model="form.UserName"
                      required
                      v-bind:placeholder="$t('请输入用户名')"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="exampleInputGroup2"
                    v-bind:label="$t('密码')"
                    label-for="exampleInput2"
                  >
                    <b-form-input
                      id="exampleInput2"
                      type="password" 
                      v-model="form.Password"
                      required
                      v-bind:placeholder="$t('请输入密码')"
                    ></b-form-input>
                  </b-form-group>
                  <b-button type="submit" variant="primary" style="width:100%">{{$t('登录')}}</b-button>
                </b-form>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<style scoped>
body {
  width: 100% !important;
  height: 100% !important;
}
</style>

<script>
export default {
  components: {},
  data() {
    return {
      form: {
        UserName: "",
        Password: ""
      },
      show: true,
      logopic: require("../assets/img/cute/logo.png"),
      lang: "zh",
      loading:false
    };
  },
  methods: {
    lanChange() {
      //先切换语言
      this.$i18n.locale = this.lang;
      this.$cookies.set("lan", this.$i18n.locale, 365);
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.loading=this.$loading();
      new Promise((res, rej) => {
        var data = JSON.stringify(this.form);
        this.$ksajax
          .ksPostPromise("/api/smart/login", data, true)
          .then(success => {
            this.$cookies.set("token", success.Token, 1);
            this.$store.commit("setLogin", this.form);
            this.$cookies.set("username", this.form.UserName);
            this.$router.push("/home");
            res()
          })
          .catch(err => {
            rej(err);
          });
      }).then(()=>{
          return new Promise((res,rej)=>{
              this.$ksajax.ksPostPromise("/api/smart/getProject", "", true).then(success=>{
                this.$store.commit("setProject",success);
                res();
              }).catch(err=>{
                rej(err);
              })
          })
      }).then(()=>{
        this.loading.close();
      }).catch(err=>{
        this.loading.close();
        this.$throw(err);
      })
      // alert(JSON.stringify(this.form));
      // var data = JSON.stringify(this.form);
      // this.$ksajax.ksPostData("/api/smart/login", data, null, success => {
      //   this.$cookies.set("token", success.Token, 1);
      //   this.$store.commit("setLogin", this.form);
      //   this.$cookies.set("username", this.form.UserName);
      //   this.$router.push("/home");
      // });
    }
  },
  mounted: function() {
    this.lang = this.$cookies.get("lan");
  }
};
</script>

