<template>
    <div  style="width:100%">
         <div class="nhome_main_header" style="width:100%">
      <b-navbar
        toggleable="lg"
        type="dark"
        variant="dark"
        :style="tran?'background-color: transparent !important; padding-left:0px':'background-color:#1d1e23 !important'"
      >
        <b-navbar-brand >
          <router-link to="/admin/home">
          <b-img :src="$store.state.project.Logo" style="height:35px"></b-img></router-link>
          <span v-show="tran" style="padding-left:15px;font-size:20px">{{$store.state.project.ProjectName}}</span>
        <i v-show="!tran" class="fa fa-th-list" style="margin-left:20px;color:rgba(255, 255, 255, 0.5);cursor: pointer;line-height:35px"
         @click="$emit('slider-click')"></i>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav></b-navbar-nav>
          <b-navbar-nav class="ml-auto">
              
            <b-nav-item>
              <router-link to="/admin/setting">
                <i style="font-size:20px;color:white" class="fa fa-cog fa-fw"></i>
              </router-link>
            </b-nav-item>
            <b-nav-item>
              <router-link to="/admin/alarm">
                <i style="font-size:20px;color:white" class="fa fa-bell-o fa-fw"></i>
              </router-link>
            </b-nav-item>
            <b-nav-item-dropdown right>
              <template slot="button-content">
                  <i class="fa fa-user-circle-o"></i>
                <em>{{userName}}</em>
              </template>
              <b-dropdown-item to="/admin/setting/password">{{$t('修改密码')}}</b-dropdown-item>
              <b-dropdown-item @click="logout">{{$t('安全退出')}}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <el-radio-group
            v-model="lang"
            size="mini"
            fill="black"
            style="margin-left:20px"
            @change="lanChange"
          >
            <el-radio-button value="cn" label="zh"></el-radio-button>
            <el-radio-button value="en" label="en"></el-radio-button>
          </el-radio-group>
        </b-collapse>
      </b-navbar>
    </div>
    </div>
</template>
<style scoped>
.navbar-expand-lg{
  z-index: 100;
}
</style>
<script>
export default {
    props:{
        tran:true
    },
     data() {
    return {
      lang:"zh",
      menu:[],
      userName:this.$cookies.get("username")
    };
  },
  mounted:function(){
     var lan = this.$cookies.get("lan");
     this.lang=lan;
    this.$store.commit("setLan", lan);
  },
  methods:{
    
      logout() {
      //删除cookie
      this.$cookies.set("token", null, 0);
      if(this.$route.path.toLowerCase()=="/admin/nhome"){
        this.$router.push({ path: "/info/home?reload=true" });
      }else{
      this.$router.push({ path: "/info/login?reload=true" });}
    },
    lanChange() {
      //先切换语言
      this.$i18n.locale = this.lang;
      this.$cookies.set("lan", this.$i18n.locale, 365);
      this.$store.dispatch(
        "menuAction",
        () => {
          this.init();
        },
        (err) => {
          this.$throw(err);
        }
      );
    }
  }
}
</script>