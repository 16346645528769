<template>
  <div ref="piechart" style="width: 100%; height: 100%"></div>
</template>
<script>
export default {
  data: function () {
    return {
      chart: null,
      data: null,
      legend: null,
      color: null,
      emphasis: null,
      xdata: null,
      title:null,
      xdataV:false,
      barWidth:null
    };
  },
  methods: {
    getOption() {
      var opt = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "line", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        title:this.title,
        legend: this.legend,
        series: [],
        grid: {
          left: "3%",
          right: "3%",
          bottom: "0%",
          top: this.title&&this.title.show?"20%":"5%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
            splitLine: {
            show: true,
            lineStyle:{
                opacity:0.2,
                type:"dashed"
            }
            },
        },
        xAxis: {
          type: "category",
          data: this.xdata,
            splitLine: {
            show: true,
            lineStyle:{
                opacity:0.2,
                type:"dashed"
            }
            },
          axisLabel: {
            formatter:  (value)=> {
              var ret = value.split("");
              if (ret.length > 5) {
                ret = ret.slice(0, 5);
              }
              var r = ret.join("\n");
              if(!this.xdataV){
                return r;
              }else{
                return value;
              }
            },
            fontSize: 10,
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        color: this.color,
        textStyle: {
          color: "#fff",
        },
      };
      //   opt.title = this.pietitle;
      this.data.forEach((x) => {
        opt.series.push({
          name: x.name,
          type: "bar",
          stack: "总数",
          barWidth:this.barWidth,
          lable: {
            show: true,
            position: "insideRight",
          },
          data: x.data,
        });
      });
      return opt;
    },
    setAndInit(data, legend, color, emphasis, xdata,title,xdataV,barWidth) {
      this.barWidth=barWidth;
      this.xdataV=xdataV;
      this.title=title;
       if(this.title){
         if(this.title.textStyle){
           this.title.textStyle.color="#fff";
         }else{
          this.title.textStyle={color:"#fff"};
         }
        }
      this.data = data;
      this.legend = legend;
       if(this.legend){
          this.legend.textStyle={color:"#fff"};
        }
      this.color = color;
      this.emphasis = emphasis;
      this.xdata = xdata;
      this.init();
    },
    init() {
      if(!this.chart){
        this.chart = window.echarts.init(this.$refs.piechart);
      }else{
        this.chart.clear();
      }
      var obj = this.getOption();
      this.chart.setOption(obj);
      window.charts=window.charts||[];
      window.charts.push(this.chart);
      window.addEventListener("resize",()=>{
        this.chart.resize();
      });
      return this.chart;
    },
  },
};
</script>