<template>
    <div class="white">
        <actionbar v-bind:select='true' v-bind:modify='true' :del="true" :insert="true" :publish="true" :sync="true"
        v-on:select='select'
        v-on:modify='modify'
        v-on:delete='del'
        v-on:insert="insert"
        v-on:publish="publish"
        v-on:sync="sync"
        >
        </actionbar>
          <el-table stripe=""
            :data="items"
            style="width: 100%"
            highlight-current-row
            @current-change="currentRow" :empty-text="$t('暂无数据')">
                <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
                <el-table-column :label="$t('高度')" prop="Height"></el-table-column>
                <el-table-column :label="$t('宽度')" prop="Width"></el-table-column>
                <el-table-column :label="$t('时长')+'(秒)'" prop="Duration"></el-table-column>
                <el-table-column :label="$t('状态')">
                    <template slot-scope="scope">
                        {{scope.row.Publish==0?'未发布':'已发布'}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('预览')">
                    <template slot-scope="scope">
                        <b-button variant="success" size="sm" @click="view(scope.row)">{{$t('预览')}}</b-button>
                    </template>
                </el-table-column>
          </el-table>
         <b-modal id="infomodal" class="ksmodal" ref="modallg" size="lg" v-bind:title="title" hide-footer
         header-bg-variant="success"
         body-text-variant="dark">
         <el-steps :active="active" finish-status="success">
            <el-step :title="this.$t('基础信息')"></el-step>
            <el-step :title="this.$t('显示资源')"></el-step>
            <el-step :title="this.$t('完成')"></el-step>
            </el-steps>
            <hr>
            <b-form @submit="onSubmit" ref="ledform" class="needs-validation">
                <div v-if="active==0">  
                     <b-container v-if="active==0">
                        <b-row>
                            <b-col cols="12" md="6">
                    <b-form-group v-bind:label="$t('名称')" :label-cols="2">
                        <b-form-input type="text" v-model="modalData.Name" required></b-form-input>
                    </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                
                    <b-form-group v-bind:label="$t('时长')" :label-cols="2">
                        <b-form-input type="number" step="1" min="5" v-model="modalData.Duration" required></b-form-input>
                    </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                
                    <b-form-group v-bind:label="$t('高度')" :label-cols="2">
                        <b-form-input type="number" step="1" min="1" v-model="modalData.Height" required></b-form-input>
                    </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                
                    <b-form-group v-bind:label="$t('宽度')" :label-cols="2">
                        <b-form-input type="number" step="1" min="1" v-model="modalData.Width" required></b-form-input>
                    </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">

                    <b-form-group v-bind:label="$t('入场动画')" :label-cols="2">
                        <b-form-select :options="inAnimate" v-model="modalData.InAnimate" required> </b-form-select> 
                    </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                
                    <b-form-group v-bind:label="$t('入场时长')" :label-cols="2">
                        <b-form-input type="number" step="1" min="1" v-model="modalData.InTimmer" required></b-form-input>
                    </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                
                    <b-form-group v-bind:label="$t('出场动画')" :label-cols="2">
                        <b-form-select :options="outAnimate" v-model="modalData.OutAnimate" required> </b-form-select> 
                    </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                
                    <b-form-group v-bind:label="$t('出场时长')" :label-cols="2">
                        <b-form-input type="number" step="1" min="1" v-model="modalData.OutTimmer" required></b-form-input>
                    </b-form-group>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
                <div v-if="active==1">
                         <el-select
                v-model="progs"
                multiple
                style="margin-bottom:10px; width:100%"
                :placeholder="$t('请选择')">
                <el-option
                v-for="item in options"
                :key="item.Sid"
                :label="item.Name"
                :value="item.Sid">
                </el-option>
            </el-select>
            <div style="overflow:auto">
            <div :style="style" ref="ledc">
                <template v-for="item in modalData.StateCotnents">
                    <vue-draggable-resizable :parent="true" 
                    v-on:resizing="onResizing"
                    v-on:dragging="onDrag"
                    v-on:activated="onActive(item.LedResourceSid)"
                    v-on:deactivated="onDeActive(item.LedResourceSid)"
                    :resizable="true" :x="item.X" :y="item.Y" :z="item.Z" :w="item.Width" 
                    :h="item.Height" 
                    :key="item.Sid">
                    <div v-if="item.Data.Type==0" :style="item.Custom">
                        <input v-model="item.Data.Content" class="soninput" @mousedown="mousedown" />
                    </div>
                    <div v-if="item.Data.Type==1" :style="item.Custom">
                        <b-img class="autoimg" :src="item.Data.Content"></b-img>
                    </div>
                    <div v-if="item.Data.Type==2" :style="item.Custom">
                        <div>{{item.Data.Name}}</div>
                    </div>
                    <div v-if="item.Data.Type==3" :style="item.Custom">
                        <textscroll :data="item.Data.Content" :speed="item.Custom.speed"></textscroll>
                    </div>
                        <el-carousel v-if="item.Data.Type==4" trigger="click" style="height:100%;">
                            <el-carousel-item v-for="e in JSON.parse(item.Data.Content)" :key="e">
                                <div :style="item.Custom">
                                    <b-img class="autoimg" :src="e"></b-img>
                                </div>
                    </el-carousel-item>
                    </el-carousel>
                    <sensorinfo v-if="item.Data.Type==5" :data="item" :compute="false"></sensorinfo>
                    <div v-if="item.Data.Type==6" :style="item.Custom">
                         {{item.Data.Name}}
                    </div>
                    <div v-if="item.Data.Type==7" :style="item.Custom">
                         {{item.Data.Name}}
                    </div>
                    <div v-if="item.Data.Type==8" :style="item.Custom">
                         0%
                    </div>
                    <div v-if="item.Data.Type==9" :style="item.Custom">
                        {{item.Data.Content}}
                    </div>
                    </vue-draggable-resizable>
                </template>
            </div>
            </div>
            <div  class="stage_content">
                <div style="width:100px;float:left">
                    <div>{{$t('字体')}}</div>
                <b-form-select :options="fontFamily" v-model="subStyle['font-family']" required  v-b-tooltip.hover :title="$t('字体')" > </b-form-select> 
                </div>
                <div style="width:100px;float:left;">
                    <div>{{$t('字体大小')}}</div>
                <b-form-select :options="fontSize" v-model="subStyle['font-size']" required  v-b-tooltip.hover :title="$t('字体大小')" style="width:100px"> </b-form-select> 
                </div>
                <div style="width:100px;float:left;">
                    <div>{{$t('边框')}}</div>
                <b-form-select v-model="subStyle['border']" required v-b-tooltip.hover :title="$t('字体')" style="width:100px">
                        <option value="none">{{$t('无边框')}}</option>
                        <option value="1px solid black">{{$t('黑色')}}</option>
                        <option value="1px solid white">{{$t('白色')}}</option>
                        <option value="1px solid red">{{$t('红色')}}</option>
                        <option value="1px solid green">{{$t('绿色')}}</option>
                        <option value="1px solid blue">{{$t('蓝色')}}</option>
                    </b-form-select> 
                </div>
                <div style="width:100px;float:left;">
                    <div>{{$t('斜体')}}</div>
                <b-form-select v-model="subStyle['font-style']" required v-b-tooltip.hover :title="$t('斜体')" style="width:100px">
                        <option value="normal">{{$t('正常')}}</option>
                        <option value="italic">{{$t('斜体')}}</option>
                    </b-form-select> 
                </div>
                <div style="width:100px;float:left;">
                    <div>{{$t('粗体')}}</div>
                    <b-form-select v-model="subStyle['font-weight']" required v-b-tooltip.hover :title="$t('粗体')" style="width:100px">
                        <option value="normal">{{$t('正常')}}</option>
                        <option value="bold">{{$t('粗体')}}</option>
                    </b-form-select> 
                </div>
                <div style="width:100px;float:left;">
                    <div>{{$t('文字方向')}}</div>
                    <b-form-select v-model="subStyle['writing-mode']" required v-b-tooltip.hover  :title="$t('文字方向')" style="width:100px">
                        <option value="horizontal-tb">{{$t('横向')}}</option>
                        <option value="vertical-lr">{{$t('竖向')}}</option>
                    </b-form-select> 
                </div>
                <div style="width:100px;float:left;">
                    <div>{{$t('文字对齐')}}</div>
                    <b-form-select v-model="subStyle['text-align']" required v-b-tooltip.hover  :title="$t('文字对齐')" style="width:100px">
                        <option value="left">{{$t('左')}}</option>
                        <option value="right">{{$t('右')}}</option>
                        <option value="center">{{$t('居中')}}</option>
                    </b-form-select> 
                </div>
                <div style="width:100px;float:left;">
                    <div>{{$t('垂直居中')}}</div>
                    <b-form-select  v-model="subStyle['display']" v-b-tooltip.hover :title="$t('垂直居中')" style="width:100px">
                        <option value="flex">{{$t('是')}}</option>
                        <option value="block">{{$t('否')}}</option>
                    </b-form-select> 
                </div>
                <div style="width:100px;float:left;">
                    <div>{{$t('自动换行')}}</div>
                    <b-form-select  v-model="subStyle['white-space']" v-b-tooltip.hover  :title="$t('自动换行')"  style="width:100px">
                        <option value="normal">{{$t('是')}}</option>
                        <option value="nowrap">{{$t('否')}}</option>
                    </b-form-select> 
                </div>
                <div  style="width:100px;float:left;">
                    <div>{{$t('滚动速度')}}</div>
                    <b-form-select  v-model="subStyle['speed']" v-b-tooltip.hover  :title="$t('滚动速度')"  style="width:100px">
                        <option value="40">{{$t('慢速')}}</option>
                        <option value="20">{{$t('正常')}}</option>
                        <option value="15">{{$t('快速')}}</option>
                        <option value="10">{{$t('极快')}}</option>
                    </b-form-select> 
                </div>
                <div style="width:100px;float:left;">
                    <div>{{$t('背景颜色')}}</div>
                    <el-color-picker v-model="subStyle['background-color']"></el-color-picker>
                </div>
                <div style="width:100px;float:left;">
                    <div>{{$t('字体颜色')}}</div>
                    <el-color-picker v-model="subStyle['color']"></el-color-picker>
                </div>
                <div style="width:100px;float:left;">
                    <div>{{$t('上移一层')}}</div>
                        <el-button type="danger" @click="layUp"  icon="el-icon-arrow-up" circle></el-button>
                </div>
                <div style="width:100px;float:left;">
                    <div>{{$t('下移一层')}}</div>
                        <el-button type="danger" @click="layDown" icon="el-icon-arrow-down" circle></el-button>
                </div>
                    <div class="clear"></div>
            </div>
                </div>
                <b-form-group>
                    <hr>
                    <b-container>
                        <b-row>
                            <b-col cols="4">
                                <b-button :disabled="active==0" @click="step(true)">{{$t('上一步')}}</b-button>
                            </b-col>
                            <b-col cols="4"></b-col>
                            <b-col cols="4" style="text-align:right">
                                <b-button type="submit" v-if="active!=2">{{active==0?$t("下一步"):$t("提交")}}</b-button>
                                </b-col>
                        </b-row>
                    </b-container>
                </b-form-group>
            </b-form>
         </b-modal>
        <deletedata ref="del"
            v-on:deleted="deleted"
            :sid="sid"
            url="/api/led/DelLedState"
            :title="$t('删除')"
        ></deletedata>
        <b-modal id="ledmodal" class="ksmodal"  ref="ledmodal" size="lg" :title="$t('预览')"  body-text-variant="dark">
            <div style="overflow:auto;">
                <ledtemplete v-bind:modalData="viewData" ref="ledtemp"></ledtemplete>
            </div>
        </b-modal>
        <b-modal id="publishmodal" class="ksmodal"  ref="publishmodal" size="lg" title="$t('发布')"  body-text-variant="dark" @ok="publishStage" @shown="publishShown">
            <span class="kstitle">{{$t("LED发布大小")}}</span>
            <hr>
            <el-table 
            ref="multipleTable"
            :data="devices"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange" :empty-text="$t('暂无数据')"
            > 
            <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column :label="$t('名称')" prop="DeviceName"></el-table-column>
              <el-table-column :label="$t('宽度')" prop="Width"></el-table-column>
              <el-table-column :label="$t('高度')" prop="Height"></el-table-column>
              <el-table-column :label="$t('位置')" prop="Address"></el-table-column>
            </el-table>
        </b-modal>
    </div>
</template>
<style>
.soninput{
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    background: inherit;
    color: inherit;
    background-color:inherit;
    writing-mode: inherit;
    width: 100%;
    height: 100%;
    margin: 5%;
    text-align: inherit
}
.temps{
            width:200px;
                float: left;
                margin: 5px;
                
}
.temps.active{
    box-shadow: 10px 10px 5px #888888;
}
</style>

<script>
import actionbar from '@/components/actionbar'
import tablepage from '@/components/tablepage'
import modify from '@/components/modify'
import deletedata from '@/components/deletedata'
import ledtemplete from '@/components/ledtemplete'
import {getInAnimates,getJson} from '@/assets/utils/datef'
import textscroll from "@/components/textscroll";
import sensorinfo from "@/components/sensorinfo";
export default {
    components:{actionbar,tablepage,modify,deletedata,ledtemplete,sensorinfo,textscroll},
    data:function(){
        return{
            viewStyle:{
                position:"relative"
            },
            viewData:this.resetData(),
            rate:1,
            vertical:0,
            fontFamily:[
                {text:"Helvetica Neue",value:"Helvetica Neue"},
                {text:"PingFang SC",value:"PingFang SC"},
                {text:"Hiragino Sans GB",value:"Hiragino Sans GB"},
                {text:"Helvetica",value:"Helvetica"},
                {text:"微软雅黑",value:"微软雅黑"},
                {text:"Arial",value:"Arial"},
                {text:"sans-serif",value:"sans-serif"},
            ],
            fontSize:[
                {text:this.$t("X1"),value:"1em"},
                {text:this.$t("X2"),value:"2em"},
                {text:this.$t("X3"),value:"3em"},
                {text:this.$t("X4"),value:"4em"},
                {text:this.$t("X5"),value:"5em"},
            ],
            subStyle:{},
            activeSid:null,
            progs:[],
            style:{
                //border:"1px solid blue",
                width:"100%",
                position:"relative",
                "background-color":"blue"
            },
            options:[],
            inAnimate:getInAnimates(true),
            outAnimate:getInAnimates(false),
            deviceSelect:[],
            deviceSelect1:[],
            devices:[],
            temps:[],
            active:0,
            mapitem:{
                    "value":"Address",
                    "require":true,
                    "subvalue":false,
                    "Position":{
                        "Lat":"Lat",
                        "Lng":"Lng"
                    }
             },
            modalData:this.resetData(),
            loading:{},
            cols:[
                {name:this.$t("名称"),value:"Name"},
            ],
            items:[],
            total:0,
            count:20,
            index:0,
            sid:"",
            title:"",
            current:null,
            obj:null,
            nova:false
        }
    },
    watch:{
        "progs":function(){
            var array=[];
            this.progs.forEach((ele)=>{
                var obj = this.modalData.StateCotnents.find((x)=>x.LedResourceSid==ele);
                if(obj){
                    //如果已经存在，不管
                    array.push(obj);
                }else{
                    //如果不存在，添加
                    var data=this.options.find((x)=>x.Sid==ele);
                     array.push({
                        Width:200,
                        Height:100,
                        X:0,
                        Y:0,
                        Z:100,
                        Custom:this.getSubStyle(),
                        LedResourceSid:ele,
                        Data:data
                    });
                }
            });
            this.modalData.StateCotnents=array;
        },
        "subStyle":function(){
            if(this.activeSid){
                this.modalData.StateCotnents.forEach((x)=>{
                    if(x.LedResourceSid==this.activeSid){
                        x.Custom=this.subStyle;
                    }
                })
            }
        }
    },
    methods:{
        getSubStyle(){
            return {
                width:"100%",
                height:"100%",
                "font-size":"1em",
                "font-family":"微软雅黑",
                "font-style":"normal",
                "font-weight":"bold",
                "writing-mode":"horizontal-tb",
                "text-align":"left",
                "background-color":"#ffffff",
                "color":"#000000",
                 display:"flex",
                "align-items":"center",
                "justify-content":"center",
                "border":"1px solid black",
                "white-space":"normal",
                "flex-direction":"column"
            }
        },
        select(noloading,index){
            if(!noloading){
                if(!index){
                    this.index=0;
                }
                this.loading=this.$loading({fullScreen:true});
            }
             this.$ksajax.ksPostPromise("/api/led/getledstage","",true).then((data)=>{
                 data.forEach((ele)=>{
                     if(ele.StateCotnents){
                         ele.StateCotnents.forEach((x)=>{
                             if(x.Custom){
                                 x.Custom=getJson(x.Custom);
                             }
                         })
                     }
                 })
                 this.items=data;
                 if(this.loading.close){
                     this.loading.close();
                 }
             }).catch((err)=>{
                 this.$throw(err,this);
                 if(this.loading.close){
                     this.loading.close();
                 }
             })
        },
        del(){
            if(this.current==null)
            {
                throw this.$t("选择一条数据");
            }
            this.sid=this.current.Sid;
            this.$refs.del.show();
        },
        modify(){
            if(this.current==null)
            {
                throw this.$t("选择一条数据");
            }
            this.modalData=this.current;
            this.progs=[];
            if(this.modalData.StateCotnents){
                this.modalData.StateCotnents.forEach((x)=>{
                    this.progs.push(x.LedResourceSid);
                })
            }
            this.viewData=this.modalData;
            this.$refs.modallg.show();
            this.title=this.$t("修改");
        },
        insert(){
            this.progs=[];
            this.modalData=this.resetData();
            this.$refs.modallg.show();
            this.title=this.$t("新增");
        },
        tableCreated(){},
        currentPage(){

        },
        currentRow(val){
            this.current=val;
        },
        resetData(){
           return  {
                    "Sid":"",
                    "Width":384,
                    "Height":640,
                    "Name":"",
                    "Duration":5,
                    "State":1,
                    "Publish":0,
                    "InAnimate":"",
                    "InTimmer":2,
                    "OutAnimate":"",
                    "OutTimmer":2,
                    "Custom":"",
                    "StateCotnents":[]
            }
        },
        submited(){
            this.select();
        },
        deleted(){
            this.select();
         },
         onSubmit(evt){
             evt.preventDefault();
             this.loading=this.$loading({fullScreen:true});
              new Promise((res)=>{
                    if(this.active==0){
                            let obj={
                                Index:0,
                                Count:1000
                            };
                            this.$ksajax.ksPostPromise("/api/resource/GetLedResources",obj,true).then((data)=>{
                                this.options=data.Items;
                                this.style.width=`${this.modalData.Width}px`;
                                this.style.height=`${this.modalData.Height}px`;
                                res();
                            })
                    }else{
                        let data= Object.assign({}, this.modalData);
                        data.StateCotnents.forEach((x)=>{
                            x.Custom["flex-direction"]="column";
                                 if(x.Data.Type==5){
                                     x.Custom.display="block";
                                 }
                            x.Custom=JSON.stringify(x.Custom);
                        });
                        this.$ksajax.ksPostPromise("/api/led/InsertStage",data,true).then(()=>{
                            this.$refs.modallg.hide();
                            res();
                        })
                    }
                }).then(()=>{
                    this.active+=1;
                    if(this.active>1){
                        this.active=0;
                    }
                     this.loading.close();
                })
                .then(()=>{
                    if(this.active==0){
                        this.select();
                    }
                })
                .catch((err)=>{
                    this.$throw(err);
                     this.loading.close();
                })
         },
         tempclick(sid){
            for(var i=0;i<this.temps.length;i++){
                var obj = this.temps[i];
                if(obj.Sid===sid){
                    obj.active=true;
                }else{
                    obj.active=false;
                }
            }
         },
         step(){
                 this.active-=1;
                 if(this.active<0){
                     this.active=0;
                 }
         },
         handleSelectionChange(val){
             this.deviceSelect=val;
         },
         onResizing(x,y,w,h){
             if(this.activeSid){
                 var obj = this.modalData.StateCotnents.find((x)=>x.LedResourceSid==this.activeSid);
                 if(obj){
                     obj.X=x;
                     obj.Y=y;
                     obj.Width=w;
                     obj.Height=h;
                 }
             }
         },
         onDrag(x,y){
                if(this.activeSid){
                 var obj = this.modalData.StateCotnents.find((x)=>x.LedResourceSid==this.activeSid);
                 if(obj){
                     obj.X=x;
                     obj.Y=y;
                 }
             }
         },
         onActive(sid){
             this.activeSid=sid;
             var obj = this.modalData.StateCotnents.find((x)=>x.LedResourceSid==this.activeSid);
             this.subStyle=obj.Custom;
             this.obj=obj;
         },
         onDeActive(){
             this.activeSid=null;
         },
         layUp(){
                 if(this.obj){
                     this.obj.Z+=1;
                 }
         },
         layDown(){
                 if(this.obj){
                     this.obj.Z-=1;
                 }
         },
         view(data){
             data.StateCotnents.forEach(x => {
            x.style={
                position:"absolute",
                top:x.Y+"px",
                left:x.X+"px",
                width:x.Width+"px",
                height:x.Height+"px",
                "z-index":x.Z
                };
            });
             this.viewData=data;  
             this.$refs.ledmodal.show();
         },
         publish(){
            if(this.current==null)
            {
                throw this.$t("选择一条数据");
            }
             this.loading=this.$loading({fullScreen:true});
             //获取设备列表
             let obj={
                Index:0,
                Count:10000
            }
            var self=this;
            var url=this.nova?"/api/led/GetNovaLeds":"/api/device/GetDeviceLedAds";
             this.$ksajax.ksPostPromise(url,obj,true).then((data)=>{
                 this.devices=[];
                 this.deviceSelect1=[];
                 data.Items.forEach((x)=>{
                         this.devices.push(x);
                        if(this.current.Devices&&this.current.Devices.length!=0){
                            var exsit=this.current.Devices.find((y)=>y.DeviceSid==x.Sid);
                            if(exsit){
                                self.deviceSelect1.push(x);
                            }
                        }
                 })
                 this.loading.close();
                 this.$refs.publishmodal.show();
             })
             .catch((err)=>{
                 this.$throw(err);
                 this.loading.close();
             })
         },
         sync(){
              if(this.current==null)
            {
                throw this.$t("选择一条数据");
            }
             this.loading=this.$loading({fullScreen:true});
             this.$ksajax.ksPostPromise("/api/led/Sync",this.current.Sid,true).then(()=>{
                 this.$throw("操作成功，一分钟后同步");
                 this.loading.close();
             })
             .catch((err)=>{
                 this.$throw(err);
                 this.loading.close();
             })
         },
         publishStage(){
             this.loading=this.$loading({fullScreen:true})
             var data={
                 Sid:this.current.Sid,
                 Devices:[]
             }
             if(this.deviceSelect&&this.deviceSelect.length>0){
                 this.deviceSelect.forEach((x)=>{
                     data.Devices.push(x.Sid)
                 })
             }
             this.$ksajax.ksPostPromise("/api/led/PublishLed",data,true).then(()=>{
                 this.loading.close();
                   this.$refs.publishmodal.hide();
                   this.select();
             }).catch((err)=>{
                 this.$throw(err);
                 this.loading.close();
             })
         },
         publishShown(){
              this.$refs.multipleTable.clearSelection();
             if(this.deviceSelect1&&this.deviceSelect1.length!=0){
                 this.deviceSelect1.forEach((x)=>{
                    this.$refs.multipleTable.toggleRowSelection(x);
                 })
             }
         },
         mousedown(e){
             e.stopPropagation();
         }
        },
    mounted:function(){
        this.nova=window.location.href.toLowerCase().indexOf("nova")!=-1;
        this.select();
         
    }
}
</script>
