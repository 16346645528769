<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      :init="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:init="init"
      v-on:insert="insert"
    ></actionbar>

    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>

    <modify
      ref="modify"
      :mapitem="mapitem"
      :modaldata="modalData"
      :title="title"
      :labels="labels"
      v-on:submited="submited"
      url="/api/box/InsertDevice"
    ></modify>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/box/deldevice"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
export default {
  components: { actionbar, tablepage, modify, deletedata },
  data: function() {
    return {
      mapitem: {
        value: "Address",
        require: true,
        subvalue: false,
        Position: {
          Lat: "Lat",
          Lng: "Lng"
        }
      },
      modalData: this.resetData(),
      loading: {},
      cols: [
        { name: this.$t("设备名"), value: "DeviceName" },
        {
          name: this.$t("设备类型"),
          value: "TypeName",
          subvalue: "DeviceType"
        },
        {
          name: this.$t("设备型号"),
          value: "ModelNumber",
          subvalue: "DeviceType"
        },
        { name: this.$t("序列号"), value: "Serial" },
        { name: this.$t("录像机序列号"), value: "NVRSerial",subvalue:"DeviceBox" },
        { name: this.$t("录像机IP"), value: "NVRIp",subvalue:"DeviceBox" },
        { name: this.$t("录像机用户名"), value: "NVRUsername",subvalue:"DeviceBox" },
        { name: this.$t("录像机密码"), value: "NVRPassword",subvalue:"DeviceBox" },
        { name: this.$t("录像机验证码"), value: "NVRIp",subvalue:"DeviceBox" },
        { name: this.$t("在线状态"), value: "Status", state: 1 }
      ],
      items: [],
      total: 0,
      count: 20,
      index: 0,
      sid: "",
      labels: this.getResetLable(),
      title: "",
      current: null
    };
  },
  methods: {
    select(noloading, index) {
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      this.$ksajax
        .ksPostPromise("/api/box/getdevices", "", true)
        .then(data => {
          this.items = data;
          console.log(this.items);
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch(err => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    init() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.loading = this.$loading({ fullScreen: true });
      this.$ksajax
        .ksPostPromise("/api/box/setnvr", this.current.Sid, true)
        .then(() => {
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch(err => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.modalData = {
        Sid: this.current.Sid,
        DeviceName: this.current.DeviceName,
        Lat: this.current.Lat,
        Lng: this.current.Lng,
        Address: this.current.Address,
        Serial: this.current.Serial,
        DTypeSid: this.current.DeviceType.Sid
      };
      this.$refs.modify.show();
    },
    insert() {
      this.modalData = this.resetData();
      this.$refs.modify.show();
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      return {
        Sid: "",
        DeviceName: "智慧综合箱",
        Lat: 23.452,
        Lng: 123.452,
        Address: "",
        Serial: "",
        DTypeSid: "1801",
        LStandSid: ""
      };
    },
    getResetLable() {
      return [
        {
          label: this.$t("设备名"),
          type: "text",
          value: "DeviceName",
          require: true
        },
        {
          label: this.$t("序号"),
          type: "text",
          value: "Serial",
          require: true
        },
        {
          label: this.$t("类型"),
          type: "select",
          value: "DTypeSid",
          require: true,
          bind: this.$store.getters.getDeviceType("018")
        },
        {
          label: this.$t("位置"),
          type: "map",
          value: "Address",
          require: true
        }
      ];
    },
    submited() {
      this.select();
    },
    deleted() {
      this.select();
    }
  },
  mounted: function() {
    this.select();
  }
};
</script>
