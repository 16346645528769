<template>
    <div class="broadset">
    
        <div class="login">
    
            <b-container>
    
                <b-row>
    
                    <b-col cols="12" md="8" lg="6" offset-lg="3" offset-md="2">
    
                        <div class="login-form" style="margin:10%;position:relative">
    
                            <b-form @submit="onSubmit">
    
                                <b-form-group id="exampleInputGroup1" v-bind:label="$t('用户名')" label-for="exampleInput1">
    
                                    <b-form-input id="exampleInput1" type="text" v-model="form.UserName" required v-bind:placeholder="$t('用户名')">
    
                                    </b-form-input>
    
                                </b-form-group>
    
                                <b-form-group id="exampleInputGroup2" v-bind:label="$t('密码')" label-for="exampleInput2">
    
                                    <b-form-input id="exampleInput2" type="password" v-model="form.Password" required v-bind:placeholder="$t('密码')">
    
                                    </b-form-input>
    
                                </b-form-group>
    
                                <b-form-group id="exampleInputGroup3" v-bind:label="$t('服务器地址')" label-for="exampleInput3">
    
                                    <b-form-input id="exampleInput3" type="password" v-model="form.Password" required v-bind:placeholder="$t('服务器地址')">
    
                                    </b-form-input>
    
                                </b-form-group>
                                <hr>
                                <div style="text-align:center">
                                    
                                    <b-button type="submit" variant="success" style="margin-right:10%">{{$t('修改')}}</b-button>
                                    <b-button type="submit" variant="primary">{{$t('测试')}}</b-button>
                                </div>
                            </b-form>
    
            <div style="position:absolute;right:20px;top:10px;">
                <i class="fa fa-refresh" style="cursor:pointer"></i>
            </div>
                        </div>
    
                    </b-col>
    
                </b-row>
    
            </b-container>

        </div>
    
    </div>
</template>
<style>
    .broadset {
    
        height: 100%;
    
    }
</style>

<script>
    import actionbar from '@/components/actionbar'
    
    export default {
    
        components: {
    
            actionbar
    
        },
    
        data: function() {
    
            return {
    
                form: {
    
                    Url: "",
    
                    Pass: "",
    
                    User: ""
    
                }
    
            }
    
        },
        methods:{
            
          onSubmit(){

          }
        }    
    }
</script>

