
import cookies from '@/assets/utils/kscookie'
import http from '@/assets/utils/kshttp'
// import itc from '@/assets/utils/itchttp.js'
import store from '../../store'
export default{
    loginAction:function(login){
        return new Promise((reslove,rejects)=>{
            if(!login){
                reslove();
            }
            else{
                //是否已经登录
                let token=cookies.get("token");
                if(!token){
                    //没有登录，返回登录界面
                    rejects({path:"/login"})
                }
                else{
                    //如果有登录，看菜单有没有下载
                    if(!store.state.menuLoaded){
                        //没有下载菜单，下载菜单
                        store.dispatch("menuAction",()=>{
                        reslove();
                        },(err)=>{
                            console.log("loginAction",err);
                            rejects({path:"/error",query:{err:err}});
                        });
                    }else{
                        reslove();
                    }
               }
            }
        })
    },
    // broadAction:function(broad,sdata){
    //     return new Promise((reslove,rejects)=>{
    //         if(!broad){
    //             reslove();
    //         }
    //         else{
    //             // let session=cookies.get("JSESSIONID");
    //             // if(session&&session.length!=0){
    //             // reslove();
    //             // }
    //             // else{
    //             // //登录广播服务器
    //             // let data={
    //             //     User:sdata.user,
    //             //     Passwd:sdata.pass
    //             //     }
    //             //     itc.postData(sdata.url,"login",data).then((data)=>{
    //             //     store.commit("setBroadcastSession",data.JSessionID);
    //             //     cookies.set("JSESSIONID",data.JSessionID,1);
    //             //     reslove();
    //             //     })
    //             //     .catch((err)=>{  
    //             //         console.log("broadAction",err);
    //             //     rejects({path:"/admin/broadcast"})
    //             //     });
    //             // }
    //         }
    //     })
    // },
    lampsAction(lamp){
        return new Promise((reslove,rejects)=>{
            if(!lamp)
            {
                reslove();
            }
            else{
                if(store.state.lStands.length===0){
                    store.dispatch("lstandAction",()=>{
                        reslove();
                    },(err)=>{
                        console.log("lampsAction",err);
                        rejects({path:"/error",query:{err:err}})
                    })
                }else{
                    reslove();
                }
            }
        })
    },
    dtypeAction(dtype){
        return new Promise((reslove,rejects)=>{
            if(!dtype)
            {
                reslove();
            }
            else{
                if(store.state.deviceType.length===0){
                    store.dispatch("deviceTypeAction",()=>{
                        reslove();
                    },(err)=>{
                        console.log("dtypeAction",err);
                        rejects({path:"/error",query:{err:err}})
                    })
                }else{
                    reslove();
                }
            }
        })
    },
    ltypeAction(ltype){
        return new Promise((reslove,rejects)=>{
            if(!ltype)
            {
                reslove();
            }
            else{
                if(store.state.lStandType.length===0){
                    store.dispatch("lStandTypeAction",()=>{
                        reslove();
                    },(err)=>{
                        console.log("ltypeAction",err);
                        rejects({path:"/error",query:{err:err}})
                    })
                }else{
                    reslove();
                }
            }
        })
    },
    szAction(sz){
        return new Promise((reslove,rejects)=>{
            if(!sz){
                reslove();
            }
            else{
                if(store.state.sz){
                    reslove();
                }else{
                    http.ksPostPromise("/api/config/GetLamp","",true).then((data)=>{
                        store.commit("setSz",data);
                        reslove();
                    }).catch((err)=>{
                        console.log("szAction",err);
                        rejects({path:"/error",query:{err:err}})
                    })
                }
            }
        })
    },
    projectAction(project){
        return new Promise((reslove,rejects)=>{
            if(!project){
                reslove();
            }
            else{
                if(store.state.project){
                    reslove();
                }else{
                    http.ksPostPromise("/api/smart/getproject","",true).then((data)=>{
                        store.commit("setProject",data);
                        reslove();
                    }).catch((err)=>{
                        console.log("projectAction",err);
                        rejects({path:"/error",query:{err:err}});
                        
                    })
                }
            }
        })
    },
    alarmAction(alarm){
        return new Promise((reslove,rejects)=>{
            if(!alarm){
                reslove();
            }
            else{
                if(store.state.alarms){ 
                    reslove();
                }else{
                    var obj={
                        Index:0,
                        Count:100000
                    }
                    http.ksPostPromise("/api/device/GetDeviceVideos",obj,true).then((data)=>{
                        store.commit("setAlarms",data.Items);
                        reslove();
                    }).catch((err)=>{
                        console.log("alarmAction",err);
                        rejects({path:"/error",query:{err:err}});
                    })
                }
            }
        })
    }
}