<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      :reboot="true"
      :ntp="true"
      :light="true"
      :screen="true"
      :volume="true"
      :init="true"
      :publish="true"
      v-on:publish="publish"
      v-on:select="select"
      v-on:insert="insert"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:volume="vol"
      v-on:reboot="reboot"
      v-on:ntp="ntp"
      v-on:light="light"
      v-on:screen="screen"
      v-on:init="init"
    ></actionbar>
    <el-table stripe=""
      :data="items"
      style="width: 100%"
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column :label="$t('序号')" prop="Serial"></el-table-column>
      <el-table-column :label="$t('设备名')" prop="DeviceName"></el-table-column>
      <el-table-column :label="$t('设备类型')">
        <template slot-scope="scope">
          <span>{{scope.row.DeviceType.TypeName}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('高度')">
        <template slot-scope="scope">
          <span>{{scope.row.DeviceNovaLed.Height}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('宽度')">
        <template slot-scope="scope">
          <span>{{scope.row.DeviceNovaLed.Width}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('版本')">
        <template slot-scope="scope">
          <span>{{scope.row.DeviceNovaLed.Version}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('地址')" prop="Address"></el-table-column>
      <el-table-column :label="$t('在线状态')">
        <template slot-scope="scope">
          <i :class="['fa',scope.row.Status==1?'stateOn':'stateOff','fa-2x','fa-signal']"></i>
        </template>
      </el-table-column>
    </el-table>
    <modify
      ref="modify"
      :mapitem="mapitem"
      :modaldata="modalData"
      :title="title"
      :labels="labels"
      v-on:submited="submited"
      url="/api/led/AddNovaLed"
    ></modify>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/led/DeleteNovaLed"
      :title="$t('删除')"
    ></deletedata>
     <b-modal
      id="novalControlModal"
      ref="novalControlModal"
      size="ms"
      :title="$t('控制')"
      body-text-variant="dark"
      @ok="novaSubmit"
    >
    <div>{{controlTitle}}{{controlDesc}}</div>
    <b-form-input type="number" v-if="controlData.option==2||controlData.option==3" v-model="controlData.value"></b-form-input>
    <b-form-select v-if="controlData.option==1||controlData.option==4||controlData.option==5"  v-model="controlData.value">
      <b-form-select-option value="1">开</b-form-select-option>
      <b-form-select-option  value="0">关</b-form-select-option>
    </b-form-select>
       </b-modal>
         <b-modal
      id="initModel"
      ref="initModel"
      size="lg"
      :title="$t('初始化')"
      body-text-variant="dark"
      @ok="initView"
    >
    <div>请输入显示文本（图片下载失败时显示）</div>
    <b-form-input v-model="initModal.Text"></b-form-input>
    <div>{{$t("选择资源")}}</div>
       <b-form-select v-model="initModal.Pic" :options="picRes" text-field="Name" value-field="Content"></b-form-select>
       <b-img fluid="" :src="initModal.Pic"></b-img>
       </b-modal>
       <b-modal
      id="publishmodal"
      class="ksmodal"
      ref="publishmodal"
      size="lg"
      :title="$t('发布')"
      body-text-variant="dark"
      @ok="publishStage"
      @shown="publishShown"
    >
      <hr>
      <el-table
        ref="multipleTable"
        :data="devices"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange" :empty-text="$t('暂无数据')"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
        <el-table-column :label="$t('宽度')" prop="Width"></el-table-column>
        <el-table-column :label="$t('高度')" prop="Height"></el-table-column>
        <el-table-column :label="$t('时长')" prop="Duration"></el-table-column>
      </el-table>
    </b-modal>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
export default {
  components: { actionbar, modify, deletedata },
  data: function() {
    return {
      picRes:[],
      initModal:{
        Pic:"",
        Text:"",
        Sid:""
      },
      
      controlTitle:"",
      controlDesc:"",
      controlData:{
        option:0,
        playerIds:[],
        value:""
      },
      sid: "",
      items: [],
      val: null,
      mapitem: {
        value: "Address",
        require: true,
        subvalue: false,
        Position: {
          Lat: "Lat",
          Lng: "Lng"
        }
      },
      modalData: this.resetData(),
      title: "",
      labels: this.getResetLable(),
      loading:null,
      deviceSlected: [],
      deviceSlected1: [],
      devices: [],
    };
  },
  methods: {handleSelectionChange(val) {
      this.deviceSlected = val;
    },
     publishShown() {
      this.$refs.multipleTable.clearSelection();
      if (this.deviceSlected1 && this.deviceSlected1.length != 0) {
        this.deviceSlected1.forEach(x => {
          this.$refs.multipleTable.toggleRowSelection(x);
        });
      }
    },
    publish() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.loading = this.$loading();
      new Promise((res, rej) => {
        if (this.devices.length != 0) {
          res();
        } else {
          this.$ksajax
            .ksPostPromise("/api/led/getledstage", "", true)
            .then(data => {
              this.devices = data;
              res();
            })
            .catch(err => {
              rej(err);
            });
        }
      })
        .then(() => {
          this.deviceSlected1 = this.devices.filter(
            x => this.current.Stages.indexOf(x.Sid) != -1
          );
          this.loading.close();
          this.$refs.publishmodal.show();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    publishStage() {
      this.loading = this.$loading({ fullScreen: true });
      var data = {
        Sid: this.current.Sid,
        Stages: []
      };
      if (this.deviceSlected && this.deviceSlected.length > 0) {
        this.deviceSlected.forEach(x => {
          data.Stages.push(x.Sid);
        });
      }
      this.$ksajax
        .ksPostPromise("/api/led/PublishLedByDev", data, true)
        .then(() => {
          this.loading.close();
          this.$refs.publishmodal.hide();
          this.select();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    initView(){
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.loading = this.$loading({ fullScreen: true });
      this.initModal.Sid=this.current.Sid;
      this.$ksajax.ksPostPromise("/api/led/NovaInit", this.initModal, true).then(()=>{
        this.$refs.initModel.hide();
          this.loading.close();
      }).catch((err)=>{
          this.$throw(err);
          this.loading.close();
      })
    },
    init(){
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      new Promise((res,rej)=>{
        if(this.picRes.length!=0){
          res();
        }else{
          this.loading = this.$loading({ fullScreen: true });
           this.$ksajax.ksPostPromise("/api/led/GetPicRes", "", true).then((r)=>{
             this.picRes=r;
             res();
           }).catch((err)=>{
             rej(err);
           })
        }
      }).then(()=>{
        this.$refs.initModel.show();
        this.loading.close();
      }).catch((err)=>{
          this.$throw(err);
          this.loading.close();
      })
    },
    screen(){
       this.controlDetail(this.$t("屏幕开关"),"",4);
    },
    light:function(){
      this.controlDetail(this.$t("修改量度"),"(0-100)",3);
    },
    ntp:function(){
        this.controlDetail(this.$t("Ntp校时"),"",5);
    },
    reboot: function() {
      this.controlDetail(this.$t("重启设备"),"",0);
    },
    vol: function() {
      this.controlDetail(this.$t("修改音量"),"(0-100)",2);
    },
    controlDetail(title,desc,opt){
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.controlData.playerIds= [this.current.Serial]
      this.controlData.option=opt;
      this.controlTitle=title;
      this.controlDesc=desc;
      this.$refs.novalControlModal.show();
    },
    novaSubmit(){
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/NovaLedControl", this.controlData, true)
        .then(() => {
          this.$throw("Sccuess");
          this.$loading().close();
        })
        .catch(err => {
          this.$throw(err);
          this.$loading().close();
        });
    },
    control: function(opt, value) {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      var obj = {
        playerIds: [this.current.Serial],
        option: opt,
        value: value.value
      };
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/NovaLedControl", obj, true)
        .then(() => {
          this.$throw("Sccuess");
          this.$loading().close();
        })
        .catch(err => {
          this.$throw(err);
          this.$loading().close();
        });
    },
    del: function() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    getResetLable() {
      return [
        {
          label: this.$t("设备名"),
          type: "text",
          value: "DeviceName",
          require: true
        },
        {
          label: this.$t("高度"),
          type: "number",
          value: "Height",
          require: true
        },
        {
          label: this.$t("宽度"),
          type: "number",
          value: "Width",
          require: true
        },
        {
          label: this.$t("播放器名称"),
          type: "text",
          value: "Name",
          require: true
        },
        {
          label: this.$t("类型"),
          type: "select",
          value: "DTypeSid",
          require: true,
          bind: this.$store.getters.getDeviceType("006", "nova")
        },
        {
          label: this.$t("灯杆"),
          type: "select",
          value: "LStandSid",
          require: false,
          bind: this.$store.state.lStands
        },
        {
          label: this.$t("位置"),
          type: "map",
          value: "Address",
          require: true
        }
      ];
    },
    modify: function() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.title = this.$t("修改");
      this.modalData = {
        Sid: this.current.Sid,
        DeviceName: this.current.DeviceName,
        Lat: this.current.Lat,
        Lng: this.current.Lng,
        Address: this.current.Address,
        Serial: this.current.Serial,
        Width: this.current.DeviceNovaLed.Width,
        Height: this.current.DeviceNovaLed.Height,
        DTypeSid: this.current.DeviceType.Sid,
        LStandSid: this.current.LampStand ? this.current.LampStand.Sid : "",
        Name: this.current.DeviceNovaLed.Name
      };
      this.$refs.modify.show();
    },
    select: function() {
      var obj = {
        Index: 0,
        Count: 1000
      };
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/GetNovaLeds", obj, true)
        .then(data => {
          this.items = data.Items;
          this.$loading().close();
        })
        .catch(err => {
          this.$throw(err);
          this.$loading().close();
        });
    },
    currentRow: function(val) {
      this.current = val;
    },
    insert: function() {
      this.title = this.$t("新增");
      this.modalData = this.resetData();
      this.$refs.modify.show();
    },
    submited() {
      this.select();
    },
    deleted: function() {
      this.select();
    },
    resetData: function() {
      return {
        DeviceName: "",
        Lat: 0,
        Lng: 0,
        Address: "",
        Serial: "Serial",
        DTypeSid: "",
        LStandSid: "",
        Width: "",
        Height: "",
        Name: ""
      };
    }
  },
  mounted: function() {
    this.select();
  }
};
</script>