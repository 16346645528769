<template>
  <div style="color: white; height: 100%">
    <div id="todetail">
      <router-link :to="`/admin/stand/commondevice?sid=${$route.query.sid}`">{{
        this.$t("详情")
      }}</router-link>
    </div>
    <img :src="rolleBorderImg" style="display: none" id="rolleBorderImg" />
    <img :src="rollImg" style="display: none" id="rollImg" />
    <img :src="rollYezi" style="display: none" id="rollYezi" />
    <img :src="biaoChi" style="display: none" id="biaoChi" />
    <div class="detailview">
      <div class="detailview_1">
        <div>
          <box style="position: relative">
            <div
              id="centerTop"
              style="position: absolute; top: 20px; left: 20px"
            >
              <div style="font-size: 1.5rem">{{ this.$t("水位检测") }}</div>
            </div>
            <div id="leftTop" style="width: 100%; height: 100%"></div>
          </box>
        </div>
      </div>
      <div class="detailview_1">
        <div>
          <box id="canvas_box" style="position: relative">
            <canvas id="canvas" style="position: absolute; z-index: 0"></canvas>
            <canvas id="canvas_roll" :style="imgStyle"></canvas>
            <canvas
              id="canvas_water"
              style="position: absolute; z-index: 1"
            ></canvas>
            <div
              id="centerTop"
              style="position: absolute; top: 20px; left: 20px"
            >
              <div style="font-size: 1.5rem">{{ para.name }}</div>
            </div>
            <div style="position: absolute; right: 0; height: 30%; width: 20%">
              <alarm ref="alarm"></alarm>
            </div>
          </box>
        </div>
      </div>
      <div class="detailview_1">
        <div>
          <box v-loading="loadingCanvas">
            <div class="ksflexcol" style="height: 100%">
              <div style="padding: 20px 0 0 20px; width: 100%">
                <div style="font-size: 1.5rem">{{ this.$t("参数设置") }}</div>
              </div>
              <div
                class="ksflexcol"
                style="flex: 1; width: 100%; padding-top: 20px; font-size: 1rem"
              >
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ this.$t("实际水位") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ mainData.Wl }}mm
                  </div>
                </div>
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ this.$t("量程") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ mainData.MeasuringRange }}mm
                  </div>
                </div>
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ this.$t("警戒值") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ item.alarm }}mm
                  </div>
                </div>
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ this.$t("开阈值") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ item.flag }}mm
                  </div>
                </div>
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ this.$t("关阈值") }}
                  </div>
                  <div class="col" style="text-align: center">
                    {{ item.self }}mm
                  </div>
                </div>
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ this.$t("报警开关") }}
                  </div>
                  <div
                    class="col"
                    style="text-align: center"
                    v-loading="alarmSwLoading"
                  >
                    <el-radio-group
                      v-model="alarmSw"
                      size="mini"
                      fill="black"
                      style="margin-right: 20px"
                      @change="alarmSwChange"
                    >
                      <el-radio-button value="on" label="On"></el-radio-button>
                      <el-radio-button
                        value="off"
                        label="Off"
                      ></el-radio-button>
                    </el-radio-group>
                  </div>
                </div>
                <div class="row ksrow" style="flex: 1; width: 100%">
                  <div class="col" style="text-align: center">
                    {{ this.$t("水泵开关") }}
                  </div>
                  <div
                    class="col"
                    style="text-align: center"
                    v-loading="waterSwLoading"
                  >
                    <el-radio-group
                      v-model="waterSw"
                      size="mini"
                      fill="black"
                      style="margin-right: 20px"
                      @change="waterSwChange"
                    >
                      <el-radio-button value="on" label="On"></el-radio-button>
                      <el-radio-button
                        value="off"
                        label="Off"
                      ></el-radio-button>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </box>
        </div>
      </div>
      <div class="detailview_2">
        <div>
          <box v-loading="leftBottomLoading">
            <div id="leftBottom" style="width: 100%; height: 100%"></div>
          </box>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import box from "@/components/box.vue";
import alarm from "@/components/alarm";
export default {
  components: { box, alarm },
  data: function () {
    return {
      alarmSwLoading: false,
      waterSwLoading: false,
      leftBottomLoading: false,
      mainData: {},
      roll: false,
      startPushWaterThread: null,
      startRollThread: null,
      startWaterThread: null,
      startWateValueThread: null,
      startWaterCurrentThread: null,
      loadingCanvas: true,
      waterSw: "Off",
      alarmSw: "Off",
      tvalue: 0,
      wateValueDic: true,
      wateDic: true,
      ratex: 0.4,
      ratey: 0.08,
      angle: 360,
      imgStyle: {
        position: "absolute",
        "z-index": 1,
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
      },
      alarm: false,
      rolleBorderImg: require("@/assets/img/ic_shuil.png"),
      rollImg: require("@/assets/img/ic_fengs.png"),
      rollYezi: require("@/assets/img/ic_yezhi.png"),
      biaoChi: require("@/assets/img/ic_biaochi.png"),
      canvasWater: null,
      canvasRoll: null,
      canvas: null,
      ctx: null,
      width: 0,
      height: 0,
      item: {
        max: 3000,
        alarm: 3000,
        self: 1000,
        value: 600,
        flag: 2000,
      },
      para: {
        sweight: 10,
        lweight: 5,
        mweight: 7,
        llweigth: 3,
        name: "测试传感器",
      },
      borderPosition: {
        lt: [],
        lb: [],
        rb: [],
        bt: [],
        max: [],
        alarm: [],
        self: [],
        value: [],
        flag: [],
        center: [],
        lrad: 0,
        pieStart: [],
        pieHeiht: 0,
        pieWidth: 0,
        pieHorizon: 0,
        pieVertal: 0,
        pieEndWidth: 0,
        imgBorderStart: [],
        imgBorderWidth: 0,
        imgBorderHeight: 0,
        imgSize: 0,
        waterStart: [],
        waterWidth: 0,
        waterHeight: 0,
      },

      color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#2978c9" },
        { offset: 0.5, color: "#00b2e4" },
        { offset: 1, color: "#00e7fd" },
      ]),
      color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0, [
        { offset: 0, color: "#ffad42" },
        { offset: 0.5, color: "#ffc54b" },
        { offset: 1, color: "#ffd450" },
      ]),
    };
  },
  methods: {
    initCanvas() {
      this.canvas = document.getElementById("canvas");
      this.canvasRoll = document.getElementById("canvas_roll");
      this.canvasWater = document.getElementById("canvas_water");
      this.ctx = this.canvas.getContext("2d");
      this.width = document.getElementById("canvas_box").clientWidth;
      this.height = document.getElementById("canvas_box").clientHeight;
      this.canvas.width = this.width;
      this.canvas.height = this.height;
      this.canvasWater.width = this.width;
      this.canvasWater.height = this.height;
      this.borderPosition.lt = [this.width * 0.1, this.height * 0.25];
      this.borderPosition.lb = [this.width * 0.1, this.height * 0.9];
      this.borderPosition.rt = [this.width * 0.65, this.height * 0.25];
      this.borderPosition.rb = [this.width * 0.65, this.height * 0.9];
      this.borderPosition.center = [
        (this.borderPosition.rt[0] - this.borderPosition.lt[0]) / 2 +
          this.borderPosition.lt[0],
        this.height * 0.1,
      ];
      this.borderPosition.lrad = this.height * 0.03;
      var rate = (this.height * 0.6) / this.item.max;
      this.borderPosition.value = [
        this.borderPosition.lt[0] / 4,
        this.height * 0.65 - rate * this.item.value + this.borderPosition.lt[1],
      ];
      this.borderPosition.waterStart = [
        this.borderPosition.lt[0] + this.para.sweight / 2,
        this.borderPosition.value[1],
      ];
      this.borderPosition.waterWidth =
        this.borderPosition.rt[0] -
        this.para.sweight -
        this.borderPosition.lt[0];
      this.borderPosition.waterHeight =
        rate * this.item.value - this.para.sweight / 2;
      this.borderPosition.self = [
        this.borderPosition.lt[0] / 4,
        this.height * 0.65 - rate * this.item.self + this.borderPosition.lt[1],
      ];
      this.borderPosition.max = [
        this.borderPosition.lt[0] / 4,
        this.height * 0.65 - rate * this.item.max + this.borderPosition.lt[1],
      ];
      this.borderPosition.alarm = [
        this.borderPosition.lt[0] / 4,
        this.height * 0.65 - rate * this.item.alarm + this.borderPosition.lt[1],
      ];
      this.borderPosition.flag = [
        this.borderPosition.lt[0] / 4,
        this.height * 0.65 - rate * this.item.flag + this.borderPosition.lt[1],
      ];
      this.borderPosition.pieStart = [
        this.borderPosition.rt[0] - this.width * 0.06,
        this.borderPosition.rt[1] - this.height * 0.1,
      ];
      this.borderPosition.pieHeiht = this.height * 0.65;
      this.borderPosition.pieWidth = this.height * 0.04;
      this.borderPosition.pieHorizon = this.width * 0.12;
      this.borderPosition.pieVertal = this.height * 0.5;
      this.borderPosition.pieEndWidth = 20;
      this.borderPosition.imgBorderStart = [
        this.borderPosition.pieStart[0] +
          this.borderPosition.pieHorizon +
          this.borderPosition.pieEndWidth,
        this.borderPosition.pieStart[1] +
          this.borderPosition.pieVertal -
          this.borderPosition.pieWidth -
          this.borderPosition.pieWidth * 0.25,
      ];
      this.borderPosition.imgBorderWidth = this.width * 0.18;
      var imgRate = 0.844;
      this.borderPosition.imgBorderHeight =
        this.borderPosition.imgBorderWidth * imgRate;
      this.borderPosition.imgSize = this.borderPosition.imgBorderWidth * 0.6;
      this.canvasRoll.width = this.borderPosition.imgSize;
      this.canvasRoll.height = this.borderPosition.imgSize;
      this.imgStyle.width = this.borderPosition.imgSize + "px";
      this.imgStyle.height = this.borderPosition.imgSize + "px";
      this.imgStyle.left =
        this.borderPosition.imgBorderStart[0] +
        this.borderPosition.imgBorderWidth / 5 +
        "px";
      this.imgStyle.top =
        this.borderPosition.imgBorderStart[1] +
        this.borderPosition.imgBorderHeight / 7 +
        "px";
    },
    drawBorder() {
      var ctx = this.ctx;
      ctx.beginPath();
      ctx.lineWidth = this.para.sweight;
      ctx.strokeStyle = "white";
      ctx.rect(
        this.borderPosition.lt[0],
        this.borderPosition.lt[1],
        this.borderPosition.rt[0] - this.borderPosition.lt[0],
        this.borderPosition.rb[1] - this.borderPosition.rt[1]
      );
      ctx.stroke();
      ctx.clearRect(
        0,
        0,
        this.width,
        this.borderPosition.rt[1] + this.para.sweight / 2
      );
      ctx.closePath();

      ctx.beginPath();
      ctx.moveTo(this.borderPosition.lt[0], this.borderPosition.lt[1]);
      ctx.fillStyle = "white";
      if (window.innerWidth > 1366) {
        ctx.font = "20px YaiHei";
      }
      if (this.item.self) {
        ctx.fillText(
          this.item.self,
          this.borderPosition.self[0],
          this.borderPosition.self[1]
        );
        ctx.fillText(
          this.$t("安全线"),
          this.borderPosition.lt[0] + this.width * 0.05,
          this.borderPosition.self[1]
        );
      }
      if (this.item.max) {
        ctx.fillText(
          this.item.max,
          this.borderPosition.max[0],
          this.borderPosition.max[1]
        );
      }
      if (this.item.flag) {
        ctx.fillText(
          this.item.flag,
          this.borderPosition.self[0],
          this.borderPosition.flag[1]
        );
        ctx.fillText(
          this.$t("阈值线"),
          this.borderPosition.lt[0] + this.width * 0.05,
          this.borderPosition.flag[1]
        );
      }
      if (this.item.alarm) {
        ctx.fillText(
          this.item.alarm,
          this.borderPosition.self[0],
          this.borderPosition.alarm[1]
        );
        ctx.fillText(
          this.$t("报警线"),
          this.borderPosition.lt[0] + this.width * 0.05,
          this.borderPosition.alarm[1]
        );
      }

      ctx.closePath();
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.setLineDash([3, 1]);
      ctx.moveTo(
        this.borderPosition.lt[0] + this.width * 0.12,
        this.borderPosition.self[1] - 4
      );
      ctx.lineTo(
        this.borderPosition.rt[0] - this.width * 0.03,
        this.borderPosition.self[1] - 4
      );
      ctx.stroke();
      ctx.lineWidth = 1;
      ctx.setLineDash([3, 1]);
      ctx.moveTo(
        this.borderPosition.lt[0] + this.width * 0.12,
        this.borderPosition.flag[1] - 4
      );
      ctx.lineTo(
        this.borderPosition.rt[0] - this.width * 0.03,
        this.borderPosition.flag[1] - 4
      );
      ctx.stroke();
      ctx.lineWidth = 1;
      ctx.setLineDash([3, 1]);
      ctx.moveTo(
        this.borderPosition.lt[0] + this.width * 0.12,
        this.borderPosition.alarm[1] - 4
      );
      ctx.lineTo(
        this.borderPosition.rt[0] - this.width * 0.03,
        this.borderPosition.alarm[1] - 4
      );
      ctx.stroke();
      ctx.setLineDash([0, 0]);
      ctx.closePath();

      var img = document.getElementById("biaoChi");
      ctx.drawImage(
        img,
        this.borderPosition.lt[0] + this.para.lweight,
        this.borderPosition.lt[1] + this.para.lweight,
        this.para.sweight,
        this.borderPosition.rb[1] -
          this.borderPosition.rt[1] -
          this.para.sweight
      );
    },
    drawSensor() {
      var ctx = this.ctx;
      ctx.beginPath();
      ctx.lineWidth = this.para.lweight;
      ctx.moveTo(this.borderPosition.center[0], this.borderPosition.center[1]);
      ctx.lineTo(this.borderPosition.center[0], this.height * 0.6);
      ctx.stroke();
      ctx.closePath();
      ctx.beginPath();
      ctx.arc(
        this.borderPosition.center[0],
        this.height * 0.6 + this.borderPosition.lrad,
        this.borderPosition.lrad,
        Math.PI * 1.0,
        Math.PI * 0
      );
      ctx.stroke();
      ctx.closePath();
      ctx.beginPath();
      ctx.moveTo(
        this.borderPosition.center[0] - this.borderPosition.lrad,
        this.height * 0.6 + this.borderPosition.lrad
      );
      ctx.lineTo(
        this.borderPosition.center[0] - this.borderPosition.lrad,
        this.height * 0.8
      );
      ctx.moveTo(
        this.borderPosition.center[0] + this.borderPosition.lrad,
        this.height * 0.6 + this.borderPosition.lrad
      );
      ctx.lineTo(
        this.borderPosition.center[0] + this.borderPosition.lrad,
        this.height * 0.8
      );
      ctx.stroke();
      ctx.closePath();
      ctx.beginPath();
      ctx.arc(
        this.borderPosition.center[0],
        this.height * 0.8,
        this.borderPosition.lrad,
        Math.PI * 0,
        Math.PI * 1.0
      );
      ctx.stroke();
      ctx.closePath();
      ctx.beginPath();
      ctx.lineWidth = this.para.mweight;
      ctx.moveTo(
        this.borderPosition.center[0] - this.borderPosition.lrad,
        this.height * 0.6 + this.borderPosition.lrad * 2
      );
      ctx.lineTo(
        this.borderPosition.center[0] + this.borderPosition.lrad,
        this.height * 0.6 + this.borderPosition.lrad * 2
      );
      ctx.moveTo(
        this.borderPosition.center[0] - this.borderPosition.lrad,
        this.height * 0.6 + this.borderPosition.lrad * 3
      );
      ctx.lineTo(
        this.borderPosition.center[0] + this.borderPosition.lrad,
        this.height * 0.6 + this.borderPosition.lrad * 3
      );
      ctx.stroke();
      ctx.closePath();
    },
    drawPie() {
      var ctx = this.ctx;
      ctx.beginPath();
      ctx.lineWidth = this.para.llweigth;
      ctx.strokeStyle = "white";
      ctx.moveTo(
        this.borderPosition.pieStart[0],
        this.borderPosition.pieStart[1]
      );
      ctx.strokeRect(
        this.borderPosition.pieStart[0],
        this.borderPosition.pieStart[1],
        this.borderPosition.pieWidth,
        this.borderPosition.pieHeiht
      );
      ctx.rect(
        this.borderPosition.pieStart[0],
        this.borderPosition.pieStart[1],
        this.borderPosition.pieWidth,
        this.borderPosition.pieHeiht
      );
      ctx.strokeRect(
        this.borderPosition.pieStart[0],
        this.borderPosition.pieStart[1],
        this.borderPosition.pieHorizon,
        this.borderPosition.pieWidth
      );
      ctx.rect(
        this.borderPosition.pieStart[0],
        this.borderPosition.pieStart[1],
        this.borderPosition.pieHorizon,
        this.borderPosition.pieWidth
      );

      ctx.strokeRect(
        this.borderPosition.pieStart[0] +
          this.borderPosition.pieHorizon -
          this.borderPosition.pieWidth,
        this.borderPosition.pieStart[1],
        this.borderPosition.pieWidth,
        this.borderPosition.pieVertal
      );
      ctx.rect(
        this.borderPosition.pieStart[0] +
          this.borderPosition.pieHorizon -
          this.borderPosition.pieWidth,
        this.borderPosition.pieStart[1],
        this.borderPosition.pieWidth,
        this.borderPosition.pieVertal
      );

      ctx.strokeRect(
        this.borderPosition.pieStart[0] + this.borderPosition.pieHorizon,
        this.borderPosition.pieStart[1] +
          this.borderPosition.pieVertal -
          this.borderPosition.pieWidth,
        this.borderPosition.pieEndWidth,
        this.borderPosition.pieWidth
      );
      ctx.rect(
        this.borderPosition.pieStart[0] + this.borderPosition.pieHorizon,
        this.borderPosition.pieStart[1] +
          this.borderPosition.pieVertal -
          this.borderPosition.pieWidth,
        this.borderPosition.pieEndWidth,
        this.borderPosition.pieWidth
      );
      ctx.fillStyle = "rgba(0, 179, 244, 1)";
      ctx.fill();
      ctx.closePath();
      ctx.clearRect(
        this.borderPosition.pieStart[0],
        this.borderPosition.pieStart[1] + this.borderPosition.pieHeiht,
        this.borderPosition.pieWidth,
        this.para.llweigth
      );
    },
    drawRollBorder() {
      var ctx = this.ctx;
      var img = document.getElementById("rolleBorderImg");
      ctx.drawImage(
        img,
        this.borderPosition.imgBorderStart[0],
        this.borderPosition.imgBorderStart[1],
        this.borderPosition.imgBorderWidth,
        this.borderPosition.imgBorderHeight
      );
    },
    drawRoll() {
      var ctx = this.canvasRoll.getContext("2d");
      ctx.moveTo(0, 0);
      ctx.translate(0, 0);
      var img = document.getElementById("rollImg");
      ctx.clearRect(
        0,
        0,
        this.borderPosition.imgSize,
        this.borderPosition.imgSize
      );
      ctx.drawImage(
        img,
        0,
        0,
        this.borderPosition.imgSize,
        this.borderPosition.imgSize
      );
    },
    startRoll() {
      this.canvasRoll
        .getContext("2d")
        .translate(this.canvasRoll.width / 2, this.canvasRoll.height / 2);
      return window.setInterval(() => {
        this.angle -= 0.1;
        if (this.angle <= 0) {
          this.angle = 360;
        }
        var ctx = this.canvasRoll.getContext("2d");
        ctx.clearRect(
          -this.borderPosition.imgSize / 2,
          -this.borderPosition.imgSize / 2,
          this.canvasRoll.width,
          this.canvasRoll.height
        );
        var img = document.getElementById("rollImg");
        ctx.rotate((this.angle * Math.PI) / 180);
        ctx.drawImage(
          img,
          -this.borderPosition.imgSize / 2,
          -this.borderPosition.imgSize / 2,
          this.borderPosition.imgSize,
          this.borderPosition.imgSize
        );
      }, 50);
    },
    drawWater(ratex, ratey) {
      var rate = (this.height * 0.6) / this.item.max;
      this.borderPosition.value = [
        this.borderPosition.lt[0] / 4,
        this.height * 0.65 -
          rate * this.item.value +
          this.borderPosition.lt[1] -
          this.para.sweight / 2,
      ];
      this.borderPosition.waterStart = [
        this.borderPosition.lt[0] + this.para.sweight / 2,
        this.borderPosition.value[1],
      ];
      this.borderPosition.waterWidth =
        this.borderPosition.rt[0] -
        this.para.sweight -
        this.borderPosition.lt[0];
      this.borderPosition.waterHeight = rate * this.item.value;
      this.borderPosition.waterHeight =
        this.borderPosition.waterHeight < 0
          ? 0
          : this.borderPosition.waterHeight;
      var r = this.item.value / this.item.max;
      ratey = ratey * (r * 1.3);
      var ctx = this.canvasWater.getContext("2d");
      ctx.clearRect(0, 0, this.canvasWater.width, this.canvasWater.height);
      ctx.beginPath();
      ctx.moveTo(
        this.borderPosition.waterStart[0],
        this.borderPosition.waterStart[1]
      );
      ctx.fillStyle = "rgba(2,180,245,0.8)";
      ctx.lineWidth = null;
      var center = [
        this.borderPosition.waterStart[0] + this.borderPosition.waterWidth / 2,
        this.borderPosition.waterStart[1],
      ];
      var c1 = [
        this.borderPosition.waterStart[0] +
          (this.borderPosition.waterWidth / 2) * (ratex + 0.1),

        this.borderPosition.waterStart[1] -
          this.borderPosition.waterStart[1] * ratey,
      ];
      var c2 = [
        this.borderPosition.waterStart[0] +
          (this.borderPosition.waterWidth / 2) * ratex,
        this.borderPosition.waterStart[1] +
          this.borderPosition.waterStart[1] * ratey,
      ];
      var c3 = [
        center[0] + (this.borderPosition.waterWidth / 2) * (ratex + 0.1),
        this.borderPosition.waterStart[1] -
          this.borderPosition.waterStart[1] * ratey * 0.8,
      ];
      var c4 = [
        center[0] + (this.borderPosition.waterWidth / 2) * ratex,
        this.borderPosition.waterStart[1] +
          this.borderPosition.waterStart[1] * ratey * 0.8,
      ];
      ctx.bezierCurveTo(c1[0], c1[1], c2[0], c2[1], center[0], center[1]);
      ctx.moveTo(center[0], center[1]);
      ctx.bezierCurveTo(
        c3[0],
        c3[1],
        c4[0],
        c4[1],
        center[0] + this.borderPosition.waterWidth / 2,
        this.borderPosition.waterStart[1]
      );
      ctx.moveTo(
        center[0] + this.borderPosition.waterWidth / 2,
        this.borderPosition.waterStart[1]
      );

      ctx.rect(
        this.borderPosition.waterStart[0],
        this.borderPosition.waterStart[1],
        this.borderPosition.waterWidth,
        this.borderPosition.waterHeight
      );
      ctx.fill();
    },
    startWater() {
      return window.setInterval(() => {
        if (this.wateDic) {
          this.ratey -= 0.005;
        } else {
          this.ratey += 0.005;
        }
        if (this.ratey >= 0.08) {
          this.wateDic = true;
        }
        if (this.ratey <= -0.08) {
          this.wateDic = false;
        }
        this.drawWater(this.ratex, this.ratey);
      }, 50);
    },
    startWateValue() {
      return window.setInterval(() => {
        if (Math.abs(this.mainData.Wl - this.item.value) > 1) {
          if (this.mainData.Wl > this.item.value) {
            this.item.value += 1;
            if (this.item.value > this.item.max) {
              this.item.value = this.item.max;
            }
          } else {
            this.item.value -= 1;
          }
        } else {
          this.item.value = this.mainData.Wl;
        }
      }, 50);
    },
    startWaterCurrent() {
      return window.setInterval(() => {
        var com = 1;
        var random = Math.random() * 50;
        if (parseInt(random) > 25) {
          com = -1;
        }
        if (this.mainData.Wl < 0) {
          this.mainData.Wl = 0;
        }
        this.mainData.Wl += Math.round(com * random, 2);
      }, 5000);
    },
    startPush(value) {
      var ctx = this.ctx;
      var start = [
        this.borderPosition.imgBorderStart[0] +
          this.borderPosition.imgBorderWidth,
        this.borderPosition.imgBorderStart[1] +
          this.borderPosition.imgBorderHeight,
      ];
      ctx.clearRect(start[0], start[1] - 200, 10000, 10000);
      if (value) {
        ctx.lineWidth = 1;
        var prate = 0;
        for (var i = 0; i < 4; i++) {
          prate += 0.05;
          ctx.beginPath();
          var s2 = [
            this.borderPosition.imgBorderStart[0] +
              this.borderPosition.imgBorderWidth,
            this.borderPosition.imgBorderStart[1] +
              this.borderPosition.imgBorderHeight -
              this.para.sweight * (i + 1) * 0.5,
          ];
          ctx.moveTo(s2[0], s2[1]);
          var e2 = [this.width, this.height - this.height * prate];
          var c2 = this.getControl(s2, e2, 10 * (prate * 10));
          var x2 = this.getCurpoint(s2, e2, c2, value);
          this.drawCur(
            ctx,
            60,
            10,
            (this.width -
              this.borderPosition.imgBorderStart[0] -
              this.borderPosition.imgBorderWidth) /
              3,
            x2[0],
            x2[1]
          );
          ctx.closePath();
          ctx.fill();
        }
      }
    },
    getCurpoint(start, end, c, t) {
      var x =
        (1 - t) * (1 - t) * start[0] + 2 * t * (1 - t) * c[0] + t * t * end[0];
      var y =
        (1 - t) * (1 - t) * start[1] + 2 * t * (1 - t) * c[1] + t * t * end[1];
      return [x, y];
    },
    getControl(s, e, d) {
      var x = (s[0] + e[0]) / 2;
      var y = (s[1] + e[1]) / 2;
      x = x + d;
      return [x, y];
    },
    drawCur(ctx, angel, hangle, line, a, b) {
      angel = (angel * Math.PI) / 180;
      hangle = (hangle * Math.PI) / 180;
      var p1 = [];
      var p2 = [];
      p1[0] = Math.cos(hangle) * line + a;
      p1[1] = Math.sin(hangle) * line + b;
      p2[0] = a + Math.cos(angel + hangle) * line;
      p2[1] = b + Math.sin(angel + hangle) * line;
      ctx.moveTo(a, b);
      var p = {
        p1: p1,
        p2: p2,
      };
      var c1 = [(a + p.p1[0]) / 2, (b + p.p1[1]) / 2];
      var c2 = [(p.p1[0] + p.p2[0]) / 2, (p.p1[1] + p.p2[1]) / 2];
      ctx.bezierCurveTo(c1[0], c1[1], p.p1[0], p.p1[1], c2[0], c2[1]);
      var c3 = [(p.p2[0] + a) / 2, (p.p2[1] + b) / 2];
      ctx.bezierCurveTo(p.p2[0], p.p2[1], c3[0], c3[1], a, b);
      ctx.stroke();
    },
    startPushWater() {
      return window.setInterval(() => {
        this.tvalue += 0.1;
        if (this.tvalue >= 1) {
          this.tvalue = 0;
        }
        this.startPush(this.tvalue);
      }, 20);
    },

    beginLeftTopChart(data) {
      var font = 12;
      if (window.innerWidth > 1366) {
        font = 20;
      }
      var on = data.AlarmCount;
      var off = data.EventCount;
      var sub = on + off;
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          left: 20,
          bottom: 20,
          orient: "vertical",
          data: [this.$t("报警次数"), this.$t("开阈次数")],
          textStyle: {
            color: "#ffffff",
            fontWeight: "normal",
          },
        },
        series: [
          {
            name: this.$t("报警率"),
            type: "pie",
            radius: ["30%", "60%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                formatter: [
                  `{title|${this.$t("总数量")}}`,
                  `{count|${sub}}`,
                ].join("\n"),
                show: true,
                position: "center",
                rich: {
                  title: {
                    color: "white",
                    fontSize: font,
                  },
                  count: {
                    color: "#8495c2",
                    fontSize: font,
                    padding: [0, 0, 10, 0],
                  },
                },
              },
            },
            labelLine: {
              normal: {
                show: true,
              },
            },
            data: [
              { value: on, name: this.$t("报警次数") },
              { value: off, name: this.$t("开阈次数") },
            ],
            color: [this.color1, this.color2],
          },
        ],
        grid: {
          top: 0,
          right: 100,
          bottom: 0,
          containLabel: false,
        },
      };
      this.leftTop.setOption(option, true);
      window.addEventListener("resize", () => {
        this.leftTop.resize();
      });
    },
    getOption(xAxis, yAxis, datas) {
      var obj = {
        textStyle: {
          color: "#8495c2",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "25%",
          containLabel: true,
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: datas,
        legend: {
          data: [],
          textStyle: {
            color: "#ffffff",
          },
          orient: "vertical",
          right: 50,
        },
      };
      return obj;
    },
    beginLeftBottomChart(data) {
      var datas = [];
      var names = [];
      for (var j = 0; j < data.length; j++) {
        var area = {};
        var line = "#00e5fc";
        if (j == 0) {
          area = {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(24,203,231,0.7)" },
                { offset: 0.5, color: "rgba(24,203,231,0.5)" },
                { offset: 1, color: "rgba(24,203,231,0.2)" },
              ]),
            },
          };
        }
        if (j == 1) {
          area = {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(149,143,69,0.9)" },
                { offset: 0.5, color: "rgba(149,143,69,0.5)" },
                { offset: 1, color: "rgba(24,203,231,0.2)" },
              ]),
            },
          };
          line = "#86fc21";
        }
        if (j == 2) {
          area = {
            normal: {
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(87,141,170,0.5)" },
                { offset: 0, color: "rgba(87,141,170,0.4)" },
                { offset: 1, color: "rgba(24,203,231,0.2)" },
              ]),
            },
          };
          line = "#6cce38";
        }
        names.push(data[j].Time);
        var online = [];
        var labels = [];
        for (var i = 0; i < data[j].Data.length; i++) {
          var obj = data[j].Data;
          online.push(obj[i].Count);
          labels.push(obj[i].Time);
        }
        datas.push({
          name: data[j].Time,
          type: "line",
          stack: data[j].Time,
          label: {
            normal: {
              show: false,
              position: "top",
              color: "white",
            },
          },
          smooth: true,
          sampling: "average",
          data: online,
          itemStyle: {
            color: line,
          },
          areaStyle: area,
        });
      }
      var x = {
        type: "category",
        boundaryGap: false,
        data: labels,
      };
      var y = {
        splitLine: {
          lineStyle: {
            color: "#8495c2",
          },
        },
        type: "value",
      };
      var option = this.getOption(x, y, datas);
      option.legend = {
        data: names,
        textStyle: {
          color: "#ffffff",
        },
      };

      (option.title = {
        show: true,
        text: this.$t("水位分析"),
        padding: [20, 0, 0, 20],
        textStyle: {
          color: "#ffffff",
        },
      }),
        this.leftBottom.setOption(option, true);
      window.addEventListener("resize", () => {
        this.leftBottom.resize();
      });
    },
    select() {
      var sid = this.$route.query.sid;
      this.$ksajax
        .ksPostPromise("/api/device/GetWaterLevelInfo", sid, true)
        .then((data) => {
          this.loadingCanvas = false;
          this.mainData = data;
          var self = data.LinkageConfigs.find((x) => x.LinkageName == "安全线");
          this.item.self = self ? self.ThresholdValue : null;
          var flag = data.LinkageConfigs.find((x) => x.LinkageName == "抽水线");
          this.item.flag = flag ? flag.ThresholdValue : null;
          var alarm = data.LinkageConfigs.find(
            (x) => x.LinkageName == "报警线"
          );
          this.item.alarm = alarm ? alarm.ThresholdValue : null;
          this.item.max = data.MeasuringRange;
          this.item.value = data.Wl;
          this.para.name = data.DeviceName;
          // if(this.item.value>this.item.alarm){
          //     this.alarm=true;
          //     this.$refs.alarm.start(true);
          // }
          //this.item.value=50;
          this.initCanvas();
          this.drawBorder();
          this.drawSensor();
          this.drawPie();
          this.drawRollBorder();
          this.drawRoll();
          this.drawWater(this.ratex, this.ratey);
          this.startWateValueThread = this.startWateValue();
          this.startWaterThread = this.startWater();
          //this.starstartWaterCurrentThread=this.startWaterCurrent();
          this.leftBottomChat();
          this.alarm =data.AlarmLampStatus==1? true:false;
          this.roll =data.WaterPumpStatus==1? true:false;
          console.log(data);
        })
        .catch((err) => {
          this.$throw(err, this);
          this.loadingCanvas = false;
        });
    },

    canvasFn: function () {
      this.leftTop = window.echarts.init(document.getElementById("leftTop"));
      this.leftBottom = window.echarts.init(
        document.getElementById("leftBottom")
      );
      this.beginLeftTopChart({ AlarmCount: 10, EventCount: 20 });
    },
    leftBottomChat() {
      this.leftBottomLoading = true;
      this.$ksajax
        .ksPostPromise(
          "/api/device/GetRecentlyWaterLevelDatas",
          this.mainData.DeviceSid,
          true
        )
        .then((data) => {
          var data1 = [];
          data.forEach((x) => {
            var m = new Date().getMonth();
            var sub = {
              Time: `${m}/${x[0].Day}`,
              Data: [],
            };
            x.forEach((y) => {
              var t = ("0" + y.Hour).substr(-2);
              sub.Data.push({
                Time: `${t}:00`,
                Count: y.Wl,
              });
            });
            data1.push(sub);
          });
          this.beginLeftBottomChart(data1);
          this.leftBottomLoading = false;
        })
        .catch((err) => {
          this.leftBottomLoading = false;
          this.$throw(err);
        });
    },
    openRoll(action) {
      var obj = {
        Mac: this.mainData.Serial,
        Fn: action,
      };
      return this.$ksajax.ksPostPromise(
        "/api/device/CtrlWaterLevel",
        obj,
        true
      );
    },
    waterSwChange(val) {
      var sw = val == "Off" ? 2 : 1;
      this.waterSwLoading = true;
      this.openRoll(sw)
        .then((data) => {
          this.waterSwLoading = false;
          if (!data.Status) {
            this.waterSw = false;
          }
        })
        .catch((err) => {
          this.throw(err);
          this.waterSwLoading = false;
        });
    },
    alarmSwChange(val) {
      var sw = val == "Off" ? 4 : 3;
      this.alarmSwLoading = true;
      this.openRoll(sw)
        .then((data) => {
          this.alarmSwLoading = false;
          if (!data.Status) {
            this.alarmSw = false;
          }
        })
        .catch((err) => {
          this.throw(err);
          this.alarmSwLoading = false;
        });
    },
  },
  watch: {
    alarm: function () {
      if (this.alarm) {
        this.$refs.alarm.start(true);
      } else {
        this.$refs.alarm.start(false);
      }
    },
    roll: function () {
      if (this.roll) {
        this.startRollThread = this.startRoll();
        this.startPushWaterThread = this.startPushWater();
      } else {
        window.clearInterval(this.startRollThread);
        window.clearInterval(this.startPushWaterThread);
        this.canvasRoll
          .getContext("2d")
          .translate(-this.canvasRoll.width / 2, -this.canvasRoll.height / 2);
        this.drawRoll();
        this.startPush(false);
      }
    },
    "$store.state.dataHubChange": function () {
      try {
        var data = this.$store.state.dataHub;
        if (data.CtgSid == "009") {
          if (this.mainData.DeviceSid == data.DeviceSid) {
            this.mainData.Wl = data.Data.Wl;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    "$store.state.actionHubChange": function () {
      try {
        var data = this.$store.state.actionHub;
        if (data.CtgSid == "009") {
          if (this.mainData.DeviceSid == data.DeviceSid) {
            if (data.ActionType == 1) {
              if (data.Status == 0) {
                this.alarm = false;
                this.alarmSw = "Off";
              } else {
                this.alarm = true;
                this.alarmSw = "On";
              }
            } else if (data.ActionType == 0) {
              if (data.Status == 0) {
                this.roll = false;
                this.waterSw = "Off";
              } else {
                this.roll = true;
                this.waterSw = "On";
              }
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      this.select();
      this.canvasFn();
    });
  },
  destroyed: function () {
    window.clearInterval(this.startWateValueThread);
    window.clearInterval(this.startWaterThread);
    window.clearInterval(this.startRollThread);
    window.clearInterval(this.startPushWaterThread);
    window.clearInterval(this.startWaterCurrentThread);
  },
};
</script>

