<template>
    <div class="white">
        <actionbar :select="true" :read="true" v-on:select="select" v-on:read="read"></actionbar>
        <el-table stripe=""
            ref="multipleTable"
            :data="items"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            @select="rowselected" :empty-text="$t('暂无数据')"
            > 
            <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column :label="$t('名称')" width="100"><template slot-scope="scope">{{scope.row.SZ.name}}</template></el-table-column>
              <el-table-column :label="$t('在线状态')"  width="100"><template slot-scope="scope">{{scope.row.SZ.online==0?$t('离线'):$t('在线')}} </template></el-table-column>
              <el-table-column :label="$t('回路个数')" width="100"><template slot-scope="scope">{{scope.row.SZ.section_count}}</template></el-table-column>
              <el-table-column :label="$t('合相电流')+'(A)'" width="100">
                  <template slot-scope="scope">
                      {{scope.row.SZ.three_phase_elec?scope.row.SZ.three_phase_elec.all_current:"-"}}
                 </template>
             </el-table-column>
              <el-table-column :label="$t('有功功率')+'(W)'" width="100">
                  <template slot-scope="scope">
                      {{scope.row.SZ.three_phase_elec?scope.row.SZ.three_phase_elec.all_active_power:"-"}}
                 </template>
             </el-table-column>
              <el-table-column :label="$t('无功功率')+'(W)'" width="100">
                  <template slot-scope="scope">
                      {{scope.row.SZ.three_phase_elec?scope.row.SZ.three_phase_elec.all_reactive_power:"-"}}
                 </template>
             </el-table-column>
              <el-table-column :label="$t('合相功率')+'(W)'" width="100">
                  <template slot-scope="scope">
                      {{scope.row.SZ.three_phase_elec?scope.row.SZ.three_phase_elec.all_apparent_power:"-"}}
                 </template>
             </el-table-column>
              <el-table-column :label="$t('三相电采集')" width="150"><template slot-scope="scope">
                  <el-button v-if="scope.row.SZ.three_phase_elec" class="ksbtn"  type="primary" 
                  round size="small" @click="three(scope.row.SZ.three_phase_elec)">{{$t("三相电")}}</el-button>
             </template></el-table-column>
              <el-table-column :label="$t('回路')">
                  <template slot-scope="scope">
                      <div>
                      <el-checkbox v-for="item in scope.row.SZ.sections" v-model="item.status" 
                      :true-label="1" :false-label="0"  :label="$t('回路')+item.id" :key="item.id" border  @change="close(scope.row.SZ,item)">
                      </el-checkbox></div>
                  </template>
            </el-table-column>
        </el-table>
        <b-modal id="modallg" ref="modallg" size="lg" v-bind:title="title" hide-footer
         header-bg-variant="success"
         body-text-variant="dark">
          <el-table   ref="multipleTable" :data="threeItems" tooltip-effect="dark" style="width: 100%">
              <el-table-column :label="$t('名称')" prop="name"></el-table-column> 
              <el-table-column :label="$t('电流')" prop="current"></el-table-column> 
              <el-table-column :label="$t('电压')" prop="voltage"></el-table-column> 
              <el-table-column :label="$t('有功功率')" prop="active"></el-table-column> 
              <el-table-column :label="$t('无功功率')" prop="reactive"></el-table-column> 
              <el-table-column :label="$t('合相功率')" prop="apparent"></el-table-column> 
          </el-table>
        </b-modal>
    </div>
</template>
<style>
.ksbtn{
    margin-left: 5px
}
</style>

<script>
import actionbar from "@/components/actionbar"
export default {
    components:{actionbar},
    data:function(){
        return {
            selected:null,
            items:[],
            loading:{},
            threeItems:[],
            title:this.$t("三相电")
        }
    },
    mounted:function(){
        this.select();
    },
    methods:{
        close(data,item){
             let obj={
                platform:1,
                cmds:[
                    {
                        code:9,
                        headSerial:1,
                        uid:data.uid,
                        addr:data.addr,
                        control:{
                                devtype:data.type,
                                loop:[{
                                    idx:item.id,
                                    on:item.status
                                }]
                            }  
                    }
                ]
            };
            var req={
                 "Method": "send",
                 "SZ":obj
            }
            this.loading=this.$loading();
             this.$ksajax.ksPostPromise("/api/agent/RequestSzApi",req,true).then(()=>{
                //刷新
                this.loading.close();
                this.select();
            }).catch(err=>{
                this.$throw(err);
                this.loading.close();
            })
        },
        three(data){
            this.threeItems=[];
            this.threeItems.push({
                name:"A相",
                current:data.a_current,
                voltage:data.a_voltage,
                active:data.a_active_power,
                reactive:data.a_reactive_power,
                apparent:data.a_apparent_power
            });
            this.threeItems.push({
                name:"B相",
                current:data.b_current,
                voltage:data.b_voltage,
                active:data.b_active_power,
                reactive:data.b_reactive_power,
                apparent:data.b_apparent_power
            });
            this.threeItems.push({
                name:"C相",
                current:data.c_current,
                voltage:data.c_voltage,
                active:data.c_active_power,
                reactive:data.c_reactive_power,
                apparent:data.c_apparent_power
            });
            this.$refs.modallg.show();
        },
        read(){
            if(!this.selected||this.selected.length==0){
                throw this.$t("请至少选择一个设备");
            }
            let obj={
                platform:1,
                cmds:[
                ]
            }
            var i=0;
            this.selected.forEach(x => {
                if(x.Custom.DeviceType.ModelNumber.match("K60")){
                    x.SZ.sections.forEach(e=>{
                i++;
                        obj.cmds.push({
                            code:8,
                            headSerial:i,
                            uid:x.SZ.uid,
                            addr:x.SZ.addr,
                            control:{
                                devtype:x.SZ.type,
                                loopidx:e.id
                            }  
                        })
                    })
                }
                else{
                i++;
                    obj.cmds.push({
                        code:8,
                        headSerial:i,
                        uid:x.SZ.uid,
                        addr:x.SZ.addr,
                        control:{
                            devtype:x.SZ.type,
                            loopidx:255
                        }  
                    })
                }
                i++;
                obj.cmds.push({
                    code:2,
                        headSerial:i,
                        uid:x.SZ.uid,
                        addr:x.SZ.addr,
                        control:{
                            devtype:x.SZ.type
                        }  
                })
            });
            var data={
                 "Method": "send",
                 "SZ":obj
            }
            //发送指令
            this.loading=this.$loading();
            this.$ksajax.ksPostPromise("/api/agent/RequestSzApi",data,true).then(()=>{
                //刷新
                window.setTimeout(()=>{
                this.loading.close();
                    this.select();
                },3000)
            }).catch(err=>{
                this.$throw(err);
                this.loading.close();
            })
        },
        select(){
            this.loading=this.$loading();
            let obj={
                "Method": "read",
                "SZ": {
                    "platform": 1,
                    "type": 1,
                    "skip": 0,
                    "limit":10000,
                    "name": ""
                    }
                }
            this.$ksajax.ksPostPromise("/api/agent/RequestSzApi",obj,true).then((data)=>{
                this.items=data.Items;
                this.loading.close();
            }).catch(err=>{
                this.loading.close();
                this.$throw(err);
            })
        },
        handleSelectionChange(val){
            this.selected=val;
        },
        rowselected(){}
    },
    
}
</script>

