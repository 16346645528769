<template>
    <div class="ligthview"   v-loading="loading">
        <div class="ligthview_l">
            <div>
                <box>
                    <div class="ksflex" style="height:100%;padding:10px">
                        <div style="flex:1">
                            <div class="maxnumber">
                                 0.2%
                            </div>
                            <div class="content_text">{{$t("亮灯率")}}%</div>
                        </div>
                        <div style="flex:1;text-align:right">
                            <img src="../../../../public/img/lighticon.png" />
                        </div>
                    </div>
                </box>
            </div>
        </div>
        <div class="ligthview_l">
            <div>
                <box>
                    <div class="ksflex" style="height:100%;padding:10px">
                        <div style="flex:1">
                            <div class="maxnumber">
                               62.5%</div>
                            <div class="content_text">{{$t("节能率")}}%</div>
                        </div>
                        <div style="flex:1;text-align:right">
                            <img src="../../../../public/img/saveicon.png" />
                        </div>
                    </div>
                </box>
            </div></div>
        <div class="ligthview_l">
            <div>
                <box>
                    <div class="ksflex" style="height:100%;padding:10px">
                        <div style="flex:1">
                            <div class="maxnumber">
                               96.72%</div>
                            <div class="content_text">{{$t("在线率")}}%</div>
                        </div>
                        <div style="flex:1;text-align:right">
                            <img src="../../../../public/img/onlineicon.png" />
                        </div>
                    </div>
                </box>
            </div></div>
        <div class="ligthview_2">
            <div>
                <box>

                    <div class="row" style="height:100%">
                                <div class="col col-sx-6" style="height:100%">
                                    <div style="height:100%; width:100%" id="leftTop">
                                        
                                    </div>
                                </div>
                                <div class="col col-sx-6 ksflexcol smhide" style="height:100%">
                                    <div style="flex:1;width:100%;text-align:center" class="ksflex">
                                        <div style="text-align:center;width:100%">
                                        <span class="content_text">{{$t("计划能耗")}}：</span>
                                        <span class="maxnumber" style="padding:0 10px 0 10px">{{current.EnergyPlan?current.EnergyPlan.PlanEnergy:0}}</span>
                                        <span class="content_text">kW-h</span>
                                        </div>
                                    </div>
                                    <div style="flex:1;width:100%;" class="ksflex">
                                        <div style="text-align:center;width:100%">
                                        <span class="content_text">{{$t("实际能耗")}}：</span>
                                        <span class="maxnumber" style="padding:0 10px 0 10px">{{current.EnergyPlan?current.EnergyPlan.RealEnergy:0}}</span>
                                        <span class="content_text">kW-h</span>
                                    </div>
                                    </div>
                                    <div style="flex:1;width:100%;" class="ksflex">
                                        <div style="text-align:center;width:100%">
                                        <span class="content_text">{{$t("差值")}}：</span>
                                        <span class="maxnumber" style="padding:0 10px 0 10px">
                                            <!-- {{current.EnergyPlan?current.EnergyPlan.RealEnergy:0-current.EnergyPlan?current.EnergyPlan.PlanEnergy:0}} -->
                                            300
                                            </span>
                                        <span class="content_text">kW-h</span>
                                    </div>
                                    </div>
                                    <div style="flex:1;width:100%;" class="ksflex">
                                        <div style="text-align:center;width:100%">
                                        <span class="content_text">{{$t("状态")}}：</span>
                                        <span  style="padding:0 10px 0 10px;color:white;font-size:1.5em">
                                            {{(current.EnergyPlan?current.EnergyPlan.RealEnergy:0-current.EnergyPlan?current.EnergyPlan.PlanEnergy:0)>0?$t("超出"):$t("正常")}}
                                        </span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                </box>
            </div>
        </div>
        <div class="ligthview_2">
            <div>
                <box>
                    
                             <div style="height:100%; width:100%" id="rightTop">
                                 
                             </div>
                </box>
            </div>
       </div>
        <div class="ligthview_2">
            <div>
                <box><div class="row" style="height:100%">
                                <div class="col col-xs-8" style="height:100%">
                                    <div style="height:100%; width:100%" id="leftBottom">
                                        
                                    </div>
                                </div>
                                <div class="col col-xs-4 ksflexcol smhide" style="height:100%">
                                    <div style="flex:1;width:100%;text-align:center" class="ksflex">
                                        <div style="text-align:center;width:100%">
                                        <span class="content_text">{{$t("本月能耗")}}：</span>
                                        <span class="maxnumber" style="padding:0 10px 0 10px">
                                            {{current.EnergyPandect?current.EnergyPandect.CurrentMonthEnergy.Energy:0}}
                                            </span>
                                        <span class="content_text">kW-h</span>
                                        </div>
                                    </div>
                                    <div style="flex:1;width:100%;" class="ksflex">
                                        <div style="text-align:center;width:100%">
                                        <span class="content_text">{{$t("上月能耗")}}：</span>
                                        <span class="maxnumber" style="padding:0 10px 0 10px">
                                            {{current.EnergyPandect?current.EnergyPandect.LastMonthEnergy.Energy:0}}
                                            </span>
                                        <span class="content_text">kW-h</span>
                                    </div>
                                    </div>
                                    <div style="flex:1;width:100%;" class="ksflex">
                                        <div style="text-align:center;width:100%">
                                        <span class="content_text">{{$t("同比")}}：</span>
                                        <span class="maxnumber" style="padding:0 10px 0 10px">
                                            {{current.EnergyPlan?current.EnergyPlan.RealEnergy:0-current.EnergyPlan?current.EnergyPlan.PlanEnergy:0}}
                                            </span>
                                    </div>
                                    </div>
                                    <div style="flex:1;width:100%;" class="ksflex">
                                        <div style="text-align:center;width:100%">
                                        <span class="content_text">{{$t("环比")}}：</span>
                                        <span class="maxnumber" style="padding:0 10px 0 10px">
                                            {{current.EnergyPlan?current.EnergyPlan.RealEnergy:0-current.EnergyPlan?current.EnergyPlan.PlanEnergy:0}}
                                            </span>
                                    </div>
                                    </div>
                                </div>
                            </div></box>
            </div>
        </div>
        <div class="ligthview_2">
            <div>
                <box>
                            <div style="height:100%; width:100%" id="rightBottom">

                            </div></box>
            </div>
       </div>
       <div class="clear"></div>
    </div>
</template>
<script>
import box from "@/components/box.vue"
export default {
    components:{box},
    data:function(){
        return{
            current:{
                
            },
            loading:true,
            leftTop:null,
            leftBottom:null,
            rightTop:null,
            rightBottom:null,
            color1:new window.echarts.graphic.LinearGradient( 0, 0, 1, 0,
                        [  {offset: 0, color: '#2978c9'},
                            {offset: 0.5, color: '#00b2e4'},
                            {offset: 1, color: '#00e7fd'}]),
            color2:new window.echarts.graphic.LinearGradient( 0, 0, 1, 0,
                        [  {offset: 0, color: '#ffad42'},
                            {offset: 0.5, color: '#ffc54b'},
                            {offset: 1, color: '#ffd450'}]),
            color3:new window.echarts.graphic.LinearGradient( 0, 0, 1, 0,
                        [  {offset: 0, color: '#f7500f'},
                            {offset: 0.5, color: '#eec54b'},
                            {offset: 1, color: '#f7500f'}]),
        }
    },
    mounted:function(){
        this.leftTop=window.echarts.init(document.getElementById("leftTop"));
         this.leftBottom=window.echarts.init(document.getElementById("leftBottom"));
        this.rightBottom=window.echarts.init(document.getElementById("rightBottom"));
         this.rightTop=window.echarts.init(document.getElementById("rightTop"));
        this.select();
    },
    methods:{
        getOption(xAxis,yAxis,datas){
            var obj={
                textStyle:
                 { 
                     color: '#8495c2'
                 },
                 tooltip : {
                     trigger: 'axis',
                     axisPointer : {   
                         type : 'shadow'   
                    }
                },
                grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                },
                xAxis:  xAxis, 
                yAxis: yAxis,
                     series:datas,
                      legend:{
                          data:[], 
                            textStyle:
                                { 
                                    color: '#ffffff'
                                },
                        orient: 'vertical',
                        right: 50,
                }
            }
                return obj;
        },
        beginLeftTop(j,s){
            var on=j
            var off=s;
            var option = {
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b}: {c} ({d}%)"
            },
            legend: {
                left:5,
                bottom:5,
                orient:"vertical",
                data:[this.$t('计划能耗'),this.$t('实际能耗')], 
                            textStyle:
                                { 
                                    color: '#ffffff'
                                }
            },
            series: [
                {
                    name:this.$t('用能计划'),
                    type:'pie',
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                           // formatter:[ '{title|总灯数}',`{count|${sub}}`].join('\n'),
                            show: false,
                            position: 'center',
                            rich:{
                                title:{
                                    color:"white",
                                    fontSize:20
                                },
                                count:{
                                    color:"#8495c2",
                                    fontSize:14,
                                    padding: [ 0, 0, 10,0],
                                }
                            }
                        },
                    },
                    labelLine: {
                        normal: {
                            show: true
                        }
                    },
                    data:[
                        {value:on, name:this.$t('计划能耗')},
                        {value:off, name:this.$t('实际能耗')},
                        ],
                    color:[this.color1,this.color2]
                    },
                    
                ],
                grid: {
                    top:0, 
                    right: 100,
                    bottom: 0,
                    containLabel: false
                }
            };
            option.title= {
                     show: true,
                     text:this.$t("用能计划"),
                     "padding":[5, 0, 0 ,5], 
                     textStyle:
                        { 
                            color: '#ffffff'
                        },
                    }
            this.leftTop.setOption(option,true);
      window.addEventListener("resize", () => {
        this.leftTop.resize();
      });
        },
        beginRightTop(month){
            var datas=[];
            var names=[];
            month.forEach(x => {
                var dt=[];
                x.Data.forEach(y=>{
                    dt.push(y.Energy);
                })
                names.push(x.Year+"");
                datas.push({
                    name:x.Year,
                    type:'line',
                    stack: x.Year,
                    smooth:true,
                    areaStyle: {},
                    data:dt
                })
            });
                 var x={
                    type:"category",
                    boundaryGap : false,
                    data:['1','2','3','4','5','6','7','8','9','10','11','12']
                }
                var y={
                    splitLine:{
                        lineStyle:{
                            color:"#8495c2"
                        },
                        show:false
                    },
                    type:"value"
                }
                var option=this.getOption(x,y,datas); 
                option.title= {
                     show: true,
                     text:this.$t("横向对比分析"),
                     "padding":[5, 0, 0 ,5], 
                     textStyle:
                        { 
                            color: '#ffffff'
                        },
                    };
                option.legend= {
                data:names,
                   textStyle:
                                { 
                                    color: '#ffffff'
                                }
            },
            option.color= [this.color1,this.color2,this.color3]
                this.rightTop.setOption(option,true);
      window.addEventListener("resize", () => {
        this.rightTop.resize();
      });
        },
        beginLeftBottom(Energy){
          var option = {
                textStyle:
                 { 
                     color: '#8495c2'
                 },
                    color: [this.color2],
                    tooltip : {
                        trigger: 'axis',
                        axisPointer : {            // 坐标轴指示器，坐标轴触发有效
                            type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis : [
                        {
                            type : 'category',
                            data : [this.$t('当月能耗'), this.$t('下月能耗'), this.$t('去年同期')],
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis : [
                        {
                            type : 'value'
                        }
                    ],
                    series : [
                        {
                            name:this.$t('能耗'),
                            type:'bar',
                            barWidth: '60%',
                            data:[Energy.CurrentMonthEnergy.Energy,
                             Energy.LastMonthEnergy.Energy,
                              Energy.LastYearSameMonthEnergy.Energy
                              ]
                        }
                    ]
                };
                option.title= {
                     show: true,
                     text:this.$t("能耗分析(月)"),
                     "padding":[5, 0, 0 ,5], 
                     textStyle:
                        { 
                            color: '#ffffff'
                        },
                    };
                this.leftBottom.setOption(option,true);
      window.addEventListener("resize", () => {
        this.leftBottom.resize();
      });
        },
        beginRightBottom(Energy){
            
          var option = {
                textStyle:
                 { 
                     color: '#8495c2'
                 },
                    color: [this.color3],
                    tooltip : {
                        trigger: 'axis',
                        axisPointer : {            // 坐标轴指示器，坐标轴触发有效
                            type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis : [
                        {
                            type : 'category',
                            data : ['2016', '2017', '2018'],
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis : [
                        {
                            type : 'value'
                        }
                    ],
                    series : [
                        {
                            name:'能耗',
                            type:'bar',
                            barWidth: '60%',
                            data:[Energy[0].Energy+Math.random()*10,
                             Energy[1].Energy+Math.random()*10,
                              Energy[2].Energy+Math.random()*10
                              ]
                        }
                    ]
                };
                option.title= {
                     show: true,
                     text:this.$t("能耗分析(年)"),
                     "padding":[5, 0, 0 ,5], 
                     textStyle:
                        { 
                            color: '#ffffff'
                        },
                    };
                this.rightBottom.setOption(option,true);
      window.addEventListener("resize", () => {
        this.rightBottom.resize();
      });
        },
        select(){
            this.loading=true;
            this.$ksajax.ksPostPromise("/api/overview/GetLampStatistics","",true).then(data=>{
                this.loading=false;
                this.current=data;
                this.current.EnergySavingRate=90;
                this.current.OnlineRate=92;
                this.current.LightUpRate=100;
                this.current.EnergyPlan.PlanEnergy=3000;
                this.current.EnergyPlan.RealEnergy=2700;
                this.beginLeftTop(this.current.EnergyPlan.PlanEnergy,this.current.EnergyPlan.RealEnergy);
                this.current.MonthParallel.forEach(x => {
                    x.Data.forEach(y=>{
                        y.Energy=3000+(Math.random()*1000);
                    })
                });
                 this.beginRightTop(this.current.MonthParallel);
                 this.current.EnergyPandect.CurrentMonthEnergy.Energy=2700;
                 this.current.EnergyPandect.LastMonthEnergy.Energy=3000;
                 this.current.EnergyPandect.LastYearSameMonthEnergy.Energy=3100;
                this.beginLeftBottom(this.current.EnergyPandect);
                 this.beginRightBottom(this.current.YearParallel);
            }).catch(err=>{
                this.$throw(err);
                this.loading=false;
            })
        }
    }
}
</script>
