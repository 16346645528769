<template>
  <div :style="style" class="portLine">
    <div
      v-for="item in ports"
      :key="item.Sid"
      :class="current!=item.Sid? 'vportDetail portPosition':'vportDetail portPosition portCheck'"
      :style="styleDetail"
      @click="$emit('portClick',item.Sid)"
    >
      <div  v-if="item.Status == 0" class="ksflexcol">
        空闲
      </div>
      <div v-else class="ksflexcol carAnimateIn" style="height:100%">
        <div style="text-align: center">{{ item.Plate || "无车牌" }}</div>
        <div style="flex: 1; padding: 5px 20%;width:100%">
          <img
            :src="coverImg"
            style="width: 100%; height: auto; max-height: 100%"
            class="carRevert"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.vportDetail {
  display: inline-block;
  cursor: pointer;
}
.carRevert {
  transform: rotateZ(180deg);
}
</style>
<script>
export default {
  props: ["vheight", "ports","width","current"],
  data() {
    return {
      style: {
        height: "0px",
        width: "auto",
        "white-space": "nowrap",
      },
      styleDetail: {
        height: "0px",
        width: "0px",
      },

      coverImg: require("@/assets/img/ic_car.png"),
    };
  },
  watch: {
    vheight() {
      this.init();
    },
    width(){
        this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.style.height = this.vheight + "px";
      this.styleDetail.height = this.vheight + "px";
      this.styleDetail.width = this.vheight / 1.5 + "px";
      this.style.width=this.width+"px";
    },
  },
};
</script>