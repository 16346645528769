<template>
  <div class="broadtimmer white">
    <actionbar
      v-bind:select="true"
      v-bind:insert="true"
      :modify="true"
      v-bind:del="true"
      v-on:select="select"
      v-on:insert="insert"
      v-on:modify="modify"
      v-on:delete="del"
    ></actionbar>
    <div>
      <el-table
      stripe=""
        :data="items"
        class="demo-table"
        @current-change="handleCurrentChange"
        highlight-current-row :empty-text="$t('暂无数据')"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item :label="$t('定时终端')">
                <div
                  v-for="item in $store.getters.getBroadById(scope.row.Tids)"
                  :key="item.value"
                >{{ item.text}}</div>
              </el-form-item>
              <el-form-item :label="$t('播放文件')">
                <div
                  v-for="item in $store.getters.getMediaById(scope.row.ProgIds)"
                  :key="item.value"
                >{{item.text}}.mp3</div>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column :label="$t('序号')" prop="ID"></el-table-column>
        <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
        <el-table-column :label="$t('类型')">
          <template slot-scope="scope">
            <!-- <span v-if='!it.subvalue'>{{scope.row[it.value]}}</span> -->
            <span v-if="scope.row.Type=='4'">{{$t("一次性任务")}}</span>
            <span v-if="scope.row.Type=='3'">{{$t("每月任务")}}</span>
            <span v-if="scope.row.Type=='2'">{{$t("每周任务")}}</span>
            <span v-if="scope.row.Type=='1'">{{$t("每天任务")}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('开始时间')" prop="StartTime"></el-table-column>
        <el-table-column :label="$t('播放模式')">
          <template slot-scope="scope">
            <span v-if="scope.row.PlayMode=='0'">{{$t("顺序播放")}}</span>
            <span v-if="scope.row.PlayMode=='1'">{{$t("随机播放")}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('播放音量')" prop="PlayVol"></el-table-column>
        <el-table-column :label="$t('启用状态')">
          <template slot-scope="scope">
            <span v-if="scope.row.Enable=='0'">{{$t("冻结")}}</span>
            <span v-if="scope.row.Enable=='1'">{{$t("启用")}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('播放状态')">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.Status=='0'"
              type="primary"
              circle
              size="small"
              @click="play(scope.row.ID,true)"
            >
              <i class="fa fa-play"></i>
            </el-button>
            <el-button
              v-if="scope.row.Status=='1'"
              type="primary"
              circle
              size="small"
              @click="play(scope.row.ID,false)"
            >
              <i class="fa fa-stop"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <b-modal id="timmerModal" ref="timmerModal" :title="title" size="lg" hide-footer>
        <mtimmer
          v-on:submit="submit"
          ref="mtimmer"
          :form="form"
          :insert="isinsert"
          :MethodsSelected="MethodsSelected"
        ></mtimmer>
      </b-modal>
    </div>
  </div>
</template>
<style scoped>
i.fa {
  padding: 0px;
}
.el-table .warning-row {
  background: oldlace;
}
.el-table .success-row {
  background: #f0f9eb;
}
</style>

<script>
import actionbar from "@/components/actionbar";
import itc from "@/assets/utils/itchttp.js";
import { formatDate } from "@/assets/utils/datef.js";
import mtimmer from "./timmer/mtimmer.vue";
export default {
  components: {
    actionbar,
    mtimmer
  },
  data: function() {
    return {
      MethodsSelected: 0,
      isinsert: true,
      form: this.getInsertData(),
      items: [],
      loading: {},
      currentRow: 0,
      title: "",
      sid: ""
    };
  },
  mounted: function() {
    var st = new Promise((res, rej) => {
      this.loading = this.$loading({ fullscreen: true });
      if (this.$store.state.broads) {
        res();
      } else {
        //下载设备
        let req = {
          Index: 0,
          Count: 100000
        };
        this.$ksajax
          .ksPostPromise("/api/device/GetDeviceBroadcasts", req, true)
          .then(data => {
            this.$store.commit("setBroads", data.Items);
            res();
          })
          .catch(err => {
            rej(err);
          });
      }
    });
    st.then(() => {
      return new Promise((res, rej) => {
        if (this.$store.state.meidas) {
          res();
        } else {
          let data = {
            DirId: this.$store.state.project.BroadcastDirId
          };
          //下载媒体资源
          itc
            .postData(
              this.$store.state.broadcast.url,
              "MLListDir",
              data,
              this.$store.state.broadcast.session
            )
            .then(data => {
              this.$store.commit("setMedia", data.Items);
              res();
            })
            .catch(err => {
              rej(err);
            });
        }
      });
    })
      .then(() => {
        //查询
        this.select(true);
      })
      .catch(err => {
        if (this.loading.close) {
          this.loading.close();
        }
        this.$throw(err);
      });
  },
  methods: {
    select(noloading) {
      if (!noloading) {
        this.loading = this.$loading({ fullscreen: true });
      }
      let obj = { TaskIDs: [] };
      itc
        .postData(
          this.$store.state.broadcast.url,
          "TaskList",
          obj,
          this.$store.state.broadcast.session
        )
        .then(data => {
          var devs = this.$store.state.borads;
          this.items = [];
          if (devs && data.Items) {
            devs.forEach(x => {
              data.Items.forEach(y => {
                if (y.Tids.find(z => z == x.Serial)) {
                  if (!this.items.find(g => g.ID == y.ID)) {
                    this.items.push(y);
                  }
                }
              });
            });
          }
          this.loading.close();
        })
        .catch(err => {
          if (this.loading.close) {
            this.loading.close();
          }
          this.$throw(err);
        });
    },
    play(id, isplay) {
      let obj = {
        TaskID: id
      };
      this.loading = this.$loading({ fullscreen: true });
      let meth = isplay ? "TaskManualStart" : "TaskManualStop";
      itc
        .postData(
          this.$store.state.broadcast.url,
          meth,
          obj,
          this.$store.state.broadcast.session
        )
        .then(() => {
          for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].ID === id) {
              this.items[i].Status = isplay ? 1 : 0;
            }
          }
          this.loading.close();
        })
        .catch(error => {
          this.$throw(error);
          this.loading.close();
        });
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    insert() {
      this.title = this.$t("新增");
      this.form = this.getInsertData();
      this.isinsert = true;
      this.MethodsSelected = 0;
      this.$refs.timmerModal.show();
    },
    getInsertData() {
      var ret = {
        Tids: [],
        ProgIds: [],
        WeekItem: {
          DaysInWeek: [1, 2, 3, 4, 5, 6, 7],
          Every: 1
        },
        MonthItem: {
          MonthsInYear: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          DayInMonth: [1]
        },
        DayItem: {
          Every: 1
        },
        Type: 1,
        StartTime: formatDate(new Date(), "yyyy/MM/dd hh:mm:ss"),
        Enable: 1,
        Status: 1,
        PlayVol: 50,
        Name: this.$t("名称"),
        PlayMode: 1,
        RepeatTime: 1,
        Length: 1
      };
      return ret;
    },
    submit(data) {
      let obj = {
        Item: data
      };
      this.loading = this.$loading({ fullscreen: true });
      new Promise((res, rej) => {
        if (this.isinsert) {
          itc
            .postData(
              this.$store.state.broadcast.url,
              "TaskCreate",
              obj,
              this.$store.state.broadcast.session
            )
            .then(() => {
              res();
            })
            .catch(err => {
              rej(err);
            });
        } else {
          itc
            .postData(
              this.$store.state.broadcast.url,
              "TaskUpdate",
              obj,
              this.$store.state.broadcast.session
            )
            .then(() => {
              res();
            })
            .catch(err => {
              rej(err);
            });
        }
      })
        .then(() => {
          this.$refs.timmerModal.hide();
          this.select(true);
        })
        .catch(err => {
          this.$throw(err);
          this.$refs.timmerModal.hide();
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    modify() {
      if (!this.currentRow) {
        throw this.$t("选择一条数据");
      }
      this.isinsert = false;
      this.form = this.currentRow;
      this.form.StartTime=new Date(this.form.StartTime);
      if (!this.form.WeekItem) {
        this.form.WeekItem = {
          DaysInWeek: [1, 2, 3, 4, 5, 6, 7],
          Every: 1
        };
      }
      if (!this.form.MonthItem) {
        this.form.MonthItem = {
          MonthsInYear: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          DayInMonth: [1]
        };
      }
      if (!this.form.DayItem) {
        this.form.DayItem = {
          Every: 1
        };
      }
      if (this.currentRow.Length) {
        this.MethodsSelected = 1;
      } else {
        this.MethodsSelected = 0;
      }
      this.$refs.timmerModal.show();
    },
    del() {
      if (!this.currentRow) {
        throw this.$t("选择一条数据");
      }
      this.loading = this.$loading({ fullscreen: true });
      this.$confirm(this.$t("删除确认"), this.$t("删除"), {
        confirmButtonText: this.$t("确认"),
        cancelButtonText: this.$t("退出"),
        type: "warning"
      })
        .then(() => {
          let obj = {
            TaskID: this.currentRow.ID
          };
          return itc.postData(
            this.$store.state.broadcast.url,
            "TaskDelete",
            obj,
            this.$store.state.broadcast.session
          );
        })
        .then(() => {
          this.select(true);
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    }
  }
};
</script>

