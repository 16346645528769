<template>
    <div class="ksflex smart_device white">
        <div class="smart_device_left">
             <el-menu
      default-active="0"
      class="el-menu-vertical-demo"
      @select="select"
      >
      <el-menu-item index="0">
        <i class="el-icon-document"></i>
        <span slot="title">网关管理</span>
      </el-menu-item>
      <el-menu-item index="1">
        <i class="el-icon-setting"></i>
        <span slot="title">灯具管理</span>
      </el-menu-item>
    </el-menu>
        </div>
        <div class="smart_device_right">
            <device v-if="isLight"></device>
            <gate v-else></gate>
        </div>
    </div>
</template>
<style scoped>
ul{
    width: 100% !important;
}
.smart_device{
    height: 100%
}
.smart_device_left{
    width: 300px;
    height: 100%;
}
.smart_device_right{
    flex: 1;
    height: 100%;
    padding-left: 15px;
}
</style>
<script>
import device from "./comms/addDevice"
import gate from "./comms/addGateway"
export default {
    data(){
        return{
            isLight:false
        }
    },
    mounted(){
        this.select(0);
    },
    components:{device,gate},
    methods:{
        select(index){
            this.isLight=index==1;
        }
    }
}
</script>