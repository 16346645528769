<template>
  <div v-loading="loading" style="height:100%">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      v-bind:insert="true"
      v-bind:del="true"
      v-on:select="select"
      v-on:insert="insert"
      v-on:modify="modify"
      v-on:delete="del"
    ></actionbar>

    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
      @shown="onModalLoaded"
    >
      <b-form @submit="onSubmit">
        <b-form-group :label="$t('设备类型')" label-cols="2">
          <el-select v-model="form.type" clearable placeholder="请选择">
            <el-option
              v-for="item in selectOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </b-form-group>
        <b-form-group :label="$t('设备名')" label-cols="2">
          <b-form-input v-model="form.name"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('网关地址')" label-cols="2">
          <b-form-input v-model="form.addr"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('设备序列号')" label-cols="2">
          <b-form-input v-model="form.serial"></b-form-input>
        </b-form-group>
    
        <b-form-group :label="$t('是否加密')" label-cols="2">
          <b-form-checkbox v-model="form.encryption"></b-form-checkbox>
        </b-form-group>
        <b-form-group :label="$t('位置')" label-cols="2">
          <b-form-input
            type="text"
            v-model="form.address"
            placeholder="Address"
            v-on:keyup.enter.native="setAddress"
          ></b-form-input>
          <ksmap
            ref="map"
            v-on:mapMoveEnd="mapMoveEnd"
            width="100%"
            :showCenter="true"
            height="200px"
            v-bind:lat="0"
            v-bind:lng="0"
            v-bind:zoom="25"
          ></ksmap>
        </b-form-group>

        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width:100px" v-on:click="hidden">Cancel</b-button>
            <b-button type="button" style="width:100px" variant="primary" v-on:click="onSubmit">OK</b-button>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar.vue";
import fake from "@/assets/utils/fake";
import tablepage from "@/components/tablepage";
import ksmap from "@/components/map";
export default {
  components: { actionbar, tablepage, ksmap },
  data() {
    return {
      items: [],
      index: 0,
      count: 8,
      total: 0,
      current: null,
      loading: false,
      sid: "",
      title: "新增网关",
      form: this.getResetData(),
      selectOption: [
        {
          label: "勤上单灯控制器V1.0",
          value: 0
        },
        {
          label: "勤上单灯控制器V1.1.5",
          value: 1
        },
        {
          label: "勤上单灯控制器V2.0",
          value: 2
        }
      ],
      cols: [
        { name: this.$t("设备名"), value: "DeviceName" },
        { name: this.$t("设备类型"), value: "TypeName" },
        { name: this.$t("设备型号"), value: "ModelNumber" },
        { name: this.$t("设备序列号"), value: "serial" },
        { name: this.$t("网关地址"), value: "addrStr" },
        { name: this.$t("创建时间"), value: "addDate" },
        { name: this.$t("修改时间"), value: "modifyDate" },
        { name: this.$t("在线状态"), value: "online",state:1 }
      ]
    };
  },
  mounted() {
    this.selectData();
  },
  methods: {
    select() {
      this.loading = true;
      window.setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    insert() {
      this.title = this.$t("新增");
      this.$refs.modallg.show();
    },
    hidden() {
      this.$refs.modallg.hide();
    },
    onSubmit() {
      this.hidden();
      this.select();
    },
    getResetData() {
      return {
        latitude: 0,
        longitude: 0,
        address: "",
        type: "",
        name: "",
        addr: "",
        register_pkg: "",
        formula: "",
        poll_interval: 15,
        section_count: 4,
        encryption: false
      };
    },
    mapMoveEnd(latlng) {
      this.form.latitude = latlng.lat + "";
      this.form.longitude = latlng.lng + "";
      console.log(this.form);
      this.$refs.map.getAddressByPostion(latlng.lat, latlng.lng, address => {
        let add = address.regeocode.formattedAddress;
        this.form.address = add;
      });
    },
    setAddress() {
      this.$refs.map.setAddress(this.form.address);
    },
    onModalLoaded() {
      this.$refs.map.setCenter(
        this.form.latitude || 22.98987,
        this.form.longitude || 114.000364
      );
    },
 modify() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      this.title = this.$t("修改");
      this.method = "update";
      this.form = {
        latitude: 0,
        longitude: 0,
        address: this.current.address,
        type: 2,
        serial:this.current.serial,
        name: this.current.DeviceName,
        addr: this.current.addrStr,
        register_pkg: this.current.register_pkg,
        formula: this.current.formula,
        poll_interval: 15,
        section_count: 4,
        encryption: false
      };
      this.$refs.modallg.show();
    },
    del() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      this.$confirm(this.$t("删除确认"), this.$t("删除"), {
        confirmButtonText: this.$t("确认"),
        cancelButtonText: this.$t("退出"),
        type: "warning"
      })
    },
    tableCreated() {
      // this.select();
    },
    currentPage(val) {
      this.index = (val - 1) * this.count;
      this.selectData();
    },
    currentRow(val) {
      this.current = val;
    },
    selectData() {
      var gs = fake.getFakeLightDeath();
      var items = [];
      var i=0;
      gs.forEach(x => {
        i+=1;
        items.push({
          DeviceName: x.name,
          TypeName:"勤上单灯控制器V2.0",
          ModelNumber:"KS-G002",
          longitude: x.lng,
          address: "东莞市常平镇围面前路",
          type: 0,
          name: x.name,
          addrStr: "FCFC9AC1"+i,
          serial: "57784181DS"+i,
          formula: "1",
          poll_interval: 15,
          section_count: 4,
          encryption: false,
          online:1,
          modifyDate:"2022/03/04",
          addDate:"2022/02/25"
        });
      });
      this.total=gs.length;
      this.items=items.splice(this.index,this.count);
      console.log(this.index,this.count);
    }
  }
};
</script> 