<template>
    <div class="" v-loading="loading" style="position:relative; height:100%">
       
                 <div style="position:absolute;right:20px;top:10px;z-index:999;">
           <i class="fa fa-table fa-2x " style="cursor: pointer;color:8495c2" @click="tabled"></i>
        </div>
        <ksmap v-show="map"
        ref="map" width='100%' :showCenter="false" height="100%"
          v-bind:lat='0' v-bind:lng='0'
          v-bind:zoom=25>
          </ksmap>
          <div v-show="!map">
                 <el-table class="kstable" :data="items" stripe=""
                style="width: 100%"  highlight-current-row :empty-text="$t('暂无数据')"> 
                 <el-table-column prop="DeviceName" :label="$t('设备名称')"></el-table-column>
                 <el-table-column prop="Serial" :label="$t('序号')"></el-table-column>
                 <el-table-column prop="Voltage" :label="$t('电压')"></el-table-column>
                 <el-table-column  :label="$t('详情')">
                     <template slot-scope="scope">
                         <router-link :to="'/admin/hydrant/detail?sid='+scope.row.Sid">{{$t("详情")}}</router-link>
                         </template>
                 </el-table-column>
              </el-table>
          </div>
    </div>
</template>
<script>
import ksmap from '@/components/map'
import box from "@/components/box.vue"
import {BaseUrl} from "@/assets/utils/config"
export default {
    components:{ksmap,box},
    data:function(){
        return {
            selected:{},
            loading:true,
            map:true,
            items:[],
             options: [
          { text: '街道1', value: 'radio1' },
          { text: '街道2', value: 'radio1' },
          { text: '街道3', value: 'radio1' },
        ]
        }
    },
    mounted:function(){
        this.select();
    },
    methods:{
        tabled(){
            this.map=!this.map;
            if(this.map){
                this.$refs.map.setFilter(0);
            }
        },
        select(){
            let obj={
                Index:0,
                Count:100000
            }
            this.$ksajax.ksPostPromise("/api/device/GetFireHydrants",obj,true).then((data)=>{
                 this.loading=false;
                 var device=[];
                 this.items=data.Items;
                 data.Items.forEach(x => {
                     device.push(
                         {
                             lat:x.Lat,
                             lng:x.Lng,
                             icon:`${BaseUrl}/img/new/ic_xiaofx.png`,
                             url:"/admin/hydrant/detail?sid="+x.Sid,
                             title:x.DeviceName
                         },
                         
                        //  {
                        //      lat:x.Lat+0.0005,
                        //      lng:x.Lng+0.0003,
                        //      icon:require("@/assets/img/ic_car.png"),
                        //      url:"/admin/car/detail?sid="+x.Sid,
                        //      title:x.DeviceName
                        //  }
                     )
                 });
                 this.$refs.map.addDevice(device,0);
             }).catch((err)=>{
                 this.$throw(err,this);
                 this.loading=false;
             })
        }
    }
}
</script>
