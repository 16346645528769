<template>
  <div class="white" style="height:100%">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :insert="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:insert="insert"
    ></actionbar>
    <el-table stripe=""
      :data="items"
      style="width: 100%"
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
      <el-table-column :label="$t('描述')" prop="Desc"></el-table-column>
      <el-table-column :label="$t('联系人')" prop="Contacter"></el-table-column>
      <el-table-column :label="$t('联系电话')" prop="ContactPhone"></el-table-column>
      <el-table-column :label="$t('模板')">
        <template slot-scope="scope">
          <el-checkbox disabled checked v-for="i in scope.row.Templetes" :key="i.Sid">{{i.Name}}</el-checkbox>
        </template>
      </el-table-column>
    </el-table>

    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      :title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('名称')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Name" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('描述')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Desc" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('联系人')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.ContactName" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('联系电话')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.ContactPhone" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('模板')" :label-cols="2">
          <b-form-checkbox-group
            :options="templetes"
            value-field="Sid"
            text-field="Name"
            v-model="modalData.Templetes"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width:100px" v-on:click="hiden('modallg')">Cancel</b-button>
            <b-button type="submit" style="width:100px" variant="primary">OK</b-button>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
export default {
  components: { actionbar },
  data: function() {
    return {
      current: {},
      items: [],
      loading: {},
      modalData: {},
      templetes: [],
      title: ""
    };
  },
  mounted: function() {
    this.select();
  },
  methods: {
    select() {
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/alarm/GetPartments", "", true)
        .then(r => {
          this.items = r;
          this.loading.close();
        })
        .catch(err => {
          this.loading.close();
          this.$throw(err);
        });
    },
    modify() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/alarm/GetTempletes", "", true)
        .then(r => {
          this.loading.close();
          var list = [];
          for (var i = 0; i < this.current.Templetes.length; i++) {
            list.push(this.current.Templetes[i].PTemplateSid);
          }
          this.templetes = r;
          this.modalData = {
            Sid: this.current.Sid,
            Name: this.current.Name,
            Desc: this.current.Desc,
            ContactName: this.current.Contacter,
            ContactPhone: this.current.ContactPhone,
            Templetes: list
          };
          this.title = this.$t("修改");
          this.$refs.modallg.show();
        })
        .catch(err => {
          this.loading.close();
          this.$throw(err);
        });
    },
    insert() {
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/alarm/GetTempletes", "", true)
        .then(r => {
          this.loading.close();
          this.templetes = r;
          this.modalData = {
            Sid: "",
            Name: "",
            Desc: "",
            ContactName: "",
            ContactPhone: "",
            Templetes: []
          };
          this.title = this.$t("新增");
          this.$refs.modallg.show();
        })
        .catch(err => {
          this.loading.close();
          this.$throw(err);
        });
    },
    currentRow(val) {
      this.current = val;
    },
    hiden(name) {
      if (!name) {
        this.$refs.modallg.hide();
      } else {
        this.$refs[name].hide();
      }
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/alarm/AddPartments", this.modalData, true)
        .then(() => {
          this.loading.close();
          this.hiden();
          this.select();
        })
        .catch(err => {
          this.$throw(err);
          this.hiden();
        });
    }
  }
};
</script>
