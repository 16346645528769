<template>
    <div class="main">
        <div id="main_top">
            <div id="main_top_left">
                <b-img :src="logopic" fluid></b-img>
            </div>
            <div id="main_top_center">智慧城市</div>
            <div id="main_top_right">
                <i class="fa fa-user-circle-o"></i>
                <span class="content_text">智慧城市用户</span>
            </div>
        </div>
        <div class="container-fluid" id="main_content">
            <div class="row" style="height:100%">
                <div class="col-sm-12 col-lg-4 col-md-4" id="main_cotnent_left"  style="height:100%;">
                    <div class="main_content_left_top">
                        <div style="height:100%;width:100%;" id="onlineChat"></div>
                    </div>
                    <div class="main_content_left_bottom">
                        <div style="height:100%;width:100%;" id="leftBottomChat"></div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-4 col-md-4">
                        <div style="height:10%">
                            <div class="row" style="text-align:center">
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                            </div>
                        </div>
                        <div style="height:60%" id="myChart">
                            
                        </div>
                        <div style="height:30%">
                            
                            <div class="row" style="text-align:center">
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                            </div>
                            
                            <div class="row" style="text-align:center">
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                                <div class="col">
                                    <span style="color:white;font-size:2em;display:block">2546</span>
                                    <span class="content_text">智慧灯杆</span>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="col-sm-12 col-lg-4 col-md-4" id="main_cotnent_left"  style="height:100%;">
                    <div class="main_content_right_top">
                        <div class="main_flex">
                            <div id="rightTopChat"></div>
                            <div style="position:relative">
                                <alarm ref="alarm" right="15%"></alarm>
                                <div style="padding:20px 0 0 5px">
                                    <div style="color:white;font-size:24px">告警分析</div>
                                    <div style="font-size:80%;" class="content_text">Alarm Analysis</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main_content_right_bottom">
                         <div style="height:100%;width:100%;" id="rightBottomChat"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.col_flex{
    display:-moz-flex;
    display:-o-flex;
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-items: center;
}
.col_flex>div{
    width: 100%;
}
.main_flex{
    display:-moz-flex;
    display:-o-flex;
    display: flex;
    height: 100%;
    flex-direction: column
}
.main_flex>div{
    flex: 1;
    height: 100%;
    margin-bottom: 20px;
    background-image:  url("../assets/img/cute/kuang_9.png");
    background-size: 100% 100%;
}
.main_content_right_top{
    height: calc(50% - 20px);
}
.main_content_right_bottom{
    height: calc(50% - 20px);
    background-image: url("../assets/img/cute/kuang_9.png");
    background-size: 100% 100%;
    margin-bottom: 20px;
    padding-top: 30px;
}
.main_content_left_top{
    height: calc(50% - 20px);
    background-image:  url("../assets/img/cute/kuang_9.png");
    background-size: 100% 100%;
    margin-bottom: 20px;
    padding-top: 30px;
}
.main_content_left_bottom{
    height: calc(50% - 20px);
    background-image:  url("../assets/img/cute/kuang_9.png");
    background-size: 100% 100%;
    margin-bottom: 20px;
    padding-top: 30px;
}
#main_content{
    height: calc(100% - 104px);
}
#main_content_center{
    flex: 1;
}
#main_content_right{
    width: 560px;
    margin-right: 20px;
}
#main_top{
    height: 84px;
    position: relative;
    display:-moz-flex;
    display:-o-flex;
    display: flex;
    margin-bottom: 20px;
}
#main_top_right>i{
    color:mediumslateblue;
    margin-right: 10px;
    font-size: 1.5em
    
}
#main_top_right>span{
    font-size: 0.8em
}
</style>
<script>
import alarm from '@/components/alarm'
export default {
    components:{alarm},
    data:function(){
        return{
            timmer1:null,
            timmer2:null,
            timmer3:null,
            timmer4:null,
            timmer5:null,
            onlineChat:null,
            leftBottomChat:null,
            rightTopChat:null,
            rightBottomChat:null,
            myChart:null,
            color1:new window.echarts.graphic.LinearGradient( 0, 0, 1, 0,
                        [  {offset: 0, color: '#2978c9'},
                            {offset: 0.5, color: '#00b2e4'},
                            {offset: 1, color: '#00e7fd'}]),
            color2:new window.echarts.graphic.LinearGradient( 0, 0, 1, 0,
                        [  {offset: 0, color: '#ffad42'},
                            {offset: 0.5, color: '#ffc54b'},
                            {offset: 1, color: '#ffd450'}]),
             logopic:require("../assets/img/cute/logo.png"),
        }
    },
    mounted:function(){
        //初始化在线报表
        this.onlineChat = window.echarts.init(document.getElementById("onlineChat"));
        this.startOnlineChat();
        //初始化左下报表
        this.leftBottomChat = window.echarts.init(document.getElementById("leftBottomChat"));
        this.startLeftBootom();
        this.rightTopChat=window.echarts.init(document.getElementById("rightTopChat"));
        this.startRightTopChart();
        this.startAlarm();
        this.rightBottomChat=window.echarts.init(document.getElementById("rightBottomChat"));
        this.beginRightBottomChart();
        this.myChart=window.echarts.init(document.getElementById("myChart"));
        this.centerData();

    },
    methods:{
        getOption(xAxis,yAxis,datas){
            var obj={
                textStyle:
                 { 
                     color: '#8495c2'
                 },
                 tooltip : {
                     trigger: 'axis',
                     axisPointer : {   
                         type : 'shadow'   
                    }
                },
                grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                },
                xAxis:  xAxis, 
                yAxis: yAxis,
                     series:datas,
                      legend:{
                          data:[], 
                            textStyle:
                                { 
                                    color: '#ffffff'
                                },
                        orient: 'vertical',
                        right: 50,
                }
            }
                return obj;
        },
        beginOnlineChart(){
               var online=[];
                var offline=[];
                for(var i=0;i<7;i++){
                    var value=Math.floor(Math.random()*700);
                    online.push(value);
                    offline.push(Math.floor(Math.random()*400));
                }
                var datas=[];
            datas.push({
                name: '在线',
                type: 'bar',
                stack: '总量',
                label: {
                    normal: {
                    show: true,
                    position: 'insideRight',
                    color:"white"
                    }
                    },
                    itemStyle:{
                        normal: {
                        color: this.color1
                        }
                    },
                        data: online
                    });
                datas.push({
                  name: '离线',
                  type: 'bar',
                  stack: '总量',
                  label: {
                      normal: {
                            show: true,
                            position: 'insideRight',
                            color:"white"
                            }
                    },
                    itemStyle: {
                        normal: {
                        color: this.color2
                        }
                    },
                    data: offline
            });
            var x={splitLine:{show: false},
                type:'value'
            }
            var y={
                type:"category",splitLine:{show: false},
                data:['摄像头','传感器','灯具','广播','无线AP','LED屏','网关']
            }
             var option=this.getOption(x,y,datas);
             option.title= {
                     show: true,
                     text:"设备在线统计",
                     subtext:"Online Statistics",
                     "padding":[0, 0, 0 ,50], 
                     textStyle:
                        { 
                            color: '#ffffff'
                        },
                    },
            option.legend.data.push("在线");
            option.legend.data.push("离线");
             this.onlineChat.setOption(option);
        },
        startOnlineChat(){
            this.beginOnlineChart();
            this.timmer1=window.setInterval(this.beginOnlineChart,10000)
        },
        beginLeftBottomChart(){
                var online=[];
                for(var i=0;i<7;i++){
                    var value=Math.floor(Math.random()*700);
                    online.push(value);
                }
                var datas=[];
                datas.push({
                    name: '数量',
                    type: 'line',
                    stack: '总量', 
                    label: {
                      normal: {
                            show: true,
                            position: 'top',
                            color:"white"
                            }
                    }, smooth:true,
                     data: online,
                     itemStyle:{
                         color:"#1b85c9"
                     },
                    areaStyle:  {normal: {
                                            color: new window.echarts.graphic.LinearGradient(
                                                    0, 0, 0, 1,
                                                    [
                                                        {offset: 0, color: '#00749a'},
                                                        {offset: 0.5, color: '#123e62'},
                                                        {offset: 1, color: '#132f57'}
                                                    ]
                                            )
                                        }},
                            });
                var x={
                    type:"category",
                    boundaryGap : false,
                    data:['摄像头','传感器','灯具','广播','无线AP','LED屏','网关']
                }
                var y={
                    splitLine:{
                        lineStyle:{
                            color:"#8495c2"
                        }
                    },
                    type:"value"
                }
                var option=this.getOption(x,y,datas);
                option.title= {
                        show: true,
                        text:"设备数量统计",
                        subtext:"Quantity  Statistics",
                        "padding":[0, 0, 0 ,50], 
                        textStyle:
                            { 
                                color: '#ffffff'
                            },
                        },
                 this.leftBottomChat.setOption(option);
        },
        startLeftBootom(){
            this.beginLeftBottomChart();
            this.timmer2=window.setInterval(this.beginLeftBottomChart,10000)
        },
        beginRightTopChart(){
            var on=Math.floor(Math.random()*500);
            var off=Math.floor(Math.random()*500);
            var sub=on+off;
            var option = {
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b}: {c} ({d}%)"
            },
            legend: {
                left:5,
                bottom:5,
                orient:"vertical",
                data:['亮灯','关灯'], 
                            textStyle:
                                { 
                                    color: '#ffffff'
                                }
            },
            series: [
                {
                    name:'亮灯率',
                    type:'pie',
                    radius: ['60%', '90%'],
                    center: ['70%', '50%'],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            formatter:[ '{title|总灯数}',`{count|${sub}}`].join('\n'),
                            show: true,
                            position: 'center',
                            rich:{
                                title:{
                                    color:"white",
                                    fontSize:20
                                },
                                count:{
                                    color:"#8495c2",
                                    fontSize:14,
                                    padding: [ 0, 0, 10,0],
                                }
                            }
                        },
                    },
                    labelLine: {
                        normal: {
                            show: true
                        }
                    },
                    data:[
                        {value:on, name:'关灯'},
                        {value:off, name:'亮灯'},
                        ],
                    color:[this.color1,this.color2]
                    },
                    
                ],
                grid: {
                    top:0, 
                    right: 100,
                    bottom: 0,
                    containLabel: false
                }
            };
            option.title= {
                     show: true,
                     text:"灯具亮灯率",
                     subtext:"Online Statistics",
                     "padding":[20, 0, 0 ,5], 
                     textStyle:
                        { 
                            color: '#ffffff'
                        },
                    }
            this.rightTopChat.setOption(option,true);
        },
        startRightTopChart(){
            this.beginRightTopChart();
            this.timmer3=window.setInterval(this.beginRightTopChart,10000);
        },
        startAlarm(){
           this.timmer4= window.setInterval(()=>{
                var result=this.$refs.alarm.get();
                this.$refs.alarm.start(!result);
            },10000);
        },
        beginRightBottomChart(){
           var option = {
                    title: {
                        show: true,
                        text:"能耗分析对比",
                        subtext:"Energy Analysis",
                        "padding":[0, 0, 0 ,5], 
                        textStyle:
                            { 
                                color: '#ffffff'
                            },
                        },
                    tooltip: {},
                    legend: {
                        orient:"vertical",
                        data: ['2017', '2018'],
                            textStyle:
                                { 
                                    color: '#ffffff'
                                },
                                
                bottom:5,
                left:5,
                    },
                    radar: {
                        // shape: 'circle',
                        name: {
                            textStyle: {
                                color: '#fff',
                                backgroundColor: '#999',
                                borderRadius: 3,
                                padding: [3, 5]
                        }
                        },
                        //['摄像头','传感器','灯具','广播','无线AP','LED屏','网关']
                        indicator: [
                        { name: '摄像头', max: 6500},
                        { name: '传感器', max: 16000},
                        { name: '灯具', max: 30000},
                        { name: '广播', max: 38000},
                        { name: '无线AP', max: 52000},
                        { name: 'LED屏', max: 25000},
                        { name: '网关', max: 25000}
                        ]
                    },
                    series: [{
                        name: '能耗分析对比',
                        type: 'radar',
                        areaStyle: {normal: {}},
                        data : [
                            {
                                value : [4300, 10000, 28000, 35000, 50000, 19000,19000],
                                name : '2017'
                            },
                            {
                                value : [5000, 14000, 28000, 31000, 42000, 21000,17000],
                                name : '2018'
                            }
                        ],
                         color:[this.color1,this.color2]
                    }]
                };
            this.rightBottomChat.setOption(option,true);
        },
         getAirportCoord(idx) {
                return [this.json.airports[idx][3], this.json.airports[idx][4]];
            },
        centerData(){
             var routes = this.json.routes.map( (airline) =>{
                        return [
                            this.getAirportCoord(airline[1]),
                            this.getAirportCoord(airline[2])
                        ];
                    });
                    this.myChart.setOption({
                            geo3D: {
                                map: 'world',
                                shading: 'realistic',
                                silent: true,
                               // environment: '#333',
                                realisticMaterial: {
                                    roughness: 0.8,
                                    metalness: 0
                                },
                                postEffect: {
                                    enable: true
                                },
                                groundPlane: {
                                    show: false
                                },
                                light: {
                                    main: {
                                        intensity: 1,
                                        alpha: 30
                                    },
                                    ambient: {
                                        intensity: 0
                                    }
                                },
                                viewControl: {
                                    distance: 70,
                                    alpha: 89,

                                    panMouseButton: 'left',
                                    rotateMouseButton: 'right'
                                },

                                itemStyle: {
                                    areaColor: '#000'
                                },

                                regionHeight: 0.5
                            },
                            series: [{
                                type: 'lines3D',

                                coordinateSystem: 'geo3D',

                                effect: {
                                    show: true,
                                    trailWidth: 1,
                                    trailOpacity: 0.5,
                                    trailLength: 0.2,
                                    constantSpeed: 5
                                },

                                blendMode: 'lighter',

                                lineStyle: {
                                    width: 0.2,
                                    opacity: 0.05
                                },

                                data: routes
                            }]
                        });
        }
    },
    destroyed:function(){
        if(this.timmer1){
            window.clearInterval(this.timmer1);
        }
        if(this.timmer2){
            window.clearInterval(this.timmer2);
        }
        if(this.timmer3){
            window.clearInterval(this.timmer3);
        }
        if(this.timmer4){
            window.clearInterval(this.timmer4);
        }
        if(this.leftBottomChat){
            this.leftBottomChat.dispose();
        }
        if(this.onlineChat){
            this.onlineChat.dispose();
        }
        if(this.rightBottomChat){
            this.rightBottomChat.dispose();
        }
        if(this.rightTopChat){
            this.rightTopChat.dispose();
        }
        if(this.myChart){
            this.myChart.dispose();
        }
    }
}
</script>
