<template>
    <!--<div class="info_stand">
        <div class="container">
        <div v-for="t in this.item.Pics" :key="t">
            <b-img fluid class="w-100" :src="t"></b-img>
        </div></div>
    </div>-->
    <div class="info_stand">
        <imgs :data="item"></imgs>
    </div>
</template>
<style>
.info_stand{
    padding-top: 70px;
    padding-bottom:30px !important;
    height:90%;
}
</style>

<script>
import imgs from "@/components/imgs"
import { BaseUrl } from '../../assets/utils/config'
export default {
    components:{imgs},
    data:function(){
        return{
            item:  {
                    "Sid":0,
                    "Pics":[
                        `${BaseUrl}/img/case/stand1.png`,
                        `${BaseUrl}/img/case/stand2.png`,
                        `${BaseUrl}/img/case/stand3.png`,
                        `${BaseUrl}/img/case/stand4.png`,
                    ]
                }
            }
        },
    mounted:function(){
        this.$nextTick(()=>{
    //         var ele=document.getElementById("info_menu");
    //         if(ele){
    //             ele.style.backgroundColor="#333";
    //         }
    //   document.getElementById("info_footer").style.position="relative";
        })
    }
}
</script>
