import Axios from "axios";
import cookies from '@/assets/utils/kscookie';
const baseUrl="Https://kingsunsmart.com";
import config from "@/assets/utils/config"
export default{
    getInstance: function (method, type, url, data) {
        url=config.Theme!=0?`${baseUrl}${url}`:url;
        var http = Axios.create({
            method: method,
            headers: {
                'Content-Type': type,
                'Access-Control-Allow-Origin': '*'
            },
            url: url,
            data: data
        });
        return http();
    },
    postData:function(surl,method,data){
        if(!surl){
            throw "没有配置广播服务器"
        }
        let obj={
            // "Url":surl,
            // "Session":cookies.get("JSESSIONID"),
            "Method":method,
            "Data":data
        }
        var sdata={
            Token:cookies.get("token"),
            Data:obj
        }
        let url='/api/NatProx/Broadcast';
        var m = "post";
        var t = "application/json;charset=utf-8"
        return new Promise((res,rej)=>{
            this.getInstance(m,t,url,sdata).then((success)=>{
                if(success.data.RetCode!==0){
                    rej(success.data.RetMsg);
                }
                else{
                        res(success.data.Data);
                   
                }
            });
        }) ;
    }
}