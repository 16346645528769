<template>
  <div>
    <b-list-group class="lightGroup">
      <b-list-group-item
        v-for="item in items"
        href="#"
        variant="primary"
        :active="item.Active"
        :key="item.Sid"
      >
        <b-row>
          <b-col cols="8" style="height: 100%" @click="itemClick(item.Sid)">
            <span>{{ item.GroupName }}</span>
          </b-col>
          <b-col cols="4">
            <i class="fa fa-edit fa-1x" @click="addGroup(item)"></i>
            <i class="fa fa-trash-o fa-1x" @click="delgroup(item)"></i>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <hr />
    <div style="text-align: center">
      <i
        class="fa fa-plus-circle fa-4x"
        style="color: #999; cursor: pointer"
        v-b-tooltip.hover
        title="添加分组"
        @click="addGroup(null)"
      ></i>
    </div>
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      title="添加分组"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
      @shown="onModalLoaded"
    >
      <b-form @submit="onSubmit">
        <b-form-group :label="$t('分组名')" label-cols="2">
          <b-form-input required v-model="form.GroupName"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('设备列表')" label-cols="2">
          <el-table
            stripe=""
            ref="multipleTable"
            :data="devices"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :empty-text="$t('暂无数据')"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column :label="$t('名称')">
              <template slot-scope="scope">{{ scope.row.SZ.name }}</template>
            </el-table-column>
            <el-table-column :label="$t('设备类型')">
              <template slot-scope="scope">{{
                scope.row.Custom.DeviceType.TypeName
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('网关地址')">
              <template slot-scope="scope">{{ scope.row.SZ.addrStr }}</template>
            </el-table-column>
          </el-table>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width: 100px" v-on:click="$refs.modallg.hide()"
              >Cancel</b-button
            >
            <b-button type="submit" style="width: 100px" variant="success"
              >OK</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/device/DelGroup"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<style>
.lightGroup .list-group-item {
  padding: 0px 0px 0px 5px !important;
  height: 50px;
  line-height: 50px;
}
</style>

<script>
import deletedata from "@/components/deletedata";
export default {
  components: { deletedata },
  data: function () {
    return {
      form: {
        GroupType: 1,
        GroupName: "",
        Devices: [],
      },
      title: "",
      loading: {},
      items: [],
      devices: [],
      deviceSelect: [],
      deviceSelect1: [],
      sid: "",
    };
  },
  mounted: function () {
    this.select();
  },
  methods: {
    select() {
      //查询网关分组
      this.loading = this.$loading({ fullScreen: true });
      this.$ksajax
        .ksPostPromise("/api/device/GetGroups", 1, true)
        .then((data) => {
          data.forEach((x) => {
            x.Active = false;
          });
          this.items = data;
          this.loading.close();
        })
        .catch((err) => {
          this.loading.close();
          this.$throw(err);
        });
    },
    itemClick(uid) {
      this.items.forEach((x) => {
        if (x.Sid == uid) {
          x.Active = true;
          this.$emit("itemclick", x.Sid);
        } else {
          x.Active = false;
        }
      });
    },
    deleted() {
      this.select();
    },
    delgroup(req) {
      this.sid = req.Sid;
      this.$refs.del.show();
    },
    addGroup(req) {
      //获取网关列表
      if (req) {
        this.form.Sid = req.Sid;
        this.form.GroupName = req.GroupName;
      } else {
        this.form.Sid = null;
      }
      let obj = {
        Method: "read",
        SZ: {
          platform: 1,
          type: 4,
          skip: 0,
          limit: 10000,
          name: "",
        },
        Custom: {
          Index: 0,
          Count: 10000,
        }
      };
      this.$ksajax
        .ksPostPromise("/api/agent/RequestSzApi", obj, true)
        .then((success) => {
          this.deviceSelect1 = [];
          this.devices = success.Items;
          if (this.form.Sid) {
            success.Items.forEach((x) => {
              var y = x.Custom.Groups.find((z) => z == this.form.Sid);
              if (y) {
                this.deviceSelect1.push(x);
              }
            });
          }
          this.$refs.modallg.show();
          this.loading.close();
        })
        .catch((err) => {
          this.$throw(err);
          this.loading.close();
        });
    },
    onModalLoaded() {
      if (this.deviceSelect1 && this.deviceSelect1.length != 0) {
        this.deviceSelect1.forEach((x) => {
          this.$refs.multipleTable.toggleRowSelection(x);
        });
      }
    },
    handleSelectionChange(val) {
      this.deviceSelect = val;
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (!this.deviceSelect || this.deviceSelect.length == 0) {
        this.$throw("请至少选择一个设备");
      } else {
        this.form.Devices = [];
        this.deviceSelect.forEach((x) => {
          this.form.Devices.push(x.Custom.Sid);
        });
        this.loading = this.$loading({ fullScreen: true });
        this.$ksajax
          .ksPostPromise("/api/device/AddGroup", this.form, true)
          .then(() => {
            this.loading.close();
            this.$refs.modallg.hide();
            this.select();
          })
          .catch((err) => {
            this.loading.close();
            this.$throw(err);
          });
      }
    },
  },
};
</script>
