<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      :send="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
      v-on:publish="publish"
    ></actionbar>
    <el-table stripe=""
      :data="items"
      class="demo-table"
      @current-change="handleCurrentChange"
      highlight-current-row
      :empty-text="$t('暂无数据')"
    >
      <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
      <el-table-column :label="$t('类型')">
          <template slot-scope="scope">
              {{scope.row.Type==0?"屏幕控制":"定时重启"}}
          </template>
      </el-table-column>
      <el-table-column label="ID" prop="Sid"></el-table-column>
      <el-table-column :label="$t('创建时间')" prop="CreateTime"></el-table-column>
      <el-table-column :label="$t('创建人')" prop="CreateName"></el-table-column>
    </el-table>
    <b-modal id="timmerModal" ref="timmerModal" :title="title" size="lg" hide-footer>
      <b-form ref="form" @submit="onSubmit">
        <b-form-group v-bind:label="$t('名称')" :label-cols="2">
          <b-form-input type="text" v-model="form.Name" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('类型')" :label-cols="2">
            <b-select v-model="form.Type">
                <b-select-option value="0">屏幕控制</b-select-option>
                <b-select-option value="1">定时重启</b-select-option>
            </b-select>
        </b-form-group>
        <b-form-group
          v-bind:label="$t('时间安排')"
          :label-cols="2"
          v-for="time in form.Shedules"
          :key="time.Sid"
        >
          <div class="sceneTemp">
            <div style="text-align:right;margin-bottom:5px;">
              <i class="fa fa-trash-o" style="cursor:pointer" @click="delSchedule(time.Sid)"></i>
              <i class="fa fa-plus" style="cursor:pointer" @click="addSchedule(time.Sid)"></i>
            </div>
            <b-form-group v-bind:label="$t('日期')" :label-cols="2">
              <div>
                <el-date-picker
                  v-model="time.StartTime"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>-
                <el-date-picker
                  v-model="time.EndTime"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </div>
            </b-form-group>
            <b-form-group v-bind:label="$t('时间')" :label-cols="2">
              <div>
                <el-time-picker v-model="time.ExecuteTime" placeholder="选择时间" value-format="HH:mm"></el-time-picker>
              </div>
            </b-form-group>
            <b-form-group v-bind:label="$t('星期')" :label-cols="2">
              <b-check-group
                :options="Weeks"
                v-model="time.WeekFilter"
              ></b-check-group>
            </b-form-group>
            <b-form-group v-if="form.Type==0" v-bind:label="$t('操作')" :label-cols="2">
              <b-select v-model="time.Status">
                  <b-select-option value="0">开屏</b-select-option>
                  <b-select-option value="1">关屏</b-select-option>
              </b-select>
            </b-form-group>
          </div>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width:100px" v-on:click="hidden">Cancel</b-button>
            <b-button type="submit" style="width:100px" variant="primary">OK</b-button>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
<b-modal
      id="publishmodal"
      class="ksmodal"
      ref="publishmodal"
      size="lg"
      :title="$t('发布')"
      body-text-variant="dark"
      @ok="publishStage"
    >
      <el-table
        ref="multipleTable"
        :data="devices"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange" :empty-text="$t('暂无数据')"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column :label="$t('名称')" prop="DeviceName"></el-table-column>
        <el-table-column :label="$t('宽度')" prop="Width"></el-table-column>
        <el-table-column :label="$t('高度')" prop="Height"></el-table-column>
        <el-table-column :label="$t('位置')" prop="Address"></el-table-column>
      </el-table>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/led/DelNovaTimmer"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>

<script>
import actionbar from "@/components/actionbar";
import { GetGuid, formatDate } from "@/assets/utils/datef";
import deletedata from "@/components/deletedata";
export default {
  components: { actionbar, deletedata },
  mounted: function() {
    this.select();
  },
  data: function() {
    return {
      form: {},
      title: "",
      current: null,
      sid: "",
      Weeks: [
        { value: 1, text: this.$t("星期一") },
        { value: 2, text: this.$t("星期二") },
        { value: 3, text: this.$t("星期三") },
        { value: 4, text: this.$t("星期四") },
        { value: 5, text: this.$t("星期五") },
        { value: 6, text: this.$t("星期六") },
        { value: 0, text: this.$t("星期天") }
      ],
      items: [],
      devices: [],
      loading: null,
      subForm:null,
      deviceSlected:[]
    };
  },
  methods: {
      showTime(serial){
          this.loading=this.$loading();
          this.$ksajax
          .ksPostPromise("/api/led/GetDevTimmer", serial, true).then((data)=>{
              this.subForm=data;
              this.$refs.subModal.show();
              this.loading.close();
          this.loading.close();
          }).catch(err=>{
              this.$throw(err);
              this.loading.close();
          })
      },
      handleSelectionChange(val){
           this.deviceSlected=val;
      },
     publishStage() {
      var data = {
        Sid: this.current.Sid,
        Devices: []
      };
      if (this.deviceSlected && this.deviceSlected.length > 0) {
        this.deviceSlected.forEach(x => {
          data.Devices.push(x.Serial);
        });
      } else {
        throw this.$t("请选择数据");
      }
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/PublishNovaTimmer", data, true)
        .then(() => {
          this.$router.push({
          path: "/admin/nova/log"
        });
          this.loading.close();
        })
        .catch(err => {
          this.loading.close();
          this.$throw(err);
        });
    },
    publish() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      new Promise((res, rej) => {
        if (this.devices.length != 0) {
          res();
        } else {
          this.loading = this.$loading();
          let obj = {
            Index: 0,
            Count: 10000
          };
          this.$ksajax
            .ksPostPromise("/api/led/GetNovaLeds", obj, true)
            .then(data => {
              this.devices = data.Items;
              res();
              this.loading.close();
            })
            .catch(err => {
              rej(err);
              this.loading.close();
            });
        }
      })
        .then(() => {
          this.$refs.publishmodal.show();
        })
        .catch(err => {
          this.$throw(err);
        });
    },
    insert() {
      this.form = this.getResetForm();
      this.title = this.$t("新增");
      this.$refs.timmerModal.show();
    },
    del() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    deleted() {
      this.select();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.form = this.current;
      this.title = this.$t("修改");
      this.$refs.timmerModal.show();
    },
    select() {
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/GetNovaTimmer", "", true)
        .then(data => {
          this.items = data;
           this.items.forEach(x=>{
               if(x.Shedules){
                   x.Shedules.forEach(y=>{
                       y.StartTime=formatDate(new Date(y.StartTime), "yyyy-MM-dd");
                       y.EndTime=formatDate(new Date(y.EndTime), "yyyy-MM-dd");
                       y.ExecuteTime=formatDate(new Date(y.ExecuteTime), "hh:mm");
                   });
               }
           });
          this.loading.close();
          this.hidden();
        })
        .catch(err => {
          this.loading.close();
          this.hidden();
          this.$throw(err);
        });
    },
    hidden() {
      this.$refs.timmerModal.hide();
    },
    onSubmit(event) {
      event.preventDefault();
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/AddNovaTimmer", this.form, true)
        .then(() => {
          this.hidden();
          this.loading.close();
          this.select();
        })
        .catch(err => {
          this.loading.close();
          this.hidden();
          this.$throw(err);
        });
    },
    addSchedule() {
      this.form.Shedules.push({
        Sid: GetGuid(),
        ExecuteTime: formatDate(new Date(), "hh:mm"),
        StartTime: formatDate(new Date(), "yyyy-MM-dd"),
        EndTime: formatDate(new Date(), "yyyy-MM-dd"),
        WeekFilter: [],
        Status:0
      });
    },
    delSchedule(sid) {
      if (this.form.Shedules.length <= 1) {
        return;
      } else {
        this.form.Shedules = this.form.Shedules.filter(x => x.Sid != sid);
      }
    },
    weekChange(event, time) {
      if (event == "None") {
        time.weekFilter = [];
      }
    },
    timeChange(event, time) {
      if (event == "All") {
        time.startTime = null;
        time.endTime = null;
      } else {
        time.startTime = formatDate(new Date(), "hh:mm");
        time.endTime = formatDate(new Date(), "hh:mm");
      }
    },
    dateChange(event, time) {
      if (event == "All") {
        time.startDate = null;
        time.endDate = null;
      } else {
        time.startDate = formatDate(new Date(), "yyyy-MM-dd");
        time.endDate = formatDate(new Date(), "yyyy-MM-dd");
      }
    },
    handleCurrentChange(val) {
      this.current = val;
    },
    getResetForm() {
      return {
        Sid: "",
        Name: "",
        Type:0,
        Shedules: [
          {
            StartTime: formatDate(new Date(), "yyyy-MM-dd"),
            EndTime: formatDate(new Date(), "yyyy-MM-dd"),
            ExecuteTime: formatDate(new Date(), "hh:mm"),
            Status:0,
            WeekFilter: [],
            Sid: GetGuid()
          }
        ]
      };
    }
  }
};
</script>

<style>
</style>