<template>
    <div class="info_product">
        <div class="container">
            <div class="row row_title" style="margin-bottom:15px" v-for="item in this.items" :key="item.Sid">
                <div class="col-4">
                    <b-img :src="item.Pics[0]" fluid style="max-height:300px"></b-img>
                </div>
                <div class="col-8">
                    <div class="product_title">{{item.Name}}</div>
                    <div>
                        <p class="product_content">{{item.Content}}</p>
                        <router-link :to="'/info/productdetail?Sid='+item.Sid" style="color:red">查看详情></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .row_title{
        padding-bottom:15px;
        border-bottom:5px solid #b1b1b1;
    }

    .product_title {
        font-size: 20px;
        margin: 15px 0 15px 0
    }

    .product_content {
        color: #666
    }

    @media (max-width: 768px) {
        .product_title {
            font-size: 16px;
            margin: 0
        }

        .product_content {
            display: none;
        }
    }

    .imgmodal .modal-body {
        height: 95%;
    }

    .imgmodal .modal-content, .imgmodal .modal-dialog {
        height: 100%;
    }

    .imgmodal #modal1 {
        margin-top: 70px;
    }

    .sc_content {
        width: 100%;
        height: 60px;
        background-color: lightcyan;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .flatBox {
        float: left;
        width: 33.333%;
        padding: 10px;
        cursor: pointer
    }

    .sc_wrap {
        width: 100%;
        height: 50px;
        line-height: 25px;
        overflow: hidden;
        margin-right: -70px;
    }

    .sc_prop {
        float: left;
        width: 1px;
        height: 50px;
        background: transparent;
    }

    .sc_end {
        float: right;
        position: relative;
        width: 1px;
        background: #FFA;
    }

    .sc_realend {
        position: absolute;
        width: 27px;
        top: -23px;
        left: 323px;
        background: #fff;
        font-size: 13px;
    }

    .info_product {
        width: 100%;
        padding-top: 70px;
    }

    .imgbox {
        padding-bottom: 70%;
        height: 0;
        background: white;
        position: relative;
    }

    .imgbox_content {
        position: absolute;
        width: 100%;
        height: 100%
    }
</style>

<script>
    import imgs from "@/components/imgs"
import config from "@/assets/utils/config";
    export default {
        components: { imgs },
        data: function () {
            return {
                baseUrl:config.BaseUrl,
                items:[],
                item: {},
                title: ""
            }
        },
        methods: {
            show: function (item) {
                this.item = item;
                this.title = item.Name;
                this.$refs.modal1.show();
            },
            getData() {
                return [
                    {
                        "Sid": 0,
                        "Name": "贵阳市智慧商业区及景观亮化项目",
                        "Content": "市西滨河商业街定位为富有贵州地域文化特色的现代时尚街区，本次设计范围在商业街的基础上，继续塑造市西路滨河景观夜景设计",
                        "Desc": "市西滨河商业街定位为富有贵州地域文化特色的现代时尚街区，本次设计范围在商业街的基础上，继续塑造市西路滨河景观夜景设计，水与街的互动与融合是一种自然而然的过程，由此带来的繁华与活力也是一种必然的演化过程。智慧庭院灯沿河道两边安装，数量约150套。主要有水位监测、人流密集度监测、人脸识别、公共广播、无线网络等功能。当前方三公里水位超过警戒线后，通过广播告知现场人员撤离，监控中心预警，河道围栏关闭；当摄像头检测到商业街道人员密集度达到预警值后，通过广播告知现场游客，监控中心预警；摄像头抓取现场人脸，与公安系统对接。",
                        "Pics": [
                            this.baseUrl+"/img/case/gy1.jpg",
                            this.baseUrl+"/img/case/gy2.jpg",
                            this.baseUrl+"/img/case/gy3.jpg",
                            this.baseUrl+"/img/case/gy4.jpg",
                            this.baseUrl+"/img/case/gy5.jpg",
                            this.baseUrl+"/img/case/gy6.jpg",
                            this.baseUrl+"/img/case/gy7.jpg",
                        ]
                    },
                    {
                        "Sid": 1,
                        "Name": "南昌新建城基础和公共配套设施项目",
                        "Content": "南昌新建城项目位于新建区长堎新区，打造集生态宜居、活力商贸、文化休闲等功能为一体的现代化高品质复合型宜居新城",
                        "Desc": "南昌新建城项目位于新建区长堎新区，总面积约3000亩，主要开发建设住宅、商业、基础和公共配套设施，打造集生态宜居、活力商贸、文化休闲等功能为一体的现代化高品质复合型宜居新城。对十三条道路进行整体设计及施工，由低、中、高三种灯杆配置型号，共计768杆，集成LED照明，视频监控、无线网络、一键呼叫、信息发布、RFID等功能。",
                        "Pics": [
                            this.baseUrl+"/img/case/nc1.jpg",
                            this.baseUrl+"/img/case/nc2.jpg",
                            this.baseUrl+"/img/case/nc3.jpg",
                            this.baseUrl+"/img/case/nc4.jpg",
                            this.baseUrl+"/img/case/nc5.jpg",
                            this.baseUrl+"/img/case/nc6.jpg",
                            this.baseUrl+"/img/case/nc7.jpg",
                            this.baseUrl+"/img/case/nc8.jpg",
                        ]
                    },
                    {
                        "Sid": 2,
                        "Name": "韶关智慧城市",
                        "Content": "联合广东广电网络，建设基于城市光纤的智慧城市试点",

                        "Desc": "联合广东广电网络，建设基于城市光纤的智慧城市试点。项目位于韶关西桥梁公园，集成LED照明、环境监测、无线网络、视频监控、公共广播、紧急呼叫、信息发布、井盖监测、车位管理等功能。",
                        "Pics": [
                            this.baseUrl+"/img/case/sg1.jpg",
                        ]
                    }
                ]
            }
        },
        mounted: function () {
            this.$nextTick(() => {
                // var ele = document.getElementById("info_menu");
                // this.items=this.getData();
                // if (ele) {
                //     ele.style.backgroundColor = "#333333";
                // }
                // document.getElementById("info_footer").style.position = "relative";
            })
        }
    }
</script>
