<template>
    <div class="white">
        <actionbar v-bind:select='true' v-bind:modify='true'
        v-on:select='select'
        v-on:modify='modify'
        >
        </actionbar>
        
        <tablepage ref="tableref" id="tableref"
         v-on:created='tableCreated'
         v-on:currentPage='currentPage'
         v-on:currentRow='currentRow'
         :cols='cols'
         :items='items'
         :total='total'
         :perpage='count'
        ></tablepage>
         <b-modal ref="modallg" size="lg" v-bind:title="title" hide-footer class="ksmodal"
         header-bg-variant="success"
         body-text-variant="dark">
                  <b-tabs>
                        <b-tab :title="$t('紧急呼叫')" active>
                            <hr>
                            <alarmAction  :car="true" :alarm="true" :value="modalData.keyAlarm"  :filter="true"></alarmAction>
                               
                        </b-tab>
                        
                        <b-tab :title="$t('业务咨询')">
                            <alarmAction  :car="true" :alarm="true" :value="modalData.keyHelp"  :filter="true"></alarmAction>
                            <hr>
                        </b-tab>
                  </b-tabs>
                 
         </b-modal>
    </div>
</template>

<script>
import actionbar from '@/components/actionbar'
import tablepage from '@/components/tablepage'
import modify from '@/components/modify'
import alarmAction from '@/components/alarmAction'
import {Link} from '@/assets/utils/datef'
export default {
    components:{actionbar,tablepage,modify,alarmAction},
    data:function(){
        return{
            radio:0,
            title:this.$t("修改"),
            modalData:this.resetData(),
            loading:{},
            cols:[
                {name:this.$t("设备名"),value:"DeviceName"},
                {name:this.$t("设备类型"),value:"TypeName",subvalue:"DeviceType"},
                {name:this.$t("设备型号"),value:"ModelNumber",subvalue:"DeviceType"},
                {name:this.$t("紧急呼叫"),value:"State",subvalue:"keyAlarm"},
                {name:this.$t("业务咨询"),value:"State",subvalue:"keyHelp"},
            ],
            items:[],
            total:0,
            count:2000,
            index:0,
            sid:"",
            current:null,
            link:Link().filter(function(x){return x.state==1}),
        }
    },
    methods:{
        select(noloading,index){
            if(!noloading){
                if(!index){
                    this.index=0;
                }
                this.loading=this.$loading({fullScreen:true});
            }
             new Promise((res,rej)=>{
                let obj={
                    Index:this.index,
                    Count:this.count
                }
                this.$ksajax.ksPostPromise("/api/device/GetDeviceVideos",obj,true).then((data)=>{
                    this.items=[];
                    data.Items.forEach(x => {
                        if(x.DeviceType.Sid=="1501"){
                            x.keyAlarm={
                                LinkageActions:[]};
                            x.keyHelp={
                                LinkageActions:[]};
                        this.items.push(x);
                        }
                    });
                    res();
                }).catch(err=>{
                    rej(err);
                })
             }).then(()=>{
                 return new Promise((res,rej)=>{
                     let obj={
                    Index:this.index,
                    Count:this.count,
                    DevCtgSid:"015"
                    }
                    this.$ksajax.ksPostPromise("/api/device/GetSensorLinkages",obj,true).then((data)=>{
                        this.items.forEach((x)=>{
                            data.Items.forEach((y)=>{
                                if(y.LinkageActions){
                                    y.LinkageActions.forEach((l)=>{
                                        l.link=this.link.find(o=>o.value==l.LinkageType);
                                        l.guid=this.guid();
                                    })
                                }
                                if(y.DeviceSid==x.Sid){
                                    y.State=y.IsEnabled==0?"关闭":"开启"
                                    if(y.DataKind==6){
                                        x.keyAlarm=y;
                                    }
                                    if(y.DataKind==7){
                                        x.keyHelp=y;
                                    }
                                }
                            })
                        })
                        res();
                    }).catch(err=>{
                        rej(err);
                    })
                 })
             }).then(()=>{
                 if(this.loading.close){
                     this.loading.close();
                 }
             }).catch((err)=>{
                 this.$throw(err);
                 if(this.loading.close){
                     this.loading.close();
                 }
             })
        },
        guid(){
            var guid = "";
            for (var i = 1; i <= 32; i++){
            var n = Math.floor(Math.random()*16.0).toString(16);
            guid +=   n;
            if((i==8)||(i==12)||(i==16)||(i==20))
                guid += "-";
            }
            return guid; 
                },
        del(){
            if(this.current==null)
            {
                throw this.$t("选择一条数据");
            }
            this.sid=this.current.Sid;
            this.$refs.ksmodel.show();
        },
        modify(){
            if(this.current==null)
            {
                throw this.$t("选择一条数据");
            }
                this.modalData.keyAlarm={
                    "Sid": this.current.keyAlarm?this.current.keyAlarm.Sid:null,
                    "LinkageName": "紧急呼叫",
                    "IsEnabled":  this.current.keyAlarm?this.current.keyAlarm.IsEnabled:false,
                    "DeviceSid": this.current.Sid,
                    "DataKind": 6,
                    "ThresholdValue": this.current.keyAlarm? this.current.keyAlarm.ThresholdValue:null,
                    "CompareSymbol": this.current.keyAlarm?this.current.keyAlarm.CompareSymbol:0,
                    "LinkageActions":this.current.keyAlarm?this.current.keyAlarm.LinkageActions:[],
                    "IsContrary":this.current.keyAlarm?this.current.keyAlarm.IsContrary:false,
                }
                this.modalData.keyHelp={
                    "Sid": this.current.keyHelp?this.current.keyHelp.Sid:null,
                    "LinkageName": "业务咨询",
                    "IsEnabled":  this.current.keyHelp?this.current.keyHelp.IsEnabled:false,
                    "DeviceSid": this.current.Sid,
                    "DataKind": 7,
                    "ThresholdValue": this.current.keyHelp? this.current.keyHelp.ThresholdValue:null,
                    "CompareSymbol": this.current.keyHelp?this.current.keyHelp.CompareSymbol:0,
                    "LinkageActions":this.current.keyHelp?this.current.keyHelp.LinkageActions:[],
                    "IsContrary":this.current.keyHelp?this.current.keyHelp.IsContrary:false,
                }
            
            this.$refs.modallg.show();
        },
        tableCreated(){},
        currentPage(){
        },
        currentRow(val){
            this.current=val;
        },
        resetData(){
           return   {
             keyAlarm:{LinkageActions:[]},
              keyHelp:{LinkageActions:[]},
           }
        }
        },
    mounted:function(){
        this.select();
    }
}
</script>
