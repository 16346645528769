<template>
  <div class="white">
    <actionbar v-bind:select="true" v-bind:modify="true" v-on:select="select" v-on:modify="modify"></actionbar>

    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>
    <b-modal
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      class="ksmodal"
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-tabs>
        <b-tab :title="$t('移动侦测')">
          <alarmAction :car="true" :alarm="true" :value="modalData.alarm" :filter="true"></alarmAction>
          <hr />
        </b-tab>
        <b-tab :title="$t('越界侦测')">
          <alarmAction :car="true" :alarm="true" :value="modalData.line" :filter="true"></alarmAction>
          <hr />
        </b-tab>
        <b-tab :title="$t('区域侦测')">
          <alarmAction :car="true" :alarm="true" :value="modalData.area" :filter="true"></alarmAction>
          <hr />
        </b-tab>
        <b-tab :title="$t('人群聚集')">
          <alarmAction :car="true" :alarm="true" :value="modalData.person" :filter="true"></alarmAction>
          <hr />
        </b-tab>
        <b-tab :title="$t('人员密度')">
          <alarmAction :car="false" :alarm="false" :value="modalData.dens" :filter="true"></alarmAction>
          <hr />
        </b-tab>
        <b-tab :title="$t('抽烟监测')">
          <alarmAction :car="true" :alarm="true" :value="modalData.smoke" :filter="true"></alarmAction>
          <hr />
        </b-tab>
        <b-tab :title="$t('摔倒监测')">
          <alarmAction :car="true" :alarm="true" :value="modalData.fall" :filter="true"></alarmAction>
          <hr />
        </b-tab>
      </b-tabs>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/device/DelWaterLevel"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>

<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
import alarmAction from "@/components/alarmAction";
import { Link } from "@/assets/utils/datef";
export default {
  components: { actionbar, tablepage, modify, deletedata, alarmAction },
  data: function() {
    return {
      radio: 0,
      title: this.$t("修改"),
      modalData: this.resetData(),
      loading: {},
      cols: [
        { name: this.$t("设备名"), value: "DeviceName" },
        {
          name: this.$t("设备类型"),
          value: "TypeName",
          subvalue: "DeviceType"
        },
        {
          name: this.$t("设备型号"),
          value: "ModelNumber",
          subvalue: "DeviceType"
        },
        { name: this.$t("移动侦测"), value: "State", subvalue: "alarm" },
        { name: this.$t("越界侦测"), value: "State", subvalue: "line" },
        { name: this.$t("区域侦测"), value: "State", subvalue: "area" },
        { name: this.$t("人群聚集"), value: "State", subvalue: "person" },
        { name: this.$t("人群密度"), value: "State", subvalue: "dens" },
        { name: this.$t("抽烟监测"), value: "State", subvalue: "smoke" },
        { name: this.$t("摔倒监测"), value: "State", subvalue: "fall" },
        {name:this.$t("参数配置"),value:"url",link:true},
      ],
      items: [],
      total: 0,
      count: 2000,
      index: 0,
      sid: "",
      labels: this.getResetLable(),
      current: null,
      link: Link().filter(function(x) {
        return x.state == 1;
      }),
      selfChoose: {
        radio: null,
        devs: [],
        selected: {}
      },
      flagChoose: {
        radio: null,
        devs: [],
        selected: {}
      },
      leds: []
    };
  },
  methods: {
    select(noloading, index) {
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      new Promise((res, rej) => {
        let obj = {
          Index: this.index,
          Count: this.count
        };
        this.$ksajax
          .ksPostPromise("/api/device/GetDeviceVideos", obj, true)
          .then(data => {
            data.Items.forEach(x => {
              x.url=`http://${x.Ip}`
              x.alarm = {
                State: "关闭",
                LinkageActions: []
              };
              x.line={
                State: "关闭",
                LinkageActions: []
              };
              x.area={
                State: "关闭",
                LinkageActions: []
              };
              x.person={
                State: "关闭",
                LinkageActions: []
              };
              x.dens={
                State: "关闭",
                LinkageActions: []
              };
              x.fall={
                State: "关闭",
                LinkageActions: []
              };
              x.smoke={
                State: "关闭",
                LinkageActions: []
              };
            });
            this.items = data.Items;
            this.total = data.Items.length;
            res();
          })
          .catch(err => {
            rej(err);
          });
      })
        .then(() => {
          return new Promise((res, rej) => {
            let obj = {
              Index: this.index,
              Count: this.count,
              DevCtgSid: "001"
            };
            this.$ksajax
              .ksPostPromise("/api/device/GetSensorLinkages", obj, true)
              .then(data => {
                console.log(data);
                this.items.forEach(x => {
                  data.Items.forEach(y => {
                    if (y.LinkageActions) {
                      y.LinkageActions.forEach(l => {
                        l.link = this.link.find(o => o.value == l.LinkageType);
                        l.guid = this.guid();
                      });
                    }
                    if (y.DeviceSid == x.Sid) {
                      if (y.DataKind == 1) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.alarm = y;
                      }
                      if (y.DataKind == 5) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.line = y;
                      }
                      if (y.DataKind == 9) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.area = y;
                      }
                      if (y.DataKind == 8) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.person = y;
                      }
                      if (y.DataKind == 704) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.smoke = y;
                      }
                      if (y.DataKind == 705) {
                        y.State = y.IsEnabled == 0 ? "关闭" : "开启";
                        x.fall = y;
                      }
                    }
                  });
                });
                res();
              })
              .catch(err => {
                rej(err);
              });
          });
        })
        .then(() => {
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch(err => {
          this.$throw(err);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    guid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
      }
      return guid;
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.ksmodel.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.modalData.alarm = {
        Sid: this.current.alarm ? this.current.alarm.Sid : null,
        LinkageName: "移动侦测",
        IsEnabled: this.current.alarm ? this.current.alarm.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 1,
        ThresholdValue: 1,
        CompareSymbol: this.current.alarm
          ? this.current.alarm.CompareSymbol
          : 0,
        LinkageActions: this.current.alarm
          ? this.current.alarm.LinkageActions
          : [],
        IsContrary: this.current.alarm ? this.current.alarm.IsContrary : false
      };
      
      this.modalData.line = {
        Sid: this.current.line ? this.current.line.Sid : null,
        LinkageName: "越界侦测",
        IsEnabled: this.current.line ? this.current.line.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 5,
        ThresholdValue: 1,
        CompareSymbol: this.current.line
          ? this.current.line.CompareSymbol
          : 0,
        LinkageActions: this.current.line
          ? this.current.line.LinkageActions
          : [],
        IsContrary: this.current.line ? this.current.line.IsContrary : false
      };
      this.modalData.area = {
        Sid: this.current.area ? this.current.area.Sid : null,
        LinkageName: "区域侦测",
        IsEnabled: this.current.area ? this.current.area.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 9,
        ThresholdValue: 1,
        CompareSymbol: this.current.area
          ? this.current.area.CompareSymbol
          : 0,
        LinkageActions: this.current.area
          ? this.current.area.LinkageActions
          : [],
        IsContrary: this.current.area ? this.current.area.IsContrary : false
      };
      this.modalData.person = {
        Sid: this.current.person ? this.current.person.Sid : null,
        LinkageName: "人群聚集",
        IsEnabled: this.current.person ? this.current.person.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 8,
        ThresholdValue: 1,
        CompareSymbol: this.current.person
          ? this.current.person.CompareSymbol
          : 0,
        LinkageActions: this.current.person
          ? this.current.person.LinkageActions
          : [],
        IsContrary: this.current.person ? this.current.person.IsContrary : false
      };
      this.modalData.dens = {
        Sid: this.current.dens ? this.current.dens.Sid : null,
        LinkageName: "人群密度",
        IsEnabled: this.current.dens ? this.current.dens.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 68,
        ThresholdValue: this.current.dens.ThresholdValue,
        CompareSymbol: this.current.dens
          ? this.current.dens.CompareSymbol
          : 0,
        LinkageActions: this.current.dens
          ? this.current.dens.LinkageActions
          : [],
        IsContrary: this.current.dens ? this.current.dens.IsContrary : false
      };
      this.modalData.fall = {
        Sid: this.current.fall ? this.current.fall.Sid : null,
        LinkageName: "摔倒监测",
        IsEnabled: this.current.fall ? this.current.fall.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 705,
        ThresholdValue: 1,
        CompareSymbol: this.current.fall
          ? this.current.fall.CompareSymbol
          : 0,
        LinkageActions: this.current.fall
          ? this.current.fall.LinkageActions
          : [],
        IsContrary: this.current.fall ? this.current.fall.IsContrary : false
      };
      this.modalData.smoke = {
        Sid: this.current.smoke ? this.current.smoke.Sid : null,
        LinkageName: "抽烟监测",
        IsEnabled: this.current.smoke ? this.current.smoke.IsEnabled : false,
        DeviceSid: this.current.Sid,
        DataKind: 704,
        ThresholdValue: 1,
        CompareSymbol: this.current.smoke
          ? this.current.smoke.CompareSymbol
          : 0,
        LinkageActions: this.current.smoke
          ? this.current.smoke.LinkageActions
          : [],
        IsContrary: this.current.smoke ? this.current.smoke.IsContrary : false
      };
      this.$refs.modallg.show();
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      return {
        line: { LinkageActions: [] },
        alarm: { LinkageActions: [] },
        area: { LinkageActions: [] },
        person: { LinkageActions: [] },
        dens: { LinkageActions: [] }
      };
    },
    getResetLable() {
      return [
        {
          label: this.$t("设备名"),
          type: "text",
          value: "DeviceName",
          require: true
        },
        {
          label: this.$t("序号"),
          type: "text",
          value: "Serial",
          require: true
        },
        {
          label: this.$t("类型"),
          type: "select",
          value: "DTypeSid",
          require: true,
          bind: this.$store.getters.getDeviceType("009")
        },
        {
          label: this.$t("位置"),
          type: "map",
          value: "Address",
          require: true
        },
        {
          label: this.$t("量程"),
          type: "number",
          value: "MeasuringRange",
          require: true,
          defalut: 9000
        },
        {
          label: this.$t("基值"),
          type: "number",
          value: "BasicValue",
          require: true,
          defalut: 3
        },
        {
          label: this.$t("单位量"),
          type: "number",
          value: "UnitAmount",
          require: true,
          defalut: 3000
        }
      ];
    },
    submited() {
      this.select();
    },
    deleted() {
      this.select();
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    addItem(val) {
      if (val == 0) {
        //self
        var dev = this.selfChoose.devs.find(
          x => x.Sid == this.selfChoose.selected
        );
        var action = this.link.find(x => x.value == this.selfChoose.radio);
        this.modalData.self.LinkageActions.push({
          Sid: "",
          DeviceSid: dev.Sid,
          DeviceName: dev.DeviceName,
          LinkageType: action.value,
          Content: null,
          link: action
        });
      }
    },
    selfChange(val) {
      if ([0, 1, 2, 3, 4].indexOf(val) != -1) {
        if (this.leds.length == 0) {
          let obj = {
            Index: 0,
            Count: 1000
          };
          this.loading = this.$loading();
          this.$ksajax
            .ksPostPromise("/api/device/GetDeviceLedAds", obj, true)
            .then(x => {
              this.leds = x.Items;
              this.selfChoose.devs = x.Items;
              this.selfChoose.selected = this.leds[0].Sid;
              this.loading.close();
            })
            .catch(err => {
              this.$throw(err);
              this.loading.close();
            });
        } else {
          this.selfChoose.devs = this.leds;
          this.selfChoose.selected = this.leds[0].Sid;
        }
      }
    }
  },
  mounted: function() {
    this.select();
  }
};
</script>
