<template>
  <div style="color: white; height: 100%" class="ksflex">
    <div style="width: 150px; height: 100%" v-loading="loading">
      <div
        v-for="item in items"
        :key="item.Sid"
        :class="
          currentSid == item.Sid
            ? 'slider_router slider_router_check'
            : 'slider_router'
        "
        @click="change(item.Sid)"
      >
        {{ item.Name }}
      </div>
    </div>
    <div class="detailview" style="flex: 1">
      <div class="detailview_1">
        <box style="position: relative">
          <div style="width: 100%; height: 50%">
            <pie
              v-loading="deviceLoading"
              :radius="true"
              :centerlable="'总数'"
              :size="'small'"
              ref="deviceOnline"
            ></pie>
          </div>
          <hr />
          <div style="width: 100%; height: 48%">
            <bar ref="yearCount" v-loading="yearLoading"></bar>
          </div>
        </box>
      </div>
      <div class="detailview_1">
        <box id="canvas_box" style="position: relative">
          <pie
            :radius="false"
            :size="'small'"
            ref="expCount"
            v-loading="expLoading"
          ></pie>
        </box>
      </div>
      <div class="detailview_1">
        <box>
          <div style="width: 100%; height: 50%">
            <pie
              :radius="true"
              :size="'small'"
              ref="sexCount"
              v-loading="sexLoading"
            ></pie>
          </div>
          <hr />
          <div style="width: 100%; height: 48%">
            <pie
              :radius="true"
              :size="'small'"
              ref="maskCount"
              v-loading="maskLoading"
            ></pie>
          </div>
        </box>
      </div>
      <div class="detailview_2">
        <box>
          <linebar
            ref="personCount"
            :smooth="true"
            v-loading="countLoading"
          ></linebar>
        </box>
      </div>
    </div>
  </div>
</template>
<style scoped>
.slider_router {
  padding-left: 10px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}
.slider_router_check {
  color: cadetblue;
}
</style>
<script>
import box from "@/components/box1.vue";
import pie from "@/components/chart/pie.vue";
import bar from "@/components/chart/bar.vue";
import linebar from "@/components/chart/line.vue";
import { colorArr } from "@/assets/utils/echartUtil";
import { getAgeByCode, getExpByCode } from "@/assets/utils/hikUtil";
export default {
  components: { box, pie, bar, linebar },
  data() {
    return {
      currentSid: "",
      items: [],
      chartData: {},
      loading: false,
      deviceCount: null,
      deviceOnline: null,
      deviceLoading: false,
      yearLoading: false,
      sexLoading: false,
      expLoading: false,
      countLoading: false,
      maskLoading: false,
    };
  },
  mounted() {
    this.selectRouter();
  },
  methods: {
    initDeviceOnline() {
      this.deviceLoading = true;
      this.$ksajax
        .ksPostPromise(
          "/api/hikctl/GetFaceViewDeviceData",
          this.currentSid,
          true
        )
        .then((data1) => {
          this.deviceLoading = false;
          this.chartData.Online = data1;
          var data = [
            {
              name: "在线",
              value: this.chartData.Online.Online,
            },
            {
              name: "离线",
              value: this.chartData.Online.Offline,
            },
          ];
          var legend = {
            orient: "vertical",
            left: 10,
            data: ["在线", "离线"],
            bottom: 5,
            show: true,
          };
          var title = {
            show: true,
            text: "设备在线情况分析",
            left: 10,
            textStyle: {
              fontSize: 14,
            },
          };
          var color = [colorArr[0], colorArr[1]];
          this.$refs.deviceOnline.setAndInit(data, legend, color, null, title);
        });
    },

    initSexCount() {
      this.sexLoading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetFaceViewSexData", this.currentSid, true)
        .then((data1) => {
          this.sexLoading = false;
          this.chartData.Sex = data1;
          var data = [
            {
              name: "男",
              value: this.chartData.Sex.Man,
            },
            {
              name: "女",
              value: this.chartData.Sex.Woman,
            },
          ];
          var legend = {
            orient: "vertical",
            left: 10,
            data: ["男", "女"],
            bottom: 5,
            show: true,
          };
          var title = {
            show: true,
            text: "过往人员性别数量分析",
            left: 10,
            textStyle: {
              fontSize: 14,
            },
          };
          var emphasis = {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          };
          var color = [colorArr[5], colorArr[3]];
          this.$refs.sexCount.setAndInit(data, legend, color, emphasis, title);
        });
    },

    initYear() {
      this.yearLoading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetFaceViewYearData", this.currentSid, true)
        .then((data1) => {
          this.yearLoading = false;
          this.chartData.Year = data1;
          var title = {
            show: true,
            text: "过往人员年龄段分析",
            left: 10,
            textStyle: {
              fontSize: 14,
            },
          };
          var data = [
            {
              name: "数量",
              data: [],
            },
          ];
          var xdata = [];
          this.chartData.Year.forEach((x) => {
            data[0].data.push(x.Count);
            xdata.push(getAgeByCode(x.Type));
          });
          var legend = {
            orient: "vertical",
            left: 10,
            data: xdata,
            bottom: 5,
            show: true,
          };
          this.$refs.yearCount.setAndInit(
            data,
            legend,
            [colorArr[2]],
            null,
            xdata,
            title,
            xdata,
            "30%"
          );
        });
    },
    initExpCount() {
      this.expLoading = true;
      this.$ksajax
        .ksPostPromise(
          "/api/hikctl/GetFaceViewExpressionData",
          this.currentSid,
          true
        )
        .then((data1) => {
          this.expLoading = false;
          this.chartData.Express = data1;
          var data = [];
          var ds = [];
          this.chartData.Express.forEach((x) => {
            var n = getExpByCode(x.Type);
            data.push({
              name: n,
              value: x.Count,
            });
            ds.push(n);
          });
          var legend = {
            orient: "vertical",
            left: 10,
            data: ds,
            bottom: 5,
            show: true,
          };
          var title = {
            show: true,
            text: "过往人员情绪分析",
            left: 10,
            textStyle: {
              fontSize: 14,
            },
          };
          var emphasis = {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          };
          var color = [colorArr[0], colorArr[1]];
          this.$refs.expCount.setAndInit(data, legend, color, emphasis, title);
        });
    },
    initMaskCount() {
      this.maskLoading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetFaceViewMaskData", this.currentSid, true)
        .then((data1) => {
          this.maskLoading = false;
          this.chartData.Mask = data1;
          var data = [
            {
              name: "已戴口罩",
              value: this.chartData.Mask.Mast,
            },
            {
              name: "未戴口罩",
              value: this.chartData.Mask.NoMast,
            },
          ];
          var legend = {
            orient: "vertical",
            left: 10,
            data: ["已戴口罩", "未戴口罩"],
            bottom: 5,
            show: true,
          };
          var title = {
            show: true,
            text: "口罩佩戴情况分析",
            left: 10,
            textStyle: {
              fontSize: 14,
            },
          };
          var emphasis = {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          };
          var color = [colorArr[0], colorArr[1]];
          this.$refs.maskCount.setAndInit(data, legend, color, emphasis, title);
        });
    },
    initPersonCount() {
      this.countLoading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetFaceViewCountData", this.currentSid, true)
        .then((data1) => {
          this.countLoading = false;
          this.chartData.Law7 = data1;
          var title = {
            show: true,
            text: "人流量分析",
            left: 10,
            textStyle: {
              fontSize: 14,
            },
          };
          var data = [
            {
              name: "数量",
              data: [],
              color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#ffb669" },
                { offset: 0.5, color: "#ea413f" },
                { offset: 1, color: "#132f57" },
              ]),
            },
          ];
          var xdata = [];
          this.chartData.Law7.forEach((x) => {
            data[0].data.push(x.Count);
            xdata.push(x.Day);
          });
          var legend = {
            orient: "vertical",
            left: 10,
            data: xdata,
            bottom: 5,
            show: true,
          };
          this.$refs.personCount.setAndInit(
            data,
            legend,
            [colorArr[1]],
            null,
            xdata,
            title
          );
        });
    },
    selectRouter() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetRouter", "1", true)
        .then((data) => {
          this.items = data;
          this.loading = false;
          if (this.items.length != 0) {
            this.change(this.items[0].Sid);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    change(val) {
      this.currentSid = val;
      this.select();
    },
    select() {
      this.initDeviceOnline();
      this.initYear();
      this.initSexCount();
      this.initExpCount();
          this.initMaskCount();
          this.initPersonCount();
      // this.loading = true;
      // this.$ksajax
      //   .ksPostPromise("/api/hikctl/GetFaceViewData", this.currentSid, true)
      //   .then((data) => {
      //     this.chartData = data;
      //     console.log(this.chartData);
      //     this.initPersonCount();
      //     this.initDeviceOnline();
      //     this.initMaskCount();
      //     this.initSexCount();
      //     this.initYear();
      //     this.initExpCount();
      //     this.loading = false;
      //   })
      //   .catch((err) => {
      //     this.$throw(err);
      //     this.loading = false;
      //   });
    },
  },
};
</script>


