<template>
  <div style="height: 100%">
    <div class="abimg" v-show="center" @click="center = false">
      <div style="text-align: right">
        <el-button type="primary">关闭大图</el-button>
      </div>
      <iframe
        style="width: 100%; height: 100%"
        :src="centerPic"
        @click="center = false"
      >
      </iframe>
    </div>
    <el-tabs v-model="activeName" style="height: 100%" type="border-card">
      <el-tab-pane label="基本信息" name="0">
        <div style="width: 100%; height: 100%" class="clearfixCol">
          <div class="lawdetail lawdetailpic" v-if="carPic">
            <img
              :src="carPic"
              style="height: auto; width: 100%; max-width: 100%"
              @click="showCenter(carPic)"
            />
          </div>
          <div class="lawdetail lawdetailInfo ksflexcol">
            <el-row class="law_row">
              <el-col :span="8">车牌</el-col>
              <el-col :span="16">{{ plate }}</el-col>
            </el-row>

            <el-row class="law_row">
              <el-col :span="8">所在停车场</el-col>
              <el-col :span="16">{{ ParkName }}</el-col>
            </el-row>
            <el-row class="law_row">
              <el-col :span="8">所在泊位名称</el-col>
              <el-col :span="16">{{ ParkPortName }}</el-col>
            </el-row>
            <el-row class="law_row">
              <el-col :span="8">所在泊位号</el-col>
              <el-col :span="16">{{ PortCode }}</el-col>
            </el-row>
            <el-row class="law_row">
              <el-col :span="8">入场时间</el-col>
              <el-col :span="16">{{ InTime }}</el-col>
            </el-row>
            <el-row class="law_row">
              <el-col :span="8">出场时间</el-col>
              <el-col :span="16">{{ OutTime }}</el-col>
            </el-row>
            <el-row class="law_row">
              <el-col :span="8">完成时间</el-col>
              <el-col :span="16">{{ CompleteTime }}</el-col>
            </el-row>
            <el-row class="law_row">
              <el-col :span="8">停车费用</el-col>
              <el-col :span="16">{{ charge }}</el-col>
            </el-row>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="入场图片" name="1" v-if="descPics[0].pic">
        <img
          :src="descPics[0].pic"
          style="width: auto; height: 100%; max-width: 100%"
          @click="showCenter(descPics[0].pic)"
        />
      </el-tab-pane>
      <el-tab-pane label="出场图片" name="2" v-if="descPics[1].pic">
        <img
          :src="descPics[1].pic"
          style="width: auto; height: 100%; max-width: 100%"
          @click="showCenter(descPics[1].pic)"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<style scoped>
img {
  cursor: pointer;
}
.abimg {
  background-color: white;
  position: fixed;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  z-index: 10000;
}
.lawdetail {
  height: 100%;
  width: 50%;
  float: left;
}
.law_row {
  padding: 2px 5px;
  margin: 0px;
  flex: 1;
  width: 100%;
}
.lawdetailpic {
  display: flex;
  align-items: center;
}
</style>
<script>
export default {
  props: ["value"],
  data() {
    return {
      centerPic: "",
      center: false,
      activeName: "0",
      descPics: [],
      carPic: "",
      plate: "",
      OutTime: "",
      CompleteTime: "",
      ParkName: "",
      InTime: "",
      ParkPortName: "",
      PortCode: "",
      charge: "",
    };
  },
  watch: {
    value() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    showCenter(pic) {
      this.centerPic = pic;
      this.center = true;
    },
    init() {
      if (this.value && this.value.InPic) {
        this.carPic = this.value.InPic;
        this.descPics = [
          { name: "入场图片", pic: this.value.InPic },
          { name: "出场图片", pic: this.value.OutPic },
        ];
        this.plate = this.value.Plate;
        this.ParkName = this.value.ParkName;
        this.ParkPortName = this.value.ParkPortName;
        this.PortCode = this.value.PortCode;
        this.InTime = this.value.InTime;
        this.OutTime = this.value.OutTime;
        this.CompleteTime = this.value.CompleteTime;
        this.charge = this.value.Charge;
      }
      this.activeName = "0";
    },
  },
};
</script>