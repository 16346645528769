<template>
    <div style="width:100%;height:100%;overflow: auto">
        <div style="color:white;font-size:12px">{{objvalue.Desc}}</div>
        <img :src="objvalue.Data.PicUrl" style="width:100%;height:auto;max-height:100%"/>
    </div>
</template>
<script>
export default {
    props:["objvalue"],
    watch:{
        objvalue:{
            immediate:true,
            deep:true,
            handler(){
                console.log(this.objvalue,"objvalue");
            }
        }
    }
}
</script>