<template>
  <div class="Stands white" v-loading="loading">
    <div class="funView">
      <div class="seleView">
        <el-select v-model="parkSid" @change="select">
          <el-option
            v-for="(item, index) in parkList"
            :key="index"
            :value="item.Sid"
            :label="item.Name"
          ></el-option>
        </el-select>
      </div>
      <actionbar
        class="btnView"
        v-bind:select="true"
        v-bind:modify="true"
        v-on:select="select"
        v-on:modify="modify"
      >
      </actionbar>
    </div>
    <parkPage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></parkPage>

    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('泊位排序')" :label-cols="2">
          <b-form-input
            type="number"
            v-model="modalData.Order"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('横竖泊位')" :label-cols="2">
          <b-form-select v-model="modalData.IsVertical">
            <option :value="false">横排</option>
            <option :value="true">竖排</option></b-form-select
          >
        </b-form-group>

        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width: 100px" v-on:click="hiden">Cancel</b-button>
            <b-button type="submit" style="width: 100px" variant="primary"
              >OK</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<style>
.admin {
  background-color: white;
}
.funView {
  display: flex;
  align-items: center;
  background: rgb(209, 237, 219);
}
.seleView {
  width: 120px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.el-input__inner {
  height: 32px;
  font-size: 12px;
}
.el-input__suffix {
  display: flex;
  align-items: center;
}
.btnView {
  flex: 1;
  height: 44px;
}
.actionbar-content {
  border: none;
}
</style>

<script>
import actionbar from "@/components/actionbar";
import parkPage from "./components/parkPage.vue";
export default {
  data: function () {
    return {
      modalData: {
        Sid: "",
        Order: 0,
        IsVertical: false,
      },
      title: "",
      cols: [
        { name: this.$t("停车场名称"), value: "ParkName" },
        { name: this.$t("泊位名称"), value: "Name" },
        { name: this.$t("泊位号"), value: "PortCode" },
        { name: this.$t("泊位ID"), value: "PortId" },
        { name: this.$t("泊位状态"), value: "StatusDes" },
        { name: this.$t("泊位排序"), value: "Order" },
        { name: this.$t("横竖泊位"), value: "IsVerticalDes" },
      ],
      items: [],
      index: 0,
      count: 5,
      total: 0,
      current: null,
      parkSid: "",
      loading: false,
      parkList: [],
    };
  },
  mounted: function () {
    this.getParkList();
  },
  components: {
    actionbar,
    parkPage,
  },
  created: function () {},
  methods: {
    hiden() {
      this.$refs.modallg.hide();
    },
    currentRow(val) {
      this.current = val;
    },
    getParkList() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkList", "", true)
        .then((data) => {
          if (data.length != 0) {
            data.push({ Sid: "", Name: "全部" });
            this.parkList = data;
            this.loading = false;
            this.parkSid = this.parkList[0].Sid;
            this.select();
          } else {
            this.$throw(this.$t("数据为空"), this);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/ParkCtl/GetParkPortList", this.parkSid, true)
        .then((data) => {
          data.forEach((element) => {
            element.StatusDes = element.Status === 0 ? "空闲" : "占用";
            element.IsVerticalDes =
              element.IsVertical === true ? "竖排" : "横排";
          });
          this.total = data.length;
          var array = [];
          var i = this.index;
          for (i; i < this.index + this.count; i++) {
            if (i >= data.length) {
              break;
            }
            array.push(data[i]);
          }
          this.items = array;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },

    onSubmit() {
      if (this.modalData.Order < 0) {
        this.$throw(this.$t("泊位排序不能少于0"), this);
        return;
      }
      console.log("md:", this.modalData);
      this.loading = true;
      this.$ksajax
        .ksPostPromise(
          "/api/ParkCtl/UpdateParkPort",
          {
            Sid: this.modalData.Sid,
            Order: parseInt(this.modalData.Order),
            IsVertical: this.modalData.IsVertical,
          },
          true
        )
        .then(() => {
          this.select();
          this.loading = false;
          this.hiden();
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    modify() {
      if (!this.current) {
        this.$throw(this.$t("选择一条数据"), this);
      } else {
        this.modalData = {
          Sid: this.current.Sid,
          Order: this.current.Order,
          IsVertical: this.current.IsVertical,
        };
        if (this.current.LampStand) {
          this.modalData.LStandSid = this.current.LampStand.Sid;
        }
        this.title = this.$t("修改");
        this.$refs.modallg.show();
      }
    },

    tableCreated() {
      // this.select();
    },

    currentPage(val) {
      this.index = (val - 1) * this.count;
      this.select();
    },
  },
};
</script>

