<template>
    <div style="width:100%;height:100%;position:relative" ref="ledp">
        <div v-for="item in data" style="width:100%;height:100%;position:absolute;"  :key="item.Sid">
            <transition
                :enter-active-class="item.InAnimate"
                :leave-active-class="item.OutAnimate"
            >
            <div  class="animated"  animate-duration="15s" v-show="item.Active" :style="getStyle(item.InAnimate)">
                <ledtemplete :modalData="item" ref="ledtemp"></ledtemplete>
            </div>
       </transition>
       </div>
    </div>
</template>
<script>
window.fresh=function(){
    window.location.reload();
}
import ledtemplete from '@/components/ledtemplete'
import {getJson} from '@/assets/utils/datef'
export default {
    props:[
        "sid","Width","Height"
    ],
    components:{ledtemplete},
    data:function(){
        return {
            //loading:{},
            data:[],
            stageTimmer:null,
            current:0
        }
    },
    mounted:function(){
        this.setSize();
        this.data=this.getReset();
        this.init();
    },
    computed:{
        
    },
    methods:{getStyle(){
            return{
                "height": "100%",
                "width": "100%",
                "display":"flex",
                "align-content": "center",
                "justify-content":"center",
                "flex-flow": "column"
            }
        },
        getReset(){
            var height=document.body.clientHeight;
            var width=document.body.clientWidth;
            return [{

                        "Active": true,
                        "Sid": "123",
                        "Custom": "",
                        "Duration": 5,
                        "Height": height,
                        "InAnimate": "fadeIn",
                        "InTimmer": 2,
                        "Name": "默认节目",
                        "OutAnimate": "bounceOut",
                        "OutTimmer": 2,
                        "Publish": 1,
                        "State": 1,
                        "Width": width,
                        "Devices": [],
                        "StateCotnents": [
                            {
                        "style":{
                             position:"absolute",
                                    top:0+"px",
                                    left:0+"px",
                                    width:width+"px",
                                    height:height+"px",
                                    "z-index":0+"px"
                            },
                                "Custom": {
                                    "width": "100%",
                                    "height": "100%",
                                    "font-size": "1em",
                                    "font-family": "微软雅黑",
                                    "font-style": "normal",
                                    "font-weight": "bold",
                                    "writing-mode": "horizontal-tb",
                                    "text-align": "left",
                                    "background-color": "#ffffff",
                                    "color": "#92C52B",
                                    "display": "flex",
                                    "align-items": "center",
                                    "justify-content": "center",
                                    "border": "1px solid green",
                                    "white-space": "normal",
                                    "flex-direction": "column"
                                },
                                "Height": height,
                                "LedResourceSid": "201811011432416171117534",
                                "Sid": "201811091625283490631084",
                                "Width": width,
                                "X": 0,
                                "Y": 0,
                                "Z": 100,
                                "Data": {
                                    "Name": "默认图片",
                                    "Content": "img/log-bg.jpg",
                                    "Custom": "",
                                    "Duration": 15,
                                    "Sid": "201811011432416171117534",
                                    "Size": 10,
                                    "Type": 1
                                },
                            }
                        ],
                    }]
        },
        init(){
            var sid=this.$route.query.Sid;
            if(!sid){
                sid=this.sid;
            }
            if(sid){
                //查询设备节目数据
                //this.loading=this.$loading({fullScreen:true});
                this.$ksajax.ksPostPromise("/api/led/GetDevLedStage",sid,false).then((success)=>{
                    if(success.Items.length!=0){
                    this.data=[];
                    success.Items.forEach(x => {
                        if(x.StateCotnents&&x.StateCotnents.lenth!=0){
                            x.StateCotnents.forEach((y)=>{
                                y.Custom=getJson(y.Custom);
                                y.style={
                                    position:"absolute",
                                    top:y.Y+"px",
                                    left:y.X+"px",
                                    width:y.Width+"px",
                                    height:y.Height+"px",
                                    "z-index":y.Z+"px"
                                }
                            })
                        }
                        x.Active=false;
                        this.data.push(x);
                    });
                    if(this.data[0]){
                        this.data[0].Active=true;
                        this.data[0].Timmer=new Date();
                        this.timmer();
                    }}
                   // this.loading.close();
                }).catch((err)=>{
                    this.$throw(err);
                   // this.loading.close();
                })
            }
        },
        timmer(){
            if(this.data.length>1){
                this.stageTimmer=window.setInterval(()=>{
                    for(var i=0;i<this.data.lenth;i++){
                        if(this.data[i].Active){
                            this.current=i;
                            break;
                        }
                    }
                    var ti=this.data[this.current].Duration*1000;
                    if(new Date()-this.data[ this.current].Timmer>ti){
                        this.data[this.current].Active=false;
                         this.current+=1;
                        if(this.current==this.data.length){
                             this.current=0;
                        }
                        this.data[this.current].Active=true;
                        this.data[this.current].Timmer=new Date();
                    }
                },1000);
            }
        },
        setSize(){
            var w="180";
            var h="320";
            if(this.Width){
                document.body.style.width=this.Width;
                document.body.style.height=this.Height;
            }else{
                if(this.$route.query.Width){
                    w=this.$route.query.Width;
                }
                if(this.$route.query.Height){
                    h=this.$route.query.Height;
                }
                if(window.$card){
                    w=window.$card.getScreenWidth();
                    h=window.$card.getScreenHeight();
                }
                document.body.style.width=w+"px";
                document.body.style.height=h+"px";
            }
        }
    }
}
</script>
