<template>
        <b-row class="lcontrol white">
            <b-col cols="12" md="4" lg="3" xl="3" class="side lsidel">
                <b-tabs  pills card>
                <b-tab :title="$t('网关')" active>
                    <gateway v-on:itemclick='gatewayClick'></gateway>
                </b-tab>
                <b-tab :title="$t('网关分组')" >
                    <gatewayg v-on:itemclick='gatewaygClick'></gatewayg>
                </b-tab>
                <b-tab :title="$t('软件分组')">
                    <softg v-on:itemclick='softgClick'></softg>
                </b-tab>
                </b-tabs>
            </b-col>
            <b-col cols="12" md="8" lg="9" xl="9" class="sidecontent lsidel" style="padding-left:50px">
                <lightcontrol :items="items" :type="type" :gateways="gateways" v-on:refresh="refresh" :sid="sid"></lightcontrol>
            </b-col>
        </b-row>
</template>
<style scoped>
.lcontrol,.lsidel{
    margin: 0;
    padding: 0;
}
.el-table__body-wrapper{
    overflow: auto;
}
</style>
<script>
import gateway from './componets/gateway'
import gatewayg from './componets/gatewayg'
import softg from './componets/softg'
import lightcontrol from './componets/lightcontrol'
export default {
    data:function(){
        return {
            loading:{},
            items:[],
            type:0,
            gateways:[],sid:""
        }
    },
    components:{gateway,gatewayg,softg,lightcontrol},
    methods:{
        selectDevice(){
            return new Promise((res,rej)=>{
                this.loading=this.$loading({fullScreen:true});
                let obj={
                "Method": "read",
                "SZ": {
                    "platform": 1,
                    "type": 4,
                    "skip": 0,
                    "limit":100000,
                    "name": ""
                    },
                    "Custom":{
                        "Index":0,
                        "Count":10000
                    }
                }
            this.$ksajax.ksPostPromise("/api/agent/RequestSzApi",obj,true).then((data)=>{
                this.loading.close();
                res(data);
            }).catch(err=>{
                this.loading.close();
                rej(err);
            })
            })
            
        },
        gatewayClick(obj){
            //查询设备
            this.selectDevice().then((data)=>{
                this.items=[];
                this.type=0;
                this.gateways=[];
                this.gateways.push(obj);
                data.Items.forEach(x => {
                    if(x.SZ.gatewayId==obj.uid){
                        this.items.push(x);
                    }
                });
            }).catch(err=>{
                this.$throw(err);
            })
        },
        gatewaygClick(obj){
             //查询设备
            this.selectDevice().then((data)=>{
                this.items=[];
                this.type=1;
                this.gateways=obj;
                data.Items.forEach(x=>{
                    if(obj.find(y=>y.addr==x.SZ.gateway_addr)){
                        this.items.push(x);
                    }
                })
            }).catch(err=>{
                this.$throw(err);
            })
        },  softgClick(obj){
             //查询设备
            this.selectDevice().then((data)=>{
                this.items=[];
                this.type=2;
                this.gateways=[];
                this.sid=obj;
                data.Items.forEach(x=>{
                    if(x.Custom.Groups.find(y=>y==obj)){
                        this.items.push(x);
                    }
                })
            }).catch(err=>{
                this.$throw(err);
            })
        },
        refresh(data){
            if(data.type==0){
                this.gatewayClick(data.gateways[0]);
            }
            else if(data.type==1){
                this.gatewaygClick(data.gateways);
            }
            else if(data.type==2){
                this.softgClick(data.sid);
            }
        }

    }
}
</script>

