<template>
  <div class="Stands white" v-loading="loading">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      v-bind:insert="true"
      v-bind:del="true"
      v-on:select="select"
      v-on:insert="insert"
      v-on:modify="modify"
      v-on:delete="del"
    >
    </actionbar>
    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/hikctl/DelCarAlarm"
      :title="$t('删除')"
    ></deletedata>
    
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('车牌号码')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Plate" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('布防原因')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Reason" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('布防级别')" :label-cols="2">
          <b-form-select
            id="form-Tids"
            required
            v-model="modalData.Level"
            :options="levels"
          ></b-form-select>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width:100px" v-on:click="hiden">Cancel</b-button>
            <b-button type="submit" style="width:100px" variant="primary">OK</b-button>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<style>
.admin {
  background-color: white;
}
</style>

<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
export default {
  data: function () {
    return {
      modalData: {
        Sid: "",
        Level: "",
        Reason: "",
        Plate:""
      },
      title: "",
      cols: [
        { name: this.$t("创建日期"), value: "CreateTime" },
        { name: this.$t("车牌号码"), value: "Plate" },
        { name: this.$t("级别"), value: "Level" },
      ],
      items: [],
      index: 0,
      count: 5,
      total: 0,
      current: null,
      loading: false,
      devs:[],
      sid:"",
      levels:[
          {value:0,text:"普通布防"},
          {value:1,text:"安全布防"},
          {value:2,text:"重点布防"},
      ]
    };
  },
  mounted: function () {
    this.select();
  },
  components: {
    actionbar,
    tablepage,
    modify,
    deletedata,
  },
  created: function () {},
  methods: {
    hiden(){
        this.$refs.modallg.hide();
    },
    showModify(insert) {
      this.labels = this.getLable(insert);
      this.$refs.modify.show();
    },
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      return {
        Sid: "",
        Name: "",
        Device: []
      };
    },
    select() {
      this.loading = true;
      var req={
          Index:this.index,
          Count:this.count
      }
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetCarAlarm", req, true)
        .then((data) => {
          this.items = data.Items;
          this.total=data.TotalCount
          this.loading = false;
          console.log (data);
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    insert() {
      this.title = this.$t("新增");
      this.modalData = this.resetData();
      this.$refs.modallg.show();
    },
    onSubmit(){
        this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/SetCarAlarm", this.modalData, true)
        .then(() => {
          this.select();
          this.loading = false;
          this.hiden();
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    modify() {
      if (!this.current) {
        this.$throw(this.$t("选择一条数据"), this);
      } else {
        this.modalData = {
          Sid: this.current.Sid,
          Plate: this.current.Plate,
          Reason: this.current.Reason,
          Level: this.current.Level,
        };
        if (this.current.LampStand) {
          this.modalData.LStandSid = this.current.LampStand.Sid;
        }
        this.title = this.$t("修改");
        this.$refs.modallg.show();
      }
    },
    del() {
      if (!this.current) {
        this.$throw(this.$t("选择一条数据"), this);
      } else {
        this.sid = this.current.Sid;
        this.$refs.del.show();
      }
    },
    tableCreated() {
      // this.select();
    },
    currentPage(val) {
      this.index = (val - 1) * this.count;
      this.select();
    },
    submited() {
      this.index = (1 - 1) * this.count;
      this.select();
    },
    deleted() {
      this.index = (1 - 1) * this.count;
      this.select();
    },
  },
};
</script>

