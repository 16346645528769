<template>
  <div class="sidemenu">
    <div class="sidemenu-title">
      <div>
        <span>{{ title }}</span>
      </div>
      <div class="sidemenu-button">
        <button class="btn btn-defalut btn-sm toggle-btn" v-b-toggle.collapse1>
          <i class="fa fa-list" style="font-size: 20px"></i>
        </button>
      </div>
    </div>
    <hr />
    <div v-if="items.length === 0">{{ $t("暂无数据") }}</div>
    <b-collapse id="collapse1" visible class="mt-2">
      <b-list-group class="sidemenu-group">
        <b-list-group-item
          href="#"
          variant="success"
          v-for="item in items"
          :key="item.sid"
          :active="item.active"
          @click="clickItem(item.sid)"
          >{{ item.text }}</b-list-group-item
        >
      </b-list-group>
    </b-collapse>
  </div>
</template>
<style scoped>
.toggle-btn {
  display: none;
}
@media (max-width: 765px) {
  .toggle-btn {
    display: block;
  }
}
.list-group-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.sidemenu-button {
  position: absolute;
  right: 0px;
  top: -5px;
}
.sidemenu-title {
  color: #909399;
  margin-top: 1rem;
  font-size: 0.9rem;
  padding-left: 24px;
  position: relative;
}
.bd-sidebar .nav > li > a.active {
  color: #000;
  font-weight: 700;
}
.bd-sidebar .nav > li > a {
  display: block;
  padding: 4px 24px;
  padding: 0.25rem 1.5rem;
  font-size: 90%;
  color: rgba(0, 0, 0, 0.65);
}
</style>

<script>
export default {
  props: ["title", "items"],
  methods: {
    clickItem(sid) {
      this.$emit("clickItem", sid);
    },
  },
};
</script>
