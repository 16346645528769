<template>
    <div class="white">
        <actionbar v-bind:select='true' v-bind:modify='true' :del="true" :insert="true"
        v-on:select='select'
        v-on:modify='modify'
        v-on:delete='del'
        v-on:insert="insert">
        </actionbar>
        
        <tablepage ref="tableref" id="tableref"
         v-on:created='tableCreated'
         v-on:currentPage='currentPage'
         v-on:currentRow='currentRow'
         :cols='cols'
         :items='items'
         :total='total'
         :perpage='count'
        ></tablepage>
        
        <modify ref="modify"
         :modaldata='modalData'
         :title='title'
         :labels='labels'
         v-on:submited='submited'
         url="/api/xixun/AddProgram"
        ></modify>
        <deletedata ref="del"
            v-on:deleted="deleted"
            :sid="sid"
            url="/api/xixun/DelProgram"
            :title="$t('删除')"
        ></deletedata>
    </div>
</template>
<script>
import actionbar from '@/components/actionbar'
import tablepage from '@/components/tablepage'
import modify from '@/components/modify'
import deletedata from '@/components/deletedata'
export default {
    components:{actionbar,tablepage,modify,deletedata},
    data:function(){
        return{
            text:"test",
            modalData:this.resetData(),
            loading:{},
            cols:[
                {name:this.$t("名称"),value:"name"},
                {name:this.$t("长度"),value:"width"},
                {name:this.$t("高度"),value:"height"},
                {name:this.$t("创建日期"),value:"dateCreated"},
                { name: this.$t("详情"), value: "url", link: true }
            ],
            items:[],
            total:0,
            count:20,
            index:0,
            sid:"",
            labels:this.getResetLable(),
            title:"",
            current:null
        }
    },
    methods:{
        select(){
             this.loading=this.$loading({fullScreen:true});
             this.$ksajax.ksPostPromise("/api/xixun/GetProgram","",true).then((data)=>{
                 this.items=data;
                 this.items.forEach(x=>{
                     x.url = `/admin/xixun/progDetail?sid=${x.Sid}`;
                 })
                 if(this.loading.close){
                     this.loading.close();
                 }
             }).catch((err)=>{
                 this.$throw(err,this);
                 if(this.loading.close){
                     this.loading.close();
                 }
             })
        },
        del(){
            if(this.current==null)
            {
                throw this.$t("选择一条数据");
            }
            this.sid=this.current.Sid;
            this.$refs.del.show();
        },
        modify(){
            if(this.current==null)
            {
                throw this.$t("选择一条数据");
            }
            if(!this.current.LampStand){
                this.current.LampStand={}
            }
            this.modalData={
                "Name": this.current.name,
                "Width": this.current.width,
                "Height": this.current.height,
                "Lng": this.current.Lng,
                "Sid": this.current.Sid,
            }
            this.$refs.modify.show();
        },
        insert(){
            this.modalData=this.resetData();
            this.$refs.modify.show();
        },
        tableCreated(){},
        currentPage(){

        },
        currentRow(val){
            this.current=val;
        },
        resetData(){
           return  {
                "Name": "",
                "Width": "",
                "Height": "",
                "Sid": ""
            }
        },
        getResetLable(){
            return [
                {
                    "label":this.$t("名称"),
                    "type":"text",
                    "value":"Name",
                    "require":true,
                }, 
                {
                    "label":this.$t("长度"),
                    "type":"number",
                    "value":"Width",
                    "require":true,
                }, 
                { 
                    "label":this.$t("高度"),
                    "type":"number",
                    "value":"Height",
                    "require":true,
                    "bind":this.$store.getters.getDeviceType("008")
                }
            ]
        },
        submited(){
            this.select();
        },
        deleted(){
            this.select();
         }
        },
    mounted:function(){
        this.select();
    }
}
</script>
