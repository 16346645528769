<template>
    <div class="ligthview">
        <div class="ligthview_1_tall">
            <div>
                <box>
                    <div class="chunk_title">
                        {{$t("实时对比")}}
                        <span style="float:right;">
                        {{$t("请选择摄像机")}} :
                            <el-select v-model="cameraSelect"  size="mini" @change="selectChange">
                                <el-option v-for="item in cameraArray"
                                           :key="item.Sid"
                                           :label="item.DeviceName"
                                           :value="item.Sid">
                                </el-option>
                            </el-select>
                        </span>
                    </div>
                    <div class="chunk_content">
                        <div class="left_1">
                            <b-img v-if="!(faceData[1] && faceData[1].ImageUrl)" class="face1" src="/img/unknow.png"></b-img>
                            <b-img v-if="(faceData[1] && faceData[1].ImageUrl)" class="face1" :src="faceData[1].ImageUrl" @click="clickFaceDetail"></b-img>
                            <div class="vip"></div>
                            <label class="face1_title"> {{$t("抓拍人脸")}}</label>
                        </div>
                        <div style="width:33.3%; display:inline-block; text-align:center;">
                            <h3>{{$t("相似度")}}</h3>
                            <div class="similar_num" style="margin:0 auto;">{{faceData[1].Similarity*100 || 0 | keepZeroNum}}%</div>
                        </div>
                        <div class="right_1">
                            <b-img v-if="!faceData[1].FaceUrl" class="face2" src="/img/unknow.png"></b-img>
                            <b-img v-if="faceData[1].FaceUrl" class="face2" :src="faceData[1].FaceUrl"></b-img>
                            <label class="face2_title">{{$t("人脸库")}}</label>
                        </div>
                    </div>

                    <!-- 人形检测 -->
                    <div class="chunk_title" v-if="faceData[1].Type==0">{{$t("人形检测")}}</div>
                    <div class="chunk_content" v-if="faceData[1].Type==0">
                        <div class="chunk_item_1">{{$t("性别")}}: {{faceData[1].SexString || "-"}}</div>
                        <div class="chunk_item_1">{{$t("上衣颜色")}}{{faceData[1].JacetColor || "-"}}</div>
                        <div class="chunk_item_1">{{$t("是否骑车")}}{{faceData[1].IsRide?"是":"否"}}</div>
                        <div class="chunk_item_1">{{$t("是否带帽")}} {{faceData[1].IsHat?"是":"否"}}</div>
                        <div class="chunk_item_1">{{$t("下装类型")}} {{faceData[1].TrousersType || "-"}}</div>
                        <div class="chunk_item_1">{{$t("下装颜色")}} {{faceData[1].TrousersColor || "-"}}</div>
                        <div class="chunk_item_1">{{$t("发型")}} {{faceData[1].HairStyle || "-"}}</div>
                        <div class="chunk_item_1">{{$t("是否拎东西")}}{{faceData[1].IsThings?"是":"否"}}</div>
                    </div>
                    
                    <!-- 人脸检测 -->
                    <div class="chunk_title" v-if="faceData[1].Type==1">{{$t("人脸检测")}}</div>
                    <div class="chunk_content" v-if="faceData[1].Type==1">
                        <div class="chunk_item_1">{{$t("性别")}}: {{faceData[1].SexString || "-"}}</div>
                        <div class="chunk_item_1">{{$t("年龄")}}: {{faceData[1].Age}}</div>
                        <div class="chunk_item_1">{{$t("年龄")}}是否戴眼镜: {{faceData[1].IsGlass?"是":"否"}}</div>
                    </div>
                   
                    <!-- 人脸对比 -->
                    <div class="chunk_title" v-if="faceData[1].Type==2">{{$t("人脸对比")}}</div>
                    <div class="chunk_content" v-if="faceData[1].Type==2">
                        <div class="chunk_item_1" :title="faceData[1].Name">{{$t("姓名")}}: {{faceData[1].Name || "-"}}</div>
                        <div class="chunk_item_1">{{$t("性别")}}: {{faceData[1].SexString || "-"}}</div>
                        <div class="chunk_item_1">{{$t("年龄")}}: {{faceData[1].Age || "-"}}</div>
                        <div class="chunk_item_1">{{$t("民族")}}: {{faceData[1].Nation || "-"}}</div>
                        <div class="chunk_item_2">{{$t("身份证")}}: {{faceData[1].IdNumber || "-"}}</div>
                        <div class="chunk_item_2">{{$t("住址")}}: {{faceData[1].Address || "-"}}</div>
                        <div class="chunk_item_2">{{$t("备注")}}: {{faceData[1].Describe || "-"}}</div>
                    </div>
                    <div class="chunk_content" v-show="faceData[1].Type == null" style="min-height:300px;">暂无信息</div>

                    <div class="chunk_title" v-show="faceData[1].Type != null"></div>
                    <div class="chunk_content" v-show="faceData[1].Type != null">
                        <div class="chunk_item_2">{{$t("抓拍设备")}}: {{faceData[1].DeviceName || "-"}}</div>
                        <div class="chunk_item_2">{{$t("抓拍时间")}}: {{faceData[1].DetectTime || "-"}}</div>
                        <div class="chunk_toolbar">
                            <el-button class="btn_normal" @click="detail" :disabled="faceData[1].Sid==null">{{$t("查看经过路线")}}</el-button>
                        </div>
                    </div>

                    <div class="chunk_title">
                        {{$t("上一个对比")}}
                        <div class="btn-toolbar">
                            <router-link :to="`/admin/Face/FaceMsg`">{{$t("更多")}}>></router-link>
                        </div>
                    </div>

                    <div class="chunk_content">
                        <div class="left_2">
                            <b-img v-if="!(faceData[0] && faceData[0].ImageUrl)" class="face1_2" src="/img/unknow.png"></b-img>
                            <b-img v-if="(faceData[0] && faceData[0].ImageUrl)" class="face1_2" :src="faceData[0].ImageUrl" @click="clickFaceDetail(faceData[0])"></b-img>
                            <label class="face1_2_title">{{faceData[0].DeviceName || "-设备"}} <i>{{faceData[0].DetectTime}}</i></label>
                        </div>
                        <div class="middle_2">
                            <h3>{{$t("相似度")}}</h3>
                            <div class="similar_num">{{faceData[0].Similarity*100 || 0 | keepZeroNum}}%</div>
                            <!--<el-button @click="clickFaceDetail" size="mini">查看详情</el-button>-->
                        </div>
                        <div class="right_2">
                            <b-img v-if="!faceData[0].FaceUrl" class="face2_2" src="/img/unknow.png"></b-img>
                            <b-img v-if="faceData[0].FaceUrl" class="face2_2" :src="faceData[0].FaceUrl"></b-img>
                            <label class="face2_2_title">{{$t("人脸库")}}: {{faceData[0].Name || "-"}}</label>
                        </div>
                    </div>
                </box>
            </div>
        </div>

        <div class="ligthview_4_big" style="overflow:hidden;">
            <div style="overflow:hidden;">
                <box>
                    <div style="width:100%;height:100%;overflow:hidden;">

                        <div class="chunk_title">
                           {{$t("实时播放")}} <el-button type="success" size="mini" style="margin-left:10px;" @click="grabOnce">{{$t("立刻抓取")}}  </el-button>
                            <div class="btn-toolbar">
                              {{$t("局域网播放模式")}}   <el-switch v-model="camIsLocal" active-color="#13ce66" inactive-color="#ff4949" @change="elswitch()">
                                </el-switch>
                            </div>
                        </div>
                        <div style="width:100%; height:calc(100% - 65px);">
                            <facenatpay v-if="camIsLocal" ref="natpay" :data='playdata' count="1"></facenatpay>
                            
                            <netvideo ref="netplay"  v-if="!camIsLocal" :showRight="false" :playdata="playdata"></netvideo>
                            <!-- <facenetpay v-if="!camIsLocal" ref="netplay" :data='playdata'></facenetpay> -->
                        </div>
                        <!--<camera v-if="camIsLocal" ref="netplay" :data='playdata' :isLocal="camIsLocal" style="width:100%;height:90%;"></camera>-->
                    </div>
                </box>
            </div>
        </div>

        <div class="ligthview_4_small">
            <div>
                <box>
                    <div class="chunk_title">
                        {{$t("实时抓拍")}} 
                        <div class="btn-toolbar">
                            <router-link :to="`/admin/Face/MoreFace`" style="color:#fff;">{{$t("更多")}}>></router-link>
                        </div>
                    </div>
                    <div class="chunk_content" style="max-height:222px; overflow:auto; white-space:nowrap;">
                        <div class="chunk_item_91px" v-for="item in faceArray" :key="item.sid">
                            <b-img v-if="!item.FaceUrl" class="face1_2" src="/img/unknow.png"></b-img>
                            <b-img v-if="item.FaceUrl" class="face1_2" :src="item.FaceUrl"></b-img>
                            <label class="face1_2_title">{{item.DetectTime}}</label>
                        </div>
                    </div>
                </box>
            </div>
        </div>

        <div class="clear"></div>

        <!-- 新增、修改对话框 -->
        <!--<b-modal id="modallg"
                 ref="modallg"
                 size="lg"
                 v-bind:title="modalTitle"
                 hide-footer
                 header-bg-variant="success"
                 body-text-variant="dark">

            <b-form @submit="onSubmit">
                <b-form-group v-bind:label="$t('姓名')" :label-cols="2">
                    <b-form-input type="text" v-model="modalData.Name" required placeholder="请输入姓名（必填）"></b-form-input>
                </b-form-group>
                <b-form-group v-bind:label="$t('身份证')" :label-cols="2">
                    <b-form-input type="text" v-model="modalData.IdNumber"></b-form-input>
                </b-form-group>
                <b-form-group v-bind:label="$t('民族')" :label-cols="2">
                    <b-form-select :options="initData.Nation" v-model="modalData.Nation"></b-form-select>
                </b-form-group>
                <b-form-group v-bind:label="$t('类型')" :label-cols="2">
                    <b-form-select :options="initData.Type" v-model="modalData.Type"></b-form-select>
                </b-form-group>
                <b-form-group v-bind:label="$t('生日')" :label-cols="2">
                    <el-date-picker required v-model="modalData.Birthday" type="date"></el-date-picker>
                </b-form-group>
                <b-form-group v-bind:label="$t('住址')" :label-cols="2">
                    <b-form-input type="text" v-model="modalData.Address"></b-form-input>
                </b-form-group>
                <b-form-group v-bind:label="$t('性别')" :label-cols="2">
                    <b-form-radio-group v-model="modalData.Sex">
                        <b-form-radio :value="0">男</b-form-radio>
                        <b-form-radio :value="1">女</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
                <b-form-group v-bind:label="$t('状态')" :label-cols="2">
                    <b-form-radio-group v-model="modalData.IsEnabled">
                        <b-form-radio :value="0">禁用</b-form-radio>
                        <b-form-radio :value="1">启用</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
                <b-form-group v-bind:label="$t('广播提示语')" :label-cols="2">
                    <b-form-input type="text" v-model="modalData.VoiceText"></b-form-input>
                </b-form-group>
                <b-form-group v-bind:label="$t('备注')" :label-cols="2">
                    <b-form-input type="textarea" v-model="modalData.Describe" autosize placeholder="请输入备注"></b-form-input>
                </b-form-group>
                <b-form-group>
                    <hr>
                    <div class="modal-foot">
                        <b-button style="width:100px" v-on:click="hiden(modallg)">Cancel</b-button>
                        <b-button type="submit" style="width:100px" variant="primary">OK</b-button>
                    </div>
                </b-form-group>
            </b-form>
        </b-modal>-->

        <!-- 新增、修改对话框 -->
        <!--<b-modal id="modallg2"
                 ref="modallg2"
                 size="lg"
                 v-bind:title="modalTitle"
                 hide-footer
                 header-bg-variant="success"
                 body-text-variant="dark">

            <b-form @submit="onSubmit">
                <b-container>
                    <b-row>
                        <b-col>
                            <el-upload class="avatar-uploader"
                                       action="/api/Upload/FileUploadSingle"
                                       :show-file-list="false"
                                       :on-success="handleAvatarSuccess"
                                       :before-upload="beforeAvatarUpload">
                                <img v-if="modalData.ImageUrl" :src="modalData.ImageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </b-col>
                        <b-col>
                            <b-form-group v-bind:label="$t('人脸库')" :label-cols="2">
                                <b-form-input type="text" v-model="setName" readonly></b-form-input>
                            </b-form-group>
                            <b-form-group v-bind:label="$t('备注')" :label-cols="2">
                                <b-form-textarea rows="3" v-model="modalData.Describe" placeholder="请输入备注"></b-form-textarea>
                            </b-form-group>
                            <b-form-group :label-cols="2">
                                <p>仅支持jpg、png、gif、bmp格式，文件大小不超过1M，且尺寸在1280*1280像素以内</p>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-container>
                <b-form-group>
                    <hr>
                    <div class="modal-foot">
                        <b-button style="width:100px" v-on:click="hiden(modallg)">Cancel</b-button>
                        <b-button type="submit" style="width:100px" variant="primary">OK</b-button>
                    </div>
                </b-form-group>
            </b-form>
        </b-modal>-->

        <!-- face详情 -->
        <faceDetail ref="faceDetail"></faceDetail>
    </div>
</template>

<style>
    .ligthview_1_tall {
        width: 33.33%;
        height: 100%;
        min-height: 736px;
    }

    .ligthview .ligthview_4_big > div, .ligthview .ligthview_4_small > div, .ligthview .ligthview_1_tall > div {
        padding: 5px;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .ligthview_4_big {
        width: 66%;
        height: 71.66%;
    }

    .ligthview_4_small {
        width: 66%;
        height: 28.33%;
    }

    .chunk_title {
        color: #fff;
        border-bottom: 1px solid #3274b8;
        margin: 13px;
        padding-bottom: 10px;
    }

        .chunk_title .btn-toolbar {
            float: right;
        }
            .chunk_title .btn-toolbar a {
                color:#fff;
            }

    .chunk_content {
        /*min-height: 10%;*/
        /*border: 1px solid red;*/
        margin: 0 15px;
        position: relative;
    }

        .chunk_content .left_1, .chunk_content .right_1 {
            width: 33.3%;
            display: inline-block;
            position: relative;
            vertical-align: top;
            text-align:center;
        }

        .chunk_content .face1 {
            width: 161px;
            height: 168px;
            text-align: center;
            cursor:pointer;
        }

        .chunk_content .vip {
            position: absolute;
            top: 0;
            background-color: rgba(255, 106, 0,0.5);
            text-align: center;
        }

        .chunk_content .face1_title {
            /*color: #ff6a00;*/
            color:#fff;
            font-size: 1rem;
            display: block;
            /*padding-left: 45px;*/
        }

        .chunk_content h3 {
            font-size: 1.5rem;
            color: #fff;
            margin-top: 16px;
        }

        .chunk_content .face2 {
            /*width: 110px;*/
            /*height: 115px;*/
            width: 161px;
            height: 168px;
            text-align: center;
        }

        .chunk_content .face2_title {
            /*color: #ff6a00;*/
            color:#fff;
            font-size: 1rem;
            /*padding-left: 13%;*/
            display: block;
        }

        .chunk_content .similar_num {
            background-color: #fff;
            color: darkgreen;
            font-size: 2.5rem;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            /*position: absolute;*/
            display: flex;
            justify-content: center;
            align-items: center;
            /*left: 80px;*/
            /*top: -10px;*/
            font-weight: 700;
        }

        .chunk_content .chunk_item_1 {
            width: 50%;
            color: #fff;
            display: inline-block;
            padding-bottom: 5px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .chunk_content .chunk_item_2 {
            width: 100%;
            color: #fff;
            display: inline-block;
            padding-bottom: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .chunk_content .chunk_item_91px {
            width: 91px;
            color: #fff;
            display: inline-block;
            margin: 0 10px;
            text-align: center;
        }

        .chunk_content .chunk_toolbar {
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            text-align: right;
        }

        .chunk_content .left_2, .chunk_content .middle_2, .chunk_content .right_2 {
            width: 33.3%;
            display: inline-block;
            position: relative;
            vertical-align: top;
            text-align: center;
        }

        .chunk_content .face1_2 {
            width: 89px;
            height: 93px;
            text-align: center;
            cursor:pointer;
        }

        .chunk_content .face1_2_title {
            color: #fff;
            font-size: 0.5rem;
            display: block;
            margin-top: 5px;
        }

        .chunk_content .face2_2 {
            width: 89px;
            height: 93px;
            text-align: center;
        }

        .chunk_content .face2_2_title {
            /*color: #ff6a00;*/
            color:#fff;
            font-size: 0.5rem;
            display: block;
            margin-top: 5px;
        }

    .middle_2 h3 {
        font-size: 1rem;
        margin: 0;
    }

    .middle_2 .similar_num {
        position: static;
        width: 61px;
        height: 61px;
        font-size: 1.5rem;
        margin-left: calc(50% - 30px);
        margin-top: 10px;
    }
</style>

<script>
    import box from "@/components/box.vue"
    import camera from '@/components/camera'
    import facenatpay from '@/components/facenatpay'
    import faceDetail from '@/components/faceDetail.vue'
import netvideo from '@/components/netvideo'
    export default {
        components: { box, faceDetail, camera, facenatpay ,netvideo},
        data: function () {
            return {
                loading: false,
                color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#2978c9' },
                    { offset: 0.5, color: '#00b2e4' },
                    { offset: 1, color: '#00e7fd' }]),
                color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#ffad42' },
                    { offset: 0.5, color: '#ffc54b' },
                    { offset: 1, color: '#ffd450' }]),
                color3: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#f7500f' },
                    { offset: 0.5, color: '#eec54b' },
                    { offset: 1, color: '#f7500f' }]),
                color4: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#f7510f' },
                    { offset: 0.5, color: '#ee154b' },
                    { offset: 1, color: '#f75020' }]),
                faceSid: null,
                faceArray: [],
                faceData: [{}, {}],
                // 摄像头对象
                playdata: {},
                // 摄像头列表
                cameraArray: [],
                cameraSelect: null,
                // 是否本地
                camIsLocal: false,
            }
        },
        mounted: function () {
            this.getDevices();
        },
        filters: {
            keepZeroNum: function (value) {
                value = Number(value);
                return value.toFixed(0);
            }
        },
        methods: {
            // 获取人脸检测信息 Type=0  （实时抓拍）
            getFaceBySid() {
                //this.loading = this.$loading();
                new Promise((res, rej) => {
                    this.$ksajax
                        .ksPostPromise("/api/face/GetFaceBySid", this.faceSid, true)
                        .then(data => {
                            //this.loading.close();
                            this.faceArray.push(data);
                            res();
                        })
                        .catch(err => {
                            rej(err);
                            this.$throw(err);
                            //this.loading.close();
                        });
                })
            },
            // 获取人脸告警信息 Type=1 (实时对比)
            getFaceWarnBySid() {
                if (this.faceSid) this.msgSid = this.faceSid;
                //this.loading = this.$loading();
                new Promise((res, rej) => {
                    this.$ksajax
                        .ksPostPromise("/api/face/GetMessageDetail", this.faceSid, true)
                        .then(data => {
                            //this.loading.close();
                            if (this.faceData.length > 1) this.faceData.shift();
                            this.faceData.push(data);
                            if (this.faceData[1])
                                switch (this.faceData[1].Sex) {
                                    case 0: this.faceData[1].SexString = "男"; break;
                                    case 1: this.faceData[1].SexString = "女"; break;
                                    default: this.faceData[1].SexString = "-";
                                }
                            res();
                        })
                        .catch(err => {
                            this.$throw(err);
                            rej(err);
                            //this.loading.close();
                        });
                })
            },
            // choose 摄像机
            choseCamera(sid) {
                let device = {};
                for (var i = 0; i < this.cameraArray.length; i++) {
                    if (this.cameraArray[i].Sid === sid) {
                        device = this.cameraArray[i];
                    }
                }
                var url;
                if (this.camIsLocal) {
                    // 局域网播放
                    url = "/api/device/GetPayNat";
                    this.$ksajax.ksPostData(url, sid, true, (success) => {

                        device.Ip = success.Ip;
                        device.Password = success.Password;
                        device.Username = success.Username;
                        device.Port = success.Port;

                        this.$refs.natpay.start(device, () => {
                        }, (data) => {
                            this.$throw(data);
                        });
                    })
                  
                } else {
                    // 广域网播放
                    //播放
                    var req = this.getPlayReqData(sid, 'sd');
                    url = "/api/device/GetPayNet";
                    this.$ksajax.ksPostData(url, req, true, (success) => {
                        this.playdata.data = success;
                        this.playdata.play = true;
                        this.playdata.device = device;
                        this.$refs.netplay.start();
                    })
                }

            },

            // 获取摄像机
            getPlayReqData(sid, hd) {
                return {
                    "EzopenType": 0,
                    "Channel": 1,
                    "Definition": hd,
                    "PlaybackSource": 0,
                    "Sid": sid
                }
            },
            // 获取设备列表
            getDevices() {
                new Promise((res, rej) => {
                    //this.loading = this.$loading();
                    let obj = {
                        Index: 0,
                        Count: 1000,
                        IsBinding: true // 是否绑定AiKit任务
                    }
                    this.$ksajax.ksPostPromise("/api/device/GetAiKitVideos", obj, true).then((data) => {
                        data.Items.forEach(x => {
                            x.DeviceName += x.AiKitStatus == 1 ? "（已开启）" : "（已关闭）";
                        });
                        this.cameraArray = data.Items;
                        //this.loading.close();
                        res();
                        throw this.$t("请求成功");
                    }).catch(err => { rej(err); })
                }).then(() => { }).catch((err) => { this.$throw(err); })
            },
            selectChange() {
                this.choseCamera(this.cameraSelect);
            },
            // 立即抓取
            grabOnce() {
                if (this.cameraSelect == null) {
                    throw this.$t("选择一台设备");
                }
                let device = {};
                for (var i = 0; i < this.cameraArray.length; i++) {
                    if (this.cameraArray[i].Sid === this.cameraSelect) {
                        device = this.cameraArray[i];
                    }
                }
                this.loading = this.$loading();
                new Promise((res, rej) => {
                    this.$ksajax
                        .ksPostPromise("/api/device/AiKitCapture", device.Serial, true)
                        .then(() => {
                            this.loading.close();
                            res();
                        })
                        .catch(err => {
                            rej(err);
                            this.$throw(err);
                            this.loading.close();
                        });
                })
            },
            // 查看行走路径
            detail() {
                if (this.faceData[1] == null) { throw this.$t("选择一条数据"); }
                this.$router.push('/admin/face/roadmap?sid=' + this.faceData[1].FaceSid + "&FaceSetSid=" + this.faceData[1].FaceSetSid);
            },
            // 查看face详情
            clickFaceDetail(sid) {
                this.msgSid = sid || '201903311509540711713450';
                if (!this.msgSid) {
                    throw this.$t("请输入Sid");
                }
                this.$refs.faceDetail.detail(this.msgSid);
            },
            // switch change 事件
            elswitch() {
                this.camIsLocal != this.camIsLocal;
            },
        },
        watch: {
            // singlar
            '$store.state.faceHubChange': function () {
                try{
                var data = this.$store.state.faceHub;
                var type = data.Type;
                this.faceSid = data.Sid;
                if (data.Sid == "abc") {
                    var r = Math.random(9);
                    if (r > 6) {
                        // 人脸对比
                        type = 0;
                        this.faceSid = "FACE12104820Hf4e7I4990Kbb90Hbb8506ee0b42";
                    } else if (r > 3) {
                        // 人脸检测
                        type = 0;
                        this.faceSid = "FACE60f0d3e7H29e6I46e6K8f62H5e89c4853be7";
                    } else {
                        // 人形检测
                        type = 0;
                        this.faceSid = "FACE60f0d3e7H29e6I46e6K8f62H5e89c4853be7";
                    }
                }
                switch (type) {
                    case 0: // 0表示人脸检测信息。
                        this.getFaceBySid();
                        break;
                    case 1: // 1表示人脸告警信息。
                        this.getFaceWarnBySid();
                        break;
                    default:
                        this.$throw("-类型");
                        break;
                }
                //var ran = Math.random();
                //var type = (data && ran > 0.4) ? 1 : 0;
                //if (type == 1) {
                //    //this.faceSid = "FACE60f0d3e7H29e6I46e6K8f62H5e89c4853be7";
                //    this.getFaceBySid();
                //} else if (type == 0) {
                //    //this.faceSid = ran > 0.2 ? "201904111657334881713207" : "201903311531205691713266";
                //    this.getFaceWarnBySid();
                //}
                }catch(err){console.error(err)}
            }
        },
    }
</script>

