<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      :sync="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
      v-on:sync="sync"
    >
    </actionbar>

    <cityBroadCastPage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      v-on:stopRun="stopRun"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></cityBroadCastPage>

    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      @shown="onModalLoaded"
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group
          v-bind:label="$t('终端ID')"
          :label-cols="2"
          v-if="modalData.TerminalId === ''"
        >
          <b-form-input type="text" v-model="TID" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('终端名称')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.TerminalName"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('音量（0-15）')" :label-cols="2">
          <b-form-input
            type="number"
            v-model="modalData.Volume"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group v-bind:label="$t('位置')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.Address"
            required
            :placeholder="$t('请输入位置')"
            v-on:keyup.enter.native="setAddress"
          >
          </b-form-input>

          <div style="margin: 10px 0 0 10px">
            <span>经度：</span
            ><b-form-input
              style="width: 40%; display: inline"
              v-model="modalData.TLongitude"
              v-on:keyup.enter.native="onModalLoaded"
            ></b-form-input>
            <span style="padding-left: 20px">纬度：</span
            ><b-form-input
              style="width: 40%; display: inline"
              v-model="modalData.TLatitude"
              v-on:keyup.enter.native="onModalLoaded"
            ></b-form-input>
          </div>

          <ksmap
            ref="map"
            v-on:mapMoveEnd="mapMoveEnd"
            width="100%"
            :showCenter="true"
            height="200px"
            v-bind:lat="parseFloat(modalData.TLatitude)"
            v-bind:lng="parseFloat(modalData.TLongitude)"
            v-bind:zoom="25"
          >
          </ksmap>
        </b-form-group>

        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button
              style="width: 100px"
              variant="secondary"
              v-on:click="hiden"
              >{{ $t("取消") }}</b-button
            >
            <b-button
              type="button"
              style="width: 100px"
              variant="primary"
              v-on:click="onSubmit"
              >{{ $t("确认") }}</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/CityBroadcast/Terminal/Delete"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>

<script>
import actionbar from "@/components/actionbar";
import deletedata from "@/components/deletedata";
import cityBroadCastPage from "./components/cityBroadCastPage.vue";
import ksmap from "@/components/map";

export default {
  components: { actionbar, cityBroadCastPage, deletedata, ksmap },
  data: function () {
    return {
      modalData: {
        TerminalId: "",
        TerminalName: "",
        ExtendData: "",
        Volume: 0,
        Address: "",
        TLatitude: 0,
        TLongitude: 0,
      },
      loading: {},
      cols: [
        { name: this.$t("终端名称"), value: "Name" },
        { name: this.$t("终端Id"), value: "Id" },
        { name: this.$t("广播音量"), value: "BCOutV" },
        { name: this.$t("本地Ip地址"), value: "LocalIp" },
        { name: this.$t("任务状态"), value: "TaskName", runState: true },
        { name: this.$t("在线状态"), value: "StateName" },
      ],
      items: [],
      total: 0,
      count: 6,
      index: 0,
      sid: "",
      title: "",
      current: null,
      TID: "",
    };
  },
  methods: {
    stopRun(item) {
      console.log("stopRun:", item);
      this.loading = this.$loading({ fullScreen: true });
      let obj = {
        TerminalId: item.Id,
        SrcId: item.Task.split("|")[1],
      };
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/Terminal/SessionControl", obj, true)
        .then((data) => {
          console.log("data:", data);
          this.select();
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    mapMoveEnd(latlng) {
      console.log("latlng:", latlng);
      this.$refs.map.getAddressByPostion(latlng.lat, latlng.lng, (data) => {
        console.log("latlngdata:", data);
        this.modalData.Address = data.regeocode.formattedAddress;
        this.modalData.TLatitude = latlng.lat;
        this.modalData.TLongitude = latlng.lng;
      });
    },
    onModalLoaded() {
      if (this.$refs.map) {
        if (!this.modalData.TLatitude || this.modalData.TLatitude === 0) {
          this.modalData.TLatitude = 39.908714;
        }
        if (!this.modalData.TLongitude || this.modalData.TLongitude === 0) {
          this.modalData.TLongitude = 116.397056;
        }
        this.$refs.map.setCenter(
          this.modalData.TLatitude,
          this.modalData.TLongitude
        );
      }
    },
    setAddress() {
      this.$refs.map.setAddress(this.modalData.Address);
    },
    select() {
      this.loading = this.$loading({ fullScreen: true });

      let obj = {
        PageIndex: this.index,
        PageCount: this.count,
      };
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/Terminal/GetList", obj, true)
        .then((data) => {
          console.log("data:", data);
          this.items = data.Rows;
          this.items.forEach((element) => {
            if (element.Task === "0") {
              element.TaskName = "空闲";
            } else {
              element.TaskName = "工作中";
            }
            if (element.State === "-1") {
              element.StateName = "离线";
            } else {
              element.StateName = "在线";
            }
          });
          this.total = parseInt(data.Total);
          if (this.total <= this.count) {
            this.index = 0;
          }
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      console.log("tid:", this.current.Id);
      this.sid = this.current.Id;
      this.$refs.del.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      let location = [];
      if (
        this.current.ExtendData &&
        this.current.ExtendData !== "" &&
        this.current.ExtendData.split("/").length === 3
      ) {
        location = this.current.ExtendData.split("/");
      } else {
        location[0] = "";
        location[1] = 0;
        location[2] = 0;
      }

      console.log("location:", location);
      this.modalData = {
        TerminalId: this.current.Id,
        TerminalName: this.current.Name,
        ExtendData: this.current.ExtendData,
        Volume: parseInt(this.current.BCOutV),
        Address: location[0],
        TLatitude: parseFloat(location[1]),
        TLongitude: parseFloat(location[2]),
      };
      this.title = this.$t("修改");
      this.$refs.modallg.show();
    },
    insert() {
      this.title = this.$t("新增");
      this.modalData = this.resetData();
      this.$refs.modallg.show();
    },
    resetData() {
      return {
        TerminalId: "",
        TerminalName: "",
        ExtendData: "",
        Volume: 0,
        Address: "",
        TLatitude: 0,
        TLongitude: 0,
      };
    },
    tableCreated() {},
    currentPage(val) {
      console.log("val：", val);
      this.index = val - 1;
      this.select();
    },
    currentRow(val) {
      this.current = val;
    },

    onSubmit(evt) {
      evt.preventDefault();
      if (
        parseInt(this.modalData.Volume) > 15 ||
        parseInt(this.modalData.Volume) < 0
      ) {
        this.$throw(this.$t("音量范围需要在0-15之间"), this);
        return;
      }
      if (this.modalData.TerminalId) {
        this.loading = this.$loading({ fullScreen: true });
        this.$ksajax
          .ksPostPromise(
            "/api/CityBroadcast/Terminal/Update",
            {
              TerminalId: this.modalData.TerminalId,
              TerminalName: this.modalData.TerminalName,
              Volume: this.modalData.Volume.toString(),
              ExtendData:
                this.modalData.Address +
                "/" +
                parseFloat(this.modalData.TLatitude) +
                "/" +
                parseFloat(this.modalData.TLongitude),
            },
            true
          )
          .then(() => {
            this.select();
            this.loading.close();
            this.hiden();
          })
          .catch((err) => {
            this.loading.close();
            this.$throw(err);
          });
      } else {
        this.loading = this.$loading({ fullScreen: true });
        this.$ksajax
          .ksPostPromise(
            "/api/CityBroadcast/Terminal/Add",
            {
              TerminalId: this.TID,
              TerminalName: this.modalData.TerminalName,
              Volume: this.modalData.Volume.toString(),
              ExtendData:
                this.modalData.Address +
                "/" +
                parseFloat(this.modalData.TLatitude) +
                "/" +
                parseFloat(this.modalData.TLongitude),
            },
            true
          )
          .then(() => {
            this.select();
            this.loading.close();
            this.hiden();
          })
          .catch((err) => {
            this.loading.close();
            this.$throw(err);
          });
      }
    },
    deleted() {
      this.select();
    },
    hiden() {
      this.$refs.modallg.hide();
    },
    sync(){
      this.$ksajax
          .ksPostPromise(
            "/api/CityBroadcast/Terminal/Sync","",true);
    },
  },
  mounted: function () {
    this.select();
  },
};
</script>

<style lang="scss" scoped>
</style>