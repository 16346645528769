<template>
    <div class="white">
         <actionbar 
        v-bind:select='true' v-bind:modify='true' :insert="true" :del="true" :sync="true"
        v-on:select='select'
        v-on:modify='modify'
        v-on:delete='del'
        v-on:insert='insert'
        v-on:sync='sync'
        >
        </actionbar>

         <tablepage ref="tableref" id="tableref"
         v-on:created='tableCreated'
         v-on:currentPage='currentPage'
         v-on:currentRow='currentRow'
         :cols='cols'
         :items='items'
         :total='total'
         :perpage='count'
        ></tablepage>
         <b-modal id="modallg" ref="modallg" size="lg" v-bind:title="title" hide-footer
         header-bg-variant="success"
         body-text-variant="dark"
         @shown="onModalLoaded"
        >
        <b-form @submit="onSubmit">
            <b-form-group :label="$t('设备类型')" label-cols="2">
                <b-form-select :options="$store.getters.getDeviceCustom('003')" v-model="form.SZ.property.type" ></b-form-select>
            </b-form-group>
            <b-form-group :label="$t('设备名')" label-cols="2">
                <b-form-input v-model="form.SZ.property.name"></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('网关地址')" label-cols="2">
                <b-form-input v-model="form.SZ.addr"></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('TCP注册包')" label-cols="2">
                <b-form-input v-model="form.SZ.property.register_pkg"></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('变比')" label-cols="2">
                <b-form-input v-model="form.SZ.property.formula"></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('轮询时间')" label-cols="2">
                <b-form-input v-model="form.SZ.property.poll_interval"></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('回路数量')" label-cols="2">
                <b-form-select v-model="form.SZ.property.section_count">
                    <option value="4">4</option>
                    <option value="8">8</option>
                </b-form-select>
            </b-form-group>
            <b-form-group :label="$t('是否加密')" label-cols="2">
                <b-form-checkbox v-model="form.SZ.property.encryption"></b-form-checkbox>
            </b-form-group>
            <b-form-group :label="$t('位置')" label-cols="2">
                
          <b-form-input type="text" v-model="form.SZ.property.address" placeholder="Address"  v-on:keyup.enter.native="setAddress" >
        </b-form-input>
                <ksmap ref="map" v-on:mapMoveEnd="mapMoveEnd" width='100%' :showCenter="true" height="200px"
                    v-bind:lat=0
                    v-bind:lng=0
                    v-bind:zoom=25>
          </ksmap>
            </b-form-group>
            
        <b-form-group>
            <hr>
            <div class="modal-foot">
                   <b-button style="width:100px" v-on:click="hidden" >Cancel</b-button>
                   <b-button type="button" style="width:100px" variant="primary" v-on:click='onSubmit'>OK</b-button>
            </div>
        </b-form-group>
        </b-form>
        </b-modal>
    </div>
</template>
<script>
import actionbar from '@/components/actionbar'
import tablepage from '@/components/tablepage'
import ksmap from '@/components/map'
export default {
    components:{actionbar,tablepage,ksmap},
    data:function(){
        return{
            loading:{},
            title:"",
            cols:[
                {name:this.$t("设备名"),value:"DeviceName",subvalue:"Custom",sub:1},
                {name:this.$t("设备类型"),value:"TypeName",subvalue:"Custom",thvalue:"DeviceType",sub:2},
                {name:this.$t("设备型号"),value:"ModelNumber",subvalue:"Custom",thvalue:"DeviceType",sub:2},
                {name:this.$t("网关地址"),value:"addrStr",subvalue:"SZ",sub:1},
                {name:this.$t("TCP注册包"),value:"register_pkg",subvalue:"SZ",sub:1},
                {name:this.$t("回路数量"),value:"section_count",subvalue:"SZ",sub:1},
                {name:this.$t("创建时间"),value:"addDate",subvalue:"SZ",sub:1},
                {name:this.$t("修改时间"),value:"modifyDate",subvalue:"SZ",sub:1},
                {name:this.$t("在线状态"),value:"online",subvalue:"SZ",sub:1,state:1},
                {name:this.$t("详情"),value:"url",link:true},
            ],
            items:[],
            index:0,
            count:15,
            total:0,
            current:null,
            sid:"",
            form:this.getResetdata(),
            method:"",
            delArray:[],
        }
    },
    mounted:function(){
        this.select();
    },
    methods:{
        sync(){this.loading=this.$loading({fullScreen:true});
             this.$ksajax.ksPostPromise("/api/device/syncszgateway","",true).then(()=>{
                this.loading.close();
                this.select();
            }).catch((err)=>{
                this.$throw(err);
                this.loading.close();
            })
        },
        select(noloading){
            if(!noloading){
                this.loading=this.$loading({fullScreen:true});
            }
            let obj={
                "Method": "read",
                "SZ": {
                    "platform": 1,
                    "type": 1,
                    "skip": 0,
                    "limit":1000000,
                    "name": ""
                    }
                }
            this.$ksajax.ksPostPromise("/api/agent/RequestSzApi",obj,true).then((success)=>{
                success.Items.forEach(x => {
                    x.url=`/admin/stand/Commondevice?sid=${x.Custom.Sid}`;
                    x.Custom.DeviceType.TypeName=this.$t(x.Custom.DeviceType.TypeName);
                });
                this.items=success.Items;
                this.total=success.TotalCount;
                this.loading.close();
            }).catch((err)=>{
                this.$throw(err);
                this.loading.close();
            })
        },
        modify(){
            if(!this.current){
                throw this.$t("选择一条数据");
            }
            this.title=this.$t("修改");
            this.method="update";
            this.form=this.getModifydata(this.current);
            this.$refs.modallg.show();
        },
        del(){
            if(!this.current){
                throw this.$t("选择一条数据");
            }
            this.$confirm(this.$t("删除确认"),this.$t("删除"),{
                confirmButtonText:this.$t("确认"),
                cancelButtonText: this.$t("退出"),
                type: 'warning'
            }).then(()=>{
                this.loading=this.$loading({fullScreen:true});
                this.delAction();
            }).catch((err)=>{
                this.$throw(err,this);
                this.loading.close();
            })
        },
        delAction(){
            //查询灯
             let obj={
                "Method": "read",
                "SZ": {
                    "platform": 1,
                    "type": 4,
                    "skip": 0,
                    "limit":1000000,
                    "name": ""
                    }
                }
                this.$ksajax.ksPostPromise("/api/agent/RequestSzApi",obj,true)
                .then((data)=>{
                    this.delArray=[];
                    return new Promise((reslove,rejects)=>{
                    for(let i=0;i<data.Items.length;i++){
                        var value=data.Items[i].SZ;
                        if(value.gatewayId==this.current.SZ.uid){
                            this.delArray.push(value);
                        }
                    }
                    if(!this.delArray||this.delArray.length==0){
                        reslove();
                    }
                    else{
                        for(let i=0;i<this.delArray.length;i++){
                        var ele=this.delArray[i];
                         //删除灯具
                            var cdata={
                                "Method": "delete",
                                "SZ": {
                                    "platform": 1,
                                    "type": 3,
                                    "uid": ele.lamp_ctrl_id,
                                    "property": {
                                        "type": ele.lampControllerType
                                    }
                                }
                            }
                            var ldata={
                                "Method": "delete",
                                "SZ": {
                                    "platform": 1,
                                    "type": 4,
                                    "uid": ele.uid
                                }
                            }
                        this.$ksajax.ksPostPromise("/api/agent/RequestSzApi",ldata,true,true).then(()=>{
                            return new Promise((res,rej)=>{
                                this.$ksajax.ksPostPromise("/api/agent/RequestSzApi",cdata,true,true).then(()=>{
                                    if(i==this.delArray.length-1){
                                        res();
                                    }
                                }).catch(err=>rej(err));
                            })
                        }).then(()=>{
                            reslove();
                        }).catch(err=>{
                            rejects(err);
                        })
                     }
                    }
                    });
                }).then(()=>{
                    return new Promise((res,rej)=>{
                        //删除网关
                        let obj={
                            "Method": "delete",
                            "SZ": {
                                "platform": 1,
                                "type": 1,
                                "uid": this.current.SZ.uid
                                }
                            }
                        this.$ksajax.ksPostPromise("/api/agent/RequestSzApi",obj,true).then(()=>{
                            res();
                        }).catch(err=>rej(err));
                    })
                })
                .then(()=>{
                    this.loading.close();
                    this.select();
                })
                .catch((err)=>{
                    this.$throw(err,this);
                    this.loading.close();
                })
        },
        insert(){
            this.form=this.getResetdata();
            this.title=this.$t("新增");
            this.method="create";
            this.$refs.modallg.show();
        },
        tableCreated(){},
        currentPage(){},
        currentRow(val){
            this.current=val;

        },
        getModifydata(data){
            return {
             "Method":this.method,
             "SZ": {
			"platform": 1,
			"type": 1,
            "uid": data.SZ.uid,
            "addr":data.SZ.addrStr,
			"property": {
				"name": data.SZ.name,
				"type": data.SZ.type,
				"addr": data.SZ.addr,
				"division_id": "",
				"organization_id": "",
				"project_id": "",
				"latitude": data.SZ.latitude?data.SZ.latitude:data.SZ.latitudeMap,
				"longitude": data.SZ.longitude?data.SZ.longitude:data.SZ.longitudeMap,
				"box_code":data.SZ.box_code,
				"section_count": data.SZ.section_count,
				"register_pkg":data.SZ.register_pkg,
				"formula": data.SZ.formula,
				"poll_interval": data.SZ.poll_interval,
				"encryption": data.SZ.encryption==1?true:false,
                "address":""
			}
		},
		"Custom": {
			"DTypeSid": data.Custom.DeviceType.Sid,
			"LStandSid": ""
          }
         }
        },
        getResetdata(){
            return {
             "Method":this.method,
             "SZ": {
			"platform": 1,
			"type": 1,
			"addr": "",
			"property": {
				"type": 1,
				"division_id": "",
				"organization_id": "",
				"project_id": "",
				"name": "",
				"longitude": "116.397056",
				"latitude": "39.908714",
				"box_code": "",
				"section_count": 4,
				"register_pkg": "",
				"formula": 1,
				"poll_interval": 15,
                "encryption": false,
                "address":""
			}
		},
		"Custom": {
			"DTypeSid": "",
			"LStandSid": ""
          }
         }
        },
        mapMoveEnd(latlng){
            this.form.SZ.property.latitude=latlng.lat+"";
            this.form.SZ.property.longitude=latlng.lng+"";
            this.$refs.map.getAddressByPostion(latlng.lat,latlng.lng,(address)=>{
                let add=address.regeocode.formattedAddress;
                this.form.SZ.property.address=add;
            });
        },
        setAddress(){
            this.$refs.map.setAddress(this.form.SZ.property.address);
        },
        onModalLoaded(){
            this.$refs.map.setCenter(this.form.SZ.property.latitude,this.form.SZ.property.longitude);
        },hidden(){
            this.$refs.modallg.hide();
        },
        onSubmit(evn){
            evn.preventDefault();
            this.form.SZ.property.division_id=this.$store.state.sz.DivisionId;
            this.form.SZ.property.organization_id=this.$store.state.sz.OrganizationId;
            this.form.SZ.property.project_id=this.$store.state.sz.ProjectId;
            this.form.SZ.property.box_code=this.form.SZ.addr;
            this.form.Method=this.method;
            this.form.SZ.property.encryption=this.form.SZ.property.encryption?1:0;
            var array=this.$store.getters.getDeviceCustom('003');
            for(var i=0;i<array.length;i++){
                if(this.form.SZ.property.type==array[i].value){
                    this.form.Custom.DTypeSid=array[i].sid;
                }
            }
            if(this.method=="create"){
                this.form.SZ.addr=`0x${this.form.SZ.addr.toLowerCase()}`;
            }
            if(this.method=="update"){
                this.form.SZ.property.addr=`0x${this.form.SZ.addr.toLowerCase()}`;
                delete this.form.SZ.addr;
            }
            this.loading=this.$loading({fullScreen:true});
            this.$ksajax.ksPostPromise("/api/agent/RequestSzApi",this.form,true).then(()=>{
                this.hidden();
                this.select(true);
            }).catch((err)=>{
                console.error('tag', err)
                this.$throw(err,this);
                this.hidden();
                this.loading.close();
            });
        }
    },
}
</script>

