<template>
    <div class="face-camera">
        <div class="netplay" ref="netplay">
            <div v-if='installed_local && isLocal' class="netplay-play" :style="style">
                <div class="netplay-obj" v-if='installed_local && isLocal' ref="obj" id="divPlugin"> </div>
            </div>
            <div v-if='installed_net && !isLocal' class="netplay-play" :style="style">
                <object class="netplay-obj" v-if='installed_net && !isLocal' ref="obj" classid="clsid:54FC7795-1014-4BF6-8BA3-500C61EC1A05" id="EZUIKit" width="100%" height="100%" name="EZUIKit"></object>
            </div>
            <div v-if='!installed_local' style="color:red">
                <a :href="baseUrl+'/share/WebComponentsKit.zip'">请安装好插件后用IE浏览器打开该页面</a>
            </div>
        </div>
    </div>
</template>

<style scoped>
    #div-action-bar > i, #div-action-arrow > i {
        margin-left: 10px;
        cursor: pointer;
    }

    #div-action-bar > span {
        margin-left: 10px;
        cursor: pointer;
        display: inline-block;
        height: 30px;
        width: 30px;
        border: 1px solid blue;
        font-size: 20px;
        text-align: center;
    }

        #div-action-bar > span.active {
            border: 1px solid white;
        }
    .face-camera{
        padding:0 20px 12px 20px;
    }

    .face-camera .netplay-obj {
        position: absolute;
        background-color: white;
        width: 100%;
        height: 100%;
    }

    .face-camera .netplay {
        width: 100%;
        height: 100%;
        background-color: black;
        /* position:relative; */
        color: white
    }

    .face-camera .netplay-play {
        position: absolute;
    }

    .face-camera #frame-title {
        position: absolute;
        z-index: 1;
        width: 120px;
        height: 25px;
        top: 10px;
        right: 10px;
        filter: 'progid:DXImageTransform.Microsoft.Alpha(style=0,opacity=0)';
    }

    .face-camera #frame-action {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 50px;
        bottom: 0px;
        filter: 'progid:DXImageTransform.Microsoft.Alpha(style=0,opacity=0)';
    }

    .face-camera #div-title {
        position: absolute;
        z-index: 2;
        height: 25px;
        top: 10px;
        right: 10px;
    }

    .face-camera #div-action {
        position: absolute;
        z-index: 2;
        width: 100%; 
        height: 50px;
        bottom: 0px;
    }
</style>

<script>
import { BaseUrl } from '@/assets/utils/config'
    export default {
        props: ["count", "isLocal","data"],
        data: function () {
            return {
                baseUrl:BaseUrl,
                installed_local: true,
                installed_net: true,
                style: {
                    height: "504px",
                    width: "1215px"
                },
                actionStyle: {
                    left: '0px'
                },
                play_loc: false,
                play_net: false,
                talk: false,
                sound: true,
                currentIndex: 0,
                devices: [],
                wins: 1,
                winarray: [],
            }
        },
        // 创建摄像机对象
        created: function (w,h) {
            //// 创建网络摄像机========
            try {
                new window.ActiveXObject("EZOPENUIACTIVEXK.EzOpenUIActiveXKCtrl.1");
                this.installed_local = true;

            } catch (e) {
                this.installed_local = false;
            }
            window.PluginEventHandler = this.callBack;

            //// 创建局域网摄像机
            //var g_iWndIndex = 0; //可以不用设置这个变量，有窗口参数的接口中，不用传值，开发包会默认使用当前选择窗口
            // 检查插件是否已经安装过
            var iRet = window.WebVideoCtrl.I_CheckPluginInstall();
            if (-1 == iRet) {
                this.installed_net = false;
                return;
            }

            // 初始化插件参数及插入插件
            window.WebVideoCtrl.I_InitPlugin(w, h, {
                bWndFull: true,     //是否支持单窗口双击全屏，默认支持 true:支持 false:不支持
                iPackageType: 2,    //2:PS 11:MP4
                iWndowType: 2,
                bNoPlugin: true,
                cbSelWnd: (xmlDoc) => {
                    this.currentIndex = parseInt(xmlDoc.getElementsByTagName("SelectWnd")[0].childNodes[0].nodeValue);
                    var exsit = false;
                    for (var i = 0; i < this.winarray.length; i++) {
                        if (this.winarray[i].index == this.currentIndex) {
                            this.play = this.winarray[i].play;
                            this.sound = this.winarray[i].sound;
                            exsit = true;
                        }
                    }
                    if (!exsit) {
                        this.play = false;
                        this.sound = false;
                    }
                },
                cbRemoteConfig: function () {
                    // showCBInfo("关闭远程配置库！");
                },
                cbInitPluginComplete: function () {
                    window.WebVideoCtrl.I_InsertOBJECTPlugin("divPlugin");
                    // 检查插件是否最新
                    if (-1 == window.WebVideoCtrl.I_CheckPluginVersion()) {
                        alert("检测到新的插件版本，双击开发包目录里的WebComponentsKit.exe升级！");
                        return;
                    }
                }
            });
            window.WebVideoCtrl.I_ChangeWndNum(this.count)


        },
        watch: {
            "data": function () {
                if (this.data.play_net) {
                    this.start_net();
                }
                else {
                    this.stop_net();
                }
            }
        },
        mounted: function () {
        },
        methods: {
            start_net(metu) {
                this.loading = this.$loading();
                var playOcx = document.getElementById("EZUIKit");
                playOcx.InitWithAppKey(this.data.data.AppKey);
                var res = playOcx.SetAccessToken(this.data.data.AccessToken);
                res = playOcx.StartPlay(this.data.data.Url);
                if (res !== 0) {
                    this.$throw("播放视频失败", this);
                }
                else {
                    this.play_net = true;
                    if (metu) {
                        this.sound = false;
                    } else {
                        this.sound = true;
                    }
                }
            },
            stop_net() {
                var playOcx = document.getElementById("EZUIKit");//得到控件引用
                var res = playOcx.StopPlay();
                if (res !== 0) {
                    this.$throw("停止播放失败", this);
                }
                else {
                    this.play_net = false;
                }
            },
            // 出错返回码提示
            callBack(lEventType, strErrorCode, lInterErrorCode) {
                //handle message msgtype
                var EZUI_MSGID_PLAY_EXCEPTION = 0;			//播放异常
                var EZUI_MSGID_PLAY_RECONNECT = 1;			//播放重连
                var EZUI_MSGID_PLAY_RECONNECT_EXCEPTION = 2;			//播放重连异常
                var EZUI_MSGID_PLAY_START = 3;			//播放开始
                var EZUI_MSGID_PLAY_STOP = 4;			//播放停止
                var EZUI_MSGID_PLAY_ARCHIVE_END = 5;			//回放结束
                var EZUI_MSGID_VOICETALK_START = 16;			//语音对讲开始
                var EZUI_MSGID_VOICETALK_STOP = 17;			//语音对讲停止
                var EZUI_MSGID_VOICETALK_EXCEPTION = 18;			//语音对讲异常 
                var EZUI_MSGID_RECORD_FILE = 20;			//查询的录像文件
                var EZUI_MSGID_PTZCTRL_SUCCESS = 46;			//云台控制命令发送成功
                var EZUI_MSGID_PTZCTRL_FAILED = 47;			//云台控制失败

                var EZUI_ERROR_ACCESSTOKEN_EXPIRE = "UE001"; 	///< accesstoken异常或失效，需要重新获取accesstoken，并传入到sdk
                var EZUI_ERROR_APPKEY_TOKEN_NOT_MATCH = "UE002";     ///< appkey和AccessToken不匹配,建议更换appkey或者AccessToken
                var EZUI_ERROR_CHANNEL_NOT_EXIST = "UE004";     ///< 通道不存在，设备参数错误，建议重新获取播放地址
                var EZUI_ERROR_DEVICE_NOT_EXIST = "UE005";     ///< 设备不存在，设备参数错误，建议重新获取播放地址
                var EZUI_ERROR_PARAM_INVALID = "UE006";     ///< 参数错误，建议重新获取播放地址
                var EZUI_ERROR_EZOPEN_URL_INVALID = "UE007";     ///< 播放地址错误,建议重新获取播放地址
                var EZUI_ERROR_NO_RESOURCE = "UE101";	    ///< 设备连接数过大，停止其他连接后再试试
                var EZUI_ERROR_DEVICE_OFFLINE = "UE102"; 	///< 设备不在线，确认设备上线之后重试
                var EZUI_ERROR_CONNECT_DEVICE_TIMEOUT = "UE103"; 	///< 播放失败，请求连接设备超时，检测设备网路连接是否正常.
                var EZUI_ERROR_INNER_VERIFYCODE = "UE104"; 	///< 视频验证码错误，建议查看设备上标记的验证码
                var EZUI_ERROR_PLAY_FAIL = "UE105"; 	///< 视频播放失败
                var EZUI_ERROR_TERMINAL_BINDING = "UE106"; 	///< 当前账号开启了终端绑定，只允许指定设备登录操作
                var EZUI_ERROR_DEVICE_INFO_INVALID = "UE107"; 	///< 设备信息异常为空，建议重新获取播放地址
                var EZUI_ERROR_VIDEO_RECORD_NOTEXIST = "UE108"; 	///< 未查找到录像文件
                var EZUI_ERROR_VTDU_NO_RESOURCE = "UE109"; 	///< 取流并发路数限制,请升级为企业版.
                var EZUI_ERROR_UNSUPPORTED = "UE110"; 	///< 设备不支持的清晰度类型, 请根据设备预览能力级选择.
                var info = null;
                switch (lEventType) {
                    case EZUI_MSGID_VOICETALK_EXCEPTION: {

                        info = "语音对讲异常!";
                    }
                        break;
                    case EZUI_MSGID_PLAY_ARCHIVE_END: {

                        info = "回放结束!";
                    }
                        break;
                    case EZUI_MSGID_PLAY_RECONNECT_EXCEPTION: {

                        info = "播放重连失败!";
                    }
                        break;
                    case EZUI_MSGID_PLAY_RECONNECT: {

                        info = "播放重连!";
                    }
                        break;
                    case EZUI_MSGID_PLAY_START:		//播放开始
                        {
                            info = "播放成功!";
                        }
                        break;
                    case EZUI_MSGID_PLAY_EXCEPTION:	//播放异常
                        {
                            var errinfo = "";
                            if (strErrorCode == EZUI_ERROR_ACCESSTOKEN_EXPIRE) {
                                errinfo = "accesstoken异常或失效，需要重新获取accesstoken，并传入到sdk";
                            }
                            else if (strErrorCode == EZUI_ERROR_APPKEY_TOKEN_NOT_MATCH) {
                                errinfo = "ppkey和AccessToken不匹配,建议更换appkey或者AccessToken";
                            }
                            else if (strErrorCode == EZUI_ERROR_CHANNEL_NOT_EXIST) {
                                errinfo = "通道不存在，设备参数错误，建议重新获取播放地址";
                            }
                            else if (strErrorCode == EZUI_ERROR_DEVICE_NOT_EXIST) {
                                errinfo = "设备不存在，设备参数错误，建议重新获取播放地址";
                            }
                            else if (strErrorCode == EZUI_ERROR_PARAM_INVALID) {
                                errinfo = "参数错误，建议重新获取播放地址";
                            }
                            else if (strErrorCode == EZUI_ERROR_EZOPEN_URL_INVALID) {
                                errinfo = "播放地址错误,建议重新获取播放地址";
                            }
                            else if (strErrorCode == EZUI_ERROR_NO_RESOURCE) {
                                errinfo = "设备连接数过大，停止其他连接后再试试";
                            }
                            else if (strErrorCode == EZUI_ERROR_DEVICE_OFFLINE) {
                                errinfo = "设备不在线，确认设备上线之后重试";
                            }
                            else if (strErrorCode == EZUI_ERROR_CONNECT_DEVICE_TIMEOUT) {
                                errinfo = "播放失败，请求连接设备超时，检测设备网路连接是否正常.";
                            }
                            else if (strErrorCode == EZUI_ERROR_INNER_VERIFYCODE) {
                                errinfo = "视频验证码错误，建议查看设备上标记的验证码";
                            }
                            else if (strErrorCode == EZUI_ERROR_PLAY_FAIL) {
                                errinfo = "视频播放失败";
                            }
                            else if (strErrorCode == EZUI_ERROR_TERMINAL_BINDING) {
                                errinfo = "当前账号开启了终端绑定，只允许指定设备登录操作";
                            }
                            else if (strErrorCode == EZUI_ERROR_DEVICE_INFO_INVALID) {
                                errinfo = "设备信息异常为空，建议重新获取播放地址";
                            }
                            else if (strErrorCode == EZUI_ERROR_VIDEO_RECORD_NOTEXIST) {
                                errinfo = "未查找到录像文件";
                            }
                            else if (strErrorCode == EZUI_ERROR_VTDU_NO_RESOURCE) {
                                errinfo = "取流并发路数限制,请升级为企业版.";
                            }
                            else if (strErrorCode == EZUI_ERROR_UNSUPPORTED) {
                                errinfo = "设备不支持的清晰度类型, 请根据设备预览能力级选择";
                            }

                            info = "播放失败," + errinfo + ".错误码:" + strErrorCode + ", 内部错误码:" + lInterErrorCode;
                        }
                        break;
                    case EZUI_MSGID_PLAY_STOP:			//播放停止
                        {
                            info = "播放停止"
                        }
                        break;
                    case EZUI_MSGID_RECORD_FILE:		//录像搜索成功
                        {
                            info = "录像搜索成功"
                        }
                        break;
                    case EZUI_MSGID_VOICETALK_START:		//对讲开启
                        {
                            info = "对讲开启成功";
                        }
                        break;
                    case EZUI_MSGID_VOICETALK_STOP:		//对讲开启
                        {
                            info = "对讲停止成功";
                        }
                        break;
                    case EZUI_MSGID_PTZCTRL_SUCCESS:		//云台控制成功
                        {
                            info = "云台控制信令发送成功";
                        }
                        break;
                    case EZUI_MSGID_PTZCTRL_FAILED:		//云台控制失败
                        {
                            info = "云台控制失败";
                        }
                        break;
                    default:
                }
                if (info) {
                    this.$throw(info);
                }
                if (this.loading.close) {
                    this.loading.close();
                }
            },

            init(w, h) {
                //var g_iWndIndex = 0; //可以不用设置这个变量，有窗口参数的接口中，不用传值，开发包会默认使用当前选择窗口
                // 检查插件是否已经安装过
                var iRet = window.WebVideoCtrl.I_CheckPluginInstall();
                if (-1 == iRet) {
                    this.installed = false;
                    return;
                }

                // 初始化插件参数及插入插件
                window.WebVideoCtrl.I_InitPlugin(w, h, {
                    bWndFull: true,     //是否支持单窗口双击全屏，默认支持 true:支持 false:不支持
                    iPackageType: 2,    //2:PS 11:MP4
                    iWndowType: 2,
                    bNoPlugin: true,
                    cbSelWnd: (xmlDoc) => {
                        this.currentIndex = parseInt(xmlDoc.getElementsByTagName("SelectWnd")[0].childNodes[0].nodeValue);
                        var exsit = false;
                        for (var i = 0; i < this.winarray.length; i++) {
                            if (this.winarray[i].index == this.currentIndex) {
                                this.play = this.winarray[i].play;
                                this.sound = this.winarray[i].sound;
                                exsit = true;
                            }
                        }
                        if (!exsit) {
                            this.play = false;
                            this.sound = false;
                        }
                    },
                    cbRemoteConfig: function () {
                        // showCBInfo("关闭远程配置库！");
                    },
                    cbInitPluginComplete: function () {
                        window.WebVideoCtrl.I_InsertOBJECTPlugin("divPlugin");
                        // 检查插件是否最新
                        if (-1 == window.WebVideoCtrl.I_CheckPluginVersion()) {
                            alert("检测到新的插件版本，双击开发包目录里的WebComponentsKit.exe升级！");
                            return;
                        }
                    }
                });
                window.WebVideoCtrl.I_ChangeWndNum(this.count)
            },
            start(device, scuess, error) {
                //登录
                this.login(device, () => {
                    var exsit = false;
                    var d = {};
                    for (var i = 0; i < this.devices.length; i++) {
                        if (this.devices[i].Sid === device.Sid) {
                            exsit = true;
                            this.devices[i].index = this.currentIndex
                            d = this.devices[i];
                            break;
                        }
                    }
                    if (!exsit) {
                        //如果不存在，则获取端口及通道
                        var dev = {};
                        dev.Sid = device.Sid;
                        dev.device = device;
                        var oport = this.getPort(device.Ip, device.Port);
                        dev.DevicePort = oport.iDevicePort;
                        dev.RSTPort = oport.iRtspPort;
                        //获取通道
                        this.getMC(device.Ip, device.Port,
                            function (id, name) {
                                //获取模拟通道成功
                                dev.CID = id;
                                dev.CNAME = name;
                            }, function () {
                                this.getDC(device.Ip, device.Port,
                                    function (id, name) {
                                        //获取数字通道成功
                                        dev.CID = id;
                                        dev.CNAME = name;
                                    }, function () {
                                        throw "获取通道失败";
                                    })
                            });
                        d = dev;
                    }
                    //播放
                    if (!exsit) {
                        d.play = true;
                        this.devices.push(d);
                    }
                    this.startPlay(d, scuess, error);
                    scuess();
                }, (msg) => {
                    error(msg);
                })
            },
            login(device, suc, err) {
                var iRet = window.WebVideoCtrl.I_Login(device.Ip, 1, device.Port, device.Username, device.Password, {
                    success: function (xmlDoc) {
                        suc(xmlDoc);
                    },
                    error: function (status) {
                        err('登录失败' + status);
                    }
                });

                if (-1 == iRet) {
                    suc();
                }
            },
            getPort(ip, port) {
                var szDeviceIdentify = `${ip}_${port}`;
                var oPort = window.WebVideoCtrl.I_GetDevicePort(szDeviceIdentify);
                if (oPort != null) {
                    return oPort;
                } else {
                    throw "获取端口失败";
                }
            },
            getMC(ip, port, suc, err) {
                var szDeviceIdentify = `${ip}_${port}`;
                window.WebVideoCtrl.I_GetAnalogChannelInfo(szDeviceIdentify, {
                    async: false,
                    success: function (xmlDoc) {
                        var id = xmlDoc.getElementsByTagName("VideoInputChannel")[0].getElementsByTagName("id")[0].childNodes[0].nodeValue;
                        var name = xmlDoc.getElementsByTagName("VideoInputChannel")[0].getElementsByTagName("name")[0].childNodes[0].nodeValue;
                        suc(id, name);
                    },
                    error: function () {
                        err();
                    }
                })
            },
            getDC(ip, port, suc, err) {
                var szDeviceIdentify = `${ip}_${port}`;
                window.WebVideoCtrl.I_GetDigitalChannelInfo(szDeviceIdentify, {
                    async: false,
                    success: function (xmlDoc) {
                        var id = xmlDoc.getElementsByTagName("InputProxyChannelStatus")[0].getElementsByTagName("id")[0].childNodes[0].nodeValue;
                        var name = xmlDoc.getElementsByTagName("InputProxyChannelStatus")[0].getElementsByTagName("name")[0].childNodes[0].nodeValue;
                        suc(id, name);
                    },
                    error: function () {
                        err();
                    }
                })
            },
            startRealPlay: function (ip, port, rtspport, cid, suc, err) {
                var szDeviceIdentify = `${ip}_${port}`;
                var szInfo = "";
                window.WebVideoCtrl.I_StartRealPlay(szDeviceIdentify, {
                    iRtspPort: rtspport,
                    iStreamType: 1,
                    iChannelID: cid,
                    bZeroChannel: false,
                    success: () => {
                        szInfo = "开始预览成功！";
                        if (suc) {
                            suc(this.currentIndex);
                        }
                    },
                    error: (status) => {
                        if (403 === status) {
                            szInfo = "设备不支持Websocket取流！";
                        } else {
                            szInfo = "开始预览失败！" + status;
                        }
                        if (err) {
                            err(szInfo);
                        }
                        throw szInfo;
                    }
                });
            },
            startPlay(device, suc, err) {
                var ip, port, rtspport, cid;
                if (!device) {
                    for (var i = 0; i < this.winarray.length; i++) {
                        if (this.winarray[i].index == this.currentIndex) {
                            device = this.winarray[i].device;
                        }
                    }
                }
                if (!device) {
                    this.$throw("请点击左侧设备");
                    throw "请点击左侧设备"
                }
                ip = device.device.Ip;
                port = device.device.Port;
                rtspport = device.RSTPort;
                cid = device.CID;
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);
                if (oWndInfo == null) {
                    this.startRealPlay(ip, port, rtspport, cid, (index) => {
                        this.play = true;
                        var exsit = false;
                        for (var i = 0; i < this.winarray.length; i++) {
                            if (this.winarray[i].index == index) {
                                exsit = true;
                                this.winarray[i].device = device;
                                this.winarray[i].play = true;
                                break;
                            }
                        }
                        if (!exsit) {
                            var obj = {};
                            obj.play = true;
                            obj.index = index;
                            obj.device = device;
                            obj.sound = false;
                            this.winarray.push(obj);
                        }
                        if (suc) {
                            suc(index);
                        }
                    }, err);
                }
                else {
                    //先停止
                    window.WebVideoCtrl.I_Stop({
                        success: () => {
                            this.startRealPlay(ip, port, rtspport, cid, (index) => {
                                var exsit = false;
                                for (var i = 0; i < this.winarray.length; i++) {
                                    if (this.winarray[i] == index) {
                                        exsit = true;
                                        this.winarray[i].device = device;
                                        this.winarray[i].play = true;
                                        break;
                                    }
                                }
                                if (!exsit) {
                                    var obj = {};
                                    obj.play = true;
                                    obj.index = index;
                                    obj.device = device;
                                    this.winarray.push(obj);
                                }
                                if (suc) {
                                    suc(index);
                                }
                            }, err);
                        }
                    });
                }
            },
            stopRealPlay() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);
                var szInfo = "";

                if (oWndInfo != null) {
                    window.WebVideoCtrl.I_Stop({
                        success: () => {
                            szInfo = "停止预览成功！";
                            for (var i = 0; i < this.winarray.length; i++) {
                                if (this.winarray[i].index == this.currentIndex) {
                                    this.winarray[i].play = false;
                                    this.play = false;
                                }
                            }
                        },
                        error: function () {
                            szInfo = "停止预览失败！";
                        }
                    });
                }szInfo;
            },
            changeWindow(c) {
                this.wins = c;
                window.WebVideoCtrl.I_ChangeWndNum(c);
            },
            startMetu() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);
                if (oWndInfo != null) {
                    this.sound = false;
                    for (var i = 0; i < this.winarray.length; i++) {
                        if (this.winarray[i].index == this.currentIndex) {
                            this.winarray[i].sound = false;
                            this.sound = false;
                        }
                    }
                    window.WebVideoCtrl.I_CloseSound();
                }
            },
            stopMetu() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);
                if (oWndInfo != null) {
                    var allWndInfo = window.WebVideoCtrl.I_GetWindowStatus();
                    // 循环遍历所有窗口，如果有窗口打开了声音，先关闭
                    for (var i = 0, iLen = allWndInfo.length; i < iLen; i++) {
                        oWndInfo = allWndInfo[i];
                        if (oWndInfo.bSound) {
                            window.WebVideoCtrl.I_CloseSound(oWndInfo.iIndex);
                        }
                    }
                    window.WebVideoCtrl.I_OpenSound();
                    for (var j = 0; i < this.winarray.length; j++) {
                        if (this.winarray[j].index == this.currentIndex) {
                            this.winarray[j].sound = true;
                            this.sound = true;
                        }
                    }
                }
            },
            mouseDownPTZControl(iPTZIndex) {
                var g_bPTZAuto = false;
                var iPTZSpeed = 4;
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);
                if (oWndInfo != null) {
                    if (9 == iPTZIndex && g_bPTZAuto) {
                        iPTZSpeed = 0;// 自动开启后，速度置为0可以关闭自动
                    } else {
                        g_bPTZAuto = false;// 点击其他方向，自动肯定会被关闭
                    }

                    window.WebVideoCtrl.I_PTZControl(iPTZIndex, false, {
                        iPTZSpeed: iPTZSpeed,
                        success: function () {
                            if (9 == iPTZIndex && g_bPTZAuto) {
                                //showOPInfo(oWndInfo.szDeviceIdentify + " 停止云台成功！");
                            } else {
                                //showOPInfo(oWndInfo.szDeviceIdentify + " 开启云台成功！");
                            }
                            if (9 == iPTZIndex) {
                                g_bPTZAuto = !g_bPTZAuto;
                            }
                        },
                        error: function () {
                            //showOPInfo(oWndInfo.szDeviceIdentify + " 开启云台失败！", status, xmlDoc);
                        }
                    });
                }
            },
            mouseUpPTZControl() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);
                if (oWndInfo != null) {
                    window.WebVideoCtrl.I_PTZControl(1, true, {
                        success: function () {
                            //showOPInfo(oWndInfo.szDeviceIdentify + " 停止云台成功！");
                        },
                        error: function () {
                            //showOPInfo(oWndInfo.szDeviceIdentify + " 停止云台失败！", status, xmlDoc);
                        }
                    });
                }
            },
            PTZZoomIn() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);

                if (oWndInfo != null) {
                    window.WebVideoCtrl.I_PTZControl(10, false, {
                        iWndIndex: this.currentIndex,
                        success: function () {
                        },
                        error: function () {
                        }
                    });
                }
            },
            PTZZoomout() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);

                if (oWndInfo != null) {
                    window.WebVideoCtrl.I_PTZControl(11, false, {
                        iWndIndex: this.currentIndex,
                        success: function () {
                        },
                        error: function () {
                        }
                    });
                }
            },
            PTZZoomStop() {
                var oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(this.currentIndex);

                if (oWndInfo != null) {
                    window.WebVideoCtrl.I_PTZControl(11, true, {
                        iWndIndex: this.currentIndex,
                        success: function () {
                        },
                        error: function () {
                        }
                    });
                }
            },
            clickFullScreen() {
                window.WebVideoCtrl.I_FullScreen(true);
            }
        }
    }
</script>
