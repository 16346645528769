<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :insert="true"
      :del="true"
      :password="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
      v-on:password="password"
    ></actionbar>
    <el-table
      stripe=""
      :data="items"
      style="width: 100%"
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
      <el-table-column
        :label="$t('部门')"
        prop="PartmentName"
      ></el-table-column>
      <el-table-column :label="$t('手机')" prop="PhoneNumber"></el-table-column>
      <el-table-column :label="$t('邮箱')" prop="Email"></el-table-column>
      <el-table-column :label="$t('微信')" prop="Wechat">
        <template slot-scope="scope">{{
          scope.row.Wechat ? scope.row.Wechat.NickName : ""
        }}</template>
      </el-table-column>
      <el-table-column :label="$t('状态')" prop="IsEnabled">
        <template slot-scope="scope">{{
          scope.row.IsEnabled == 1 ? $t("正常") : $t("禁用")
        }}</template>
      </el-table-column>
      <el-table-column :label="$t('接收短信')">
        <template slot-scope="scope">{{
          scope.row.EnableSms == 1 ? $t("接收") : $t("拒收")
        }}</template>
      </el-table-column>
      <el-table-column :label="$t('接收邮件')" prop="EnableEmail">
        <template slot-scope="scope">{{
          scope.row.EnableEmail == 1 ? $t("接收") : $t("拒收")
        }}</template>
      </el-table-column>
      <el-table-column :label="$t('接收微信')" prop="EnableWechat">
        <template slot-scope="scope">{{
          scope.row.EnableWechat == 1 ? $t("接收") : $t("拒收")
        }}</template>
      </el-table-column>
      <el-table-column :label="$t('接收电话')" prop="EnableCall">
        <template slot-scope="scope">{{
          scope.row.EnableCall == 1 ? $t("接收") : $t("拒收")
        }}</template>
      </el-table-column>
    </el-table>
    <b-modal
      id="passwordmd"
      ref="passwordmd"
      size="lg"
      title="修改密码"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmitPassword">
        <b-form-group v-bind:label="$t('原密码')" :label-cols="2">
          <b-form-input
            type="password"
            v-model="modifyPasword.OldPassword"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('新密码')" :label-cols="2">
          <b-form-input
            type="password"
            v-model="modifyPasword.NewPassword"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('确认密码')" :label-cols="2">
          <b-form-input
            type="password"
            v-model="modifyPasword.NewPassword2"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width: 100px" v-on:click="hiden('passwordmd')"
              >Cancel</b-button
            >
            <b-button type="submit" style="width: 100px" variant="primary"
              >OK</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('名称')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.Name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('部门')" :label-cols="2">
          <b-form-radio-group
            :options="partments"
            v-model="modalData.PartmentSid"
            value-field="Sid"
            text-field="Name"
          ></b-form-radio-group>
        </b-form-group>
        <b-form-group v-bind:label="$t('手机')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.PhoneNumber"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('邮箱')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.Email"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('微信')" :label-cols="2">
          <el-input
            placeholder="请输入内容"
            v-model="modalData.Wechat.NickName"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchWechat"
            ></el-button>
          </el-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('状态')" :label-cols="2">
          <b-form-radio-group v-model="modalData.IsEnabled">
            <b-form-radio :value="1">启用</b-form-radio>
            <b-form-radio :value="0">禁用</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group v-bind:label="$t('接收短信')" :label-cols="2">
          <b-form-radio-group v-model="modalData.EnableSms">
            <b-form-radio :value="1">接收</b-form-radio>
            <b-form-radio :value="0">拒收</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group v-bind:label="$t('接收邮件')" :label-cols="2">
          <b-form-radio-group v-model="modalData.EnableEmail">
            <b-form-radio :value="1">接收</b-form-radio>
            <b-form-radio :value="0">拒收</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group v-bind:label="$t('接收微信')" :label-cols="2">
          <b-form-radio-group v-model="modalData.EnableWechat">
            <b-form-radio :value="1">接收</b-form-radio>
            <b-form-radio :value="0">拒收</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group v-bind:label="$t('接收电话')" :label-cols="2">
          <b-form-radio-group v-model="modalData.EnableCall">
            <b-form-radio :value="1">接收</b-form-radio>
            <b-form-radio :value="0">拒收</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width: 100px" v-on:click="hiden(modallg)"
              >Cancel</b-button
            >
            <b-button type="submit" style="width: 100px" variant="primary"
              >OK</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/alarm/DelReceiver"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import deletedata from "@/components/deletedata";
export default {
  components: { actionbar, tablepage, deletedata },
  data: function () {
    return {
      index: 0,
      count: 1500,
      current: null,
      loading: {},
      items: [],
      total: 0,
      modalData: { Wechat: { NickName: "" } },
      title: "",
      sid: "",
      modifyPasword: {
        Username: "",
        OldPassword: "",
        NewPassword: "",
        NewPassword2: "",
      },
      partments: [],
    };
  },
  mounted: function () {
    this.select();
  },
  methods: {
    searchWechat() {
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise(
          "/api/Wechat/GetUserInfo",
          this.modalData.Wechat.NickName,
          true
        )
        .then((data) => {
          if (!data) {
            this.$throw(
              "未找到数据，该微信账户不存在或未关注公众号，请重新输入"
            );
          } else {
            this.$throw("微信绑定更新成功");
            this.modalData.WechatSid = data.Sid;
          }
          this.loading.close();
        })
        .catch((err) => {
          this.$throw(err);
          this.loading.close();
        });
    },
    deleted() {
      this.select();
    },
    select() {
      this.loading = this.$loading();
      let obj = {
        Index: this.index,
        Count: this.Count,
      };
      this.$ksajax
        .ksPostPromise("/api/alarm/GetReceivers", obj, true)
        .then((r) => {
          r.Items.forEach((element) => {
            if (!element.Wechat) {
              element.Wechat = { NickName: "" };
            }
          });
          this.items = r.Items;
          this.loading.close();
        })
        .catch((err) => {
          this.loading.close();
          this.$throw(err);
        });
    },
    modify() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/alarm/GetPartments", "", true)
        .then((r) => {
          this.loading.close();
          this.partments = r;
          this.modalData = {
            Sid: this.current.Sid,
            Name: this.current.Name,
            PhoneNumber: this.current.PhoneNumber,
            Email: this.current.Email,
            Wechat: this.current.Wechat,
            IsEnabled: this.current.IsEnabled,
            EnableSms: this.current.EnableSms,
            EnableEmail: this.current.EnableEmail,
            EnableWechat: this.current.EnableWechat,
            EnableCall: this.current.EnableCall,
            PartmentSid: this.current.PartmentSid,
            WechatSid: this.current.WechatSid,
          };
          this.title = this.$t("修改");
          this.$refs.modallg.show();
        })
        .catch((err) => {
          this.loading.close();
          this.$throw(err);
        });
    },
    del() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    insert() {
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/alarm/GetPartments", "", true)
        .then((r) => {
          this.loading.close();
          this.partments = r;
          this.modalData = {
            Sid: "",
            Name: "",
            PhoneNumber: "",
            Email: "",
            Wechat: { NickName: "" },
            IsEnabled: 1,
            EnableSms: 1,
            EnableEmail: 1,
            EnableWechat: 0,
            PartmentSid: "",
            WechatSid: "",
          };

          this.title = this.$t("新增");
          this.$refs.modallg.show();
        })
        .catch((err) => {
          this.loading.close();
          this.$throw(err);
        });
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/alarm/AddReceiver", this.modalData, true)
        .then(() => {
          this.loading.close();
          this.hiden();
          this.select();
        })
        .catch((err) => {
          this.$throw(err);
          this.hiden();
        });
    },
    onSubmitPassword(evt) {
      evt.preventDefault();
      if (this.modifyPasword.NewPassword != this.modifyPasword.NewPassword2) {
        this.$throw("二次输入的密码不一致");
      } else {
        this.loading = this.$loading();
        this.$ksajax
          .ksPostPromise("/api/alarm/ModifyPassword", this.modifyPasword, true)
          .then(() => {
            this.loading.close();
            this.hiden("passwordmd");
            // this.select();
          })
          .catch((err) => {
            this.loading.close();
            this.$throw(err);
            this.hiden("passwordmd");
          });
      }
    },

    hiden(name) {
      if (!name) {
        this.$refs.modallg.hide();
      } else {
        this.$refs[name].hide();
      }
    },
    password() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.modifyPasword.Username = this.current.Name;
      this.$refs.passwordmd.show();
    },
    partment() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
    },
  },
};
</script>

