<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      :toggleOn="TaskState === '0' ? true : false"
      :toggleOff="TaskState === '1' ? true : false"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
      v-on:toggleOn="toggleOn"
      v-on:toggleOff="toggleOff"
    >
    </actionbar>

    <cityBroadCastPage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      v-on:called="called"
      v-on:setPlay="setPlay"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></cityBroadCastPage>

    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('任务名称')" :label-cols="2">
          <b-form-input
            type="text"
            v-model="modalData.Name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('状态')" label-cols="2">
          <b-form-select
            :options="IsDisableArray"
            v-model="modalData.IsDisable"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-bind:label="$t('优先级（0-15）')" :label-cols="2">
          <b-form-input
            type="number"
            v-model="modalData.TaskLevel"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('添加触发器')" :label-cols="2">
          <b-button
            style="width: 50px"
            variant="primary"
            v-on:click="addTriggers"
            >+</b-button
          >
        </b-form-group>
        <b-form-group
          v-bind:label="$t('触发器列表')"
          :label-cols="2"
          v-if="modalData.Triggers.length > 0"
        >
          <div style="border: 1px solid #999; border-radius: 5px">
            <div
              class="TriggersItem"
              style="
                display: flex;
                padding: 5px;
                justify-content: space-between;
                align-items: center;
              "
              v-for="(item, index) in modalData.Triggers"
              :key="index"
            >
              <div>
                {{ index + 1 }}、 执行时间:{{ item.StartTime }}|周期类型:{{
                  item.ScheduleType === "0"
                    ? "每天"
                    : item.ScheduleType === "1"
                    ? "每周"
                    : "单次"
                }}|开始日期:{{ item.StartDate }}|结束日期：{{ item.EndDate }}
              </div>
              <div>
                <i
                  class="fa fa-cog"
                  style="cursor: pointer"
                  @click="selTrigger(item, index)"
                ></i>
                <i
                  class="fa fa-trash-o"
                  style="cursor: pointer"
                  @click="delTrigger(item, index)"
                ></i>
              </div>
            </div>
          </div>
        </b-form-group>
        <b-form-group v-bind:label="$t('添加操作')" :label-cols="2">
          <b-button
            style="width: 50px"
            variant="primary"
            v-on:click="addCommands"
            >+</b-button
          >
        </b-form-group>
        <b-form-group
          v-bind:label="$t('操作列表')"
          :label-cols="2"
          v-if="modalData.Triggers.length > 0"
        >
          <div style="border: 1px solid #999; border-radius: 5px">
            <div
              class="CommandsItem"
              style="
                display: flex;
                padding: 5px;
                justify-content: space-between;
                align-items: center;
              "
              v-for="(item, index) in modalData.Commands"
              :key="index"
            >
              <div>
                {{ index + 1 }}、
                {{ item.AudioSrc === "0" ? "文件" : "文本" }}-{{
                  item.AudioSrc === "0" ? item.AudioFiles : item.SourceTxt
                }}|终端信息-{{ item.Terminals }}
              </div>
              <div>
                <i
                  class="fa fa-cog"
                  style="cursor: pointer"
                  @click="selCommand(item, index)"
                ></i>
                <i
                  class="fa fa-trash-o"
                  style="cursor: pointer"
                  @click="delCommand(item, index)"
                ></i>
              </div>
            </div>
          </div>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button
              style="width: 100px"
              variant="secondary"
              v-on:click="hiden"
              >{{ $t("取消") }}</b-button
            >
            <b-button
              type="button"
              style="width: 100px"
              variant="primary"
              v-on:click="onSubmit"
              >{{ $t("确认") }}</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modatrigger"
      ref="modatrigger"
      size="lg"
      v-bind:title="tritterTitle"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmitTrigger">
        <b-form-group v-bind:label="$t('周期类型')" :label-cols="2">
          <b-form-select
            :options="ScheduleTypeArray"
            v-model="trigger.ScheduleType"
          ></b-form-select>
        </b-form-group>
        <b-form-group :label="$t('执行日期')" label-cols="2">
          <div>
            <el-date-picker
              style="margin-right: 10px"
              v-model="trigger.StartDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy/MM/dd"
            ></el-date-picker>

            <el-date-picker
              v-model="trigger.EndDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy/MM/dd"
              v-if="trigger.ScheduleType !== '2'"
            ></el-date-picker>
          </div>
        </b-form-group>

        <b-form-group v-bind:label="$t('执行时间')" :label-cols="2">
          <div>
            <el-time-picker
              v-model="trigger.StartTime"
              placeholder="选择时间"
              value-format="HH:mm:ss"
            ></el-time-picker>
          </div>
        </b-form-group>
        <b-form-group
          v-bind:label="$t('星期')"
          :label-cols="2"
          v-if="trigger.ScheduleType === '1'"
        >
          <div>
            <b-check-group
              :options="Weeks"
              v-model="trigger.WeekDayList"
            ></b-check-group>
          </div>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button
              style="width: 100px"
              variant="secondary"
              v-on:click="hidenTrigger"
              >{{ $t("取消") }}</b-button
            >
            <b-button
              type="button"
              style="width: 100px"
              variant="primary"
              v-on:click="onSubmitTrigger"
              >{{ $t("确认") }}</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modacommand"
      ref="modacommand"
      size="lg"
      v-bind:title="commandTitle"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmitCommand">
        <b-form-group v-bind:label="$t('音量微调（-5到5）')" :label-cols="2">
          <b-form-input
            type="number"
            v-model="command.Volinch"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('同步强切')" :label-cols="2">
          <b-form-select
            :options="SyncCtrlArray"
            v-model="command.SyncCtrl"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-bind:label="$t('终端列表')" :label-cols="2">
          <div>
            <b-check-group
              :options="iterminalArray"
              v-model="command.Terminals"
            ></b-check-group>
          </div>
        </b-form-group>
        <b-form-group v-bind:label="$t('音频源类型')" :label-cols="2">
          <b-form-select
            :options="AudioSrcArray"
            v-model="command.AudioSrc"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          v-bind:label="$t('媒体列表')"
          :label-cols="2"
          v-if="command.AudioSrc === '0'"
        >
          <div>
            <b-check-group
              :options="mediaArray"
              v-model="command.AudioFiles"
            ></b-check-group>
          </div>
        </b-form-group>
        <b-form-group
          v-bind:label="$t('播放文本')"
          :label-cols="2"
          v-if="command.AudioSrc === '3'"
        >
          <b-form-input
            type="text"
            v-model="command.SourceTxt"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('持续时间')" :label-cols="2">
          <b-radio-group
            :options="DurationsArray"
            v-model="DurationSel"
            @change="commandChange"
          ></b-radio-group>
        </b-form-group>
        <b-form-group
          v-bind:label="$t('自定义时长（秒）')"
          :label-cols="2"
          v-if="DurationSel === '1'"
        >
          <b-form-input
            type="number"
            v-model="command.Durations"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('随机播放')" :label-cols="2">
          <b-form-select
            :options="IsRandomArray"
            v-model="command.IsRandom"
          ></b-form-select>
        </b-form-group>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button
              style="width: 100px"
              variant="secondary"
              v-on:click="hidenCommand"
              >{{ $t("取消") }}</b-button
            >
            <b-button
              type="button"
              style="width: 100px"
              variant="primary"
              v-on:click="onSubmitCommand"
              >{{ $t("确认") }}</b-button
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/CityBroadcast/PlanTask/Delete"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>

<script>
import actionbar from "@/components/actionbar";
import deletedata from "@/components/deletedata";
import cityBroadCastPage from "./components/cityBroadCastPage.vue";
export default {
  components: { actionbar, cityBroadCastPage, deletedata },
  data: function () {
    return {
      modalData: {
        Id: "",
        Name: "",
        IsDisable: "0",
        TaskLevel: "0",
        Triggers: [],
        Commands: [],
      },
      trigger: {
        StartDate: "",
        EndDate: "",
        StartTime: "",
        ScheduleType: "0",
        WeekDayList: [],
      },
      seltriggerIndex: -1,
      command: {
        Durations: "",
        Volinch: "",
        SyncCtrl: "",
        Terminals: [],
        AudioFiles: [],
        FileTime: [],
        SourceTxt: "",
        IsRandom: "",
        AudioSrc: "",
      },
      selcommandIndex: -1,
      loading: {},
      cols: [
        { name: this.$t("任务名称"), value: "Name" },
        { name: this.$t("任务Id"), value: "Id" },
        { name: this.$t("创建人"), value: "Creator" },
        { name: this.$t("描述"), value: "Description" },
        { name: this.$t("启用"), value: "IsDisable", switch: true },
        { name: this.$t("任务状态"), value: "TaskState" },
        { name: this.$t("手动"), value: "State", play: true },
        { name: this.$t("是否丢失任务文件"), value: "fileState" },
      ],
      items: [],
      total: 0,
      count: 6,
      index: 0,
      sid: "",
      title: "",
      tritterTitle: "",
      commandTitle: "",
      current: null,
      IsDisableArray: [
        { value: "0", text: "启用" },
        { value: "1", text: "禁用" },
      ],
      ScheduleTypeArray: [
        { value: "0", text: "每天" },
        { value: "1", text: "每周" },
        { value: "2", text: "单次" },
      ],
      Weeks: [
        { value: "1", text: this.$t("星期一") },
        { value: "2", text: this.$t("星期二") },
        { value: "3", text: this.$t("星期三") },
        { value: "4", text: this.$t("星期四") },
        { value: "5", text: this.$t("星期五") },
        { value: "6", text: this.$t("星期六") },
        { value: "0", text: this.$t("星期天") },
      ],
      SyncCtrlArray: [
        { value: "0", text: "禁用" },
        { value: "1", text: "启用" },
      ],
      AudioSrcArray: [
        { value: "0", text: "文件" },
        { value: "3", text: "文本" },
      ],
      IsRandomArray: [
        { value: "0", text: "否" },
        { value: "1", text: "是" },
      ],
      iterminalArray: [],
      mediaArray: [],
      DurationsArray: [
        { value: "0", text: "播放一遍" },
        { value: "1", text: "自定义" },
      ],
      DurationSel: "0",
      TaskState: "0",
    };
  },
  mounted: function () {
    this.select();
    this.getTaskState();
    this.getIterminal();
    this.getMedia();
  },
  methods: {
    setPlay(item) {
      console.log("setPlay:", item);
      if (item.State !== "0") {
        this.loading = this.$loading({ fullScreen: true });

        this.$ksajax
          .ksPostPromise("/api/CityBroadcast/PlanTask/Stop", item.Id, true)
          .then((data) => {
            console.log("data:", data);
            this.select();
            if (this.loading.close) {
              this.loading.close();
            }
          })
          .catch((err) => {
            this.$throw(err, this);
            if (this.loading.close) {
              this.loading.close();
            }
          });
      } else if (item.State === "0") {
        this.loading = this.$loading({ fullScreen: true });

        this.$ksajax
          .ksPostPromise("/api/CityBroadcast/PlanTask/Run", item.Id, true)
          .then((data) => {
            console.log("data:", data);
            this.select();
            if (this.loading.close) {
              this.loading.close();
            }
          })
          .catch((err) => {
            this.$throw(err, this);
            if (this.loading.close) {
              this.loading.close();
            }
          });
      }
    },
    called(item) {
      console.log("calleditem:", item);

      this.loading = this.$loading({ fullScreen: true });
      let obj = {
        TaskId: item.Id,
        IsDisabled: item.IsDisable === "0" ? "1" : "0",
      };
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/PlanTask/Switch", obj, true)
        .then((data) => {
          console.log("data:", data);
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    getTaskState() {
      this.loading = this.$loading({ fullScreen: true });

      this.$ksajax
        .ksPostPromise(
          "/api/CityBroadcast/PlanTask/GetAllPlanTaskState",
          "",
          true
        )
        .then((data) => {
          console.log("getTaskState:", data);
          this.TaskState = data;
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    toggleOn() {
      console.log("开启");
      this.loading = this.$loading({ fullScreen: true });

      this.$ksajax
        .ksPostPromise(
          "/api/CityBroadcast/PlanTask/AllPlanTaskSwitch",
          "1",
          true
        )
        .then((data) => {
          console.log("data:", data);
          this.TaskState = "1";
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    toggleOff() {
      console.log("关闭");
      this.loading = this.$loading({ fullScreen: true });

      this.$ksajax
        .ksPostPromise(
          "/api/CityBroadcast/PlanTask/AllPlanTaskSwitch",
          "0",
          true
        )
        .then((data) => {
          console.log("data:", data);
          this.TaskState = "0";
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    commandChange(e) {
      console.log("e:", e);
      if (e === "0") {
        this.command.Durations = "0";
      }
    },
    addCommands() {
      this.selcommandIndex = -1;
      this.command = {
        Durations: "0",
        Volinch: "0",
        SyncCtrl: "0",
        Terminals: [],
        AudioFiles: [],
        FileTime: [],
        SourceTxt: "",
        IsRandom: "0",
        AudioSrc: "0",
      };
      this.commandTitle = this.$t("新增");
      this.$refs.modacommand.show();
    },
    selCommand(item, index) {
      console.log("selcommandItem:", item);
      this.selcommandIndex = index;
      this.command = {
        Durations: item.Durations,
        Volinch: item.Volinch,
        SyncCtrl: item.SyncCtrl,
        Terminals: item.Terminals,
        AudioFiles: item.AudioFiles,
        FileTime: item.FileTime,
        SourceTxt: item.SourceTxt,
        IsRandom: item.IsRandom,
        AudioSrc: item.AudioSrc,
      };
      if (item.Durations === "0") {
        this.DurationSel = "0";
      } else {
        this.DurationSel = "1";
      }
      this.commandTitle = this.$t("修改");
      this.$refs.modacommand.show();
    },
    delCommand(item, index) {
      this.modalData.Commands.splice(index, 1);
    },
    onSubmitCommand(evt) {
      evt.preventDefault();
      console.log("this.command:", this.command);
      if (this.selcommandIndex !== -1) {
        this.modalData.Commands.splice(this.selcommandIndex, 1, this.command);
      } else {
        this.modalData.Commands.push(this.command);
      }
      this.hidenCommand();
    },
    hidenCommand() {
      this.$refs.modacommand.hide();
    },
    addTriggers() {
      this.seltriggerIndex = -1;
      this.trigger = {
        StartDate: "",
        EndDate: "",
        StartTime: "",
        ScheduleType: "0",
        WeekDayList: [],
      };
      this.tritterTitle = this.$t("新增");
      this.$refs.modatrigger.show();
    },
    selTrigger(item, index) {
      console.log("seltriggerItem:", item);
      this.seltriggerIndex = index;
      this.trigger = {
        StartDate: item.StartDate,
        EndDate: item.EndDate,
        StartTime: item.StartTime,
        ScheduleType: item.ScheduleType,
        WeekDayList: item.WeekDayList,
      };
      this.tritterTitle = this.$t("修改");
      this.$refs.modatrigger.show();
    },
    delTrigger(item, index) {
      console.log("deltriggerItem:", item);
      this.modalData.Triggers.splice(index, 1);
    },
    onSubmitTrigger(evt) {
      evt.preventDefault();
      if (this.seltriggerIndex !== -1) {
        this.modalData.Triggers.splice(this.seltriggerIndex, 1, this.trigger);
      } else {
        this.modalData.Triggers.push(this.trigger);
      }
      this.hidenTrigger();
    },
    hidenTrigger() {
      this.$refs.modatrigger.hide();
    },
    select() {
      this.loading = this.$loading({ fullScreen: true });

      let obj = {
        PageIndex: this.index,
        PageCount: this.count,
      };
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/PlanTask/GetList", obj, true)
        .then((data) => {
          console.log("data:", data);
          this.items = data.Rows;
          this.items.forEach((element) => {
            if (element.IsDisable === "0") {
              element.disableState = "启用";
            } else {
              element.disableState = "禁用";
            }
            if (element.State === "0") {
              element.TaskState = "未运行";
            } else if (element.State === "1") {
              element.TaskState = "自动运行";
            } else if (element.State === "2") {
              element.TaskState = "手动运行";
            }
            if (element.IsLostFile === "0") {
              element.fileState = "否";
            } else {
              element.fileState = "是";
            }
          });
          this.total = parseInt(data.Total);
          if (this.total <= this.count) {
            this.index = 0;
          }
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    insert() {
      this.title = this.$t("新增");
      this.modalData = this.resetData();
      this.$refs.modallg.show();
    },
    resetData() {
      return {
        Id: "",
        Name: "",
        IsDisable: "0",

        TaskLevel: "0",
        Triggers: [],
        Commands: [],
      };
    },
    tableCreated() {},
    currentPage(val) {
      console.log("val：", val);
      this.index = val - 1;
      this.select();
    },
    currentRow(val) {
      console.log("val:", val);
      if (val && val.Id) {
        this.loading = this.$loading({ fullScreen: true });

        this.$ksajax
          .ksPostPromise("/api/CityBroadcast/PlanTask/Get", val.Id, true)
          .then((data) => {
            console.log("data:", data);
            this.current = data;
            if (this.loading.close) {
              this.loading.close();
            }
          })
          .catch((err) => {
            this.$throw(err, this);
            if (this.loading.close) {
              this.loading.close();
            }
          });
      } else {
        this.current === null;
      }
    },
    getIterminal() {
      this.iterminalArray = [];
      let obj = {
        PageIndex: 0,
        PageCount: 10000,
      };
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/Terminal/GetList", obj, true)
        .then((data) => {
          console.log("data:", data);

          data.Rows.forEach((element) => {
            let dict = { value: "", text: "" };
            dict.value = element.Id;
            dict.text = element.Name;
            this.iterminalArray.push(dict);
          });

          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err, this);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    getMedia() {
      this.mediaArray = [];
      let obj2 = {
        PageIndex: 0,
        PageCount: 10000,
      };
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/Media/GetList", obj2, true)
        .then((data) => {
          console.log("data:", data);
          data.Rows.forEach((element) => {
            let dict = { value: "", text: "" };
            dict.value = element.Name;
            dict.text = element.Name;
            this.mediaArray.push(dict);
          });
        })
        .catch((error) => {
          this.loading.close();
          this.$throw(error);
        });
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      console.log("tid:", this.current.Id);
      this.sid = this.current.Id;
      this.$refs.del.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }

      this.modalData = {
        Id: this.current.Id,
        Name: this.current.Name,
        IsDisable: this.current.IsDisable,

        TaskLevel: this.current.TaskLevel,
        Triggers: this.current.Triggers,
        Commands: this.current.Commands,
      };
      this.title = this.$t("修改");
      this.$refs.modallg.show();
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.modalData.Id) {
        this.loading = this.$loading({ fullScreen: true });
        this.$ksajax
          .ksPostPromise(
            "/api/CityBroadcast/PlanTask/Update",
            {
              Id: this.modalData.Id,
              Name: this.modalData.Name,
              IsDisable: this.modalData.IsDisable,
              TaskLevel: this.modalData.TaskLevel,
              Triggers: this.modalData.Triggers,
              Commands: this.modalData.Commands,
            },
            true
          )
          .then(() => {
            this.loading.close();
            this.hiden();
            this.select();
          })
          .catch((err) => {
            this.loading.close();
            this.$throw(err);
          });
      } else {
        this.loading = this.$loading({ fullScreen: true });
        this.$ksajax
          .ksPostPromise(
            "/api/CityBroadcast/PlanTask/Add",
            {
              Name: this.modalData.Name,
              IsDisable: this.modalData.IsDisable,

              TaskLevel: this.modalData.TaskLevel,
              Triggers: this.modalData.Triggers,
              Commands: this.modalData.Commands,
            },
            true
          )
          .then(() => {
            this.select();
            this.loading.close();
            this.hiden();
          })
          .catch((err) => {
            this.loading.close();
            this.$throw(err);
          });
      }
    },
    deleted() {
      this.select();
    },
    hiden() {
      this.$refs.modallg.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>