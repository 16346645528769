<template>
  <div class="overhide xxsensor" :style="style">
      <div class="dialog"  ref="sensor_con"  v-html="shtml"></div>
  </div>
</template>
<style>
/* .xxsensor{
    display:flex;
    align-content: center;align-items: center;
}
.xxsensor p{
    width: 100%;
    display: block;
} */
.xxsensor:after {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.dialog {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    font-size: 14px;
    white-space: normal;
    width: 100%;
}
</style>

<script>
export default {
  props: ["sensor","html","speed","lheight","bground","url"],
  data: function() {
    return {
      shtml: "",
      interval: null,
      pageInterval:null,
      style:{
          "line-height":1.4,
          "background-color":"black"
      },
      currentP:0
    };
  },
  mounted(){
      this.$nextTick(()=>{
          this.getContent(); 
          this.startInterval();
          this.Page();
          this.startPage();
          this.sensor.lineHeight=this.sensor.lineHeight||1.4;
          this.sensor.backgroundColor=this.sensor.backgroundColor||"rgba(0,0,0,1)";
          this.style["line-height"]=this.sensor.lineHeight;
          this.style["background-color"]=  this.sensor.backgroundColor;
      })
  },
  watch: {
      url:function(){
          this.sensor.sUrl=this.url;
          this.Page();
          this.getContent(); 
      },
      speed:function(){
          this.sensor.speed=this.speed;
           window.clearInterval(this.interval);
        this.interval = null;
        window.clearInterval(this.pageInterval);
        this.pageInterval=null;
          this.startPage();
      },
      bground:function(){
          this.sensor.backgroundColor=this.bground;
          this.style["background-color"]=this.bground;
      },
      lheight:function(){
          this.sensor.lineHeight=this.lheight;
          this.style["line-height"]=this.lheight;
      },
    html:function(){
      this.sensor.html=this.html;
      window.clearInterval(this.interval);
      this.interval = null;
      this.getContent();
      this.startInterval();
    }
  },
  methods: {
    getContent() {
      if(this.sensor.sUrl){
      this.$ksajax
        .ksGetPromise(this.sensor.sUrl)
        .then(data => {
          var ht= this.sensor.html.replace("%s", data.data);
          ht=ht.replace("<p><p>","<p>")
          ht=ht.replace("</p></p>","</p>")
          this.shtml=ht;
        })
        .catch(err => {
          this.$throw(err);
        });
      }else{
          this.shtml=this.sensor.html;
      }
    },
    startInterval() {
      if (!this.sensor.sInterval) {
        this.sensor.sInterval = 10;
      }
      if(this.sensor.sUrl){
        this.interval = window.setInterval(() => {
            if (this.sensor.sUrl) {
            this.getContent();
            }
        }, this.sensor.sInterval*1000);
      }
    },
    startPage(){
        if(!this.sensor.speed){
            this.sensor.speed=10;
        }
        this.pageInterval=window.setInterval(()=>{
          this.Page();
        },this.sensor.speed*1000);
    },
    Page(){
        
            var ele=this.$refs.sensor_con;
            var cd=ele.children;
            var list=[];
            for(var i=0;i<cd.length;i++){
                var element=cd[i];
                if(element.nodeName=="P"){
                    list.push(element);
                }
            }
            if(this.currentP+1>list.length){
                this.currentP=0;
            }
            if(list.length>1){
                for(var k=0;k<list.length;k++){
                    if(this.currentP==k){
                        list[k].style.display="block"
                    }else{
                        list[k].style.display="none"
                 }
                }
                this.currentP+=1;
            }
    }
  },
  destroyed: function() {
    window.clearInterval(this.interval);
    this.interval = null;
    window.clearInterval(this.pageInterval);
    this.pageInterval=null;
  }
};
</script>
