<template>
    <div class="ligthview" v-loading="loading">
        <!-- 来访统计 -->
        <div class="ligthview_3">
            <div>
                <box>
                    <div style="height:100%; width:100%; padding-top:20px;" id="chatTop"> 
                    </div>
                </box>
            </div>
        </div>

        <div class="ligthview_3" style="height:30%;">
            <div>
                <box>
                    <b-row class="info_list" style="height:100%;">
                        <b-col cols="2"><h3>{{$t("人脸识别数量")}}</h3><small>Recognition Quantity</small></b-col>
                        <b-col>
                            <b-row style="height:100%; ">
                                <b-col style="border-right:1px solid #3274b8;">
                                    <p class="info_title">{{$t("今日人次")}}</p>
                                    <p class="info_val">
                                        <router-link :to="`/admin/Face/FaceMsg?opcode=1`"> {{current.FaceMessage.TodayCount}}</router-link>
                                    </p>
                                </b-col>
                                <b-col style="border-right:1px solid #3274b8;"><p class="info_title">{{$t("近7天人次")}}</p><p class="info_val">{{current.FaceMessage.Nearly7DaysCount}}</p></b-col>
                                <b-col style="border-right:1px solid #3274b8;"><p class="info_title">{{$t("近30天人次")}}</p><p class="info_val">{{current.FaceMessage.Nearly30DaysCount}}</p></b-col>
                                <b-col><p class="info_title">{{$t("累计人次")}}</p><p class="info_val">{{current.FaceMessage.TotalCount}}</p></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </box>
            </div>
        </div>

        <div class="ligthview_2">
            <div>
                <box>
                    <div style="height:100%;width:100%; padding-top:20px;" id="chatLeft">
                    </div>
                </box>
            </div>
        </div>

        <div class="ligthview_2">
            <div>
                <box>
                    <div style="height:100%;width:100%" id="chatRight">

                    </div>
                    <div class="container">
                        <div class="container_item"> {{$t("男性")}}<span class="num_b">{{mydataChat2.num1}}</span>{{$t("次")}} </div>
                        <div class="container_item"> {{$t("女性")}}<span class="num_b">{{mydataChat2.num2}}</span>{{$t("次")}} </div>
                    </div>
                </box>
            </div>
        </div>
        
        <div class="ligthview_3">
            <div>
                <box>
                    <div style="height:100%; width:100%; padding-top:20px;" id="chatBottom">

                    </div>
                </box>
            </div>
        </div>

        <div class="ligthview_3" style="height:20%;">
            <div>
                <box>
                    <b-row class="info_list" style="height:100%;">
                        <b-col cols="2"><h3>{{$t("设备信息")}}</h3><small>Device Info</small></b-col>
                        <b-col>
                            <b-row style="height:50%;">
                                <b-col class="info_col">{{$t("名称")}}<span>{{current.DeviceInfo.DeviceName}}</span></b-col>
                                <b-col class="info_col">{{$t("序列号")}}<span>{{current.DeviceInfo.Serial}}</span></b-col>
                                <b-col class="info_col">{{$t("是否绑定Ai任务")}}<span>{{current.DeviceInfo.IsBinding==true?$t("是"):$t("否")}}</span></b-col>
                                <b-col class="info_col">>{{$t("是否启动事件消息")}}：<span>{{current.DeviceInfo.AiKitStatus==1?$t("是"):$t("否")}}</span></b-col>
                            </b-row>
                            <b-row style="height:50%;">
                                <b-col class="info_col" style="padding-top:0;">>{{$t("类型")}}：<span>{{current.DeviceInfo.VendorType}}</span></b-col>
                                <b-col class="info_col" style="padding-top:0;">>{{$t("状态")}}：<span>{{current.DeviceInfo.Status==0?$t("离线"):$t("在线")}}</span></b-col>
                                <b-col class="info_col" cols="6" style="padding-top:0;">>{{$t("地址")}}：<span>{{current.DeviceInfo.Address}}</span></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </box>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>

<style>
    /* 人脸识别文字 */
    .ligthview .ligthview_2 .container {
        position: absolute;
        top: 0;
        right: 0;
        text-align: left;
        width: 30%;
        z-index: 1;
        padding: 30px 20px;
    }

        .ligthview .ligthview_2 .container .container_item {
            color: #eee;
            min-width: 173px;
        }

            .ligthview .ligthview_2 .container .container_item .num_b {
                color: #fff;
                font-size: 36px;
                padding: 0 5px;
            }

    .ligthview .ligthview_3 > div {
        padding: 5px;
        width: 100%;
        height: 100%;
    }

    .ligthview_3 {
        width: 100%;
        height: 42%;
    }

        .ligthview_3 .info_list {
            color: #d1d1d1;
        }

            .ligthview_3 .info_list h3 {
                color: #fff;
                font-size: 18px;
                font-weight: bold;
                padding: 20px 0 0 20px;
                margin-bottom: 0;
            }

            .ligthview_3 .info_list small {
                padding: 0 0 0 20px;
            }

            .ligthview_3 .info_list span {
                color: #fff;
            }

            .ligthview_3 .info_list .info_col {
                padding: 20px;
                font-size: 17px;
            }

            .ligthview_3 .info_list .info_title {
                text-align: center;
                padding-top: 15%;
                margin: 0;
                font-size: 17px;
            }

            .ligthview_3 .info_list .info_val {
                text-align: center;
                font-size: 44px;
                color: #fff;
            }
</style>

<script>
    import box from "@/components/box.vue"
    export default {
        components: { box },
        data: function () {
            return {
                loading: false,
                sid: null,
                current: {
                    DeviceInfo: {},
                    FaceMessage: {},
                    FaceRecognition: {},
                    Sex: {},
                    Age: {},
                },
                mydataChat1: {},
                mydataChat2: {},
                chatTop: null,
                chatLeft: null,
                chatRight: null,
                chatBottom: null,
                color1: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#2978c9' },
                    { offset: 0.5, color: '#00b2e4' },
                    { offset: 1, color: '#00e7fd' }]),
                color2: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#ffad42' },
                    { offset: 0.5, color: '#ffc54b' },
                    { offset: 1, color: '#ffd450' }]),
                color3: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#f7500f' },
                    { offset: 0.5, color: '#eec54b' },
                    { offset: 1, color: '#f7500f' }]),
                color4: new window.echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{ offset: 0, color: '#f7510f' },
                    { offset: 0.5, color: '#ee154b' },
                    { offset: 1, color: '#f75020' }]),
            }
        },
        mounted: function () {
            this.select();
            this.chatTop = window.echarts.init(document.getElementById("chatTop"));
            this.chatLeft = window.echarts.init(document.getElementById("chatLeft"));
            this.chatRight = window.echarts.init(document.getElementById("chatRight"));
            this.rightBottom = window.echarts.init(document.getElementById("chatBottom"));
            this.chatBottom = window.echarts.init(document.getElementById("chatBottom"));
        },
        methods: {
            getOption(xAxis, yAxis, datas) {
                var obj = {
                    textStyle: {
                        color: '#8495c2'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: xAxis,
                    yAxis: yAxis,
                    series: datas,
                    legend: {
                        data: [],
                        textStyle: {
                            color: '#ffffff'
                        },
                        orient: 'vertical',
                        right: 50,
                    }
                }
                return obj;
            },
            //  左扇形图
            beginLeftTop(num1, num2, num3, num4) {
                this.mydataChat1 = {
                    num1: num1,
                    num2: num2,
                    num3: num3,
                    num4: num4,
                }
                var sum = num1 + num2 + num3 + num4;
                var option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b}: {c} ({d}%)"
                    },
                    legend: {
                        left: 5,
                        bottom: 5,
                        orient: "vertical",
                        data: [this.$t('普通客人'), this.$t('重要客人'), this.$t('陌生人'), this.$t('嫌疑人')],
                        textStyle: {
                            color: '#ffffff'
                        }
                    },
                    series: [
                        {
                            radius: ['60%', '90%'],
                            name: this.$t('总数量'),
                            type: 'pie',
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    formatter: ['{title|总数量}', `{count|${sum}}`].join('\n'),
                                    show: true,
                                    position: 'center',
                                    rich: {
                                        title: {
                                            color: "white",
                                            fontSize: 22
                                        },
                                        count: {
                                            color: "#8495c2",
                                            fontSize: 34,
                                            padding: [0, 0, 10, 0],
                                        }
                                    }
                                },
                            },
                            labelLine: {
                                normal: {
                                    show: true
                                }
                            },
                            data: [
                                { value: num1, name: this.$t('普通客人') },
                                { value: num2, name: this.$t('重要客人') },
                                { value: num3, name: this.$t('陌生人') },
                                { value: num4, name: this.$t('嫌疑人') },
                            ],
                            color: [this.color1, this.color2, this.color3, this.color4]
                        },

                    ],
                    grid: {
                        top: 0,
                        right: 100,
                        bottom: 0,
                        containLabel: false
                    }
                };
                option.title = {
                    show: true,
                    text: "人物识别比例1",
                    subtext: "Person Ratio",
                    "padding": [0, 0, 0, 0],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                }
                this.chatLeft.setOption(option, true);

                window.addEventListener("resize", () => {
                    this.chatLeft.resize();
                });
            },
            // 右扇形图
            beginLeftBottom(j, s) {
                this.mydataChat2 = {
                    num1: j,
                    num2: s,
                }
                var on = j
                var off = s;
                var sub = j + s;
                var option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b}: {c} ({d}%)"
                    },
                    legend: {
                        left: 5,
                        bottom: 5,
                        orient: "vertical",
                        data: [this.$t('男性'), this.$t('女性')],
                        textStyle: {
                            color: '#ffffff'
                        }
                    },
                    series: [
                        {
                            radius: ['60%', '90%'],
                            name: this.$t('总数量'),
                            type: 'pie',
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    formatter: ['{title|总数量}', `{count|${sub}}`].join('\n'),
                                    show: true,
                                    position: 'center',
                                    rich: {
                                        title: {
                                            color: "white",
                                            fontSize: 22
                                        },
                                        count: {
                                            color: "#8495c2",
                                            fontSize: 34,
                                            padding: [0, 0, 10, 0],
                                        }
                                    }
                                },
                            },
                            labelLine: {
                                normal: {
                                    show: true
                                }
                            },
                            data: [
                                { value: on, name: this.$t('男性') },
                                { value: off, name: this.$t('女性') },
                            ],
                            color: [this.color1, this.color3]
                        },

                    ],
                    grid: {
                        top: 0,
                        right: 100,
                        bottom: 0,
                        containLabel: false
                    }
                };
                option.title = {
                    show: true,
                    text: "性别比例",
                    subtext: "Sex Ratio",
                    "padding": [20, 0, 0, 20],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                }
                this.chatRight.setOption(option, true);

                window.addEventListener("resize", () => {
                    this.chatRight.resize();
                });
            },
            // 左条形统计图
            beginLeftBarGraph(num1, num2, num3, num4) {
                var datas = [];
                datas.push({
                    name: "数量",
                    type: "bar",
                    stack: "总量",
                    smooth: true,
                    data: [num1, num2, num3, num4],
                    barCategoryGap: "50%",
                    itemStyle: {
                        color: '#ca8622'
                    }
                });
                var x = {
                    type: "category",
                    data: [this.$t('普通客人'), this.$t('重要客人'), this.$t('陌生人'), this.$t('嫌疑人')],
                    splitLine: { show: false },
                    z: 10
                };
                var y = {
                    type: "value",
                    splitLine: { show: false },
                    z: 10
                };
                var option = this.getOption(x, y, datas);
                (option.title = {
                    show: true,
                    text: "人物类型分布",
                    subtext: "Person Distrubution",
                    padding: [0, 0, 0, 20],
                    textStyle: {
                        color: "#ffffff"
                    }
                }),
                    this.chatLeft.setOption(option, true);
                window.addEventListener("resize", () => {
                    this.chatLeft.resize();
                });
            },
            // 底部条形统计图
            beginChatBottom(arrTrend) {
                //var data = this.current.Resources;
                var d = arrTrend;
                var datas = [];
                datas.push({
                    name: '数量',
                    type: 'bar',
                    stack: '总数量',
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            color: "white"
                        }
                    },
                    data: [d.LessThan10Count, d.Between10And20Count, d.Between20And30Count, d.Between30And40Count, d.Between40And50Count, d.Between50And60Count, d.Between60And70Count, d.Between70And80Count, d.GreaterThan80Count, d.UnknowCount],
                    itemStyle: {
                        color: "#1b85c9"
                    },
                });
                var x = {
                    type: "category",
                    boundaryGap: false,
                    data: [this.$t('10岁以下'), this.$t('10~20岁'), this.$t('20~30岁'), this.$t('30~40岁'), this.$t('40~50岁'), this.$t('50~60岁'), this.$t('60~70岁'), this.$t('70~80岁'), this.$t('80岁以上'), this.$t('未知')],
                }
                var y = {
                    splitLine: {
                        lineStyle: {
                            color: "#8495c2"
                        }
                    },
                    type: "value"
                }
                var option = this.getOption(x, y, datas);
                option.title = {
                    show: true,
                    text: "年龄分布",
                    subtext: "Age Distribution",
                    "padding": [0, 0, 0, 20],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                },
                    this.chatBottom.setOption(option);

                window.addEventListener("resize", () => {
                    this.chatBottom.resize();
                });
            },
            // 每天识别数量折线图
            beginChatTop(arrTrend) {
                var online = [];
                var labels = [];
                for (var i = 0; i < arrTrend.length; i++) {
                    online.push(arrTrend[i].Count);
                    labels.push(arrTrend[i].Month + "/" + arrTrend[i].Day);
                }

                var datas = [];
                datas.push({
                    name: '数量',
                    type: 'line',
                    stack: '总数量',
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            color: "white"
                        }
                    },
                    smooth: true,
                    data: online,
                    itemStyle: {
                        color: "#1b85c9"
                    },
                    areaStyle: {
                        normal: {
                            color: new window.echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: '#00749a' },
                                    { offset: 0.5, color: '#123e62' },
                                    { offset: 1, color: '#132f57' }
                                ]
                            )
                        }
                    },
                });
                var x = {
                    type: "category",
                    boundaryGap: false,
                    data: labels
                }
                var y = {
                    splitLine: {
                        lineStyle: {
                            color: "#8495c2"
                        }
                    },
                    type: "value"
                }
                var option = this.getOption(x, y, datas);
                option.title = {
                    show: true,
                    text: "人脸识别趋势",
                    subtext: "Face Recognition Trends",
                    "padding": [0, 0, 0, 20],
                    textStyle:
                    {
                        color: '#ffffff'
                    },
                },
                    this.chatTop.setOption(option);

                window.addEventListener("resize", () => {
                    this.chatTop.resize();
                });
            },

            select() {
                this.sid = this.$route.query.sid;
                this.loading = true;
                this.$ksajax.ksPostPromise("/api/face/GetStatisticsByDevSid", this.sid, true).then(data => {
                    this.loading = false;
                    this.current = data;
                    var df = data.FaceRecognition;
                    // 普通客人、重要客人、陌生人、嫌疑人
                    this.beginLeftBarGraph(df.RegularCount, df.VipCount, df.StrangerCount, df.SuspectCount);
                    var ds = data.Sex;
                    // 男性、女性
                    this.beginLeftBottom(ds.MaleCount, ds.FemaleCount);
                    // 每天识别数量折线图
                    this.beginChatBottom(data.Age);
                }).catch(err => {
                    this.$throw(err);
                    this.loading = false;
                });
                this.loading = true;
                this.$ksajax.ksPostPromise("/api/overview/GetFaceStatistics", "", true).then(data => {
                    this.loading = false;
                    // 每天识别数量折线图
                    this.beginChatTop(data.RecognitionTrend);
                }).catch(err => {
                    this.$throw(err);
                    this.loading = false;
                })
            }
        }
    }
</script>

