<template>
  <div class="white">
    <b-row class="ksrow">
      <b-col md="12" lg="2">
        <div class="card border-primary mb-3" style="margin-top:10px;">
          <div class="card-header">资源</div>
          <div class="card-body text-primary">
            <div role="tablist">
              <b-card no-body class="mb-1" v-for="item in res" :key="item.id">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block href="#" v-b-toggle="item.id" variant="info">{{item.group}}</b-button>
                </b-card-header>
                <b-collapse :id="item.id" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-list-group>
                      <b-list-group-item
                        style="cursor:pointer"
                        draggable="true"
                        @dragstart="dragStart(it)"
                        v-for="it in item.data"
                        variant="primary"
                        :key="it.Sid"
                      >{{it.Name}}</b-list-group-item>
                    </b-list-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="12" lg="6">
        <div class="card border-primary mb-3" style="margin-top:10px;">
          <div class="card-header">时间轴</div>
          <div class="card-body text-primary">
            <div class="alert alert-primary" role="alert" id="action_notify">
              <b-button variant="outline-primary" @click="addLayer">
                <i class="fa fa-plus-square"></i>添加层
              </b-button>
              <b-button variant="outline-primary" @click="sortUp">
                <i class="fa fa-arrow-up"></i>上移
              </b-button>
              <b-button variant="outline-primary" @click="sortDown">
                <i class="fa fa-arrow-down"></i>下移
              </b-button>
              <b-button variant="outline-primary" @click="delLay">
                <i class="fa fa-trash-o"></i>删除层
              </b-button>
              <b-button variant="outline-primary" @click="delRes">
                <i class="fa fa-trash-o"></i>删除元素
              </b-button>
              <b-button variant="outline-primary" @click="save">
                <i class="fa fa-save"></i>保存节目
              </b-button>
            </div>
            <div id="timeaxis" @scroll="scrolling" style="position:relative">
              <div :style="lineStyle"></div>
              <timeaxis
                ref="timeax"
                :MUnit="false"
                v-on:timeUpdated="timeUpdated"
                v-on:timeClick="timeClick"
              ></timeaxis>
              <div
                v-bind:class="[!lay.active?'':'timeax_a','timeax_u']"
                :style="timeStyle"
                v-for="lay in prog.layers"
                :key="lay.id"
                :id="lay.id"
                @drop="drop($event,lay.id)"
                @dragover="allowDrop($event,lay.id)"
                @click="timeaClick($event,lay.id)"
              >
                <vue-draggable-resizable
                  v-for="item in lay.sources"
                  :id="item.id"
                  :key="item.id"
                  class-name="my-class"
                  :parent="true"
                  :handles="['mr']"
                  :minw="10"
                  :x="item.viewStart"
                  :w="item.viewEnd"
                  :z="item.viewTop"
                  :active="item.active"
                  :h="50"
                  v-on:activated="onActive(item.id)"
                  v-on:deactivated="onDeActive(item.id)"
                  v-on:resizestop="resizestop"
                  v-on:dragstop="dragstop"
                  v-on:resizing="onResizing"
                  v-on:dragging="onDrag"
                >
                  <div class="my-class-content">{{item.name}}</div>
                </vue-draggable-resizable>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-primary mb-4" style="margin-top:10px;">
          <div class="card-header">节目</div>
          <div class="card-body text-primary">
            <div id="progC" :style="progCStyle">
              <template v-for="item in prog.layers">
                <vue-draggable-resizable
                  v-for="it in item.sources"
                  v-if="it.current"
                  :key="`p${it.id}`"
                  :id="`p${it.id}`"
                  :parent="true"
                  :x="it.left"
                  :y="it.top"
                  :w="it.width"
                  :h="it.height"
                  :z="item.sort"
                  :active="it.active"
                  v-on:activated="onActive(it.id)"
                  v-on:deactivated="onDeActive(it.id)"
                  v-on:resizing="onRResizing"
                  v-on:dragging="onRDrag"
                >
                  <img v-if="it.tt==1" :src="it.oldFilePath" style="width:100%;height:100%" />
                  <video
                    v-if="it.tt==2"
                    controls
                    :autoplay="false"
                    name="media"
                    width="100%"
                    height="100%"
                    loop="loop"
                  >
                    <source :src="it.oldFilePath" type="video/mp4" />
                  </video>
                  <xxsensor v-if="it.tt==5" :sensor="it" 
                  :lheight="it.lineHeight" :bground="it.backgroundColor"
                  :speed="it.speed"
                  :url="it.sUrl"
                  ></xxsensor>

                  <div v-if="it.tt==6" style="text-align:center;vertical-align:middle">{{it.name}}</div>
                  <xxsensor v-if="it.tt==0" :sensor="it" :html="it.html" 
                  :lheight="it.lineHeight" :bground="it.backgroundColor"
                  :speed="it.speed"
                  ></xxsensor>
                  <xxscroll v-if="it.tt==3" :data="it.html" :speed="it.speed"></xxscroll>
                </vue-draggable-resizable>
              </template>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="12" lg="4">
        <div class="card border-primary mb-3" style="margin-top:10px;">
          <div class="card-header">节目信息</div>
          <div class="card-body text-primary">
            <div>
              <b-form inline>
                <b-input-group prepend="名称" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input id="inline-form-input-username" v-model="prog.name"></b-input>
                </b-input-group>
                <b-input-group prepend="宽度" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input id="inline-form-input-username" v-model="prog.width" readonly></b-input>
                </b-input-group>
                <b-input-group prepend="高度" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input id="inline-form-input-username" v-model="prog.height" readonly></b-input>
                </b-input-group>
              </b-form>
            </div>
          </div>
        </div>
        <div v-if="currentRes" class="card border-primary mb-3" style="margin-top:10px;">
          <div class="card-header">素材设置</div>
          <div class="card-body text-primary">
            <div>
              <b-form inline>
                <b-input-group prepend="名称" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input id="inline-form-input-username" v-model="currentRes.name"></b-input>
                </b-input-group>
                <b-input-group prepend="类型" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input id="inline-form-input-username" v-model="currentRes._type" readonly></b-input>
                </b-input-group>
                <b-input-group prepend="距左" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input type="number" id="inline-form-input-username" v-model="getLeft"></b-input>
                </b-input-group>
                <b-input-group prepend="距顶" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input type="number" id="inline-form-input-username" v-model="getTop"></b-input>
                </b-input-group>
                <b-input-group prepend="宽度" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input type="number" id="inline-form-input-username" v-model="getWidth"></b-input>
                </b-input-group>
                <b-input-group prepend="高度" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input type="number" id="inline-form-input-username" v-model="getHeight"></b-input>
                </b-input-group>
                <b-input-group prepend="开始" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input type="number" id="inline-form-input-username" v-model="getViewStart"></b-input>
                </b-input-group>
                <b-input-group prepend="持续" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input type="number" id="inline-form-input-username" v-model="getViewEnd"></b-input>
                </b-input-group>
                <b-input-group prepend="入场动画" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-select
                    v-model="currentRes.entryEffect"
                    :options="intAlimate"
                    value-field="value"
                    text-field="text"
                  ></b-form-select>
                </b-input-group>
                <b-input-group prepend="出场动画" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-select
                    v-model="currentRes.exitEffect"
                    :options="intAlimate"
                    value-field="value"
                    text-field="text"
                  ></b-form-select>
                </b-input-group>
                <b-input-group prepend="入场时间" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input
                    type="number"
                    id="inline-form-input-username"
                    v-model="currentRes.entryEffectTimeSpan"
                    style="width:100ox"
                  ></b-input>
                </b-input-group>
                <b-input-group prepend="出场时间" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input
                    type="number"
                    id="inline-form-input-username"
                    v-model="currentRes.exitEffectTimeSpan"
                  ></b-input>
                </b-input-group>
                
                <b-input-group v-if="currentRes.tt==3" prepend="滚动速度" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input type="number" id="inline-form-input-username" v-model="currentRes.speed"></b-input>
                </b-input-group>
                <template v-if="currentRes.tt==0||currentRes.tt==5">
                <b-input-group prepend="行高" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input type="number" id="inline-form-input-username" v-model="getLineHeight"></b-input>
                </b-input-group>
                <b-input-group prepend="背景颜色" class="mb-2 mr-sm-2 mb-sm-0">
                  <el-color-picker v-model="currentRes.backgroundColor" show-alpha></el-color-picker>
                </b-input-group>
                <b-input-group prepend="翻页速度" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input type="number" id="inline-form-input-username" v-model="currentRes.speed"></b-input>
                </b-input-group>
                </template>
                <template v-if="currentRes.tt==5">
                  <b-input-group prepend="对齐方式" class="mb-2 mr-sm-2 mb-sm-0">
                     <b-form-select v-model="getCenter">
                       <option value="left">左</option>
                       <option value="center">中</option>
                       <option value="right">右</option>
                     </b-form-select>
                  </b-input-group>
                  <b-input-group prepend="自动翻页" class="mb-2 mr-sm-2 mb-sm-0">
                     <b-form-select v-model="getVer">
                       <option value="v1">否</option>
                       <option value="v2">是</option>
                     </b-form-select>
                  </b-input-group>
                  <b-input-group prepend="字体大小" class="mb-2 mr-sm-2 mb-sm-0">
                     <b-form-select v-model="getSize">
                       <option value="14px">14px</option>
                       <option value="18px">18px</option>
                       <option value="22px">22px</option>
                       <option value="26px">26px</option>
                       <option value="30px">30px</option>
                       <option value="34px">34px</option>
                       <option value="38px">38px</option>
                       <option value="42px">42px</option>
                     </b-form-select>
                  </b-input-group>
                <b-input-group prepend="字体颜色" class="mb-2 mr-sm-2 mb-sm-0">
                  <el-color-picker v-model="getColor"></el-color-picker>
                </b-input-group>
                <b-input-group prepend="粗体" class="mb-2 mr-sm-2 mb-sm-0">
                     <b-form-select v-model="getBold">
                       <option value="false">否</option>
                       <option value="true">是</option>
                     </b-form-select>
                </b-input-group>
                </template>
              </b-form>
            </div>
          </div>
        </div>
        {{currentRes.speed}}
        <div v-show="showRich" >
          使用shift+enter换行，使用enter分页
        </div>
        <div v-show="showRich" >
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<style scoped>
#editor{
  width:auto !important;
  height: auto !important
}
.mb-2 {
  width: 45% !important;
}
.vdr {
  border: 1px dashed red !important;
}
#progC {
  background-color: black;
  position: relative;
}
#action_notify i {
  cursor: pointer;
}
.my-class-content {
  width: 100%;
  height: 100%;
  background-color: green;
  color: white;
  line-height: 50px;
  text-align: center;
  border: 1px solid;
}
.my-class {
  height: 100%;
  background-color: green;
}
.timeax_u {
  border: 1px solid blue;
  margin-top: 5px;
  cursor: pointer;
}
.timeax_a {
  border: 1px solid red !important;
}
.timeax_s {
  border: 1px solid yellow;
}
.ksrow div {
  height: 100%;
}
#timeaxis {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.active.draggable > .my-class-content {
  background-color: rgb(42, 28, 230);
}
</style>

<script>
import {BaseUrl} from "@/assets/utils/config"
import timeaxis from "@/components/timeaxis.vue";
import xxsensor from "@/components/xxsensor.vue";
import xxscroll from "@/components/xxscroll";
import { GroupArray, GetResName, GetGuid,GetQueryString } from "@/assets/utils/datef";
export default {
  components: { timeaxis, xxsensor, xxscroll },
  computed: {
    getColor:{
      get(){
        var color=GetQueryString(this.currentRes.sUrl,"color");
        return "#"+GetQueryString(this.currentRes.sUrl,color)||"#ffffffff";
      },
      set(val){
        if(val){
          val=val.substring(1);
        }else{
          val="#ffffffff"
        }
        var va=GetQueryString(this.currentRes.sUrl,"color");
        if(va){
          this.currentRes.sUrl=this.currentRes.sUrl.replace(va,val);
        }else{
          if(this.currentRes.sUrl.indexOf("?")!=-1){
            this.currentRes.sUrl=`${this.currentRes.sUrl}&color=${val}`;
          }else{
            this.currentRes.sUrl=`${this.currentRes.sUrl}?color=${val}`;
          }
        }
      }
    },
    getBold:{
      get(){
        return GetQueryString(this.currentRes.sUrl,"bold")||"false";
      },
      set(val){
        var va=GetQueryString(this.currentRes.sUrl,"bold");
        if(va){
          this.currentRes.sUrl=this.currentRes.sUrl.replace(va,val);
        }else{
          if(this.currentRes.sUrl.indexOf("?")!=-1){
            this.currentRes.sUrl=`${this.currentRes.sUrl}&bold=${val}`;
          }else{
            this.currentRes.sUrl=`${this.currentRes.sUrl}?bold=${val}`;
          }
        }
      }
    },
    getSize:{
      get(){
        return GetQueryString(this.currentRes.sUrl,"size")||"14px";
      },
      set(val){
        var va=GetQueryString(this.currentRes.sUrl,"size");
        if(va){
          this.currentRes.sUrl=this.currentRes.sUrl.replace(va,val);
        }else{
          if(this.currentRes.sUrl.indexOf("?")!=-1){
            this.currentRes.sUrl=`${this.currentRes.sUrl}&size=${val}`;
          }else{
            this.currentRes.sUrl=`${this.currentRes.sUrl}?size=${val}`;
          }
        }
      }
    },
    getVer:{
      get(){
        return GetQueryString(this.currentRes.sUrl,"ver")||"v1";
      },
      set(val){
        var va=GetQueryString(this.currentRes.sUrl,"ver");
        if(va){
          this.currentRes.sUrl=this.currentRes.sUrl.replace(va,val);
        }else{
          if(this.currentRes.sUrl.indexOf("?")!=-1){
            this.currentRes.sUrl=`${this.currentRes.sUrl}&ver=${val}`;
          }else{
            this.currentRes.sUrl=`${this.currentRes.sUrl}?ver=${val}`;
          }
        }
      }
    },
    getCenter:{
      get(){
        return GetQueryString(this.currentRes.sUrl,"align")||"left";
      },
      set(val){
        var va=GetQueryString(this.currentRes.sUrl,"align");
        if(va){
          this.currentRes.sUrl=this.currentRes.sUrl.replace(va,val);
        }else{
          if(this.currentRes.sUrl.indexOf("?")!=-1){
            this.currentRes.sUrl=`${this.currentRes.sUrl}&align=${val}`;
          }else{
            this.currentRes.sUrl=`${this.currentRes.sUrl}?align=${val}`;
          }
        }
      }
    },
    getHtml: {
      get() {
        if(this.$refs.editor){
        this.$refs.editor.setHtml(this.currentRes.html);

        }
        return this.currentRes.html;
      },
      set(val) {
        val = this.$refs.editor.getHtml();
        this.currentRes.html=val;
        // this.prog.layers.forEach(x=>{
        //   if(x.id==this.currentLay.id){
        //     x.sources.forEach(y=>{
        //       if(y.id==this.currentRes.id){
        //         y.html=val;
        //       }
        //     })
        //   }
        // })
        // this.currentRes.html = val;
      }
    },
    getLineHeight:{
      get(){
        return this.currentRes.lineHeight*1;
      },
      set(val){
        this.currentRes.lineHeight=val*1;
      }
    },
    getViewStart: {
      get() {
        return this.currentRes.playTime * 1;
      },
      set(val) {
        this.currentRes.playTime = val * 1;
        this.currentRes.viewStart = val * 10;
      }
    },

    getViewEnd: {
      get() {
        return this.currentRes.timeSpan * 1;
      },
      set(val) {
        this.currentRes.timeSpan = val * 1;
        this.currentRes.viewEnd = val * 10;
      }
    },

    getLeft: {
      get() {
        return this.currentRes.left * 1;
      },
      set(val) {
        this.currentRes.left = val * 1;
      }
    },

    getTop: {
      get() {
        return this.currentRes.top * 1;
      },
      set(val) {
        this.currentRes.top = val * 1;
      }
    },

    getWidth: {
      get() {
        return this.currentRes.width * 1;
      },
      set(val) {
        this.currentRes.width = val * 1;
      }
    },

    getHeight: {
      get() {
        return this.currentRes.height * 1;
      },
      set(val) {
        this.currentRes.height = val * 1;
      }
    }
  },
  data: function() {
    return {
      showRich: false,
      text: "test",
      loading: {},
      res: [],
      timeStyle: {
        width: "100%",
        height: "50px",
        position: "relative"
      },
      lineStyle: {
        position: "absolute",
        width: "2px",
        height: "100%",
        left: "0",
        border: "2px solid red",
        "z-index": 200
      },
      progCStyle: {
        width: "0",
        height: "0"
      },
      prog: {},
      currentLay: {},
      currentRes: {html:""},
      dragData: {},
      currentTime: 0,
      currentProg: [],
      currentId: null,
      intAlimate: [
        { text: "无", value: "None" },
        { text: "淡入", value: "ALPHA_IN" },
        { text: "连续左移", value: "MOVING_LEFT" },
        { text: "连续右移", value: "MOVING_RIGHT" },
        { text: "连续上移", value: "MOVING_TOP" },
        { text: "连续下移", value: "MOVING_BOTTOM" },
        { text: "放大", value: "ZOOM_IN" },
        { text: "左下角放大", value: "ZOOM_IN_LEFT_BOTTOM" },
        { text: "左上角放大", value: "ZOOM_IN_LEFT_TOP" },
        { text: "右上角放大", value: "ZOOM_IN_RIGHT_TOP" },
        { text: "右下角放大", value: "ZOOM_IN_RIGHT_BOTTOM" },
        { text: "向右旋转", value: "ROTATE_RIGHT" },
        { text: "向左旋转", value: "ROTATE_LEFT" }
      ],
      outAlimate: [
        { text: "无", value: "None" },
        { text: "淡出", value: "ALPHA_OUT" },
        { text: "连续左移", value: "MOVING_LEFT" },
        { text: "连续右移", value: "MOVING_RIGHT" },
        { text: "连续上移", value: "MOVING_TOP" },
        { text: "连续下移", value: "MOVING_BOTTOM" },
        { text: "缩小", value: "ZOOM_OUT" },
        { text: "左下角缩小", value: "ZOOM_OUT_LEFT_BOTTOM" },
        { text: "左上角缩小", value: "ZOOM_OUT_LEFT_TOP" },
        { text: "右上角缩小", value: "ZOOM_OUT_RIGHT_TOP" },
        { text: "右下角缩小", value: "ZOOM_OUT_RIGHT_BOTTOM" },
        { text: "向右旋转", value: "ROTATE_RIGHT" },
        { text: "向左旋转", value: "ROTATE_LEFT" }
      ],
      editorMenu:[
        'source',	// 源码模式
        '|',
        'bold',	// 粗体
        'underline',	// 下划线
        'italic',	// 斜体
        'strikethrough',	// 中线
        'eraser',	// 清空格式
        'forecolor',	// 文字颜色
        'bgcolor',	// 背景颜色
        '|',
        'fontfamily',	// 字体
        'fontsize',	// 字号
        'alignleft',	// 左对齐
        'aligncenter',	// 居中
        'alignright',	// 右对齐
        '|',
        'undo',	// 撤销
        'redo',	// 重做
        'fullscreen'	// 全屏
    ]
    };
  },
  mounted: function() {
    this.getRes();
    this.getProg();
  },
  methods: {
    save(){
    },
    timeaClick(event, id) {
      var x = event.offsetX;
      if (event.target.id != id) {
        x = this.currentRes.viewStart + x;
      } else {
        if (this.currentLay.id != id) {
          this.prog.layers.forEach(x => {
            if (x.id != id) {
              x.active = false;
            } else {
              this.currentLay = x;
              x.active = true;
              this.currentRes = {};
            }
          });
        }
      }
      var count = x / 100;
      var unit = 1;
      var value = parseInt(count * unit * 10);
      this.timeClick(value);
    },
    sortUp() {
      var index = this.currentLay.sort;
      var max = this.getLayerSort() - 1;
      if (max < 0) {
        max = 0;
      }
      if (max == this.currentLay.sort) {
        return;
      } else {
        this.prog.layers.forEach(x => {
          if (x.id == this.currentLay.id) {
            x.sort = index + 1;
          } else {
            if (x.sort - 1 == index) {
              x.sort = index;
            }
          }
        });
        this.prog.layers = this.prog.layers.sort((a, b) => {
          return b.sort - a.sort;
        });
      }
    },
    sortDown() {
      var index = this.currentLay.sort;
      if (0 == this.currentLay.sort) {
        return;
      } else {
        this.prog.layers.forEach(x => {
          if (x.id == this.currentLay.id) {
            x.sort = index - 1;
          } else {
            if (x.sort + 1 == index) {
              x.sort = index;
            }
          }
        });
        this.prog.layers = this.prog.layers.sort((a, b) => {
          return b.sort - a.sort;
        });
      }
    },
    getNewPosition(id) {
      var lay = this.prog.layers.find(x => x.id == id);
      if (lay && lay.sources && lay.sources.sort && lay.sources.length != 0) {
        var sort = lay.sources.sort((a, b) => {
          return b.viewEnd + b.viewStart - a.viewEnd - a.viewStart;
        });
        return sort[0].viewEnd + sort[0].viewStart;
      } else {
        return 0;
      }
    },
    addRes(data, id) {
      var obj = {
        tt: data.Type,
        active: false,
        id: GetGuid(),
        name: data.Name,
        _type: data.TypeXX,
        lineHeight: 1.4,
        playTime: 0,
        timeSpan: 20,
        left: 0,
        top: 0,
        width: 100,
        height: 100,
        entryEffect: "None",
        exitEffect: "None",
        entryEffectTimeSpan: 0,
        exitEffectTimeSpan: 0,
      };
      if (data.Type == 1 || data.Type == 2) {
        obj.oldFilePath = data.Content;
        obj.url = data.Content;
        obj.size=data.Size;
        obj.md5=data.MD5;
        var array=data.Content.split(".");
        obj.fileExt=array[array.length-1];
        if(data.Type==2){
          obj.mime="video/mp4";
        }else{
          obj.mime="image/jpeg";
        }
      }
      if (data.Type == 5) {
        obj.sUrl = `${BaseUrl}/home/text?sid=${data.Sid}&ver=v1`;
        obj.sInterval = 10;
        obj.html = "%s";
        //翻页速度
        obj.speed=5;
        obj.center=true;
        obj.backgroundColor="rgba(0,0,0,1)"
      }
      if (data.Type == 6) {
        obj.url = data.Content;
      }
      if (data.Type == 0) {
        obj.html = "<p>请输入文本内容</p>";
        obj.backgroundColor="rgba(0,0,0,1)"
        //翻页速度
        obj.speed=5;
        obj.center=true;
        obj.backgroundColor="rgba(0,0,0,1)"
      }
      if (data.Type == 3) {
        obj.html = "请输入滚动文本内容";
        obj.speed = 10;
      }
      var start = this.getNewPosition(id);
      obj.viewStart = start;
      obj.viewEnd = 200;
      obj.playTime = start / 10;
      this.prog.layers.forEach(x => {
        if (x.id == id) {
          x.sources.push(obj);
        }
      });
      this.getCurrentProg();
    },
    drop: function(event, id) {
      event.preventDefault();
      this.addRes(this.dragData, id);
    },
    allowDrop() {
      event.preventDefault();
    },
    dragStart(data) {
      this.dragData = data;
    },
    getRes() {
      let obj = {
        Index: 0,
        Count: 1000
      };
      this.$ksajax
        .ksPostPromise("/api/resource/GetLedResources", obj, true)
        .then(data => {
          if (data.Items && data.Items.sort) {
            data.Items = data.Items.filter(
              x => x.Type == 1 || x.Type == 2 || x.Type == 5 || x.Type == 6
            );
            var items = data.Items.sort((a, b) => {
              a.Type - b.Type;
            });
            items.forEach(x => {
              var obj = GetResName(x.Type);
              x.TypeName = this.$t(obj.text);
              x.TypeXX = obj.type;
            });
            var res = GroupArray("TypeName", data.Items);
            res.push({
              group: "文本",
              data: [
                {
                  Sid: GetGuid(),
                  Type: 0,
                  TypeName: "文本",
                  Name: "文本",
                  TypeXX: "MultiLineText"
                }
              ]
            });
            res.push({
              group: "滚动文本",
              data: [
                {
                  Sid: GetGuid(),
                  Type: 3,
                  TypeName: "滚动文本",
                  Name: "滚动文本",
                  TypeXX: "SingleLineTextV2"
                }
              ]
            });
            res.forEach(x => {
              x.id = x.data[0].Sid;
            });
            this.res = res;
          }
        });
    },
    calcPosition() {
      var currentRes = this.getData(this.currentRes.id);
      currentRes.viewEnd -= currentRes.viewEnd % 10;
      currentRes.viewStart -= currentRes.viewStart % 10;
      this.prog.layers.forEach(y => {
        if (y.id == this.currentLay.id) {
          if (y.sources && y.sources.forEach) {
            y.sources.forEach(x => {
              if (x.id != currentRes.id) {
                if (
                  currentRes.viewStart < x.viewStart &&
                  currentRes.viewStart < x.viewEnd + x.viewStart &&
                  currentRes.viewEnd + currentRes.viewStart > x.viewStart
                ) {
                  if (
                    currentRes.viewEnd + currentRes.viewStart >
                    x.viewEnd + x.viewStart
                  ) {
                    //删除
                    x.viewEnd = 1;
                  } else {
                    x.viewEnd =
                      x.viewEnd +
                      x.viewStart -
                      currentRes.viewStart -
                      currentRes.viewEnd;
                    x.viewStart = currentRes.viewEnd + currentRes.viewStart;
                  }
                }
                if (
                  currentRes.viewStart > x.viewStart &&
                  currentRes.viewStart < x.viewStart + x.viewEnd
                ) {
                  if (
                    currentRes.viewEnd + currentRes.viewStart <
                    x.viewEnd + x.viewStart
                  ) {
                    x.viewEnd = currentRes.viewStart - x.viewStart;
                  } else {
                    x.viewEnd = currentRes.viewStart - x.viewStart;
                  }
                }
              }
            });
          }
        }
      });
      this.prog.layers.forEach(x => {
        var array = [];
        x.sources.forEach(y => {
          if (y.viewEnd >= 10) {
            array.push(y);
          }
          //计算开始时间和时长
          y.playTime = y.viewStart / 10;
          y.timeSpan = y.viewEnd / 10;
        });
        x.sources = array;
      });
      this.getCurrentProg();
      //编辑资源开始时间小于当前资源开始时间并且小于当前资源结束时间并且编辑资源结束时间大于当前开始时间
      //编辑资源结束时间大于当前资源结束时间，全覆盖，删除
      //编辑资源结束时间小于当前资源结束时间，取当前节目开始时间-编辑资源结束时间
      //编辑资源开始时间大于当前资源开始时间
      //编辑资源结束时间小于当前资源结束时间，取当前节目开始时间-编辑资源开始时间
      //编辑资源结束时间大于当前资源结束时间，取编辑资源开始时间-当前节目结束时间
    },
    onRResizing(l, t, w, h) {
      this.currentRes.width = w;
      this.currentRes.height = h;
      this.currentRes.left = l;
      this.currentRes.top = t;
      // this.updateDataRes(this.currentRes.id, l,t,w,h);
    },
    onRDrag(l, t) {
      this.currentRes.left = l;
      this.currentRes.top = t;
    },
    onResizing(l, t, w, h) {
      t, h;
      this.updateData(this.currentRes.id, l, w);
    },
    onDrag(l, t) {
      t;
      this.updateData(this.currentRes.id, l, null);
    },
    resizestop() {
      this.calcPosition();
    },
    dragstop() {
      this.calcPosition();
    },
    onDeActive(id) {
      this.prog.layers.forEach(x => {
        if (x.sources && x.sources.forEach) {
          x.sources.forEach(y => {
            if (y.id == id) {
              y.viewTop = 0;
              y.active = false;
              this.currentId = null;
            }
          });
        }
      });
    },
    onActive(id) {
      this.prog.layers.forEach(x => {
        if (x.sources && x.sources.forEach) {
          x.sources.forEach(y => {
            if (y.id == id) {
              y.viewTop = 100;
              y.active = true;
              x.active = true;
              this.currentId = y.id;
              this.currentLay = x;
              this.currentRes = y;
              if (y.tt == 0 || y.tt == 3) {
                this.showRich = true;
              } else {
                this.showRich = false;
              }
            }
          });
        }
      });
      this.prog.layers.forEach(x => {
        if (x.id == this.currentLay.id) {
          x.active = true;
        } else {
          x.active = false;
        }
      });
    },
    updateData(id, xd, width) {
      this.prog.layers.forEach(x => {
        if (x.sources && x.sources.forEach) {
          x.sources.forEach(y => {
            if (y.id == id) {
              if (width || width == 0) {
                y.viewEnd = width;
              }
              if (xd || xd == 0) {
                y.viewStart = xd;
              }
            }
          });
        }
      });
    },
    updateDataRes(id, l, t, w, h) {
      this.prog.layers.forEach(x => {
        if (x.sources && x.sources.forEach) {
          x.sources.forEach(y => {
            if (y.id == id) {
              y.left = l;
              y.top = t;
              y.width = w;
              y.height = h;
            }
          });
        }
      });
    },
    getData(id) {
      var obj = null;
      this.prog.layers.forEach(x => {
        if (x.sources && x.sources.forEach) {
          x.sources.forEach(y => {
            if (y.id == id) {
              obj = y;
            }
          });
        }
      });
      return obj;
    },
    scrolling(data) {
      this.$refs.timeax.updateWidth(data.srcElement.scrollLeft) + "px";
    },
    timeUpdated(width) {
      this.timeStyle.width = width + "px";
    },
    timeClick(value) {
      this.lineStyle.left = value * 10 + "px";
      this.currentTime = value;
     // var data = this.getCurrentProg();
    },
    getCurrentProg() {
      // var progs=[];
      var start = this.currentTime * 10;
      this.prog.layers.forEach(x => {
        x.sources.forEach(y => {
          if (y.viewStart <= start && y.viewStart + y.viewEnd > start) {
            // var obj={
            //   layer:x,
            //   source:y
            // }
            // progs.push(obj);
            y.current = true; 
          } else {
            y.current = false;
          }
        });
      });
      // this.currentProg=progs;
      // return progs;
    },
    delRes() {
      for (var i = 0; i < this.prog.layers.length; i++) {
        if (this.prog.layers[i].id == this.currentLay.id) {
          var lay=this.prog.layers[i].sources;
          var sou=lay.filter(x=>x.id!=this.currentRes.id);
          this.prog.layers[i].sources=sou;
        }
      }
    },
    delLay() {
      this.prog.layers = this.prog.layers
        .filter(x => x.id != this.currentLay.id)
        .sort((a, b) => {
          return b.sort - a.sort;
        });
    },
    addLayer() {
      var obj = {
        repeat: true,
        id: GetGuid(),
        sort: this.getLayerSort(),
        active: false,
        sources: []
      };
      this.prog.layers.push(obj);
      this.prog.layers = this.prog.layers.sort((a, b) => {
        return b.sort - a.sort;
      });
    },
    getLayerSort() {
      var array = this.prog.layers.sort((a, b) => {
        return b.sort - a.sort;
      });
      if (array && array[0]) {
        return array[0].sort + 1;
      } else {
        return 0;
      }
    },
    getProg() {
      var obj = {
        totalSize: 1364316,
        name: "韶关全民健身广场广告节目",
        width: 260,
        height: 572,
        layers: [
          {
            active: false,
            repeat: true,
            id: GetGuid(),
            sort: 0,
            sources: []
          }
        ]
      };
      this.progCStyle.height = `${obj.height}px`;
      this.progCStyle.width = `${obj.width}px`;
      this.prog = obj;
      this.timeClick(0);
      return obj;
    }
  }
};
</script>