<template>
  <div class v-loading="loading" style="position: relative; height: 100%">
    <div style="position: absolute; right: 20px; top: 10px; z-index: 999">
      <i
        class="fa fa-table fa-2x"
        style="cursor: pointer; color: 8495c2"
        @click="tabled"
      ></i>
    </div>
    <ksmap
      v-show="map"
      ref="map"
      width="100%"
      :showCenter="false"
      height="100%"
      v-bind:lat="0"
      v-bind:lng="0"
      @mapclick="mapClick"
      v-bind:zoom="25"
      @iconClick="iconClick"
    ></ksmap>
    <div v-show="!map">
      <el-table
        class="kstable"
        :data="items"
        stripe
        style="width: 100%"
        highlight-current-row
        :empty-text="$t('暂无数据')"
      >
        <el-table-column
          prop="StandName"
          :label="$t('设备名称')"
        ></el-table-column>
        <el-table-column prop="Serial" :label="$t('序号')"></el-table-column>
        <el-table-column
          prop="SensorVoltage"
          :label="$t('电压')"
        ></el-table-column>
        <el-table-column prop="Rssi" :label="$t('信号')"></el-table-column>
        <el-table-column
          prop="AlarmAngle"
          :label="$t('告警角度')"
        ></el-table-column>
        <el-table-column
          prop="RecoverAngle"
          :label="$t('安全角度')"
        ></el-table-column>
        <el-table-column prop="Angle" :label="$t('当前角度')"></el-table-column>
        <el-table-column
          prop="HeartbeatInterval"
          :label="$t('心跳周期')"
        ></el-table-column>
        <el-table-column :label="$t('详情')">
          <template slot-scope="scope">
            <router-link
              :to="'/admin/stand/standDetail?sid=' + scope.row.Sid"
              >{{ $t("详情") }}</router-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="新增灯具" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="formLamp" label-width="80px">
        <el-form-item label="地址" :required="true" prop="Address">
          <el-input v-model="formLamp.Address"></el-input>
        </el-form-item>
        <el-form-item label="类型" :required="true" prop="TypeSid">
          <el-select v-model="formLamp.TypeSid">
            <el-option
              v-for="item in ltypes"
              :key="item.value"
              :value="item.value"
              :label="item.text"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="桩号" :required="true" prop="ZNumber">
          <el-input v-model="formLamp.ZNumber"></el-input>
        </el-form-item>
        <el-form-item label="编号" :required="true" prop="ZID">
          <el-input type="number" v-model="formLamp.ZID"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addLamp">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ksmap from "@/components/map";
import box from "@/components/box.vue";
import { BaseUrl } from "../../../assets/utils/config";
export default {
  components: { ksmap, box },
  data: function () {
    return {
      selected: {},
      loading: true,
      map: true,
      items: [],
      options: [
        { text: "街道1", value: "radio1" },
        { text: "街道2", value: "radio1" },
        { text: "街道3", value: "radio1" },
      ],
      formLamp: {
        Serial: "",
        TypeSid: "",
        Lat: 0,
        Lng: 0,
        Address: "",
        Description: "",
        ZID: 1,
        ZNumber: "",
        StandName:"",
        Sid:null,
      },
      ltypes: this.$store.state.lStandType,
      dialogVisible: false,
    };
  },
  mounted: function () {
    this.select();
    console.log(this.ltypes);
  },
  methods: {
    mapClick(e) {
      this.formLamp.Lat = e.lnglat.getLat();
      this.formLamp.Lng = e.lnglat.getLng();
      this.dialogVisible = true;
    },
    iconClick(sid){
      var obj=this.items.find(x=>x.Sid==sid);
      this.formLamp.Sid=obj.Sid;
       this.formLamp.Serial= obj.Serial;
        this.formLamp.TypeSid= obj.TypeSid;
        this.formLamp.Lat= obj.Lat;
        this.formLamp.Lng= obj.Lng;
        this.formLamp.Address= obj.Address;
        this.formLamp.Description= obj.Description;
        this.formLamp.ZID= obj.ZID;
        this.formLamp.ZNumber=obj.ZNumber;
        this.formLamp.StandName=obj.StandName;
      this.dialogVisible = true;
    },
    addLamp() {
      this.loading=true;
      this.$refs.form.validate().then(() => {
        this.formLamp.Sid=null;
        this.formLamp.Serial = `${this.formLamp.ZNumber}-${this.formLamp.ZID}`;
        var type=this.ltypes.find(x=>x.value==this.formLamp.TypeSid)||{text:"未知灯具"};
        this.formLamp.StandName=this.formLamp.Description=`${type.text}(${this.formLamp.Serial})`;
         this.$ksajax
        .ksPostPromise("/api/Asset/AddLStand", this.formLamp, true).then(()=>{
          this.loading=false;
          this.dialogVisible=false;
          this.select();
        }).catch(err=>{
          this.$throw(err);
        })
      });
    },
    tabled() {
      this.map = !this.map;
      if (this.map) {
        this.$refs.map.setFilter(0);
      }
    },
    select() {
      let obj = {
        Index: 0,
        Count: 100000,
      };this.$refs.map.clear();
      this.$ksajax
        .ksPostPromise("/api/asset/GetLampStands", obj, true)
        .then((data) => {
          this.loading = false;
          var device = [];
          this.items = data.Items;
          data.Items.forEach((x) => {
            var icon=`${BaseUrl}/img/new/eded.png`;
            if(x.LampStandType&&x.LampStandType.Pic){
              icon=x.LampStandType.Pic;
            }
            device.push({
              lat: x.Lat,
              lng: x.Lng,
              icon: icon,
              title: x.StandName,
              sid: x.Sid,
            });
          });
          this.$refs.map.addDevice(device, 0,{w:25,h:35});
        })
        .catch((err) => {
          this.$throw(err, this);
          this.loading = false;
        });
    },
  },
};
</script>
