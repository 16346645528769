<template>
    <div class="info_soft">
        <div class="container" style="padding:0px; max-width:100%;">
            <div v-for="t in this.items" :key="t.name">
                <div class="soft_title">
                    {{t.name}}
                    <!--<hr />-->
                </div>
                <b-img fluid class="w-100" :src="t.pic"></b-img>
            </div>
        </div>
        <!--<slider animation="fade" :speed="300" style="height:100%; min-height:866px; ">
    <slider-item v-for="t in this.items" :key="t.name" @click="hello">
       <p style="line-height:280px; font-size:5rem; text-align:center;">Page {{index+1}}</p>
     <b-img :src="t.pic" style="width:100%; height:100%;"></b-img>
     </slider-item>
     <div slot="loading">custom loading ...</div>
     </slider>-->
    </div>
</template>
<style>
    .soft_title {
        text-align: center;
        font:51px/60px a;
        font-weight:400;
        color:#fff;
    }
    .info_soft { 
        padding:63px 0 0 0 !important;
        background-color:#000;
    }
</style>

<script>
    import { Slider, SliderItem } from 'vue-easy-slider';
    export default {
        components: {
            Slider, SliderItem,
        },
        data: function () {
            return {
                items: [
                    {
                        pic: require("@/assets/img/home.gif"),
                        name: "智慧城市平台主界面"
                    },
                    {
                        pic: require("@/assets/img/cw.gif"),
                        name: "智慧车位管理界面"
                    },
                    {
                        pic: require("@/assets/img/water.gif"),
                        name: "智慧水位管理界面"
                    },

                ]
            }
        },
        methods: {
            hello() {
            }
        },
        mounted: function () {
            this.$nextTick(() => {
                // var ele = document.getElementById("info_menu");
                // if (ele) {
                //     ele.style.backgroundColor = "#333";
                // }
                // document.getElementById("info_footer").style.position = "relative";
            })
        }
    }
</script>
