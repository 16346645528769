<template>
  <div class="detailview">
    <div class="detailview_1" style="width: 30%">
      <div>
        <box style="position: relative">
          <bar ref="yearCount" v-loading="yearLoading"></bar>
        </box>
      </div>
    </div>
    <div class="detailview_1" style="width: 40%">
      <div>
        <box id="canvas_box" v-loading="loading">
          <netvideo
            :playdata="playdata"
            ref="camininet"
            v-if="!IsISC"
          ></netvideo>
          <natpay
            ref="camininat"
            v-else
            :count="count"
            :showbar="true"
            v-on:current="current"
          ></natpay>
        </box>
      </div>
    </div>
    <div class="detailview_1" style="width: 30%">
      <div>
        <box v-loading="loading">
          <div class="realtime" ref="tableScroll">
            <div
              class="realtimeFace ksflex"
              v-for="(item, index) in datas"
              :key="index"
            >
              <div class="realtimeFace_header">
                <img :src="item.Law.HeaderPic" />
              </div>
              <div class="realtimeFace_feature clearfix">
                <span>性别：{{ item.FaceInfo.Sex }}</span>
                <span>是否戴眼镜：{{ item.FaceInfo.EyeGlass }}</span>
                <span>是否戴口罩：{{ item.FaceInfo.Mask }}</span>
                <span>表情：{{ item.FaceInfo.FaceExpression }}</span>
                <span>是否微笑：{{ item.FaceInfo.Smile }}</span>
                <span>是否戴帽子：{{ item.FaceInfo.Hat }}</span>
                <span>年纪：{{ item.FaceInfo.Age }}</span>
                <span>年纪属性：{{ item.FaceInfo.AgeGroup }}</span>
              </div>
            </div>
          </div>
        </box>
      </div>
    </div>
    <div class="detailview_2" style="width: 30%">
      <div>
        <box>
          <pie :radius="true" :size="'small'" ref="sexCount" v-loading="sexLoading"></pie>
        </box>
      </div>
    </div>
    <div class="detailview_2" style="width: 40%">
      <div>
        <box> <linebar ref="personCount" :smooth="true" v-loading="countLoading"></linebar></box>
      </div>
    </div>
    <div class="detailview_2" style="width: 30%">
      <div>
        <box>
          <pie :radius="true" :size="'small'" ref="maskCount" v-loading="maskLoading"></pie>
        </box>
      </div>
    </div>
  </div>
</template>
<style scoped>
.realtime {
  height: 100%;
  overflow: auto;
}
.realtimeFace {
  height: 120px;
  padding: 5px;
  border: 1px solid #fff;
}
.realtimeFace_header {
  height: 100%;
  width: 80px;
}
.realtimeFace_header > img {
  height: 100%;
  width: auto;
  max-width: 100%;
}
.realtimeFace_feature {
  flex: 1;
}
.realtimeFace_feature > span {
  border: 1px solid #3274b8;
  padding: 2px;
  margin-left: 5px;
  margin-bottom: 5px;
  color: white;
  display: block;
  float: left;
  font-size: 0.8rem;
}
</style>
<script>
import netvideo from "@/components/netvideo";
import natpay from "@/components/natpay";
import box from "@/components/box1.vue";
import { colorArr } from "@/assets/utils/echartUtil";
import pie from "@/components/chart/pie.vue";
import bar from "@/components/chart/bar.vue";
import linebar from "@/components/chart/line.vue";
import { getAgeByCode, getExpByCode } from "@/assets/utils/hikUtil";
export default {
  components: { box, pie, bar, linebar, netvideo, natpay },
  data() {
    return {
      loading: false,
      sid: "",
      chartData: {},
      IsISC: false,
      count: 1,
      playdata: {},
      datas: [],
      playDevs: [],
      yearLoading: false,
      maskLoading: false,
      sexLoading: false,
      countLoading: false,
    };
  },
  mounted() {
    this.sid = this.$route.query.sid;
    this.IsISC = this.$route.query.nat;
    this.select();
    this.initYearData();
    this.initMaskCountData();
    this.initSexCountData();
    this.initPersonCountData();
  },
  watch: {
    "$store.state.carPoliceHubChange": function () {
      try {
        var data = this.$store.state.carPoliceHub;
        if (data.RouterSid.indexOf(this.sid) != -1) {
          this.toUpperCase(data.Law);
          this.toUpperCase(data.Law.FaceInfo);
          this.toUpperCase(data.Law.Law);
          if (data.Law.FaceInfo) {
            this.chartData.Year.forEach((x) => {
              if (x.Type == data.Law.FaceInfo.AgeGroup) {
                x.Count += 1;
              }
            });
            if (data.Law.FaceInfo.Mask == 1) {
              this.chartData.Mask.NoMast += 1;
            } else {
              this.chartData.Mask.Mast += 1;
            }
            if (data.Law.FaceInfo.Sex == 1) {
              this.chartData.Sex.Man += 1;
            } else {
              this.chartData.Sex.Woman += 1;
            }
            this.chartData.Law7[this.chartData.Law7.length - 1].count += 1;
          }
          this.freshChart();
          var obj = this.pariseData(data.Law);
          this.datas.push(obj);
          this.$nextTick(() => {
            this.scrollTable();
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  methods: {
    scrollTable() {
      var ele = this.$refs.tableScroll;
      ele.scrollTop = ele.scrollHeight;
    },
    pariseData(data) {
      if (!data.FaceInfo) {
        data.FaceInfo = {
          Age: -1,
          AgeDeviation: 0,
          AgeGroup: -1,
          EyeGlass: -1,
          FaceExpression: -1,
          Hat: -1,
          Mask: -1,
          Sex: -1,
          Sid: -1,
          Smile: -1,
        };
      }
      data.FaceInfo.Age =
        data.FaceInfo.Age == -1 ? "未知" : data.FaceInfo.Age + "岁";
      data.FaceInfo.AgeGroup = getAgeByCode(data.FaceInfo.AgeGroup);
      data.FaceInfo.EyeGlass =
        data.FaceInfo.EyeGlass == 1
          ? "未戴眼镜"
          : data.FaceInfo.EyeGlass == 2
          ? "戴眼镜"
          : "未知";
      data.FaceInfo.FaceExpression = getExpByCode(data.FaceInfo.FaceExpression);
      data.FaceInfo.Hat =
        data.FaceInfo.Hat == 1
          ? "未戴帽子"
          : data.FaceInfo.Hat == 2
          ? "戴帽子"
          : "未知";
      data.FaceInfo.Mask =
        data.FaceInfo.Mask == 1
          ? "未戴口罩"
          : data.FaceInfo.Mask == 2
          ? "戴口罩"
          : "未知";
      data.FaceInfo.Sex =
        data.FaceInfo.Sex == 1 ? "男" : data.FaceInfo.Sex == 2 ? "女" : "未知";
      data.FaceInfo.Smile =
        data.FaceInfo.Smile == 1
          ? "是"
          : data.FaceInfo.Smile == 2
          ? "否"
          : "未知";
      return data;
    },
    toUpperCase(jsonObj) {
      if (typeof jsonObj == "object") {
        for (var key in jsonObj) {
          jsonObj[key.substring(0, 1).toUpperCase() + key.substring(1)] =
            jsonObj[key];
          delete jsonObj[key];
        }
        return jsonObj;
      }
      return jsonObj;
    },
    freshChart() {
      this.initYear();
      this.initMaskCount();
      this.initSexCount();
      this.initPersonCount();
    },
    initYear() {
      var title = {
        show: true,
        text: "过往人员年龄段分析",
        left: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      var data = [
        {
          name: "数量",
          data: [],
        },
      ];
      var xdata = [];
      this.chartData.Year.forEach((x) => {
        data[0].data.push(x.Count);
        xdata.push(getAgeByCode(x.Type));
      });
      var legend = {
        orient: "vertical",
        left: 10,
        data: xdata,
        bottom: 5,
        show: true,
      };
      this.$refs.yearCount.setAndInit(
        data,
        legend,
        [colorArr[2]],
        null,
        xdata,
        title,
        xdata,
        "30%"
      );
    },
    initYearData() {
      this.yearLoading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetFaceRouterYearDetail", this.sid, true)
        .then((data1) => {
          this.yearLoading = false;
          this.chartData.Year = data1;
          this.initYear();
        });
    },
    initMaskCountData() {
      this.maskLoading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetFaceRouterMaskDetail", this.sid, true)
        .then((data1) => {
          this.maskLoading = false;
          this.chartData.Mask = data1;
          this.initMaskCount();
        });
    },
    initPersonCountData(){
      this.countLoading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetFaceRouterCountDetail", this.sid, true)
        .then((data1) => {
          this.countLoading = false;
          this.chartData.Law7 = data1;
          this.initPersonCount();
        });
    },
    initSexCountData(){
      this.sexLoading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetFaceRouterSexDetail", this.sid, true)
        .then((data1) => {
          this.sexLoading = false;
          this.chartData.Sex = data1;
          this.initSexCount();
        });
    },
    initMaskCount() {
      var data = [
        {
          name: "已戴口罩",
          value: this.chartData.Mask.Mast,
        },
        {
          name: "未戴口罩",
          value: this.chartData.Mask.NoMast,
        },
      ];
      var legend = {
        orient: "vertical",
        left: 10,
        data: ["已戴口罩", "未戴口罩"],
        bottom: 5,
        show: true,
      };
      var title = {
        show: true,
        text: "口罩佩戴情况分析",
        left: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      var emphasis = {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      };
      var color = [colorArr[0], colorArr[1]];
      this.$refs.maskCount.setAndInit(data, legend, color, emphasis, title);
    },

    initSexCount() {
      var data = [
        {
          name: "男",
          value: this.chartData.Sex.Man,
        },
        {
          name: "女",
          value: this.chartData.Sex.Woman,
        },
      ];
      var legend = {
        orient: "vertical",
        left: 10,
        data: ["男", "女"],
        bottom: 5,
        show: true,
      };
      var title = {
        show: true,
        text: "过往人员性别数量分析",
        left: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      var emphasis = {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      };
      var color = [colorArr[5], colorArr[3]];
      this.$refs.sexCount.setAndInit(data, legend, color, emphasis, title);
    },

    initPersonCount() {
      var title = {
        show: true,
        text: "人流量分析",
        left: 10,
        textStyle: {
          fontSize: 14,
        },
      };
      var data = [
        {
          name: "数量",
          data: [],
          color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#ffb669" },
            { offset: 0.5, color: "#ea413f" },
            { offset: 1, color: "#132f57" },
          ]),
        },
      ];
      var xdata = [];
      this.chartData.Law7.forEach((x) => {
        data[0].data.push(x.count);
        xdata.push(x.date);
      });
      var legend = {
        orient: "vertical",
        left: 10,
        data: xdata,
        bottom: 5,
        show: true,
      };
      this.$refs.personCount.setAndInit(
        data,
        legend,
        [colorArr[2]],
        null,
        xdata,
        title
      );
    },

    current(val) {
      val = val || 0;
      if (val >= this.playDevs.length) {
        val = 0;
      }
      var dev = this.playDevs[val];
      if (dev) {
        this.$refs.camininat.start(
          dev,
          () => {},
          () => {},
          val
        );
      }
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetFaceRouterBaseDetail", this.sid, true)
        .then((data) => {
          data.Datas.forEach((x) => {
            var obj = this.pariseData(x);
            this.datas.push(obj);
          });
          // this.chartData = data.ChartData;
          // this.initYear();
          // this.initMaskCount();
          // this.initSexCount();
          // this.initPersonCount();
          this.playDevs = data.Devices;
          this.scrollTable();
          this.loading = false;
          this.$nextTick(() => {
            this.play();
          });
        })
        .catch((err) => {
          this.$throw(err);
          this.loading = false;
        });
    },
    play() {
      if (this.IsISC) {
        this.playNat();
      } else {
        this.playNet();
      }
    },
    // 广域网请求头封装
    package(sid, hd) {
      return {
        EzopenType: 0,
        Channel: 1,
        Definition: hd,
        PlaybackSource: 0,
        Sid: sid,
      };
    },
    playNet() {
      var url = "/api/device/GetPayNet";
      if (this.playDevs.length > 0) {
        var dev = this.playDevs[0];
        var req = this.package(dev.Sid, "hd");
        this.$ksajax.ksPostData(url, req, true, (success) => {
          this.playdata.data = success;
          this.playdata.play = true;
          this.playdata.device = {
            sid: dev.Sid,
            serial: dev.Serial,
          };
          this.$refs.camininet.start();
        });
      }
    },
    playNat() {
      this.count = this.playDevs.length || 1;
      this.playNatSer(0);
    },

    playNatSer(index) {
      if (this.playDevs.length <= index) {
        return;
      } else {
        var dev = this.playDevs[index];
        console.log("playNatSer", dev);
        this.$refs.camininat.start(
          dev,
          () => {
            var s1 = index + 1;
            console.log(dev, s1, "success");
            this.playNatSer(s1);
          },
          () => {
            var s2 = index + 1;
            console.log(dev, s2, "error");
            this.playNatSer(s2);
          },
          index
        );
      }
    },
  },
};
</script>