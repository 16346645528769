<template>
    <div class="broadset">
    
        <div class="login">
    
            <b-container>
    
                <b-row>
    
                    <b-col cols="12" md="8" lg="6" offset-lg="3" offset-md="2">
    
                        <div class="login-form" style="margin:5%;position:relative">
    
                            <b-form @submit="onSubmit">
    
                                <b-form-group id="exampleInputGroup1" v-bind:label="$t('用户名')" label-for="exampleInput1">
    
                                    <b-form-input id="exampleInput1" type="text" v-model="form.UserName" required v-bind:placeholder="$t('用户名')">
    
                                    </b-form-input>
    
                                </b-form-group>
    
                                <b-form-group id="exampleInputGroup2" v-bind:label="$t('密码')" label-for="exampleInput2">
    
                                    <b-form-input id="exampleInput2" type="password" v-model="form.Password" required v-bind:placeholder="$t('密码')">
    
                                    </b-form-input>
    
                                </b-form-group>
    
    
                                <b-form-group id="exampleInputGroup3" v-bind:label="$t('新密码')" label-for="exampleInput2">
    
                                    <b-form-input id="exampleInput3" type="password" v-model="form.NPassWord" required v-bind:placeholder="$t('新密码')">
    
                                    </b-form-input>
    
                                </b-form-group>
                                <b-form-group id="exampleInputGroup4" v-bind:label="$t('确认密码')" label-for="exampleInput2">
    
                                    <b-form-input id="exampleInput4" type="password" v-model="form.NPassWord1" required v-bind:placeholder="$t('确认密码')">
    
                                    </b-form-input>
    
                                </b-form-group>
                                <hr>
                                <div style="text-align:center">
                                    
                                    <b-button type="submit" variant="primary" style="width:100%">{{$t('修改')}}</b-button>
                                </div>
                            </b-form>
    
                        </div>
    
                    </b-col>
    
                </b-row>
    
            </b-container>

        </div>
    
    </div>
</template>
<style>
    .broadset {
    
        height: 100%;
    
    }
</style>

<script>
    import actionbar from '@/components/actionbar'
    
    export default {
    
        components: {
    
            actionbar
    
        },
    
        data: function() {
    
            return {
    
                form: {
    
                    PassWord: "",
    
                    NPassWord: "",
    
                    UserName: "",
    
                    NPassWord1: ""
    
                },
                loading:{}
    
            }
    
        },
        methods:{
          onSubmit(evt){
            evt.preventDefault();
            if(this.form.NPassWord!==this.form.NPassWord1){
              this.$throw(this.$t("两次输入密码不一致"));
            }else{
            this.loading=this.$loading();
             this.$ksajax.ksPostPromise("/api/smart/UpdatePassword",this.form,true).then(()=>{
               this.loading.close();
               this.$message('修改成功');
             }).catch((err)=>{
               this.loading.close();
               this.$throw(err);
             })

            }
          }
        }
    }
</script>

