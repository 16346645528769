<template>
  <div
    class="white"
    v-loading="loading"
    style="position: relative; height: 100%"
  >
    <div style="position: absolute; right: 20px; top: 10px; z-index: 999">
      <i
        class="fa fa-table fa-2x"
        style="cursor: pointer; color: 8495c2"
        @click="tabled"
      ></i>
    </div>
    <ksmap
      v-show="map"
      ref="map"
      width="100%"
      :showCenter="false"
      height="100%"
      v-bind:lat="0"
      v-bind:lng="0"
      v-bind:zoom="25"
    >
    </ksmap>
    <div v-show="!map">
      <el-table
        class="kstable"
        :data="items"
        stripe=""
        style="width: 100%"
        highlight-current-row
        :empty-text="$t('暂无数据')"
      >
        <el-table-column prop="Name" :label="$t('终端名称')"></el-table-column>
        <el-table-column prop="Id" :label="$t('终端Id')"></el-table-column>
        <el-table-column
          prop="BCOutV"
          :label="$t('广播音量度')"
        ></el-table-column>
        <el-table-column
          prop="LocalIp"
          :label="$t('本地Ip地址')"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import ksmap from "@/components/map";
import { BaseUrl } from "@/assets/utils/config";
export default {
  components: { ksmap },
  data: function () {
    return {
      loading: true,
      map: true,
      items: [],
    };
  },
  mounted: function () {
    this.select();
  },
  methods: {
    tabled() {
      this.map = !this.map;
      if (this.map) {
        this.$refs.map.setFilter(0);
      }
    },
    select() {
      let obj = {
        PageIndex: 0,
        PageCount: 100000,
      };
      this.$ksajax
        .ksPostPromise("/api/CityBroadcast/Terminal/GetList", obj, true)
        .then((data) => {
          this.loading = false;
          var device = [];
          this.items = data.Rows;
          console.log("b1");
          data.Rows.forEach((x) => {
            console.log("b1:", x);
            let location = [];
            console.log("b2:", x.ExtendData);
            if (
              x.ExtendData &&
              x.ExtendData !== "" &&
              x.ExtendData.split("/").length === 3
            ) {
              location = x.ExtendData.split("/");
            } else {
              location[0] = "";
              location[1] = 0;
              location[2] = 0;
            }
            console.log("b3");
            device.push({
              lat: parseFloat(location[1]),
              lng: parseFloat(location[2]),
              icon: `${BaseUrl}/img/map/ic_broad.png`,

              title: x.Name,
            });
          });
          console.log("device:", device);
          this.$refs.map.addDevice(device, 0);
        })
        .catch((err) => {
          this.$throw(err, this);
          this.loading = false;
        });
    },
  },
};
</script>
