<template>
  <div v-loading="loading">
    <div class="clearfix log_action">
      <div class="log_action_filter">
        <span>车牌号码</span>
        <el-input v-model="filter.Plate"></el-input>
      </div>
      <div class="log_action_filter">
        <span>车牌颜色</span>
        <el-select v-model="filter.PlateColor">
          <el-option
            v-for="(item, index) in plateColrs"
            :key="`${item.code}${index}`"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
      <div class="log_action_filter">
        <span>车辆类型</span>
        <el-select v-model="filter.CarType">
          <el-option
            v-for="(item, index) in carType"
            :key="`${item.code}${index}`"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
      <div class="log_action_filter">
        <span>车辆品牌</span>
        <el-select v-model="filter.CarLogoType">
          <el-option
            v-for="(item, index) in cars"
            :key="`${item.code}${index}`"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
      <div class="log_action_filter">
        <span>违法类型</span>
        <el-select v-model="filter.LawType">
          <el-option
            v-for="(item, index) in laws"
            :key="`${item.code}${index}`"
            :value="item.code"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
      <div class="log_action_filter log_action_filter_botton">
        <el-button type="primary" style="width: 120px" @click="dselect"
          >查询</el-button
        >
        <el-button type="primary" style="width: 120px" @click="exportFun"
          >导出</el-button
        >
      </div>
    </div>
    <tablepage
      ref="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>
    <b-modal
      ref="modal"
      :title="$t('详情')"
      centered
      content-class="ksmod"
      :ok-title="$t('确定')"
      hide-footer
      size="xl"
    >
      <div style="height: 350px">
        <lawDetail :value="currentLaw"></lawDetail>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
.log_action {
  color: black;
  background-color: white;
  padding: 10px;
}
.log_action_filter {
  width: 200px;
  padding: 5px;
  float: left;
}
.log_action_filter_botton {
  height: 74px;
  align-items: center;
  display: flex;
  padding-left: 20px;
}
</style>
<script>
import {
  carType,
  plateColrs,
  cars,
  laws,
  getLawByCode,
  getCarByCode,
  getCarTypeByCode,
  getPlateColorByCode,
} from "@/assets/utils/hikUtil";
import tablepage from "@/components/tablepage";
import lawDetail from "./components/lawDetail.vue";
export default {
  components: { tablepage, lawDetail },
  data() {
    return {
      currentLaw: {},
      items: [],
      loading: false,
      filter: {
        Count: 8,
        Skip: 0,
        Plate: null,
        PlateColor: null,
        CarType: null,
        LawType: null,
        CarLogoType: null,
      },
      carType: [],
      plateColrs: [],
      cars: [],
      laws: [],
      cols: [
        {
          name: "抓拍日期",
          value: "CreateTime",
          subvalue: "Law",
        },
        {
          name: "车牌号码",
          value: "License",
          subvalue: "Car",
        },
        {
          name: "车牌品牌",
          value: "VehicleLogoRecog",
          subvalue: "Car",
        },
        {
          name: "车牌类型",
          value: "VehicleType",
          subvalue: "Law",
        },
        {
          name: "车牌颜色",
          value: "PlateColor",
          subvalue: "Car",
        },
        {
          name: "违法行为",
          value: "IllegalType",
          subvalue: "Law",
        },
        {
          name: "主驾安全带",
          value: "PilotSafebelt",
          subvalue: "Law",
        },
        {
          name: "主驾打电话",
          value: "PilotCall",
          subvalue: "Law",
        },
      ],
      total: 0,
      perpage: 0,
      count: 8,
      cpage: 1,
    };
  },
  mounted() {
    this.select();
    this.carType = carType;
    this.plateColrs = plateColrs;
    this.laws = laws;
    this.cars = cars;
    this.carType.push({ code: null, name: "全部" });
    this.plateColrs.push({ code: null, name: "全部" });
    this.cars.push({ code: null, name: "全部" });
    this.laws.push({ code: null, name: "全部" });
  },
  methods: {
    tableCreated() {},
    currentRow(val) {
      this.currentLaw = val;
      if (this.currentLaw) {
        this.$refs.modal.show();
      }
    },
    currentPage(val) {
      this.cpage = val;
      this.filter.Skip = (val - 1) * this.filter.Count;
      this.select();
    },
    dselect() {
      if (this.cpage == 1) {
        this.select();
      } else {
        this.$refs.tableref.reset();
      }
    },
    exportFun() {
      this.loading = true;
      this.$ksajax
        .ksPostExport("/api/ExcelExport/CarLawRecords", this.filter, true)
        .then((data) => {
          console.log("导出data:", data);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    select() {
      this.$refs.modal.hide();
      console.log("test");
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetRecords", this.filter, true)
        .then((data) => {
          this.loading = false;
          data.Items.forEach((element) => {
            if (element.Law) {
              element.Law.IllegalType = getLawByCode(element.Law.IllegalType);
              element.Law.VehicleType = getCarTypeByCode(
                element.Law.VehicleType
              );
              element.Law.PilotSafebelt =
                element.Law.PilotSafebelt == 2 ? "未系" : "已系";
              element.Law.PilotCall =
                element.Law.PilotCall == 2 ? "打电话" : "无";
            }
            if (element.Car) {
              element.Car.VehicleLogoRecog = getCarByCode(
                element.Car.VehicleLogoRecog
              );
              element.Car.PlateColor = getPlateColorByCode(
                element.Car.PlateColor
              );
            }
          });
          this.items = data.Items;
          this.total = data.TotalCount;
          this.$refs.modal.hide();
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
  },
};
</script>