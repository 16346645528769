<template>
  <div class="boradm white">
    <actionbar
      v-bind:select="true"
      v-bind:insert="true"
      v-bind:del="true"
      v-on:select="select"
      v-on:insert="insert"
      v-on:delete="del"
    >
    </actionbar>
    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>
    <b-modal id="fileupload" ref="fileupload" :title="$t('MP3文件上传')">
      <el-upload
        :on-success="Uploaded"
        :data="fileData"
        :multiple="false"
        :limit="1"
        class="upload-demo"
        ref="upload"
        :action="baseUrl+'/api/Upload/BroadFileUpload'"
        :file-list="fileList"
        accept=".mp3"
        :auto-upload="false"
      >
        <el-button slot="trigger" size="small" type="primary">{{
          $t("选取文件")
        }}</el-button>
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="submitUpload"
          >{{ $t("上传到服务器") }}</el-button
        >
        <div slot="tip" class="el-upload__tip">{{ $t("只能上传MP3文件") }}</div>
      </el-upload>
    </b-modal>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import itc from "@/assets/utils/itchttp.js";
import cookies from "@/assets/utils/kscookie";
import config from "@/assets/utils/config.js";
export default {
  data: function () {
    return {
      cols: [
        { name: this.$t("序号"), value: "ID" },
        { name: this.$t("名称"), value: "Name" },
        { name: this.$t("大小"), value: "Length" },
      ],
      items: [],
      index: 0,
      count: 1500,
      total: 0,
      current: null,
      sid: "",
      loading: {},
      fileList: [],
      fileData: {},
      baseUrl:config.BaseUrl
    };
  },
  components: { actionbar, tablepage },
  mounted: function () {
    this.$nextTick(() => {
      this.select();
    });
  },
  methods: {
    select() {
      this.loading = this.$loading({ fullscreen: true });
      let data = {
        DirId: this.$store.state.project.BroadcastDirId,
      };
      itc
        .postData(
          this.$store.state.broadcast.url,
          "MLListDir",
          data,
          this.$store.state.broadcast.session
        )
        .then((success) => {
          this.loading.close();
          var it = success.Items.filter((r) => r.Type == 1);
          this.total = it.length;
          this.items = it;
        })
        .catch((error) => {
          this.loading.close();
          this.$throw(error);
        });
    },
    insert() {
      this.fileList = [];
      this.loading = this.$loading({ fullscreen: true });
      let data = { Type: 1 };
      itc
        .postData(
          this.$store.state.broadcast.url,
          "FileUpload",
          data,
          this.$store.state.broadcast.session
        )
        .then((success) => {
          this.loading.close();
          let su = this.$store.state.broadcast.url
            .toLowerCase()
            .match(/http:\/\/(\S*):(\S*)/)[1];
          let lu = success.FtpUrl.toLowerCase().match(/ftp:\/\/(\S*):(\S*)/)[1];
          success.FtpUrl = success.FtpUrl.replace(lu, su);
          success.Code = cookies.get("token");
          this.fileData = success;
          this.$refs.fileupload.show();
        })
        .catch((error) => {
          this.loading.close();
          this.$throw(error);
        });
    },
    del() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      this.$confirm(this.$t("删除确认"), this.$t("删除"), {
        confirmButtonText: this.$t("确认"),
        cancelButtonText: this.$t("退出"),
        type: "warning",
      })
        .then(() => {
          this.sid = this.current.ID;
          let obj = {
            ID: this.sid,
          };
          return itc.postData(
            this.$store.state.broadcast.url,
            "MLDelProg",
            obj,
            this.$store.state.broadcast.session
          );
        })
        .then(() => {
          this.$message({
            type: "info",
            message: this.$t("操作成功"),
          });
          this.select();
        })
        .catch((data) => {
          this.$message({
            type: "info",
            message: data,
          });
        });
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    submitUpload() {
      this.$refs.upload.submit();
      this.loading = this.$loading({ fullscreen: true });
    },
    Uploaded(response, file) {
      let obj = {
        ParentId: this.$store.state.project.BroadcastDirId,
        Type: 1,
        Name: file.name,
        FileId: response.Data,
      };
      itc
        .postData(
          this.$store.state.broadcast.url,
          "MLCreateNode",
          obj,
          this.$store.state.broadcast.session
        )
        .then(() => {
          this.loading.close();
          this.$refs.fileupload.hide();
          this.select();
        })
        .catch((err) => {
          this.$throw(err);
          this.loading.close();
        });
    },
  },
};
</script>

