<template>
    <div class="Stands white">
        <actionbar v-bind:select='true' v-bind:modify='true'  :sync="true" :volume='true'
        v-on:select='select'
        v-on:modify='modify'
        v-on:sync='sync'
        v-on:volume="volume"
        >
        </actionbar>
        <tablepage ref="tableref" id="tableref"
         v-on:created='tableCreated'
         v-on:currentPage='currentPage'
         v-on:currentRow='currentRow'
         :cols='cols'
         :items='items'
         :total='total'
         :perpage='count'
        ></tablepage>
        
        <modify ref="modify"
        :mapitem="mapitem"
         :modaldata='modalData'
         :title='title'
         :labels='labels'
         v-on:submited='submited'
         url="/api/device/UpdateBroadcast"
        ></modify>
        <deletedata ref="del"
            v-on:deleted="deleted"
            :sid="sid"
            url="/api/device/DelBroadcast"
            :title="$t('删除')"
        ></deletedata>
    </div>
</template>
<style>
.admin{
    background-color: white;
}
</style>

<script>
import actionbar from '@/components/actionbar'
import tablepage from '@/components/tablepage'
import modify from '@/components/modify'
import deletedata from '@/components/deletedata'
import itc from '@/assets/utils/itchttp.js'
export default {
    data:function(){
        return{
            loading:{},
                  modalData:{
                        "Volume": 0,
                        "DeviceName": "",
                        "Lat": 0,
                        "Lng": 0,
                        "Address": "",
                        "Serial": "",
                        "DTypeSid": "",
                        "LStandSid": "",
                        "Sid": ""
                    },
            labels:this.getLable(true),
            title:"",
            cols:[
                {name:this.$t("设备名"),value:"DeviceName"},
                {name:this.$t("状态"),value:"State"},
                {name:this.$t("设备类型"),value:"TypeName",subvalue:"DeviceType"},
                {name:this.$t("设备型号"),value:"ModelNumber",subvalue:"DeviceType"},
                {name:this.$t("序号"),value:"Serial"},
                {name:this.$t("IP"),value:"IP"},
                {name:this.$t("音量"),value:"Volume"},
                {name:this.$t("位置"),value:"Address"},
                {name:this.$t("详情"),value:"url",link:true},
            ],
            items:[],
            index:0,
            count:15,
            total:0,
            current:null,
            sid:"",
            mapitem:{
                    "value":"Address",
                    "require":true,
                    "subvalue":false,
                    "Position":{
                        "Lat":"Lat",
                        "Lng":"Lng"
                    }
                }
        }
    },
    components:{
        actionbar,tablepage,modify,deletedata
    },
    created:function(){
        
    },
    mounted:function(){
        this.select();
    },
    methods:{
    getLable(){
        var array= [
                 {
                    "label":this.$t("设备名"),
                    "type":"text",
                    "value":"DeviceName",
                    "require":true
                }, 
                {
                    "label":this.$t("类型"),
                    "type":"select",
                    "value":"DTypeSid",
                    "require":true,
                    "bind":this.$store.getters.getDeviceType("002")
                },
                {
                    "label":this.$t("灯杆"),
                    "type":"select",
                    "value":"LStandSid",
                    "require":false,
                    "bind":this.$store.state.lStands
                },
                 {
                    "label":this.$t("位置"),
                    "type":"map",
                    "value":"Address",
                    "require":true,
                }, 
                 {
                    "label":this.$t("音量"),
                    "type":"number",
                    "value":"Volume",
                    "require":true,
                    "readonly":true
                },  {
                    "label":this.$t("序号"),
                    "type":"text",
                    "value":"Serial",
                    "require":true,
                    "readonly":true
                }
            ]
            return array;
    },
    showModify(insert){
       if(this.$store.state.lStands.length===0){
            this.$store.dispatch("lstandAction",()=>{
                if(this.$store.getters.getDeviceType("001").length===0){
                    this.$store.dispatch("deviceTypeAction",()=>{
                        this.labels=this.getLable(insert);
                        this.$refs.modify.show();
                    },false);
                }
                else{
                    this.labels=this.getLable(insert);
                    this.$refs.modify.show();
                }
            },false);
        }else{
             if(this.$store.getters.getDeviceType("001").length===0){
                    this.$store.dispatch("deviceTypeAction",()=>{
                        this.labels=this.getLable(insert);
                        this.$refs.modify.show();
                    },false);
                }
                else{
                    this.labels=this.getLable(insert);
                    this.$refs.modify.show();
                }
        }
    },
    currentRow(val){
        this.current=val;
        },
      resetData(){
          return{
                        "Volume": 0,
                        "DeviceName": "",
                        "Lat": 0,
                        "Lng": 0,
                        "Address": "",
                        "Serial": "",
                        "DTypeSid": "",
                        "LStandSid": "",
                        "Sid": ""
                }
      },
      select(){
          if(this.index<0){
              this.index=0;
          }
          let req={
              Index:this.index,
              Count:this.count
          };
        var self=this;
        this.$ksajax.ksPostData("/api/device/GetDeviceBroadcasts",req,true,
        (success)=>{
            self.total=success.TotalCount;
            success.Items.forEach(x => {
                x.url=`/admin/stand/broaddetail?sid=${x.Sid}`;
                x.State=x.Status==-1?this.$t("离线"):x.Status==0?this.$t("在线"):this.$t("工作中")
            });
            self.items=success.Items;
        },
        ()=>{
        },
        false
        );
      },
      modify(){
           if(!this.current){
              this.$throw(this.$t("选择一条数据"),this);
          }
          else{
            this.modalData={
                        "Volume": this.current.Volume,
                        "Ip": this.current.IP,
                        "DeviceName": this.current.DeviceName,
                        "Lat": this.current.Lat,
                        "Lng": this.current.Lng,
                        "Address": this.current.Address,
                        "Serial": this.current.Serial,
                        "LStandSid": "",
                        "Sid": this.current.Sid,
                        "DTypeSid": this.current.DeviceType.Sid
          };
          if(this.current.LampStand){
               this.modalData.LStandSid=this.current.LampStand.Sid
          }
          this.title=this.$t('修改');
          this.showModify(false);
          }
      },
      tableCreated(){
         // this.select();
      },
      currentPage(val){
          this.index=(val-1)*this.count;
          this.select();
      },
      submited(){
          this.index=(1-1)*this.count;
          this.select();
      },
      deleted(){
          this.index=(1-1)*this.count;
          this.select();
      },
      sync(){
          this.loading=this.$loading({fullscreen:true});
          let obj={
              TNumber:0
          }
          //获取设备IDS
           itc.postData(this.$store.state.broadcast.url,"getTermIds",obj,this.$store.state.broadcast.session)
           .then((success)=>{
             //获取设备列表
             let obj={
                 TermIDs:success.TermIds,
                 TNumber:0
             }
             return itc.postData(this.$store.state.broadcast.url,"getTermState",obj,this.$store.state.broadcast.session)
           })
           .then((success)=>{
            //同步
              return this.$ksajax.ksPostPromise("/api/device/SyncBroadcast",success.Terms,true);
           })
           .then(()=>{
               //查询
             let req={
              Index:this.index,
              Count:this.count 
              };
              return this.$ksajax.ksPostPromise("/api/device/GetDeviceBroadcasts",req,true);
           })
           .then(()=>{
               this.loading.close();
               this.select();
           })
           .catch((err)=>{
               this.loading.close();
               this.$throw(err);
           })
      },
       volume(){
           if(!this.current){
              throw this.$t("选择一条数据");
          }
            this.$prompt(this.$t("修改音量"), this.$t("提交"), {
            confirmButtonText: this.$t("确认"),
            cancelButtonText: this.$t("退出"),
            inputPattern: /^[0-5][0-6]{0,1}$/,
            inputErrorMessage: this.$t("ITC音量范围")
                }).then(({ value }) => {
                    this.loading=this.$loading({fullscreen:true});
                    let obj={
                        TermIds:[parseInt(this.current.Serial)],
                        Volume:parseInt(value)
                    }
                    return itc.postData(this.$store.state.broadcast.url,"TermVolSet",obj,this.$store.state.broadcast.session)
                })
                .then(()=>{
               this.loading.close();
                    this.sync();
                })
                .catch((err) => {
                    this.$throw(err);
                    this.loading.close();
                });
       }
      }
}
</script>

