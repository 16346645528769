<template>
  <div class="" v-loading="loading" style="position: relative; height: 100%">
    <div style="position: absolute; right: 20px; top: 10px; z-index: 999">
      <i
        class="fa fa-table fa-2x"
        style="cursor: pointer; color: 8495c2"
        @click="tabled"
      ></i>
    </div>
    <ksmap
      v-show="map"
      ref="map"
      width="100%"
      :showCenter="false"
      height="100%"
      v-bind:lat="0"
      v-bind:lng="0"
      v-bind:zoom="25"
    >
    </ksmap>
    <div v-show="!map">
      <el-table
        class="kstable"
        :data="items"
        stripe=""
        style="width: 100%"
        highlight-current-row
        :empty-text="$t('暂无数据')"
      >
        <el-table-column
          prop="DeviceName"
          :label="$t('设备名称')"
        ></el-table-column>
        <el-table-column
          prop="Chanel"
          :label="$t('全景通道')"
        ></el-table-column>
       
      </el-table>
    </div>
  </div>
</template>
<script>
import ksmap from "@/components/map";
import box from "@/components/box.vue";
import { BaseUrl } from "@/assets/utils/config";
export default {
  components: { ksmap, box },
  data: function () {
    return {
      selected: {},
      loading: true,
      map: true,
      items: [],
      options: [
        { text: "街道1", value: "radio1" },
        { text: "街道2", value: "radio1" },
        { text: "街道3", value: "radio1" },
      ],
    };
  },
  mounted: function () {
    this.select();
  },
  methods: {
    tabled() {
      this.map = !this.map;
      if (this.map) {
        this.$refs.map.setFilter(0);
      }
    },
    select() {
      this.$ksajax
        .ksPostPromise("/api/oview/getoviews", "", true)
        .then((data) => {
          this.loading = false;
          var device = [];
          this.items = data;
          data.forEach((x) => {
            device.push(
              {
                lat: x.Lat,
                lng: x.Lng,
                icon: `${BaseUrl}/img/map/ic_video.png`,
                url: "/admin/Panor/detail?sid=" + x.Sid,
                title: x.DeviceName,
              }
            );
          });
          this.$refs.map.addDevice(device, 0);
        })
        .catch((err) => {
          this.$throw(err, this);
          this.loading = false;
        });
    },
  },
};
</script>
