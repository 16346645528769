<template>
<div style="height:100%">
  <div id="commondevice" class="clearfix" v-loading="load">
    <div>
      <boxtitle :data="infoTitle">
        <ksmap
          ref="map"
          width="100%"
          :showCenter="false"
          height="100%"
          v-bind:lat="0"
          v-bind:lng="0"
          v-bind:zoom="25"
        ></ksmap>
      </boxtitle>
    </div>
    <div>
      <boxtitle :data="stateTitle" v-on:titleClick="wantError">
        <div class="clearfix" id="commondevice_info">
          <div id="commondevice_info_pic">
            <b-img fluid class="ksimg" :src="current.Pic"></b-img>
          </div>
          <div id="commondevice_info_text" class="ksflexcol">
            <div class="flex1 row ksrow">
              <div class="col">{{$t('设备名称')}}</div>
              <div class="col">{{current.DeviceName}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t('型号')}}</div>
              <div class="col">{{current.DeviceType?current.DeviceType.ModelNumber:""}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t('部门')}}</div>
              <div class="col">{{current.Partment?current.Partment.Name:""}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t('联系人')}}</div>
              <div class="col">{{current.Partment?current.Partment.Contacter:""}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t('联系电话')}}</div>
              <div class="col">{{current.Partment?current.Partment.ContactPhone:""}}</div>
            </div>
            <div class="flex1 row ksrow">
              <div class="col">{{$t('状态')}}</div>
              <div class="col">{{current.State==0?$t('状态'):current.State==1?$t("异常"):$t("废弃")}}</div>
            </div>
          </div>
        </div>
      </boxtitle>
    </div>
    <div>
      <boxtitle :data="checkTitle" v-loading="loadingCheck">
        <el-table stripe="" class="kstable" :data="checks" style="width: 100%" highlight-current-row :empty-text="$t('暂无数据')">
          <el-table-column prop="CheckTime" :label="$t('巡检时间')"></el-table-column>
          <el-table-column :label="$t('巡检人')" >
            <template slot-scope="scope">{{scope.row.Reciver?scope.row.Reciver.Name:""}}</template>
          </el-table-column>
          <el-table-column  :label="$t('巡检电话')" >
            <template slot-scope="scope">{{scope.row.Reciver?scope.row.Reciver.PhoneNumber:""}}</template>
          </el-table-column>
          <el-table-column :label="$t('巡检部门')" >
            <template slot-scope="scope">{{scope.row.Reciver?scope.row.Reciver.PartmentName:""}}</template>
          </el-table-column>
          <el-table-column :label="$t('巡检状态')">
            <template slot-scope="scope">{{scope.row.Reciver.State==0?"正常":scope.row.Reciver.State==1?"异常":"废弃"}}</template>
          </el-table-column>
          <el-table-column :label="$t('详情')">
            <template slot-scope="scope">
              <router-link :to="'/admin/stand/checkdetail?sid='+scope.row.Sid">{{$t('详情')}}</router-link>
            </template>
          </el-table-column>
        </el-table>
      </boxtitle>
    </div>
    <div>
      <boxtitle :data="errTitle" v-loading="loadingError">
        <el-table class="kstable" :data="errors" style="width: 100%" highlight-current-row :empty-text="$t('暂无数据')">
          <el-table-column prop="CheckTime" :label="$t('故障时间')" ></el-table-column>
          <el-table-column prop="Reason" :label="$t('故障描述')"  ></el-table-column>
          <el-table-column :label="$t('处理人')"  >
            <template slot-scope="scope">{{scope.row.Reciver?scope.row.Reciver.Name:""}}</template>
          </el-table-column>
          <el-table-column :label="$t('处理电话')" >
            <template slot-scope="scope">{{scope.row.Reciver?scope.row.Reciver.PhoneNumber:""}}</template>
          </el-table-column>
          <el-table-column  :label="$t('处理部门')">
            <template slot-scope="scope">{{scope.row.Reciver?scope.row.Reciver.PartmentName:""}}</template>
          </el-table-column>
          <el-table-column  :label="$t('故障状态')">
            <template slot-scope="scope">{{scope.row.DisposeStatus==0?$t('未恢复'):$t('恢复')}}</template>
          </el-table-column>
          <el-table-column  :label="$t('详情')">
            <template slot-scope="scope">
              <router-link :to="'/admin/stand/errordetail?sid='+scope.row.Sid">{{$t('详情')}}</router-link>
            </template>
          </el-table-column>
        </el-table>
        </boxtitle>
    </div>
  </div>
     <b-modal id="modallg" ref="modallg" size="lg" :title="$t('报故障')" hide-footer
         header-bg-variant="success"
         body-text-variant="dark">
             <b-form @submit="onSubmit">
            <b-form-group   :label="$t('故障描述')" label-cols="2">
                <b-form-input v-model="errroData.Content" required></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('联系人')" label-cols="2">
                <b-form-input v-model="errroData.ContactName" required></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('联系电话')" label-cols="2">
                <b-form-input v-model="errroData.ContactPhone" required></b-form-input>
            </b-form-group>
             </b-form>
             
        <b-form-group>
            <hr>
            <div class="modal-foot">
                   <b-button style="width:100px" v-on:click="hidden" >Cancel</b-button>
                   <b-button type="button" style="width:100px" variant="primary" v-on:click='onSubmit'>OK</b-button>
            </div>
        </b-form-group>
     </b-modal></div>
</template>
<style>
#commondevice_info_pic {
  display: flex;
  flex-direction: column;
  vertical-align: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 40%;
  padding: 20px !important;
}
#commondevice_info_text {
  width: 60%;
}
#commondevice {
  height: 100%;
  color: white;
}
#commondevice_info {
  height: 100%;
}
#commondevice_info > div {
  float: left;
  padding: 5px;
  height: 100%;
}
#commondevice > div {
  float: left;
  width: 50%;
  padding: 5px;
  height: 50%;
}
@media (max-width: 1200px) {
  #commondevice > div {
    width: 100%;
    height: 100%;
  }

  #commondevice_info > div {
    height: 50%;
    width: 100%;
  }
}
</style>

<script>
import boxtitle from "@/components/boxtitle.vue";
import ksmap from "@/components/map";
export default {
  components: { boxtitle, ksmap },
  data: function() {
    return {
        errroData:{},
      load: false,
      infoTitle: {
        title: this.$t("设备位置")
      },
      stateTitle: {
        title: this.$t("设备信息"),
        action:this.$t("报故障"),
        style:"fa fa-windows"
      },
      checkTitle: {
        title: this.$t("巡检日志")
      },
      errTitle: {
        title: this.$t("故障日志")
      },
      loadingError: false,
      loadingCheck: false,
      current: { Partment: {}, DeviceType: {} },
      checks: [],
      errors: []
    };
  },
  mounted: function() {
    this.select();
  },
  methods: {
      hidden(){
            this.$refs.modallg.hide();
        },
        onSubmit(evn){
              evn.preventDefault();
            this.load=true;
              this.errroData.Sid=this.$route.query.sid;
               this.$ksajax
        .ksPostPromise("/api/sz/CreateAlarm",  this.errroData, false).then(()=>{
            this.hidden();
            this.$throw("操作成功");
            this.load=false;
            this.select();
        }).catch(err=>{
            this.$throw(err);
            this.load=false;
        })
        },
    wantError(){
        this.$refs.modallg.show();
    },
    getError() {
      this.loadingError = true;
      this.$ksajax
        .ksPostPromise("/api/info/GetDeviceError", this.$route.query.sid, true)
        .then(data => {
          this.errors = data;
          this.loadingError = false;
        })
        .catch(err => {
          this.$throw(err);
          this.loadingError = false;
        });
    },
    getCheck() {
      this.loadingCheck = true;
      this.$ksajax
        .ksPostPromise("/api/info/GetDeviceLog", this.$route.query.sid, true)
        .then(data => {
          data.forEach(x => {
            x.url = "admin/info/checkdetail";
          });
          this.checks = data;
          this.loadingCheck = false;
        })
        .catch(err => {
          this.$throw(err);
          this.loadingCheck = false;
        });
    },
    select() {
      this.load = true;
      this.$ksajax
        .ksPostPromise("/api/info/GetDeviceBasic", this.$route.query.sid, true)
        .then(success => {
          this.current = success;
          this.load = false;
          var device = [
            {
              lat: this.current.Lat,
              lng: this.current.Lng,
              icon: this.current.Pic,
              title: this.current.Address
            }
          ];
          this.$refs.map.addDevice(device, 0);
          this.getCheck();
          this.getError();
        })
        .catch(err => {
          this.load = false;
          this.$throw(err);
        });
    }
  }
};
</script>
