<template>
  <b-row class="white netcam">
    <b-col cols="12" md="4" lg="3" xl="2" class="side hiden_scroll">
      <sidemenu
        v-on:clickItem="clickItem"
        :title="$t('摄像头列表')"
        :items="items"
      ></sidemenu>
    </b-col>
    <b-col cols="12" md="8" lg="9" xl="10" class="sidecontent">
      <netvideo
        ref="netplay"
        :showRight="showRight"
        :playdata="playdata"
      ></netvideo>
    </b-col>
  </b-row>
</template>
<script>
import sidemenu from "@/components/sidemenu";
import netvideo from "@/components/netvideo";
export default {
  components: { sidemenu, netvideo },
  data: function () {
    return {
      items: [],
      playdata: {},
      showRight: true,
    };
  },
  methods: {
    stateChanged(state) {
      var sid = this.playdata.device.sid;
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].sid === sid) {
          this.items[i].active = state;
        } else {
          this.items[i].active = false;
        }
      }
    },
    clickItem(sid) {
      let choose = false;
      let device = {};
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].sid === sid) {
          choose = this.items[i].active = !this.items[i].active;
          device = this.items[i];
        } else {
          this.items[i].active = false;
        }
      }
      if (choose) {
        //播放
        var req = this.getPlayReqData(sid, "sd", false);
        var url = "/api/device/GetPayNet";
        this.$ksajax.ksPostData(url, req, true, (success) => {
          this.playdata.data = success;
          this.playdata.isPlay = true;
          this.playdata.device = device;
          this.$refs.netplay.start();
        });
      } else {
        //停止播放
        this.playdata.isPlay = false;
        this.$refs.netplay.stop();
      }
    },
    getPlayReqData(sid, hd) {
      return {
        EzopenType: 0,
        Channel: 1,
        Definition: hd,
        PlaybackSource: 0,
        Sid: sid,
      };
    },
  },
  destroyed: function () {
    window.alarmDoAction = null;
    window.stopYSPlay = null;
    window.stopYSSound = null;
  },
  mounted: function () {
    var result = this.$route.path.toLowerCase().indexOf("alarmaction") != -1;
    if (result) {
      this.showright = false;
      window.alarmDoAction = (serial) => {
        var sids = this.items.filter((x) => x.serial == serial);
        if (!sids || sids.length == 0) {
          this.$throw("没有找到设备");
        } else {
          this.clickItem(sids[0].sid);
        }
      };
    }
    var serial = this.$route.query.serial;
    if (result) {
      this.showRight = false;
    }
    //获取设备列表
    let req = {
      Index: 0,
      Count: 100000,
    };
    var self = this;
    this.$ksajax.ksPostData(
      "/api/device/GetDeviceVideos",
      req,
      true,
      (success) => {
        var array = [];
        for (let i = 0; i < success.Items.length; i++) {
          var isAlarm = success.Items[i].DeviceType.Sid == "1501";
          var obj = {
            text: success.Items[i].DeviceName,
            active: false,
            sid: success.Items[i].Sid,
            serial: success.Items[i].Serial,
          };
          if (result) {
            if (isAlarm) {
              array.push(obj);
            }
          } else {
            array.push(obj);
          }
        }
        self.items = array;
        if (serial) {
          var sids = array.filter((x) => x.serial == serial);
          if (!sids || sids.length == 0) {
            this.$throw("没有找到设备");
          } else {
            this.clickItem(sids[0].sid);
          }
        }
      },
      () => {},
      false
    );
  },
};
</script>

