<template>
  <div class="white">
    <b-row class="ksrow">
      <b-col md="12" lg="3" style="height:600px;overflow:auto;" class="hiden_scroll">
        <div class="card border-primary mb-3" style="margin-top: 10px;">
          <div class="card-header">{{ $t("资源") }}</div>
          <div class="card-body text-primary">
            <div role="tablist">
              <b-card
                no-body
                class="mb-1"
                v-for="item in res"
                :key="item.id + 'col'"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle="item.id" variant="info">{{
                    item.group
                  }}</b-button>
                </b-card-header>
                <b-collapse
                  :id="item.id"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-list-group>
                      <b-list-group-item
                        style="cursor: pointer"
                        v-for="it in item.data"
                        @click="addRes(it)"
                        variant="primary"
                        :key="it.Sid"
                        >{{ it.Name }}</b-list-group-item
                      >
                    </b-list-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="12" lg="5">
        <div class="card border-primary mb-3" style="margin-top: 10px">
          <div class="card-header">{{ $t("节目") }}</div>
          <div class="card-body text-primary">
            <div class="alert alert-primary" role="alert" id="action_notify">
              <b-button variant="outline-primary" @click="sortUp">
                <i class="fa fa-arrow-up"></i>
                {{ $t("上移") }}
              </b-button>
              <b-button variant="outline-primary" @click="sortDown">
                <i class="fa fa-arrow-down"></i>
                {{ $t("下移") }}
              </b-button>
              <b-button variant="outline-primary" @click="delRes">
                <i class="fa fa-trash-o"></i>
                {{ $t("删除元素") }}
              </b-button>
              <b-button variant="outline-primary" @click="save">
                <i class="fa fa-save"></i>
                {{ $t("保存节目") }}
              </b-button>
            </div>
            <div id="prog_nova" :style="progCStyle">
              <vue-draggable-resizable
                v-for="it in prog.Widgets"
                :key="`p${it.Sid}`"
                :id="`p${it.Sid}`"
                :parent="true"
                :x="it.layout.x"
                :y="it.layout.y"
                :w="it.layout.width"
                :h="it.layout.height"
                :z="it.zIndex"
                v-on:resizing="onResizing"
                v-on:dragstop="onDragstop"
                v-on:activated="onActive(it.Sid)"
              >
                <div v-if="it.Value == 0" class="subResize">
                  <textarea
                    v-model="it.lines[0].textAttributes[0].content"
                    :style="it.lines[0].textAttributes[0].Style"
                  ></textarea>
                </div>
                <div v-if="it.Value == 1" class="subResize">
                  <b-img
                    style="width: 100%; height: 100%"
                    :src="it.url"
                  ></b-img>
                </div>
                <video
                  v-if="it.Value == 2"
                  controls
                  :autoplay="false"
                  name="media"
                  width="100%"
                  height="100%"
                  loop="loop"
                >
                  <source :src="it.url" type="video/mp4" />
                </video>
                <div
                  v-if="it.Value == 3"
                  class="subResize"
                  :style="it.lines[0].textAttributes[0].Style"
                >
                  <textscroll
                    :data="it.lines[0].textAttributes[0].content"
                    :speed="100 / it.scrollAttribute.speed"
                  ></textscroll>
                </div>
                <el-carousel
                  v-if="it.Value == 4"
                  trigger="click"
                  style="height: 100%"
                >
                  <el-carousel-item v-for="e in it.mediaList" :key="e.url">
                    <b-img class="autoimg" :src="e.url"></b-img>
                  </el-carousel-item>
                </el-carousel>
                <div
                  v-if="it.Value == 5 && it.displayType == 'SCROLL'"
                  class="subResize"
                  :style="it.bodyTextAttr.Style"
                >
                  <textscroll
                    :data="it.md5"
                    :speed="100 / it.scrollAttribute.speed"
                  ></textscroll>
                </div>
                <div
                  v-if="it.Value == 5 && it.displayType == 'PAGE_SWITCH'"
                  class="subResize"
                >
                  <textarea
                    v-model="it.md5"
                    readonly
                    :style="it.bodyTextAttr.Style"
                  ></textarea>
                </div>
                <div
                  v-if="it.Value == 6"
                  class="subResize"
                  style="background-color: #225544; color: white"
                >
                  摄像头画面区域
                </div>
              </vue-draggable-resizable>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="12" lg="4">
        <div class="card border-primary mb-3" style="margin-top: 10px">
          <div class="card-header">{{ $t("节目信息") }}</div>
          <div class="card-body text-primary">
            <div>
              <b-form inline>
                <b-input-group
                  :prepend="$t('名称')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-input v-model="prog.Name"></b-input>
                </b-input-group>
                <b-input-group
                  :prepend="$t('宽度')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-input v-model="prog.Width" readonly></b-input>
                </b-input-group>
                <b-input-group
                  :prepend="$t('高度')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-input v-model="prog.Height" readonly></b-input>
                </b-input-group>
              </b-form>
            </div>
          </div>
        </div>
        <div
          v-if="currentRes"
          class="card border-primary mb-3"
          style="margin-top: 10px"
        >
          <div class="card-header">{{ $t("素材设置") }}</div>
          <div class="card-body text-primary">
            <div>
              <b-form inline>
                <b-input-group
                  :prepend="$t('名称')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-input v-model="currentRes.name"></b-input>
                </b-input-group>
                <b-input-group
                  :prepend="$t('类型')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-input v-model="currentRes.type" readonly></b-input>
                </b-input-group>
                <b-input-group
                  v-if="currentRes.Value != 4"
                  :prepend="$t('播放时长')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-input v-model="currentRes.duration"></b-input>
                </b-input-group>
                <b-input-group
                  :prepend="$t('入场动画')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-select
                    :options="inAimation"
                    v-model="currentRes.inAnimation.type"
                  ></b-select>
                </b-input-group>
                <b-input-group
                  :prepend="$t('入场时长')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-input v-model="currentRes.inAnimation.duration"></b-input>
                </b-input-group>
                <template v-if="currentRes.Value == 4">
                  <b-input-group
                    :prepend="$t('滚动动画')"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-select
                      :options="inAimation"
                      v-model="currentRes.scrollAttribute.animation"
                      @change="scrollChange($event, 0)"
                    ></b-select>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('滚动时长')"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-input
                      v-model="currentRes.scrollAttribute.speed"
                      @change="scrollChange($event, 1)"
                    ></b-input>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('滚动频率')"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-input
                      v-model="currentRes.duration"
                      @change="scrollChange($event, 2)"
                    ></b-input>
                  </b-input-group>
                </template>
                <b-input-group
                  :prepend="$t('显示类型')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-if="currentRes.Value == 0"
                >
                  <b-select v-model="currentRes.displayType">
                    <option value="STATIC">静态</option>
                    <option value="PAGE_SWITCH">翻页</option>
                  </b-select>
                </b-input-group>
                <b-input-group
                  :prepend="$t('显示类型')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-if="currentRes.Value == 5"
                >
                  <b-select v-model="currentRes.displayType">
                    <option value="SCROLL">滚动</option>
                    <option value="PAGE_SWITCH">翻页</option>
                  </b-select>
                </b-input-group>
                <template v-if="currentRes.displayType == 'PAGE_SWITCH'">
                  <b-input-group
                    :prepend="$t('翻页动画')"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-select
                      :options="inAimation"
                      v-model="currentRes.pageSwitchAttribute.inAnimation.type"
                    ></b-select>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('动画时长')"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-input
                      v-model="
                        currentRes.pageSwitchAttribute.inAnimation.duration
                      "
                    ></b-input>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('翻页时长')"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-input
                      v-model="currentRes.pageSwitchAttribute.remainDuration"
                    ></b-input>
                  </b-input-group>
                </template>
                <template v-if="currentRes.displayType == 'SCROLL'">
                  <b-input-group
                    :prepend="$t('滚动速度')"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-select
                      :options="scrollspeed"
                      v-model="currentRes.scrollAttribute.speed"
                    ></b-select>
                  </b-input-group>
                </template>
                <template v-if="currentRes.Value == 3">
                  <b-input-group
                    :prepend="$t('文本内容')"
                    class="mr-sm-2 mb-sm-0"
                    style="width: 90%"
                  >
                    <b-input
                      v-model="currentRes.lines[0].textAttributes[0].content"
                    ></b-input>
                  </b-input-group>
                </template>
                <template v-if="currentRes.type == 'ARCH_TEXT'">
                  <b-input-group
                    :prepend="$t('字体')"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-select
                      :options="fontFamily"
                      v-model="currentRes.lines[0].textAttributes[0].fontFamily"
                      @change="textChange"
                    ></b-select>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('字体大小')"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-input
                      type="number"
                      v-model="currentRes.lines[0].textAttributes[0].fontSize"
                      @change="textChange"
                    ></b-input>
                  </b-input-group>
                  <b-input-group :prepend="$t('加粗')" class="mr-sm-2 mb-sm-0">
                    <b-form-checkbox
                      switch
                      size="lg"
                      v-model="currentRes.lines[0].textAttributes[0].isBold"
                      @change="textChange($event, 0)"
                    ></b-form-checkbox>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('下划线')"
                    class="mr-sm-2 mb-sm-0"
                  >
                    <b-form-checkbox
                      switch
                      size="lg"
                      v-model="
                        currentRes.lines[0].textAttributes[0].isUnderLine
                      "
                      @change="textChange($event, 1)"
                    ></b-form-checkbox>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('背景颜色')"
                    class="mr-sm-2 mb-sm-0"
                  >
                    <el-color-picker
                      v-model="currentRes.backgroundColor"
                      @change="textChange"
                    ></el-color-picker>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('字体颜色')"
                    class="mr-sm-2 mb-sm-0"
                  >
                    <el-color-picker
                      v-model="currentRes.lines[0].textAttributes[0].textColor"
                      @change="textChange"
                    ></el-color-picker>
                  </b-input-group>
                </template>
                <template v-if="currentRes.type == 'SIMPLE_RSS'">
                  <b-input-group
                    :prepend="$t('刷新频率')"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-input
                      type="number"
                      v-model="currentRes.updatePeriod"
                    ></b-input>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('字体')"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-select
                      :options="fontFamily"
                      v-model="currentRes.bodyTextAttr.fontFamily"
                      @change="bodyChange"
                    ></b-select>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('字体大小')"
                    class="mb-2 mr-sm-2 mb-sm-0"
                  >
                    <b-input
                      type="number"
                      v-model="currentRes.bodyTextAttr.fontSize"
                      @change="bodyChange"
                    ></b-input>
                  </b-input-group>
                  <b-input-group :prepend="$t('加粗')" class="mr-sm-2 mb-sm-0">
                    <b-form-checkbox
                      switch
                      size="lg"
                      v-model="currentRes.bodyTextAttr.isBold"
                      @change="bodyChange($event, 0)"
                    ></b-form-checkbox>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('下划线')"
                    class="mr-sm-2 mb-sm-0"
                  >
                    <b-form-checkbox
                      switch
                      size="lg"
                      v-model="currentRes.bodyTextAttr.isUnderLine"
                      @change="bodyChange($event, 1)"
                    ></b-form-checkbox>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('背景颜色')"
                    class="mr-sm-2 mb-sm-0"
                  >
                    <el-color-picker
                      v-model="currentRes.backgroundColor"
                      @change="bodyChange"
                    ></el-color-picker>
                  </b-input-group>
                  <b-input-group
                    :prepend="$t('字体颜色')"
                    class="mr-sm-2 mb-sm-0"
                  >
                    <el-color-picker
                      v-model="currentRes.bodyTextAttr.textColor"
                      @change="bodyChange"
                    ></el-color-picker>
                  </b-input-group>
                </template>
              </b-form>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<style scoped>
.mb-2 {
  width: 45% !important;
}
#prog_nova {
  background-color: black;
}
.subResize {
  height: 100%;
  width: 100%;
}
</style>
<script>
import textscroll from "@/components/textscroll";
import {
  GroupArray,
  GetNovaResName,
  GetGuid,
  GetNovaInmation,
} from "@/assets/utils/datef";
import { BaseUrl } from '../../../assets/utils/config';
export default {
  components: { textscroll },
  data: function () {
    return {
      res: [],
      progCStyle: {
        width: "0px",
        height: "0px",
      },
      prog: {},
      currentRes: null,
      dragData: null,
      inAimation: GetNovaInmation(),
      fontFamily: [
        { value: "SimSun", text: "宋体" },
        { value: "Microsoft YaHei", text: "微软雅黑" },
        { value: "Arial", text: "Arial字体" },
        { value: "Wingdings2", text: "Wingdings2" },
        { value: "Calibri", text: "Calibri" },
      ],
      scrollspeed: [
        { value: 1, text: "特慢" },
        { value: 3, text: "慢" },
        { value: 5, text: "中等" },
        { value: 7, text: "快" },
        { value: 10, text: "特快" },
      ],
    };
  },
  mounted: function () {
    this.getRes();
    this.getProg();
  },
  methods: {
    onDragstop(X, Y) {
      this.currentRes.layout.x = X;
      this.currentRes.layout.y = Y;
    },
    onResizing(x, y, w, h) {
      this.currentRes.layout.x = x;
      this.currentRes.layout.y = y;
      this.currentRes.layout.width = w;
      this.currentRes.layout.height = h;
    },
    scrollChange(event, type) {
      if (event != undefined) {
        switch (type) {
          case 0:
            this.currentRes.mediaList.forEach((x) => {
              x.inAnimation.type = this.currentRes.scrollAttribute.animation;
            });
            break;
          case 1:
            this.currentRes.mediaList.forEach((x) => {
              x.inAnimation.duration = this.currentRes.scrollAttribute.speed;
            });
            break;
          case 2:
            this.currentRes.mediaList.forEach((x) => {
              x.duration = this.currentRes.duration;
            });
            break;
        }
      }
    },
    bodyChange(event, type, data) {
      if (!data) {
        data = this.currentRes;
      }
      if (event != undefined) {
        if (type == 0) {
          data.bodyTextAttr.isBold = event;
        }
        if (type == 1) {
          data.bodyTextAttr.isUnderLine = event;
        }
      }
      data.bodyTextAttr.Style = {
        "font-size": data.bodyTextAttr.fontSize + "px",
        "font-family": data.bodyTextAttr.fontFamily,
        "font-weight": data.bodyTextAttr.isBold ? "bold" : "normal",
        "text-decoration": data.bodyTextAttr.isUnderLine
          ? "underline"
          : "unset",
        "background-color": data.backgroundColor,
        color: data.bodyTextAttr.textColor,
        width: "100%",
        height: "100%",
        "overflow-y": "hidden",
      };
    },
    textChange(event, type, data) {
      if (!data) {
        data = this.currentRes;
      }
      if (event != undefined) {
        if (type == 0) {
          data.lines[0].textAttributes[0].isBold = event;
        }
        if (type == 1) {
          data.lines[0].textAttributes[0].isUnderLine = event;
        }
      }
      data.lines[0].textAttributes[0].Style = {
        "font-size": data.lines[0].textAttributes[0].fontSize + "px",
        "font-family": data.lines[0].textAttributes[0].fontFamily,
        "font-weight": data.lines[0].textAttributes[0].isBold
          ? "bold"
          : "normal",
        "text-decoration": data.lines[0].textAttributes[0].isUnderLine
          ? "underline"
          : "unset",
        "background-color": data.backgroundColor,
        color: data.lines[0].textAttributes[0].textColor,
        width: "100%",
        height: "100%",
        "overflow-y": "hidden",
      };
    },
    onActive(sid) {
      this.currentRes = this.prog.Widgets.filter((x) => x.Sid == sid)[0];
    },
    allowDrop(event) {
      event.preventDefault();
    },
    addRes(it) {
      this.dragData = it;
      var obj = this.getDefalutWidget();
      obj.name = `${this.dragData.TypeXX}${this.prog.Widgets.length}`;
      obj.type = this.dragData.TypeXX;
      obj.Value = this.dragData.Type;
      obj.zIndex = this.prog.Widgets.length;
      switch (this.dragData.Type) {
        case 0:
          //文本
          obj.displayType = "STATIC";
          break;
        //图片
        case 1:
        case 2:
          obj.md5 = this.dragData.MD5;
          obj.url = this.dragData.Content;
          obj.size = this.dragData.Size;
          break;
        case 3:
          obj.displayType = "SCROLL";
          break;
        case 4:
          //滚动图片
          obj.scrollAttribute.animation = "NONE";
          obj.scrollAttribute.speed = 2;
          obj.mediaList = [];
          var json = JSON.parse(this.dragData.Custom);
          var i = 0;
          json.forEach((x) => {
            var imgs = this.getDefalutWidget();
            imgs.url = x.Url;
            imgs.md5 = x.Md5;
            imgs.size = x.Size;
            imgs.duration = 10;
            imgs.zIndex = i;
            imgs.type = "PICTURE";
            i++;
            obj.mediaList.push(imgs);
            imgs.inAimation = {
              type: "NONE",
              duration: 0,
            };
          });
          break;
        case 5:
          //传感器数据
          var sid = this.dragData.Content;
          var array = JSON.parse(this.dragData.Custom);
          obj.displayType = "SCROLL";
          obj.Url = `${BaseUrl}/rss/sensorrss?sensorSid=${sid}&sensorData=${array.sensor.toString()}`;
          this.$ksajax
            .ksGetPromise(obj.Url)
            .then((data) => {
              var list = new DOMParser()
                .parseFromString(data.data, "text/xml")
                .getElementsByTagName("description");
              var array = [];
              for (var i = 0; i < list.length; i++) {
                array.push(list[i].textContent);
              }
              obj.md5 = array.join("\n");
            })
            .catch((err) => {
              this.$throw(err);
            });
          break;
        case 6:
          obj.url = this.dragData.Content;
          obj.duration = 60;
          break;
      }
      this.prog.Widgets.push(obj);
    },
    getDefalutWidget() {
      var obj = {
        Sid: GetGuid(),
        Value: -1,
        name: "",
        type: "",
        md5: "",
        size: 0,
        duration: 30,
        url: "",
        zIndex: 0,
        displayType: "",
        backgroundColor: "#00000000",
        updatePeriod: 15,
        titleEnable: false,
        pubTimeEnable: false,
        bodyEnable: true,
        bodyImageEnable: false,
        layout: {
          x: 0,
          y: 0,
          width: 100,
          height: 100,
        },
        inAnimation: {
          type: "NONE",
          duration: 0,
        },
        scrollAttribute: {
          animation: "MARQUEE_LEFT",
          speed: 5,
        },
        pageSwitchAttribute: {
          inAnimation: {
            type: "NONE",
            duration: 5,
          },
          remainDuration: 20,
        },
        lines: [
          {
            textAttributes: [
              {
                content: "请输入文本内容",
                fontFamily: "Arial",
                fontSize: 25,
                textColor: "#ffffff",
                isBold: false,
                isUnderLine: false,
                backgroundColor: "#00000000",
                Style: {
                  "font-size": "25px",
                  "font-family": "Arial",
                  "font-weight": "normal",
                  "text-decoration": "unset",
                  "background-color": "#00000000",
                  color: "#ffffff",
                  width: "100%",
                  height: "100%",
                  "overflow-y": "hidden",
                },
              },
            ],
          },
        ],
        bodyTextAttr: {
          fontFamily: "Arial",
          fontSize: 25,
          textColor: "#ffffff",
          isBold: false,
          isUnderLine: false,
          isItalic: false,
          Style: {
            "font-size": "25px",
            "font-family": "Arial",
            "font-weight": "normal",
            "text-decoration": "unset",
            "background-color": "#00000000",
            color: "#ffffff",
            width: "100%",
            height: "100%",
            "overflow-y": "hidden",
          },
        },
      };
      return obj;
    },
    sortUp() {
      if (!this.currentRes) {
        throw "请选择元素";
      }
      this.currentRes.zIndex += 1;
    },
    sortDown() {
      if (!this.currentRes) {
        throw "请选择元素";
      }
      this.currentRes.zIndex -= 1;
      if (this.currentRes.zIndex < 0) {
        this.currentRes.zIndex = 0;
      }
    },
    delRes() {
      if (!this.currentRes) {
        throw "请选择元素";
      }
      this.prog.Widgets = this.prog.Widgets.filter(
        (x) => x.Sid != this.currentRes.Sid
      );
      this.currentRes = null;
    },
    save() {
      if (this.prog.Widgets.length == 0) {
        throw "不能保存空的节目";
      }
      var obj = {
        Name: this.prog.Name,
        Sid: this.prog.Sid,
        Desc: JSON.stringify(this.prog.Widgets),
      };
      this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/UpdateNovaProgDetail", obj, true)
        .then(() => {
          this.$loading().close();
          this.$throw("保存成功");
        })
        .catch((err) => {
          this.$loading().close();
          this.$throw(err);
        });
    },
    getProg() {
      var sid = this.$route.query.sid;
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/led/GetNovaProgDetail", sid, true)
        .then((obj) => {
          this.progCStyle.height = `${obj.Height}px`;
          this.progCStyle.width = `${obj.Width}px`;
          obj.Widgets = JSON.parse(obj.Describe);
          this.prog = obj;
          this.loading.close();
        })
        .catch((err) => {
          this.$throw(err);
          this.loading.close();
        });
    },
    getRes() {
      let obj = {
        Index: 0,
        Count: 1000,
      };
      this.$ksajax
        .ksPostPromise("/api/resource/GetLedResources", obj, true)
        .then((data) => {
          if (data.Items && data.Items.sort) {
            data.Items = data.Items.filter(
              (x) =>
                x.Type == 1 ||
                x.Type == 2 ||
                x.Type == 5 ||
                x.Type == 6 ||
                x.Type == 4
            );
            var items = data.Items.sort((a, b) => {
              a.Type - b.Type;
            });
            items.forEach((x) => {
              var obj = GetNovaResName(x.Type);
              x.TypeName = this.$t(obj.text);
              x.TypeXX = obj.type;
            });
            var res = GroupArray("TypeName", data.Items);
            res.push({
              group: "文本",
              data: [
                {
                  Sid: GetGuid(),
                  Type: 0,
                  TypeName: "文本",
                  Name: "文本",
                  TypeXX: "ARCH_TEXT",
                },
              ],
            });
            res.push({
              group: "滚动文本",
              data: [
                {
                  Sid: GetGuid(),
                  Type: 3,
                  TypeName: "滚动文本",
                  Name: "滚动文本",
                  TypeXX: "ARCH_TEXT",
                },
              ],
            });
            res.forEach((x) => {
              x.id = x.data[0].Sid;
            });
            this.res = res;
          }
        });
    },
  },
};
</script>