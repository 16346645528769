<template>
    <div class="white" v-loading="loading" style="position:relative;height:100%; overflow:hidden;">

        <ksmap ref="map" width='100%' :showCenter="false" height="100%"
               v-bind:lat='0' v-bind:lng='0' viewMode="2D"
               v-bind:zoom=25>
        </ksmap>

        <div class="left_layout">
            <div class="title"><h5 style="padding:5px;">经过路线</h5><hr/></div>
            <div class="content">
                <div class="block">
                    <el-timeline>
                        <el-timeline-item v-for="item in items" :key="item.Sid" :timestamp="item.DetectTime" placement="top">
                            <div class="panel">
                                <div class="left">
                                    <b-img v-if="!item.ImageUrl" class="face" src="/img/unknow.png"></b-img>
                                    <b-img v-if="item.ImageUrl" class="face" :src="item.ImageUrl"></b-img>
                                </div>
                                <div class="right">
                                    <p>{{item.DeviceName}}</p>
                                    <p>{{item.Similarity*100}}%</p>
                                </div>
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </div>
        </div>
        <el-row class="right_layout">
            <el-col :span="24" style=" text-align:center; align-items :center;min-height:300px; border-bottom:2px solid #b7b9e6;">
                <b-img v-if="!fData.FaceUrl" class="face" src="/img/unknow.png"></b-img>
                <b-img v-if="fData.FaceUrl" class="face" :src="fData.FaceUrl"></b-img>
            </el-col>
            <el-col :span="24">
                <!-- 人形检测 -->
                <el-row style="padding:10px;" v-if="fData.Type==0">
                    <el-col :span="24">性别： {{fData.SexString || "-"}}</el-col>
                    <el-col :span="12"> 上衣颜色： {{fData.JacetColor || "-"}}</el-col>
                    <el-col :span="12">是否骑车：{{fData.IsRide?"是":"否"}}</el-col>
                    <el-col :span="24">是否带帽：{{fData.IsHat?"是":"否"}}</el-col>
                    <el-col :span="24">下装类型： {{fData.TrousersType || "-"}}</el-col>
                    <el-col :span="24">下装颜色：  {{fData.TrousersColor || "-"}}</el-col>
                    <el-col :span="24">发型： {{fData.HairStyle || "-"}}</el-col>
                    <el-col :span="24">是否拎东西：{{fData.IsThings?"是":"否"}}</el-col>
                </el-row>
                <!-- 人脸检测 -->
                <el-row style="padding:10px;" v-if="fData.Type==1">
                    <el-col :span="12">性别： {{fData.Sex==0?"男":"女"}}</el-col>
                    <el-col :span="12">年龄： {{fData.Age || "-"}}</el-col>
                    <el-col :span="24">是否戴眼镜： {{fData.IsGlass?"是":"否"}}</el-col>
                </el-row>
                <!-- 人脸对比 -->
                <el-row style="padding:10px;" v-if="fData.Type==2">
                    <el-col :span="24">姓名： {{fData.Name|| "-"}}</el-col>
                    <el-col :span="12">性别： {{fData.Sex==0?"男":"女"}}</el-col>
                    <el-col :span="12">年龄： {{fData.Age || "-"}}</el-col>
                    <el-col :span="24">身份证： {{fData.IdNumber || "-"}}</el-col>
                    <el-col :span="24">住址： {{fData.Address || "-"}}</el-col>
                    <el-col :span="24">备注： {{fData.Describe || "-"}}</el-col>
                </el-row>
                <hr />
            </el-col>
            <el-col :span="24" class="toolbar" style="padding:10px;">
                开始时间
                <el-date-picker v-model="searchData.BeginTime" type="datetime" placeholder="选择日期时间"> </el-date-picker>
            </el-col>
            <el-col :span="24" class="toolbar" style="padding:10px;">
                结束时间
                <el-date-picker v-model="searchData.EndTime" type="datetime" placeholder="选择日期时间"> </el-date-picker>
            </el-col>
            <el-col :span="24" class="toolbar" style="padding:10px;">
                相似度%
                <el-input-number v-model="searchData.SimilarityShow" @change="handleChange" :min="1" :max="100" label="描述文字" size="small"></el-input-number>
            </el-col>
            <el-col :span="24" style="text-align:center;">
                <el-button type="primary" icon="el-icon-search" @click="select">查询</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<style>
    .white {
        position: relative;
    }

    .left_layout {
        border: 2px solid grey;
        background-color: #fff;
        position: absolute;
        top: 35px;
        left: 0;
        width: 350px;
        height: 530px;
        overflow: auto;
    }

        .left_layout .panel > div {
            display: inline-block;
            vertical-align: top;
        }

            .left_layout .panel > div.right {
                padding: 10px;
            }

        .left_layout .face {
            width: 80px;
        }

    .right_layout .face {
        width: 280px;
        margin: 10px;
    }

    .right_layout {
        border: 2px solid grey;
        background-color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        width: 350px;
        height: 100%;
    }

    .right_layout .toolbar{
        padding:10px 0;
    }
</style>

<script>
    import ksmap from '@/components/map'
import config from "@/assets/utils/config"
    export default {
        components: { ksmap },
        data: function () {
            return {
      baseUrl:config.BaseUrl,
                loading: true,
                map: null,
                items: [],
                sid: this.$route.query.Sid,
                searchData: {
                },
                fData: {
                },
            }
        },
        mounted: function () {
            this.select();
        },
        methods: {
            select() {
                let param = {
                    Index: 0,
                    Count: 100,
                    Condition: this.searchData,
                }
                var faceSetSid = this.$route.query.FaceSetSid;
                var pathArr = [];
                // 因为只有人脸对比才会有FaceSetSid，人形和人脸检测只有一个数据
                // 情况一：有FaceSetSid
                if (faceSetSid != "null") {
                    param.Condition.FaceSetSid = faceSetSid;
                    if (param.Condition.SimilarityShow) param.Condition.Similarity = param.Condition.SimilarityShow / 100;
                  
                }
                else {
                // 情况二：没有FaceSetSid
                    param.Condition.Sid = this.sid;
                    
                }
                this.$ksajax.ksPostPromise("/api/face/SearchMessages", param, true).then((data) => {
                    this.loading = false;
                    var device = [];
                    this.items = data.Items;
                    data.Items.forEach(x => {
                        //x.Lat += Math.random()*0.001;
                        //x.Lng += Math.random()*0.001;
                        device.push(
                            {
                                lat: x.Lat,
                                lng: x.Lng,
                                icon: `${this.baseUrl}/img/new/ai_face.png`,
                                url: "/admin/water/detail?sid=" + x.Sid,
                                title: x.Name
                            }
                        );
                        pathArr.push([x.Lng, x.Lat]);
                    });
                    this.$refs.map.addDevice(device, 0);
                    this.getFaceSetDetail();
                    this.map = this.$refs.map.map;
                    this.$refs.map.drawLine(pathArr);
                }).catch((err) => {
                    this.$throw(err, this);
                    this.loading = false;
                })
            },
            getFaceSetDetail() {
                this.$ksajax.ksPostPromise("/api/face/GetMessageDetail", this.sid, true).then((data) => {
                    this.loading = false;
                    this.fData = data;
                }).catch((err) => {
                    this.$throw(err, this);
                    this.loading = false;
                })
            },
            handleChange() {

            }
        }
    }
</script>
