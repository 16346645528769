<template>
  <div v-loading="loading">
    
      <tablepage ref="tableref" id="tableref"
         v-on:currentPage='currentPage'
         v-on:currentRow='currentRow'
         :cols='cols'
         :items='items'
         :total='total'
         :perpage='Count'
        ></tablepage>
  </div>
</template>
<script>
import tablepage from '@/components/tablepage'
export default {
    components:{tablepage},
  data: function () {
    return {
      items: [],
      loading: false,
      sid: "",
      index: 0,
      Count: 10,
      total:0,
      cols:[
           {name:this.$t("序号"),value:"Sid"},
           {name:this.$t("设备名称"),value:"DeviceName"},
           {name:this.$t("内容"),value:"Content"},
           {name:this.$t("敏感词"),value:"SensitiveWord"},
           {name:this.$t("类型"),value:"WordType"},
           {name:this.$t("时间"),value:"RecognizeTime"},
      ]
    };
  },
  mounted: function () {
    this.sid= this.$route.query.Sid;
    this.select();
  },
  methods: {
      currentRow(){},
     currentPage(val){
          this.index=(val-1)*this.Count;
          this.select();
      },
    select() {
      this.loading = true;
      let obj = {
        Index: this.index,
        Count: this.Count,
        DeviceSid: this.sid,
      };
      this.$ksajax
        .ksPostPromise("/api/voice/GetAsrHistoriesByDevSid", obj, true)
        .then((r) => {
          this.items = r.Items;
          this.total = r.TotalCount;
          this.loading=false;
        })
        .catch((err) => {
         this.loading=false;
          this.$throw(err);
        });
    }
  }
};
</script>