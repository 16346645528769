<template>
    <div id="time_box" :style="style">
        <div id="time_box_axis"  @click="timeClick"></div>
        <div id="time_box_desc" :style="style">
            <div class="time_box_desc" v-for="item in Texts" v-bind:key="item.value">
                {{item.text}}
            </div>
            <div style="clear:both"></div>
        </div>
    </div>
</template>
<style scoped>
#time_box_axis{
    cursor: pointer;
}
.time_box_desc{
    width: 100px;
    text-align: left;
    float: left;
}
</style>
<script>
export default {
    props:{
        MUnit:Boolean
    },
    data:function(){
        return{
            width:3000,
            style:{
                width:"3000px"
            },
            Texts:[]
        }
    },
    mounted:function(){
        this.style.width=`${this.width}px`;
        this.Texts=this.GetTexts();
        this.$emit("timeUpdated", this.width);
    },
    methods:{
        updateWidth(scroll){
        this.width=(scroll+3000)-(scroll+3000)%100+100;
        this.style.width=`${this.width}px`;
        this.Texts=this.GetTexts();
        this.$emit("timeUpdated", this.width);
        },
        GetTexts(){
            var unit=1;
            if(this.MUnit){
                unit=30;
            }
            var list=[];
            for(var i=0;i<this.width/100-1;i++){
                list.push({
                    text:this.getTimeDes(i*10*unit),
                    value:i*10*unit
                });
            }
            return list;
        },
        getTimeDes(time){
            var hu=time%3600;
            var h=(time-hu)/3600;
            var mu=hu%60;
            var m=(hu-mu)/60;
            return `${this.getFixTime(h)}:${this.getFixTime(m)}:${this.getFixTime(mu)}`;
        },
        getFixTime(value){
            return value<10?`0${value}`:value;
        },
        timeClick(event){
            var count=event.offsetX/100; 
            var unit=1;
            if(this.MUnit){
                unit=30;
            }
            var value=parseInt(count*unit*10);
            this.$emit("timeClick",value);
        }
    }
}
</script>
