<template>
        <b-row class="netcam white">
            <b-col cols="12" md="4" lg="3" xl="2" class="side">
                <sidemenu v-on:clickItem='clickItem' :title="$t('摄像头列表')" :items='items'></sidemenu>
            </b-col>
            <b-col cols="12" md="8" lg="9" xl="10" class="sidecontent">
                 <natpay ref="natpay" :data='playdata' count="2"></natpay>
            </b-col>
        </b-row>
</template>

<script>
import sidemenu from '@/components/sidemenu'
import natpay from '@/components/natpay'

export default {
    components:{sidemenu,natpay},
    data:function(){
        return{
            items:[
            ],
            playdata:{},
            devices:[],
            loading:{},
    }},
    methods:{
        clickItem(sid){
            let choose =true;
            let device={};
            for(var i=0;i<this.items.length;i++){
                if(this.items[i].sid===sid){
                     device=this.items[i];
                }
            }
            if(choose){
                //播放
                this.$refs.natpay.start(device.device,()=>{
                },(data)=>{
                    this.$throw(data);
                });
            }
        }
    },
    
    mounted:function(){
        //获取设备列表
         let req={
              Index:0,
              Count:100000
          };
        var self=this;
        this.loading=this.$loading();
        this.$ksajax.ksPostPromise("/api/device/GetDeviceVideos",req,true).then((success)=>{
             var array=[];
            for(let i=0;i<success.Items.length;i++){
                array.push({
                    text:success.Items[i].DeviceName,
                    active:false,
                    sid:success.Items[i].Sid,
                    device:success.Items[i]
                });
            }
            self.items=array;
            this.loading.close();
        }).catch(err=>{
            this.$throw(err);
            this.loading.close();
        })
    },
}
</script>

