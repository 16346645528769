<template>
  <div id="checkdetail" class="white">
    <div class="container" style="padding:0px" v-loading="loading">
      <div class="checkdetail_title">
        <h4>巡检设备信息</h4>
        <hr>
        <b-alert show>
            <div class="info_alert">
            <span>名称:{{current.Device?current.Device.DeviceName:""}}</span>
            <span>型号:{{current.Device?current.Device.ModelNumber:""}}</span>
            </div>
        </b-alert>
      </div>
      <div class="checkdetail_title">
        <h4>巡检人信息</h4>
        <hr>
        <b-alert show>
            <div class="info_alert">
            <span>姓名:{{current.Reciver?current.Reciver.Name:""}}</span>
            <span>电话:{{current.Reciver?current.Reciver.PhoneNumber:""}}</span>
            <span>部门:{{current.Reciver?current.Reciver.PartmentName:""}}</span>
            </div>
        </b-alert>
      </div>
      <div class="checkdetail_title">
        <h4>巡检内容</h4>
        <hr>
        <b-alert show>
            <div class="info_alert">
            <span>时间:{{current.CheckTime}}</span>
            <span>状态:{{current.State==0?"正常":current.State==1?"异常":"废弃"}}</span>
            <span>描述:{{current.Content}}</span>
            </div>
        </b-alert>
      </div>
      <div class="checkdetail_title">
        <h4>现场图片</h4>
        <hr>
        <div v-if="current.Pics.length==0" style="font-size:16px">
            暂无图片
        </div>
        <div v-else>
            <div  v-for="item in current.Pics" :key="item" >
          <b-img :src="item" fluid class="w-100"></b-img>
          <hr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.checkdetail_title{
    color: black;
    font-size: 25px;
    width: 100%;
}
.info_alert>span{
    margin-right: 15px;
    font-size: 20px
}
</style>
<script>
export default {
    data:function(){return {
        current:{
            Pics:[]
        },
        loading:false
    }},
    mounted:function(){
        this.select();
    },
    methods:{
        select(){
            this.loading=true; 
            this.$ksajax
        .ksPostPromise("/api/info/GetDeviceLogDetail",  this.$route.query.sid, true).then(data=>{
            if(!data.Pics){
                data.Pics=[];
            }
            this.current=data;
            this.loading=false;
        }).catch(err=>{
            this.loading=false;
            this.$throw(err);
        })
        }
    }
};
</script>
