<template>
    <div>
        内部错误：500
        <div>
            {{$route.query.err}}
        </div>
    </div>
</template>
<style scoped>
body{
  width: 100% !important;
  height: 100% !important;
}
</style>

<script>
export default {
    mounted:function(){
    }
}
</script>
