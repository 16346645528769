<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :del="true"
      :insert="true"
      :publish="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
      v-on:publish="publish"
    ></actionbar>

    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/xixun/DelProgTask"
      :title="$t('删除')"
    ></deletedata>
    <b-modal
      id="modallg"
      ref="modallg"
      class="lgModal"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('名称')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Name" required></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('节目')" :label-cols="2">
          <b-form-select
            v-model="selected"
            :options="progs"
            value-field="Sid"
            text-field="name"
            style="width:80%;"
            @change="progChange"
          ></b-form-select>
          <el-button type="primary" @click="addItem" round size="small">{{$t('新增')}}</el-button>
        </b-form-group>
        <b-form-group
          v-bind:label="item.Prog.name"
          :label-cols="2"
          v-for="item in modalData.Items"
          :key="item.Sid"
        >
          <div class="sceneTemp">
            <div style="text-align:right;margin-bottom:5px;">
              <i class="fa fa-trash-o" style="cursor:pointer" @click="delItem(item.Sid)"></i>
            </div>
            <b-form-group v-bind:label="$t('优先级')" :label-cols="2">
              <b-form-input type="number" v-model="item.Priority" required></b-form-input>
            </b-form-group>
            <b-form-group v-bind:label="$t('重复次数')" :label-cols="2">
              <b-form-input type="number" v-model="item.RepeatTimes" required></b-form-input>
            </b-form-group>
            <b-form-group
              v-bind:label="$t('时间安排')"
              :label-cols="2"
              v-for="time in item.Schedules"
              :key="time.Sid"
            >
              <div class="sceneTemp">
                <div style="text-align:right;margin-bottom:5px;">
                  <i
                    class="fa fa-trash-o"
                    style="cursor:pointer"
                    @click="delSchedule(item,time.Sid)"
                  ></i>
                  <i class="fa fa-plus" style="cursor:pointer" @click="addSchedule(item,time.Sid)"></i>
                </div>
                <b-form-group v-bind:label="$t('日期')" :label-cols="2">
                  <b-form-checkbox
                    v-model="time.DateType"
                    value="All"
                    unchecked-value="Range"
                    @change="dateChange($event,time)"
                  >{{$t('不限')}}</b-form-checkbox>
                  <div v-if="time.DateType=='Range'">
                    <el-date-picker
                      v-model="time.StartDate"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>-
                    <el-date-picker
                      v-model="time.EndDate"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                  </div>
                </b-form-group>
                <b-form-group v-bind:label="$t('时间')" :label-cols="2">
                  <b-form-checkbox
                    v-model="time.TimeType"
                    value="All"
                    unchecked-value="Range"
                    @change="timeChange($event,time)"
                  >不限</b-form-checkbox>
                  <div v-if="time.TimeType=='Range'">
                    <el-time-picker
                      v-model="time.StartTime"
                      placeholder="选择时间"
                      value-format="HH:mm"
                    ></el-time-picker>-
                    <el-time-picker v-model="time.EndTime" placeholder="选择时间" value-format="HH:mm"></el-time-picker>
                  </div>
                </b-form-group>
                <b-form-group v-bind:label="$t('星期')" :label-cols="2">
                  <b-form-checkbox
                    v-model="time.FilterType"
                    value="None"
                    unchecked-value="Week"
                    @change="weekChange($event,time)"
                  >不限</b-form-checkbox>
                  <b-check-group
                    v-if="time.FilterType=='Week'"
                    :options="Weeks"
                    v-model="time.WeekFilter"
                  ></b-check-group>
                </b-form-group>
              </div>
            </b-form-group>
          </div>
        </b-form-group>
        <div style="text-align:center">
          <b-button type="submit" style="width:70%">{{$t("保存")}}</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="publishmodal"
      class="ksmodal"
      ref="publishmodal"
      size="lg"
      :title="$t('发布')"
      body-text-variant="dark"
      @ok="publishStage"
      @shown="publishShown"
    >
      <el-table stripe=""
        ref="multipleTable"
        :data="devices"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange" :empty-text="$t('暂无数据')"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column :label="$t('名称')" prop="DeviceName"></el-table-column>
        <el-table-column :label="$t('宽度')" prop="Width"></el-table-column>
        <el-table-column :label="$t('高度')" prop="Height"></el-table-column>
        <el-table-column :label="$t('位置')" prop="Address"></el-table-column>
      </el-table>
    </b-modal>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
import { GetGuid, formatDate } from "@/assets/utils/datef";
export default {
  components: { actionbar, tablepage, modify, deletedata },
  data: function() {
    return {
      devices: [],
      Weeks: [
        { value: 1, text: this.$t("星期一") },
        { value: 2, text: this.$t("星期二") },
        { value: 3, text: this.$t("星期三") },
        { value: 4, text: this.$t("星期四") },
        { value: 5, text: this.$t("星期五") },
        { value: 6, text: this.$t("星期六") },
        { value: 7, text: this.$t("星期天") }
      ],
      selected: "",
      modalData: this.resetData(),
      loading: {},
      cols: [
        { name: this.$t("名称"), value: "Name" },
        { name: this.$t("创建日期"), value: "CreateTime" },
        { name: this.$t("创建人"), value: "CreateUser" }
      ],
      items: [],
      total: 0,
      count: 20,
      index: 0,
      sid: "",
      title: "",
      current: null,
      progs: [],
      deviceSlected: []
    };
  },
  methods: {
    progChange(){
      this.addItem();
    },
    handleSelectionChange(val) {
      this.deviceSlected = val;
    },
    publishStage() {
      var data = {
        TaskSid: this.current.Sid,
        Devices: []
      };
      if (this.deviceSlected && this.deviceSlected.length > 0) {
        this.deviceSlected.forEach(x => {
          data.Devices.push(x.Sid);
        });
      } else {
        throw this.$t("请选择数据");
      }

      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/xixun/PublicPlay", data, true)
        .then(() => {
          this.$router.push({
          path: "/admin/XiXun/progLog"
        });
          this.loading.close();
        })
        .catch(err => {
          this.loading.close();
          this.$throw(err);
        });
    },
    publishShown() {
      this.$refs.multipleTable.clearSelection();
    },
    publish() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      new Promise((res, rej) => {
        if (this.devices.length != 0) {
          res();
        } else {
          this.loading = this.$loading();
          let obj = {
            Index: 0,
            Count: 10000
          };
          this.$ksajax
            .ksPostPromise("/api/device/GetDeviceLedAds", obj, true)
            .then(data => {
              this.devices = data.Items;
              res();
              this.loading.close();
            })
            .catch(err => {
              rej(err);
              this.loading.close();
            });
        }
      })
        .then(() => {
          this.$refs.publishmodal.show();
        })
        .catch(err => {
          this.$throw(err);
        });
    },
    onSubmit(event) {
      event.preventDefault();
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/xixun/AddProgTask", this.modalData, true)
        .then(() => {
          this.$refs.modallg.hide();
          this.loading.close();
          this.select();
        })
        .catch(err => {
          this.loading.close();
          this.$refs.modallg.hide();
          this.$throw(err);
        });
    },
    addSchedule(item) {
      item.Schedules.push({
        Sid: GetGuid(),
        DateType: "All",
        StartDate: null,
        EndDate: null,
        TimeType: "All",
        StartTime: null,
        EndTime: null,
        WeekFilter: [],
        FilterType: "None"
      });
    },
    delSchedule(item, sid) {
      if (item.Schedules.length <= 1) {
        return;
      } else {
        item.Schedules = item.Schedules.filter(x => x.Sid != sid);
      }
    },
    weekChange(event, time) {
      if (event == "None") {
        time.WeekFilter = [];
      }
    },
    timeChange(event, time) {
      if (event == "All") {
        time.StartTime = null;
        time.EndTime = null;
      } else {
        time.StartTime = formatDate(new Date(), "hh:mm");
        time.EndTime = formatDate(new Date(), "hh:mm");
      }
    },
    dateChange(event, time) {
      if (event == "All") {
        time.StartDate = null;
        time.EndDate = null;
      } else {
        time.StartDate = formatDate(new Date(), "yyyy-MM-dd");
        time.EndDate = formatDate(new Date(), "yyyy-MM-dd");
      }
    },
    select() {
      this.loading = this.$loading({ fullScreen: true });
      this.$ksajax
        .ksPostPromise("/api/xixun/GetProgTask", "", true)
        .then(data => {
          this.items = data;
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch(err => {
          this.$throw(err);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    del() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },
    modify() {
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.judge()
        .then(data => {
          this.progs = data;
          this.modalData = this.current;
          this.$refs.modallg.show();
          this.title = "修改";
        })
        .catch(err => {
          this.$throw(err);
        });
    },
    insert() {
      this.judge()
        .then(data => {
          this.progs = data;
          this.modalData = this.resetData();
          this.$refs.modallg.show();
          this.title = "新增";
        })
        .catch(err => {
          this.$throw(err);
        });
    },
    tableCreated() {},
    currentPage() {},
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      // data = data || { Name: "", Sid: "" };
      return {
        Sid: "",
        Name: "任务名称",
        Items: [
        ]
      };
    },
    delItem(id) {
      if (this.modalData.Items.length <= 1) {
        return;
      } else {
        this.modalData.Items = this.modalData.Items.filter(x => x.Sid != id);
      }
    },
    addItem() {
      var data = this.progs.find(x => x.Sid == this.selected);
      if (!data) {
        throw "请选择节目";
      }
      this.modalData.Items.push({
        Sid: GetGuid(),
        ProgSid: data.Sid,
        Priority: 1,
        RepeatTimes: 1,
        Schedules: [
          {
            Sid: GetGuid(),
            DateType: "All",
            StartDate: null,
            EndDate: null,
            TimeType: "All",
            StartTime: null,
            EndTime: null,
            WeekFilter: [],
            FilterType: "None"
          }
        ],
        Prog: {
          name: data.name
        }
      });
    },
    submited() {
      this.select();
    },
    deleted() {
      this.select();
    },
    judge() {
      return new Promise((res, rej) => {
        if (this.progs.length == 0) {
          this.loading = this.$loading();
          this.$ksajax
            .ksPostPromise("/api/xixun/GetProgram", "", true)
            .then(data => {
              if (data.length == 0) {
                rej("没有节目数据");
              } else {
                res(data);
              }
              this.loading.close();
            })
            .catch(err => {
              this.$throw(err);
              this.loading.close();
            });
        } else {
          res(this.progs);
        }
      });
    }
  },
  mounted: function() {
    this.select();
  }
};
</script>
