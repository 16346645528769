<template>
  <div class="Overview" style="color: white; padding-top: 10px">
    <div style="height: 100%">
      <div v-if="items.length == 0">
        <h3>{{ $t("暂无数据") }}</h3>
      </div>
      <div v-else class="ksflexcol" style="height: 100%; overflow: hidden">
        <div style="width: 100%">
          <div class="sensor-header ksflex">
            <div style="flex: 1; text-align: left">
              <b-form-radio-group
                id="btnradios1"
                buttons
                v-model="selected"
                :options="items"
                text-field="DeviceName"
                value-field="Sid"
                button-variant="primary"
                @change="changed"
                name="radiosBtnDefault"
              />
            </div>
            <div class="smhide">
              <span
                class="content_text"
                style="font-size: 20px; color: white; margin-right: 30px"
              >
                {{ $t("数据刷新时间")
                }}<span class="number">{{ updateTime }}</span>
              </span>
            </div>
            <div style="flex: 1; text-align: center">
              <i
                class="fa fa-table fa-2x"
                style="cursor: pointer"
                @click="tabled"
              ></i>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 70%; padding-top: 10px">
          <div v-if="table">
            <el-table stripe=""
              class="kstable"
              :data="datas"
              style="width: 100%"
              highlight-current-row
              :empty-text="$t('暂无数据')"
            >
              <el-table-column
                prop="DeviceName"
                label="设备名称"
              ></el-table-column>
              <el-table-column prop="Serial" label="序号"></el-table-column>
              <el-table-column label="风向(deg)">
                <template slot-scope="scope">
                  {{ scope.row.SensorData.Dm }}
                </template>
              </el-table-column>
              <el-table-column label="风速(m/s)">
                <template slot-scope="scope">
                  {{ scope.row.SensorData.Sm }}
                </template>
              </el-table-column>
              <el-table-column label="温度(℃)">
                <template slot-scope="scope">
                  {{ scope.row.SensorData.Ta }}
                </template>
              </el-table-column>
              <el-table-column label="湿度(%)">
                <template slot-scope="scope">
                  {{ scope.row.SensorData.Ua }}
                </template>
              </el-table-column>
              <el-table-column label="气压(hPa)">
                <template slot-scope="scope">
                  {{ scope.row.SensorData.Pa }}
                </template>
              </el-table-column>
              <el-table-column label="降雨量(mm)">
                <template slot-scope="scope">
                  {{ scope.row.SensorData.Rc }}
                </template>
              </el-table-column>
              <el-table-column label="太阳辐射(W/m²)">
                <template slot-scope="scope">
                  {{ scope.row.SensorData.Sr }}
                </template>
              </el-table-column>
              <el-table-column label="紫外线指数(μW/m²)">
                <template slot-scope="scope">
                  {{ scope.row.SensorData.Uv }}
                </template>
              </el-table-column>
              <el-table-column label="噪声(dB)">
                <template slot-scope="scope">
                  {{ scope.row.SensorData.Ns }}
                </template>
              </el-table-column>
              <el-table-column label="PM2.5(μg/m³)">
                <template slot-scope="scope">
                  {{ scope.row.SensorData.PM2_5 }}
                </template>
              </el-table-column>
              <el-table-column label="PM10(μg/m³)">
                <template slot-scope="scope">
                  {{ scope.row.SensorData.PM10 }}
                </template>
              </el-table-column>
              <el-table-column label="采集时间">
                <template slot-scope="scope">
                  {{ scope.row.CollectTime }}
                </template>
              </el-table-column>
              <el-table-column label="详情">
                <template slot-scope="scope">
                  <router-link
                    :to="`/admin/stand/sensordetail?sid=${scope.row.DeviceSid}`"
                    >详情</router-link
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else class="ksflex sensor-data">
            <div v-if="current.Dm || current.Dm == 0" id="sensor-dm">
              <div class="sensor-center ksflex text-align:center">
                <div style="text-align: center; flex: 1">
                  <img src="../../../assets/img/windDirection.png" />
                  <div id="Dm" class="animated">
                    <i style="font-size: 150%">{{ getDm(current.Dm) }}deg</i>
                  </div>
                  <div class="sensor-desc">
                    <span> {{ $t("风向") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="sensor-sm" v-if="current.Sm || current.Sm == 0">
              <div class="sensor-center ksflex text-align:center">
                <div style="text-align: center; flex: 1">
                  <img src="../../../assets/img/wind.png" />
                  <div id="Sm" class="animated">
                    <i style="font-size: 150%">{{ current.Sm }}m/s</i>
                  </div>
                  <div class="sensor-desc">
                    <span>{{ $t("风速") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="sensor-ta" v-if="current.Ta || current.Ta == 0">
              <div class="sensor-center ksflex text-align:center">
                <div style="text-align: center; flex: 1">
                  <img src="../../../assets/img/temperature.png" />
                  <div id="Ta" class="animated">
                    <i style="font-size: 150%">{{ current.Ta }}℃</i>
                  </div>
                  <div class="sensor-desc">
                    <span>{{ $t("温度") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="sensor-ua" v-if="current.Ua || current.Ua == 0">
              <div class="sensor-center ksflex text-align:center">
                <div style="text-align: center; flex: 1">
                  <img src="../../../assets/img/humidity.png" />
                  <div id="Ua" class="animated">
                    <i style="font-size: 150%">{{ current.Ua }}%</i>
                  </div>
                  <div class="sensor-desc">
                    <span>{{ $t("湿度") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="sensor-pa" v-if="current.Pa || current.Pa == 0">
              <div class="sensor-center ksflex text-align:center">
                <div style="text-align: center; flex: 1">
                  <img src="../../../assets/img/pressure.png" />
                  <div id="Pa" class="animated">
                    <i style="font-size: 150%">{{ current.Pa }}hPa</i>
                  </div>
                  <div class="sensor-desc">
                    <span>{{ $t("气压") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="sensor-rc" v-if="current.Rc || current.Rc == 0">
              <div class="sensor-center ksflex text-align:center">
                <div style="text-align: center; flex: 1">
                  <img src="../../../assets/img/rainfall.png" />
                  <div id="Rc" class="animated">
                    <i style="font-size: 150%">{{ current.Rc }}mm</i>
                  </div>
                  <div class="sensor-desc">
                    <span>{{ $t("降雨量") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="sensor-sr" v-if="current.Sr || current.Sr == 0">
              <div class="sensor-center ksflex text-align:center">
                <div style="text-align: center; flex: 1">
                  <img src="../../../assets/img/radiation.png" />
                  <div id="Sr" class="animated">
                    <i style="font-size: 150%">{{ current.Sr }}W/m²</i>
                  </div>
                  <div class="sensor-desc">
                    <span>{{ $t("太阳辐射") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="sensor-uv" v-if="current.Uv || current.Uv == 0">
              <div class="sensor-center ksflex text-align:center">
                <div style="text-align: center; flex: 1">
                  <img src="../../../assets/img/ultraviolet.png" />
                  <div id="Uv" class="animated">
                    <i style="font-size: 150%">{{ current.Uv }}W/m²</i>
                  </div>
                  <div class="sensor-desc">
                    <span>{{ $t("紫外线指数等级") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="sensor-nx" v-if="current.Ns || current.Ns == 0">
              <div class="sensor-center ksflex text-align:center">
                <div style="text-align: center; flex: 1">
                  <img src="../../../assets/img/noise.png" />
                  <div id="Ns" class="animated">
                    <i style="font-size: 150%">{{ current.Ns }}db</i>
                  </div>
                  <div class="sensor-desc">
                    <span>{{ $t("噪声") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="sensor-pm" v-if="current.PM2_5 || current.PM2_5 == 0">
              <div class="sensor-center ksflex text-align:center">
                <div style="text-align: center; flex: 1">
                  <img src="../../../assets/img/PM25.png" />
                  <div id="PM2_5" class="animated">
                    <i style="font-size: 150%">{{ current.PM2_5 }}μg/m³</i>
                  </div>
                  <div class="sensor-desc">
                    <span>PM2.5</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="sensor-pm10" v-if="current.PM10 || current.PM10 == 0">
              <div class="sensor-center ksflex text-align:center">
                <div style="text-align: center; flex: 1">
                  <img src="../../../assets/img/PM10.png" />
                  <div id="PM10" class="animated">
                    <i style="font-size: 150%">{{ current.PM10 }}μg/m³</i>
                  </div>
                  <div class="sensor-desc">
                    <span>PM10</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.kstable.el-table td,
.kstable.el-table th.is-leaf {
  border-bottom: 1px solid #3779c5;
}
.sensor-data > div .sensor-desc {
  opacity: 1;
  font-size: 180%;
  transition: all 0.5s ease 0s;
}
.sensor-center {
  position: absolute;
  width: 100%;
  height: 50%;
  top: 25%;
  background-color: white;
  opacity: 0.8;
  color: black;
}
.sensor-data img {
  width: 30%;
  border-style: none;
  border-radius: 50%;
}
.sensor-data {
  width: 100%;
  height: 100%;
}
.sensor-data > div {
  height: 100%;
  border: 1px solid;
  width: 100%;
  transition: width 0.5s ease 0s;
  position: relative;
}
.sensor-data > div:hover {
  width: 150%;
}
.sensor-data > div:hover .sensor-desc {
  opacity: 1;
}
#sensor-dm {
  background: radial-gradient(
    circle at 50px 50px,
    rgb(241, 132, 105),
    rgb(243, 191, 178)
  );
  background: -webkit-radial-gradient(
    circle at 50px 50px,
    rgb(241, 132, 105),
    rgb(243, 191, 178)
  );
  background: -moz-radial-gradient(
    circle at 50px 50px,
    rgb(241, 132, 105),
    rgb(243, 191, 178)
  );
  background: -o-radial-gradient(
    circle at 50px 50px,
    rgb(241, 132, 105),
    rgb(243, 191, 178)
  );
  background: -ms-radial-gradient(
    circle at 50px 50px,
    rgb(241, 132, 105),
    rgb(243, 191, 178)
  );
}
#sensor-sm {
  background: radial-gradient(
    at bottom left,
    rgb(215, 238, 114),
    rgb(8, 245, 134)
  );
  background: -webkit-radial-gradient(
    at left center,
    rgb(8, 178, 245),
    rgb(7, 110, 179)
  );
  background: -moz-radial-gradient(
    at left center,
    rgb(8, 178, 245),
    rgb(7, 110, 179)
  );
  background: -o-radial-gradient(
    at left center,
    rgb(8, 178, 245),
    rgb(7, 110, 179)
  );
  background: -ms-radial-gradient(
    at left center,
    rgb(8, 178, 245),
    rgb(7, 110, 179)
  );
}
#sensor-ta {
  background: radial-gradient(
    at right center,
    rgb(8, 245, 134),
    rgb(61, 245, 77)
  );
  background: -webkit-radial-gradient(
    at right center,
    rgb(8, 245, 134),
    rgb(61, 245, 77)
  );
  background: -moz-radial-gradient(
    at right center,
    rgb(8, 245, 134),
    rgb(61, 245, 77)
  );
  background: -o-radial-gradient(
    at right center,
    rgb(8, 245, 134),
    rgb(61, 245, 77)
  );
  background: -ms-radial-gradient(
    at right center,
    rgb(8, 245, 134),
    rgb(61, 245, 77)
  );
}
#sensor-ua {
  background: radial-gradient(
    at left center,
    rgb(8, 178, 245),
    rgb(7, 110, 179)
  );
  background: -webkit-radial-gradient(
    at left center,
    rgb(8, 178, 245),
    rgb(7, 110, 179)
  );
  background: -moz-radial-gradient(
    at left center,
    rgb(8, 178, 245),
    rgb(7, 110, 179)
  );
  background: -o-radial-gradient(
    at left center,
    rgb(8, 178, 245),
    rgb(7, 110, 179)
  );
  background: -ms-radial-gradient(
    at left center,
    rgb(8, 178, 245),
    rgb(7, 110, 179)
  );
}

#sensor-pa {
  background: radial-gradient(
    at right bottom,
    rgb(226, 36, 201),
    rgb(73, 107, 170)
  );
  background: -webkit-radial-gradient(
    at right bottom,
    rgb(226, 36, 201),
    rgb(73, 107, 170)
  );
  background: -moz-radial-gradient(
    at right bottom,
    rgb(226, 36, 201),
    rgb(73, 107, 170)
  );
  background: -o-radial-gradient(
    at right bottom,
    rgb(226, 36, 201),
    rgb(73, 107, 170)
  );
  background: -ms-radial-gradient(
    at right bottom,
    rgb(226, 36, 201),
    rgb(73, 107, 170)
  );
}
#sensor-rc {
  background: radial-gradient(
    at left top,
    rgb(78, 60, 238),
    rgb(129, 157, 206)
  );
  background: -webkit-radial-gradient(
    at left top,
    rgb(78, 60, 238),
    rgb(129, 157, 206)
  );
  background: -moz-radial-gradient(
    at left top,
    rgb(78, 60, 238),
    rgb(129, 157, 206)
  );
  background: -o-radial-gradient(
    at left top,
    rgb(78, 60, 238),
    rgb(129, 157, 206)
  );
  background: -ms-radial-gradient(
    at left top,
    rgb(78, 60, 238),
    rgb(129, 157, 206)
  );
}
#sensor-sr {
  background: radial-gradient(at left top, rgb(204, 76, 243), rgb(76, 80, 87));
  background: -webkit-radial-gradient(
    at left top,
    rgb(243, 76, 132),
    rgb(76, 80, 87)
  );
  background: -moz-radial-gradient(
    at left top,
    rgb(243, 76, 132),
    rgb(76, 80, 87)
  );
  background: -o-radial-gradient(
    at left top,
    rgb(243, 76, 132),
    rgb(76, 80, 87)
  );
  background: -ms-radial-gradient(
    at left top,
    rgb(243, 76, 132),
    rgb(76, 80, 87)
  );
}
#sensor-uv {
  background: radial-gradient(at left top, rgb(243, 76, 132), rgb(76, 80, 87));
  background: -webkit-radial-gradient(
    at left top,
    rgb(243, 76, 132),
    rgb(76, 80, 87)
  );
  background: -moz-radial-gradient(
    at left top,
    rgb(243, 76, 132),
    rgb(76, 80, 87)
  );
  background: -o-radial-gradient(
    at left top,
    rgb(243, 76, 132),
    rgb(76, 80, 87)
  );
  background: -ms-radial-gradient(
    at left top,
    rgb(243, 76, 132),
    rgb(76, 80, 87)
  );
}
#sensor-nx {
  background: radial-gradient(
    at right center,
    rgb(8, 245, 134),
    rgb(61, 245, 77)
  );
  background: -webkit-radial-gradient(
    at right center,
    rgb(8, 245, 134),
    rgb(61, 245, 77)
  );
  background: -moz-radial-gradient(
    at right center,
    rgb(8, 245, 134),
    rgb(61, 245, 77)
  );
  background: -o-radial-gradient(
    at right center,
    rgb(8, 245, 134),
    rgb(61, 245, 77)
  );
  background: -ms-radial-gradient(
    at right center,
    rgb(8, 245, 134),
    rgb(61, 245, 77)
  );
}
#sensor-pm {
  background: radial-gradient(
    at bottom left,
    rgb(215, 238, 114),
    rgb(8, 245, 134)
  );
  background: -webkit-radial-gradient(
    at bottom left,
    rgb(215, 238, 114),
    rgb(8, 245, 134)
  );
  background: -moz-radial-gradient(
    at bottom left,
    rgb(215, 238, 114),
    rgb(8, 245, 134)
  );
  background: -o-radial-gradient(
    at bottom left,
    rgb(215, 238, 114),
    rgb(8, 245, 134)
  );
  background: -ms-radial-gradient(
    at bottom left,
    rgb(215, 238, 114),
    rgb(8, 245, 134)
  );
}
#sensor-pm10 {
  background: radial-gradient(
    circle at 50px 50px,
    rgb(241, 132, 105),
    rgb(243, 191, 178)
  );
  background: -webkit-radial-gradient(
    circle at 50px 50px,
    rgb(241, 132, 105),
    rgb(243, 191, 178)
  );
  background: -moz-radial-gradient(
    circle at 50px 50px,
    rgb(241, 132, 105),
    rgb(243, 191, 178)
  );
  background: -o-radial-gradient(
    circle at 50px 50px,
    rgb(241, 132, 105),
    rgb(243, 191, 178)
  );
  background: -ms-radial-gradient(
    circle at 50px 50px,
    rgb(241, 132, 105),
    rgb(243, 191, 178)
  );
}
</style>

<script>
import { formatDate } from "@/assets/utils/datef";
export default {
  data: function () {
    return {
      table: false,
      selected: "",
      items: [],
      loading: {},
      datas: [],
      current: {},
      updateTime: null,
      currentSid: "",
    };
  },
  mounted: function () {
    this.select();
  },
  methods: {
    getDm(dm) {
      var array = dm.split(" ");
      return this.$t(array[0]) + " " + array[1];
    },
    select() {
      this.loading = this.$loading();
      new Promise((res, rej) => {
        //获取设备列表
        let req = {
          Index: 0,
          Count: 100000,
        };
        this.$ksajax
          .ksPostPromise("/api/device/GetDeviceSensors", req, true)
          .then((data) => {
            this.items = data.Items;
            res();
          })
          .catch((err) => {
            rej(err);
          });
      })
        .then(() => {
          return new Promise((res, rej) => {
            var list = [];
            this.items.forEach((x) => {
              list.push(x.Sid);
            });
            this.$ksajax
              .ksPostPromise("/api/device/GetSensorDatas", list, true)
              .then((data) => {
                // if(data.forEach){
                //     data.forEach(x=>{
                //         this.action(x.SensorData,x.DeviceSid);
                //     })
                // }
                this.datas = data;
                res();
              })
              .catch((err) => {
                rej(err);
              });
          });
        })
        .then(() => {
          if (this.items.length != 0) {
            this.selected = this.items[0].Sid;
            this.changed(this.selected);
          }
          this.loading.close();
        })
        .catch((err) => {
          this.$throw(err);
          this.loading.close();
        });
    },
    tabled() {
      this.table = !this.table;
    },
    changed(val) {
      this.datas.forEach((x) => {
        if (x.DeviceSid == val) {
          this.current = x.SensorData;
          this.updateTime = x.CollectTime;
          this.currentSid = x.DeviceSid;
        }
      });
    },
    dmFormat(d, r) {
      return `${d} ${r}`;
    },
    action(data, sid) {
      if (data.Dm || data.Dm == 0) {
        if (data.Dm > 337.5 && data.Dm <= 22.5) {
          data.Dm = this.dmFormat("北风", data.Dm);
        }
        if (data.Dm > 22.5 && data.Dm <= 67.5) {
          data.Dm = this.dmFormat("东北风", data.Dm);
        }
        if (data.Dm > 67.5 && data.Dm <= 112.5) {
          data.Dm = this.dmFormat("东风", data.Dm);
        }
        if (data.Dm > 112.5 && data.Dm <= 157.5) {
          data.Dm = this.dmFormat("东南风", data.Dm);
        }
        if (data.Dm > 157.5 && data.Dm <= 202.5) {
          data.Dm = this.dmFormat("南风", data.Dm);
        }
        if (data.Dm > 202.5 && data.Dm <= 247.5) {
          data.Dm = this.dmFormat("西南风", data.Dm);
        }
        if (data.Dm > 247.5 && data.Dm <= 292.5) {
          data.Dm = this.dmFormat("西风", data.Dm);
        }
        if (data.Dm > 292.5 && data.Dm <= 337.5) {
          data.Dm = this.dmFormat("西北风", data.Dm);
        }
      }
      if (sid == "201810241212573190592165") {
        data.PM2_5 = 10;
        var ran = parseInt(Math.random() * 10);
        data.PM2_5 += ran;
      }
    },
  },
  watch: {
    "$store.state.dataHubChange": function () {
      try {
        var data = this.$store.state.dataHub;
        this.action(data.Data, data.DeviceSid);
        if (data.CtgSid == "005") {
          this.datas.forEach((x) => {
            if (x.DeviceSid == data.DeviceSid) {
              // this.current=x.SensorData=data.Data;
              x.SensorData = data.Data;
              var da = formatDate(new Date(), "yyyy/MM/dd hh:mm:ss");
              x.CollectTime = da;
            }
          });
          if (this.currentSid == data.DeviceSid) {
            var keys = Object.keys(this.current);
            keys.forEach((x) => {
              var obj = data.Data[x];
              if (obj !== this.current[x]) {
                var element = document.getElementById(x);
                if (element) {
                  element.classList.remove("flip");
                }
                window.setTimeout(
                  (ele) => {
                    var es = document.getElementById(ele);
                    if (es) {
                      es.classList.add("flip");
                    }
                  },
                  500,
                  x
                );
              }
            });
            this.current = data.Data;
            var da = formatDate(new Date(), "yyyy/MM/dd hh:mm:ss");
            if (!this.table) {
              this.updateTime = da;
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
