<template>
  <div style="width: 100%; height: 100%;position:relative">
    <ksmap
      ref="map"
      width="100%"
      :showCenter="false"
      height="100%"
      v-bind:lat="22.986334"
      v-bind:lng="114.016356"
      v-bind:zoom="25"
    ></ksmap>
    <div style="position:absolute;z-index:1000;top:0;">
      <!-- <el-button @click="setPosition">设置位置</el-button> -->
      <el-dropdown split-button type="primary"  size="mini" style="padding:0 15px" @command="commandType">
        设备类型
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="0">全部</el-dropdown-item>
          <el-dropdown-item command="003">网关</el-dropdown-item>
          <el-dropdown-item command="004">灯具</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown split-button type="primary"  size="mini" @command="commandLamp">
        灯具类型
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="-1">全部</el-dropdown-item>
          <el-dropdown-item command="0">在线</el-dropdown-item>
          <el-dropdown-item command="1">离线</el-dropdown-item>
          <el-dropdown-item command="2">告警</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import ksmap from "@/components/map";
import fake from "@/assets/utils/fake";
import config from "@/assets/utils/config";
export default {
  components: { ksmap },
  data() {
    return {
      baseUrl: config.BaseUrl
    };
  },
  mounted() {
    this.setMap();
  },
  methods: {
    getFakeGateway() {
      return fake.getFakeGateways();
    },
    setMap() {
      var gs = this.getFakeGateway();
      var datas = [];
      gs.forEach(x => {
        datas.push({
          icon: `${this.baseUrl}/nicon/map/003_a.png`,
          lat: x.lat,
          lng: x.lng,
          title: x.name,
          sid: x.sid,
          serial: x.serial,
          draggable: false,
          obj: x
        });
      });
      var iconSize = {
        w: 26,
        h: 40
      };
      this.$refs.map.addDevice(datas, "003", iconSize);
      var ls = fake.getFakeLightDeath();
        datas=[];
      ls.forEach(x => {
        var ic =
          x.state == 0 ? "004.png" : x.state == 1 ? "004_a.png" : "004_o.png";
        datas.push({
          icon: `${this.baseUrl}/nicon/map/${ic}`,
          lat: x.lat,
          lng: x.lng,
          title: x.name,
          sid: x.sid,
          serial: x.serial,
          draggable: false,
          obj: x
        });
      });
      iconSize = {
        w: 26,
        h: 40
      };
      this.$refs.map.addDevice(datas, "004", iconSize);
      this.$refs.map.setFilter();
    },
    commandType(command){
        var array=[];
        if(command=="0"){
            array=["003","004"];
        }else{
            array=[command];
        }
         this.$refs.map.fresh(array);
         console.log(array);
    },
    commandLamp(command){
        this.$refs.map.displayByPar("004",command*1);
    },
    setPosition() {
      var config = this.$refs.map.getDeviceByType("003");
      var gates = [];
      config.markers.forEach(x => {
        x.Obj.lat = x.De.position.lat;
        x.Obj.lng = x.De.position.lng;
        gates.push(x.Obj);
      });
      console.log(JSON.stringify(gates));
      var c1 = this.$refs.map.getDeviceByType("004");
      var ls = [];
      c1.markers.forEach(x => {
        x.Obj.lat = x.De.position.lat;
        x.Obj.lng = x.De.position.lng;
        ls.push(x.Obj);
      });
      console.log(JSON.stringify(ls));
    }
  }
};
</script>