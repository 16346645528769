<template>
  <div class="white" style="min-height: 100%">
    <actionbar v-bind:select="true" v-on:select="select(1)"></actionbar>
    <div>
      <b-check v-model="actioned">未解决</b-check>
    </div>
    <el-table
      show-overflow-tooltip
      stripe=""
      fit
      :data="items"
      style="width: 100%"
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column
        :label="$t('告警设备')"
        prop="DeviceName"
      ></el-table-column>
      <!-- <el-table-column :label="$t('设备类型')" prop="DeviceType"></el-table-column> -->
      <!-- <el-table-column :label="$t('告警类型')">
        <template slot-scope="scope">{{getAlarmType(scope.row.AlarmType)}}</template>
      </el-table-column> -->
      <el-table-column
        :label="$t('告警时间')"
        prop="AlarmTime"
      ></el-table-column>
      <!-- <el-table-column :label="$t('接警人员')" prop="Receives"></el-table-column> -->
      <el-table-column :label="$t('状态')" prop="DisposeStatus">
        <template slot-scope="scope">{{
          scope.row.DisposeStatus == 0 ? "未解决" : "已解决"
        }}</template>
      </el-table-column>
      <el-table-column :label="$t('处理人')" prop="Disposer"></el-table-column>
      <el-table-column
        :label="$t('处理时间')"
        prop="DisposeTime"
      ></el-table-column>
      <el-table-column
        :label="$t('处理描述')"
        prop="Description"
      ></el-table-column>
      <el-table-column :label="$t('处理')">
        <template slot-scope="scope">
          <b-button
            :disabled="scope.row.DisposeStatus != 0"
            variant="success"
            size="small"
            @click="doalarm(scope.row.Sid)"
            >{{ "处理" }}</b-button
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('详情')" prop="DisposeStatus">
        <template slot-scope="scope">
          <router-link :to="`/admin/stand/errordetail?sid=${scope.row.Sid}`">{{
            $t("详情")
          }}</router-link>
        </template>
      </el-table-column>
    </el-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="total"
      @change="currentChange"
      size="md"
      align="center" 
      :first-text="$t('首页')"
      :prev-text="$t('上一页')"
      :next-text="$t('下一页')"
      :last-text="$t('最后一页')"
    />
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-form-group v-bind:label="$t('报警接收人')" :label-cols="2">
          <b-form-select
            :options="recivers"
            v-model="modalData.DisposerSid"
            value-field="Sid"
            text-field="Name"
          ></b-form-select>
        </b-form-group>
        <!-- <b-form-group v-bind:label="$t('处理描述')" :label-cols="2">
          <b-form-input type="text" v-model="modalData.Description" required></b-form-input>
        </b-form-group> -->
        <hr />
        <div class="modal-foot">
          <b-button style="width: 100px" v-on:click="hiden">Cancel</b-button>
          <b-button type="submit" style="width: 100px" variant="primary"
            >OK</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import deletedata from "@/components/deletedata";
export default {
  components: { actionbar, tablepage, deletedata },
  data: function () {
    return {
      index: 0,
      Count: 12,
      current: null,
      loading: {},
      items: [],
      total: 0,
      modalData: {},
      title: "",
      sid: "",
      recivers: [],
      currentPage: 1,
      actioned: true,
    };
  },
  watch: {
    actioned: function () {
      this.select(1);
    },
  },
  mounted: function () {
    this.select(1);
  },
  methods: {
    doalarm(sid) {
      this.modalData = {};
      this.sid = sid;
      this.loading = this.$loading();
      let obj = {
        Index: 0,
        Count: 1000,
      };
      this.$ksajax
        .ksPostPromise("/api/alarm/GetReceivers", obj, true)
        .then((data) => {
          this.recivers = data.Items;
          this.loading.close();
          this.$refs.modallg.show();
        })
        .catch((err) => {
          this.loading.close();
          this.$throw(err);
        });
    },
    getAlarmType(type) {
      var str = "其它故障";
      switch (type) {
        case 0:
          str = "离线告警";
          break;
        case 1:
          str = "设备上线";
          break;
        case 2:
          str = "设备故障";
          break;
        case 3:
          str = "通信故障";
          break;
      }
      return str;
    },
    select(current) {
      this.loading = this.$loading();
      if (this.current) {
        this.currentPage = current;
      }
      this.index = (current - 1) * this.Count;
      let obj = {
        Index: this.index,
        Count: this.Count,
        Checked: this.actioned,
      };
      this.$ksajax
        .ksPostPromise("/api/alarm/GetAlarms", obj, true)
        .then((r) => {
          this.items = r.Items;
          this.loading.close();
          this.total = r.TotalCount;
        })
        .catch((err) => {
          this.loading.close();
          this.$throw(err);
        });
    },
    tableCreated() {},
    currentRow(val) {
      this.current = val;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.loading = this.$loading();
      this.modalData.Sid = this.sid;
      this.$ksajax
        .ksPostPromise("/api/alarm/Processing", this.modalData, true)
        .then(() => {
          this.loading.close();
          this.$refs.modallg.hide();
          this.select(1);
        })
        .catch((err) => {
          this.$throw(err);
          this.$refs.modallg.hide();
        });
    },
    hiden() {
      this.$refs.modallg.hide();
    },
    currentChange(a) {
      this.select(a);
    },
  },
};
</script>

