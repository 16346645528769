import { render, staticRenderFns } from "./alarms.vue?vue&type=template&id=5b3ac6de"
import script from "./alarms.vue?vue&type=script&lang=js"
export * from "./alarms.vue?vue&type=script&lang=js"
import style0 from "./alarms.vue?vue&type=style&index=0&id=5b3ac6de&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports