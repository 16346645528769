<template>
  <div class="Stands white" v-loading="loading">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      v-bind:insert="true"
      v-bind:del="true"
      v-on:select="select"
      v-on:insert="insert"
      v-on:modify="modify"
      v-on:delete="del"
    >
    </actionbar>
    <tablepage
      ref="tableref"
      id="tableref"
      v-on:created="tableCreated"
      v-on:currentPage="currentPage"
      v-on:currentRow="currentRow"
      :cols="cols"
      :items="items"
      :total="total"
      :perpage="count"
    ></tablepage>

    <modify
      ref="modify"
      :mapitem="mapitem"
      :modaldata="modalData"
      :title="title"
      :labels="labels"
      v-on:submited="submited"
      url="/api/hikctl/SetDevice"
    ></modify>
    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/hikctl/DelDevice"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<style>
.admin {
  background-color: white;
}
</style>

<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
export default {
  data: function () {
    return {
      modalData: {
        Code: "",
        Username: "",
        Password: "",
        Ip: "",
        Port: "",
        IsNVR: true,
        DeviceName: "",
        Lat: 0,
        Lng: 0,
        Address: "",
        Serial: "",
        LStandSid: "",
        Sid: "",
        DTypeSid: "",
      },
      labels: this.getLable(true),
      title: "",
      cols: [
        { name: this.$t("设备名"), value: "DeviceName" },
        {
          name: this.$t("设备类型"),
          value: "TypeName",
          subvalue: "DeviceType",
        },
        {
          name: this.$t("设备型号"),
          value: "ModelNumber",
          subvalue: "DeviceType",
        },
        { name: this.$t("序号"), value: "Serial" },
        { name: this.$t("IP"), value: "Ip" },
        { name: this.$t("端口"), value: "Port" },
        { name: this.$t("位置"), value: "Address" },
        { name: this.$t("详情"), value: "url", link: true },
      ],
      items: [],
      index: 0,
      count: 5,
      total: 0,
      current: null,
      sid: "",
      mapitem: {
        value: "Address",
        require: true,
        subvalue: false,
        Position: {
          Lat: "Lat",
          Lng: "Lng",
        },
      },
      loading: false,
    };
  },
  mounted: function () {
    this.select();
  },
  components: {
    actionbar,
    tablepage,
    modify,
    deletedata,
  },
  created: function () {},
  methods: {
    getLable() {
      var array = [
        {
          label: this.$t("设备名"),
          type: "text",
          value: "DeviceName",
          require: true,
        },
        {
          label: this.$t("序列号"),
          type: "text",
          value: "Serial",
          require: true,
        },
        {
          label: this.$t("用户名"),
          type: "text",
          value: "Username",
          require: true,
        },
        {
          label: this.$t("密码"),
          type: "text",
          value: "Password",
          require: true,
        },
        {
          label: this.$t("IP"),
          type: "text",
          value: "Ip",
          require: true,
        },
        {
          label: this.$t("端口"),
          type: "text",
          value: "Port",
          require: true,
        },
        {
          label: this.$t("类型"),
          type: "select",
          value: "DTypeSid",
          require: true,
          bind: this.$store.getters.getDeviceType("200"),
        },
        {
          label: this.$t("灯杆"),
          type: "select",
          value: "LStandSid",
          require: false,
          bind: this.$store.state.lStands,
        },
        {
          label: this.$t("位置"),
          type: "map",
          value: "Address",
          require: true,
          subvalue: false,
        },
      ];
      return array;
    },
    showModify(insert) {
      this.labels = this.getLable(insert);
      this.$refs.modify.show();
    },
    currentRow(val) {
      this.current = val;
    },
    resetData() {
      return {
        Username: "",
        Password: "",
        Ip: "",
        Port: "",
        DeviceName: "",
        Lat: 0,
        Lng: 0,
        Address: "",
        Serial: "",
        LStandSid: "",
        Sid: "",
        DTypeSid: "",
      };
    },
    select() {
      this.loading = true;
      this.$ksajax
        .ksPostPromise("/api/hikctl/GetDevices", "", true)
        .then((data) => {
          data.forEach((element) => {
            element.url = "/admin/epolice/detail?sid=" + element.Sid;
          });
          this.items = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$throw(err);
        });
    },
    insert() {
      this.title = this.$t("新增");
      this.modalData = this.resetData();
      this.showModify(true);
    },
    modify() {
      if (!this.current) {
        this.$throw(this.$t("选择一条数据"), this);
      } else {
        this.modalData = {
          Username: this.current.UserName,
          Password: this.current.PassWord,
          Ip: this.current.Ip,
          Port: this.current.Port,
          DeviceName: this.current.DeviceName,
          Lat: this.current.Lat,
          Lng: this.current.Lng,
          Address: this.current.Address,
          Serial: this.current.Serial,
          LStandSid: "",
          Sid: this.current.Sid,
          DTypeSid: this.current.DeviceType.Sid,
        };
        if (this.current.LampStand) {
          this.modalData.LStandSid = this.current.LampStand.Sid;
        }
        this.title = this.$t("修改");
        this.showModify(false);
      }
    },
    del() {
      if (!this.current) {
        this.$throw(this.$t("选择一条数据"), this);
      } else {
        this.sid = this.current.Sid;
        this.$refs.del.show();
      }
    },
    tableCreated() {
      // this.select();
    },
    currentPage(val) {
      this.index = (val - 1) * this.count;
      this.select();
    },
    submited() {
      this.index = (1 - 1) * this.count;
      this.select();
    },
    deleted() {
      this.index = (1 - 1) * this.count;
      this.select();
    },
  },
};
</script>

