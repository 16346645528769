<template>
  <div :style="data.Custom">
    <div v-if="!line" style="height:100%;width:100%;overflow:hidden" ref="sensorscroll">
      <div
        v-for="item in this.items"
        :key="item.value"
      >{{items.length>1?item.text+":":""}}{{item.val}}{{item.pix}}</div>
    </div>
    <div v-if="line" style="width:100%;height:100%;position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;white-space: nowrap;">
      <span ref="ele1" :style="style1">{{lineText}}</span>
      <span ref="ele2" :style="style2">{{lineText}}</span>
    </div>
  </div>
</template>
<script>
import { getJson, sensorFormat } from "@/assets/utils/datef";

import textscroll from "@/components/textscroll";
export default {
  props: ["data", "compute"],
  components: { textscroll },
  data: function() {
    return {
      sid: "",
      items: [],
      scrollTimmer: null,
      computeTimmer: null,
      lineTimmer: null,
      horizon: true,
      pages: 1,
      current: 1,
      speed: 1000,
      line: false,
      style1: {},
      style2: {},
      HInit: false,
      lineText:"",
      position1:0,
      position2:0,
      hscorll:false,
      vscroll:false
    };
  },
  watch: {
    "data": function() {
      this.quit();
      this.init();
    }
  },
  mounted: function() {
    this.$nextTick(()=>{
      this.quit();
      this.init();
    })
  },
  methods: {
    init() {
      window.clearInterval(this.scrollTimmer);
      window.clearInterval(this.computeTimmer);
      if (this.data.Custom.speed) {
        this.speed = this.data.Custom.speed * 100;
      }
      this.sid = this.data.Data.Content;
      this.update();
      this.line=this.data.Custom["white-space"] == "nowrap";
          if (!this.line) {
            this.startVScroll();
          }else{
            this.startLineScroll();
          }
      // else {
      //   this.line={
      //     lineText:l,
      //     scroll:false
      //   }
      //     // var array=[];
      //     // if(this.items.forEach){
      //     //     this.items.forEach(x=>{
      //     //         array.push(`${x.text}${x.val}${x.pix}`)
      //     //     })
      //     // }
      //     // this.lineText=array.join(",");
      //     // this.lineTimmer=window.setInterval(()=>{
      //     //     var lele=this.$refs.linescroll;
      //     //     if(lele){
      //     //     var parent=lele.parentElement;
      //     //     if(lele.clientWidth>=parent.clientWidth){
      //     //         //滚动
      //     //     }
      //     //     }
      //     // },this.speed);

      // }
      if (this.compute) {
        this.computeTimmer = window.setInterval(this.update, 5000);
      }
    },
    startLineScroll() {
      this.line=true;
      if(this.hscorll){
        return;
      }
      this.lineTimmer = window.setInterval(() => {
      this.hscorll=true;
        var ele1 = this.$refs.ele1;
        var parent = ele1.parentElement;
        if (!this.HInit) {
          if (ele1.clientWidth <= parent.clientWidth) {
            this.position1=0;
            this.position2=parent.clientWidth;
          }else{
            this.position1=parent.clientWidth;
            this.position2=this.position1+ele1.clientWidth;
          }
          this.style1 = {
            position: "absolute",
            left: this.position1 + "px",
            height: "100%",
            top: 0,
            "line-height": parent.clientHeight + "px"
          };
          this.style2 = {
            position: "absolute",
            left: this.position2 + "px",
            height: "100%",
            top: 0,
            "line-height": parent.clientHeight + "px"
          };
          this.HInit=true;
        }else{
          //已经初始化
          if(ele1.clientWidth >= parent.clientWidth){
            //滚动
            this.position1-=1;
            this.position2-=1;
            if(this.position1<-1*ele1.clientWidth){
              this.position1=parent.clientWidth;
            }
            if(this.position2<-1*(ele1.clientWidth)){
              this.position2=1*(this.position1+ele1.clientWidth+50);
            }
             this.style1.left=this.position1+"px";
            //  this.style2.left=this.position2+"px";
          }
        }
      },this.data.Custom.speed);
    },
    startVScroll() {
      if(this.vscroll){
        return;
      }
      this.pages = 1;
      this.current = 1;
      this.scrollTimmer = window.setInterval(() => {
        this.vscroll=true;
        if (this.data.Custom["writing-mode"] == "vertical-lr") {
          this.horizon = false;
        }
        if (!this.$refs.sensorscroll) {
          window.clearInterval(this.scrollTimmer);
        }
        if (this.horizon) {
          //如果字休是横向，则上下分页
          var h = this.$refs.sensorscroll.clientHeight;
          var sh = this.$refs.sensorscroll.scrollHeight;
          if (h >= sh) {
            this.pages = 1;
          } else {
            var rate = sh % h;
            this.pages = Number.parseInt(sh / h);
            if (rate != 0) {
              this.pages += 1;
            }
          }
        } else {
          var w = this.$refs.sensorscroll.clientWidth;
          var sw = this.$refs.sensorscroll.scrollWidth;
          if (w >= sw) {
            this.pages = 1;
          } else {
            var rate1 = sw % w;
            this.pages = Number.parseInt(sw / w);
            if (rate1 != 0) {
              this.pages += 1;
            }
          }
        }
        if (this.pages > 1) {
          this.current += 1;
          if (this.current > this.pages) {
            this.current = 1;
          }
          if (this.horizon) {
            //如果是横向，往下滚动
            this.$refs.sensorscroll.scrollTop =
              (this.current - 1) * this.$refs.sensorscroll.clientHeight;
          } else {
            //如果是纵向，往左右滚动
            var sc = (this.current - 1) * this.$refs.sensorscroll.clientWidth;
            this.$refs.sensorscroll.scrollLeft = sc;
          }
        }
      }, this.speed);
    },
    update() {
      this.$ksajax
        .ksPostPromise("/api/device/GetSensorDataAuth", this.sid, false)
        .then(data => {
          if (!this.items || this.items.length == 0) {
            this.items = [];
            let value = getJson(this.data.Data.Custom);
            let obj = {};
            value.sensor.forEach(x => {
              obj[x] = 0;
            });
            var its = sensorFormat(obj);
            its.forEach(x => {
              x.val = 0;
            });
            this.items = its;
          }
          this.items.forEach(x => {
            x.val =
              data.SensorData[x.value] == -1 ? 0 : data.SensorData[x.value];
          });
          var array = [];
          if (this.items.forEach) {
            this.items.forEach(x => {
              array.push(`${x.text}:${x.val}${x.pix}`);
            });
          }
          this.lineText = array.join(",");
          
        });

    },
    quit() {
      window.clearInterval(this.scrollTimmer);
      window.clearInterval(this.computeTimmer);
      window.clearInterval(this.lineTimmer);
    }
  },
  destroyed: function() {
    this.quit();
  }
};
</script>
