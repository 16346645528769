
<template>
  <div class="white">
    <actionbar
      v-bind:select="false"
      :insert="false"
      v-bind:modify="false"
      :del="true"
      :detail="true"
      :path="true"
      v-on:insert="insert"
      v-on:select="select"
      v-on:modify="modify"
      v-on:detail="detail"
      v-on:path="path"
      v-on:delete="del"
    >
    </actionbar>
    <el-row style="display:none">
      <el-col>
        {{ $t("类型") }}
        <el-select v-model="searchType" size="mini">
          <el-option
            v-for="item in initData.SearchType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row v-if="searchType == 0">
      <el-col :span="2" style="min-width: 170px">
        {{ $t("相似度") }}：
        <el-input-number
          v-model="searchData.SimilarityShow"
          @change="handleChange"
          :min="0"
          :max="100"
          label="描述文字"
          size="mini"
          style="width: 90px"
        ></el-input-number>
      </el-col>
      <el-col :span="2" style="min-width: 140px">
        {{ $t("性别") }}:
        <el-select
          v-model="searchData.Sex"
          placeholder="请选择"
          size="mini"
          style="width: 90px"
        >
          <el-option
            v-for="item in initData.Sex"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="2" style="min-width: 180px">
        {{ $t("是否带帽") }}:
        <el-select
          v-model="searchData.Hat"
          placeholder="请选择"
          size="mini"
          style="width: 90px"
        >
          <el-option
            v-for="item in initData.Hat"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="2" style="min-width: 180px">
        {{ $t("是否背包") }}:
        <el-select
          v-model="searchData.Bag"
          placeholder="请选择"
          size="mini"
          style="width: 90px"
        >
          <el-option
            v-for="item in initData.Bag"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="min-width: 190px">
        {{ $t("是否拎东西") }}:
        <el-select
          v-model="searchData.Things"
          placeholder="请选择"
          size="mini"
          style="width: 90px"
        >
          <el-option
            v-for="item in initData.Things"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="min-width: 190px">
        {{ $t("是否戴眼镜") }}:
        <el-select
          v-model="searchData.Glass"
          placeholder="请选择"
          size="mini"
          style="width: 90px"
        >
          <el-option
            v-for="item in initData.Glass"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="min-width: 420px">
        {{ $t("时间") }}:
        <el-date-picker
          v-model="searchData.DateFrame"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
        >
        </el-date-picker>
      </el-col>

      <el-col :span="3"
        ><el-button
          type="primary"
          icon="el-icon-search"
          round
          size="mini"
          @click="search"
          >搜索{{ $t("类型") }}</el-button
        ></el-col
      >
    </el-row>

    <el-row v-if="searchType == 1">
      <el-col :span="4" style="min-width: 250px">
        {{ $t("姓名") }}:
        <el-input
          v-model="searchData.Name"
          placeholder="请输入内容"
          size="mini"
          style="width: 190px"
        ></el-input>
      </el-col>
      <el-col :span="3"
        ><el-button
          type="primary"
          icon="el-icon-search"
          round
          size="mini"
          @click="search"
          >搜索{{ $t("类型") }}</el-button
        ></el-col
      >
    </el-row>
    <el-row v-if="searchType == 2">
      <el-col :span="4" style="min-width: 250px">
        {{ $t("人脸图片") }}:
        <el-upload
          class="avatar-uploader"
          action="/api/Upload/FileUploadSingle"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="searchData.ImageUrl"
            :src="searchData.ImageUrl"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-col>
      <el-col :span="3"
        ><el-button
          type="primary"
          icon="el-icon-search"
          round
          size="mini"
          @click="search"
          >搜索{{ $t("类型") }}</el-button
        ></el-col
      >
    </el-row>

    <el-table
      :data="items"
      stripe=""
      highlight-current-row
      @current-change="currentRow"
      :empty-text="$t('暂无数据')"
    >
      <el-table-column
        :label="$t('序号')"
        type="index"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column :label="$t('抓拍图像')" width="100">
        <template slot-scope="scope">
          <b-img
            :src="scope.row.ImageUrl"
            width="40"
            v-if="scope.row.ImageUrl"
          ></b-img>
          <b-img
            src="/img/unknow.png"
            width="40"
            v-if="!scope.row.ImageUrl"
          ></b-img>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('姓名')"
        prop="Name"
        width="250"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.Name">{{ scope.row.Name }}</span>
          <span v-if="!scope.row.Name">-</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('性别')">
        <template slot-scope="scope">
          <span v-if="scope.row.Sex == 0">男{{ $t("类型") }}</span>
          <span v-else-if="scope.row.Sex == 1">女{{ $t("类型") }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('相似度')" prop="Similarity" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.Similarity != 0"
            >{{ (scope.row.Similarity * 100) | fix0 }}%</span
          >
          <span v-if="scope.row.Similarity == 0">-</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('类型')" prop="Type">
        <template slot-scope="scope">
          <span v-if="scope.row.Type == 0">{{ $t("人形检测") }}</span>
          <span v-if="scope.row.Type == 1">{{ $t("人脸检测") }}</span>
          <span v-if="scope.row.Type == 2">{{ $t("人脸对比") }}</span>
          <span v-if="scope.row.Type == 10">{{ $t("陌生人") }}</span>
          <span v-if="scope.row.Type == 11">{{ $t("嫌疑人") }}</span>
          <span v-if="scope.row.Type == 12">{{ $t("普通客人") }}</span>
          <span v-if="scope.row.Type == 13">{{ $t("重要客人") }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('抓拍设备')"
        prop="DeviceName"
      ></el-table-column>
      <el-table-column
        :label="$t('时间')"
        prop="DetectTime"
        width="250"
      ></el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout=" prev, pager, next, total,sizes"
      @current-change="current_change"
      @size-change="pagesize_change"
      :current-page="currentPage"
      :page-sizes="[10, 50, 100, 200]"
      :page-size="pagesize"
      :total="total"
      background
    >
    </el-pagination>

    <!-- 详情页 -->
    <b-modal
      id="modallg"
      ref="modallg"
      size="lg"
      v-bind:title="modalTitle"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <b-form @submit="onSubmit">
        <b-container>
          <b-row>
            <!-- 左边 -->
            <b-col style="padding-right: 0">
              <p style="text-align: center">{{ $t("抓拍图像") }}</p>
              <img
                v-if="!curItem.PicUrl"
                src="/img/unknow.png"
                style="max-width: 315px"
              />
              <img
                id="headpicimage"
                v-if="curItem.PicUrl"
                :src="curItem.PicUrl"
                style="max-width: 315px"
              />
              <div class="focusline" ref="focuslinehead"></div>
            </b-col>
            <!-- 右边 -->
            <b-col>
              <div class="chunk_content_1">
                <div class="left_2">
                  <label class="face1_2_title">{{ $t("抓拍人脸") }}</label>
                  <b-img
                    v-if="!curItem.ImageUrl"
                    class="face1_2"
                    src="/img/unknow.png"
                  ></b-img>
                  <b-img
                    v-if="curItem.ImageUrl"
                    class="face1_2"
                    :src="curItem.ImageUrl"
                  ></b-img>
                </div>
                <div class="middle_2">
                  <div class="similar_num">
                    {{ curItem.Similarity * 100 || 0 | fix0 }}%
                  </div>
                  <h3>{{ $t("相似度") }}</h3>
                </div>
                <div class="right_2">
                  <label class="face2_2_title">{{ $t("人脸库") }}</label>
                  <b-img
                    v-if="!curItem.FaceUrl"
                    class="face2_2"
                    src="/img/unknow.png"
                  ></b-img>
                  <b-img
                    v-if="curItem.FaceUrl"
                    class="face2_2"
                    :src="curItem.FaceUrl"
                  ></b-img>
                </div>
              </div>
              <!-- 人形检测 -->
              <div class="chunk_title_1" v-if="curItem.Type == 0">
                {{ $t("人形检测") }}
              </div>
              <div class="chunk_content_1" v-if="curItem.Type == 0">
                <div class="chunk_item_1">
                  {{ $t("性别") }}:
                  {{ curItem.Sex == 0 ? "男" : "女" || "未知" }}
                </div>
                <div class="chunk_item_1">
                  {{ $t("上衣颜色") }}: {{ curItem.JacetColor || "未知" }}
                </div>
                <div class="chunk_item_1">
                  {{ $t("是否骑车") }}: {{ curItem.IsRide ? "是" : "否" }}
                </div>
                <div class="chunk_item_1">
                  {{ $t("是否带帽") }}: {{ curItem.IsHat ? "是" : "否" }}
                </div>
                <div class="chunk_item_1">
                  {{ $t("下装类型") }}: {{ curItem.TrousersType || "未知" }}
                </div>
                <div class="chunk_item_1">
                  {{ $t("下装颜色") }}: {{ curItem.TrousersColor || "未知" }}
                </div>
                <div class="chunk_item_1">
                  {{ $t("发型") }}: {{ curItem.HairStyle || "未知" }}
                </div>
                <div class="chunk_item_1">
                  {{ $t("是否拎东西") }}: {{ curItem.IsThings ? "是" : "否" }}
                </div>
              </div>
              <!-- 人脸检测 -->
              <div class="chunk_title_1" v-if="curItem.Type == 1">
                {{ $t("人脸检测") }}
              </div>
              <div class="chunk_content_1" v-if="curItem.Type == 1">
                <div class="chunk_item_1">
                  {{ $t("性别") }}:
                  {{ curItem.Sex == 0 ? "男" : "女" || "未知" }}
                </div>
                <div class="chunk_item_1">
                  {{ $t("年龄") }}: {{ curItem.Age }}
                </div>
                <div class="chunk_item_1">
                  {{ $t("是否戴眼镜") }}: {{ curItem.IsGlass ? "是" : "否" }}
                </div>
              </div>
              <!-- 人脸对比 -->
              <div class="chunk_title_1" v-if="curItem.Type == 2">
                {{ $t("人脸对比") }}
              </div>
              <div class="chunk_content_1" v-if="curItem.Type == 2">
                <div class="chunk_item_1" :title="curItem.Name">
                  {{ $t("姓名") }}: {{ curItem.Name || "未知" }}
                </div>
                <div class="chunk_item_1">
                  {{ $t("性别") }}: {{ curItem.SexString || "未知" }}
                </div>
                <div class="chunk_item_1">
                  {{ $t("年龄") }}: {{ curItem.Age || "未知" }}
                </div>
                <div class="chunk_item_1">
                  {{ $t("民族") }}: {{ curItem.Nation || "未知" }}
                </div>
                <div class="chunk_item_2">
                  {{ $t("身份证") }}: {{ curItem.IdNumber || "未知" }}
                </div>
                <div class="chunk_item_2">
                  {{ $t("住址") }}: {{ curItem.Address || "未知" }}
                </div>
                <div class="chunk_item_2">
                  {{ $t("备注") }}: {{ curItem.Describe || "未知" }}
                </div>
              </div>

              <div class="chunk_title_1"></div>
              <div class="chunk_content_1">
                <div class="chunk_item_2">
                  {{ $t("抓拍设备") }}: {{ curItem.DeviceName || "未知" }}
                </div>
                <div class="chunk_item_2">
                  {{ $t("抓拍时间") }}: {{ curItem.DetectTime || "未知" }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-form-group>
          <hr />
          <div class="modal-foot">
            <b-button style="width: 100px" v-on:click="hiden(modallg)">{{
              $t("取消")
            }}</b-button>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>

    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/face/DelMessage"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>

<style>
.focusline {
  width: 0px;
  height: 0px;
  background: linear-gradient(270deg, #faad14, #faad14) 0 0 no-repeat,
    linear-gradient(180deg, #faad14, #faad14) 0 0 no-repeat,
    linear-gradient(270deg, #faad14, #faad14) 100% 0 no-repeat,
    linear-gradient(180deg, #faad14, #faad14) 100% 0 no-repeat,
    linear-gradient(270deg, #faad14, #faad14) 0 100% no-repeat,
    linear-gradient(180deg, #faad14, #faad14) 0 100% no-repeat,
    linear-gradient(270deg, #faad14, #faad14) 100% 100% no-repeat,
    linear-gradient(270deg, #faad14, #faad14) 100% 100% no-repeat;
  background-size: 2px 20px, 20px 2px, 2px 20px, 20px 2px;
  position: absolute;
}

.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}

.chunk_title_1 {
  color: #000;
  border-bottom: 1px solid #b0b0b0;
  margin: 13px;
  padding-bottom: 10px;
}

.chunk_title_1 .btn-toolbar {
  float: right;
}

.chunk_content_1 {
  /*min-height: 10%;*/
  /*border: 1px solid red;*/
  margin: 0 15px;
  position: relative;
}

.chunk_content_1 .left_1,
.chunk_content_1 .right_1 {
  width: 50%;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.chunk_content_1 .face1 {
  width: 161px;
  height: 168px;
  text-align: center;
}

.chunk_content_1 .vip {
  position: absolute;
  top: 0;
  background-color: rgba(255, 106, 0, 0.5);
  text-align: center;
}

.chunk_content_1 .face1_title {
  color: #ff6a00;
  font-size: 1rem;
  display: block;
  padding-left: 45px;
}

.chunk_content_1 h3 {
  font-size: 1.5rem;
  color: #000;
  margin-top: 16px;
}

.chunk_content_1 .face2 {
  width: 110px;
  height: 115px;
}

.chunk_content_1 .face2_title {
  color: #ff6a00;
  font-size: 1rem;
  padding-left: 13%;
  display: block;
}

.chunk_content_1 .similar_num {
  background-color: #fff;
  color: darkgreen;
  font-size: 2.5rem;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 80px;
  top: -10px;
  font-weight: 700;
}

.chunk_content_1 .chunk_item_1 {
  width: 50%;
  color: #000;
  display: inline-block;
  padding-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.chunk_content_1 .chunk_item_2 {
  width: 100%;
  color: #000;
  display: inline-block;
  padding-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chunk_content_1 .chunk_item_91px {
  width: 91px;
  color: #000;
  display: inline-block;
  margin: 0 10px;
  text-align: center;
}

.chunk_content_1 .chunk_toolbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  text-align: right;
}

.chunk_content_1 .middle_2 {
  position: absolute;
  left: 33%;
  bottom: -18px;
  z-index: 1;
}

.chunk_content_1 .left_2,
.chunk_content_1 .right_2 {
  width: 49.3%;
  display: inline-block;
  position: relative;
  vertical-align: top;
  text-align: center;
}

.chunk_content_1 .face1_2 {
  width: 130px;
  height: 131.14px;
  text-align: center;
}

.chunk_content_1 .face1_2_title {
  color: #000;
  font-size: 0.5rem;
  display: block;
  margin-top: 5px;
}

.chunk_content_1 .face2_2 {
  width: 130px;
  height: 131.14px;
  text-align: center;
}

.chunk_content_1 .face2_2_title {
  color: #ff6a00;
  font-size: 0.5rem;
  display: block;
  margin-top: 5px;
}

.middle_2 h3 {
  font-size: 1rem;
  margin: 0;
}

.middle_2 .similar_num {
  position: static;
  width: 61px;
  height: 61px;
  font-size: 1.5rem;
  margin-left: calc(50% - 30px);
  margin-top: 10px;
}
</style>

<script>
import actionbar from "@/components/actionbar";
import tablepage from "@/components/tablepage";
import modify from "@/components/modify";
import deletedata from "@/components/deletedata";
import alarmAction from "@/components/alarmAction";
import config from "@/assets/utils/config"
export default {
  components: { actionbar, tablepage, modify, deletedata, alarmAction },
  data: function () {
    return {
      baseUrl:config.BaseUrl,
      radio: 0,
      modalTitle: this.$t("创建人脸库"),
      modalData: {},
      initData: {
        SearchType: [
          //  人脸消息类型
          { label: "条件搜索", value: 0 },
          { label: "名字搜索", value: 1 },
          { label: "图片搜索", value: 2 },
        ],
        Hat: [
          // 是否带帽
          { label: "全部", value: -1 },
          { label: "未戴帽", value: 0 },
          { label: "戴帽", value: 1 },
        ],
        Bag: [
          // 是否背包
          { label: "全部", value: -1 },
          { label: "未背包", value: 0 },
          { label: "背包", value: 1 },
        ],
        Things: [
          // 是否拎东西
          { label: "全部", value: -1 },
          { label: "没有", value: 0 },
          { label: "有", value: 1 },
        ],
        Glass: [
          // 是否戴眼镜
          { label: "全部", value: -1 },
          { label: "未戴", value: 0 },
          { label: "戴了", value: 1 },
        ],
        Sex: [
          // 性别
          { label: "全部", value: -1 },
          { label: "男", value: 0 },
          { label: "女", value: 1 },
        ],
        MessageType: [
          { label: "全部", value: -1 },
          { label: "人形检测", value: 0 },
          { label: "人脸检测", value: 1 },
          { label: "人脸对比", value: 2 },
          { label: "陌生人", value: 10 },
          { label: "嫌疑人", value: 11 },
          { label: "普通客人", value: 12 },
          { label: "重要客人", value: 13 },
        ],
      },
      searchType: null,
      searchData: {
        SimilarityShow: 80,
      },
      loading: {},
      items: [],
      curItem: {},
      total: 0, // 默认数据总数
      pagesize: 10, // 每页的数据条数
      currentPage: 1, // 默认开始页面
      curIndex: 0,
      index: 0,
      sid: "",
      current: null,
      focuslinestyle: {},
    };
  },
  filters: {
    fix0: function (value) {
      value = Number(value);
      return value.toFixed(0);
    },
  },
  methods: {
    modify() {},
    test() {
      //
    },
    select(noloading, index) {
      // 获取设备列表
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      new Promise((res, rej) => {
        let obj = {
          Index: (this.currentPage - 1) * this.pagesize,
          Count: this.pagesize,
        };
        this.$ksajax
          .ksPostPromise("/api/face/GetMessages", obj, true)
          .then((data) => {
            this.items = data.Items;
            this.total = data.TotalCount;
            res();
          })
          .catch((err) => {
            rej(err);
          });
      })
        .then(() => {
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    search(noloading, index) {
      // 获取设备列表
      if (!noloading) {
        if (!index) {
          this.index = 0;
        }
        this.loading = this.$loading({ fullScreen: true });
      }
      new Promise((res, rej) => {
        this.searchData.Similarity = this.searchData.SimilarityShow / 100;
        let obj = {
          Index: (this.currentPage - 1) * this.pagesize,
          Count: this.pagesize,
          CdtType: this.searchType,
          Condition: this.searchData,
        };
        // 处理名字搜索
        if (obj.CdtType == 1 && this.searchData.Name)
          obj.Condition = this.searchData.Name;
        // 处理图片搜索
        if (obj.CdtType == 2 && this.searchData.ImageUrl)
          obj.Condition = this.searchData.ImageUrl;
        // 根据时间搜索
        if (obj.CdtType == 0 && this.searchData.DateFrame) {
          obj.Condition.BeginTime = this.searchData.DateFrame[0].Format(
            "yyyy-MM-dd"
          );
          obj.Condition.EndTime = this.searchData.DateFrame[1].Format(
            "yyyy-MM-dd"
          );
        }
        var url = "/api/face/SearchMessages";
        if (obj.CdtType == null) url = "/api/face/GetMessages";

        this.$ksajax
          .ksPostPromise(url, obj, true)
          .then((data) => {
            this.items = data.Items;
            this.total = data.TotalCount;
            res();
          })
          .catch((err) => {
            rej(err);
          });
      })
        .then(() => {
          if (this.loading.close) {
            this.loading.close();
          }
        })
        .catch((err) => {
          this.$throw(err);
          if (this.loading.close) {
            this.loading.close();
          }
        });
    },
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.curIndex = (this.currentPage - 1) * this.pagesize;
      this.search();
    },
    pagesize_change: function (pagesize) {
      this.pagesize = pagesize;
      this.currentPage = 1;
      this.curIndex = (this.currentPage - 1) * this.pagesize;
      this.search();
    },
    indexMethod: function (index) {
      return index + this.curIndex + 1;
    },
    beforeAvatarUpload(file) {
      // 上传图片前
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";

      const iRet = isJPG || isGIF || isPNG;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!iRet) {
        throw this.$t("图片格式不正确");
      }
      if (!isLt2M) {
        throw this.$t("图片大小要小于2M");
      }
      return iRet && isLt2M;
    },
    handleAvatarSuccess(res) {
      // 上传图片成功后
      this.searchData.ImageUrl = res.Data;
    },
    insert() {
      // 新增对话框
      this.modalTitle = this.$t("创建人脸库");
      this.$refs.modallg.show();
    },
    hiden(name) {
      // 隐藏对话框
      if (!name) {
        this.$refs.modallg.hide();
      } else {
        this.$refs[name].hide();
      }
    },
    onSubmit(evt) {
      // 提交对话框
      evt.preventDefault();
      /* this.loading = this.$loading();
                 this.$ksajax
                     .ksPostPromise("/api/face/AddFaceSet", this.modalData, true)
                     .then((r) => {
                         this.loading.close();
                         this.hiden();
                         this.select();
                     })
                     .catch(err => {
                         this.$throw(err);
                         this.hiden();
                     });*/
    },

    del() {
      // 删除集合
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.sid = this.current.Sid;
      this.$refs.del.show();
    },

    detail() {
      // 修改对话框
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.modalTitle = this.$t("人脸识别详情");
      this.loading = this.$loading();
      this.$ksajax
        .ksPostPromise("/api/face/GetMessageDetail", this.current.Sid, true)
        .then((r) => {
          this.curItem = r;
          this.loading.close();
          this.$refs.modallg.show();
          var i = new Image();
          //i.src = r.PicUrl;
          i.src =
            `${this.baseUrl}/AiImages/Originals/Faces/cba43126f22046a9abcc3233615c1934.png`;
          var focusl = this.$refs.focuslinehead;
          i.onload = function () {
            var scale = i.naturalWidth / 315;
            var calcW = r.Location.Width / scale;
            var calcH = r.Location.Height / scale;
            var calcX = r.Location.X / scale;
            var calcY = r.Location.Y / scale;

            focusl.style.width = calcW + "px";
            focusl.style.height = calcH + "px";
            focusl.style.top = calcX + "px";
            focusl.style.left = calcY + "px";
          };

          //this.$refs.focuslinehead.style.width = r.Location.Width + "px";
          //this.$refs.focuslinehead.style.height = r.Location.Height + "px";
          //this.$refs.focuslinehead.style.top=r.Location.X + "px";
          //this.$refs.focuslinehead.style.left=r.Location.Y + "px";
        })
        .catch((err) => {
          this.$throw(err);
        });
    },
    currentRow(val) {
      this.current = val;
    },

    submited() {
      this.select();
    },
    deleted() {
      this.select();
    },
    path() {
      // 查看详情
      if (this.current == null) {
        throw this.$t("选择一条数据");
      }
      this.$router.push(
        "/admin/face/roadmap?Sid=" +
          this.current.Sid +
          "&FaceSetSid=" +
          this.current.FaceSetSid
      );
    },
    handleChange() {
      //
    },
  },
  mounted: function () {
    Date.prototype.Format = function (fmt) {
      var o = {
        "M+": this.getMonth() + 1,
        "d+": this.getDate(),
        "h+": this.getHours(),
        "m+": this.getMinutes(),
        "s+": this.getSeconds(),
        "q+": Math.floor((this.getMonth() + 3) / 3),
        S: this.getMilliseconds(),
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (this.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    };

    this.select();
  },
};
</script>
