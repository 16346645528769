<template>
  <div class="white">
    <actionbar
      v-bind:select="true"
      v-bind:modify="true"
      :insert="true"
      :del="true"
      v-on:select="select"
      v-on:modify="modify"
      v-on:delete="del"
      v-on:insert="insert"
    ></actionbar>
    <el-table stripe="" :data="items" style="width: 100%" highlight-current-row @current-change="currentRow" :empty-text="$t('暂无数据')">
      <el-table-column :label="$t('名称')" prop="Name"></el-table-column>
      <el-table-column :label="$t('开始日期')" prop="BeginDate"></el-table-column>
      <el-table-column :label="$t('结束日期')" prop="EndDate"></el-table-column>
      <el-table-column :label="$t('最后一次执行')" prop="LastExecuteTime"></el-table-column>
      <el-table-column :label="$t('执行次数')" prop="ExecuteCount"></el-table-column>
      <el-table-column :label="$t('状态')">
        <template slot-scope="scope">{{scope.row.IsEnabled==0?"禁用":"启用"}}</template>
      </el-table-column>
    </el-table>
    <b-modal
      id="modallg"
      ref="modallg"
      class="ksmodal"
      size="lg"
      v-bind:title="title"
      hide-footer
      header-bg-variant="success"
      body-text-variant="dark"
    >
      <el-steps :active="active" finish-status="success">
        <el-step title="基础信息"></el-step>
        <el-step title="目标"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <hr />
      <b-form @submit="onSubmit">
        <div v-show="active==0">
          <b-form-group v-bind:label="$t('名称')" :label-cols="2">
            <b-form-input type="text" v-model="form.Name" required></b-form-input>
          </b-form-group>
          <b-form-group v-bind:label="$t('生效日期')" :label-cols="2">
            <el-date-picker
              :editable="false"
              :clearable="false"
              name="23"
              required="sdf"
              v-model="form.BeginDate"
              type="date"
            ></el-date-picker>——
            <el-date-picker :editable="false" :clearable="false" v-model="form.EndDate" type="date"></el-date-picker>
          </b-form-group>
          <b-form-group v-bind:label="$t('状态')" :label-cols="2">
            <b-form-radio-group v-model="form.IsEnabled">
              <b-form-radio :value="0">{{$t('禁用')}}</b-form-radio>
              <b-form-radio :value="1">{{$t('启用')}}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <b-form-group
            v-if="form.Timings.length+form.Events.length+form.Intervals.length+form.Sunrises.length+form.Sunsets.length>1"
            v-bind:label="$t('条件类型')"
            :label-cols="2"
          >
            <b-form-radio-group v-model="form.ConditionMode">
              <b-form-radio :value="0">{{$t('并且')}}</b-form-radio>
              <b-form-radio :value="1">{{$t('或者')}}</b-form-radio>
              <b-form-radio :value="2">{{$t('自定义')}}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <b-form-group
            v-if="form.ConditionMode==2"
            v-bind:label="$t('条件表达式')"
            :label-cols="2"
          >
            <b-form-input type="text" v-model="form.ConditionExpression" required></b-form-input>
          </b-form-group>

          <b-form-group v-bind:label="$t('类型')" :label-cols="2">
            <b-form-radio-group
              id="radios2"
              v-model="itemType"
              name="radioSubComponent"
              style="display:inline-block"
            >
              <b-form-radio value="0">{{$t('定时执行')}}</b-form-radio>
              <b-form-radio value="1">{{$t('事件触发')}}</b-form-radio>
              <b-form-radio value="2">{{$t('间隔执行')}}</b-form-radio>
              <b-form-radio value="3">{{$t('日出策略')}}</b-form-radio>
              <b-form-radio value="4">{{$t('日落策略')}}</b-form-radio>
            </b-form-radio-group>
            <el-button type="primary" @click="addItem" round size="small">{{$t('新增')}}</el-button>
          </b-form-group>
          <div v-if="form.Timings&&form.Timings.length>0">
            <b-form-group v-bind:label="$t('定时执行')" :label-cols="2" required>
              <div v-for="item in form.Timings" class="sceneTemp" :key="item.guid">
                <b-form-group v-bind:label="$t('执行时间')" :label-cols="2" required>
                  <el-time-picker
                    :editable="false"
                    :clearable="false"
                    v-model="item.ExecuteTime"
                    :placeholder="$t('时间')"
                  ></el-time-picker>
                </b-form-group>
                <b-form-group v-bind:label="$t('星期')" :label-cols="2">
                  <b-form-checkbox-group v-model="item.Rep" :options="Weeks"></b-form-checkbox-group>
                </b-form-group>
                <div style="position:absolute;right:10px;top:10px">
                  <i class="fa fa-trash-o" style="cursor:pointer" @click="delTimmer(item.guid)"></i>
                </div>
              </div>
            </b-form-group>
          </div>

          <div v-if="form.Events&&form.Events.length>0">
            <b-form-group v-bind:label="$t('事件触发')" :label-cols="2" required>
              <div v-for="item in form.Events" class="sceneTemp" :key="item.guid">
                <b-form-group v-bind:label="$t('传感器')" :label-cols="2" required>
                  <b-form-select
                    :options="sensors"
                    v-model="item.SensorSid"
                    style="display:inline-block;width:250px"
                    v-on:change="sensorChange"
                  ></b-form-select>
                </b-form-group>
                <b-form-group v-bind:label="$t('传感器数据')" :label-cols="2" required>
                  <b-form-radio-group
                    v-model="item.sensorDataSelect"
                    value-field="de"
                    :options="item.sensorDatas"
                    style="display:inline"
                  ></b-form-radio-group>
                  <el-button
                    v-if="item.sensorDataSelect.length!=0"
                    type="primary"
                    @click="addEventItem(item)"
                    round
                    size="small"
                  >{{$t('新增')}}</el-button>
                </b-form-group>
                <hr />
                <div
                  v-if="item.Details&&item.Details.length>0"
                  v-for="it in item.Details"
                  :key="it.guid"
                  style="position:relative"
                >
                  <b-form-group v-bind:label="$t('触发类型')" :label-cols="2">{{it.DataStr}}</b-form-group>
                  <b-form-group v-bind:label="$t('生效时间')" :label-cols="2">
                    <el-time-picker
                      :editable="false"
                      :clearable="false"
                      style="width:150px"
                      v-model="it.BeginTime"
                      :placeholder="$t('时间')"
                    ></el-time-picker>-
                    <el-time-picker
                      :editable="false"
                      :clearable="false"
                      style="width:150px"
                      v-model="it.EndTime"
                      :placeholder="$t('时间')"
                    ></el-time-picker>
                  </b-form-group>
                  <b-form-group v-bind:label="$t('执行条件')" :label-cols="2">
                    <b-form-select
                      v-model="it.Symbol"
                      style="width:100px;display:inline;margin-right:5px"
                    >
                      <option value="-1">{{$t('小于')}}</option>
                      <option value="0">{{$t('等于')}}</option>
                      <option value="1">{{$t('大于')}}</option>
                    </b-form-select>
                    <b-form-input
                      type="number"
                      min="0"
                      required
                      v-model="it.Value"
                      style="width:100px;display:inline;margin-right:5px"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group v-bind:label="$t('执行次数')" :label-cols="2">
                    <b-form-input
                      required
                      type="number"
                      min="0"
                      v-model="it.TriggerCount"
                      style="width:100px;display:inline;margin-right:5px"
                    ></b-form-input>
                    {{$t('次')}}
                  </b-form-group>
                  <hr v-if="it.Details&&it.Details.length>1" />
                  <div style="position:absolute;right:10px;top:10px">
                    <i class="fa fa-trash-o" style="cursor:pointer" @click="delEventItem(item,it)"></i>
                  </div>
                  <hr />
                </div>
                <div style="position:absolute;right:10px;top:10px">
                  <i class="fa fa-trash-o" style="cursor:pointer" @click="delEvent(item.guid)"></i>
                </div>
                <hr />
              </div>
            </b-form-group>
          </div>

          <div v-if="form.Intervals&&form.Intervals.length>0">
            <b-form-group v-bind:label="$t('间隔执行')" :label-cols="2">
              <div v-for="item in form.Intervals" class="sceneTemp" :key="item.guid">
                <b-form-group v-bind:label="$t('开始时间')" :label-cols="2">
                  <el-date-picker
                    :editable="false"
                    :clearable="false"
                    v-model="item.BeginTime"
                    type="date"
                  ></el-date-picker>
                  <el-time-picker
                    :editable="false"
                    :clearable="false"
                    v-model="item.BeginTime"
                    :placeholder="$t('时间')"
                  ></el-time-picker>
                </b-form-group>
                <b-form-group v-bind:label="$t('间隔')" :label-cols="2">
                  <b-form-input
                    type="number"
                    required
                    min="0"
                    v-model="item.Interval"
                    style="width:100px;display:inline;margin-right:5px"
                  ></b-form-input>
                  {{$t('秒')}}
                </b-form-group>
                <div style="position:absolute;right:10px;top:10px">
                  <i class="fa fa-trash-o" style="cursor:pointer" @click="delInterval(item.guid)"></i>
                </div>
              </div>
            </b-form-group>
          </div>

          <div v-if="form.Sunrises&&form.Sunrises.length>0">
            <b-form-group v-bind:label="$t('日出策略')" :label-cols="2">
              <div v-for="item in form.Sunrises" class="sceneTemp" :key="item.guid">
                <b-form-group v-bind:label="$t('偏移量')" :label-cols="2">
                  <b-form-input
                    type="number"
                    required
                    min="0"
                    v-model="item.Offset"
                    style="width:100px;display:inline;margin-right:5px"
                  ></b-form-input>
                  {{$t('秒')}}
                </b-form-group>
                <div style="position:absolute;right:10px;top:10px">
                  <i class="fa fa-trash-o" style="cursor:pointer" @click="delSunrises(item.guid)"></i>
                </div>
              </div>
            </b-form-group>
          </div>

          <div v-if="form.Sunsets&&form.Sunsets.length>0">
            <b-form-group v-bind:label="$t('日落策略')" :label-cols="2">
              <div v-for="item in form.Sunsets" class="sceneTemp" :key="item.guid">
                <b-form-group v-bind:label="$t('偏移量')" :label-cols="2">
                  <b-form-input
                    type="number"
                    required
                    min="0"
                    v-model="item.Offset"
                    style="width:100px;display:inline;margin-right:5px"
                  ></b-form-input>
                  {{$t('秒')}}
                </b-form-group>
                <div style="position:absolute;right:10px;top:10px">
                  <i class="fa fa-trash-o" style="cursor:pointer" @click="delSunsets(item.guid)"></i>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
        <div v-show="active==1">
          <b-tabs>
            <b-tab :title="$t('灯具')" active>
              <el-table
                ref="multlight"
                :data="lights"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="lightSelectionChange" :empty-text="$t('暂无数据')"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column :label="$t('名称')">
                  <template slot-scope="scope">{{scope.row.SZ.name}}</template>
                </el-table-column>
                <el-table-column :label="$t('设备地址')">
                  <template slot-scope="scope">{{scope.row.SZ.addr}}</template>
                </el-table-column>
                <el-table-column :label="$t('设备类型')">
                  <template slot-scope="scope">{{scope.row.Custom.DeviceType.TypeName}}</template>
                </el-table-column>
                <el-table-column :label="$t('操作')">
                  <template slot-scope="scope">
                    <b-form-select
                      v-model="scope.row.Action"
                      :options="getAction(false)"
                      style="display:inline;width:150px;"
                    ></b-form-select>
                    <b-form-input
                      type="number"
                      style="display:inline;width:100px;"
                      v-if="scope.row.Action==2"
                      v-model="scope.row.Bri"
                    ></b-form-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('修正值')">
                  <template slot-scope="scope">
                    <b-form-input type="number" v-model="scope.row.Correction"></b-form-input>
                  </template>
                </el-table-column>
              </el-table>
            </b-tab>
            <b-tab :title="$t('网关')">
              <el-table
                ref="multgate"
                :data="gateways"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="gatewaySelectionChange" :empty-text="$t('暂无数据')"
              >
                <el-table-column type="selection" width="55" :aria-checked="true"></el-table-column>
                <el-table-column :label="$t('名称')">
                  <template slot-scope="scope">{{scope.row.SZ.name}}</template>
                </el-table-column>
                <el-table-column :label="$t('设备地址')">
                  <template slot-scope="scope">{{scope.row.SZ.addr}}</template>
                </el-table-column>
                <el-table-column :label="$t('设备类型')">
                  <template slot-scope="scope">{{scope.row.Custom.DeviceType.TypeName}}</template>
                </el-table-column>
                <el-table-column :label="$t('操作')">
                  <template slot-scope="scope">
                    <b-form-select
                      v-model="scope.row.Action"
                      :options="getAction(true)"
                      style="display:inline;width:150px;"
                    ></b-form-select>
                    <b-form-input
                      type="number"
                      style="display:inline;width:100px;"
                      v-if="scope.row.Action==2"
                      v-model="scope.row.Bri"
                    ></b-form-input>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('修正值')">
                  <template slot-scope="scope">
                    <b-form-input type="number" v-model="scope.row.Correction"></b-form-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('回路')">
                  <template slot-scope="scope">
                    <b-check-group :options="scope.row.loop" v-model="scope.row.selectLoop"></b-check-group>
                  </template>
                </el-table-column>
              </el-table>
            </b-tab>
            <b-tab :title="$t('软件分组')">
              <el-table
                ref="multsgroup"
                :data="sgroup"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="sgroupSelectionChange" :empty-text="$t('暂无数据')"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column :label="$t('名称')">
                  <template slot-scope="scope">{{scope.row.GroupName}}</template>
                </el-table-column>
                <el-table-column :label="$t('操作')">
                  <template slot-scope="scope">
                    <b-form-select
                      v-model="scope.row.Action"
                      :options="getAction(false)"
                      style="display:inline;width:150px;"
                    ></b-form-select>
                    <b-form-input
                      type="number"
                      style="display:inline;width:100px;"
                      v-if="scope.row.Action==2"
                      v-model="scope.row.Bri"
                    ></b-form-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('修正值')">
                  <template slot-scope="scope">
                    <b-form-input type="number" v-model="scope.row.Correction"></b-form-input>
                  </template>
                </el-table-column>
              </el-table>
            </b-tab>
          </b-tabs>
        </div>
        <b-form-group>
          <hr />
          <b-container>
            <b-row>
              <b-col cols="4">
                <b-button :disabled="active==0" @click="step(true)">{{$t("上一步")}}</b-button>
              </b-col>
              <b-col cols="4"></b-col>
              <b-col cols="4" style="text-align:right">
                <b-button type="submit" v-if="active!=2">{{active==0?$t("下一步"):$t("提交")}}</b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
      </b-form>
    </b-modal>

    <deletedata
      ref="del"
      v-on:deleted="deleted"
      :sid="sid"
      url="/api/strategy/DelLampStrategy"
      :title="$t('删除')"
    ></deletedata>
  </div>
</template>
<style>
</style>

<script>
import actionbar from "@/components/actionbar";
import deletedata from "@/components/deletedata";
import { sensorFormat, formatDate } from "@/assets/utils/datef";
export default {
  components: { actionbar, deletedata },
  data: function() {
    return {
      loading: {},
      items: [],
      current: null,
      form: this.getResetForm(),
      title: "",
      active: 0,
      itemType: 0,
      Weeks: [
        { value: 1, text: this.$t("星期一") },
        { value: 2, text: this.$t("星期二") },
        { value: 3, text: this.$t("星期三") },
        { value: 4, text: this.$t("星期四") },
        { value: 5, text: this.$t("星期五") },
        { value: 6, text: this.$t("星期六") },
        { value: 7, text: this.$t("星期天") }
      ],
      sensors: [],
      sensorDatas: [],
      gateways: null,
      gatewaySelect: [],
      lights: null,
      ligthSelect: [],
      sgroup: null,
      hgroup: null,
      sgroupSelect: [],
      hgroupSelect: [],
      sid: ""
    };
  },
  methods: {
    deleted() {
      this.select();
    },
    getAction(gate) {
      var array = [
        { text: this.$t("开灯"), value: 1 },
        { text: this.$t("关灯"), value: 0 },
        { text: this.$t("调光"), value: 2 },
        { text: this.$t("读取数据"), value: 3 },
        { text: this.$t("重启"), value: 4 },
        { text: this.$t("查询运行时间"), value: 5 }
      ];
      if (gate) {
        array.push({ text: this.$t("读取回路"), value: 6 });
        array.push({ text: this.$t("断开回路"), value: 7 });
        array.push({ text: this.$t("闭合回路"), value: 8 });
        array.push({ text: this.$t("采集三相电"), value: 9 });
      }
      return array;
    },
    select() {
      this.loading = this.$loading();
      let obj = {
        Index: 0,
        Count: 10000
      };
      this.$ksajax
        .ksPostPromise("/api/strategy/GetLampStrategies", obj, true)
        .then(data => {
          this.items = data.Items;
          this.loading.close();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    modify() {
      if (!this.current) {
        throw this.$t("选择一条数据");
      }
      this.active = 0;
      this.form = {
        Sid: this.current.Sid,
        Name: this.current.Name,
        BeginDate: formatDate(
          new Date(this.current.BeginDate),
          "yyyy/MM/dd hh:mm:ss"
        ),
        EndDate: formatDate(
          new Date(this.current.EndDate),
          "yyyy/MM/dd hh:mm:ss"
        ),
        IsEnabled: this.current.IsEnabled,
        Timings: [],
        ConditionMode: this.current.ConditionMode,
        ConditionExpression: this.current.ConditionExpression,
        Events: [],
        Intervals: [],
        Devices: [],
        Groups: [],
        Sunrises: [],
        Sunsets: []
      };
      if (this.current.Timings) {
        this.form.Timings = this.current.Timings;
        this.form.Timings.forEach(x => {
          x.Rep = x.Repeat ? x.Repeat.split(",") : [];
          x.guid = this.guid();
          x.ExecuteTime = formatDate(
            new Date("2018/01/01 " + x.ExecuteTime),
            "yyyy/MM/dd hh:mm:ss"
          );
        });
      }
      if (this.current.Events) {
        this.current.Events.forEach(x => {
          var data = {
            guid: this.guid(),
            SensorSid: x.Sensor.Sid,
            sensorDatas: [],
            sensorDataSelect: "",
            Details: []
          };
          if (x.Details) {
            x.Details.forEach(e => {
              data.Details.push({
                DataStr: "",
                DataKind: e.DataKind,
                Symbol: e.Symbol,
                Value: e.Value,
                TriggerCount: e.TriggerCount,
                BeginTime: formatDate(
                  new Date("2018/01/01 " + e.BeginTime),
                  "yyyy/MM/dd hh:mm:ss"
                ),
                EndTime: formatDate(
                  new Date("2018/01/01 " + e.EndTime),
                  "yyyy/MM/dd hh:mm:ss"
                ),
                guid: this.guid()
              });
            });
          }
          this.form.Events.push(data);
        });
      }
      if (this.current.Intervals) {
        this.current.Intervals.forEach(x => {
          this.form.Intervals.push({
            BeginTime: formatDate(new Date(x.BeginTime), "yyyy/MM/dd hh:mm:ss"),
            Interval: x.Interval,
            guid: this.guid()
          });
        });
      }
      if (this.current.Devices) {
        this.form.Devices = this.current.Devices;
      }
      if (this.current.Groups) {
        this.form.Groups = this.current.Groups;
      }
      if (this.current.Sunrises) {
        this.current.Sunrises.forEach(x => {
          this.form.Sunrises.push({
            Offset: x.Offset,
            guid: this.guid()
          });
        });
      }
      if (this.current.Sunsets) {
        this.current.Sunsets.forEach(x => {
          this.form.Sunsets.push({
            Offset: x.Offset,
            guid: this.guid()
          });
        });
      }
      this.show();
    },
    del() {
      this.$refs.del.show();
    },
    insert() {
      this.form = this.getResetForm();
      this.title = this.$t("新增");
      this.active = 0;
      this.show();
    },
    show() {
      this.loading = this.$loading();
      let obj = {
        Index: 0,
        Count: 1000
      };
      this.$ksajax
        .ksPostPromise("/api/device/GetDeviceSensors", obj, true)
        .then(data => {
          this.sensors = [];
          for (var i = 0; i < data.Items.length; i++) {
            this.sensors.push({
              text: data.Items[i].DeviceName,
              value: data.Items[i].Sid
            });
          }
        })
        .then(() => {
          if (this.form.Events) {
            this.form.Events.forEach(x => {
              this.getSensorData(x.SensorSid).then(ret => {
                x.sensorDatas = ret;
                if (x.Details) {
                  x.Details.forEach(d => {
                    for (var i = 0; i < x.sensorDatas.length; i++) {
                      if (x.sensorDatas[i].de == d.DataKind) {
                        d.DataStr = x.sensorDatas[i].text;
                        break;
                      }
                    }
                  });
                }
              });
            });
          }
        })
        .then(() => {
          this.loading.close();
          this.$refs.modallg.show();
        })
        .catch(err => {
          this.$throw(err);
          this.loading.close();
        });
    },
    currentRow(val) {
      this.current = val;
      if (val) {
        this.sid = this.current.Sid;
      }
    },
    getResetForm() {
      return {
        Sid: "",
        Name: "23",
        BeginDate: formatDate(new Date(), "yyyy/MM/dd hh:mm:ss"),
        EndDate: formatDate(new Date(), "yyyy/MM/dd hh:mm:ss"),
        IsEnabled: 1,
        ConditionMode: 0,
        ConditionExpression: "",
        Timings: [
          {
            ExecuteTime: formatDate(new Date(), "yyyy/MM/dd hh:mm:ss"),
            Repeat: "",
            guid: this.guid()
          }
        ],
        Events: [],
        Intervals: [],
        Sunsets: [],
        Sunrises: []
      };
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.active == 0) {
        if (this.form.Timings) {
          this.form.Timings.forEach(x => {
            x.Repeat = x.Rep ? x.Rep.join(",") : "";
          });
        }
        //获取所有网关
        this.loading = this.$loading();
        new Promise((res, rej) => {
          if (this.gateways) {
            res();
          } else {
            let obj = {
              Method: "read",
              SZ: {
                platform: 1,
                type: 1,
                skip: 0,
                limit: 100000,
                name: ""
              }
            };
            this.$ksajax
              .ksPostPromise("/api/agent/RequestSzApi", obj, true)
              .then(data => {
                data.Items.forEach(x => {
                  x.Action = 1;
                  x.Bri = 100;
                  x.loop = [];
                  x.Correction = 0;
                  x.selectLoop = [];
                  x.SZ.sections.forEach(y => {
                    x.loop.push({ text: this.$t("回路") + y.id, value: y.id });
                  });
                });
                this.gateways = data.Items;
                res();
              })
              .catch(err => {
                rej(err);
              });
          }
        })
          .then(() => {
            //获取所有设备
            return new Promise((res, rej) => {
              if (this.lights) {
                res();
              } else {
                //获取所有设备
                let dobj = {
                  Method: "read",
                  SZ: {
                    platform: 1,
                    type: 4,
                    skip: 0,
                    limit: 1000000,
                    name: ""
                  }
                };
                this.$ksajax
                  .ksPostPromise("/api/agent/RequestSzApi", dobj, true)
                  .then(data => {
                    data.Items.forEach(x => {
                      x.Action = 1;
                      x.Bri = 100;
                      x.Correction = 0;
                    });
                    this.lights = data.Items;
                    res();
                  })
                  .catch(err => {
                    rej(err);
                  });
              }
            });
          })
          .then(() => {
            //获取所有软件分组
            return new Promise((res, rej) => {
              if (this.sgroup) {
                res();
              } else {
                this.$ksajax
                  .ksPostPromise("/api/device/GetGroups", 1, true)
                  .then(data => {
                    data.forEach(x => {
                      x.Action = 1;
                      x.Bri = 100;
                      x.Correction = 0;
                    });
                    this.sgroup = data;
                    res();
                  })
                  .catch(err => {
                    rej(err);
                  });
              }
            });
          })
          .then(() => {
            if (this.form.Devices) {
              if (this.lights) {
                this.lights.forEach(x => {
                  var obj = this.form.Devices.find(
                    y => y.Device.Sid == x.Custom.Sid
                  );
                  if (obj) {
                    this.$refs.multlight.toggleRowSelection(x);
                    x.Bri = obj.Brightness;
                    x.Action = obj.Action;
                    x.Correction = obj.Correction;
                    x.selectLoop = obj.Sections ? obj.Sections.split(",") : [];
                  }
                });
              }
              if (this.gateways) {
                this.gateways.forEach(x => {
                  var obj = this.form.Devices.find(
                    y => y.Device.Sid == x.Custom.Sid
                  );
                  if (obj) {
                    this.$refs.multgate.toggleRowSelection(x);
                    x.Bri = obj.Brightness;
                    x.Action = obj.Action;
                    x.Correction = obj.Correction;
                    x.selectLoop = obj.Sections ? obj.Sections.split(",") : [];
                  }
                });
              }
            }
            if (this.form.Groups) {
              if (this.sgroup) {
                this.sgroup.forEach(x => {
                  var obj = this.form.Groups.find(y => y.Group.Sid == x.Sid);
                  if (obj) {
                    this.$refs.multsgroup.toggleRowSelection(x);
                    x.Bri = obj.Brightness;
                    x.Action = obj.Action;
                    x.Correction = obj.Correction;
                    x.selectLoop = obj.Sections ? obj.Sections.split(",") : [];
                  }
                });
              }
            }

            this.active += 1;
            this.loading.close();
          })
          .catch(err => {
            this.$throw(err);
          });
      } else {
        //保存数据
        this.form.Devices = [];
        this.form.Groups = [];
        if (this.ligthSelect) {
          this.ligthSelect.forEach(x => {
            this.form.Devices.push({
              Action: x.Action,
              Sid: x.Custom.Sid,
              Brightness: x.Bri,
              Correction: x.Correction
            });
          });
        }
        if (this.gatewaySelect) {
          this.gatewaySelect.forEach(x => {
            this.form.Devices.push({
              Action: x.Action,
              Sid: x.Custom.Sid,
              Brightness: x.Bri,
              Correction: x.Correction,
              Sections: x.selectLoop ? x.selectLoop.join(",") : ""
            });
          });
        }
        if (this.sgroupSelect) {
          this.sgroupSelect.forEach(x => {
            this.form.Groups.push({
              Action: x.Action,
              Sid: x.Sid,
              Brightness: x.Bri,
              Correction: x.Correction
            });
          });
        }
        this.loading = this.$loading();
        this.$ksajax
          .ksPostPromise("/api/strategy/AddLampStrategy", this.form, true)
          .then(() => {
            this.active = 0;
            this.$refs.modallg.hide();
            this.loading.close();
            this.sgroup = null;
            this.sgroupSelect = null;
            this.lights = null;
            this.ligthSelect = null;
            this.gateways = null;
            this.gatewaySelect = null;
            this.select();
          })
          .catch(err => {
            this.$throw(err);
            this.loading.close();
          });
      }
    },
    guid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
      }
      return guid;
    },
    addItem() {
      switch (this.itemType * 1) {
        case 0:
          if (!this.form.Timings) {
            this.form.Timings = [];
          }
          this.form.Timings.push({
            ExecuteTime: formatDate(new Date(), "yyyy/MM/dd hh:mm:ss"),
            Repeat: "",
            Rep: [],
            guid: this.guid()
          });
          break;
        case 1:
          if (!this.sensors || this.sensors.length == 0) {
            throw "新增事件触发场景失败：没有传感器";
          }
          if (!this.form.Events) {
            this.form.Events = [];
          }
          this.getSensorData(this.sensors[0].value)
            .then(r => {
              if (!r || r.length == 0) {
                throw "不支持该传感器";
              }
              var detail = {
                guid: this.guid(),
                SensorSid: this.sensors[0].value,
                sensorDatas: r,
                sensorDataSelect: r[0].de,
                Details: []
              };
              this.form.Events.push(detail);
              this.addEventItem(detail);
            })
            .catch(err => {
              this.$throw(err);
            });
          break;
        case 2:
          if (!this.form.Intervals) {
            this.form.Intervals = [];
          }
          this.form.Intervals.push({
            BeginTime: formatDate(new Date(), "yyyy/MM/dd hh:mm:ss"),
            Interval: 120,
            guid: this.guid()
          });
          break;
        case 3:
          if (!this.form.Sunrises) {
            this.form.Sunrises = [];
          }
          this.form.Sunrises.push({
            Offset: 0,
            guid: this.guid()
          });
          break;
        case 4:
          if (!this.form.Sunsets) {
            this.form.Sunsets = [];
          }
          this.form.Sunsets.push({
            Offset: 0,
            guid: this.guid()
          });
          break;
      }
    },
    addEventItem(data) {
      if (!data.Details) {
        data.Details = [];
      }
      var str = "";
      for (var i = 0; i < data.sensorDatas.length; i++) {
        if (data.sensorDatas[i].de == data.sensorDataSelect) {
          str = data.sensorDatas[i].text;
          break;
        }
      }
      data.Details.push({
        DataStr: str,
        DataKind: data.sensorDataSelect,
        Symbol: 1,
        Value: 50,
        TriggerCount: 1,
        BeginTime: formatDate(new Date(), "yyyy/MM/dd hh:mm:ss"),
        EndTime: formatDate(new Date(), "yyyy/MM/dd hh:mm:ss"),
        guid: this.guid()
      });
    },
    delTimmer(guid) {
      if (this.form.Timings) {
        var index = -1;
        for (var i = 0; i < this.form.Timings.length; i++) {
          if (this.form.Timings[i].guid == guid) {
            index = i;
            break;
          }
        }
        if (index != -1) {
          this.form.Timings.splice(index, 1);
        }
      }
    },
    delEvent(guid) {
      if (this.form.Events) {
        var index = -1;
        for (var i = 0; i < this.form.Events.length; i++) {
          if (this.form.Events[i].guid == guid) {
            index = i;
            break;
          }
        }
        if (index != -1) {
          this.form.Events.splice(index, 1);
        }
      }
    },
    delSunrises(guid) {
      if (this.form.Sunrises) {
        var index = -1;
        for (var i = 0; i < this.form.Sunrises.length; i++) {
          if (this.form.Sunrises[i].guid == guid) {
            index = i;
            break;
          }
        }
        if (index != -1) {
          this.form.Sunrises.splice(index, 1);
        }
      }
    },
    delSunsets(guid) {
      if (this.form.Sunrises) {
        var index = -1;
        for (var i = 0; i < this.form.Sunsets.length; i++) {
          if (this.form.Sunsets[i].guid == guid) {
            index = i;
            break;
          }
        }
        if (index != -1) {
          this.form.Sunsets.splice(index, 1);
        }
      }
    },
    delInterval(guid) {
      if (this.form.Intervals) {
        var index = -1;
        for (var i = 0; i < this.form.Intervals.length; i++) {
          if (this.form.Intervals[i].guid == guid) {
            index = i;
            break;
          }
        }
        if (index != -1) {
          this.form.Intervals.splice(index, 1);
        }
      }
    },
    sensorChange(ele) {
      if (ele) {
        this.getSensorData(ele)
          .then(r => {
            this.form.Events.forEach(element => {
              if (element.SensorSid == ele) {
                element.sensorDatas = [];
                element.sensorDatas = r;
              }
            });
          })
          .catch(err => {
            this.$throw(err);
          });
      }
    },
    getSensorData(sid) {
      return new Promise((res, rej) => {
        var ret = null;
        if (this.sensorDatas && this.sensorDatas.length != 0) {
          for (var i = 0; i < this.sensorDatas.length; i++) {
            if (this.sensorDatas[i].sid == sid) {
              ret = this.sensorDatas[i].data;
              break;
            }
          }
        }
        if (ret) {
          window.setTimeout(function() {
            res(ret);
          }, 100);
        } else {
          this.loading = this.$loading();
          this.$ksajax
            .ksPostPromise("/api/device/GetSensorData", sid, true)
            .then(data => {
              if (!this.sensorDatas) {
                this.sensorDatas = [];
              }
              ret = sensorFormat(data.SensorData);
              this.sensorDatas.push({ data: ret, sid: sid });
              res(ret);
              this.loading.close();
            })
            .catch(err => {
              this.loading.close();
              rej(err);
            });
        }
      });
    },
    delEventItem(item, it) {
      if (!item.Details || item.Details.length == 1) {
        this.$throw("数据不能为空");
        throw "数据不能为空";
      }
      if (item.Details) {
        var index = -1;
        for (var i = 0; i < item.Details.length; i++) {
          if (item.Details[i].guid == it.guid) {
            index = i;
          }
        }
        if (index != -1) {
          item.Details.splice(index, 1);
        }
      }
    },
    step() {
      this.active -= 1;
      if (this.active < 0) {
        this.active = 0;
      }
      this.sgroup = null;
      this.sgroupSelect = null;
      this.lights = null;
      this.ligthSelect = null;
      this.gateways = null;
      this.gatewaySelect = null;
    },
    lightSelectionChange(val) {
      this.ligthSelect = val;
    },
    gatewaySelectionChange(val) {
      this.gatewaySelect = val;
    },
    sgroupSelectionChange(val) {
      this.sgroupSelect = val;
    },
    hgroupSelectionChange(val) {
      this.hgroupSelect = val;
    }
  },
  mounted: function() {
    this.select();
  }
};
</script>

