<template>
  <div :style="style">
    <div class="routerDetail routerDetail1 clearfix">
      <div class="routerCar routerCarTop">
          <img :src="coverImg" style="height:100%;width:auto" />
      </div>
    </div>
    <div class="routerDetail routerDetail2">
      <div class="routerCar routerCarBottom">
          <img :src="coverImg" style="height:100%;width:auto" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.routerDetail {
  height: 50%;
  position: relative;
}
.routerDetail1 {
  border-bottom: 2px solid yellow;
}
.routerDetail2 {
  border-top: 2px solid yellow;
}
.routerCar {
  position: absolute;
  height: 60%;
  top: 20%;
  width: auto;
}
.routerCarTop{
   transform:  rotateZ(-90deg);
   animation: carLeft 5s infinite ;
}
.routerCarBottom{
    transform: rotateZ(90deg);
   animation: carRight 3s infinite ease-in;
}
@keyframes carLeft {
  from {
    left:85%;
  }
  to {
    left: 0%;
  }
}
@keyframes carRight {
  from {
    left:0%;
  }
  to {
    left: 85%;
  }
}
</style>
<script>
export default {
  props: ["rheight","width"],
  data() {
    return {
      style: {
        width: "100%",
        height: "0px",
      },
      coverImg: require("@/assets/img/ic_car.png"),
    };
  },
  watch: {
    rheight() {
      this.init();
    },
    width(){
        this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.style.height = this.rheight + "px";
      this.style.width=this.width+"px";
    },
  },
};
</script>