
export function getExpByCode(code){
    var law=experssion.find(x=>x.code==code);
    if(law){
        return law.name;
    }else{
        return "正常"
    }
}
export function getAgeByCode(code){
    var law=ages.find(x=>x.code==code);
    if(law){
        return law.name;
    }else{
        return "正常"
    }
}
export function getLawByCode(code){
    var law=laws.find(x=>x.code==code);
    if(law){
        return law.name;
    }else{
        return "正常"
    }
}
export function getCarByCode(code){
    var law=cars.find(x=>x.code==code);
    if(law){
        return law.name;
    }else{
        return "未知"
    }
}
export function getCarTypeByCode(code){
    var law=carType.find(x=>x.code==code);
    if(law){
        return law.name;
    }else{
        return "未知"
    }
}
export function getPlateColorByCode(code){
    var law=plateColrs.find(x=>x.code==code);
    if(law){
        return law.name;
    }else{
        return "未知"
    }
}
export const laws = [
    { name:"正常", code:0 },
    { name:"逆行", code:1301 },
    { name:"违反信号灯通行", code:1625 },
    { name:"压线", code:13451 },
    { name:"违法变道", code:13453 },
    { name:"不按导向", code:1208 },
    { name:"机占非", code:1018 },
    { name:"占用专用车道", code:1019 },
    { name:"闯禁令", code:1344 },
    { name:"路口滞留", code:1228 },
    { name:"超速", code:1349 },
    { name:"超速", code:1350 },
    { name:"超速", code:1352 },
    { name:"超速", code:1628 },
    { name:"超速", code:1629 },
    { name:"超速", code:4609 },
    { name:"超速", code:4610 },
    { name:"超速", code:1632 },
    { name:"超速", code:1633 },
    { name:"超速", code:1636 },
    { name:"超速", code:1722 },
    { name:"超速", code:1723 },
    { name:"超速", code:4706 },
    { name:"超速", code:4707 },
    { name:"超速", code:1721 },
    { name:"超速", code:1726 },
    { name:"超速", code:1727 },
    { name:"0", code:0 },
    { name:"超速", code:4711 },
    { name:"绿灯停车", code:16251 },
    { name:"路口停车", code:1211 },
    { name:"低速", code:4306 },
    { name:"超速", code:6046 },
    { name:"超速", code:6047 },
    { name:"超速", code:6048 },
    { name:"超速", code:6050 },
    { name:"低速", code:4016 },
    { name:"占用应急车道", code:4614 },
    { name:"占用应急车道", code:4705 },
    { name:"未系安全带", code:1240 },
    { name:"压黄线", code:1345 },
    { name:"违章掉头", code:1044 },
    { name:"禁左", code:1208 },
    { name:"禁右", code:1208 },
    { name:"不礼让行人", code:1357 },
    { name:"行人闯红灯", code:3001 },
    { name:"左转不让直行", code:13131 },
    { name:"右转不让左转", code:1314 },
    { name:"掉头不让直行", code:10460 },
    { name:"大弯小转", code:12091 },
    { name:"打电话", code:1223 },
    { name:"非机动车载人", code:2013 },
    { name:"闯绿灯", code:12280 },
    { name:"违法加塞", code:134541 },
    { name:"占用机动车道", code:2006 },
    { name:"未戴头盔", code:151002 },
    { name:"非机动车逆行", code:2004 },
    { name:"越线", code:2005 },
    { name:"违法停车", code:1025 },
    { name:"吸烟", code:12250 },
    { name:"副驾驶未系安全带", code:3019 }
]
export const cars =[
    { name:"未知", code:0 },
    { name:"大众", code:1 },
    { name:"别克", code:2 },
    { name:"宝马", code:3 },
    { name:"本田", code:4 },
    { name:"标致", code:5 },
    { name:"丰田", code:6 },
    { name:"福特", code:7 },
    { name:"奥迪", code:8 },
    { name:"马自达", code:9 },
    { name:"雪佛兰", code:10 },
    { name:"雪铁龙", code:11 },
    { name:"现代", code:12 },
    { name:"奇瑞", code:13 },
]
export const carType = [
    { code: 0, name:"未知" },
    { code: 1, name:"客车" },
    { code: 2, name:"货车" },
    { code : 3, name:"轿车" },
    { code : 4, name:"面包车" },
    { code : 5, name:"小货车" },
    { code: 6, name:"行人" },
    { code: 7, name:"二轮车" },
    { code  : 8, name:"三轮车" },
    { code   : 9, name:"SUV/MPV" },
    { code  : 10, name:"中型客车" },
    { code: 11, name:"机动车" },
    { code : 12, name:"非机动车" },
    { code  : 13, name:"小型轿车" },
    { code  : 14, name:"微型轿车" },
    { code  : 15, name:"皮卡车" },
    { code  : 16, name:" 集装箱卡车" },
    { code  : 17, name:" 微卡，栏板卡" },
    { code: 18, name:" 渣土车" },
    { code: 19, name:" 吊车，工程车" },
    { code : 20, name:" 油罐车" },
    { code : 21, name:" 混凝土搅拌车" },
    { code  : 22, name:" 平板拖车" },
    { code   : 23, name:" 两厢轿车" },
    { code : 24, name:" 三厢轿车" },
    { code : 25, name:" 轿跑" },
    { code : 26, name:"小型客车" },

]
export const plateColrs=[
    {code:0,name:"蓝色"},
    {code:1,name:"黄色"},
    {code:2,name:"白色"},
    {code:3,name:"黑色"},
    {code:4,name:"绿色"},
    {code:255,name:"未知"}
]

export const ages=[
    {code:1,name:"婴幼儿"},
    {code:2,name:"儿童"},
    {code:3,name:"少年"},
    {code:4,name:"青少年"},
    {code:5,name:"青年"},
    {code:6,name:"壮年"},
    {code:7,name:"中年"},
    {code:8,name:"中老年"},
    {code:9,name:"老年 "},
]
export const experssion=[
    {code:1,name:"惊讶"},
    {code:2,name:"害怕"},
    {code:3,name:"厌恶"},
    {code:4,name:"高兴"},
    {code:5,name:"难过"},
    {code:6,name:"愤怒"},
    {code:7,name:"中性"}
]