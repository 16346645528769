<template>
    <div id="basic" class="white ksflexcol">
        <div id="basic_title">
            <el-checkbox-group v-model="checkbox"  @change="changeCheck">
              <ElCheckbox  v-for="item in data" :label="$t(item.CategoryName)"  :key="item.DCategorySid" checked="" style="margin:0 10px 0 0 !important"></ElCheckbox>
            </el-checkbox-group>
        </div>
        <div id="basic_map">
            <ksmap
            ref="map" width='100%' :showCenter="false" height="100%"
            v-bind:lat='0' v-bind:lng='0'
            v-bind:zoom=25>
            </ksmap>
        </div>
    </div>
</template>
<style>
#basic{
    height: 100%;
    width: 100%;
}
#basic_title{
    width: 100%
}
#basic_map{
    /* height: 95%; */
    flex:1 ;
    width: 100%
}
</style>
<script>
import ksmap from '@/components/map'
export default {
    data:function(){return{
        
    checkbox:[],
    }},
    components:{ksmap},
    props:["data"],
    watch:{
    "$store.state.alarmHubChange": function() {
      var data = this.$store.state.alarmHub;
      this.$refs.map. setAlarm(data.Serial);
    },
        "data":function(){
            this.$refs.map.clear();
            this.data.forEach(element => {
                var datas=[];
                element.List.forEach(x=>{
                    datas.push({
                        icon:x.Pic,
                        lat:x.Lat,
                        lng:x.Lng,
                        title:x.DeviceName,
                        sid:x.Sid,
                        serial:x.Serial,
                        url:this.getUrl(element.DCategorySid,x.Sid),
                        draggable:true
                    });
                })
                this.$refs.map.addDevice(datas,element.DCategorySid);
            });
            this.$refs.map.setFilter();
        }
    },
    methods:{
        getUrl(type,sid){
            var url=null;
            switch(type){
                case "001":
                url="/Admin/Cam/NetCam";
                break;
                case "002":
                url=`/admin/stand/Broaddetail?sid=${sid}`;
                break;
                case "003":
                url="/Admin/Light/Geteway";
                break;
                case "004":
                url="/Admin/Light/LControl";
                break;
                case "005":
                url=`/admin/stand/Sensordetail?sid=${sid}`;
                break;
                case "006":
                url=`/admin/stand/Leddetail?sid=${sid}`;
                break;
                case "007":
                url="/Admin/Wifi/Ac";
                break;
                case "008":
                url="/Admin/Wifi/AP";
                break;
                case "009":
                url=`/admin/water/detail?sid=${sid}`;
                break;
                case "010":
                url=`/admin/conver/detail?sid=${sid}`;
                break;
                case "011":
                url=`/admin/car/detail?sid=${sid}`;
                break;
                case "101":
                break;
                case "102":
                break;
                case "103":
                url="/admin/stand/standinfo?sid="+sid;
                break;
            }
            if(!url){
                url="/admin/stand/Commondevice?sid="+sid;
            }
            return url;
        },
        changeCheck(value){
            
            var array=[];
            this.data.forEach(x=>{
                var ex=value.find(z=>z==x.CategoryName);
                if(ex){
                    array.push(x.DCategorySid);
                }
            });
            this.$refs.map.fresh(array);
            this.$refs.map.setFilter();
        }
    }
}
</script>
