<template>
        <b-row class="netcam white">
            <b-col cols="12" md="4" lg="3" xl="2" class="side">
                <sidemenu v-on:clickItem='clickItem' :title="$t('摄像头列表')" :items='items'></sidemenu>
                 <div class="div-timer">
                     <span>{{$t('开始时间')}}</span>
                    <el-date-picker class="dpicker"
                        v-model="start"
                        width='100%'
                        type="datetime"
                        :placeholder="$t('开始时间')">
                        </el-date-picker>
                        <span>{{$t('结束时间')}}</span>
                    <el-date-picker class="dpicker"
                        v-model="end"
                        type="datetime"
                        width='100%'
                        :placeholder="$t('结束时间')">
                        </el-date-picker>
                 </div>
            </b-col>
            <b-col cols="12" md="8" lg="9" xl="10" class="sidecontent">
                 <netpay ref="netplay" :data='playdata'></netpay>
            </b-col>
        </b-row>
</template>
<style>
.div-timer{
    margin-top: 10px;
    
}
.dpicker{
    width: 100%!important
}
.div-timer>span{
   color: #9999;
   font-size: 12px;
   display:block;
   
}
</style>

<script>
import sidemenu from '@/components/sidemenu'
import netpay from '@/components/netpay'
import {formatDate} from '@/assets/utils/datef.js' 
export default {
    components:{sidemenu,netpay},
    data:function(){
        return{
            items:[
            ],
            playdata:{},
            start:"",
            end:""
    }
    },
    methods:{
        clickItem(sid){
            let choose =false;
            let device={};
            for(var i=0;i<this.items.length;i++){
                if(this.items[i].sid===sid){
                     choose=this.items[i].active=!this.items[i].active
                     device=this.items[i];
                }
                else{
                     this.items[i].active=false;
                }
            }
            if(choose){
                //播放
                var req =this.getPlayReqData(sid,'hd',false);
                var url="/api/device/GetPayNet";
                 this.$ksajax.ksPostData(url,req,true,(success)=>{
                     this.playdata.data=success;
                     this.playdata.isPlay=true;
                     this.playdata.device=device;
                     this.$refs.netplay.start();
                 })
            }else{
                //停止播放
                 this.playdata.isPlay=false;
                 this.$refs.netplay.stop();
            }
        },
        getPlayReqData(sid,hd,IsMute){
            return {
                "EzopenType": 1,
                "Channel": 1,
                "Definition": hd,
                "PlaybackSource": 0,
                "PlayParameter": {
                "IsMute": IsMute,
                "Begin": this.start,
                "End": this.end,
                "AlarmId": 0
                },
                "Sid": sid
            }
        }
    },
    
    mounted:function(){
        //获取设备列表
         let req={
              Index:0,
              Count:100000
          };
        var self=this;
         this.$ksajax.ksPostData("/api/device/GetDeviceVideos",req,true,
        (success)=>{
            var array=[];
            for(let i=0;i<success.Items.length;i++){
                array.push({
                    text:success.Items[i].DeviceName,
                    active:false,
                    sid:success.Items[i].Sid
                });
            }
            self.items=array;
        },
        ()=>{
        },
        false
        );
        this.start=formatDate(new Date(new Date(new Date().setHours(0)).setMinutes(0)) ,'yyyy/MM/dd hh:mm:ss');
        this.end=formatDate(new Date(),'yyyy/MM/dd hh:mm:ss'); 
    }
}
</script>

