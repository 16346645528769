import { Scene, WebGLRenderer, AmbientLight, OrthographicCamera, AxisHelper, PointLight, AxesHelper, HemisphereLight, Group, Vector3, Color,Raycaster } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Vector2 } from "three";
// import { Object3D } from "three";
// import { Mesh } from "three";

const prdBaseUrl = "https://kingsunsmart.com/model/";
const testBaseUrl = "http://192.168.1.4:5000/model/";
const isProducation = process.env.NODE_ENV === 'production';
const resources = {
    mainBox: appendHost("00frame.gltf"),
    fldoor: appendHost("01-F-L-door.gltf"),
    frdoor: appendHost("02-F-R-door.gltf"),
    bldoor: appendHost("03-B-L-DOOR.gltf"),
    brdoor: appendHost("04-B-R-DOOR.gltf"),
    ldoor: appendHost("05-L-door.gltf"),
    rdoor: appendHost("06-L-DOOR.gltf"),
}
export const doorInfos = ["fldoor", "frdoor", "bldoor", "brdoor", "ldoor", "rdoor"];
function appendHost(url) {
    return isProducation ? `${prdBaseUrl}${url}` : `${testBaseUrl}${url}`;
}
const allInboxConfig = {
    mainBox: {
        url: resources.mainBox,
        name: "mainBox",
        alias: "主箱体",
        box:null,
        boxObj:null
    },
    doors: [{
        name: "fldoor",
        url: resources.fldoor,
        alias: "左前门",
        opened: false,
        door: null,
        doorObj: null,
        isSpecialPosition: false,
        maxOpenValue: Math.PI * 0.75,
        doorPosition: { x: 0.7385174267292023, y: -0.7714959564208985, z: -0.3814589843750001 },
        doorWrapperPosition: { x: -0.7385174267292023, y: 0.7714959564208985, z: 0.3814589843750001 },
        rotationDirection: false,
        cameraPosition: {
            x: 0,
            y: 0.15,
            z: 4
        }
    }, {
        name: "frdoor",
        url: resources.frdoor,
        alias: "右前门",
        opened: false,
        door: null,
        doorObj: null,
        isSpecialPosition: false,
        maxOpenValue: Math.PI * 0.75,
        doorPosition: { x: -0.7385174267292023, y: -0.7714959564208985, z: -0.3814589843750001 },
        doorWrapperPosition: { x: 0.7385174267292023, y: 0.7714959564208985, z: 0.3814589843750001 },
        rotationDirection: true,
        cameraPosition: {
            x: 0,
            y: 0.15,
            z: 4
        }
    }, {
        name: "bldoor",
        url: resources.bldoor,
        alias: "左后门",
        opened: false,
        door: null,
        doorObj: null,
        isSpecialPosition: false,
        maxOpenValue: Math.PI * 0.75,
        doorPosition: { x: 0.7385174267292023, y: -0.7714959564208985, z: 0.3977089843749998 },
        doorWrapperPosition: { x: -0.7385174267292023, y: 0.7714959564208985, z: -0.3977089843749998 },
        rotationDirection: true,
        cameraPosition: {
            x: 0,
            y: 0.15,
            z: -4
        }
    }, {
        name: "brdoor",
        url: resources.brdoor,
        alias: "右后门",
        opened: false,
        door: null,
        doorObj: null,
        isSpecialPosition: false,
        maxOpenValue: Math.PI * 0.75,
        doorPosition: { x: -0.7385174267292023, y: -0.7714959564208985, z: 0.3977089843749998 },
        doorWrapperPosition: { x: 0.7385174267292023, y: 0.7714959564208985, z: -0.3977089843749998 },
        rotationDirection: false,
        cameraPosition: {
            x: 0,
            y: 0.15,
            z: -4
        }
    }, {
        name: "rdoor",
        url: resources.rdoor,
        alias: "右侧门",
        opened: false,
        door: null,
        doorObj: null,
        isSpecialPosition: true,
        maxOpenValue: Math.PI / 2,
        position: {
            z: 0.35
        },
        doorPosition: { x: -0.7960275268554687, y: -0.7714959564208985, z: 0.35 },
        doorWrapperPosition: { x: 0.7960275268554687, y: 0.7714959564208985, z: -0.35 },
        rotationDirection: true,
        cameraPosition: {
            x: 4,
            y: 0.25,
            z: 0
        }
    }, {
        name: "ldoor",
        url: resources.ldoor,
        alias: "左侧门",
        opened: false,
        door: null,
        doorObj: null,
        isSpecialPosition: true,
        maxOpenValue: Math.PI / 2,
        position: {
            z: 0.35
        },
        doorPosition: { x: 0.7960275268554687, y: -0.7714959564208985, z: 0.35 },
        doorWrapperPosition: { x: -0.7960275268554687, y: 0.7714959564208985, z: -0.35 },
        rotationDirection: false,
        cameraPosition: {
            x: -4,
            y: 0.25,
            z: 0
        }
    }]
}
export function AllInBoxScene(el, option = {}) {
    if(el instanceof HTMLElement){
        this.mount = el;
        this.sceneOption = Object.assign({}, defaultSceneOption, option);
    }else{
        throw new "el should be a html element!";
    }
}
const defaultSceneOption = {
    log: function (msg) {
        console.log(msg)
    },
    warn: function (msg) {
        console.log(msg);
    },
    error: function (ex) {
        console.log(ex);
    },
    objectDoubleClick:function(objName){
        console.log(objName)
    },
    openSpeed: 0.01,//开门速度
    closeSpeed: 0.01,//关门速度
    showBaseAxisHelper: false,//显示基础坐标轴
    showDoorAxisHelper: false,//显示调整后的门的坐标轴
    enableObjectInteract:false,//启用双击交互
    switchCamera: true,//开门时是否使用摄像机切换视角
    enableCameraControl:true,//启用摄像机控制工具
    canSeeBottom:true,      //可以查看底部
    useContainerBackground:true,//使用容器的背景
    viewScale:1.8,  //摄像机视野大小，这个参数不要小于1.8。调大后箱子整体会变小
    cameraZoom:1.5,//摄像机缩放，如果默认缩放下的箱体大小不能完全显示，可以调小，调整缩放后续调整offset以保证箱子在摄像机的视角范围内
    offset:0.9,//箱子底部距离容器距离
    alarmColor:"red",//告警颜色
    boxRotation:true,
    afterAnimationAction:true,//开关门后是否恢复原本的操作，如果原来箱体在旋转，那么设置为true会在开关门后恢复旋转。
    background: "#383838",//当useContainerBackground为false时有效
}
AllInBoxScene.prototype = {
    init() {
        this.scene = new Scene();
        this.doors = allInboxConfig.doors.map(p =>  Object.assign({},p));
        this.mainBox = Object.assign({},allInboxConfig.mainBox);
        this.sceneRender = new WebGLRenderer({
            antialias: true,
            alpha: true
        });
        this.doorMesh = null;
        this.doorMeshColor = null;
        this.defaultCamera = new OrthographicCamera();
        this.defaultCamera.near = 1;
        this.defaultCamera.far = 10;
        this.defaultCamera.position.set(0, 0.15, 4);
        this.setAspect();
        if(this.sceneOption.useContainerBackground){
            this.sceneRender.setClearAlpha(0.2);
        }else{
            this.scene.background = new Color(this.sceneOption.background);
            this.sceneRender.setClearColor(0xcccccc);
        }
        this.sceneRender.setPixelRatio(window.devicePixelRatio);
        this.sceneRender.physicallyCorrectLights = true;

        this.scene.add(this.defaultCamera);
        this.mount.appendChild(this.sceneRender.domElement);
        if(this.sceneOption.enableCameraControl){
            this.addCameraControl();
        }
        if (this.sceneOption.showBaseAxisHelper) {
            this.addAxisHelper();
        }
        this.addEnvironmentLight();
        this.loadMainBox();
        this.defaultCamera.zoom = this.sceneOption.cameraZoom;
        this.defaultCamera.updateProjectionMatrix();
        window.addEventListener("resize", this.setAspect.bind(this));
        if(this.sceneOption.enableObjectInteract){
            this.mount.addEventListener("dblclick",this.onSceneClick.bind(this));
        }
        if(this.sceneOption.boxRotation){
            this.startRotation();
        }
    },
    setAspect() {
        this.aspect = this.mount.clientWidth / this.mount.clientHeight
        console.log("aspect", this.aspect);
        this.sceneRender.setSize(this.mount.clientWidth, this.mount.clientHeight);
        this.sceneRender.shadowMap.enabled = true;
        let s = this.sceneOption.viewScale;
        let left = -s * this.aspect;
        let right = s * this.aspect;
        let offset = this.sceneOption.offset;
        let top = s * 2 - offset;
        let bottom = -offset;
        console.log("dis",(right - left) / (top - bottom) );
        this.defaultCamera.aspect = this.aspect;
        this.defaultCamera.left = left;
        this.defaultCamera.right = right;
        this.defaultCamera.top = top;
        this.defaultCamera.bottom = bottom;
        this.defaultCamera.updateProjectionMatrix();
        if(this.control){
            this.control.update();
        }
        this.renderScene();
    },
    addAxisHelper() {
        var axis = new AxesHelper(20);
        this.scene.add(axis);
    },
    addCameraControl() {
        this.control = new OrbitControls(this.defaultCamera, this.sceneRender.domElement);
        this.control.target = new Vector3();
        if(!this.sceneOption.canSeeBottom){
            this.control.maxPolarAngle = Math.PI / 2;  //禁止查看底部
        }
        this.control.addEventListener("change", this.renderScene.bind(this));
    },
    addPointLight() {
        let pointLight = new PointLight("white", 1, 100, 1);
        this.scene.add(pointLight);
        pointLight.position.set(0, 0, 10)
    },
    addEnvironmentLight() {
        let hemiLight = new HemisphereLight(0xffffff, 0x444444, 3.5);
        hemiLight.position.set(0, 20, 0);
        this.scene.add(hemiLight);
        let ambientLight = new AmbientLight("white", 0.3);
        this.scene.add(ambientLight);
    },
    loadMainBox() {
        let mainBoxLoader = new GLTFLoader();
        var that = this;
        mainBoxLoader.load(this.mainBox.url, obj => {
            obj.scene.name = "mainbox";
            that.scene.add(obj.scene);
            that.mainBox.box = obj.scene;
            that.mainBox.boxObj = obj;
            that.renderScene();
            that.findMesh();
        });
        this.loadDoor();
    },
    loadDoor() {
        let doorLoader = new GLTFLoader();
        var that = this;
        this.doors.forEach(p => {
            doorLoader.load(p.url, obj => {
                let door = obj.scene;
                let doorWrapper = new Group();
                p.doorObj = obj;
                p.door = doorWrapper;
                doorWrapper.name = p.name;
                that.scene.add(doorWrapper);
                doorWrapper.add(door);
                //设置group的位置为旋转中心点，设置该位置后，内部对象在旋转时将围绕该点进行旋转；
                doorWrapper.position.set(p.doorWrapperPosition.x, p.doorWrapperPosition.y, p.doorWrapperPosition.z);
                //设置门对应group的相对位置，将旋转轴对准旋转中心
                door.position.set(p.doorPosition.x, p.doorPosition.y, p.doorPosition.z);
                //添加辅助坐标轴
                if (that.sceneOption.showDoorAxisHelper) {
                    var axis3 = new AxisHelper(100);
                    axis3.position.copy(doorWrapper.position);
                    that.scene.add(axis3);
                }
                that.renderScene();
            });
        });
    },
    renderScene() {
        this.sceneRender.render(this.scene, this.defaultCamera);
    },
    disableCameraSwitch(){
        this.sceneOption.switchCamera = false;
    },
    enableCamerSwitch(){
        this.sceneOption.switchCamera = true;
    },
    openDoor(doorName) {
        var doorInfo = this.findDoorByName(doorName);
        if (doorInfo === null) {
            return;
        }
        if (doorInfo.opened) {
            this.sceneOption.log(`${doorName}已打开!`);
            return;
        }
        if(doorInfo.door === null){
            this.sceneOption.error(`${doorName}模型未加载！`);
            return;
        }
        this.switchCameraView(doorInfo);
        this.openDoorAnimation(doorInfo);
    },
    closeDoor(doorName) {
        var doorInfo = this.findDoorByName(doorName);
        if (doorInfo === null) {
            return;
        }
        if(doorInfo.door === null){
            this.sceneOption.error(`${doorName}模型未加载！`);
            return;
        }
        if (doorInfo.opened === false) {
            this.sceneOption.log(`${doorName}未打开!`);
            return;
        }
        this.switchCameraView(doorInfo);
        this.closeDoorAnimation(doorInfo);
    },
    switchCameraView(door) {
        if (this.sceneOption.switchCamera) {
            this.defaultCamera.position.set(door.cameraPosition.x, door.cameraPosition.y, door.cameraPosition.z);
            this.control.update();
            this.renderScene();
        }
    },
    findDoorByName(name) {
        var doors = this.doors.filter(p => p.name === name);
        if (doors.length === 0) {
            return null;
        } else {
            return doors[0]
        }
    },
    openDoorAnimation(doorInfo) {
        let that = this;
        let openSpeed = this.sceneOption.openSpeed;
        let boxRotation = this.boxRotation;
        if(this.boxRotation){
            this.stopRotation();
        }
        function rotation() {
            let stopKey = requestAnimationFrame(rotation);
            if (doorInfo.rotationDirection) {
                doorInfo.door.rotation.y += openSpeed;
            } else {
                doorInfo.door.rotation.y += -openSpeed;
            }
            that.renderScene();
            if (Math.abs(doorInfo.door.rotation.y) >= doorInfo.maxOpenValue) {
                that.sceneOption.log("opened");
                if(boxRotation === true && that.sceneOption.afterAnimationAction){
                    that.startRotation();
                }
                doorInfo.opened = true;
                window.cancelAnimationFrame(stopKey);
            }
        }
        rotation();
    },
    closeDoorAnimation(doorInfo) {
        var that = this;
        let closeSpeed = this.sceneOption.closeSpeed;
        let boxRotation = this.boxRotation;
        if(this.boxRotation){
            this.stopRotation();
        }
        function rotation() {
            let stopKey = requestAnimationFrame(rotation);
            if (doorInfo.door.rotation.y < 0) {
                doorInfo.door.rotation.y += closeSpeed;
            } else {
                doorInfo.door.rotation.y += -closeSpeed;
            }
            that.renderScene();
            if (Math.abs(doorInfo.door.rotation.y) < closeSpeed) {
                that.sceneOption.log("closed");
                if(boxRotation === true && that.sceneOption.afterAnimationAction){
                    that.startRotation();
                }
                //确保回到原位
                doorInfo.door.rotation.y = 0;
                doorInfo.opened = false;
                window.cancelAnimationFrame(stopKey);
                that.renderScene();
            }
        }
        rotation();
    },
    startRotation(){
        if(this.boxRotation){
            return;
        }
        var that = this;
        function rotation() {
            let stopKey = requestAnimationFrame(rotation);
            that.rotationStopKey = stopKey;
            if(that.defaultCamera){
                that.control.autoRotate = true;
                that.control.update();
                that.renderScene();
            } 
        }
        rotation();
        this.boxRotation = true;
    },
    stopRotation(){
        this.boxRotation = false;
        this.control.autoRotate = false;
        window.cancelAnimationFrame(this.rotationStopKey);
    },
    onSceneClick(e) {
        e.preventDefault();
        let camera = this.defaultCamera;
        let mouse = new Vector2();
        //将鼠标点击位置的屏幕坐标转成threejs中的标准坐标,具体解释见代码释义
        mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
        var raycaster = new Raycaster();
        console.log(mouse);
        raycaster.setFromCamera(mouse,camera);
        //射线和模型求交，选中一系列直线
        var intersects = raycaster.intersectObjects(this.scene.children);
        if (intersects.length > 0) {
            //选中第一个射线相交的物体
            let SELECTED = intersects[0].object;
            console.log(SELECTED.uuid);
            let objId = SELECTED.uuid;
            var doorName = this.findSelectDoorByUUid(objId);
            if(doorName !== undefined){
                this.sceneOption.objectDoubleClick(doorName);
            }
        }
    },
    //修改主箱体颜色
    changeMainBoxAlarm(color){
        if(color && this.doorMesh){
            this.doorMesh.material.color.set(color);
        }else{
            this.doorMesh.material.color.set(this.sceneOption.alarmColor);
        }
        this.renderScene();
    },
    //重置箱体颜色
    resetMainBoxAlarmState(){
        this.doorMesh.material.color = this.doorMeshColor.clone();
        this.renderScene();
    },
    findMesh(){
        let camera = this.defaultCamera;
        let mouse = new Vector2();
        //将鼠标点击位置的屏幕坐标转成threejs中的标准坐标,具体解释见代码释义
        mouse.x = 0
        mouse.y = 0;
        var raycaster = new Raycaster();
        raycaster.setFromCamera(mouse,camera);
        //射线和模型求交，选中一系列直线
        var intersects = raycaster.intersectObjects(this.mainBox.box.children);
        if (intersects.length > 0) {
            //选中第一个射线相交的物体
            let SELECTED = intersects[0].object;
            this.doorMesh = SELECTED;
            this.doorMeshColor = SELECTED.material.color.clone();
        }
    },
    findSelectDoorByUUid(uuid){
        if(!this.objMap){
            console.log("init map");
            this.initObjectMap();
        }
        return this.objMap[uuid];
    },
    initObjectMap(){
        this.objMap = {};
        for (let index = 0; index < this.doors.length; index++) {
            const doorInfo = this.doors[index];
            this.enumObject(doorInfo.door,doorInfo.name,this.objMap);
        }
        this.enumObject(this.mainBox.box,this.mainBox.box.name,this.objMap);
    },
    enumObject(obj,name,map){
        if(obj.uuid){
            map[obj.uuid] = name;
        }
        if(obj.children && obj.children.length > 0){
            obj.children.forEach(p => this.enumObject(p,name,map));
        }
    }
}