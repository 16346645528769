<template>
    <div>
        <div style="width:80%;margin:0 auto;padding-top:30px" v-loading="loading">
        <b-form @submit="onSubmit">
            <b-form-group label="RealTime服务器"   :label-cols="2">
                 <b-form-input v-model="model.Url">
                </b-form-input>
            </b-form-group>
            <b-form-group label="设备序列号"   :label-cols="2">
                 <b-form-input v-model="model.DeviceSerial" placeholder="多个设备用半角逗号隔开： y30-718-20196,y10-718-00032 ">
                </b-form-input>
            </b-form-group>
            <b-form-group label="操作"   :label-cols="2">
                <b-form-radio-group v-model="model.Type">
                    <b-form-radio value="0">开启xwalk</b-form-radio>
                    <b-form-radio value="1">加载Url()</b-form-radio>
                    <b-form-radio value="2">开启新年快乐节目</b-form-radio>
                    <b-form-radio value="3">关闭新年快乐节目</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
            
            <b-form-group label="提交操作"   :label-cols="2">
                   <b-button type="submit" style="width:100px" variant="primary">提交</b-button>
            </b-form-group>
        </b-form>
    </div></div>
</template>
<script>
export default {
    data:function(){
        return {
            model:{
                Url:"http://120.25.203.34:8086",
                DeviceSerial:"",
                Type:0
            },
            loading:false
        }
    },
    methods:{
        onSubmit(evt){
            evt.preventDefault();
            try{
                this.loading=true;
                 if(this.model.DeviceSerial.length==0||this.model.Url.length==0){
                     throw "数据不能为空";
                 }
                 var serials=this.model.DeviceSerial.split(",");
                 var obj={
                     Url:this.model.Url,
                     Type:this.model.Type,
                     Serials:serials
                 };
                 this.$ksajax.ksPostPromise("/api/upload/XiXunTest",obj,false).then((data)=>{
                     this.$throw(data);
                     this.loading=false;
                 }).catch(err=>{
                     this.loading=false;
                     this.$throw(err);
                 })
            }catch(err){
                this.loading=false;
                this.$throw(err);
            }
        }
    }
}
</script>
